export const professionalDetailsReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_PROFESSIONAL_DETAILS_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_PROFESSIONAL_DETAILS_SUCCESS':
            return {
                professionalInfo:action.payload.professionalInfo,
                loading: false,
                success: true
            }
        case'GET_PROFESSIONAL_DETAILS_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        case'POST_PROFESSIONAL_REVIEW_REQUEST':
            return {
                ...state,
                loading: true,
                page_loadings: true,
            }
        case'POST_PROFESSIONAL_REVIEW_SUCCESS':
            return {
                loading: false,
                page_loadings: false,
                success: true
            }
        case'POST_PROFESSIONAL_REVIEW_FAILED':
            return {
                ...state,
                loading: false,
                page_loadings: false,
                error: true
            }
        default:
            return state
    }
}