import React from "react";
import SelectDateTime from '../date_time/SelectDateTime'
import CheckoutSummary from '../components/CheckoutSummary';

export const DateTime = ({
  checkoutData,
  updateCheckoutData,
  appointment,
  setCheckoutData
}) => {
  return (
    <>
      <div className="stepsContent stepDate ">
        <div className="row">
          <div className="col-lg-7 col-md-12">
            <div className="card">
              <div className="stepsCard   ">
                <SelectDateTime checkoutData={checkoutData} updateCheckoutData={updateCheckoutData} setCheckoutData={setCheckoutData}/>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5">
            <CheckoutSummary 
              checkoutData={checkoutData}
              appointment={appointment}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default DateTime;
