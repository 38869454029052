export const customerAddressReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_CUSTOMER_ADDRESS_LIST_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_CUSTOMER_ADDRESS_LIST_SUCCESS':
            return {
                customerAddresses: action.payload.customerAddresses,
                loading: false,
                success: true
            }
        case'GET_CUSTOMER_ADDRESS_LIST_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        case'POST_DEFAULT_ADDRESS_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'POST_DEFAULT_ADDRESS_SUCCESS':
            return {
                loading: false,
                success: true
            }
        case'POST_DEFAULT_ADDRESS_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        default:
            return state
    }
}