export const getPrivacyReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_PP_LIST_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_PP_LIST_SUCCESS':
            return {
                pp_data:action.payload.pp_data,
                loading: false,
                success: true
            }
        case'GET_PP_LIST_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        default:
            return state
    }
}