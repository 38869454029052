import React, {useEffect, useState, useRef} from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {useTranslation, withTranslation} from "react-i18next";
import TrackingLocationModel from "./TrackingLocationModel";
import {useDispatch, useSelector} from "react-redux";
import Modal from "react-bootstrap/Modal";
import {currentOpenCleaner} from "../../firebase";
import AddressAddModal from "../my_account/Address/AddressAddModal";
import {useParams} from "react-router-dom";
import TrackingModal from "./service/TrackingModal";

const AppointmentTrack = () => {
  const getAppointmentInfoState = useSelector(
    (state) => state.appointmentReducer
  );
  const {appointmentId} = useParams();
  const {appointment} = getAppointmentInfoState;

  const [newMarkers, setNewMarkers] = useState([]);
  const [customerGeoCode, setCustomerGeoCode] = useState({});
  let app = appointment;
  const {t} = useTranslation();
  const setAddAddressRef = useRef();
  const [showAddressModal, setShowAddressModal] = useState(false);
  const handleAddressModalShow = () => setShowAddressModal(true);
  const handleAddressModalClose = () => setShowAddressModal(false);


  useEffect(() => {
    const interval = setInterval(() => {
      let isSubscribed = true;
      currentOpenCleaner()
        .then((records) => {
          records.forEach((element) => {
            app?.CleanerDetails?.forEach((cleaner) => {
              if (element.name === cleaner?._id) {
                setNewMarkers(element.location);
              }
            });
          });
        })
        .catch((err) => console.log("failed: ", err));

      return () => (isSubscribed = false);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const fetchLocations = () => {
    currentOpenCleaner()
      .then((records) => {
        const getAppointid = records?.filter(
          (obj) => obj?.name === appointmentId
        );
        setShowAddressModal(true);
      })
      .catch((err) => console.log("failed: ", err));
  };

  const show = () => {
    return newMarkers;
  };

  useEffect(() => {
    let geoCode = {
      latitude: app?.CustomerDetails?.customerAddress?.latitude,
      longitude: app?.CustomerDetails?.customerAddress?.longitude
    };
    setCustomerGeoCode(geoCode);
  }, []);

  return (
    <>
      <Header />
      <div className="py-5">
        <div className="userContainer">
          <div className="appoiintments">
            <div className="heading">
              <h5>appointments</h5>
              <h2>Appointment Tracking</h2>        
              <button
                className="btn btn-primary btn-light "
                onClick={() => fetchLocations()}
                disabled={newMarkers?.lat ? false : true}
              >
              {t("track_Appointment")}
              </button>
            </div>
          </div>

          <div className="card">
            <div className="divMap">
              <div className="selctAddressItem p-0 border-0 mb-5"></div>
            </div>
            <div className="personalForm">
              <div className="appointmentDetails">
                <div className="appointmentSummery">
                  <div className="cleanerSummery">
                    <h3>{t("appointment_details")}</h3>
                    <ul>
                      <li>
                        {t("Reference No")}{" "}
                        <span>{app?.mainPayment?.payment_reference}</span>
                      </li>
                      <li>
                        {t("EstimatedTime")}
                        <span>{app?.start_time_between}</span>
                      </li>
                    </ul>
                  </div>
                  <div className="cleanerSummery">
                    <h3>{t("cleaner_details")}</h3>
                    <ul>
                      <li>
                        {t("full_name")}{" "}
                        <span>{app?.CleanerDetails[0]?.fullName}</span>
                      </li>
                      <li>
                        {t("mobile_number")}
                        <span>{app?.CleanerDetails[0]?.mobile}</span>
                      </li>
                    </ul>
                  </div>
                  <div className="cleanerSummery">
                    <h3>{t("Driver Details")}</h3>
                    <ul>
                      <li>
                        {t("full_name")}{" "}
                        <span>{app?.DriverDetails?.fullName}</span>
                      </li>
                      <li>
                        {t("mobile_number")}
                        <span>{app?.DriverDetails?.mobile}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <AddressAddModal ref={setAddAddressRef}/> */}
      <Modal
        show={showAddressModal}
        onHide={handleAddressModalClose}
        className="addAddressModal"
        id="addAddress"
      >
        <Modal.Header closeButton>
        {t("track_Appointment")}
        </Modal.Header>
        <Modal.Body>
          <form id="addressSetupForm">
            <div className="modal-body">
              <div className="divMap">
                <TrackingModal
                  // onClose={handleClose}
                  startLocation={{lat: newMarkers?.lat, lng: newMarkers?.lng}}
                  endLocation={{
                    lat: parseInt(appointment?.booking_address?.latitude),
                    lng: parseInt(appointment?.booking_address?.longitude)
                  }}
                />
                {/* <TrackingLocationModel
                  translatedValue={t}
                  newMarkers={newMarkers}
                  customerGeoCode={customerGeoCode}
                /> */}
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
};

export default withTranslation()(AppointmentTrack);
