export const cookiesPolicyReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_COOKIES_POLICY_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_COOKIES_POLICY_SUCCESS':
            return {
                cookies_policy_data:action.payload.cookies_policy_data,
                loading: false,
                success: true
            }
        case'GET_COOKIES_POLICY_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        default:
            return state
    }
}