export const getFAQReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_FAQ_LIST_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_FAQ_LIST_SUCCESS':
            return {
                faq_list:action.payload.faq_list,
                loading: false,
                success: true
            }
        case'GET_FAQ_LIST_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        default:
            return state
    }
}