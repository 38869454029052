import React, {  useEffect } from "react";
import {Link} from "react-router-dom";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import AppDownload from "../../../components/AppDownload";

import {useDispatch, useSelector} from "react-redux";
// import { getTCDataAction } from "../actions/tcDataAction";
import { useTranslation } from 'react-i18next';




const HowItworks = () => {
  const { t } = useTranslation();
  const getTCDataState = useSelector(state => state.getTCReducer)

  return (
    <>
      <Header/>
    
      <div className="container">
        <div className="pt-5">
          <div className="heading">
            <h5><Link to="/my-account/referral">{t('free_services')}</Link></h5> 
            <h2>{t('how_it_works')}</h2> 
          </div>
          <div className="card howItworks">
          <h2 className="mb-3">{t('have_your_friends_join_in_fun')}</h2>
          <p>{t('every_time_user_registers_new_account_using_your_referral')}</p>
          <p>{t('in_return_for_spreading_the_word')}</p>
          <p>{t('the_more_people_use_your_referral_code')}</p>
          <p>{t('your_accumulated_credits_accumulated_credits')}</p>
          <h2 className="mb-3">{t('terms_and_condition')}</h2>
          <p>* {t('referral_credits_can_combined_with_promo_codes')}</p>
          <p>* {t('referral_credits_to_go_below_minimum_charge')}</p>
          <p>* {t('as_part_of_its_launch')}</p>
          <p>{t('if_you_cancel_booking_used_referral_credit')}</p>
          </div>
        </div>
      </div>
      <AppDownload/>
      <Footer />
    </>
  );
};

export default HowItworks;
