import React, {useState} from "react";
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {useDispatch, useSelector} from "react-redux";
import { toast } from 'react-toastify';
import Modal from "react-bootstrap/Modal";
import {ModifiedToastContainer} from './ModifiedToastContainer';
import jQuery from 'jquery';
import { useTranslation } from 'react-i18next'; 
import es from 'react-phone-input-2/lang/es.json'
import ar from 'react-phone-input-2/lang/ar.json';
import {loginUserAction,loginUserResendOTPAction,otpVerificationAction,profileSetupAction} from "../actions/userActions";
import LocationSearchModal from "../components/LocationSearchModal";
import Loader from "../components/Loader";

  let displayContent = {
      displayNone:{
          display:'none'
      },
      displayBlock:{
          display:'block'
      }
  }
  
  const LoginModal = ({ showLoginModal, hideLoginModal }) => {
    
    const getUserLoginState = useSelector((state) => state.userLoginReducer);
    const { loading } = getUserLoginState;
    const currentLanguageCode = localStorage.getItem('currentLanguage') ? localStorage.getItem('currentLanguage') : 'en';
    const currentUser = (localStorage.getItem('currentUser') && localStorage.getItem('currentUser') !== "null") ? JSON.parse(localStorage.getItem('currentUser')) : false;
    const dispatch = useDispatch();
    const userCountryCode = localStorage.getItem('userCountryCode') ? localStorage.getItem('userCountryCode') : 'en';
    const { t } = useTranslation();
    const [showOtpModal, setShowOtpModal] = useState(false);
    const handleOtpModalShow = () => setShowOtpModal(true);
    const handleOtpModalClose = () => { setShowOtpModal(false);
                                        window.location.reload(); };
    const [showInfoModal, setShowInfoModal] = useState(false);
    const handleInfoModalShow = () => setShowInfoModal(true);
    const handleInfoModalClose = () => setShowInfoModal(false);
    let apiUrl = process.env.REACT_APP_LOCAL_API_URL + "/login";

    var timeOut;
    function startOTPTimeOut(){
      if(timeOut) {
        stopOTPTimeOut();
        clearInterval(timeOut);
      }
      var time1 = 59;
      var time2 = 0;
      jQuery('.resentOtpTime').removeClass("timeNotVisible");
      timeOut = setInterval(function(){ 
        time2 = time1--;
        jQuery('.startTimer').html("00:"+((time2 < 10) ? '0'+time2:time2));
        if (time2 === 0) {
          clearInterval(timeOut);
          resendEvent();
        }
      }, 1000);
    }

    function stopOTPTimeOut() {
      clearInterval(timeOut);
    }

    const resendOtpSend = (e) => {
        jQuery('.resend-otp').css("display", "none");
        jQuery('.resendOtpError').css("display", "none");
        jQuery('.resendOtpError').html("");
        jQuery('.otpSent').css("display", "block");
        jQuery('.otpSent').html(t('otp_sent_on_your_mobile'));
        jQuery('.resentOtpTime').removeClass("timeNotVisible");
        let frm = document.getElementById('loginForm');
        dispatch(loginUserResendOTPAction(apiUrl,{
          mobile:currentUser.mobile,
          country_code:currentUser.country_code
        },loginSuccess));
    }

    const resendEvent = (e) => {
        jQuery('.resendOtpError').css("display", "block");
        jQuery('.resend-otp').css("display", "block");
        jQuery('.otpSent').css("display", "none");
        jQuery('.resentOtpTime').addClass("timeNotVisible");
    }

    async function loginSuccess(otp) {
      setTimeout(() => {
        const currentUser = (localStorage.getItem('currentUser') && localStorage.getItem('currentUser') !== "null") ? true : false;
        const newCurrentUser = (localStorage.getItem('currentUser') && localStorage.getItem('currentUser') !== "null") ? JSON.parse(localStorage.getItem('currentUser')) : false;
        if(currentUser){
          hideLoginModal();
          handleOtpModalShow();
          jQuery('#otp').val(otp);
          jQuery('.otpContinueBtn').removeClass('disabled');
          jQuery('.otpContinueBtn').attr('disabled', false);
          jQuery('.phone-number').html(newCurrentUser.country_code+newCurrentUser.mobile);
          jQuery('.resend-otp').css("display", "none");
          jQuery('.resendOtpError').css("display", "none");
          jQuery('.resendOtpError').html("");
          jQuery('.resentOtpTime').addClass(" timeNotVisible");
          startOTPTimeOut();
        }
      }, 700);
    }

    const handleFormSubmit = (e) => {
      e.preventDefault();
      let mobile = jQuery('#mobile').val();
      let country_code = jQuery('#country_code').val();
      let frm = document.getElementById('loginForm');
      var mobileNumberRange = 10;
      if(country_code === '+974'){
        mobileNumberRange = 8;
      }
      if(mobile && mobile.length === mobileNumberRange){
        jQuery('.createOtpSubmit').removeClass('disabled');
        jQuery('.createOtpSubmit').removeAttr('disabled');
        dispatch(loginUserAction(apiUrl,frm,loginSuccess));
      }else{
        jQuery('.createOtpSubmit').addClass('disabled');
        jQuery('.createOtpSubmit').attr('disabled', 'true');
      }
    }

    const enterMobile = (e) => {
      let mobile = jQuery('#mobile').val();
      let country_code = jQuery('#country_code').val();
      let frm = document.getElementById('loginForm');
      var mobileNumberRange = 10;
      if(country_code === '+974'){
        mobileNumberRange = 8;
      }
      if(mobile && mobile.length === mobileNumberRange){
        jQuery('.createOtpSubmit').removeClass('disabled');
        jQuery('.createOtpSubmit').removeAttr('disabled');
        dispatch(loginUserAction(apiUrl,frm,loginSuccess));
      }else{
        jQuery('.createOtpSubmit').addClass('disabled');
        jQuery('.createOtpSubmit').attr('disabled', 'true');
      }
    }

    async function onOTPVerificationSuccess(token) {
      if(token && token !== null){
        setTimeout(() => {
          localStorage.setItem('currentToken', JSON.stringify(token));
          window.location.reload();
        }, 500);
      }else{
        handleInfoModalShow();
      }
    }

    const handleFormOtpSubmit = (e) => {
        e.preventDefault();
        let otp = e.target.elements.otp.value;
        if(otp && otp.length === 4){
            let values = {
              otp: otp,
              fcm_token: "website",
              device_type: "website",
              mobile: currentUser.mobile,
              country_code: currentUser.country_code
            }
            let apiOtpUrl = process.env.REACT_APP_LOCAL_API_URL + "/verify/otp";
            dispatch(otpVerificationAction(apiOtpUrl, values, onOTPVerificationSuccess));
        }else{
            let message = (!otp) ? t('enter_otp') : t('invalid_otp');
            jQuery('.resendOtpError').html(message);
        }
    };

    const typeOtp = (e) => {
      let otp = jQuery('#otp').val();
      if(otp.length === 4 ){
          jQuery('.otpContinueBtn').removeClass("disabled");
          jQuery('.spinner-border').css("display", "block");
      }else{
          jQuery('.otpContinueBtn').addClass("disabled");
          jQuery('.spinner-border').css("display", "none"); 
      }

      if(otp && otp.length === 4){
          jQuery('.resendOtpError').css("display", "none");
          let values = {
            mobile: currentUser.mobile,
            country_code: currentUser.country_code,
            device_type: "website",
            fcm_token: "website",
            otp: otp
          }
          let apiOtpUrl = process.env.REACT_APP_LOCAL_API_URL + "/verify/otp";
          dispatch(otpVerificationAction(apiOtpUrl, values, onOTPVerificationSuccess));
      }else{
          if(otp.length >= 4 || otp.length === 4){
              jQuery('.resendOtpError').css("display", "block");
              jQuery('.resendOtpError').html(t('invalid_otp'));
          }else{
            jQuery('.resendOtpError').css("display", "none");
            jQuery('.resendOtpError').html('');
          }
      }
  }

    const handleCustomerSubmit = async (e) => {
      e.preventDefault();
      var country = jQuery('#country').val();
      var message = '';
      if(country){
        if(country === 'IN' || country === 'QA'){
          jQuery('#mobile').val(currentUser.mobile);
          jQuery('#country_code').val(currentUser.country_code);
          let frm = document.getElementById('profileSetupForm');
          let apiProfileSetupUrl = process.env.REACT_APP_LOCAL_API_URL + "/profile/setup";
          dispatch(profileSetupAction(frm, apiProfileSetupUrl));
        }else{
          message = t('currently_not_operate_in_this_area');
        }
      }else{
        message = t('search_area_first');
      }
      if(message !== ''){
        toast.error(message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };

    const [signupModalShow, setSignupModalShow] = useState(false);
    const handleSignupModalShow = () =>{
      setSignupModalShow(true);
      setShowInfoModal(false)
  
    } ;
    const handleSignupModalHide = () => setSignupModalShow(false);
    return (
      <>
        {/*  Login Modal */}
        {loading ? <Loader /> : ""}
        <Modal show={showLoginModal} onHide={hideLoginModal} id="loginModal">
          <Modal.Header closeButton>
            <Modal.Title className="loginHead">
              {" "}
              {t('login_or_signup')}<i className="flag flag-us"></i>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleFormSubmit} id="loginForm">
              <div className="">
                <label>{t('your_mobile_number')}</label>
                  <div className="row loginMobileInput">
                    <div className="inputgroup phonecodeselect col-lg-5">
                    <ReactPhoneInput
                      inputProps={{
                        className:"login_country_code form-control",
                        name: "country_code",
                        id: "country_code",
                        autoFocus: true,
                        placeholder: t("enter_mobile_number"),
                        readOnly: "readonly",
                      }}
                      country={userCountryCode.toLocaleLowerCase()}
                      localization={currentLanguageCode === 'en' ? es : ar}
                      countryCodeEditable={false}
                      placeholder=""
                      disableCountryCode={false}
                      searchClass="search-class"
                      searchStyle={{
                        margin: "0",
                        width: "97%",
                        height: "30px",
                      }}
                      enableSearch
                      disableSearchIcon
                    />
                    <div className="help-block text-danger"></div>
                    </div>
                    
                    <div className="inputgroup phonecodeselect col-lg-7">
                      <input
                          type="number"
                          name="mobile"
                          id="mobile"
                          className="form-control login_mobile"
                          onChange={enterMobile}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          placeholder="33333 3333"
                        />
                      <div className="help-block text-danger"></div>
                    </div>
                  </div>



                {/* <div className="inputgroup phonecodeselect ">
                  <ReactPhoneInput
                    inputProps={{
                      name: "mobile",
                      id: "mobile",
                      autoFocus: true,
                      placeholder: t('enter_mobile_number'),
                    }}
                    onChange
                    ={enterMobile}
                    country="in"
                    localization={currentLanguageCode === 'en' ? es : ar}
                    containerStyle={{ marginTop: "15px" }}
                    searchClass="search-class"
                    searchStyle={{ margin: "0", width: "97%", height: "30px" }}
                    enableSearch
                    disableSearchIcon
                  />{" "}
                </div>
                <input
                  type="hidden"
                  id="country_code"
                  name="country_code"
                  value=""
                /> */}
                <div className="loader">
                  <button
                    type="submit"
                    className="btn btn-primary w-full disabled createOtpSubmit"
                    disabled="disabled"
                  >
                    {t('continue_btn')}
                  </button>
                  <div
                    className="spinner-border text-dark mobileSubmitLoader"
                    role="status"
                    style={displayContent.displayNone}
                  >
                    {" "}
                    <span className="sr-only">Loading...</span>{" "}
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        {/*  OTP Modal */}
        <Modal
          show={showOtpModal}
          onHide={handleOtpModalClose}         
          id="otpVerify"
          keyboard={false}
         >
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              {t('verify_phone_number')}<i className="flag flag-us"></i>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleFormOtpSubmit}>
              <div className="">
                <label>{t('enter_otp_code_text')}</label>
                <p className="phone-number mb-4 phoneNumberDir"></p>
                <input
                  type="text"
                  id="otp"
                  className="form-control"
                  placeholder=""
                  maxLength="4"
                  onKeyUp={typeOtp}
                />
                <p className="mb-0 py-3 resentOtpTime ">
                  {t('resend_code_in')}:
                  <p className="startTimer">00:59</p>
                </p>
                <p
                  className="text-danger resendOtpError"
                  style={displayContent.displayNone}
                ></p>
                <p
                  className="otpSent text-success"
                  style={displayContent.displayNone}
                ></p>
                <p
                  className="btn btn-light resend-otp"
                  onClick={resendOtpSend}
                  style={displayContent.displayNone}
                >
                  {t('resend_code_btn')}
                </p>
                <div className="loader">
                    <button type="submit" className="btn btn-primary w-full disabled otpContinueBtn" disabled="">{t('continue_btn')}</button>
                  <div
                    className="spinner-border text-dark "
                    role="status"
                    style={displayContent.displayNone}
                  >
                    {" "}
                    <span className="sr-only">Loading...</span>{" "}
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        {/*  Info Modal */}
        <Modal
          show={showInfoModal}
          onHide={handleInfoModalClose}
          className="addAddressModal"
          id="addAddress"
        >
          <Modal.Header closeButton>
            <Modal.Title>{t('search_your_location')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleCustomerSubmit} id="profileSetupForm">
              <div className="modal-body">
                <div className="mapContent">
                  <div className="inputdiv col-lg-12 mb-0 relative ">
                    <input
                      type="hidden"
                      id="latitude"
                      name="latitude"
                      value=""
                    />
                    <input
                      type="hidden"
                      id="longitude"
                      name="longitude"
                      value=""
                    />
                    <input
                      type="hidden"
                      id="country_code"
                      name="country_code"
                      value=""
                    />
                    <input type="hidden" id="mobile" name="mobile" value="" />
                    <input type="hidden" id="country" name="country" value="" />{" "}
                  </div>
                  <div className="allInputDiv col-lg-12 mb-0 mapToogleDiv addressDetailsHide">
                    <div className="mapToggle toast hide">
                      <button
                        type="button"
                        className="btnclose"
                        data-bs-dismiss="toast"
                      >
                        {" "}
                        <span className="fa fa-angle-down"></span>{" "}
                      </button>
                      <label>{t('address_details')}</label>
                      <div className="inputdiv formError">
                        <input
                          type="text"
                          className="form-control pl-0"
                          placeholder={t('address_details')}
                          name="addressDetails"
                          id="addressDetails"
                        />
                        <div className="help-block"></div>
                      </div>
                      <div className="row mt-4">
                        <label>{t('your_name')}</label>
                        <div className=" inputdiv col-lg-5">
                          <select
                            className="form-select pl-0 genderSelect"
                            defaultValue={"Mr"}
                            name="gender"
                            id="gender"
                          >
                            <option value="Mr.">{t('gender_mr')}</option>
                            <option value="Ms.">{t('gender_ms')}</option>
                            <option value="Rather Not Say">{t('gender_rather_not_say')}</option>
                          </select>
                        </div>
                        <div className="inputdiv col-lg-7 formError">
                          <input
                            type="text"
                            className="form-control pl-0"
                            placeholder={t('your_full_name')}
                            name="name"
                            id="name"
                          />
                          <div className="help-block"></div>
                        </div>
                      </div>
                      <div className="inputdiv col-lg-12 mt-4 formError">
                        <label>{t('email')}</label>
                        <input
                          type="text"
                          className="form-control pl-0"
                          placeholder={t('email')}
                          name="email"
                          id="email"
                        />
                        <div className="help-block"></div>
                      </div>
                    </div>
                    <button className="btn btn-primary addressSubmit">
                    {t('continue_btn')}
                    </button>
                  </div>
                </div>
                <div className="divMap">
                  <LocationSearchModal translatedValue={t} saveContinue={true}/>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <ModifiedToastContainer />
      </>
    );
  };
  
  export default LoginModal;
  