import React, { useState, useEffect } from "react";
import {Link} from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Modal from "../../components/Modal";
import Loader from "../../components/Loader";
import {useDispatch, useSelector} from "react-redux";
import { getFreeCreditAction } from "../../actions/FreeCreditAction";
import { useTranslation } from 'react-i18next';

const FreeCredit = () => {
  const getFreeCrDataState = useSelector(state => state.getFreeCreditReducer)
  const { loading, free_cr_data } = getFreeCrDataState;
  const getAllServicesState = useSelector((state) => state.homeReducer);
  const { services } = getAllServicesState;
  const dispatch = useDispatch();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let listUrl = baseUrl + "/freeCredit/list";
  const currentLanguage = localStorage.getItem('currentLanguage') ? localStorage.getItem('currentLanguage') : 'en';
  useEffect(() => {
      dispatch(getFreeCreditAction(listUrl,{}));
  }, [listUrl]);
  const [serviceDetailId, setServiceDetailId] = useState('');

  useEffect(() => {
    if(services?.length > 0){
      services.map((service, index) => {
        if(service.ui_type === "Frequency"){
          setServiceDetailId(service.id)
          localStorage.setItem('frequencyTypeServiceDetailId',serviceDetailId);
        }
      })
    }
  });

  const { t } = useTranslation();
  const currentUser = (localStorage.getItem('currentUser') && localStorage.getItem('currentUser') !== "null") ? JSON.parse(localStorage.getItem('currentUser')) : false;
  const shareLink = process.env.REACT_APP_BASE_URL+"/deeplink?voucher="+currentUser.referral_code;
  const [copied, setCopied] = useState("Share");
  const copyLink = () => {
      setCopied("Copied!");
      setTimeout( () => {
        setCopied("Share");
      }, 1000)
     navigator.clipboard.writeText(shareLink);
  };  
  const btnText = copied!="Share" ? t('copied') : t('share');
  return (
    <>
      <Header />
      {loading ? <Loader /> : ""}
      <div className="py-5">
        <div className="userContainer">
          <div className="freeCredit">
            <div className="heading">
              <h2>{ t('freecredit') }</h2>
            </div>
            <div className="card">
              <div className="personalForm">
                <div className="getFreebox"> 
                  <h3>{ t('get_free_credit') }</h3> 
                  <p> 
                    { t('share_the_best_kept') }
                  </p>   
                  <h6>{(currentUser && currentUser.referral_code) ? currentUser.referral_code : 'No Referral Code'}</h6>
                  <button className="btn btn-light" onClick={() => copyLink()}>{ btnText }</button>  
                </div>
                <div className="EarnedItem">
                  <div className="creditEarned"> 
                    <p>{ t('total_credits_earned') }</p>
                    <h3>QAR { free_cr_data?.totalCreditsEarned }</h3>
                  </div>
                  <div className="creditEarned">
                    <p>{ t('credits_remaining') }</p> 
                    <h3>QAR { free_cr_data?.totalCreditsRemaining }</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default FreeCredit;