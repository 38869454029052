import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import jQuery from 'jquery';
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import {useDispatch, useSelector} from "react-redux";
import { getSubscriptionPlanAction, postBuyPackageAction } from "../../actions/subscriptionPlanAction";
import {createUpdateQnbCustomer} from "../../helpers/helpers";
import {ModifiedToastContainer} from "../../components/ModifiedToastContainer";
import {customerCreditCardListsAction} from "../../actions/customerDetails";

const CreditPackagesPayment = () => {
    const [buyPackage, setBuyPackage] = useState({
        subscription_id:localStorage.getItem('subscriptionId'),
        qnb_customer_id: "",
        payment_mode:"Card"
    });
    const [payByCard, setPayByCard] = useState(true);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let navigate = useNavigate(); 
    const getCustomerAllCreditCardState = useSelector(state => state.customerDetailsReducer)
    const {loading, customerCreditCards} = getCustomerAllCreditCardState;
    let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
    // let listUrl = baseUrl; 
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    const cardredirectUrl = new URL(process.env.REACT_APP_BASE_URL+"/my-account/credit-packages/payment");
    const cardToken = params.get('cardToken');
    useEffect(() => {
        if(!localStorage.getItem('subscriptionId')){
            window.location.href = "/my-account/righthandcredit";
        }
    }, []);

    useEffect(() => {
        if(cardToken){
          localStorage.setItem('cardToken', cardToken);
          createQnbCustomer(cardToken);
          params.delete('cardToken');
        }
        dispatch(customerCreditCardListsAction());
    }, [cardToken]);
    
      async function createQnbCustomer(cardToken){
        if(cardToken){
          let awaitRequestUrl = baseUrl + "/creditCard/create/qnbCustomer";
          await createUpdateQnbCustomer(awaitRequestUrl, cardToken).then((response) => {
            localStorage.setItem('currentUser', JSON.stringify(response.data.customer));
            toast.success(response.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setTimeout(() => {
              navigate(cardredirectUrl.search);
            }, 100);
          }).catch(function (error) {
            toast.error('Something went wrong. please try again.', {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }); 
          });
        }
      }

      const updatePackageData = (key,value) => {
        setBuyPackage((prevState) => ({
          ...prevState,
          [key]: value
        }));
      }

    async function submitPackagePayment(){
        let apiUrl = baseUrl + "/subscription/buy"; 
        dispatch(postBuyPackageAction(apiUrl,buyPackage,getCustomerAllCreditCardState.loading));
    }

    useEffect(() => {
        updatePackageData("qnb_customer_id",customerCreditCards?.customer?.id);
    }, [customerCreditCards]);

    
    useEffect(() => {
        dispatch(customerCreditCardListsAction(""));
    }, []);


    jQuery('.payByCard').on('click', function(event) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    });

  let script = document.createElement('script');
  script.src = "https://www.simplify.com/commerce/simplify.pay.js";
  document.getElementsByTagName('head')[0].appendChild(script);
  return (
    <>
      <Header />
      {loading ? <Loader /> : ""}
      <ModifiedToastContainer />
      <div className="py-5">
        <div className="userContainer">
          <div className="creditPackage">
            <div className="heading">
              <h2>Credit Package Payment</h2> 
            </div>
            <div className="card">
                <div className="stepsCard">
                    <div className="checkboxSec">
                        <div className="checkboxItem">
                            <div className="form-check">
                             
                            {(customerCreditCards && customerCreditCards !== null) ? 
                            (<div><input
                                type="checkbox"
                                className="form-check-input payByCard"
                                id="radio3"
                                name="optradio"
                                defaultChecked={true}
                            />
                            <label className="form-check-label" htmlFor="radio3">
                                {t('pay_by_cc')} ...{customerCreditCards.last4}
                            </label></div>) : ''
                            }
                            </div>
                            <div className="cardsec">
                            <h3>
                            {t('add_new_card')}
                            <button
                                className="finalPaymentButton"
                                data-sc-key={process.env.REACT_APP_QNB_PUBLIC_KEY} 
                                data-name="Right Hands"
                                data-description="Complete peace of mind" 
                                data-receipt="true" 
                                data-logo="https://dvdljkkxpxqo3.cloudfront.net/images/logo.png.webp" 
                                data-operation="create.token" 
                                id="sc_btn_1649745021579"
                                data-redirect-url={process.env.REACT_APP_BASE_URL+"/my-account/credit-packages/payment"}
                                type="button">
                                <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/plus2.png.webp" />
                            </button>
                            </h3>
                            </div>
                        </div>
                        {(() => {
                          if (payByCard && customerCreditCards && customerCreditCards !== null) {
                            return (<button className="btn btn-primary w-full buySubSubmit" onClick={() => submitPackagePayment()}>Pay</button>);
                          }
                        })()}
                    </div>
                </div>
            </div>
            </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CreditPackagesPayment;
