import {useEffect, useState} from "react";
import {
  BuyMembership,
  stepTwoBooking
} from "../../../../helpers/bookingAPIsteps";
import "./styles.css";
import {useNavigate} from "react-router-dom";
import {buyMemberShipAction} from "../../../../actions/buyMemberShipAction";
import {useDispatch} from "react-redux";
import {extendMemberShipAction} from "../../../../actions/extendMembershipAction";
import {toast} from "react-toastify";
import {rescheduleAppointmentAction} from "../../../../actions/rescheduleAppointmentAction";
import {postProfessionalReviewAction} from "../../../../actions/professionalDetailsAction";
import {useTranslation} from "react-i18next";

const SuccessFullPayment = ({
  checkoutData,
  setBookingData,
  setBookingSuccessModal
}) => {
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [isTransactionFailed, setIsTransactionFailed] = useState(false);
  // const getRHClubState = useSelector((state) => state.rhClubReducer);
  // const {rh_club} = getRHClubState;
  // console.log("customerMembership", rh_club)
  const handelBuyBooking = async (id) => {
    // const storedCheckoutDataString = localStorage.getItem('checkoutData');
    // let checkoutData = JSON.parse(storedCheckoutDataString);
    const data = {
      appointment_id: checkoutData?.appointment_id,
      appointment_process: 5,
      discount_type: checkoutData?.discount_type
        ? checkoutData?.discount_type
        : "",
      payment_mode: checkoutData?.payment_mode,
      pay_type: checkoutData?.pay_type,
      voucher: checkoutData?.voucher ? checkoutData?.voucher : "",
      promo_code: checkoutData?.promo_code ? checkoutData?.promo_code : "",
      source_id: checkoutData?.source_id ? checkoutData?.source_id : "",
      customer_id: checkoutData.customer_id
        ? checkoutData.customer_id
        : currentUser.id,
      country_id: checkoutData?.country_id,
      is_home_cleaning: checkoutData?.is_home_cleaning ? 1 : 0,
      service_type: checkoutData?.is_home_cleaning ? "Home" : "Baby",
      isWallet: checkoutData?.isWallet,
      payment_id: id ? id : ""
    };
    try {
      const stepFiveData = await stepTwoBooking({
        baseUrl: baseUrl + "/appointment/create/payment",
        data
      });
      // console.log(stepFiveData);

      if (stepFiveData) {
        setBookingSuccessModal(true);
        setBookingData(stepFiveData);
      }
      if (
        stepFiveData?.code === 401 ||
        stepFiveData?.code === 404 ||
        stepFiveData?.code === 420 ||
        stepFiveData?.code === 400 ||
        stepFiveData?.code === 406
      ) {
        toast.error(stepFiveData?.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        navigate("/");
      }
    } catch (error) {
      // Handle errors here
      toast.error(error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      console.error("stepOneData-error", error);
    }
  };
  const [pageLoading, setPageLoading] = useState(false);
  const handelBuyMembership = async (id) => {
    let rh_club_id = localStorage.getItem("userCountryId");

    const response = await BuyMembership({
      baseUrl: baseUrl + "/customer/buy-rhclub-membership",
      data: {
        rh_club_id: rh_club_id,
        source_id: id
      }
    });
    if (response.code === 200) {
      await handelBuyBooking(id);
      return true;
    } else {
      // console.log("buy-rhclub-membership", response);
      toast.error(response?.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      navigate("/");
      return false;
    }
  };
  const handelExtendPayment = (id, extendHours, transId, isWallet) => {
    let extendMemberShipUrl =
      baseUrl + `/appointment/extend/${id}?additional_hours=${extendHours}`;

    let values = {
      additional_hours: extendHours,
      isWallet: isWallet ? 1 : 0,
      source_id: transId
    };
    dispatch(
      extendMemberShipAction(
        extendMemberShipUrl,
        values,
        setPageLoading,
        navigate,
        "paymentGateway"
      )
    );
    navigate("/my-account/appointment-details", {
      state: {apptType: localStorage.getItem("rangeFilter")}
    });
  };
  useEffect(async () => {
    // Get the current URL parameters
    const urlParams = new URLSearchParams(window.location.search);

    // Access individual parameters
    const step = urlParams.get("step");
    const id = urlParams.get("id");
    const statusId = urlParams.get("statusId");
    const status = urlParams.get("status");
    const transId = urlParams.get("transId");
    const custom1 = urlParams.get("custom1");
    const custom2 = urlParams.get("custom2");
    const custom3 = urlParams.get("custom3");
    if (status === "Failed") {
      setIsTransactionFailed(true);
    }
    // Use the retrieved parameters as needed
    if (status === "Paid" && custom1 === "appointment") {
      await handelBuyBooking(id);
    } else if (status === "Paid" && custom1 === "memberShip") {
      let rh_club_id = localStorage.getItem("userCountryId");
      // let values = {
      //   rh_club_id: rh_club_id,
      //   source_id: id ? id : ""
      // };
      const response = await BuyMembership({
        baseUrl: baseUrl + "/customer/buy-rhclub-membership",
        data: {
          rh_club_id: rh_club_id,
          source_id: id ? id : ""
        }
      });
      if (response.code === 200) {
        navigate("/my-account/righthandcredit");
      }
    } else if (status === "Paid" && custom1 === "memberShipAppointment") {
      await handelBuyMembership(id);
    } else if (status === "Paid" && custom1 === "extendAppointment") {
      let extendHours = localStorage.getItem("extended_hours");
      handelExtendPayment(transId, extendHours, id);
    } else if (status === "Paid" && custom1 === "extendAppointmentWallet") {
      let extendHours = localStorage.getItem("extended_hours");
      handelExtendPayment(transId, extendHours, id, true);
    } else if (status === "Paid" && custom1 === "Reschedule") {
      const appointmentDetails =
        localStorage.getItem("appointmentDetailsUpdateData") &&
        localStorage.getItem("appointmentDetailsUpdateData") !== "null"
          ? JSON.parse(localStorage.getItem("appointmentDetailsUpdateData"))
          : false;
      const paymentData = {
        appointment_date_start: appointmentDetails?.appointment_date_start,
        start_time_between: appointmentDetails?.start_time_between,
        source_id: id,
        payment_id: "",
        payment_mode: "card"
      };
      dispatch(
        rescheduleAppointmentAction(
          appointmentDetails?.apiEndPoint,
          paymentData
        )
      );
    } else if (status === "Paid" && custom1 === "Tips") {
      const appointmentDetails =
        localStorage.getItem("appointmentDetailsUpdateData") &&
        localStorage.getItem("appointmentDetailsUpdateData") !== "null"
          ? JSON.parse(localStorage.getItem("appointmentDetailsUpdateData"))
          : false;
      const paymentData = {
        appointment_id: appointmentDetails?.appointment_id,
        customer_id: currentUser.id,
        ["rating_point"]: appointmentDetails.rating_point,
        ["review"]: appointmentDetails.review,
        ["cleaner_id"]: appointmentDetails.cleaner_id,
        ["addToFav"]: appointmentDetails.addToFav
      };
      dispatch(
        postProfessionalReviewAction(
          appointmentDetails?.apiEndPoint,
          paymentData
        )
      );
    } else if (custom1 === "ios") {
      window.location.replace(
        "Com.righthand://checkout/details?step=6&id=31a5b320-87b9-4f47-87f0-f4057295106e&statusId=2&status=Paid&transId=jbjgjhg&custom1=payment"
      );
    }
  }, []);
  return (
    <div className="flex-container">
      {isTransactionFailed ? (
        <>
          <div className="card">
            <XCircleIcon className="check-icon" />
            <h1 className="title"> {t("payment_fail")}</h1>
            <p className="message">{t("payment_fail_msg")}</p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <button
                className="btn btn-primary"
                style={{width: 120}}
                onClick={() => navigate("/")}
              >
                {t("home")}
              </button>
            </div>

            {/* <div className="link">Please check your email for further details.</div> */}
          </div>
        </>
      ) : (
        <>
          <div className="card">
            <CheckIcon className="check-icon" />
            <h1 className="title"> {t("payment_success")}</h1>
            <p className="message">{t("payment_success_msg")}</p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <button
                className="btn btn-primary"
                style={{width: 120}}
                onClick={() => navigate("/")}
              >
                {t("home")}
              </button>
            </div>

            {/* <div className="link">Please check your email for further details.</div> */}
          </div>
        </>
      )}
    </div>
  );
};
export default SuccessFullPayment;
function CheckIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="20 6 9 17 4 12" />
    </svg>
  );
}

function XCircleIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="red"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <path d="m15 9-6 6" />
      <path d="m9 9 6 6" />
    </svg>
  )
}

