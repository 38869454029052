import React, { useEffect,useState } from "react";
import {Link} from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import {getRhPolicyDetails} from '../helpers/helpers';
import { useTranslation } from 'react-i18next';
import AppDownload from "./AppDownload";

const RHClubPolicyDetails = () => {
    const[rhPolicy,setRhPolicy] = useState('');
    const { t } = useTranslation();
    let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
    const currentLanguage = localStorage.getItem('currentLanguage') ? localStorage.getItem('currentLanguage') : 'en';
    let listUrl =`${baseUrl}/staticPage/info/rhClubDetail`;
    async function rhClubPolicyDetails(){
        await getRhPolicyDetails(listUrl,{})
            .then((res) => {
            setRhPolicy(res.data.staticContent)
            }).catch((err) => {
            console.log("error: ", err);
        })
    }

    useEffect(()=>{
        rhClubPolicyDetails()
    })

    return (
        <>
          <Header />
          <div className="container">
          <div className="card">
            {rhPolicy ?<div className="accordion-body" dangerouslySetInnerHTML={ { __html: (currentLanguage === "en" ? rhPolicy.content : rhPolicy.content_arabic)}}>
            </div>
            : <div className="accordion-body content_not_found"><div className="col-12"><h6 className="text-center content_not_found_text">{ t('content_not_found') }</h6></div></div>}
          </div>
          </div>
          <AppDownload />
          <Footer />
    </>
    )
}
export default RHClubPolicyDetails;