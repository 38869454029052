/* eslint-disable no-undef */
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle
} from "react";
import {ModifiedToastContainer} from "../../../components/ModifiedToastContainer";
import {saveForm} from "../../../helpers/helpers";
import {toast} from "react-toastify";
import LocationSearchModal from "../../../components/LocationSearchModal";
import {useDispatch, useSelector} from "react-redux";
import jQuery from "jquery";
import Modal from "react-bootstrap/Modal";
import {useTranslation} from "react-i18next";
import Loader from "../../../components/Loader";
import {countryListAction} from "../../../actions/userActions";
import {customerAddressListsAction} from "../../../actions/customerDetails";

import ReactPhoneInput from "react-phone-input-2";
import es from "react-phone-input-2/lang/es.json";
import ar from "react-phone-input-2/lang/ar.json";
import {saveAddressModalData} from "../../../actions/SaveAddressAction";

const AddressAddModal = forwardRef((props, ref) => {
  const [newCustomerAddress, setNewCustomerAddress] = useState();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [showAddresAddModal, setShowAddressAddModal] = useState(false);
  const handleAddressAddModalShow = () => setShowAddressAddModal(true);
  const handleAddressAddModalClose = () => setShowAddressAddModal(false);
  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  const currentLanguageCode = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  let customer_id = currentUser._id;
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  const userCountryCode = localStorage.getItem("userCountryCode")
    ? localStorage.getItem("userCountryCode")
    : "en";
  const getAllCountryState = useSelector((state) => state.countryReducer);
  const {
    loading,
    country_list,
    success: getAllCountryStateSuceess
  } = getAllCountryState;
  let countryListUrl = baseUrl + "/country/list";
  let addressListUrl = baseUrl + "/customer/address/list";
  const [addressDetails, setAddressDetails] = useState({});
  const [formData, setFormData] = useState({
    address: "",
    area: "",
    building: "",
    city: "",
    state: "",
    street: ""
  });

  const [error, setError] = useState({
    address_type: "",
    street: " ",
    floor: "",
    house_no: ""
  });
  const [addError, setAdderror] = useState({
    address_type: "",
    street: " ",
    floor: "",
    house_no: ""
  });
  const [countryListId, setCountryListId] = useState("");

  useEffect(() => {
    let ids =
      country_list?.length > 0 &&
      country_list?.map((obj) => {
        return {id: obj.id};
      });
    setCountryListId(ids);
  }, [loading, getAllCountryStateSuceess]);

  useEffect(() => {
    dispatch(countryListAction(countryListUrl, {}));
  }, [countryListUrl]);
  const country_arr = [];
  for (var i = 0; i < country_list?.length; i++) {
    if (country_list[i]?.isActive) {
      country_arr.push(country_list[i]?.name);
    }
  }

  const onChangeValidation = (e) => {
    // const {name} = e.target;
    setFormData({...formData, ["area"]: e.target.value});
  };

  const validation = (
    address_type,
    street,
    floor,
    house_no,
    office_no,
    appartment_no
  ) => {
    let isValid = true;
    const error = {};
    if (props.isLoggedIn === false) {
      return isValid;
    } else if (address_type === "" || address_type === "Address Type") {
      setAdderror({
        ...addError,
        ["address_type"]: t("no_address_found")
      });
      // error.address_type = "Please Insert Address Type ";
      isValid = false;
    } else if (street === "") {
      setAdderror({
        ...addError,
        ["street"]: t("street-Error"),
        ["address_type"]: ""
      });
      // error.street = " ";
      isValid = false;
    } else if (floor === "") {
      setAdderror({
        ...addError,
        ["floor"]: t("floor_error"),
        ["street"]: ""
      });
      // error.floor = "Please Insert floor";
      isValid = false;
    } else if (house_no === "" || office_no === "" || appartment_no === "") {
      setAdderror({
        ...addError,
        ["house_no"]: t("house_error"),
        ["floor"]: ""
      });
      error.house_no = t("house_error");
      isValid = false;
    } else {
      setAdderror({
        ...addError,
        ["house_no"]: "",
        ["address_type"]: "",
        ["floor"]: "",
        ["street"]: ""
      });
    }
    return isValid;
  };

  const getUserCoordinates = (geolocationAPI) => {
    // console.log("geolocationAPI", geolocationAPI);
    if (!geolocationAPI) {
      alert("Geolocation API is not available in your browser!");
    } else {
      navigator?.geolocation?.getCurrentPosition(
        (position) => {
          const {coords} = position;
          return coords;
        },
        (error) => {
          alert("Something went wrong getting your position!");
        }
      );
    }
  };
  function setAutoFillAddress(address) {
    setAddressDetails(address);
    // console.log("setAutoFillAddress", address);
    setFormData({
      ...formData,
      ["address"]: address?.address ? address?.address : "",
      ["area"]: address?.area ? address?.area : "",
      ["building"]: address?.building ? address?.building : "",
      ["city"]: address?.city ? address?.city : "",
      ["state"]: address?.state ? address?.state : "",
      ["street"]: address?.street ? address?.street : "",
      ["latitude"]: address?.latitude ? address?.latitude : "",
      ["longitude"]: address?.longitude ? address?.longitude : ""
    });
  }
  const bookalidation = (
    address_type,
    street,
    floor,
    house_no,
    office_no,
    appartment_no
  ) => {
    const error = {};
    if (address_type === "") {
      error.address_type = t("no_address_found");
    } else if (street === "") {
      error.street = t("street-Error");
    } else if (floor === "") {
      error.floor = t("floor_error");
    } else if (house_no === "" || office_no === "" || appartment_no === "") {
      error.house_no = t("house_error");
    }
    return error;
  };
  const onChange = (event) => {
    const value = event.target.value;
    const {name} = event.target;
    // let address = jQuery("#address").val();
    // let country = jQuery("#country").val();
    // let latitude = jQuery("#latitude").val();
    // let longitude = jQuery("#longitude").val();
    // let country_id = jQuery("#country_id").val();
    // let nick_name = jQuery("#address_nickname").val();
    // let area = jQuery("#area").val();
    // let address_type = jQuery("#address_type").val();
    // let street = jQuery("#street").val();
    // let addressDetails = jQuery("#addressDetails").val();

    // let building = jQuery("#building").val();
    // let floor = jQuery("#floor").val();
    // let country_code = jQuery("#country_code").val();
    // let mobile = jQuery("#mobile").val();
    // let landline = jQuery("#landline_number").val();

    // setTimeout(() => {
    //   jQuery("#address").val(address);
    //   jQuery("#country").val(country);
    //   jQuery("#latitude").val(latitude);
    //   jQuery("#longitude").val(longitude);
    //   jQuery("#address_nickname").val(nick_name);
    //   jQuery("#area").val(area);
    //   jQuery("#address_type").val(address_type);
    //   jQuery("#street").val(street);
    //   jQuery("#addressDetails").val(addressDetails);

    //   jQuery("#building").val(building);
    //   jQuery("#floor").val(floor);
    //   jQuery("#country_code").val(country_code);
    //   jQuery("#mobile").val(mobile);
    //   jQuery("#landline_number").val(landline);
    // }, 1000);
    let inputName = "house_no";
    let inputPlaceholder = t("home");

    if (value === "Apartment") {
      inputName = "apartment_no";
      inputPlaceholder = t("apartment");
    } else if (value === "Office") {
      inputName = "office_no";
      inputPlaceholder = t("office");
    }

    inputPlaceholder = inputPlaceholder + " " + t("number");
    setFormData({...formData, [name]: event.target.value});
    jQuery("#home_number").attr({
      name: inputName,
      placeholder: inputPlaceholder
    });
  };
  const handleCustomerSubmit = async (e) => {
   
    e.preventDefault();
    let contry = jQuery("#country").val();
    let addr = jQuery("#address").val();
    let lat = jQuery("#latitude")?.val()
      ? jQuery("#latitude").val()
      : addr
      ? getUserCoordinates(addr)?.latitude
      : "";
    let long = jQuery("#longitude").val()
      ? jQuery("#longitude").val()
      : addr
      ? getUserCoordinates(addr)?.longitude
      : "";
    let country_id = jQuery("#country_id").val();

    if (formData?.address === "") {
      toast.error(t("drag_no_address"), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      // setError(...errors, {[address_type]: "Please Insert Address Type"});
      const isValid = validation(
        formData?.address_type,
        formData?.street,
        formData?.floor,
        formData?.house_no,
        formData?.office_no,
        formData?.appartment_no
      );
      if (!isValid) {
        setShowAddressAddModal(true);
      }
    } else if (
      !formData?.address_type ||
      !formData.area ||
      !formData?.street ||
      !formData.building ||
      !formData.floor ||
      !formData.mobile
    ) {
      const isValid = validation(
        formData?.address_type,
        formData?.street,
        formData?.floor,
        formData?.house_no,
        formData?.office_no,
        formData?.appartment_no
      );
      if (!isValid) {
        setShowAddressAddModal(true);
      } else {
        const obj = {
          country_id: country_id,
          area: formData?.area,
          address_type: formData?.address_type,
          addressDetails: formData?.address,
          country_code: country_code,
          contry: contry,
          addr: formData?.address,
          lat: lat,
          long: long
        };
        setTimeout(() => {
          jQuery("#address").val(addr);
          jQuery("#country").val(contry);
          jQuery("#latitude").val(lat);
          jQuery("#longitude").val(long);
          jQuery("#country_id").val(country_id);
          jQuery("#area").val(area);
          jQuery("#address_type").val(address_type);
          jQuery("#street").val(street);
          jQuery("#addressDetails").val(addressDetails);
          jQuery("#building").val(building);
          jQuery("#floor").val(floor);
          jQuery("#country_code").val(country_code);
          // jQuery("#mobile").val(mobile);
          // jQuery("#landline_number").val(landline);
          // jQuery("#home_number").val(house_no);
          // jQuery("#office_no").val(office_no);
          // jQuery("#appartment_no").val(appartment_no);
          // jQuery("#landline_number").val(landline);
        }, 1000);
        var message = "";
        if (customer_id) {
          let awaitUrl = baseUrl + "/customer/address/create";
          let buldingNo =
            formData?.address_type === "Home"
              ? "house_no"
              : formData?.address_type === "Apartment"
              ? "apartment_no"
              : "office_no";

          let frm = {
            customer_id: customer_id ? customer_id : "",
            address_nickname: formData?.address_nickname
              ? formData?.address_nickname
              : "",
            area: formData?.area ? formData?.area : "",
            address_type: formData?.address_type,
            street: formData?.street ? formData?.street : "",
            building: formData?.building ? formData?.building : "",
            country_id:localStorage.getItem('userCountryId'),
            floor: formData?.floor ? formData?.floor : "",
            country_code: document?.getElementById('country_code').value  ? document?.getElementById('country_code').value : country_code  ? country_code : "",
            mobile: formData?.mobile ? formData?.mobile : "",
            landline_no: formData?.landline_no ? formData?.landline_no : "",
            additional_direction: formData?.additional_direction
              ? formData?.additional_direction
              : "",
            address: formData.address ? formData.address : "",
            [buldingNo]: formData.house_no,
            latitude: formData?.latitude ? formData?.latitude?.toString() : "",
            longitude: formData?.longitude
              ? formData?.longitude?.toString()
              : ""
          };

          await saveForm(awaitUrl, frm, "POST")
            .then((response) => {
              toast.success(response.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });
              const isLoggedIn =  localStorage.getItem("currentToken") &&
    localStorage.getItem("currentToken") !== "null"
      ? true
      : false
      if(isLoggedIn){
        dispatch(customerAddressListsAction(listUrl));
      }
              handleAddressAddModalClose();
              // if(response.data.status || response.data.code === 200){

              // }

              setTimeout(function () {
                localStorage.removeItem("currentLatitude");
                localStorage.removeItem("currentLongitude");
            
              }, 700);
            })
            .catch(function (error) {
              if (error.response) {
                if (error.response.status === 419) {
                  message =error.response;
                  setTimeout(function () {
                    window.location.reload();
                  }, 2000);
                }
              }
            });
          if (message !== "") {
            toast.error(message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          }
        } else {
          jQuery("#address").val(addr);
          jQuery("#latitude").val(lat);
          jQuery("#longitude").val(long);
          country_id = jQuery("#country_id").val();
          props.updateCheckoutData("address", addr !== "" ? addr : "");
          props.updateCheckoutData("latitude", lat);
          props.updateCheckoutData("longitude", long);
          props.updateCheckoutData("country_id", country_id);
          props.updateCheckoutData("address_nickname", nick_name);
          props.updateCheckoutData("area", area);
          props.updateCheckoutData("address_type", address_type);
          props.updateCheckoutData("street", street);
          props.updateCheckoutData("floor", floor);
          props.updateCheckoutData("house_no", house_no);
          props.updateCheckoutData("Office_no", office_no);
          props.updateCheckoutData("apartment_no", appartment_no);
          props.updateCheckoutData("building", building);
          props.updateCheckoutData("mobile", mobile);
          props.updateCheckoutData("isDefault", true);
          if (lat !== "") {
            handleAddressAddModalClose();
            setShowAddressAddModal(false);
          }
        }
        dispatch(saveAddressModalData(obj));
      }
    }

    // let awaitMethod = jQuery("#method").val();
    // let objError = bookalidation(
    //   address_type,
    //   street,
    //   floor,
    //   house_no,
    //   office_no
    // );
    // if (isObjectEmpty(objError)) {
    //   var message = "";
    //   let countryId;
    //   let idOfCountry;
    //   // if (
    //   //   idOfCountry ||
    //   //   countryId?._id === localStorage.getItem("userCountryId")
    //   // ) {
    //   console.log(
    //     "localStorage.getItemuserCountryId",
    //     localStorage.getItem("userCountryId")
    //   );

    // }

    // else {
    //   setError(true);
    // }
  };

  async function handleEditCustomerAddress(address) {
    handleAddressAddModalShow();
    setTimeout(function () {
      let latitude = address.latitude ? address.latitude : "";
      let longitude = address.longitude ? address.longitude : "";
      let address_type = address.address_type ? address.address_type : "";
      let address_type_no = address?.house_no ? address.house_no : "";

      localStorage.setItem("currentLatitude", latitude);
      localStorage.setItem("currentLongitude", longitude);

      let inputName = "house_no";
      let inputPlaceholder = t("home");

      if (address_type === "Apartment") {
        inputName = "apartment_no";
        inputPlaceholder = t("apartment");
        address_type_no = address?.apartment_no ? address.apartment_no : "";
      } else if (address_type === "Office") {
        inputName = "office_no";
        inputPlaceholder = t("office");
        address_type_no = address?.Office_no ? address.Office_no : "";
      }

      inputPlaceholder = inputPlaceholder + " " + t("number");

      jQuery("#home_number").attr({
        name: inputName,
        placeholder: inputPlaceholder,
        value: address_type_no
      });

      jQuery("#address").val(address?.address ? address.address : "");
      jQuery("#method").val("put");
      jQuery("#_id").val(address._id ? address._id : "");
      jQuery("#address_nickname").val(
        address?.address_nickname ? address.address_nickname : ""
      );
      jQuery("#area").val(address?.area ? address.area : "");
      jQuery("#address_type").val(address_type);
      jQuery("#street").val(address?.street ? address.street : "");
      jQuery("#addressDetails").val(
        address?.addressDetails ? address.addressDetails : ""
      );
      jQuery("#latitude").val(latitude);
      jQuery("#longitude").val(longitude);
      jQuery("#building").val(address?.building ? address.building : "");
      jQuery("#floor").val(address?.floor ? address.floor : "");
      jQuery("#country_code").val(
        address?.country_code ? address.country_code : ""
      );
      jQuery("#mobile").val(address?.mobile ? address.mobile : "");
      jQuery("#landline_number").val(
        address?.landline_number ? address.landline_number : ""
      );
      jQuery("#home_number").val(
        address?.home_number ? address.home_number : ""
      );
      jQuery(".addressSubmit").html(t("save_continue"));
      jQuery(".mapToogleDiv").removeClass("addressDetailsHide");
      jQuery(".mapToggle").removeClass("hide");
      jQuery(".mapToggle").addClass("show");
    }, 1000);
  }

  async function addNewCustomerAddress() {
    localStorage.removeItem("currentLatitude");
    localStorage.removeItem("currentLongitude");
    handleAddressAddModalShow();
    jQuery("#customer_id").val(customer_id);
    jQuery("#address").val("");
    jQuery("#method").val("post");
    jQuery("#_id").val("");
    jQuery(".addressSubmit").html("Continue");
    jQuery(".mapToogleDiv").addClass("addressDetailsHide");
    jQuery(".mapToggle").addClass("hide");
    jQuery(".mapToggle").removeClass("show");

    jQuery("#country_id").val();
    // jQuery("#country_id").val(localStorage.getItem("userCountryId"))
  }

  useImperativeHandle(
    ref,
    () => ({
      handleAddress(address = null) {
        if (address) {
          // console.error(address);
          handleEditCustomerAddress(address);
        } else {
          addNewCustomerAddress();
        }
      }
    }),
    [props]
  );

  return (
    <div>
      {/* {loading ? <Loader /> : ""} */}
      <ModifiedToastContainer />
      {/*  Address Modal */}
      <Modal
        show={showAddresAddModal}
        onHide={handleAddressAddModalClose}
        id="addAddress"
        className="addAddressModal"
      >
        <Modal.Header closeButton>
          <Modal.Title> {t("your_location")} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="addressform">
            <input type="hidden" id="_id" name="_id" value="" />
            <input type="hidden" id="method" name="method" value="post" />
            <input type="hidden" id="country" name="country" value="" />
            <input
              type="hidden"
              id="country_id"
              name="country_id"
              value={localStorage.getItem("userCountryId")}
            />
            <input
              type="hidden"
              id="country_short_name"
              name="country_short_name"
              value=""
            />
            <div className="modal-body">
              <div className="mapContent">
                <div className="col-lg-12 mb-0 relative ">
                  <input type="hidden" id="latitude" name="latitude" value="" />
                  <input
                    type="hidden"
                    id="longitude"
                    name="longitude"
                    value=""
                  />
                </div>
                <div className="address_input_div col-lg-12 mb-0 mapToogleDiv addressDetailsHide">
                  <div className="mapToggle toast hide">
                    <button
                      type="button"
                      className="btnclose"
                      data-bs-dismiss="toast"
                    >
                      <span className="fa fa-angle-down"></span>
                    </button>

                    <div className="row mt-4">
                      <div className="col-lg-12 inputdiv ">
                        <input
                          type="text"
                          className="form-control pl-0"
                          id="address_nickname"
                          name="address_nickname"
                          placeholder={t("address_nickname")}
                          value={
                            addressDetails.address !== ""
                              ? addressDetails.address
                              : ""
                          }
                        />
                        <div className="help-block"></div>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-lg-12 inputdiv ">
                        <input
                          type="text"
                          className="form-control pl-0"
                          id="area"
                          name="area"
                          placeholder={t("area")}
                          value={
                            addressDetails.area !== ""
                              ? addressDetails.area
                              : ""
                          }
                          // onChange={(e) => onChangeValidation(e)}
                        />
                        <div className="help-block"></div>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-lg-12 inputdiv ">
                        <select
                          className="form-select"
                          name="address_type"
                          id="address_type"
                          onChange={(e) => onChange(e)}
                        >
                          <option>{t("address_type")} </option>
                          <option value="Home">{t("home")} </option>
                          <option value="Apartment">{t("apartment")} </option>
                          <option value="Office">{t("office")}</option>
                        </select>
                        <div className="help-block">
                          {addError.address_type ? addError.address_type : ""}
                        </div>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-lg-6 inputdiv ">
                        <input
                          type="text"
                          className="form-control pl-0"
                          id="street"
                          name="street"
                          placeholder={t("street")}
                          value={formData?.street}
                          onChange={(e) => onChange(e)}
                        />
                        <div className="help-block">
                          {addError.street ? addError.street : ""}
                        </div>
                      </div>
                      <div className="col-lg-6 inputdiv ">
                        <input
                          type="text"
                          className="form-control pl-0"
                          id="building"
                          name="building"
                          placeholder={t("building")}
                          value={
                            formData.building !== "" ? formData.building : ""
                          }
                          onChange={(e) => onChange(e)}
                        />
                        <div className="help-block"></div>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-lg-12 inputdiv ">
                        <input
                          type="text"
                          className="form-control pl-0"
                          id="floor"
                          name="floor"
                          value={formData.floor ? formData.floor : ""}
                          placeholder={t("floor")}
                          onChange={(e) => onChange(e)}
                        />
                        <div className="help-block">
                          {addError.floor ? addError.floor : ""}
                        </div>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-lg-12 inputdiv ">
                        <input
                          type="text"
                          className="form-control pl-0"
                          id="home_number"
                          name="house_no"
                          placeholder={
                            formData.address_type === "Apartment"
                              ? `${t("apartment")} ${t("number")}`
                              : formData.address_type === "Office"
                              ? `${t("office")} ${t("number")}`
                              : `${t("house")} ${t("number")}`
                          }
                          onChange={(e) => onChange(e)}
                          value={formData.house_no ? formData.house_no : ""}
                        />
                        <div className="help-block">
                          {addError.house_no ? addError.house_no : ""}
                        </div>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-lg-12 inputdiv ">
                        <input
                          type="text"
                          className="form-control pl-0"
                          placeholder={t("additional_directions")}
                          name="additional_directions"
                          onChange={(e) => onChange(e)}
                          value={
                            formData.additional_directions
                              ? formData.additional_directions
                              : ""
                          }
                        />
                        <div className="help-block"></div>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-lg-12 inputdiv ">
                        <input
                          type="text"
                          className="form-control pl-0"
                          id="landline_number"
                          name="landline_number"
                          placeholder={t("landline_number")}
                          onChange={(e) => onChange(e)}
                          value={
                            formData.landline_number
                              ? formData.landline_number
                              : ""
                          }
                        />
                        <div className="help-block"></div>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-lg-12 inputdiv ">
                        <div className="row loginMobileInput mb-2">
                          <div className="inputgroup phonecodeselect col-lg-3">
                            <ReactPhoneInput
                              inputProps={{
                                className: "login_country_code form-control",
                                name: "country_code",
                                id: "country_code",
                                autoFocus: true,
                                placeholder: t("enter_mobile_number"),
                                readOnly: "readonly"
                              }}
                              country={userCountryCode.toLocaleLowerCase()}
                              localization={
                                currentLanguageCode === "en" ? "es" : "ar"
                              }
                              countryCodeEditable={false}
                              placeholder=""
                              disableCountryCode={false}
                              searchClass="search-class"
                              searchStyle={{
                                margin: "0",
                                width: "97%",
                                height: "30px"
                              }}
                              enableSearch
                              disableSearchIcon
                            />
                            <div className="help-block text-danger"></div>
                          </div>

                          <div className="inputgroup phonecodeselect col-lg-9">
                            <input
                              type="number"
                              name="mobile"
                              id="mobile"
                              className="form-control"
                              placeholder={t("enter_mobile_number")}
                              onChange={(e) => onChange(e)}
                              value={formData.mobile ? formData.mobile : ""}
                            />
                            <div className="help-block text-danger"></div>
                          </div>
                        </div>
                        <div className="help-block"></div>
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={(e) => handleCustomerSubmit(e)}
                    className="btn btn-primary addressSubmit"
                  >
                    {t("confirm")}
                  </button>
                </div>
              </div>
              <div className="divMap">
                <LocationSearchModal
                  translatedValue={t}
                  saveContinue={true}
                  getAutoFillAddress={setAutoFillAddress}
                  setNewCustomerAddress={setNewCustomerAddress}
                  from={"NewAddress"}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
});

export default AddressAddModal;
