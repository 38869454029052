/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import jQuery from "jquery";
import Modal from "react-bootstrap/Modal";
import {toast} from "react-toastify";
import {getRhValid} from "../../../../helpers/helpers";
import {ModifiedToastContainer} from "../../../../components/ModifiedToastContainer";
import {customerCreditCardListsAction} from "../../../../actions/customerDetails";
import {getFreeCreditAction} from "../../../../actions/FreeCreditAction";
import CheckoutSummary from "../components/CheckoutSummary";
import Loader from "../../../../components/Loader";
import AddCardModal from "./AddCardModal";
import PromoCode from "./PromoCode";
import MembershipComponent from "./MembershipComponent";
import StepsPayment from "./StepsPayment";
import {useCountryCurrency} from "../../../../helpers/useCountryCurrency";
import CashCreditComponent from "./CashCreditComponent";

const Payment = ({checkoutData, updateCheckoutData, setCheckoutData}) => {
  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [addVoucherModal, setAddVoucherModal] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState("");
  const [addNewCard, setAddNewCard] = useState(false);
  const [showBuyButton, setShowBuyButton] = useState(false);
  const handleAddVoucherModalShow = () => setAddVoucherModal(true);
  const handleAddVoucherModalClose = () => setAddVoucherModal(false);
  const [addServiceModal, setAddServiceModal] = useState(false);
  const handleAddServiceModalShow = () => setAddServiceModal(true);
  const handleAddServiceModalClose = () => setAddServiceModal(false);
  const getAllCountryState = useSelector((state) => state.countryReducer);
  const [freeCredit, setFreeCredit] = useState(0);
  const [showbtn, setShowBtn] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const showBtn = () => setShowBtn(!showbtn);
  const [num, setnum] = useState(1);
  const [isCashData, setIsCashData] = useState();
  const [rhCreditDetails, setRhCreditDetails] = useState([]);
  const [isVoucher, setIsVoucher] = useState(false);
  const [wallet, setWallet] = useState(0);
  const [isCashPrice, setIsCashPrice] = useState(0);
  const [checked, setChecked] = useState(false);
  const [walletStatus, setWalletStatus] = useState(false);
  const getRHClubState = useSelector((state) => state.rhClubReducer);
  const {rh_club} = getRHClubState;
  // console.log("checkoutData", rh_club);
  const handelNewCardModel = () => {
    setAddNewCard(true);
  };
  async function validRhCredit() {
    let serviceId = localStorage.getItem("serviceId");
    // console.log("paym ServiceId", serviceId)
    let data = {
      service_id:
        checkoutData?.service_id?.length > 0
          ? checkoutData?.isBookNow
            ? checkoutData?.service_id
            : checkoutData?.service_id[0]
          : checkoutData?.service_id[0]
    };
    await getRhValid(data)
      .then((res) => {
        setRhCreditDetails(res.data);
        setWallet(res?.data?.total_wallet_credits);
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  }

  useEffect(() => {
    validRhCredit();
  }, []);
  const incNum = () => {
    setnum(num + 1);
  };

  const incMin = () => {
    if (num > 0) {
      setnum(num - 1);
      setShowBtn(!showbtn);
    }
  };
  const [isBuyMemberShipChecked, setIsBuyMemberShipChecked] = useState(false);
  const [checkedValue, setCheckedValue] = useState("");

  const [promoCodeData, setPromoCodeData] = useState({
    promo_code: localStorage.getItem("couponCode")
      ? localStorage.getItem("couponCode")
      : "",
    service_id: checkoutData?.service_id
      ? checkoutData?.service_id
      : localStorage.getItem("frequencyTypeServiceDetailId")
      ? localStorage.getItem("frequencyTypeServiceDetailId")
      : checkoutData?.service_id,
    min_amount: parseInt(checkoutData?.price),
    customer_id: currentUser?._id,
    country_id: checkoutData?.country_id
      ? checkoutData?.country_id
      : localStorage.getItem("userCountryId")
  });
  useEffect(() => {
    setCheckoutData({
      ...checkoutData,
      isBuyMemberShipRadio: isBuyMemberShipChecked
    });
  }, [isBuyMemberShipChecked]);
  const handelBuyMembership = () => {
    // console.log("handelBuyMembership", checkoutData.payment_mode, selectedPayment);
    if ((checkoutData?.payment_mode === "card" || checkoutData?.payment_mode === "Card") && selectedPayment === "credit") {
      setIsBuyMemberShipChecked(!isBuyMemberShipChecked);
    } else {
      toast.error(t("error_members_purchased"), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
  };

  const updatePromoCodeData = (key, value) => {
    setPromoCodeData((prevState) => ({
      ...prevState,
      [key]: value
    }));
  };
  const getCustomerAllCreditCardState = useSelector(
    (state) => state.customerDetailsReducer
  );
  const {loading, customerCreditCards} = getCustomerAllCreditCardState;
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let listUrl = baseUrl + "/payment/instruments";
  const handleAddNewCardClose = () => {
    dispatch(customerCreditCardListsAction());
    setAddNewCard(false);
  };
  useEffect(() => {
    dispatch(customerCreditCardListsAction());
  }, [listUrl, dispatch]);

  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const getFreeCrDataState = useSelector((state) => state.getFreeCreditReducer);
  const [transaction, setTransaction] = useState("");
  const {free_cr_data} = getFreeCrDataState;
  const [count, setCount] = useState(1);
  const countryCurrency = useCountryCurrency();
  useEffect(() => {
    if (countryCurrency?.length > 0) {
      setIsCashData(countryCurrency[0]);
    }
  }, [countryCurrency]);
  let creditlistUrl = baseUrl + "/freeCredit/rh/list";
  useEffect(() => {
    localStorage.setItem("discoverBookingPage", window.location.href);
    dispatch(getFreeCreditAction(creditlistUrl, {}));
  }, [creditlistUrl]);

  useEffect(() => {
    const countryList = getAllCountryState?.country_list?.filter(
      (country) => country.id === checkoutData?.country_id
    );
  }, [getAllCountryState]);

  // let totalPrice = parseInt(checkoutData.total_per_display_amount);
  let totalRemainingSubscriptionCredits =
    rhCreditDetails?.totalRemainingRHCredits
      ? rhCreditDetails?.totalRemainingRHCredits
      : 0;

  let totalWalletAmount = rhCreditDetails.total_wallet_credits
    ? rhCreditDetails.total_wallet_credits
    : 0;
  let payment_mode = checkoutData.payment_mode;
  let isPageLoading = false;

  useEffect(() => {
    if (customerCreditCards && customerCreditCards !== null) {
      // updateCheckoutData("payment_mode", "online");
      // updateCheckoutData("pay_type", "online");
      jQuery(".useCardPayment").prop("checked", true);
    }
    jQuery(".usermycredit").prop("checked", false);
  }, [customerCreditCards]);

  useEffect(() => {
    if (customerCreditCards) {
      updateCheckoutData("source_id", customerCreditCards?.id);
    }
  }, [customerCreditCards]);

  useEffect(() => {
    if (customerCreditCards) {
      updateCheckoutData("qnb_customer_id", "qnb_customer_id");
    }
  }, [loading, customerCreditCards]);

  async function myPaymentMode(mode, status) {
    let actionSuccess = true;
    if (checkoutData?.total_per_display_amount >= 150) {
      if (mode === "PaymentByCredit" && status) {
        if (rhCreditDetails?.isRhValid === true) {
          updateCheckoutData("discount_type", "FreeCredit");
          jQuery("." + mode).prop("checked", true);
          totalRemainingSubscriptionCredits =
            totalRemainingSubscriptionCredits - 150;
          updateCheckoutData("rh_discount", 150);
          setFreeCredit(totalRemainingSubscriptionCredits);
          checkoutData.totalPrice =
            checkoutData.totalPrice - checkoutData.rh_discount;
          updateCheckoutData("totalPrice", checkoutData.totalPrice);
          updateCheckoutData("payment_mode", "online");
          updateCheckoutData("pay_type", "online");
          updateCheckoutData("rhClub", true);
        } else {
          jQuery("." + mode).prop("checked", false);
          updateCheckoutData("rhClub", rhCreditDetails?.isRhValid);
          // updateCheckoutData("discount_type", "");
        }
      }
      if (mode === "PaymentByCredit" && !status) {
        jQuery("." + mode).prop("checked", false);

        updateCheckoutData("discount_type", "");
        totalRemainingSubscriptionCredits = totalRemainingSubscriptionCredits;
        setFreeCredit(totalRemainingSubscriptionCredits);
        checkoutData.totalPrice = checkoutData.totalPrice;
        updateCheckoutData("totalPrice", checkoutData.totalPrice);
        updateCheckoutData("payment_mode", "");
        updateCheckoutData("rh_discount", 0);
        updateCheckoutData("pay_type", "");
        updateCheckoutData("rhClub", false);
        // debugger;
      }
      if (mode === "PaymentByCard" && status) {
        updateCheckoutData("payment_mode", "online");
        updateCheckoutData("pay_type", "online");
        jQuery("." + mode).prop("checked", true);
      }

      if (
        !actionSuccess &&
        jQuery(".Toastify__toast-container")[0] === undefined
      ) {
        toast.error(t("credit_balance_is_low"), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      }
    } else {
      if (mode === "PaymentByCard" && !status) {
        updateCheckoutData("payment_mode", "Card");
        jQuery("." + mode).prop("checked", true);
        actionSuccess = false;
      }
    }
    if (mode === "PaymentByWallet" && status) {
      // jQuery("." + mode).prop("checked", true);
      updateCheckoutData("isWallet", true);
      updateCheckoutData("payment_mode", "online");
      updateCheckoutData("pay_type", "online");
      updateCheckoutData("priceAmount", checkoutData.price);
      if (totalWalletAmount > checkoutData.totalPrice) {
        let deducted_amount = totalWalletAmount - checkoutData.totalPrice;
        // console.log("wallet_amount", checkoutData);
        // if(checkoutData?.holidayPrice > 0 ){
        //   deducted_amount = deducted_amount - checkoutData?.holidayPrice
        // }

        // totalWalletAmount = totalWalletAmount - checkoutData.totalPrice;
        updateCheckoutData("totalPrice", 0);
        setWallet(deducted_amount);
        setWalletStatus(true);
        updateCheckoutData("wallet_amount", checkoutData.totalPrice);

        // updateCheckoutData("totalPrice", 0);
        // updateCheckoutData("price", 0);
      } else {
        // console.log(2222);
        // console.log(checkoutData?.price, checkoutData?.totalPrice, 5555);
        let price = checkoutData.totalPrice - totalWalletAmount;
        updateCheckoutData("wallet_amount", totalWalletAmount);
        updateCheckoutData("totalPrice", price);
        // updateCheckoutData("price", checkoutData.price);
        // console.log(checkoutData?.price, checkoutData?.totalPrice, 4444);
        // totalWalletAmount = 0;
        setWallet(0);
      }
    }
    if (mode === "PaymentByWallet" && !status) {
      setWalletStatus(false);
      setWallet(totalWalletAmount);
      // updateCheckoutData("wallet_amount", 0);

      setCheckoutData((prevState) => ({
        ...prevState,
        isWallet: false,
        totalPrice:
          checkoutData?.frequency_name === "Weekly"
            ? checkoutData?.total_amount -
              checkoutData?.total_discount +
              totalWalletAmount
            : checkoutData.total_per_display_amount
      }));
      if (
        checkoutData.payment_mode !== "cod" ||
        checkoutData.payment_mode !== "card"
      ) {
        setCheckoutData((prevState) => ({
          ...prevState,
          payment_mode:
            checkedValue === "cod"
              ? "cod"
              : checkedValue === "card"
              ? "card"
              : ""
        }));
      }

      // updateCheckoutData("isWallet", false);
      // if (checkoutData.totalPrice === 0) {
      // updateCheckoutData("totalPrice", checkoutData.total_per_display_amount);
      // } else {
      //   updateCheckoutData("price", checkoutData.totalPrice);
      // }
    }
    if (mode === "PaymentByCredit" && status) {
      if (rhCreditDetails.isRhValid === true) {
        updateCheckoutData("discount_type", "FreeCredit");
        jQuery("." + mode).prop("checked", true);
        totalRemainingSubscriptionCredits =
          totalRemainingSubscriptionCredits - checkoutData.totalPrice;
        updateCheckoutData("rh_discount", checkoutData.totalPrice);
        setFreeCredit(totalRemainingSubscriptionCredits);
        updateCheckoutData("priceAmount", checkoutData.price);
        updateCheckoutData("totalPrice", 0);
        updateCheckoutData("payment_mode", "online");
        updateCheckoutData("pay_type", "online");
        updateCheckoutData("rhClub", true);
      } else {
        jQuery("." + mode).prop("checked", false);
        updateCheckoutData("rhClub", rhCreditDetails.isRhValid);
        // updateCheckoutData("discount_type", "");
      }
    }
    if (mode === "PaymentByCredit" && !status) {
      jQuery("." + mode).prop("checked", false);

      updateCheckoutData("discount_type", "");
      totalRemainingSubscriptionCredits = totalRemainingSubscriptionCredits;
      setFreeCredit(totalRemainingSubscriptionCredits);
      checkoutData.totalPrice = checkoutData.totalPrice;

      setCheckoutData((prevState) => ({
        ...prevState,
        rhClub: false,
        payment_mode:
          checkedValue === "cod"
            ? "cod"
            : checkedValue === "card"
            ? "card"
            : "",
        rh_discount: "",
        pay_type: "",
        totalPrice:
          checkoutData?.frequency_name === "Weekly"
            ? checkoutData?.total_amount -
              checkoutData?.total_discount +
              totalWalletAmount
            : checkoutData.total_per_display_amount
      }));
    }

    // setTimeout(() => {
    //   updateCheckoutData("promo_code", "");
    //   updateCheckoutData("discountPrice", 0);
    //   updateCheckoutData("voucher", "");
    // }, 2000);
  }
  // async function updateDiscountType(discount_type) {
  //   updateCheckoutData("discount_type", discount_type);
  // }

  // const handleChange = (e) => {
  //   e.preventDefault();
  //   setChecked(e.target.checked);
  //   updateCheckoutData("payment_terms_condition", e.target.checked);
  // };

  // const letterChange = (e) => {
  //   if (e.currentTarget.value === "cash") {
  //     if (isCashData?.isCash && isCashData?.price > 0) {
  //       let price = 0;
  //       price = isCashData.price * checkoutData.hours_count;
  //       updateCheckoutData("CodCharges", price);
  //       setIsCashPrice(isCashData.price * checkoutData.hours_count);
  //       setCheckedValue("cash");
  //     }
  //     setTransaction(e.currentTarget.value);
  //     updateCheckoutData("payment_mode", "cod");
  //     updateCheckoutData("pay_type", "cod");
  //   } else {
  //     // let price = checkoutData.price;
  //     // price = checkoutData.price - price;
  //     setCheckedValue("card");
  //     updateCheckoutData("CodCharges", 0);
  //     updateCheckoutData("payment_mode", "card");
  //     updateCheckoutData("pay_type", "online");
  //     // updateCheckoutData("price", price);
  //     // updateCheckoutData("totalPrice", checkoutData.total_per_display_amount);
  //     setIsCashPrice(0);
  //   }
  // };

  return (
    <>
      <ModifiedToastContainer />
      <div className="stepsContent Payment  ">
        <div className="row">
          <div className="col-lg-7 col-md-12">
            <div className="card">
              <div className="stepsCard">
                <div className="checkboxSec">
                  {/* {rhCreditDetails?.isRhValid === true ? (
                    <div className="checkboxItem">
                      <div className="form-check switchBtn">
                        <div className="switchFlex">
                          <label className="form-check-label" htmlFor="radio3">
                            <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/card.png.webp" />
                            {t("use_credit_first")}{" "}
                            <span>
                              {freeCredit > 0
                                ? freeCredit
                                : totalRemainingSubscriptionCredits}
                            </span>
                          </label>
                          <div className="useMyCredit">
                            <label className="switch">
                              <input
                                type="checkbox"
                                className="usermycredit PaymentByCredit"
                                onChange={(event) =>
                                  event.target.checked
                                    ? myPaymentMode("PaymentByCredit", true)
                                    : myPaymentMode("PaymentByCredit", false)
                                }
                                disabled={
                                  free_cr_data?.totalRemainingRHCredits === 0 ||
                                  isVoucher === true
                                }
                              />
                              <span className="switchslide round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <p>
                        <Link
                          to={"/my-account/creditdetails"}
                          target="_blank"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          {t("see_details1")}
                        </Link>
                      </p>
                    </div>
                  ) : (
                    <></>
                  )} */}
                  <StepsPayment
                    walletAmount={wallet}
                    rhCreditDetails={rhCreditDetails}
                    freeCredit={freeCredit}
                    totalRemainingSubscriptionCredits={
                      totalRemainingSubscriptionCredits
                    }
                    checkoutData={checkoutData}
                    setCheckoutData={setCheckoutData}
                    updateCheckoutData={updateCheckoutData}
                    setFreeCredit={setFreeCredit}
                    setWallet={setWallet}
                    checkedValue={checkedValue}
                    setWalletStatus={setWalletStatus}
                    setTransaction={setTransaction}
                    setCheckedValue={setCheckedValue}
                    selectedPayment={selectedPayment}
                    setSelectedPayment={setSelectedPayment}
                  />
                  {/* <div className="checkboxItem">
                    <div className="form-check switchBtn">
                      <div className="switchFlex">
                        <label className="form-check-label" htmlFor="radio3">
                          <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/card.png.webp" />
                          {t("use_wallet")} <span>{wallet.toFixed(2)}</span>
                        </label>
                        <div className="useMyCredit">
                          <label className="switch">
                            <input
                              type="checkbox"
                              className="usermycredit PaymentByWallet"
                              checked={checkoutData?.isWallet}
                              onChange={(event) =>
                                event.target.checked
                                  ? myPaymentMode("PaymentByWallet", true)
                                  : myPaymentMode("PaymentByWallet", false)
                              }
                              disabled={totalWalletAmount === 0}
                            />
                            <span className="switchslide round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <p>
                      <Link
                        to={"/my-account/see/all/history/"}
                        target="_blank"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        {t("see_details1")}
                      </Link>
                    </p>
                  </div> */}
                  <CashCreditComponent
                    rhCreditDetails={rhCreditDetails}
                    customerCreditCards={customerCreditCards}
                    checkoutData={checkoutData}
                    setCheckoutData={setCheckoutData}
                    updateCheckoutData={updateCheckoutData}
                    isCashData={isCashData}
                    setCheckedValue={setCheckedValue}
                    setIsCashPrice={setIsCashPrice}
                    handelNewCardModel={handelNewCardModel}
                    selectedPayment={selectedPayment}
                    setSelectedPayment={setSelectedPayment}
                    isBuyMemberShipChecked={isBuyMemberShipChecked}
                    handelBuyMembership={handelBuyMembership}
                    currentUser={currentUser}
                    showBuyButton={showBuyButton}
                    setIsBuyMemberShipChecked={setIsBuyMemberShipChecked}
                  />
                  {/* {!currentUser?.isRhMember && !showBuyButton && (
                    <div>
                      <div style={{display: "flex"}}>
                        <input
                          type="radio"
                          className="form-check-input"
                          checked={isBuyMemberShipChecked === true}
                          onClick={() => handelBuyMembership()}
                        />
                        <div
                          style={{
                            marginLeft: 15
                          }}
                        >
                          <label
                            style={{
                              fontSize: 18,
                              fontWeight: 400,
                              color: "#222222"
                            }}
                            className="form-check-label line-height-0"
                          >
                            {t("buy_membership")} {t("pay")}
                          </label>
                        </div>
                      </div>
                      <p>
                        <Link
                          to={"/rh-club/policy"}
                          target="_blank"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          {t("Start saving by joing the RH club")}
                        </Link>
                      </p>
                    </div>
                  )} */}

                  {/* <div className="checkboxItem payBYcredit">
                    <h3 className="mb-4">{t("pay_by_cc")}</h3>
                    {customerCreditCards ? (
                      <div className="form-check" key={customerCreditCards?.id}>
                        <input
                          type="radio"
                          id={customerCreditCards?.id}
                          className="form-check-input  PaymentByCard"
                          value="credit"
                          disabled={walletStatus}
                          name="radioButton"
                          onChange={letterChange}
                        />
                        <div>
                          <label
                            className="form-check-label"
                            htmlFor={customerCreditCards?.id}
                          >
                            ...{customerCreditCards?.last4}
                          </label>
                          <p>
                            {customerCreditCards?.expMonth +
                              "/" +
                              customerCreditCards?.expYear}
                          </p>
                        </div>
                      </div>
                    ) : (
                      t("no_Card")
                    )}
                    {!customerCreditCards && (
                      <div className="cardsec">
                        <h3 onClick={() => handelNewCardModel()}>
                          {t("add_new_card")}
                          <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/plus2.png.webp" />
                        </h3>
                      </div>
                    )}

                    {isCashData?.isCash && (
                      <>
                        <h3 className="mb-4">{t("pay_by_cash")}</h3>
                        <div className="form-check">
                          <input
                            type="radio"
                            className="form-check-input  PaymentByCard"
                            value="cash"
                            checked={transaction === "cash"}
                            name="radioButton"
                            onChange={letterChange}
                            disabled={checkoutData?.isWallet && checkoutData?.totalPrice === 0}
                          />
                          <div>
                            <label className="form-check-label line-height-0">
                             {t("buy_membership")} {t("pay")}
                              <span>
                                {" "}
                                ({t("Pay_extra_amount")} {isCashData?.currency}{" "}
                                {isCashData?.price}){" "}
                              </span>
                            </label>
                          </div>
                        </div>
                      </>
                    )}
                  </div> */}

                  {/* {!currentUser.isRhMember && !showBuyButton ? (
                    <MembershipComponent
                      rh_club={rh_club}
                      currentUser={currentUser}
                      handelNewCardModel={handelNewCardModel}
                      setShowBuyButton={setShowBuyButton}
                    />
                  ) : (
                    ""
                  )} */}
                  <PromoCode
                    checkoutData={checkoutData}
                    setCheckoutData={setCheckoutData}
                    updateCheckoutData={updateCheckoutData}
                    promoCodeData={promoCodeData}
                    updatePromoCodeData={updatePromoCodeData}
                    handleAddVoucherModalClose={handleAddVoucherModalClose}
                    handleAddVoucherModalShow={handleAddVoucherModalShow}
                    addVoucherModal={addVoucherModal}
                    setIsVoucher={setIsVoucher}
                    currentUser={currentUser}
                    getDiscount
                  />
                  <div className="checkboxItem paymentLabel ">
                    <label>
                      <input
                        type="checkbox"
                        className="me-2 "
                        checked={checked}
                        onChange={(e) => {
                          setChecked(e.target.checked);
                          updateCheckoutData(
                            "payment_terms_condition",
                            e.target.checked
                          );
                        }}
                      />
                      {t("understand_that_purchasing")}{" "}
                      <Link to={"/terms/conditions"} target="_blank">
                        {t("terms_of_use")}
                      </Link>{" "}
                      {t("and")}{" "}
                      <Link to={"/cancellation/policy"} target="_blank">
                        {t("cancellation_policy")}
                      </Link>{" "}
                      {t("my_payment_method_charged_before_service")}
                    </label>
                  </div>
                  {/* <div className="cardsec addmoreService"><Link onClick={() => handleAddServiceModalShow()}  ><img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/plus2.png.webp" className="me-2 ms-0"/>{t('add_more_services')}</Link></div> */}
                  {/* <div className="checkboxItem paymentLabel ">
                    {!currentUser.isRhMember &&
                    rhCreditDetails.isRhValid === false ? (
                      <p>
                        <Link
                          to={"/rh-club/policy"}
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          {t("Start saving by joing the RH club")}
                        </Link>
                      </p>
                    ) : (
                      ""
                    )}
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5">
            <CheckoutSummary checkoutData={checkoutData} />
          </div>
          {loading || isPageLoading ? <Loader /> : ""}
        </div>
      </div>

      <Modal
        show={addNewCard}
        onHide={() => setAddNewCard(false)}
        id="addVocher"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {t("add_new_card")}
            <i className="flag flag-us"></i>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddCardModal handleAddNewCardClose={handleAddNewCardClose} />
        </Modal.Body>
      </Modal>
      <Modal
        show={addServiceModal}
        onHide={handleAddServiceModalClose}
        id="addService"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Add More Services<i className="flag flag-us"></i>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="addMoreService">
            <div className="serviceItem px-4">
              <div data-bs-toggle="modal" data-bs-target="#ServicePopup">
                <h3>{num}Haircut</h3>
                <p>
                  Get a neat and slick cut from the comfort of your home in 30
                  minutes.
                </p>
              </div>
              <div class="amount align-items-center justify-content-between d-flex buttonadd">
                <p>
                  {checkoutData?.currency} 50:{" "}
                  <span>{checkoutData?.currency} 70</span>
                </p>
                <button
                  className={
                    showbtn
                      ? "btn btn-primary addBtn d-none"
                      : "btn btn-primary addBtn d-block"
                  }
                  onClick={showBtn}
                >
                  ADD +
                </button>
                <div
                  className={
                    showbtn
                      ? "button-with-counter d-block"
                      : "button-with-counter d-none"
                  }
                >
                  <i className="fa fa-minus" onClick={incMin}></i>
                  <span>{num}</span>
                  <i className="fa fa-plus" onClick={incNum}></i>
                </div>
              </div>
            </div>
            <div className="serviceItem px-4">
              <div data-bs-toggle="modal" data-bs-target="#ServicePopup">
                <h3>{num}Haircut</h3>
                <p>
                  Get a neat and slick cut from the comfort of your home in 30
                  minutes.
                </p>
              </div>
              <div class="amount align-items-center justify-content-between d-flex buttonadd">
                <p>
                  {checkoutData?.currency} 50:{" "}
                  <span>{checkoutData?.currency} 70</span>
                </p>
                <button className="btn btn-primary addBtn ">ADD +</button>
                {/* <div className={showbtn ? "button-with-counter d-block" : "button-with-counter d-none"} >             
                  <i className="fa fa-minus" onClick={incMin}></i>
                  <span>{num}</span>
                  <i className="fa fa-plus" onClick={incNum}></i>
                </div> */}
              </div>
            </div>
            <div className="serviceItem px-4">
              <div data-bs-toggle="modal" data-bs-target="#ServicePopup">
                <h3>{num}Haircut</h3>
                <p>
                  Get a neat and slick cut from the comfort of your home in 30
                  minutes.
                </p>
              </div>
              <div class="amount align-items-center justify-content-between d-flex buttonadd">
                <p>
                  {checkoutData?.currency} 50:{" "}
                  <span>{checkoutData?.currency} 70</span>
                </p>
                <button className="btn btn-primary addBtn ">ADD +</button>
                {/* <div className={showbtn ? "button-with-counter d-block" : "button-with-counter d-none"} >             
                  <i className="fa fa-minus" onClick={incMin}></i>
                  <span>{num}</span>
                  <i className="fa fa-plus" onClick={incNum}></i>
                </div> */}
              </div>
            </div>
            <div className="serviceItem px-4">
              <div data-bs-toggle="modal" data-bs-target="#ServicePopup">
                <h3>{num}Haircut</h3>
                <p>
                  Get a neat and slick cut from the comfort of your home in 30
                  minutes.
                </p>
              </div>
              <div class="amount align-items-center justify-content-between d-flex buttonadd">
                <p>
                  {checkoutData?.currency} 50:{" "}
                  <span>{checkoutData?.currency} 70</span>
                </p>
                <button className="btn btn-primary addBtn ">ADD +</button>
                {/* <div className={showbtn ? "button-with-counter d-block" : "button-with-counter d-none"} >             
                  <i className="fa fa-minus" onClick={incMin}></i>
                  <span>{num}</span>
                  <i className="fa fa-plus" onClick={incNum}></i>
                </div> */}
              </div>
            </div>
          </div>
          <div
            class={
              showbtn
                ? "actionButton actionBTn d-flex"
                : "actionButton actionBTn d-none"
            }
          >
            <button class=" btn-back">
              <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/back.png.webp" />
            </button>
            <div class="priceTotal">
              <p>Total</p>
              <h3>
                {checkoutData?.currency}140
                <span class="fa fa-angle-up ms-2"></span>
              </h3>
            </div>
            <button class="btn btn-primary">Next</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Payment;
