import React from 'react'
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
const RighthandGuarntee = () => {
  return (
    <>
<Header />
  <div className='guarnteePage'>
    <div className='guarnteeBanner'>
      <div className='guarnteeBannerContent'>
        <h2>The RightHand Happiness Guarantee</h2>
        <p>Your happiness is our goal. If you’re not happy, we’ll work to make it right.</p>
      </div>
    </div>
    <div className='experienceMatters py-7'>
      <div className='container'>
        <div className='experienceMattersContent'>
          <h2>Your experience matters</h2>
          <p>RightHands strives to match you with the right pro for you and your home every time. If you’re not satisfied with the quality of the service you booked and paid for
          directly on the RightHand platform, we’ll send another pro at no extra charge for your next booking.</p>
        </div>
      </div>      
    </div>
    <div className='professionalsArea py-5'>
      <div className='container'>
      <div className='row'>
        <div className='col-lg-7'>
          <div className='professionalsReview'>
           <div className='professionalbox'>
            <img alt="" src='https://dvdljkkxpxqo3.cloudfront.net/images/user-image.png.webp'/>
            <h3>Desi K. </h3>
            <h4>Serving London</h4>
            <div className='starRating'><i className='fa fa-star'></i><i className='fa fa-star'></i><i className='fa fa-star'></i><i className='fa fa-star'></i><i className='fa fa-star-half'></i> <span>4.5</span></div>
            <h4 className='py-4'>Favorite customer review:</h4>
            <p>I was so surprised to come home to a cute swan towel on my bed! Love the attention to detail.</p>
           </div>
           <div className='professionalbox'>
            <img alt="" src='https://dvdljkkxpxqo3.cloudfront.net/images/user-image.png.webp'/>
            <h3>Desi K. </h3>
            <h4>Serving London</h4>
            <div className='starRating'><i className='fa fa-star'></i><i className='fa fa-star'></i><i className='fa fa-star'></i><i className='fa fa-star'></i><i className='fa fa-star-half'></i> <span>4.5</span></div>
            <h4 className='py-4'>Favorite customer review:</h4>
            <p>I was so surprised to come home to a cute swan towel on my bed! Love the attention to detail.</p>
           </div>
          </div>
        </div>
        <div className='col-lg-5'>
        <div className='professionalsContent'>
        <h2>The professionals you want</h2>
          <p>Cleaning and handyman services booked and paid for directly through the RightHand platform are performed by <Link to="/">screened professionals</Link> who are highly rated by customers like you. And for cleaning, your favorite pro can come back again and again to clean your home just the way you like it.</p>
        </div>
        </div>
      </div>

      </div>
    </div>
    {/* <div className='experienceMatters py-7'>
      <div className='container'>
        <div className='experienceMattersContent'>
          <h2>We’ve got you covered</h2>
          <p>In the rare event of damage, Handy’s got your back. Bookings made and paid for directly on the RightHand platform are insured. <Link to="/">Learn more</Link></p>
        </div>
      </div>      
    </div> */}
    {/* <div className='easyBook py-7'>
        <div className='container'>
          <div className='heading mb-0'>
             <h2>If you’re not happy, just let us know and we’ll work to make it right.</h2>
            
             <button className="btn btn-secondary mt-3 btnLarge px-5">Contact Us</button>
          </div>
        </div>
      </div> */}
      <div className=' readyBook py-7'>
        <div className='container'>
          <div className='heading mb-0'>
             <h2>Ready to book?</h2>
            
             <button className="btn btn-primary mt-4  px-5 mx-2">Book a Cleaner</button>
             <button className="btn btn-primary mt-4 btn-outline  px-5 mx-2">Book All Services</button>
          </div>
        </div>
      </div>
  </div>
<Footer />
    </>
  )
}

export default RighthandGuarntee