import React, {useEffect} from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Modal from "../../components/Modal";
import Loader from "../../components/Loader";
import {useTranslation} from "react-i18next";
import {getWalletData} from "../../helpers/bookingAPIsteps";
import {useState} from "react";

const RighthandWallet = () => {
  // const getFreeCrDataState = useSelector(state => state.getFreeCreditReducer)
  // const { loading, free_cr_data } = getFreeCrDataState;
  // const dispatch = useDispatch();
  const [walletData, setWalletData] = useState();
  const [loading, setLoading] = useState(false);
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let listUrl = baseUrl + "/wallet/list/";
  useEffect(async () => {
    const data = await getWalletData(listUrl);
    if (data) {
      setWalletData(data);
    }
  }, [listUrl]);
  const {t} = useTranslation();
  var options = {year: "numeric", month: "long", day: "numeric"};
  // var latestExpiryDate = new Date(free_cr_data?.latestExpiryCredit?.expiry_at).toLocaleDateString([],options);

  // const getCustomerDetailState = useSelector((state) => state.customerReducer);
  // const {  customerMembership } = getCustomerDetailState;
  // let customerMembershipUrl = baseUrl+"/customer/get/profile";
  // useEffect(() => {
  //   dispatch(customerDetailAction(customerMembershipUrl));
  // }, [customerMembershipUrl,dispatch]);

  return (
    <>
      <Header />
      {loading ? <Loader /> : ""}
      <div className="py-5">
        <div className="userContainer">
          <div className="rightHandCredit">
            <div className="heading">
              <h2>{t("wallet_amount")}</h2>
            </div>
            <div className="card">
              <div className="totalCredit">
                {/* <h3>{t("available_credits")}</h3> */}
                <h2> {walletData?.totalWalletCredits.toFixed(2)}</h2>
                <h2></h2>
                {/* <Link to="/my-account/creditdetails"> */}
                {/* <h6>
                  {t("see_details1")}{" "}
                  <span className="fa fa-angle-right"></span>
                </h6> */}
                {/* </Link> */}
              </div>
              {/* <CreditPackages/> */}
              {/* { (free_cr_data?.latestExpiryCredit?.credit) ?
              <div className="creditExpiry">
                <h3>
                  <span> 
                    <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/info.png.webp" />
                  </span>
                  
                </h3>
                <p>{t('expires_on')} </p>
              </div> : ""
              } */}

              <div className="recentTranstion">
                <h3 className="mt-4">{t("recent_transtions")}</h3>
                {walletData?.list?.length > 0 ? (
                  walletData?.list.map((free_cr, index) => {
                    if (free_cr && free_cr?.details && free_cr?.type) {
                      var options = {
                        year: "numeric",
                        month: "long",
                        day: "numeric"
                      };
                      var created_at = new Date(
                        free_cr?.createdAt
                      ).toLocaleDateString([], options);
                      return (
                        <div key={index}>
                          {/* {index < 4 && */}
                          <div className="transtionList">
                            <h3>
                              {free_cr?.details}{" "}
                              <span>
                                {free_cr?.type === "Credited" ||
                                free_cr?.type === "Refunded"
                                  ? "+ " + free_cr.credit
                                  : "- " + free_cr.debit}
                              </span>
                            </h3>
                            <p>{created_at}</p>
                          </div>
                        </div>
                      );
                    }
                  })
                ) : (
                  <center>{t("no_recent_transactions")}</center>
                )}

                {/* {walletData?.list?.length > 0 ?            
                <div className="transtionList">
                  <p>            
                    <NavLink to={"/my-account/see/all/history/"} state={{free_cr_data: walletData}} className="dropdown-item">
                      {t('see_all_history')}<i className='fa fa-arrow-right px-2'></i>
                    </NavLink>
                  </p>
                </div>
                : ""}    */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal />
      <Footer />
    </>
  );
};

export default RighthandWallet;
