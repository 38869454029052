export const rhClubReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_RH_CLUB_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_RH_CLUB_SUCCESS':
            return {
                rh_club:action.payload.rh_club,
                loading: false,
                success: true
            }
        case'GET_RH_CLUB_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        default:
            return state
    }
}