import React from 'react'
import Header from "./Header";
import Footer from "./Footer";
const HomeCleaning = () => {
    return (
      <>
       <Header/>
        <div className="container">
          <div className="py-5">
            <p>
              HOME <i className="fa fa-angle-right ms-2 me-2"></i> Covid 
            </p>
            <div className="homeCleanBanner covidBanner">
              <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/covid-full.jpg.webp"/>
            </div>
            <div className="ServicePageContent">
              <h2>Righthands Measures and Response to COVID-19</h2>
              <p>
              Sed mollitia facilis et dolorem aperiam qui velit enim qui corrupti dolorem non harum possimus qui rerum voluptas. Et optio consequatur cum voluptate asperiores id galisum placeat hic magni temporibus qui quasi assumenda non suscipit eveniet non aspernatur rerum.
              Vel porro velit quo nihil amet qui dolores distinctio qui dolorum adipisci cum vitae dolores qui laborum architecto. Ut possimus expedita quo quibusdam alias ea quia temporibus. In iure itaque in optio Quis At eveniet tempore sit repudiandae quae. Ex voluptatem molestias ut eaque voluptas et iure aliquid qui facere libero?
              </p>
              <p>
              The choice of font and font size with which Lorem ipsum is reproduced answers to specific needs that go beyond the simple and simple filling of spaces dedicated to accepting real texts and allowing to have hands an advertising/publishing product, both web and paper, true to reality.
              </p>
            </div>
          </div>
        </div>
       <Footer/>
      </>
    );
}

export default HomeCleaning
