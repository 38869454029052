export const bannerReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_HOME_BANNER_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_HOME_BANNER_SUCCESS':
            return {
                home_banner:action.payload.home_banner,
                loading: false,
                success: true
            }
        case'GET_HOME_BANNER_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        default:
            return state
    }
}