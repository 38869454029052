import React from "react";
import OwlCarousel from "react-owl-carousel";
import {useTranslation} from "react-i18next";
import Loader from "../../../../components/Loader";
const ProDates = ({
  selectedSlots,
  options,
  carouselKey,
  getDayFromDate,
  handelSelectedDate,
  selectedDate,
  currentLanguage,
  isProLoad
}) => {
  const {t} = useTranslation();

  const styles = {
    color: "#fff",
    background: "#432375",
    border: "2px solid #432375"
  };
  const styles2 = {
    margin: "0 auto"
  };
  const stylesDate = {
    color: "#999",
    background: "#fff",
    border: "2px solid #999999"
  };

  const disableStyles = {
    color: "#fff",
    background: "#999999",
    border: "2px solid #999999"
  };
  const stylesDateChecked = {
    color: " #000",
    background: "#fcd500",
    border: "2px solid #fcd500"
  };

  const stylesTime = {
    background: "#432375",
    border: "2px solid #432375",
    color: "#fff"
  };
  return (
    <>
      {isProLoad ? (
        <Loader />
      ) : (
        <div className="datesec">
          <h3>{t("like_your_service")}</h3>
          <OwlCarousel
            className="owl-theme mt-4"
            loop={false}
            key={carouselKey}
            touchDrag={false}
            mouseDrag={false}
            {...options}
          >
            <>
              {selectedSlots?.map((item, index) => {
                return (
                  <div className="item" key={index}>
                    <>
                      <span className="dia_semana">
                        <label>
                          <input
                            type="radio"
                            name="booking_date"
                            defaultValue={item?.date}
                            onClick={() => handelSelectedDate(item)}
                            defaultChecked={item?.date == selectedDate}
                            disabled={!item?.isAvailable}
                            // defaultChecked={
                            //   item.date ===
                            //   checkoutData?.appointment_date?.month_day
                            // }
                          />
                          <span>
                            {currentLanguage === "en"
                              ? getDayFromDate(item)
                              : getDayFromDate(item)}
                          </span>
                          <span
                            style={
                              item?.isAvailable === false
                                ? disableStyles
                                : item?.date === selectedDate
                                ? stylesDateChecked
                                : stylesDate
                            }
                            className="dia"
                          >
                            {item?.date}
                          </span>
                        </label>
                      </span>
                      <span className="mes">{item?.currentMonth}</span>
                    </>
                  </div>
                );
              })}
            </>
          </OwlCarousel>
        </div>
      )}
    </>
  );
};

export default ProDates;
