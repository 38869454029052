import React from "react";
import { useTranslation } from 'react-i18next';

const LifeEasy = () => {
  const { t } = useTranslation();
  
  return (
    <>
      <div className="lifeEasySec py-7">
        <div className="container">
          <div className="heading">
            <h5>{ t('life_made_easy') }</h5>
            <h2>{ t('60_sec_away') }</h2>
            <p>
              { t('better_ways') } 
            </p>
          </div>

          <div className="row">
            <div className="col-lg-4 col-sm-6 col-md-4">
              <div className="lifeEasy text-center">
                <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/book.webp" />
                <h3>{ t('customise_n_book') }</h3>
                <p>
                  { t('book_desc') }
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-4">
              <div className="lifeEasy text-center">
                <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/homeshine.webp" />
                <h3>{ t('give_your_home_some_shine') }</h3>
                <p>
                  { t('give_your_home_some_shine_desc') }
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-4">
              <div className="lifeEasy text-center">
                <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/relax.webp" />
                <h3>{ t('relax_and_enjoy') }</h3>
                <p>
                  { t('relax_and_enjoy_desc') }
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LifeEasy;
