import React, { useState } from "react";
import Service from "./Service";
import Date from "./Date";
import Payment from "./Payment";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";

const Frequency = () => {
  const [page, setPage] = useState(0);
  const FormTitle = ["Service ", "Date", "Payment"];
  const PageDisplay = () => {
    if (page === 0) {
      return <Service />;
    } else if (page === 1) {
      return <Date />;
    } else if (page === 2) {
      return <Payment />;
    } 
  };






  return (
    <>
     <Header /> 
      <div className="stepsFromPage ServiceStpes ">
       
        <div className="container">
          <div className="pt-5">
            {/* <div
              className="location"
              data-bs-toggle="modal"
              data-bs-target="#selectAddress"
            >
              <p>
                Qatar <span className="fa fa-angle-down"></span>
              </p>
            </div> */}
            <div className="heading">
              <h2>Add More Service</h2>
            </div>
            {/* <SmoothScroll/> */}
            {/* <StepperB/> */}
            {/* <Mtu />  */}
           
          </div>
          {PageDisplay()}
          <div className="row servicePackage ">
            <div className="col-lg-12">
              <div className="actionButton actionBTn">
                <button
                  disabled={page == 0}
                  className=" btn-back"
                  onClick={() => {
                    setPage((currPage) => currPage - 1);
                  }}
                >
                  <img alt="" src="http://localhost:3000https://dvdljkkxpxqo3.cloudfront.net/images/back.png.webp" />
                </button>
                <div className="priceTotal">
                  <p>Total</p>
                  <h3>
                    QAR 150.00
                    <span
                      className="fa fa-angle-up ms-2"
                      data-bs-toggle="modal"
                      data-bs-target="#bookingSummary
"
                    ></span>
                  </h3>
                </div>
                <button
                  className="btn btn-primary"
                  disabled={page == 2}
                  onClick={() => {
                    setPage((currPage) => currPage + 1);
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>

        <Footer />
       
      </div>
    </>
  );
};

export default Frequency;
