import React, {useState, useEffect} from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {useTranslation} from "react-i18next";
import {useNavigate, NavLink, useLocation} from "react-router-dom";
import jQuery from "jquery";
import {toast} from "react-toastify";
import Loader from "../../components/Loader";
import {useDispatch, useSelector} from "react-redux";
import {rescheduleAppointmentAction} from "../../actions/rescheduleAppointmentAction";
import {postProfessionalReviewAction} from "../../actions/professionalDetailsAction";
import {
  createUpdateQnbCustomer,
  handlingAuthErrors,
  hasStripePayment
} from "../../helpers/helpers";
import {ModifiedToastContainer} from "../../components/ModifiedToastContainer";
import {customerCreditCardListsAction} from "../../actions/customerDetails";
import {BuyOnline} from "../../helpers/bookingAPIsteps";

const CommonCheckout = () => {
  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  const appointmentDetails =
    localStorage.getItem("appointmentDetailsUpdateData") &&
    localStorage.getItem("appointmentDetailsUpdateData") !== "null"
      ? JSON.parse(localStorage.getItem("appointmentDetailsUpdateData"))
      : false;
  let values = {
    payment_mode: "Card",
    customer_id: currentUser._id
  };
  if (appointmentDetails.type === "Reschedule") {
    values["appointment_date_start"] =
      appointmentDetails.appointment_date_start;
    values["start_time_between"] = appointmentDetails.appointment_date_start;
  } else {
    values["appointment_id"] = appointmentDetails.appointment_id;
    values["rating_point"] = appointmentDetails.rating_point;
    values["review"] = appointmentDetails.review;
    values["cleaner_id"] = appointmentDetails.cleaner_id;
    values["addToFav"] = appointmentDetails.addToFav;
  }


  const {t} = useTranslation();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const {state} = useLocation();
  const [useCard, setUseCard] = useState("");
  const getCustomerAllCreditCardState = useSelector(
    (state) => state.customerDetailsReducer
  );
  const {loading, customerCreditCards} = getCustomerAllCreditCardState;
  const getRescheduleAppointmentState = useSelector(
    (state) => state.rescheduleAppointmentReducer
  );
  const getAllCountryState = useSelector((state) => state.countryReducer);
  const {country_list} = getAllCountryState;
  const {page_loading} = getRescheduleAppointmentState;
  const getProfessionalDetailsState = useSelector(
    (state) => state.professionalDetailsReducer
  );
  const {page_loadings} = getProfessionalDetailsState;
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  // let listUrl = baseUrl + "/creditCard/get/qnbCustomer";
  // const windowUrl = window.location.search;
  // const params = new URLSearchParams(windowUrl);
  // const cardredirectUrl = new URL(
  //   process.env.REACT_APP_BASE_URL + "/my-account/credit-packages/payment"
  // );
  // const cardToken = params.get('cardToken');
  // useEffect(() => {
  //     // if(!localStorage.getItem('appointmentDetailsId') || !appointmentDetails){
  //     //     window.location.href = "/my-account/appointments";
  //     // }
  // }, []);

  // useEffect(() => {
  //     if(cardToken){
  //       localStorage.setItem('cardToken', cardToken);
  //       // createQnbCustomer(cardToken);
  //       params.delete('cardToken');
  //     }
  //     // dispatch(customerCreditCardListsAction(listUrl));
  //     console.log("listUrl",listUrl)
  // }, [cardToken,listUrl]);

  // async function createQnbCustomer(cardToken){
  //   if(cardToken){
  //     let awaitRequestUrl = baseUrl + "/creditCard/create/qnbCustomer";
  //     await createUpdateQnbCustomer(awaitRequestUrl, cardToken).then((response) => {
  //       localStorage.setItem('currentUser', JSON.stringify(response.data.customer));
  //       toast.success(response.data.message, {
  //         position: "top-center",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //       setTimeout(() => {
  //         navigate(cardredirectUrl.search);
  //       }, 100);
  //     }).catch(function (error) {
  //       toast.error('Something went wrong. please try again.', {
  //         position: "top-center",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     });
  //   }
  // }

  const updatePaymentData = async (price, number,type) => {
    let onlinePayUrl = `${baseUrl}/appointment/generate/payment/link/`;
    const data = {
      amount: price,
      name: currentUser?.name,
      phone: `${currentUser?.country_code}${currentUser?.mobile}`,
      email: currentUser.email,
      reference_no: number,
      custom: type,
      tokenId: useCard ? useCard : "",
      isStripe: hasStripePayment(localStorage.getItem("userCountryId"), country_list)
      ? true
      : false
    };
    try {
      const stepOneData = await BuyOnline({
        baseUrl: onlinePayUrl,
        data
      });
      if (stepOneData?.data) {
        window.location.href = stepOneData?.data?.payUrl;
      }

      // console.log("stepOneData", stepOneData);
      //  setPayUrl(stepOneData?.data?.payUrl)
      // setModalVisible(true)
    } catch (error) {
      // Handle errors here
      handlingAuthErrors(error);
      console.error("stepOneData-error", error);
    }
  };

  async function submitPayment() {
    // console.log("useCard", state);
    if (appointmentDetails.type === "Reschedule") {
      updatePaymentData(appointmentDetails?.payableAmount,state.appointment_no, "Reschedule");
    } else {
      // console.log("appointmentDetails",appointmentDetails)
      updatePaymentData(appointmentDetails?.payableAmount,appointmentDetails.appointment_id, "Tips");
      // values["appointment_id"] = appointmentDetails.appointment_id;
      // values["rating_point"] = appointmentDetails.rating_point;
      // values["review"] = appointmentDetails.review;
      // values["cleaner_id"] = appointmentDetails.cleaner_id;
      // values["addToFav"] = appointmentDetails.addToFav;
    }
 
    // if(appointmentDetails.type === 'Reschedule'){
    //   dispatch(rescheduleAppointmentAction(appointmentDetails.apiEndPoint,paymentData));
    // }else{
    //   dispatch(postProfessionalReviewAction(appointmentDetails.apiEndPoint,paymentData));
    // }
  }

  // useEffect(() => {
  //   updatePaymentData("qnb_customer_id",customerCreditCards?.customer?.id !== undefined ?customerCreditCards?.customer?.id:"");
  //     updatePaymentData("qnb_customer_id","");
  //   }, [customerCreditCards]);

  // useEffect(() => {
  //     dispatch(customerCreditCardListsAction(listUrl));
  // }, [listUrl]);

  jQuery(".payByCard").on("click", function (event) {
    event.preventDefault();
    event.stopPropagation();
    return false;
  });

  const [isLoading, setIsLoading] = useState(true);
  let listUrl_creditCard = baseUrl + "/payment/instruments";
  useEffect(() => {
    dispatch(customerCreditCardListsAction());
  }, [listUrl_creditCard, dispatch]);

  useEffect(() => {
    if (customerCreditCards?.length > 0) {
      setIsLoading(true);
      setUseCard(customerCreditCards[0]?.token);
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 4000);
    }
  }, [customerCreditCards, setUseCard]);

  const handelChangeCard = (token) => {
    setUseCard(token);
  };
  const getAppointmentInfoState = useSelector(
    (state) => state.appointmentReducer
  );
  return (
    <>
      <Header />
      {loading || page_loading || page_loadings ? <Loader /> : ""}
      <ModifiedToastContainer />
      <div className="py-5">
        <div className="userContainer">
          <div className="creditPackage">
            <div className="heading">
              <h2>{t("payment_checkout")}</h2>
              <h4>QAR {appointmentDetails?.payableAmount}</h4>
            </div>
            <div className="card">
              <div className="stepsCard">
                <div className="checkboxSec">
                  <div className="checkboxItem">
                    <div className="creditHeading">
                      <h5>{t("select_card")}</h5>
                      {/* <NavLink 
                    to="/my-account/add-credit-card"
                    state={{ redirect: "/my-account/common-checkout" }}
                    className="btn btn-primary">
                    <i className="fa fa-plus me-2"></i>{ t('add_card') }
                  </NavLink> */}
                    </div>

                    {customerCreditCards?.length > 0 ? (
                      customerCreditCards.map((customerCreditCard, index) => {
                        return (
                          <div className="list-group-item" key={index}>
                            <div className="form-check">
                              <input
                                type="radio"
                                className="form-check-input useCardPayment PaymentByCard"
                                id={"PaymentByCard_" + index}
                                onChange={() =>
                                  handelChangeCard(customerCreditCard?.token)
                                }
                                name="cardNo"
                                defaultChecked={index === 0}
                              />
                              <div className="ms-4">
                                <label
                                  className="form-check-label"
                                  htmlFor={index}
                                >
                                  ...{customerCreditCard?.card?.cardNumber}
                                </label>
                                <p>{customerCreditCard?.card?.cardExpiry}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <>
                        {isLoading ? "loader" : <center>{t("no_card")}</center>}
                      </>
                    )}
                    {/*                            
                            <div className="cardsec">
                            <h3>
                            {t('add_new_card')}
                            <button
                                className="finalPaymentButton"
                                data-sc-key={process.env.REACT_APP_QNB_PUBLIC_KEY} 
                                data-name="Right Hands"
                                data-description="Complete peace of mind" 
                                data-receipt="true" 
                                data-logo="https://dvdljkkxpxqo3.cloudfront.net/images/logo.png.webp" 
                                data-operation="create.token" 
                                id="sc_btn_1649745021579"
                                data-redirect-url={process.env.REACT_APP_BASE_URL+"/my-account/common-checkout"}
                                type="button">
                                <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/plus2.png.webp" />
                            </button>
                            </h3>
                            </div> */}
                  </div>
                  <button
                    style={{marginBottom: 10}}
                    className="btn btn-primary w-full buySubSubmit"
                    onClick={() => submitPayment()}
                  >
                    {t("pay")}
                  </button>
                  {/* {(() => {
                          if (payByCard && customerCreditCards && customerCreditCards !== null) {
                            return (<button className="btn btn-primary w-full buySubSubmit" onClick={() => submitPayment()}>{t('pay')}</button>);
                          }
                        })()} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CommonCheckout;
