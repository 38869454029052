import React, {useState, useEffect} from "react";
import CheckoutSummary from "../components/CheckoutSummary";
import jQuery from "jquery";
import {useDispatch, useSelector} from "react-redux";
import {getProHourInfoAction} from "../../../../actions/tcDataAction";
import {getCleaningMaterialInfoAction} from "../../../../actions/tcDataAction";
import Modal from "react-bootstrap/Modal";
import {useTranslation} from "react-i18next";
import {authHeader, handlingAuthErrors} from "../../../../helpers/helpers";
import axios from "axios";
import { getAppointmentPriceAction } from "../../../../actions/appointmentAction";

export const ServiceDetails = ({
  checkoutData,
  appointment,
  setCheckoutData,
  packageType,
  currentUser
}) => {
  var neededProfessionals = [1, 2, 3, 4];
  const [neededHours, setNeedHours] = useState([]);
  const [serviceId, setServiceId] = useState([]);
  useEffect(() => {
    if (checkoutData?.service_id) {
      if (Array?.isArray(checkoutData.service_id)) {
        setServiceId(checkoutData.service_id[0]);
      } else {
        setServiceId(checkoutData.service_id);
      }
    }

    if (packageType === "" && checkoutData?.service_type !== "") {
      packageType = checkoutData?.service_type
    }

    if (checkoutData?.is_cleaning_materials) {
      jQuery("#cleaning_materials_2").prop("checked", true);
      jQuery("#cleaning_materials_1").prop("checked", false);
    } else {
      jQuery("#cleaning_materials_1").prop("checked", true);
      jQuery("#cleaning_materials_2").prop("checked", false);
    }
  }, []);
  useEffect(() => {
    let listUrl =
      baseUrl + `/priceMaster/view/by/country/${checkoutData?.country_id}`;
    axios({
      method: "GET",
      headers: authHeader(),
      url: listUrl
    })
      .then((res) => {
        let hoursData = res.data.priceMaster.map((x) => {
          if (
            checkoutData?.is_home_cleaning === true &&
            x.service_type === "Home"
          ) {
            return x?.hours;
          }
        });
        const updatedHoursData = hoursData?.filter(
          (hours) => hours !== undefined
        );
        const sortedHours = updatedHoursData?.slice()?.sort((a, b) => a - b);
        setNeedHours(sortedHours);

        // hoursData = hoursData?.filter((hours) => hours !== undefined);
        // setNeedHours(hoursData);
      })
      .catch((err) => {
        console.log("error: ", err);
        handlingAuthErrors(err);
      });
  }, [checkoutData]);

  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  // let getHoursCount = params.get("hours_count")
  //   ? parseInt(params.get("hours_count"))
  //   : 4;
  let getProfessionalsCount = params.get("professionals_count")
    ? parseInt(params.get("professionals_count"))
    : 1;
  let getIsCleaningMaterials = params.get("is_cleaning_materials");

  const [cleaningMaterialInfoShow, setCleaningMaterialInfoShow] =
    useState(false);
  const handleCleaningMaterialInfoShow = () =>
    setCleaningMaterialInfoShow(true);
  const handleCleaningMaterialInfoHide = () =>
    setCleaningMaterialInfoShow(false);

  const [serviceBasicInfoShow, setServiceBasicInfoShow] = useState(false);
  const handleServiceBasicInfoShow = () => setServiceBasicInfoShow(true);
  const handleServiceBasicInfoHide = () => setServiceBasicInfoShow(false);

  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";

  function writeInstructions(e) {
    proFreqUpdate("booking_instruction", e.target.value);
  }

  const getProHrInfoState = useSelector((state) => state.getTCReducer);
  const {loading, pro_hour_info} = getProHrInfoState;
  const dispatch = useDispatch();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let listUrl = baseUrl + "/staticPage/info/ProfHourInfo";

  const serviceBasicInformation = () => {
    dispatch(getProHourInfoAction(listUrl, {}));
    handleServiceBasicInfoShow();
  };

  const getCleaningState = useSelector((state) => state.getTCReducer);
  const {cleaning_material_info} = getCleaningState;
  let cleaningUrl = baseUrl + "/staticPage/info/CleaningMaterialInfo";
  const cleaningMaterialInformation = () => {
    dispatch(getCleaningMaterialInfoAction(cleaningUrl, {}));
    handleCleaningMaterialInfoShow()();
  };
  const {t} = useTranslation();

  async function proFreqUpdate(key, value) {
    setCheckoutData((prevState) => ({
      ...prevState,
      [key]: value
    }))
    var values = {
      hours_count:
        key === "hours_count"
          ? value
          : checkoutData.hours_count
          ? checkoutData?.hours_count
          : 4,
      professionals_count:
        key === "professionals_count"
          ? value
          : checkoutData.professionals_count,
      is_cleaning_materials:
        key === "is_cleaning_materials"
          ? value
          : checkoutData.is_cleaning_materials,
      frequency_id: checkoutData?.frequency_id,
      country_id: checkoutData?.country_id,
      customer_id: currentUser?.id
    };
      const actionUrl =
        baseUrl + `/appointment/calculate/frequency/appointment/price`;
      dispatch(
        getAppointmentPriceAction(
          actionUrl,
          values,
          setCheckoutData
        )
      );
      
  }

  let pkgType = packageType ? packageType : (checkoutData?.service_type) 
  return (
    <>
      <div className="stepsContent stepService ">
        <div className="row">
          <div className="col-lg-7 col-md-12">
            <div className="card">
              <div className="stepsCard ">
                {/* {console.log("serviceUiType", packageType)} */}
                {pkgType === "Frequency" ? (
                  <div className="checkboxSec">
                    <div className="checkboxItem">
                      <div className="heading">
                        <h3>{t("house_cleaning")}</h3>
                        <p className="ps-0">{t("turning_your_99_problems")}</p>
                      </div>
                      <div className="row">
                        <div className="col-sm-5 mb-2 mb-xl-0 ">
                          <div className="cleanerHour ">
                            <select
                              value={checkoutData?.hours_count}
                              selected={checkoutData?.hours_count}
                              onChange={(e) =>
                                proFreqUpdate(
                                  "hours_count",
                                  e.target.value
                                )
                              }
                            >
                              {neededHours.map((neededHour, index) => {
                                return (
                                  <option value={neededHour} key={index}>
                                    {" "}
                                    {neededHour + " " + t("hours")}{" "}
                                  </option>
                                );
                              })}
                            </select>
                            <select
                              value={checkoutData.professionals_count}
                              onChange={(e) =>
                                // setCheckoutData((prevState) => ({
                                //   ...prevState,
                                //   ["professionals_count"]:
                                //     e.target.value
                                // }))
                                proFreqUpdate(
                                  "professionals_count",
                                   e.target.value
                                )
                              
                              }
                            >
                              {neededProfessionals.map(
                                (neededProfessional, index) => {
                                  return (
                                    <option
                                      value={neededProfessional}
                                      key={index}
                                    >
                                      {" "}
                                      {neededProfessional +
                                        " " +
                                        (neededProfessional === 1
                                          ? t("pro")
                                          : t("pros"))}
                                    </option>
                                  );
                                }
                              )}
                            </select>
                          </div>
                        </div>
                       
                      </div>
                    </div>
                  
                    <div className="checkboxItem">
                      <div className="heading">
                        <h3>
                          {t("require_cleaning_materials")}
                          <img
                            src="https://dvdljkkxpxqo3.cloudfront.net/images/info2.png.webp"
                            className="static_info"
                            onClick={() => cleaningMaterialInformation()}
                            alt=""
                          />
                        </h3>
                      </div>
                      <div className="buttonItem">
                        <label htmlFor="cleaning_materials_1">
                          <input
                            type="radio"
                            name="cleaning_materials"
                            id="cleaning_materials_1"
                            defaultChecked={
                              !checkoutData?.is_cleaning_materials
                            }
                            onClick={() =>
                              proFreqUpdate("is_cleaning_materials", false)
                            }
                          />
                          <span>{t("no_i_have_them")}</span>
                        </label>
                        <label htmlFor="cleaning_materials_2">
                          <input
                            type="radio"
                            name="cleaning_materials"
                            id="cleaning_materials_2"
                            defaultChecked={checkoutData?.is_cleaning_materials}
                            onClick={() =>
                              proFreqUpdate("is_cleaning_materials", true)
                            }
                          />
                          <span>{t("yes_please")}</span>
                        </label>
                      </div>
                    </div>
                    <div className="checkboxItem">
                      <div className="heading">
                        <h3>{t("specific_cleaning_instructions")}</h3>
                      </div>
                      <div className="buttonItem me-0">
                        <textarea
                          placeholder={t("instructions_hint")}
                          value={checkoutData.booking_instruction}
                          onChange={writeInstructions}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="checkboxSec">
                    <div className="checkboxItem">
                      <div className="heading">
                        <h3>{t("How_many_Babysitters.?")} </h3>
                      </div>
                      <div className="buttonItem circleInput">
                        <h4>{t("babysitters")}</h4>
                        {neededProfessionals.map(
                          (neededProfessional, index) => {
                            return (
                              <label
                                htmlFor={
                                  "professionals_count_" + neededProfessional
                                }
                                key={index}
                              >
                                <input
                                  type="radio"
                                  name="professionals_count"
                                  id={
                                    "professionals_count_" + neededProfessional
                                  }
                                  defaultChecked={
                                    neededProfessional === getProfessionalsCount
                                      ? true
                                      : false
                                  }
                                  onClick={
                                    () =>
                                    proFreqUpdate("professionals_count" ,neededProfessional
                                      )
                                  }
                                />
                                <span>{neededProfessional}</span>
                              </label>
                            );
                          }
                        )}
                      </div>
                    </div>
                    <div className="checkboxItem">
                      <div className="heading">
                        <h3>
                          {t("hours_professional_to_stay")}
                          <img
                            src="https://dvdljkkxpxqo3.cloudfront.net/images/info2.png.webp"
                            className="static_info"
                            alt=""
                            onClick={() => serviceBasicInformation()}
                          />
                        </h3>
                      </div>
                      <div className="buttonItem circleInput">
                        <h4>{t("hours")}</h4>
                        {neededHours.map((neededHour, index) => {
                          return (
                            <label
                              htmlFor={"hours_count_" + neededHour}
                              key={index}
                            >
                              <input
                                type="radio"
                                name="hours_count"
                                id={"hours_count_" + neededHour}
                                defaultChecked={
                                  index ===
                                  (checkoutData?.hours_count === 2
                                    ? 0
                                    : checkoutData?.hours_count === 3
                                    ? 1
                                    : checkoutData?.hours_count === 4
                                    ? 2
                                    : checkoutData?.hours_count === 5
                                    ? 3
                                    : 2)
                                }
                                onClick={() =>
                                  proFreqUpdate("hours_count", neededHour)
                                }
                              />
                              <span>{neededHour}</span>
                            </label>
                          );
                        })}
                      </div>
                    </div>
                    <div className="checkboxItem">
                      <div className="heading">
                        <h3>{t("list_your_instructions_comment")}</h3>
                      </div>
                      <div className="buttonItem me-0">
                        <textarea
                          placeholder={t("baby_sitting-instruction")}
                          value={checkoutData.booking_instruction}
                          onChange={writeInstructions}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5">
            <CheckoutSummary
              checkoutData={checkoutData}
              appointment={appointment}
            />
          </div>
        </div>
      </div>

      <Modal
        show={cleaningMaterialInfoShow}
        onHide={handleCleaningMaterialInfoHide}
        id="serviceinfo"
        className="serviceinfo"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {cleaning_material_info ? (
            <div
              className="accordion-body"
              dangerouslySetInnerHTML={{
                __html:
                  currentLanguage === "en"
                    ? cleaning_material_info?.content
                    : cleaning_material_info?.content_arabic
              }}
            ></div>
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={serviceBasicInfoShow}
        onHide={handleServiceBasicInfoHide}
        id="serviceinfo"
        className="serviceinfo"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {pro_hour_info ? (
            <div
              className="accordion-body"
              dangerouslySetInnerHTML={{
                __html:
                  currentLanguage === "en"
                    ? pro_hour_info?.content
                    : pro_hour_info?.content_arabic
              }}
            ></div>
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ServiceDetails;
