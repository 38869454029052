import axios from 'axios'
import {toast} from "react-toastify";
import {authHeader, destroyTokenDetails, handlingAuthErrors} from "../helpers/helpers";

export const customerAddressListsAction = (baseUrl,selectAddress) => dispatch => {

    dispatch({type: 'GET_CUSTOMER_ADDRESS_LIST_REQUEST'})
    axios.get(baseUrl,{
        headers: authHeader(),
    }).then((res) => {
        if(res.data.address){
            selectAddress(res.data.address);
        }
        dispatch({type: 'GET_CUSTOMER_ADDRESS_LIST_SUCCESS', payload: {
            customerAddresses: res.data.address
        }});
    }).catch((err) => {
        dispatch({type: 'GET_CUSTOMER_ADDRESS_LIST_FAILED', payload: err});
        handlingAuthErrors(err);
    });
}

export const makeDefaultCustomerAddressAction = (baseUrl,getAddresses) => dispatch => {

    dispatch({type: 'POST_DEFAULT_ADDRESS_REQUEST'})
    axios({
        method: 'post',
        url: baseUrl,
        headers: authHeader()
    }).then((res) => {
        dispatch({type: 'POST_DEFAULT_ADDRESS_SUCCESS'});
        getAddresses();
    })
    .catch((err) => {
        dispatch({type: 'POST_DEFAULT_ADDRESS_FAILED', payload: err});
        handlingAuthErrors(err);
    })
}