export const buyMemberShipReducer = (state = {}, action) => {
    switch (action.type) {
        case'BUY_MEMBERSHIP_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'BUY_MEMBERSHIP_SUCCESS':
            return {
                loading: false,
                success: true
            }
        case'BUY_MEMBERSHIP_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }          
        default:
            return state
    }
}