import React, {useState,useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import { useTranslation } from 'react-i18next';
import { getProfessionalDetailsAction, postProfessionalReviewAction} from "../../actions/professionalDetailsAction";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import Footer from "../../components/Footer";
import Modal from "../../components/Modal";

const WriteReview = () => {
  const { t } = useTranslation();
  let navigate = useNavigate(); 
  const getProfessionalDetailsState = useSelector(state => state.professionalDetailsReducer)
  const {loading, professionalInfo} = getProfessionalDetailsState;
  const dispatch = useDispatch();

  const [review, setReview] = useState({
    qnb_customer_id: "",
    payment_mode: "Card",
    appointment_id:localStorage.getItem('appointmentDetailsId'),
    rating_point:0,
    review:"",
    additional_tip:0,
    cleaner_id:localStorage.getItem('appointmentCleanerId'),
    addToFav:false
  });
  useEffect(() => {
    if(!localStorage.getItem('appointmentDetailsId')){
      window.location.href = "/my-account/appointments";
    }
  }, []);
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let apiUrl = baseUrl + "/appointment/professional/info/"+localStorage.getItem('appointmentCleanerId');
  useEffect(() => {
    dispatch(getProfessionalDetailsAction(apiUrl))
  }, [apiUrl]);


  useEffect(() => {
    if(!localStorage.getItem('appointmentDetailsId') || !localStorage.getItem('appointmentCleanersId') || !localStorage.getItem('appointmentCleanerId')){
      navigate('/my-account/appointments');
    }
  }, []);

  const updateReviewData = (key,value) => {
    setReview((prevState) => ({
      ...prevState,
      [key]: value
    }));
  }

  function writeReview(e) {
    updateReviewData("review",e.target.value)
  }

  const checkNextCleaner = () => {
    let appointmentCleanersId = JSON.parse(localStorage.getItem('appointmentCleanersId'));
    let index = appointmentCleanersId.indexOf(localStorage.getItem('appointmentCleanerId'));
    let nextIndex = index + 1;

    if(index[nextIndex] !== undefined){
      localStorage.setItem('appointmentCleanerId', index[nextIndex]);
      return true;
    }else{
      return false;
    }
  };

  async function submitReview(){
    let apiUrl = process.env.REACT_APP_LOCAL_API_URL + "/appointment/rating/point";
    if(review.additional_tip > 0){
      review['apiEndPoint'] = apiUrl;
      review['type'] = "Rating";
      review['payableAmount'] = review.additional_tip;
      localStorage.setItem('appointmentDetailsUpdateData', JSON.stringify(review));
      setTimeout(() => {
        window.location.href = '/my-account/common-checkout';
      }, 1000);
    }else{
      dispatch(postProfessionalReviewAction(apiUrl,review));
    }
  }

  return (
    <>
      <Header />
      {loading ? <Loader /> : ""}
      <div className="py-5">
        <div className="userContainer">
          <div className="yourFeedback">
            <div className="heading">
              <h2>{t('rating_title')}</h2>
            </div>
            <div className="card">
              <div className="feedbackProfile">
                <img alt="" src={(professionalInfo?.user?.profile_pic) ? professionalInfo?.user?.profile_pic : "https://dvdljkkxpxqo3.cloudfront.net/images/user-image.png.webp" } />
                <div className="stars">
                  <label>
                    <input type="checkbox" onClick={() => updateReviewData('rating_point',1)}/>
                    <i className={"fa fa-star "+((review.rating_point >= 1) ? "active-star" : "")}></i>
                  </label>
                  <label>
                    <input type="checkbox" onClick={() => updateReviewData('rating_point',2)}/>
                    <i className={"fa fa-star "+((review.rating_point >= 2) ? "active-star" : "")}></i>
                  </label>
                  <label>
                    <input type="checkbox" onClick={() => updateReviewData('rating_point',3)}/>
                    <i className={"fa fa-star "+((review.rating_point >= 3) ? "active-star" : "")}></i>
                  </label>
                  <label>
                    <input type="checkbox" onClick={() => updateReviewData('rating_point',4)}/>
                    <i className={"fa fa-star "+((review.rating_point >= 4) ? "active-star" : "")}></i>
                  </label>
                  <label>
                    <input type="checkbox" onClick={() => updateReviewData('rating_point',5)}/>
                    <i className={"fa fa-star "+((review.rating_point === 5) ? "active-star" : "")}></i>
                  </label>
                </div>
                <div className="complementsec">
                  <div className="w-full ">
                    {/* <input
                      type="text"
                      className="form-control"
                      placeholder="Give a compliment"
                      onChange={writeReview}
                    /> */}
                    <textarea
                      className="form-control"
                      placeholder={t('give_a_compliment')}
                      onChange={writeReview}
                    ></textarea>
                    <button className="btn btn-primary btn-fav" onClick={() => updateReviewData('addToFav',((review.addToFav) ? false :true))}>
                      <span className={"fa fa-heart "+((review.addToFav) ? "active_my_favourites" : "")}></span>{t('add_to_favourites')}
                    </button>
                  </div>
                </div>
              </div>
              <div className="addAtip">
                <div className="addAtipitem">
                  <h3>{t('add_a_tip_for')} {professionalInfo?.user?.first_name} {professionalInfo?.user?.last_name}</h3>
                  <p>{t('your_tip_was')} {review.additional_tip}</p>
                  <div className="tipButton">
                    <button  value="5" onClick={() => updateReviewData('additional_tip',5)} className={((review.additional_tip === 5) ? "active_addtip_button" : "")}>
                      QAR 5
                    </button>
                    <button value="6" onClick={() => updateReviewData('additional_tip',6)} className={((review.additional_tip === 6) ? "active_addtip_button" : "")}>
                      QAR 6
                    </button>
                    <button value="7" onClick={() => updateReviewData('additional_tip',7)} className={((review.additional_tip === 7) ? "active_addtip_button" : "")}>
                      QAR 7
                    </button>
                  </div>
                  <input
                      type="text"
                      className="form-control custom_amount"
                      placeholder={t('enter_custom_amount')}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => {
                        let additional_tip = (event.target.value) ? parseInt(event.target.value) : 0;
                        updateReviewData('additional_tip',additional_tip);
                      }}
                    />
                  {/* <h3>Enter Custom Amount</h3> */}
                </div>
                <button className="btn btn-primary w-full" onClick={() => submitReview()}>{t('done')}</button>
                <div className="skipButtons mt-4">
                  <div className="row">
                    <div className="col-lg-6 col-sm-6"> 
                      <Link to="/my-account/appointments">
                        <button className="btn btn-primary w-full btn_skip mb-2">{t('skip_to_next')} </button>
                      </Link>
                    </div>                   
                    <div className="col-lg-6 col-sm-6">
                      <Link to="/my-account/appointments">
                        <button className="btn btn-primary w-full btn_skip">{t('skip_all')}</button>
                      </Link>
                    </div>
                  </div>                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal />
      <Footer />
    </>
  );
};

export default WriteReview;
