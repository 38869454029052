import React from "react";

const EmailInput = ({
  id,
  name,
  onChange,
  placeholder,
  maxLength,
  type,
  className,
  value,
}) => {
  return (
    <>
      <input
        type={type}
        className={className ? className : "form-control form-input-border"}
        id={id}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        maxLength={maxLength}
        value={value}
      />
    </>
  );
};

export default EmailInput;
