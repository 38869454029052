import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle
} from "react";
import {ModifiedToastContainer} from "../../../../components/ModifiedToastContainer";
import {fromLatLng} from "react-geocode";

import {useDispatch, useSelector} from "react-redux";
import Modal from "react-bootstrap/Modal";
import {useTranslation} from "react-i18next";
import {Autocomplete} from "@react-google-maps/api";
import {ValidateCountry} from "../../../../helpers/bookingAPIsteps";
import Loader from "../../../../components/Loader";

const AddressBooking = ({
  // showAddressAddModal,
  setShowAddressAddModal,
  setCheckoutData,
  checkoutData,
  AsyncMap,
  setCenter,
  setMarker,
  updateCheckoutData,
  isToggle,
  setIsToggle,
  addressObject,
  setAddressObject,
  isValidLocation,
  setIsValidLocation,
  setIsLocation,
  isAddress, 
  setIsAddress
}) => {
  // const  = props;
  const {t} = useTranslation();
  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  const handleAddressAddModalClose = () => {
    setShowAddressAddModal(false);
    setIsLocation(true);
  };
  const checkValidateCountry = async ({baseUrl, data}) => {
    const isCountryValid = await ValidateCountry({baseUrl, data});
    setIsValidLocation(isCountryValid === false ? false : true);
  };
  const getArea = (addressArray) => {
    let area = "";
    for (let i = 0; i < addressArray?.length; i++) {
      for (let j = 0; j < addressArray[i]?.types?.length; j++) {
        if (
          "sublocality_level_1" === addressArray[i].types[j] ||
          "locality" === addressArray[i].types[j]
        ) {
          area = addressArray[i].long_name;
          return area;
        }
      }
    }
  };

  const getStreet = (addressArray) => {
    let street = "";
    for (let i = 0; i < addressArray?.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i]?.types?.length; j++) {
          if (addressArray[i].types[j] === "route") {
            street = addressArray[i].long_name;
            return street;
          }
        }
      }
    }
  };

  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  const [autocomplete, setAutocomplete] = useState(null);
  const [shortName, setShortName] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  // Handel  current location Function
  const currentLocationGetter = (e) => {
    setIsLoading(true);
    e.preventDefault();
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const {latitude, longitude} = position.coords;
        // setCenter({lat: latitude, lng: longitude});
        //
        localStorage.setItem("latitude", latitude);
        localStorage.setItem("longitude", longitude);
        // setAddressObject({
        //   ...addressObject,
        //   latitude: latitude,
        //   longitude: longitude
        // });

        const lat = latitude;
        const lng = longitude;
        fromLatLng(lat, lng).then(
          (response) => {
            setCenter({lat: latitude, lng: longitude});
            setMarker({lat: latitude, lng: longitude});
            const address = response.results[0].formatted_address;
            const addressArray = response.results[0].address_components;
            let street = getStreet(addressArray);
            let area = getArea(addressArray);

            const countryComponent = addressArray.find((component) =>
              component.types.includes("country")
            );
            let newShortName = countryComponent?.short_name;
            let url = baseUrl + `/validate/location`;
            let data = {country_code_short: newShortName};
            checkValidateCountry({baseUrl: url, data});

            setShortName(countryComponent?.short_name);
            setIsAddress(true);
            window.scrollTo(0, 0);
            setAddressObject({
              ...addressObject,
              address: address,
              street: street,
              area: area,
              latitude: lat,
              longitude: lng
            });
            setCheckoutData({
              ...checkoutData,
              latitude: lat ? lat : localStorage.getItem("latitude"),
              longitude: lng ? lng : localStorage.getItem("longitude"),

              address: address ? address : ""
            });
            setIsLoading(false);
            // setIsToggle(true);
          },
          (error) => {
            console.error(error);
          }
        );
      },
      (error) => {
        console.error("Error getting current location:", error);
      }
    );
  };
  // useEffect(() => {
  //   // Fetch the user's current location when the component mounts
  //   currentLocationGetter();
  // }, []);

  // Handel Drag Marker Function

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    address_type: "",
    street: "",
    floor: "",
    house_no: "",
    office_no: "",
    appartment_no: ""
  });
  const [addressDetails, setAddressDetails] = useState({});
  const getAllCountryState = useSelector((state) => state.countryReducer);
  const {country_list} = getAllCountryState;
  // const handleAddressAddModalClose = () => setShowAddressAddModal(false);
  const [error, setError] = useState({
    address_type: "",
    street: "",
    floor: "",
    house_no: ""
  });
  function setAutoFillAddress(address) {
    setAddressDetails(address);
    // console.log("Auto Fill Address", address);
  }

  const handleCustomerSubmit = async (e) => {
    localStorage.getItem("latitude");
    localStorage.getItem("longitude");
    e.preventDefault();
    setCheckoutData({
      ...checkoutData,
      latitude: addressObject?.latitude
        ? addressObject?.latitude
        : localStorage.getItem("latitude"),
      longitude: addressObject?.longitude
        ? addressObject?.longitude
        : localStorage.getItem("longitude"),

      address: addressObject?.address !== "" ? addressObject?.address : "",
      address_nickname: addressObject?.nick_name
    });
    if (addressObject?.countryLong) {
      const filteredArray = country_list.filter(
        (item) => item?.name === addressObject?.countryLong
      );

      if (filteredArray?.length > 0) {
        updateCheckoutData("country_id", filteredArray[0]?._id);
      }
    }
    if (
      addressObject?.longitude !== "" &&
      addressObject?.longitude !== undefined
    ) {
      // handleAddressAddModalClose();
      setShowAddressAddModal(false);
      setIsLocation(true);
    } else {
      alert("Please select location correctly");
      setShowAddressAddModal(true);
      setIsLocation(false);
    }
    // Remove all jQuery usage for form values here.
  };

  // Update the state based on form input changes
  // const handleInputChange = (e) => {
  //   const {name, value} = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value
  //   });
  // };

  const handlePlaceChanged = async () => {
    if (autocomplete && autocomplete !== undefined && autocomplete !== "") {
      const place = autocomplete?.getPlace();
      const addressComponents = place.address_components || [];

      let street = "";
      let area = "";

      for (const component of addressComponents) {
        const types = component.types || [];

        if (types.includes("route")) {
          // 'route' type represents the street
          street = component.long_name;
        } else if (
          types.includes("neighborhood") ||
          types.includes("sublocality")
        ) {
          // 'neighborhood' or 'sublocality' type represents the area
          area = component.long_name;
        }
      }
      if (place?.geometry && place.geometry.location && place !== undefined) {
        const latLng = {
          lat: Number(place.geometry.location.lat()),
          lng: Number(place.geometry.location.lng())
        };

        if (!isNaN(latLng.lat) && !isNaN(latLng.lng)) {
          setCenter(latLng);
          setMarker(latLng);
          const countryComponent = place.address_components.find((component) =>
            component.types.includes("country")
          );
          let newShortName = countryComponent.short_name;
          let url = baseUrl + `/validate/location`;
          let data = {country_code_short: newShortName};
          const isCountryValid = ValidateCountry({baseUrl: url, data});
          setShortName(countryComponent ? countryComponent.short_name : "");

          setIsAddress(true);
          window.scrollTo(0, 0);
          setCheckoutData({
            ...checkoutData,
            latitude: latLng.lat
              ? latLng.lat
              : localStorage.getItem("latitude"),
            longitude: latLng.lng
              ? latLng.lng
              : localStorage.getItem("longitude"),

            address: place.formatted_address ? place.formatted_address : ""
          });
          setAddressObject({
            ...addressObject,
            latitude: latLng.lat,
            longitude: latLng.lng,
            address: place.formatted_address,
            area: area,
            street: street
          });
          setIsToggle(true);
        } else {
          console.error("Invalid coordinates:", latLng);
        }
      } else {
        console.error("Invalid place object:", place);
      }
    }
  };

  return (
    <>
      <div className="col-lg-7 col-md-12">
        <div className="card newSerachBox">
          <div className="heading mt-3">
            <h3>{t("your_location")}</h3>
          </div>
          <div className="stepsCard justify-content-start ">
            <div className="searchPlace" style={{zIndex: 1}}>
              {isLoading && <Loader />}
              <Autocomplete
                onLoad={(auto) => setAutocomplete(auto)}
                onPlaceChanged={handlePlaceChanged}
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
              >
                <>
                  <i
                    class="fa fa-map-marker"
                    style={{
                      position: "absolute",
                      top: "16px",
                      right: currentLanguage === "ar" ? 30 : "auto",
                      left: currentLanguage === "ar" ? "auto" : 30,
                      fontSize: 20
                    }}
                    aria-hidden="true"
                  ></i>
                  <input
                    type="text"
                    placeholder={t("search_for_your_building_or_area")}
                    style={{
                      boxSizing: `border-box`,
                      border: `1px solid transparent`,
                      width: `240px`,
                      height: `32px`,
                      padding: `0 12px`,
                      borderRadius: `3px`,
                      boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                      fontSize: `14px`,
                      outline: `none`,
                      textOverflow: `ellipses`
                    }}
                    onChange={(e) => {
                      setAddressObject({
                        ...addressObject,
                        address: e.target.value
                      });
                    }}
                    value={addressObject?.address}
                  />
                  <button
                    className="autosign"
                    onClick={(e) => currentLocationGetter(e)}
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: currentLanguage === "ar" ? "auto" : "15px",
                      left: currentLanguage === "ar" ? 15 : "auto"
                    }}
                  >
                    <img
                      src="https://dvdljkkxpxqo3.cloudfront.net/images/gps.png.webp"
                      height="30"
                      width="30"
                      alt=""
                    />{" "}
                  </button>
                </>
              </Autocomplete>
            </div>

            <div style={{height: isAddress ? "450px" : "100px", width: "100%"}}>
              {isAddress && (
                <>
                  <AsyncMap
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`}
                    loadingElement={<div style={{height: "100%"}} />}
                    containerElement={<div style={{height: "100%"}} />}
                    mapElement={<div style={{height: "100%"}} />}
                  />
                  <div
                    className={
                      isToggle
                        ? "address_input_div col-lg-12 mb-0 mapToogleDiv"
                        : "address_input_div col-lg-12 mb-0 mapToogleDiv addressDetailsHide"
                    }
                  >
                    {/* <div
                className={
                  isToggle ? "mapToggle hide" : "mapToggle toast hide"
                }
                style={{
                  marginTop: "-80px",
                  height: isToggle ? "100px" : "auto",
                  padding: "10px 10px 30px 10px"
                }}
              >
                 <button
                  type="button"
                  className="btnclose"
                  onClick={() => setIsToggle(false)}
                >
                  <span className="fa fa-angle-down"></span>
                </button> 

                <div className="row mt-4">
                  <div className="col-lg-12 inputdiv ">
                    <input
                      type="text"
                      className="form-control pl-0"
                      id="address_nickname"
                      name="address_nickname"
                      placeholder={t("address_nickname")}
                      value={
                        addressObject?.address !== ""
                          ? addressObject?.address
                          : ""
                      }
                    />
                    <div className="help-block"></div>
                  </div>
                </div> 
              </div> */}
                    {/* <button
                onClick={(e) => handleCustomerSubmit(e)}
                className="btn btn-primary"
                // disabled={!isValidLocation}
              >
                {t("confirm")}
              </button> */}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <ModifiedToastContainer /> */}
      {/*  Address Modal */}
      {/* <Modal
        show={showAddressAddModal}
        onHide={handleAddressAddModalClose}
        id="addAddress"
        className="addAddressModal"
      >
        <Modal.Header closeButton>
          <Modal.Title> {t("your_location")} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="searchPlace" style={{zIndex: 1}}>
            {isLoading && <Loader/>}
            <Autocomplete
              onLoad={(auto) => setAutocomplete(auto)}
              onPlaceChanged={handlePlaceChanged}
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            >
              <>
                <i
                  class="fa fa-map-marker"
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: currentLanguage === "ar" ? 30  :"auto",
                    left: currentLanguage === "ar" ? "auto"  :  30,
                    fontSize:20,
                  }}
                  aria-hidden="true"
                ></i>
                <input
                  type="text"
                  placeholder={t("search_for_your_building_or_area")}
                  style={{
                    boxSizing: `border-box`,
                    border: `1px solid transparent`,
                    width: `240px`,
                    height: `32px`,
                    padding: `0 12px`,
                    borderRadius: `3px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `14px`,
                    outline: `none`,
                    textOverflow: `ellipses`
                  }}
                  onChange={(e) => {
                    setAddressObject({
                      ...addressObject,
                      address: e.target.value
                    });
                  }}
                  value={addressObject?.address}
                />
                <button
                  className="autosign"
                  onClick={(e) => currentLocationGetter(e)}
                  style={{
                    position: "absolute",
                    top: "5px",
                    right: currentLanguage === "ar" ? "auto" : "15px",
                    left: currentLanguage === "ar" ? 15 : "auto"
                  }}
                >
                  <img
                    src="https://dvdljkkxpxqo3.cloudfront.net/images/gps.png.webp"
                    height="30"
                    width="30"
                  />{" "}
                </button>
              </>
            </Autocomplete>
          </div>

          <div style={{height: isAddress ? "400px" : "100px", width: "100%"}}>
            {isAddress && (
              <>
                <AsyncMap
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`}
                  loadingElement={<div style={{height: "100%"}} />}
                  containerElement={<div style={{height: "100%"}} />}
                  mapElement={<div style={{height: "100%"}} />}
                />
                <div
                  className={
                    isToggle
                      ? "address_input_div col-lg-12 mb-0 mapToogleDiv"
                      : "address_input_div col-lg-12 mb-0 mapToogleDiv addressDetailsHide"
                  }
                >
                  <div
                    className={
                      isToggle ? "mapToggle hide" : "mapToggle toast hide"
                    }
                    style={{
                      marginTop: "-80px",
                      height: isToggle ? "100px" : "auto",
                      padding: "10px 10px 30px 10px"
                    }}
                  >
                    <button
                      type="button"
                      className="btnclose"
                      onClick={() => setIsToggle(false)}
                    >
                      <span className="fa fa-angle-down"></span>
                    </button>

                    <div className="row mt-4">
                      <div className="col-lg-12 inputdiv ">
                        <input
                          type="text"
                          className="form-control pl-0"
                          id="address_nickname"
                          name="address_nickname"
                          placeholder={t("address_nickname")}
                          value={
                            addressObject?.address !== ""
                              ? addressObject?.address
                              : ""
                          }
                        />
                        <div className="help-block"></div>
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={(e) => handleCustomerSubmit(e)}
                    className="btn btn-primary"
                    // disabled={!isValidLocation}
                  >
                    {t("confirm")}
                  </button>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal> */}
    </>
    // </div>
  );
};

export default AddressBooking;
