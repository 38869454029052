import React from "react";
import Modal from "react-bootstrap/Modal";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {getDiscount} from "../../../../helpers/bookingAPIsteps";

const PromoCode = ({
  checkoutData,
  setCheckoutData,
  updateCheckoutData,
  promoCodeData,
  updatePromoCodeData,
  handleAddVoucherModalClose,
  handleAddVoucherModalShow,
  addVoucherModal,
  setIsVoucher,
  currentUser
}) => {
  const {t} = useTranslation();
  async function clearPromoCode() {
    updateCheckoutData("promo_code", "");
    updatePromoCodeData("promo_code", "");
    updateCheckoutData("discountPrice", 0);
    updateCheckoutData("discount_type", "");
    updateCheckoutData(
      "total_discount",
      checkoutData?.total_discount - checkoutData?.voucherDiscount
    );
    updateCheckoutData("voucher", "");
    updateCheckoutData("voucherDiscount", 0);
    setIsVoucher(false);
    localStorage.removeItem('couponCode', '')
  }

  let isCouponPreFilled = localStorage.getItem('couponCode');

  async function applyVoucher() {
    localStorage.removeItem('couponCode', '')
    let serviceId = localStorage.getItem("serviceId");
    console.log("voucher ServiceId", serviceId)
    let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
    const actionUrl = baseUrl + `/discount/apply/voucher`;

    let code = promoCodeData?.promo_code ? promoCodeData?.promo_code : "";
    const data = {
      promo_code: promoCodeData?.promo_code,
      service_id: checkoutData?.service_id,
      country_id: checkoutData?.country_id,
      customer_id: currentUser?.id
        ? currentUser?.id
        : checkoutData?.customer_id,
      min_amount: checkoutData?.price - checkoutData?.vipDiscountPrice
    };
    const DiscountData = await getDiscount(actionUrl, data);
    if (DiscountData === "Voucher not found") {
      updateCheckoutData("promo_code", "");
        updatePromoCodeData("promo_code", "");
      updateCheckoutData("discount_type", "");
      toast.error("Voucher not found", {
        duration: 2000,
        position: "top-right"
      });
      setIsVoucher(false);
      handleAddVoucherModalClose();
    } else if (DiscountData === "This Voucher is Expire") {
      updateCheckoutData("promo_code", "");
      updatePromoCodeData("promo_code", "");
      updateCheckoutData("discount_type", "");
      toast.error("This Voucher is Expire", {
        duration: 2000,
        position: "top-right"
      });
      setIsVoucher(false);
      handleAddVoucherModalClose();
    }

    if (DiscountData?.code === 200) {
      updateCheckoutData("promo_code", promoCodeData?.promo_code);
      setIsVoucher(true);
      setCheckoutData((prevState) => ({
        ...prevState,
        ["discount_type"]:
          DiscountData?.coupon_type === "promo" ? "PromoCode" : "Birthday"
      }));
      setCheckoutData((prevState) => ({
        ...prevState,
        ["voucher"]: DiscountData?.voucher
      }));
      updateCheckoutData(
        "discount_type ",
        DiscountData?.coupon_type === "promo"
          ? "PromoCode"
          : DiscountData?.coupon_type === "birthday"
          ? "Birthday"
          : "Referral"
      );
      updateCheckoutData("voucherDiscount", DiscountData?.discount_price);
      setCheckoutData((prevState) => ({
        ...prevState,
        ["total_discount"]: checkoutData?.total_discount
          ? checkoutData?.total_discount + DiscountData?.discount_price
          : DiscountData?.discount_price
      }));
      handleAddVoucherModalClose();
    }

    //  updateCheckoutData("discount_type", "Birthday");
  }
  return (
    <>
      <div className="checkboxItem">
        {(() => {
          if (checkoutData?.promo_code !== "" && checkoutData?.rhClub === false && !isCouponPreFilled) {
            return (
              <div className="applyCoupon">
                <input
                  type="hidden"
                  id="promo_code_val"
                  value={checkoutData.promo_code}
                />
                <p className="cancelPromoCode" onClick={() => clearPromoCode()}>
                  <i className="fa fa-times"></i>
                </p>
                <h4>{t("voucher_applied")}</h4>
                <h3>
                  {checkoutData.promo_code}{" "}
                  <span>
                    {checkoutData?.currency} {checkoutData.voucherDiscount}{" "}
                    {t("off")}
                  </span>
                </h3>
              </div>
            );
          } else {
            return (
              <button
                className="addVoucher"
                onClick={() => handleAddVoucherModalShow()}
                disabled={checkoutData?.rhClub === true}
              >
                {t("add_voucher_code")} <i className="fa fa-angle-right"></i>
              </button>
            );
          }
        })()}
      </div>
      <Modal
        show={addVoucherModal}
        onHide={handleAddVoucherModalClose}
        id="addVocher"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {t("add_voucher_code")}
            <i className="flag flag-us"></i>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            <div className="inputdiv col-lg-12 ">
              <label>{t("voucher_code")}</label>
              <input
                type="nubmer"
                className="form-control rds-8 h-47"
                placeholder=""
                defaultValue={
                  promoCodeData.promo_code ? promoCodeData.promo_code : ""
                }
                disabled={
                  isCouponPreFilled ? "disabled" : null
                }
                onChange={(event) => {
                  updatePromoCodeData("promo_code", event.target.value);
                }}
              />
            </div>
            <div className="row">
              <div className="w-full">
                <button
                  className={
                    "btn btn-primary mt-3 w-full " +
                    (promoCodeData.promo_code ? "" : "disabled")
                  }
                  onClick={() => applyVoucher()}
                  disabled={promoCodeData?.promo_code ? null : "disabled"}
                >
                  {t("apply")}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PromoCode;
