import React, {useState,useEffect} from "react";
import {useDispatch} from "react-redux";
import { cancelAndRescheduleAppointmentAction } from "../../actions/appointmentAction";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useTranslation } from 'react-i18next';

const CancelAppointment = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [cancellationReason, setCancellationReason] = useState({
    cancellation_reason:"",
    description:""
  });

  const updateData = (key,value) => {
    setCancellationReason((prevState) => ({
      ...prevState,
      [key]: value
    }));
  }
  
  useEffect(() => {
    if(!localStorage.getItem('appointmentDetailsId')){
      window.location.href = "/my-account/appointments";
    }
  }, []);

  async function cancelMyAppointment() {
    let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
    let appointmentDetailsId = localStorage.getItem('appointmentDetailsId');
    const cancelUrl = baseUrl + `/appointment/cancel/`+appointmentDetailsId;
    dispatch(cancelAndRescheduleAppointmentAction(cancelUrl,cancellationReason));
  }
  
  function writeDescription(e) {
    updateData("description",e.target.value)
  }

  return (
    <>
      <Header />
      <div className="py-5">
        <div className="userContainer">
          <div className="cancelAppoitment">
            <div className="heading">
              <h5>{t('cancel_appointment')}</h5> 
              <h2>{t('cancellation_reason')}</h2>
            </div>
            <div className="card">
              <div className="personalForm">
                <div className="checkboxItem">
                  <div className="form-check" onClick={() => updateData('cancellation_reason','I want to change my booking details')}>
                    <input
                      type="radio"
                      className="form-check-input"
                      id="radio1"
                      name="optradio"
                    />
                    <label className="form-check-label" htmlFor="radio1">
                      {t('i_want_to_change')}
                    </label>
                  </div>
                </div>
                <div className="checkboxItem">
                  <div className="form-check" onClick={() => updateData('cancellation_reason','I am not available at that time anymore')}>
                    <input
                      type="radio"
                      className="form-check-input"
                      id="radio2"
                      name="optradio"
                    />
                    <label className="form-check-label" htmlFor="radio2">
                      {t('i_am_not_available')}
                    </label>
                  </div>
                </div>
                <div className="checkboxItem">
                  <div className="form-check" onClick={() => updateData('cancellation_reason','I found a provider other than Right Hands.')}>
                    <input
                      type="radio"
                      className="form-check-input"
                      id="radio3"
                      name="optradio"
                    /> 
                    <label className="form-check-label" htmlFor="radio3">
                      {t('i_found_a_provider')}
                    </label>
                  </div>
                </div>
                <div className="checkboxItem">
                  <div className="form-check" onClick={() => updateData('cancellation_reason','Not happy with the punctuality')}>
                    <input
                      type="radio"
                      className="form-check-input"
                      id="radio4"
                      name="optradio"
                    /> 
                    <label className="form-check-label" htmlFor="radio4">
                      {t('not_happy_with_the_punctuality')}
                    </label>
                  </div>
                </div>
                <div className="checkboxItem">
                  <div className="form-check" onClick={() => updateData('cancellation_reason','Not getting the professional I asked for')}>
                    <input
                      type="radio"
                      className="form-check-input"
                      id="radio5"
                      name="optradio"
                    /> 
                    <label className="form-check-label" htmlFor="radio5">
                      {t('not_getting_the_professional')}
                    </label>
                  </div>
                </div>
                <div className="checkboxItem">
                  <div className="form-check" onClick={() => updateData('cancellation_reason','Leaving the country')}>
                    <input
                      type="radio"
                      className="form-check-input"
                      id="radio6"
                      name="optradio"
                    />  
                    <label className="form-check-label" htmlFor="radio6">
                      {t('leaving_the_country')}
                    </label>
                  </div>
                </div>
                <div className="checkboxItem">
                  <div className="form-check" onClick={() => updateData('cancellation_reason','Moving to a new address')}>
                    <input
                      type="radio"
                      className="form-check-input"
                      id="radio7"
                      name="optradio"
                    />
                    <label className="form-check-label" htmlFor="radio7">
                      {t('moving_to_a_new_address')}
                    </label>
                  </div>
                </div>
                <div className="checkboxItem">
                  <div className="form-check" onClick={() => updateData('cancellation_reason','Prefer to have one-time bookings')}>
                    <input
                      type="radio"
                      className="form-check-input"
                      id="radio8"
                      name="optradio"
                    />
                    <label className="form-check-label" htmlFor="radio8">
                      {t('prefer_to_have_one_time_bookings')}
                    </label>
                  </div>
                </div>
                <div className="checkboxItem">
                  <div className="form-check" onClick={() => updateData('cancellation_reason','Not happy with the quality')}>
                    <input
                      type="radio"
                      className="form-check-input"
                      id="radio9"
                      name="optradio"
                    />
                    <label className="form-check-label" htmlFor="radio9">
                      {t('not_happy_with_the_quality')}
                    </label>
                  </div>
                </div>
                <div className="checkboxItem">
                  <div className="form-check" onClick={() => updateData('cancellation_reason','The price is high')}>
                    <input
                      type="radio"
                      className="form-check-input"
                      id="radio10"
                      name="optradio"
                    />
                    <label className="form-check-label" htmlFor="radio10">
                      {t('the_price_is_high')}
                    </label>
                  </div>
                </div>
                <div className="checkboxItem">
                  <div className="form-check" onClick={() => updateData('cancellation_reason','There is another reason')}>
                    <input
                      type="radio"
                      className="form-check-input"
                      id="radio11"
                      name="optradio"
                    />
                    <label className="form-check-label" htmlFor="radio11">
                      {t('there_is_another_reason')}
                    </label>
                  </div>
                </div>
                <div className="buttonItem me-0">
                  <textarea placeholder={ t('description_optional') } value={cancellationReason.description} onChange={writeDescription}>
                  </textarea>
                </div>
                {/* <Link to="/my-account/bookingCancel"> */}
                  <button className={ "btn btn-primary w-full mb-0 mt-4 " + ((cancellationReason.cancellation_reason === '') ? 'disabled' : '')}
                  onClick={() => cancelMyAppointment()}
                  >
                    {t('submit')}  
                  </button>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CancelAppointment;
