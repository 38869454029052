import axios from 'axios'
import {authHeader, handlingAuthErrors, handlingNonauthErrors} from "../helpers/helpers";

export const getProfessionalListAction = (baseUrl,values) => dispatch => {

    dispatch({type: 'GET_PROFESSIONAL_LIST_REQUEST'})
    axios({
        method: 'GET',
        headers:authHeader(),
        url: baseUrl,
        data: values
    }).then((res) => {
        dispatch({type: 'GET_PROFESSIONAL_LIST_SUCCESS', payload: {
            professionals:res.data.list
        }});
    }).catch((err) => {
        console.log("error",err);
        dispatch({type: 'GET_PROFESSIONAL_LIST_FAILED', payload: err});
        handlingAuthErrors(err);
    })
}