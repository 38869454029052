export const customerReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_CUSTOMER_DETAIL_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_CUSTOMER_DETAIL_SUCCESS':
            return {
                user: action?.payload?.user,
                customerMembership: action?.payload?.customerMembership,
                loading: false,
                success: true
            }
        case'GET_CUSTOMER_DETAIL_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        default:
            return state
    }
}