export const professionalListReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_PROFESSIONAL_LIST_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_PROFESSIONAL_LIST_SUCCESS':
            return {
                ...state,
                professionals:action.payload.professionals,
                loading: false,
                success: true
            }
        case'GET_PROFESSIONAL_LIST_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        default:
            return state
    }
}