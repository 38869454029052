import * as React from 'react'
import { Link } from "react-router-dom";

function PageNotFound() {
    return (

        <div className='errorPage'>
            <div className='pageNotFound'>
            <div className='errorImage'>
                <img
                    src="/images/404-2.webp" width="500"
                    className=" mx-auto"
                    alt="404"
                />
            </div>
            <div className='errorText'>
                <h2 className=" mb-3 	">
                404 Error Causes 
                </h2>
                <ul>
                    <li>A misspelled URL</li>
                    <li>The page has been moved or deleted and there's no redirect set up</li>
                    <li>The URL wasn't correct when it was originally set up or it was linked
                    incorrectly</li>
                </ul>
                <Link to={"/"}
                    className="btn btn-primary ">
                    Go to Home
                </Link>
            </div>
        </div>
        </div>


    );
}

export default PageNotFound

