import React, {useState, useEffect, useRef} from "react";
import Modal from "react-bootstrap/Modal";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getCovidGLDataAction} from "../../../../actions/tcDataAction";
import AddressAddModal from "../../../my_account/Address/AddressAddModal";
import {
  BuyMembership,
  BuyOnline,
  stepOneBooking,
  stepTwoBooking
} from "../../../../helpers/bookingAPIsteps";
import {useNavigate} from "react-router-dom";
import {Check} from "@material-ui/icons";
import {buyMemberShipAction} from "../../../../actions/buyMemberShipAction";
import {hasStripePayment} from "../../../../helpers/helpers";
import {useCountryCurrency} from "../../../../helpers/useCountryCurrency";
import {toast} from "react-toastify";
// import QnbPayment from "../payment/QnbPayment";
const moment = require("moment");

const NextBackButton = ({
  checkoutData,
  updateCheckoutData,
  submitAppointment,
  loginSignupRef,
  setCheckoutData,
  serviceInfo,
  setBookingSuccessModal,
  setBookingData,
  isLoggedIn,
  isLocation,
  setIsLocation
}) => {
  const {t} = useTranslation();
  const getCovidGLState = useSelector((state) => state.getTCReducer);
  const {loading, covid_guidlines} = getCovidGLState;
  const getAllCountryState = useSelector((state) => state.countryReducer);
  const {country_list} = getAllCountryState;
  const getCleanerTimeAvailabilitySlot = useSelector(
    (state) => state.cleanerTimeAvailabilityReducer
  );
  const countryCurrency = useCountryCurrency();
  const getRHClubState = useSelector((state) => state.rhClubReducer);
  const {rh_club} = getRHClubState;
  // console.log("customerMembership", rh_club)
  const {available_time, selectedCleaner} = getCleanerTimeAvailabilitySlot;
  const [isBuyingSuccessfully, setIsBuyingSuccessfully] = useState(false);
  // const getAppointmentState = useSelector((state) => state.state.appointmentReducer);
  // const { status  } =  getAppointmentState

  const dispatch = useDispatch();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let listUrl = baseUrl + "/staticPage/info/covid";

  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  // const currentLanguageCode = localStorage.getItem("currentLanguage")
  //   ? localStorage.getItem("currentLanguage")
  //   : "en";

  // const appointmentdata123 = JSON.parse(localStorage.getItem("appointment"))
  //   ? JSON.parse(localStorage.getItem("appointment"))
  //   : {};

  useEffect(() => {
    dispatch(getCovidGLDataAction(listUrl, {}));
  }, [listUrl, dispatch]);

  const [bookingSummaryInfoShow, setBookingSummaryInfoShow] = useState(false);
  const handleSetBookingSummaryInfoShow = () => setBookingSummaryInfoShow(true);
  const handlesetBookingSummaryInfoHide = () =>
    setBookingSummaryInfoShow(false);

  const [covidGLInfoShow, setCovidGLInfoShow] = useState(false);
  const handleSetCovidGLInfoShow = () => setCovidGLInfoShow(true);
  const handleSetCovidGLInfoHide = () => setCovidGLInfoShow(false);
  const setAddAddressRef = useRef();
  const navigate = useNavigate();
  const handelBuyMembership = async () => {
    let rh_club_id = localStorage.getItem("userCountryId");

    const response = await BuyMembership({
      baseUrl: baseUrl + "/customer/buy-rhclub-membership",
      data: {
        rh_club_id: rh_club_id,
        source_id: "useCard"
      }
    });
    if (response.code === 200) {
      return true;
    } else {
      return false;
    }
  };
  // Function to check if a country has the "Stripe" payment type

  const handelPayOnline = async () => {
    let price = parseFloat(checkoutData?.totalPrice) || 0;
    // Use Number() instead of conditional operator to handle non-numeric values
    price +=
      Number(checkoutData?.CodCharges) > 0
        ? Number(checkoutData.CodCharges)
        : 0;
    price +=
      Number(checkoutData?.rushSlot) > 0 ? Number(checkoutData.rushSlot) : 0;
    price -=
      Number(checkoutData?.total_discount) > 0 && Number(checkoutData?.page) > 5
        ? Number(checkoutData.total_discount)
        : 0;
    price +=
      checkoutData?.isBuyMemberShipRadio === true
        ? countryCurrency[0]?.rhPrice
        : 0;
    price +=
      checkoutData?.holidayPrice > 0 ? Number(checkoutData.holidayPrice) : 0;
    price -=
      checkoutData?.voucherDiscount > 0
        ? Number(checkoutData.voucherDiscount)
        : 0;
    price -=
      checkoutData?.rh_discount > 0 ? Number(checkoutData.rh_discount) : 0;

    if (checkoutData?.wallet_amount > 0 && checkoutData?.isWallet) {
      price = checkoutData?.isWallet
        ? Math.max(price - Number(checkoutData?.deducted_amount), 0)
        : price;
    }
    let onlinePayUrl = `${baseUrl}/appointment/generate/payment/link/`;

    // Convert the object to a JSON string
    const checkoutDataString = JSON.stringify(checkoutData);
    localStorage.setItem("checkoutData", checkoutDataString);
    const data = {
      amount: price,
      name: currentUser?.name,
      phone: `${currentUser?.country_code}${currentUser?.mobile}`,
      email: currentUser.email,
      reference_no: checkoutData?.appointment_no,
      custom: checkoutData?.isBuyMemberShipRadio
        ? "memberShipAppointment"
        : "appointment",
      tokenId: checkoutData?.tokenId ? checkoutData?.tokenId : "",
      isStripe: hasStripePayment(checkoutData?.country_id, country_list)
        ? true
        : false
    };
    try {
      const stepOneData = await BuyOnline({
        baseUrl: onlinePayUrl,
        data
      });
      if (stepOneData.paymentRequire === false) {
        window.location.href = stepOneData?.data?.successUrl;
      } else if (stepOneData?.data) {
        window.location.href = stepOneData?.data?.payUrl;
      }
    } catch (error) {
      // Handle errors here
      toast.error(error, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      console.error("stepOneData-error", error);
    }
  };

  const backButtonClick = () => {
    navigate(`/frequency/checkout/details?step=${checkoutData?.page - 1}`);
    if (!isLoggedIn && checkoutData?.page === 2) {
      navigate("/");
    }
    if (checkoutData.page === 5) {
      setCheckoutData((prevState) => ({
        ...prevState,
        pay_type: "",
        payment_mode: "",
        payment_terms_condition: false,
        CodCharges: "",
        isWallet: false,
        deducted_amount: 0,
        wallet_amount: 0,
        rhClub: false,
        rh_discount: 0,
        totalPrice:
          checkoutData?.frequency_name === "Weekly"
            ? checkoutData?.total_amount
            : checkoutData.total_per_display_amount
      }));
    }
    updateCheckoutData("page", checkoutData?.page - 1);
  };

  const nextButtonClick = async (checkoutData) => {
    let serviceId = localStorage.getItem("serviceId");
    if (!isLoggedIn && checkoutData.page !== 5) {
      updateCheckoutData("page", checkoutData?.page + 1);
      navigate(`/frequency/checkout/details?step=${checkoutData.page + 1}`);
      setCheckoutData((prevState) => ({
        ...prevState,
        wayOfBooking: "withoutLogin"
      }));
    } else if (
      checkoutData.wayOfBooking === "withoutLogin" &&
      checkoutData.page === 5
    ) {
      const data = {
        address_id: checkoutData?.address_id,
        is_home_cleaning: true,
        service_id: checkoutData?.service_id
          ? checkoutData?.isBookNow
            ? [checkoutData?.service_id]
            : checkoutData?.service_id
          : [serviceInfo?._id],
        country_id: checkoutData?.country_id,
        appointment_process: 1,
        appointment_id: ""
      };
      try {
        const stepOneData = await stepOneBooking({
          baseUrl: baseUrl + "/appointment/create/address",
          data
        });
        //
        if (stepOneData) {
          const stepTwoData = await stepTwoBooking({
            baseUrl: baseUrl + "/appointment/create/frequency",
            data: {
              appointment_id: stepOneData?._id,
              frequency_id: checkoutData?.frequency_id,
              appointment_process: 2,
              hours_count: checkoutData?.hours_count
                ? checkoutData?.hours_count
                : 4
            }
          });
          setCheckoutData((prevState) => ({
            ...prevState,
            appointment_id: stepOneData._id
          }));
          if (stepTwoData) {
            const stepThreeData = await stepTwoBooking({
              baseUrl: baseUrl + "/appointment/create/service",
              data: {
                appointment_id: stepTwoData?._id,
                appointment_process: 3,
                booking_instruction: checkoutData?.booking_instruction,
                is_cleaning_materials: checkoutData?.is_cleaning_materials,
                hours_count: checkoutData?.hours_count,
                professionals_count: checkoutData?.professionals_count,
                cleaners_id: checkoutData?.cleaners_id
                  ? checkoutData?.cleaners_id
                  : [],
                is_auto_assign: checkoutData?.is_auto_assign
              }
            });
            if (stepThreeData) {
              // console.log(
              //   "checkoutData?.appointment_date_start",
              //   checkoutData?.appointment_date_start
              // );
              const dateOnlyRegex = /^\d{4}-\d{2}-\d{2}$/;
              const stepFourData = await stepTwoBooking({
                baseUrl: baseUrl + "/appointment/create/time",
                data: {
                  appointment_id: stepThreeData?._id,
                  appointment_process: 4,
                  appointment_date_start: dateOnlyRegex.test(
                    checkoutData?.appointment_date_start
                  )
                    ? `${checkoutData?.appointment_date_start}  ${
                        checkoutData?.start_time_between?.split("-")[0]
                      }`
                    : checkoutData?.appointment_date_start
                    ? checkoutData?.appointment_date_start
                    : dateOnlyRegex.test(checkoutData?.appointment_date)
                    ? `${checkoutData?.appointment_date}  ${
                        checkoutData?.start_time_between?.split("-")[0]
                      }`
                    : checkoutData?.appointment_date,
                  start_time_between: checkoutData?.start_time_between,
                  cleaner_id: checkoutData?.is_auto_assign
                    ? selectedCleaner
                    : checkoutData?.cleaner_id
                    ? checkoutData?.cleaner_id
                    : ""
                }
              });
              if (stepFourData) {
                setCheckoutData((prevState) => ({
                  ...prevState,
                  appointment_id: stepFourData._id,
                  country_id: stepFourData?.country_id,
                  wayOfBooking: "loggedIn"
                }));
              }
            }
          }
        }
      } catch (error) {
        // Handle errors here
        console.error("stepOneData-error", error);
      }
    } else if (checkoutData?.page === 1) {
      const data = {
        address_id: checkoutData?.address_id,
        is_home_cleaning: true,
        service_id: checkoutData?.service_id?.length > 0
          ? checkoutData?.isBookNow
            ? [checkoutData?.service_id]
            : checkoutData?.service_id
          : serviceInfo?._id,
        country_id: checkoutData?.country_id,
        appointment_process: 1,
        appointment_id: ""
      };
      try {
        const stepOneData = await stepOneBooking({
          baseUrl: baseUrl + "/appointment/create/address",
          data
        });
        // console.log("stepOneData", stepOneData);
        if (stepOneData) {
          setCheckoutData((prevState) => ({
            ...prevState,
            appointment_id: stepOneData?._id ? stepOneData?._id : "",
            service_id: stepOneData?.service_id ? stepOneData?.service_id : "",
            is_home_cleaning: stepOneData?.is_home_cleaning
              ? stepOneData?.is_home_cleaning
              : true,
            country_id: stepOneData?.country_id
              ? stepOneData?.country_id
              : localStorage.getItem("userCountryId"),
            address_id: stepOneData?.booking_address?._id
              ? stepOneData?.booking_address?._id
              : "",
            address: stepOneData?.booking_address?.address
              ? stepOneData?.booking_address?.address
              : "",
            latitude: stepOneData?.booking_address?.latitude
              ? stepOneData?.booking_address?.latitude
              : 0.0,
            longitude: stepOneData?.booking_address?.longitude
              ? stepOneData?.booking_address?.longitude
              : 0.0,
            customer_service_id: stepOneData?.customer_id
              ? stepOneData?.customer_id
              : "",
            address_nickname: stepOneData?.booking_address?.address_nickname
              ? stepOneData?.booking_address?.address_nickname
              : "",
            area: stepOneData?.booking_address?.area
              ? stepOneData?.booking_address?.area
              : "",
            address_type: stepOneData?.booking_address?.address_type
              ? stepOneData?.booking_address?.address_type
              : "",
            street: stepOneData?.booking_address?.street
              ? stepOneData?.booking_address?.street
              : "",
            building: stepOneData?.booking_address?.building
              ? stepOneData?.booking_address?.building
              : "",
            floor: stepOneData?.booking_address?.floor
              ? stepOneData?.booking_address?.floor
              : "",
            house_no: stepOneData?.booking_address?.house_no
              ? stepOneData?.booking_address?.house_no
              : "",
            Office_no: stepOneData?.booking_address?.Office_no
              ? stepOneData?.booking_address?.Office_no
              : "",
            apartment_no: stepOneData?.booking_address?.apartment_no
              ? stepOneData?.booking_address?.apartment_no
              : "",
            appointment_no: stepOneData?.appointment_no
              ? stepOneData?.appointment_no
              : ""
          }));
          updateCheckoutData("page", 2);
          navigate(`/frequency/checkout/details?step=${2}`);
          dispatch({
            type: "GET_BOOK_APPOINTMENT_SUCCESS",
            payload: {appointment: stepOneData},
            status: 200
          });
        }
      } catch (error) {
        // Handle errors here
        console.error("stepOneData-error", error);
      }
    } else if (checkoutData?.page === 2) {
      const data = {
        appointment_id: checkoutData?.appointment_id,
        frequency_id: checkoutData?.frequency_id,
        appointment_process: 2,
        hours_count: checkoutData?.hours_count ? checkoutData?.hours_count : 4
      };
      try {
        const stepTwoData = await stepTwoBooking({
          baseUrl: baseUrl + "/appointment/create/frequency",
          data
        });
        if (stepTwoData) {
          setCheckoutData((prevState) => ({
            ...prevState,
            frequency_id: stepTwoData?.frequency_id,
            frequency_no: stepTwoData?.frequency_no,
            frequencyDiscountPrice: stepTwoData?.discount_price,
            total_amount: stepTwoData?.total_amount,
            total_discount: stepTwoData?.total_discount,
            total_frequency_amount: stepTwoData?.total_frequency_amount,
            total_per_display_amount: stepTwoData?.total_per_display_amount,
            total_per_paid_amount: stepTwoData?.total_per_display_amount,
            per_order_service_total: stepTwoData?.per_order_service_total,
            appointment_no: stepTwoData?.appointment_no
              ? stepTwoData.appointment_no
              : ""
          }));
          updateCheckoutData("page", 3);
          navigate(`/frequency/checkout/details?step=${3}`);
        }
      } catch (error) {
        // Handle errors here
        console.error("stepOneData-error", error);
      }
    } else if (checkoutData?.page === 3) {
      const data = {
        appointment_id: checkoutData?.appointment_id,
        appointment_process: 3,
        booking_instruction: checkoutData?.booking_instruction,
        is_cleaning_materials: checkoutData?.is_cleaning_materials,
        hours_count: checkoutData?.hours_count,
        professionals_count: checkoutData?.professionals_count,
        cleaners_id: [],
        is_auto_assign: true
      };
      try {
        const stepThreeData = await stepTwoBooking({
          baseUrl: baseUrl + "/appointment/create/service",
          data
        });

        if (stepThreeData) {
          setCheckoutData((prevState) => ({
            ...prevState,
            hours_count: stepThreeData?.hours_count
              ? stepThreeData?.hours_count
              : 3,
            total_discount: stepThreeData?.total_discount,
            total_frequency_amount: stepThreeData?.total_frequency_amount,
            total_per_display_amount: stepThreeData?.total_per_display_amount,
            total_per_paid_amount: stepThreeData?.total_per_display_amount,
            per_order_service_total: stepThreeData?.per_order_service_total,
            appointment_no: stepThreeData?.appointment_no
              ? stepThreeData?.appointment_no
              : ""
          }));
          updateCheckoutData("page", 4);
          navigate(`/frequency/checkout/details?step=${4}`);
        }
      } catch (error) {
        // Handle errors here
        console.error("stepOneData-error", error);
      }
    } else if (checkoutData?.page === 4) {
      const data = {
        appointment_id: checkoutData?.appointment_id,
        appointment_process: 4,
        appointment_date_start: checkoutData?.appointment_date_start
          ? checkoutData?.appointment_date_start
          : checkoutData?.appointment_date,
        start_time_between: checkoutData?.start_time_between,
        cleaner_id: checkoutData?.is_auto_assign
          ? selectedCleaner
          : checkoutData?.cleaner_id
          ? checkoutData?.cleaner_id
          : ""
      };
      try {
        const stepFourData = await stepTwoBooking({
          baseUrl: baseUrl + "/appointment/create/time",
          data
        });

        if (stepFourData) {
          setCheckoutData((prevState) => ({
            ...prevState,
            is_auto_assign: stepFourData?.is_auto_assign
              ? stepFourData?.is_auto_assign
              : true,
            total_discount: stepFourData?.total_discount,
            total_frequency_amount: stepFourData?.total_frequency_amount,
            total_per_display_amount: stepFourData?.total_per_display_amount,
            total_per_paid_amount: stepFourData?.total_per_paid_amount,
            per_order_service_total: stepFourData?.per_order_service_total,
            total_amount: stepFourData?.total_amount,
            appointment_no: stepFourData?.appointment_no
              ? stepFourData?.appointment_no
              : ""
          }));
          updateCheckoutData("page", 5);
          navigate(`/frequency/checkout/details?step=${5}`);
        }
      } catch (error) {
        // Handle errors here
        console.error("stepOneData-error", error);
      }
    } else if (checkoutData?.page === 5) {
      const isBuyingDone =
        checkoutData?.isBuyMemberShipRadio === true
          ? await handelBuyMembership()
          : true;
      const data = {
        appointment_id: checkoutData?.appointment_id,
        appointment_process: 5,
        discount_type: checkoutData?.discount_type
          ? checkoutData?.discount_type
          : "",
        payment_mode: checkoutData?.payment_mode,
        pay_type: checkoutData?.pay_type,
        voucher: checkoutData?.voucher ? checkoutData?.voucher : "",
        promo_code: checkoutData?.promo_code ? checkoutData?.promo_code : "",
        source_id: checkoutData?.source_id ? checkoutData?.source_id : "",
        customer_id: checkoutData.customer_id
          ? checkoutData.customer_id
          : currentUser.id,
        country_id: checkoutData?.country_id,
        is_home_cleaning: checkoutData?.is_home_cleaning ? 1 : 0,
        service_type: checkoutData?.is_home_cleaning ? "Home" : "Baby",
        isWallet: checkoutData?.isWallet
      };
      if (isBuyingDone === true) {
        try {
          const stepFiveData = await stepTwoBooking({
            baseUrl: baseUrl + "/appointment/create/payment",
            data
          });
          if (stepFiveData) {
            setBookingSuccessModal(true);
            setBookingData(stepFiveData);
          }
        } catch (error) {
          // Handle errors here
          console.error("stepOneData-error", error);
        }
      }
    }
  };

  return (
    <>
      <div className="row servicePackage">
        <div className="col-lg-7">
          <div className="actionButton actionBTn">
            <button
              disabled={checkoutData?.page === 1}
              className=" btn-back"
              // onClick={() => changeStep("Previous", checkoutData.page)}
              onClick={() => backButtonClick(checkoutData)}
            >
              <img
                src="https://dvdljkkxpxqo3.cloudfront.net/images/back.png.webp"
                alt=""
              />
            </button>
            <div className="priceTotal">
              <p>Total</p>
              <h3>
                {checkoutData.currency}{" "}
                {checkoutData && checkoutData.totalPrice
                  ? checkoutData.totalPrice
                  : 0}
                <span
                  className="fa fa-angle-up ms-2"
                  onClick={() => handleSetBookingSummaryInfoShow()}
                ></span>
              </h3>
            </div>
            {(() => {
              let showButton = true;
              if (checkoutData.page === 1) {
                if (checkoutData.address_id === "") {
                  showButton = false;
                }
              }
              if (checkoutData.page === 2) {
                if (currentUser && checkoutData.actionStatus) {
                  showButton = true;
                }
              }
              if (
                checkoutData.page === 3 &&
                checkoutData.start_time_between === ""
              ) {
                if (currentUser) {
                  showButton = true;
                } else {
                  showButton = false;
                }
                if (currentUser === false) {
                  showButton = true;
                }
                // if(currentUser === false){
                //   // setAddAddressRef.current.handleAddress();
                // }
              }
              if (
                checkoutData.page === 4 &&
                checkoutData.payment_mode === "" &&
                checkoutData.discount_type !== "FreeCredit" &&
                parseInt(checkoutData.totalPrice) > 0
              ) {
                if (currentUser) {
                  if (checkoutData.start_time_between !== "") {
                    showButton = true;
                  } else {
                    showButton = false;
                  }
                } else {
                  if (currentUser === false && checkoutData?.latitude !== 0) {
                    showButton = true;
                  } else {
                    showButton = false;
                  }
                }
              }

              if (
                checkoutData.page === 5 &&
                checkoutData?.wayOfBooking !== "withoutLogin"
              ) {
                if (currentUser) {
                  if (
                    checkoutData.isWallet === true &&
                    checkoutData.payment_terms_condition &&
                    checkoutData?.totalPrice > 0
                  ) {
                    if (
                      (checkoutData.totalPrice != 0 &&
                        checkoutData.payment_mode === "online") ||
                      checkoutData.payment_mode === "cash"
                    ) {
                      showButton = true;
                    } else {
                      showButton = false;
                    }
                  } else if (
                    checkoutData.payment_mode !== "" &&
                    checkoutData.payment_terms_condition &&
                    checkoutData
                  ) {
                    showButton = true;
                  } else {
                    showButton = false;
                  }
                }
              } else {
                // if (
                //   checkoutData.page === 5 &&
                //   checkoutData?.wayOfBooking === "withoutLogin" &&
                //   !isLoggedIn
                // ) {
                //   loginSignupRef.current.handleLoginSignupModalShow();
                // }
                showButton = true;
              }

              if (showButton) {
                return (
                  <>
                    {checkoutData?.payment_terms_condition &&
                    checkoutData?.totalPrice > 0 &&
                    checkoutData.payment_mode === "Card" ? (
                      <button
                        className="btn btn-primary"
                        onClick={() => handelPayOnline(checkoutData)}
                      >
                        {t("complete")}
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary"
                        // onClick={() => changeStep("Next", checkoutData.page)}
                        onClick={() => nextButtonClick(checkoutData)}
                        disabled={
                          checkoutData.page === 4 &&
                          checkoutData.start_time_between === ""
                            ? true
                            : false
                        }
                      >
                        {checkoutData.page !== 5 ||
                        checkoutData?.wayOfBooking === "withoutLogin"
                          ? t("next")
                          : t("complete")}
                      </button>
                    )}

                    {/* : 
                  <QnbPayment/>} */}
                  </>
                );
              }
            })()}
          </div>
        </div>
      </div>

      <Modal
        show={covidGLInfoShow}
        onHide={handleSetCovidGLInfoHide}
        id="covidModal"
        backdrop="static"
        className="covidModal"
      >
        <Modal.Header>
          <Modal.Title>{t("health_declaration")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body p-0 ">
            <img
              src="https://dvdljkkxpxqo3.cloudfront.net/images/covid.jpg.webp"
              className="w-full "
              alt=""
            />
            <div className="p-4">
              {covid_guidlines ? (
                <div
                  className="accordion-body"
                  dangerouslySetInnerHTML={{
                    __html:
                      currentLanguage === "en"
                        ? covid_guidlines.content
                        : covid_guidlines.content_arabic
                  }}
                ></div>
              ) : (
                ""
              )}
            </div>
            <div className="p-4 border-top mt-2">
              <button
                className="btn btn-primary"
                onClick={() => handleSetCovidGLInfoHide()}
              >
                {t("i_confirm")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={bookingSummaryInfoShow}
        onHide={handlesetBookingSummaryInfoHide}
        id="bookingSummary"
        className="bookingSummary"
      >
        <Modal.Header closeButton>
          <Modal.Title>Booking Summary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body p-0 ">
            <div className="card ">
              <div className="card-content ">
                <p>City</p>
                <p>Duhail</p>
              </div>
              <div className="card-content ">
                <p>Service Details</p>
                <ul>
                  <li>
                    Frequency <span>{checkoutData.frequency_name}</span>
                  </li>
                  <li>
                    Duration <span>{checkoutData.hours_count} hours</span>
                  </li>
                  <li>
                    Number of Professionals{" "}
                    <span>{checkoutData.professionals_count}</span>
                  </li>
                  <li>
                    Material{" "}
                    <span>
                      {checkoutData.is_cleaning_materials ? "Yes" : "No"}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="card-content ">
                <p>Date & Time</p>
                <ul>
                  <li>
                    Professional{" "}
                    <span>
                      <div className="is-flex">
                        {checkoutData.professional_full_name
                          ? checkoutData.professional_full_name
                          : ""}
                        {checkoutData.professional_full_name !==
                          "Auto assign" &&
                        checkoutData.professional_full_name !== "" &&
                        checkoutData.professional_profile_image !== "" ? (
                          <img
                            src={checkoutData.professional_profile_image}
                            className="cleaner-image en"
                            alt=""
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </span>
                  </li>
                  <li>
                    Date{" "}
                    <span>
                      {checkoutData.appointment_date
                        ? moment(checkoutData.appointment_date, [
                            "YYYY-MM-DD"
                          ]).format("dddd, MMMM DD")
                        : ""}
                    </span>
                  </li>
                  <li>
                    Start Time{" "}
                    <span>
                      {checkoutData.start_time_between
                        ? checkoutData.start_time_between
                        : ""}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="card-content ">
                <p>Address </p>
                <ul>
                  <li>
                    {checkoutData && checkoutData.address
                      ? checkoutData.address
                      : ""}
                  </li>
                </ul>
              </div>
              {/* <div className="card-content ">
                <p>Price Details</p>
                <ul>
                  <li>
                    Price <span>{checkoutData.currency} {(checkoutData && checkoutData.price) ? checkoutData.price : 0}</span>
                  </li>
                  <li>
                  {checkoutData?.rhClub === 'true' ?
                  <>
                  {t('freecredit')}  <span>-{checkoutData?.currency} {Number((checkoutData && checkoutData?.rh_discount) ? checkoutData?.rh_discount : 0).toFixed(2)}</span>
               </>:""}
                  </li>
                  <li>
                    VAT <span>{checkoutData.currency} {(checkoutData && checkoutData.vatPrice) ? checkoutData.vatPrice : 0}</span>
                  </li>
                  <li>
                    Discount <span>-{checkoutData.currency} {(checkoutData && checkoutData.discountPrice) ? checkoutData.discountPrice : 0}</span>
                  </li>
                  
                  <li>
                    Total <span className="total">{checkoutData.currency} {
                    
                    (checkoutData && checkoutData.totalPrice &&  checkoutData?.rhClub !== 'false') ? checkoutData.totalPrice :
                    checkoutData.totalPrice =checkoutData.totalPrice - checkoutData?.rh_discount
                    }</span>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <AddressAddModal
        ref={setAddAddressRef}
        updateCheckoutData={updateCheckoutData}
      />
    </>
  );
};

export default NextBackButton;
