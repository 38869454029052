import axios from 'axios'
import {toast} from "react-toastify";
import {authHeader, handlingNonauthErrors} from "../helpers/helpers";

export const updateLanguageAction = (baseUrl,values,changeLanguage) => dispatch => {
    dispatch({type: 'POST_UPDATE_LANGUAGE_REQUEST'})
    axios({
        method: 'POST',
        headers:authHeader(),
        url: baseUrl,
        data: values
    }).then((res) => {
        dispatch({type: 'POST_UPDATE_LANGUAGE_SUCCESS', payload: {
            current_language:values.current_language
        }});
        changeLanguage(values.current_language);
    }).catch((err) => {
        dispatch({type: 'POST_UPDATE_LANGUAGE_FAILED', payload: err});
        handlingNonauthErrors(err)
    });
}