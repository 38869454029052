import React, {  useEffect, useState } from "react";
import {Link} from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import AppDownload from "./AppDownload";
import Loader from "./Loader";
import {useDispatch, useSelector} from "react-redux";
import { getTCDataAction } from "../actions/tcDataAction";
import { useTranslation } from 'react-i18next';

const RHClubPolicy = () => {
    const getTCDataState = useSelector(state => state.getTCReducer)
    const { loading, tc_data } = getTCDataState;
    const dispatch = useDispatch();
    let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
    let listUrl = baseUrl + "/staticPage/info/rhClub";
    const [checked, setChecked] = useState(false)
    const currentLanguage = localStorage.getItem('currentLanguage') ? localStorage.getItem('currentLanguage') : 'en';
    useEffect(() => {
        dispatch(getTCDataAction(listUrl,{}));
    }, [listUrl,dispatch]);
  const { t } = useTranslation();
  const currentUser =
  localStorage.getItem("currentUser") &&
  localStorage.getItem("currentUser") !== "null"
    ? JSON.parse(localStorage.getItem("currentUser"))
    : false;
  
    const handleChange = (e) =>{
      e.preventDefault();
      setChecked(!checked);
    }
  return (
    <>
      <Header />
      {loading ? <Loader /> : ""}
      <div className="container">
        <div className="pt-5">
          <div className="heading">
            <h5><Link to="/">{ t('home') }</Link></h5> 
            <h2>{ t('club_policy') }</h2> 
          </div>
          <div className="card">
            {tc_data ?
            <div className="accordion-body" dangerouslySetInnerHTML={ { __html: (currentLanguage === "en" ? tc_data.content : tc_data.content_arabic)}}>
            </div>
            : <div className="accordion-body content_not_found"><div className="col-12"><h6 className="text-center content_not_found_text">{ t('content_not_found') }</h6></div></div>}
          </div>
          <div className="">
          <h2><Link to="/rh-club/policy/details"  onClick={() => window.scrollTo(0, 0)}>{ t('Rh_club_policy_details') }</Link></h2> 
           
          </div>
          <div className="checkboxItem paymentLabel ">
           
            {currentUser?.isRhMember === false ?
             <label>
             <input
            id="inputRhPolicy"
            type="checkbox"
            className="me-2 "
            checked={checked}
            onChange={handleChange}
            />
            Buy RH Club Policy
            </label>
            :""
           
          } 
          { checked && ( <Link to="/my-account/righthandcredit">
                <button className="btn btn-primary">{t('Buy_Now')}</button>
            </Link>)}
        </div>
        <div>
          
       
        </div>
        </div>
      </div>
      <AppDownload />
      <Footer />
    </>
  );
};

export default RHClubPolicy;
