import React, { useState, useEffect } from "react";
import {Link} from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import AppDownload from "./AppDownload";
import Loader from "./Loader";
import {useDispatch, useSelector} from "react-redux";
import { getFAQListAction } from "../actions/faqDataAction";
import { useTranslation } from 'react-i18next';

const FAQ = () => {
    const [toggleState, setToggleState] = useState(2);
    const toggleTab = (index) => {  setToggleState(index);  };
    const getFAQListState = useSelector(state => state.getFAQReducer)
    const { loading, faq_list } = getFAQListState;
    const dispatch = useDispatch();
    let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
    let listUrl = baseUrl + "/faq/category/question/list";
    //listUrl = "https://betaapi.myrighth.com/api/v1/faq/category/question/list";
    const currentLanguage = localStorage.getItem('currentLanguage') ? localStorage.getItem('currentLanguage') : 'en';
    useEffect(() => {
        dispatch(getFAQListAction(listUrl,{}));
    }, [listUrl]);

  const { t } = useTranslation();
  return (
    <>
      <Header />
      {loading ? <Loader /> : ""}
      <div className="container">
        <div className="pt-5">
          <div className="heading">
            <h5><Link to="/">{ t('home') }</Link></h5> 
            <h2>{ t('frequently_asked_questions') }</h2> 
          </div>
          <div className="card">
            <div className="faqPage faqsec">
              <div className="faqtab ">
              {faq_list?.length > 0 ?
                faq_list.map((faq, index) => { 
                 if (faq.questions?.length > 0) {
                       let itemKey = index + 1;
                       return (
                       <button key={itemKey} 
                       className={toggleState === itemKey ? "tabBtn tabBtn-active" : "tabBtn"}
                       onClick={() => toggleTab(itemKey)}>
                           {(currentLanguage === "en" ? faq.name : faq.name_arabic)}
                       </button>
                       )
                  }
                }): ""}
              </div>

              <div className="faqTabContent">
              {faq_list?.length > 0 ?
                faq_list.map((faq, index) => {
                let itemKey = index + 1;
                let faqQuestions = faq.questions;
                return (
                <div key={itemKey} className={toggleState === itemKey ? "tabContent tabContentActive" : "tabContent"}>
                  <div className="accordion accordion-flush" id="accordionFlushExample">
                  {faqQuestions?.length > 0 ?
                  faqQuestions.map((question, index) => {
                  let item_key = question.id;
                  return (
                      <div className="accordion-item" key={item_key}>
                        <h2 className="accordion-header" id={"question_"+item_key}>
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#new_question_"+item_key}
                            aria-expanded="false"
                            aria-controls={"new_question_"+item_key}
                          > {(currentLanguage === "en" ? question.question : question.question_arabic)}</button>
                        </h2>
                        <div
                          id={"new_question_"+item_key}
                          className="accordion-collapse collapse"
                          aria-labelledby={"question_"+item_key}
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body" dangerouslySetInnerHTML={ { __html: (currentLanguage === "en" ? question.answer : question.answer_arabic)}}>
                          </div>
                        </div>
                      </div>

                      )}): ""}

                    </div>
                  </div>
                )}): ""}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppDownload />
      <Footer />
    </>
  );
};

export default FAQ;
