import React, {useEffect, useRef} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getServiceDetailsAction} from "../actions/serviceDetailsAction";
import LoginSignupModal from "./LoginSignup/LoginSignupModal";
import Header from "./Header";
import Footer from "./Footer";
import AppDownload from "./AppDownload";
import ServiceBanner from "./ServiceBanner";
import Loader from "./Loader";
import {useTranslation} from "react-i18next";
import jQuery from "jquery";
import {toast} from "react-toastify";

const ServiceDetails = () => {
  let currentLanguageCode = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  let navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const loginSignupRef = useRef();
  const getServiceInfoState = useSelector(
    (state) => state.serviceDetailsReducer
  );
  const {loading, service} = getServiceInfoState;
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let infoUrl = baseUrl + "/service/details/" + params.serviceDetailId;
  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  useEffect(() => {
    dispatch(getServiceDetailsAction(infoUrl));
  }, [infoUrl, dispatch]);

  function viewServiceDetails(id, ui_type) {
    if (currentUser) {
      jQuery("html, body").animate({scrollTop: 0}, "slow");
      localStorage.setItem("serviceDetailId", id);
      if (ui_type === "Frequency") {
        if (localStorage.getItem("frequencyAppointmentData") !== null) {
          localStorage.removeItem("frequencyAppointmentData");
        }
        localStorage.setItem("frequencyTypeServiceDetailId", id);
      } else {
        if (localStorage.getItem("frequencyAppointmentData") !== null) {
          localStorage.removeItem("frequencyAppointmentData");
        }
        if (
          localStorage.getItem("serviceDetailId") &&
          localStorage.getItem("serviceDetailId") !== id
        ) {
          localStorage.removeItem("bookAppointmentCartData");
          localStorage.removeItem("bookAppointmentDetails");
        }
        localStorage.setItem("serviceDetailId", id);
      }
      setTimeout(() => {
        if (ui_type === "Wheel") {
          navigate("/");
          toast.error(t("no_services_available"), {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        } else {
          navigate(`/frequency/checkout/details`, {
            state: {
              serviceId: id,
              isBookNow: false,
              isLogin: currentUser ? true : false,
              ui_type: ui_type
            }
          });
          // navigate((ui_type === "Frequency") ? {pathname:'/frequency/checkout/details',state:id} : '/service/checkout/details?step=1&service_id='+id);
        }
      }, 500);
    } else {
      loginSignupRef.current.handleLoginSignupModalShow();
    }
  }

  return (
    <>
      <Header />
      {loading ? <Loader /> : ""}
      <LoginSignupModal ref={loginSignupRef} />
      <div className="container">
        <div className="pt-5">
          <p>
            <Link to="/">{t("home")}</Link>{" "}
            <i className="fa fa-angle-right ms-2 me-2"></i>
            {service
              ? currentLanguageCode === "en"
                ? service.name
                : service.name_arabic
              : ""}
          </p>
          <div className="homeCleanBanner">
            <img
              src={
                service && service.icon
                  ? service.icon
                  : "https://dvdljkkxpxqo3.cloudfront.net/images/home-cleaning.jpg.webp"
              }
              alt=""
            />
            <div className="bannerContent">
              <div>
                <h1>
                  {service
                    ? currentLanguageCode === "en"
                      ? service.name
                      : service.name_arabic
                    : ""}
                </h1>
                <p>
                  {service
                    ? currentLanguageCode === "en"
                      ? service.description
                      : service.description_arabic
                    : ""}
                </p>
                <Link
                  to="#"
                  onClick={() =>
                    viewServiceDetails(
                      service ? service._id : "",
                      service ? service?.ui_type : ""
                    )
                  }
                >
                  <button className="btn btn-primary rounded">
                    {t("book_now")}
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="ServicePageContent">
            <h3>
              {service
                ? currentLanguageCode === "en"
                  ? service.description
                  : service.description_arabic
                : ""}
            </h3>
          </div>
        </div>
      </div>
      <ServiceBanner />
      <AppDownload />
      <Footer />
    </>
  );
};
export default ServiceDetails;
