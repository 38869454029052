import React, {useEffect, useRef, useState, useCallback} from "react";
import {Navigation, Pagination, Scrollbar, A11y} from "swiper";

import {Swiper, SwiperSlide} from "swiper/react";
// import Audio from './Audio';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {useNavigate, useParams, Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {getHomeMainService} from "../actions/homeAction";
// import Loader from "./Loader";
import LoginSignupModal from "./LoginSignup/LoginSignupModal";
import {toast} from "react-toastify";
import _debounce from "lodash/debounce";

const SwipeSlider = () => {
  const audio = new Audio("");
  /* const start = () => {audio.play();}; */
  const [isMainService, setIsMainService] = useState(true);
  const {t} = useTranslation();
  let navigate = useNavigate();
  const loginSignupRef = useRef();
  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  // const serviceId= localStorage.getItem('serviceDetailId');
  const getAllServicesState = useSelector((state) => state.homeReducer);
  const allStates = useSelector((state) => state);
  // console.log("getAllServicesState", allStates);
  const {loading, servicesResult, services, success} = getAllServicesState;
  // const [serviceType, setServiceType] = useState([]);
  const [mainService, setMainService] = useState(true);
  const [showServiceType, setShowServiceType] = useState(false);
  const [mainServiceList, setMainServiceList] = useState();
  const [serviceName, setServiceName] = useState(false);
  const [searchServiceName, setSearchServiceName] = useState();
  // const [ showService, setShowServie ] = useState([]);
  // const [ serviceDisplay, setServiceDisplay ] = useState(false);
  // const [ serviceResultStatus, setServiceResultStatus] = useState(false);

  const dispatch = useDispatch();

  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;

  // useEffect(() => {
  //   const countryID = localStorage.getItem("userCountryId");
  //   let listMainUrl =
  //     baseUrl +
  //     `/home/dashboard?country_id=${localStorage.getItem("userCountryId")}`;
  //   if (countryID) {
  //     dispatch(getHomeMainService(listMainUrl));
  //   }
  // }, []);
  useEffect(() => {
    let isMounted = true;
    const countryID = localStorage.getItem("userCountryId");
    let listMainUrl =
      baseUrl +
      `/home/dashboard?country_id=${localStorage.getItem("userCountryId")}`;
    if (countryID) {
      dispatch(getHomeMainService(listMainUrl));
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (isMainService) {
      setMainServiceList(services);
    }
  }, [services, isMainService]);
  const showDetailServices = (service) => {
    setMainServiceList(service.serviceTypes);
    setIsMainService(false);
  };
  async function viewServiceDetails(
    id,
    ui_type,
    subServicesCount,
    rhClub,
    serviceName,
    service
  ) {
    if (currentUser) {
      if (ui_type === "Frequency" || ui_type === "Wheel") {
        if (localStorage.getItem("frequencyAppointmentData") !== null) {
          localStorage.removeItem("frequencyAppointmentData");
        }
        if (localStorage.getItem("frequencyCheckoutData") !== null) {
          localStorage.removeItem("frequencyCheckoutData");
          localStorage.removeItem("bookAppointmentId");
        }
        localStorage.setItem("frequencyTypeServiceDetailId", id);
        localStorage.setItem("rhClub", rhClub);
        localStorage.setItem("serviceName", serviceName);
      } else {
        if (
          localStorage.getItem("serviceDetailId") &&
          localStorage.getItem("serviceDetailId") !== id
        ) {
          localStorage.removeItem("bookAppointmentCartData");
          localStorage.removeItem("bookAppointmentDetails");
        }
        localStorage.setItem("serviceDetailId", id);
        localStorage.setItem("rhClub", rhClub);
      }
      if (ui_type === "Frequency" || ui_type === "Wheel") {
        navigate("/frequency/checkout/details", {
          state: {serviceDetails: service, home: true, ui_type: ui_type}
        });
      } else if (ui_type === "Package" && subServicesCount === 0) {
        navigate("/");
      } else {
        navigate("/service/checkout/details?step=0&service_id=" + id, {
          state: {serviceDetails: service, home: true, ui_type: ui_type}
        });
      }
      if (ui_type === "Package" && subServicesCount === 0) {
        toast.error(t("no_services_available"), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      }
    
    }
  
    if (currentUser === false) {
      if (ui_type === "Frequency") {
        if (localStorage.getItem("frequencyAppointmentData") !== null) {
          localStorage.removeItem("frequencyAppointmentData");
        }
        localStorage.setItem("frequencyTypeServiceDetailId", id);
        localStorage.setItem("rhClub", rhClub);
        localStorage.setItem("serviceName", serviceName);
      } else {
        if (
          localStorage.getItem("serviceDetailId") &&
          localStorage.getItem("serviceDetailId") !== id
        ) {
          localStorage.removeItem("bookAppointmentCartData");
          localStorage.removeItem("bookAppointmentDetails");
        }
        localStorage.setItem("serviceDetailId", id);
        localStorage.setItem("rhClub", rhClub);
      }
      if (ui_type === "Frequency" || ui_type === "Wheel") {
        navigate("/frequency/checkout/details?step=2", {
          state: {
            serviceDetails: service,
            home: true,
            isLogin: false,
            ui_type: ui_type
          }
        });
      } else if (ui_type === "Package" && subServicesCount === 0) {
        navigate("/");
      } else {
        navigate("/service/checkout/details?step=0&service_id=" + id, {
          state: {
            serviceDetails: service,
            home: true,
            isLogin: false,
            ui_type: ui_type
          }
        });
      }
      // setTimeout(() => {

      //   navigate(
      //     ui_type === "Frequency" || ui_type === "Wheel"
      //       ? {pathname: "/frequency/checkout/details?step=2", state: id}
      //       : ui_type === "Package" && subServicesCount === 0
      //       ? "/"
      //       : {
      //           pathname: "/service/checkout/details?step=1&service_id=" + id,
      //           state: id
      //         }
      //   );
      //   window.scrollTo(0, 0);
      // }, 500);
      if (ui_type === "Package" && subServicesCount === 0) {
        toast.error(t("no_services_available"), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      }
    }
  }
  const emptyService = () => {
    let url = `${baseUrl}/home/dashboard?country_id=${localStorage.getItem(
      "userCountryId"
    )}`;
    dispatch(getHomeMainService(url));
    setServiceName(false);
    setMainService(true);
  };

  const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), []);
  function handleDebounceFn(inputValue, toggleState) {
    if (inputValue === "") {
      emptyService();
    } else {
      let serviceName = inputValue.toUpperCase();
      let url = `${baseUrl}/home/dashboard?country_id=${localStorage.getItem(
        "userCountryId"
      )}&name=${serviceName}`;
      dispatch(getHomeMainService(url));
      setServiceName(true);
      setMainService(false);
      setIsMainService(false);
    }
  }

  const handlerChangeService = useCallback((e) => {
    e.preventDefault();
    setSearchServiceName(e.target.value);
    debounceFn(e.target.value);
  }, []);

  const handlerMainService = (e) => {
    e.preventDefault();
    setMainService(true);
    setShowServiceType(false);
    emptyService();
    setSearchServiceName("");
    setIsMainService(true);
  };

  return (
    <>
      {/* {loading ? <Loader /> : ""} */}
      <LoginSignupModal ref={loginSignupRef} />
      <div className="serviceBanner w-full pt-7 " id="serviceShow">
        <div className="container">
          <div className="heading">
            <h2>{t("you_name_it_we_do_it")}</h2>
            <p>
              {t("pick_any_of_the_services_below")} {serviceName}
            </p>

            <div className="bannerSearch">
              <input
                type="text"
                className="form-control"
                value={searchServiceName}
                onChange={(e) => handlerChangeService(e)}
              />
              <span
                className={
                  currentLanguage === "en"
                    ? "fa fa-search bannerSearchspanLeft"
                    : "fa fa-search bannerSearchspanRight"
                }
              ></span>
            </div>
          </div>
          <span>{t("book_on_app")}</span>
          <div className=" burttonImage mt-6">
                  <a style={{"margin-right":"10px"}} href={'https://play.google.com/store/apps/details?id=com.righthands'}>
                    <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/google-play.webp" />
                  </a>
                  <a href={'https://apps.apple.com/in/app/right-hands/id1641289996'}>
                    <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/app-store.webp" />
                  </a>
                </div>
                <div className="btn-left">
            {!isMainService && (
              <button
                className="btn btn-primary mt-2"
                onClick={handlerMainService}
                style={{marginBottom: 5, cursor: "pointer", textAlign: "left"}}
              >
                Back
              </button>
            )}
          </div>
          {serviceName === false ? (
            <>
              <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={5}
                slidesPerView={6}
                navigation
                pagination={{clickable: true}}
                scrollbar={{draggable: true}}
                onSwiper={(swiper) => console.log("")}
                onSlideChange={() => console.log("")}
                breakpoints={{
                  0: {
                    slidesPerView: 2
                  },
                  640: {
                    slidesPerView: 2
                  },
                  768: {
                    slidesPerView: 4
                  },
                  810: {
                    slidesPerView: 5
                  },
                  1024: {
                    slidesPerView: 6
                  }
                }}
              >
                {mainServiceList?.length > 0
                  ? mainServiceList?.map((service, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <div
                            className="serviceSlider"
                            onClick={
                              () => 
                              isMainService ? showDetailServices(service) :
                              viewServiceDetails(
                                service?.id,
                                service?.ui_type,
                                service?.serviceCategoryCount,
                                service?.rhClub,
                                service?.name,
                                service
                              )
                            }
                          >
                            {!isMainService && (
                              <span>
                                <img
                                  src={
                                    service?.icon
                                      ? service?.icon
                                      : "https://dvdljkkxpxqo3.cloudfront.net/images/no_image.jpg.webp"
                                  }
                                  alt="window"
                                />
                              </span>
                            )}

                            <h3>
                              {currentLanguage === "en"
                                ? service.name
                                : service.name_arabic}
                            </h3>
                            <button className="btn btn-primary">
                              {t("book_now")}
                            </button>
                          </div>
                        </SwiperSlide>
                      );
                    })
                  : ""}
              </Swiper>
            </>
          ) : (
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={5}
              slidesPerView={6}
              navigation
              pagination={{clickable: true}}
              scrollbar={{draggable: true}}
              onSwiper={(swiper) => {}}
              onSlideChange={() => {}}
              breakpoints={{
                0: {
                  slidesPerView: 2
                },
                640: {
                  slidesPerView: 2
                },
                768: {
                  slidesPerView: 4
                },
                810: {
                  slidesPerView: 5
                },
                1024: {
                  slidesPerView: 6
                }
              }}
            >
              {servicesResult?.length > 0 && !mainService
                ? servicesResult.map((service, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div
                          className="serviceSlider"
                          disabled={service.serviceCategoryCount > 0 ? true: false}
                          onClick={() =>
                            viewServiceDetails(
                              service._id,
                              service.ui_type,
                              service.serviceCategoryCount,
                              service.rhClub,
                              service.name,
                              service
                            )
                          }
                        >
                          <span>
                            <img
                              src={
                                service?.icon
                                  ? service?.icon
                                  : "https://dvdljkkxpxqo3.cloudfront.net/images/no_image.jpg.webp"
                              }
                              alt="window"
                            />
                          </span>
                          <h3>
                            {currentLanguage === "en"
                              ? service.name
                              : service.name_arabic}
                          </h3>
                          {/* <p>{ currentLanguage === 'en' ? service.description : service.description_arabic  }</p> */}
                          {service.serviceCount === 0 ? "" : (<button className="btn btn-primary">
                            {t("book_now")}
                          </button>)}
                        </div>
                      </SwiperSlide>
                    );
                  })
                : ""}
            </Swiper>
          )}
        </div>
      </div>
    </>
  );
};

export default SwipeSlider;
