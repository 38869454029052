import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef
} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import LoginWithMobileModal from "./LoginWithMobileModal";
import SetupAddressModal from "./SetupAccountModals/SetupAddressModal";
import SetupProfile from "./SetupAccountModals/SetupProfile";
import AppointmentCartItemModal from "./SetupAccountModals/AppointmentCartItemModal";
import jQuery from "jquery";
import {useDispatch} from "react-redux";
import {
  loginEmailUserAction,
  signUpEmailUserAction
} from "../../actions/userActions";
import SignupModal from "../SignupModal";
import {toast} from "react-toastify";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import AppleLogin from "react-apple-login";
import {gapi} from "gapi-script";
import {commonLoginSuccess} from "../../helpers/helpers";
import AddressSetupComponent from "./SetupAccountModals/AddressSetupComponent";
import EmailInput from "../../helpers/EmailInput";

const LoginSignupModal = forwardRef((props, ref) => {
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  // let facebookAppId = "825719178678540";
  let facebookAppId = "889352129375161";
  let googleClientId =
    "395949074977-gsgdttv80ref43n64flph6bcsd5aq3ke.apps.googleusercontent.com";
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const loginWithMobileRef = useRef();
  const setAddressRef = useRef();
  const setProfileRef = useRef();
  const appointmentCartRef = useRef();
  const [loginWithGoogle, setLoginWithGoogle] = useState(false);
  const [loginWithFacebook, setLoginWithFacebook] = useState(false);
  const [loginWithApple, setLoginWithApple] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const handleInfoModalClose = () => setShowInfoModal(false);
  const [toggleState, setToggleState] = useState(1);
  const [showSetupAddressModal, setShowSetupAddressModal] = useState(false);
  const toggleTab = (index) => {
    setToggleState(index);
  };
  const [loginDataFields, setLoginDataFields] = useState({
    email_id: "",
    password: ""
  });
  const [signupModalShow, setSignupModalShow] = useState(false);
  const handleSignupModalHide = () => setSignupModalShow(false);
  const [cartAppointment, setCartAppointment] = useState({
    name: "amarjit"
  });
  const handelInputChange = (e) => {
    const {name, value} = e.target;
    setLoginDataFields({...loginDataFields,
      [name]:value
    });
  };

  const handleLoginWithEmail = (e) => {
    e.preventDefault();
    // console.log("loginDataFields", loginDataFields);
    let loginEmailUrl = baseUrl + "/customer/login";
    let email_id = jQuery("#email_id").val();
    let password = jQuery("#password").val();
    let values = {
      email: loginDataFields.email_id ? loginDataFields.email_id : email_id,
      password: loginDataFields.password ? loginDataFields.password : password,
      device_type: "website"
    };
    dispatch(loginEmailUserAction(loginEmailUrl, values, emailLoginSuccess,setShowInfoModal));
  };

  const handleSignUpWithEmail = (e) => {
    e.preventDefault();
    let signUpEmailUrl = baseUrl + "/customer/signup";
    let sign_up_email = jQuery("#sign_up_email").val();
    let sign_up_password = jQuery("#sign_up_password").val();
    let values = {
      email: sign_up_email,
      password: sign_up_password,
      device_type: "website"
    };
    dispatch(signUpEmailUserAction(signUpEmailUrl, values, emailLoginSuccess));
  };

  async function emailLoginSuccess(token, cart = null, msg) {
    commonLoginSuccess(
      token,
      cart,
      msg,
      handleInfoModalClose,
      setAddressRef,
      setProfileRef,
      appointmentCartRef,
      
    );
  }

  async function responseFacebook(response) {
    console.log("responseFacebook", response);
    let values = {
      name: response.name,
      email: response?.email ? response.email : response.id + "@facebook.com",
      // "mobile": response.id,
      provider_id: response.id,
      login_type: "Facebook",
      fcm_token: "",
      device_type: "website",
      profile_image: response.picture.data.url ? response.picture.data.url : ""
    };
    let socialLoginUrl = baseUrl + "/customer/social-login";
    dispatch(loginEmailUserAction(socialLoginUrl, values, emailLoginSuccess,setShowInfoModal));
  }

  async function responseApple(response) {
    if (response?.authorization?.id_token) {
      let id_token = response?.authorization?.id_token;
      let base64Data = id_token.split(".")[1];
      let decodedValue = JSON.parse(window.atob(base64Data));
      let values = {
        name:
          response?.user && response?.user?.name?.firstName
            ? response?.user?.name?.firstName +
              (response?.user?.name?.lastName
                ? " " + response?.user?.name?.lastName
                : "")
            : "",
        email: response?.user?.email
          ? response.user.email
          : decodedValue?.email,
        provider_id: decodedValue?.sub,
        login_type: "Apple",
        fcm_token: "",
        device_type: "website"
      };
      let socialLoginUrl = baseUrl + "/customer/social-login";
      dispatch(loginEmailUserAction(socialLoginUrl, values, emailLoginSuccess,setShowInfoModal));
    } else {
      console.error("something went wrong");
    }
  }

  async function responseSuccessGoogle(response) {
    if (response?.profileObj) {
      let values = {
        name: response.profileObj.name,
        email: response.profileObj.email,
        provider_id: response.profileObj.googleId,
        login_type: "Google",
        fcm_token: "",
        device_type: "website",
        profile_image: response.profileObj.imageUrl
          ? response.profileObj.imageUrl
          : ""
      };
      let socialLoginUrl = baseUrl + "/customer/social-login";
      dispatch(loginEmailUserAction(socialLoginUrl, values, emailLoginSuccess,setShowInfoModal,));
    }
  }

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: googleClientId,
        scope: "email"
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const [passwordShown2, setPasswordShown2] = useState(false);
  const togglePasswordVisiblity2 = () => {
    setPasswordShown2(passwordShown2 ? false : true);
  };
  useImperativeHandle(ref, () => ({
    handleLoginSignupModalHide() {
    setShowInfoModal(false);
      setLoginWithFacebook(false);
      setLoginWithGoogle(false);
      setLoginWithApple(false);
    }
    
 
 
}));

  useImperativeHandle(ref, () => ({
    handleLoginSignupModalShow() {
      setShowInfoModal(true);
      setLoginWithFacebook(false);
      setLoginWithGoogle(false);
      setLoginWithApple(false);
    }
  }));

  return (
    <div>
      {/* Login Modal */}
      <Modal
        show={showInfoModal}
        onHide={handleInfoModalClose}
        className="addAddressModal loginAddress"
        id="loginAddress"
      >
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body>
          <div className="loginForm">
            <ul>
              <li
                className={
                  toggleState === 1 ? "signBtn signBtn-active" : "signBtn"
                }
                onClick={() => toggleTab(1)}
              >
                {t("login")}
              </li>
              <li
                className={
                  toggleState === 2 ? "signBtn signBtn-active" : "signBtn"
                }
                onClick={() => toggleTab(2)}
              >
                {t("signup")}
              </li>
            </ul>
            <div className="loginTabArea">
              <div
                className={
                  toggleState === 1
                    ? "loginTabContent loginTabContentActive"
                    : "loginTabContent"
                }
              >
                <div className="loginArea">
                  <h2>{t("welcome_back")}</h2>
                  <div className="loginButtonGroup">
                    <button
                      className="btn btn-apple"
                      onClick={() =>
                        setLoginWithApple(loginWithApple ? false : true)
                      }
                    >
                      <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/apple.png.webp" />
                      {t("log_in_with_apple")}
                    </button>
                    <button
                      className="btn btn-facebook"
                      onClick={() =>
                        setLoginWithFacebook(loginWithFacebook ? false : true)
                      }
                    >
                      <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/facebook.png.webp" />
                      {t("continue_with_facebook")}
                    </button>
                    <button
                      className="btn btn-phone"
                      onClick={() => {
                        handleInfoModalClose();
                        loginWithMobileRef.current.handleLoginWithMobileModalShow();
                      }}
                    >
                      <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/phone.png.webp" />
                      {t("sign_in_with_mobile_number")}
                    </button>
                    <button
                      className="btn btn-google"
                      onClick={() =>
                        setLoginWithGoogle(loginWithGoogle ? false : true)
                      }
                    >
                      <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/google.png.webp" />
                      {t("sign_in_with_Google")}
                    </button>
                    {loginWithApple ? (
                      <AppleLogin
                        clientId="com.customer.web"
                        autoLoad={true}
                        redirectURI="https://betaweb.myrighth.com"
                        usePopup={true}
                        callback={responseApple} // Catch the response
                        scope="email name"
                        responseMode="query"
                        render={(renderProps) => (
                          <button
                            onClick={renderProps.onClick}
                            className="d-none"
                          >
                            <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/apple.png.webp" />
                            Continue with Apple
                          </button>
                        )}
                      />
                    ) : null}

                    {loginWithGoogle ? (
                      <GoogleLogin
                        clientId={googleClientId}
                        autoLoad={true}
                        className="d-none"
                        onSuccess={responseSuccessGoogle}
                        onFailure={() => {
                          console.log("Failed");
                        }}
                        cookiePolicy={"single_host_origin"}
                      />
                    ) : null}

                    {loginWithFacebook ? (
                      <FacebookLogin
                        appId={facebookAppId}
                        autoLoad={true}
                        cssClass={"d-none"}
                        fields="link,first_name,middle_name,name,birthday,last_name,email,gender,locale,verified,picture.height(2048),age_range"
                        isMobile={false}
                        callback={responseFacebook}
                      />
                    ) : null}
                  </div>
                  <div className="loginWithEmail">
                    <div className="text-center orLogin mb-5">
                      <h3>{t("or")}</h3>
                    </div>
                    <h3> {t("login_with_email")}</h3>

                    <div className="form-group mb-4">
                      <EmailInput
                        type="email"
                        name="email_id"
                        onChange={(e) => handelInputChange(e)}
                        className="form-control form-input-border"
                        id="email_id"
                        placeholder={t("email_address")}
                        maxLength="80"
                      />
                    </div>

                    <div className="form-group mb-5 changeEyeSignUp">
                      <EmailInput
                        type={passwordShown2 ? "text" : "password"}
                        className="form-control form-input-border"
                        id="password"
                        name="password"
                        onChange={(e) => handelInputChange(e)}
                        placeholder={t("password")}
                        maxLength="80"
                      />
                      <i
                        className={
                          passwordShown2 ? "fa fa-eye" : "fa fa-eye-slash"
                        }
                        onClick={togglePasswordVisiblity2}
                      ></i>
                    </div>

                    <div className="mt-5">
                      <button
                        type="submit"
                        className="btn btn-primary w-full"
                        onClick={(e)=> handleLoginWithEmail(e)}
                      >
                        {t("login_with_email")}
                      </button>
                    </div>

                    <div className="text-center text-xl">
                      <h3 className="mb-3">
                        {" "}
                        <Link to={"/customer/forgot-password"}>
                          {t("forgot_password")}
                        </Link>
                      </h3>
                      <p>
                        {t("dont_have_an_account")}{" "}
                        <Link to="" onClick={() => toggleTab(2)}>
                          {t("signup")}
                        </Link>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={
                  toggleState === 2
                    ? "loginTabContent loginTabContentActive"
                    : "loginTabContent"
                }
              >
                <div className="signupArea">
                  <h2>{t("sign_up_to_book_services")}</h2>
                  <div className="loginButtonGroup">
                    <button
                      className="btn btn-apple"
                      onClick={() => setLoginWithApple(true)}
                    >
                      <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/apple.png.webp" />
                      {t("sign_up_with_apple")}
                    </button>
                    <button
                      className="btn btn-facebook"
                      onClick={() => setLoginWithFacebook(true)}
                    >
                      <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/facebook.png.webp" />
                      {t("continue_with_facebook")}
                    </button>
                    <button
                      className="btn btn-phone"
                      onClick={() => {
                        handleInfoModalClose();
                        loginWithMobileRef.current.handleLoginWithMobileModalShow();
                      }}
                    >
                      <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/phone.png.webp" />
                      {t("sign_up_with_mobile_number")}
                    </button>
                    <button
                      className="btn btn-google"
                      onClick={() => setLoginWithGoogle(true)}
                    >
                      <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/google.png.webp" />
                      {t("sign_up_with_google")}
                    </button>
                  </div>
                  <div className="loginWithEmail">
                    <div className="text-center orLogin mb-5">
                      <h3>or</h3>
                    </div>

                    <div className="form-group mb-4">
                      <input
                        type="email"
                        className="form-control form-input-border"
                        id="sign_up_email"
                        placeholder={t("email_address")}
                        maxLength="80"
                      />
                    </div>

                    <div className="form-group mb-5 changeEyeSignUp">
                      <input
                        type={passwordShown ? "text" : "password"}
                        className="form-control form-input-border"
                        id="sign_up_password"
                        placeholder={t("password")}
                        maxLength="80"
                      />
                      <i
                        className={
                          passwordShown ? "fa fa-eye" : "fa fa-eye-slash"
                        }
                        onClick={togglePasswordVisiblity}
                      ></i>
                    </div>

                    <div className="mt-5">
                      <button
                        type="submit"
                        className="btn btn-primary w-full"
                        onClick={(e) => handleSignUpWithEmail(e)}
                      >
                        {t("sign_up_with_email")}
                      </button>
                    </div>

                    <div className="text-center text-xl">
                      <p>
                        {t("already_have_an_account")}{" "}
                        <Link to="" onClick={() => toggleTab(1)}>
                          {t("login")}
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="loginfooter">
              <h3 className="mb-2">{t("booked_over_phone_or_chat")} </h3>
              <p>{t("claim_your_online_account")}</p>
              <Link to={"/customer/claim-account"}>{t("claim_account")}</Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <SignupModal
        showSignupModal={signupModalShow}
        hideSignupModal={handleSignupModalHide}
      />

      <LoginWithMobileModal ref={loginWithMobileRef} />
      <AddressSetupComponent
        ref={setAddressRef}
        showSetupAddressModal={showSetupAddressModal}
        setShowSetupAddressModal={setShowSetupAddressModal}
      />
      {/* <SetupAddressModal ref={setAddressRef} /> */}
      <SetupProfile ref={setProfileRef} />
      <AppointmentCartItemModal ref={appointmentCartRef} />
    </div>
  );
});

export default LoginSignupModal;
