export const saveAddressModalData=(obj)=>{
    return (dispatch)=>{
        dispatch({
			type: "INITIATE DATA",
		});
    return new Promise((resolve,reject)=>{
        dispatch({
            type: "DATA SUCCESS",
            payload: obj,
        });
        return resolve(obj)
        .catch((err)=>{
            dispatch({
                type: "DATA FAILED",
            });
            return reject(err)
        })
    })
    }
}