export const blogsReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_BLOGS_LIST_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_BLOGS_LIST_SUCCESS':
            return {
                blogs_list:action.payload.blogs_list,
                loading: false,
                success: true
            }
        case'GET_BLOGS_LIST_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        case'GET_BLOGS_DETAIL_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_BLOGS_DETAIL_SUCCESS':
            return {
                blog:action.payload.blog,
                loading: false,
                success: true
            }
        case'GET_BLOGS_DETAIL_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }            
        default:
            return state
    }
}