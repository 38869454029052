import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Loader from "../../components/Loader";
import {ModifiedToastContainer} from "../../components/ModifiedToastContainer";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const Announcements = () => {

    const { t } = useTranslation();
    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (index) => {
      setToggleState(index);
    };

    return (
      <> 
        <Header />     
        <ModifiedToastContainer />
        <div className="py-5">
          <div className="userContainer annoouncments">
            <div className="editPersonalDetail">
              <div className="heading">
                <h2>{t('announcements')}</h2>                
              </div>
              <div className="card">
              <div className="appoitmentButton align-items-center justify-content-start  d-flex px-4"> 
             <button  className={toggleState === 1 ? "tabBtn me-4 tabBtn-active" : "tabBtn me-4" }  onClick={() => toggleTab(1)}>{t('announcements')}</button>
             <button  className={toggleState === 2 ? "tabBtn tabBtn-active" : "tabBtn"}  onClick={() => toggleTab(2)}>{t('favorites')} </button>
            </div>
            <div className="customerTabArea mt-3">
             <div className={toggleState === 1 ? "tabContent show" : "tabContent"}>
             <h4>{t('last_30_days')}</h4>
             <ul className="list">
                <li>
                    <div className="listContent">
                        <h3>Reporting Issues and Sharing Fee</h3>
                        <p>Reporting Issues and Sharing Fee</p>
                    </div>
                    <div>
                    <h5>12/12/2022</h5>
                    <span className="fa fa-star"></span>
                    </div>
                </li>
                <li>
                    <div className="listContent">
                        <h3>Reporting Issues and Sharing Fee</h3>
                        <p>Reporting Issues and Sharing Fee</p>
                    </div>
                    <div>
                    <h5>12/12/2022</h5>
                    <span className="fa fa-star"></span>
                    </div>
                </li>
                <li>
                    <div className="listContent">
                        <h3>Reporting Issues and Sharing Fee</h3>
                        <p>Reporting Issues and Sharing Fee</p>
                    </div>
                    <div>
                    <h5>12/12/2022</h5>
                    <span className="fa fa-star"></span>
                    </div>
                </li>                
             </ul>
             <h4>{t('oldest')}</h4>
             <ul className="list">
                <li>
                    <div className="listContent">
                        <h3>Reporting Issues and Sharing Fee</h3>
                        <p>Reporting Issues and Sharing Fee</p>
                    </div>
                    <div>
                    <h5>12/12/2022</h5>
                    <span className="fa fa-star"></span>
                    </div>
                </li>
                <li>
                    <div className="listContent">
                        <h3>Reporting Issues and Sharing Fee</h3>
                        <p>Reporting Issues and Sharing Fee</p>
                    </div>
                    <div>
                    <h5>12/12/2022</h5>
                    <span className="fa fa-star"></span>
                    </div>
                </li>                             
             </ul>
             </div>
             <div className={toggleState === 2 ? "tabContent show" : "tabContent"}>
             <h4>{t('last_10_days')}</h4>  
             <ul className="list">
                <li>
                    <div className="listContent">
                        <h3>Reporting Issues and Sharing Fee</h3>
                        <p>Reporting Issues and Sharing Fee</p>
                    </div>
                    <div>
                    <h5>12/12/2022</h5>
                    <span className="fa fa-star"></span>
                    </div>
                </li>
                <li>
                    <div className="listContent">
                        <h3>Reporting Issues and Sharing Fee</h3>
                        <p>Reporting Issues and Sharing Fee</p>
                    </div>
                    <div>
                    <h5>12/12/2022</h5>
                    <span className="fa fa-star"></span>
                    </div>
                </li>
                <li>
                    <div className="listContent">
                        <h3>Reporting Issues and Sharing Fee</h3>
                        <p>Reporting Issues and Sharing Fee</p>
                    </div>
                    <div>
                    <h5>12/12/2022</h5>
                    <span className="fa fa-star"></span>
                    </div>
                </li>                
             </ul>
             <h4>{t('oldest')}</h4>
             <ul className="list">
                <li>
                    <div className="listContent">
                        <h3>Reporting Issues and Sharing Fee</h3>
                        <p>Reporting Issues and Sharing Fee</p>
                    </div>
                    <div>
                    <h5>12/12/2022</h5>
                    <span className="fa fa-star"></span>
                    </div>
                </li>
                <li>
                    <div className="listContent">
                        <h3>Reporting Issues and Sharing Fee</h3>
                        <p>Reporting Issues and Sharing Fee</p>
                    </div>
                    <div>
                    <h5>12/12/2022</h5>
                    <span className="fa fa-star"></span>
                    </div>
                </li>                             
             </ul>
             </div>
            </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
}

export default Announcements
