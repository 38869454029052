import React, {useRef, useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import AppleLogin from "react-apple-login";
import {
  // addressSetupAction,
  loginEmailUserAction,
  signUpEmailUserAction
} from "../../../../actions/userActions";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {gapi} from "gapi-script";
import jQuery from "jquery";

import EmailInput from "../../../../helpers/EmailInput";
import {Link} from "react-router-dom";
import {addressSetup, socialLoginApi} from "../../../../helpers/bookingAPIsteps";
import ProfileSetupModal from "./profileSetupModal";
import LoginWithMobileModal from "../../../../components/LoginSignup/LoginWithMobileModal";
import SignupModal from "../../../../components/SignupModal";

const LoginSignupStep = ({
  checkoutData,
  setCheckoutData,
  isAddressAdded,
  setIsAddressAdded
}) => {
  const loginWithMobileRef = useRef();
  const {t} = useTranslation();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;

  let facebookAppId = "889352129375161";
  let googleClientId =
    "395949074977-gsgdttv80ref43n64flph6bcsd5aq3ke.apps.googleusercontent.com";

  const [toggleState, setToggleState] = useState(1);
  const [loginWithGoogle, setLoginWithGoogle] = useState(false);
  const [loginWithFacebook, setLoginWithFacebook] = useState(false);
  const [loginWithApple, setLoginWithApple] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [signupModalShow, setSignupModalShow] = useState(false);
  const [profileInfoModal, setProfileInfoModal] = useState(false);
  const [loginDataFields, setLoginDataFields] = useState({
    email_id: "",
    password: ""
  });
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });

  const [userSignUpEmail, setUserSignUpEmail] = React.useState("");
  const [userSignUpName, setUserSignUpName] = React.useState("");
  const [userMobile, setUserMobile] = React.useState("");
  const [passwordShown2, setPasswordShown2] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [isProfileFromMobile, setIsProfileFromMobile] = useState(false);
  const dispatch = useDispatch();
  const handleProfileInfoModalShow = () => setProfileInfoModal(true);
  const handleProfileInfoModalClose = () => setProfileInfoModal(false);
  const handleAddressModalShow = () => setShowAddressModal(true);
  const handleAddressModalClose = () => setShowAddressModal(false);
  const handleSignupModalHide = () => setSignupModalShow(false);
  async function handleProfileModalShow(address, msg) {
    setTimeout(() => {
      handleAddressModalClose();
      handleProfileInfoModalShow();
    }, 1000);
  }
  const addSetupAddress = async (currentUserDetail, token) => {
    let buldingNo =
      checkoutData?.addressData?.address_type === "Home"
        ? "house_no"
        : checkoutData?.addressData?.address_type === "Apartment"
        ? "apartment_no"
        : "office_no";
    if (currentUserDetail?._id) {
      let frm = {
        customer_id: currentUserDetail?._id ? currentUserDetail?._id : "",
        address_nickname: checkoutData?.addressData?.address_nickname
          ? checkoutData?.addressData?.address_nickname
          : "",
        area: checkoutData?.addressData?.area
          ? checkoutData?.addressData?.area
          : "",
        address_type: checkoutData?.addressData?.address_type,
        street: checkoutData?.addressData?.street
          ? checkoutData?.addressData?.street
          : "",
        building: checkoutData?.addressData?.building
          ? checkoutData?.addressData?.building
          : "",
        floor: checkoutData?.addressData?.floor
          ? checkoutData?.addressData?.floor
          : "",
        country_code: document?.getElementById("country_code")?.value
          ? document?.getElementById("country_code")?.value
          : checkoutData?.country_code
          ? checkoutData?.country_code
          : "",
        country_id: localStorage.getItem("userCountryId"),
        mobile: checkoutData?.addressData?.mobile
          ? checkoutData?.addressData?.mobile
          : "",
        landline_no: checkoutData?.addressData?.landline_no
          ? checkoutData?.addressData?.landline_no
          : "",
        additional_direction: checkoutData?.addressData?.additional_direction
          ? checkoutData?.addressData?.additional_direction
          : "",
        address: checkoutData?.addressData?.address
          ? checkoutData?.addressData?.address
          : "",
        [buldingNo]: checkoutData?.addressData?.house_no,
        latitude:
          checkoutData?.latitude !== undefined &&
          checkoutData?.latitude?.toString() !== ""
            ? checkoutData?.latitude?.toString()
            : localStorage.getItem("latitude")
            ? localStorage.getItem("latitude")?.toString()
            : "",
        longitude:
          checkoutData?.longitude !== undefined &&
          checkoutData?.longitude?.toString() !== ""
            ? checkoutData?.longitude?.toString()
            : localStorage.getItem("longitude")
            ? localStorage.getItem("longitude")?.toString()
            : "",
        isDefault: true
      };
      let apiAddressSetupUrl = baseUrl + "/customer/address/setup";
      const results = await addressSetup({
        baseUrl: apiAddressSetupUrl,
        data: frm
      });
      if (results.code === 200) {
        if (token) {
          localStorage.setItem("currentToken", JSON.stringify(token));
        }
        if(isProfileFromMobile){
          setUserSignUpEmail(currentUserDetail?.email);
          setUserSignUpName(currentUserDetail?.name);
          var mobileNumber = parseInt(currentUserDetail?.mobile);
          if(mobileNumber){
            setUserMobile(mobileNumber)
          }
          handleProfileInfoModalShow();
          // setIsAddressAdded(true);
        }else{
          setUserSignUpEmail(currentUserDetail?.email);
          setUserSignUpName(currentUserDetail?.name);
          handleProfileInfoModalShow();
        }
      
      }
      // console.log("Profile info modal",  results?.message, results?.status, results?.code)
      //  "Address already setup"
      // 
      if (
        results?.code == 400 &&
        results?.message === "Address already setup"
      ) {
        setUserSignUpEmail(currentUserDetail?.email);
        setUserSignUpName(currentUserDetail?.name);
        setProfileInfoModal(true);
        // handleProfileInfoModalShow();
        // if (token) {
        //   localStorage.setItem("currentToken", JSON.stringify(token));
        // }
      }
      // console.log("addressSetup", results);
    }
    // console.log("Add Setup Address", checkoutData, currentUserDetail);
  };
  const togglePasswordVisiblity2 = () => {
    setPasswordShown2(passwordShown2 ? false : true);
  };
  const handleSignupModalShow = () => {
    setSignupModalShow(true);
    setShowInfoModal(false);
  };
  const handelSingUpInputChange = (e) => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  async function emailSignUpSuccess(token, msg) {
    const currentUserDetail =
      localStorage.getItem("currentUser") &&
      localStorage.getItem("currentUser") !== "null"
        ? JSON.parse(localStorage.getItem("currentUser"))
        : "";
    handleInfoModalClose();
    if (currentUserDetail?.profile_status === 1) {
      addSetupAddress(currentUserDetail, token);
      //   handleAddressModalShow();
    } else if (currentUserDetail?.profile_status === 2) {
      addSetupAddress(currentUserDetail, token);
      // handleProfileInfoModalShow();
    } else if (currentUserDetail?.profile_status === 3) {
      setTimeout(() => {
        localStorage.setItem("currentToken", JSON.stringify(token));
        // window.location.reload();
      }, 2000);
      toast.success(msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
  }

  async function responseFacebook(response) {
    // console.log("responseFacebook", response);
    let values = {
      name: response.name,
      email: response?.email ? response.email : response.id + "@facebook.com",
      // "mobile": response.id,
      provider_id: response.id,
      login_type: "Facebook",
      fcm_token: "",
      device_type: "website",
      profile_image: response.picture.data.url ? response.picture.data.url : ""
    };
    // let values = {
    //   name: response.name,
    //   email: response?.email ? response.email : response.id + "@facebook.com",
    //   // "mobile": response.id,
    //   provider_id: response.id,
    //   login_type: "Facebook",
    //   fcm_token: "",
    //   device_type: "website",
    // };
    let socialLoginUrl = baseUrl + "/customer/social-login";
    dispatch(
      loginEmailUserAction(
        socialLoginUrl,
        values,
        emailSignUpSuccess,
        setShowInfoModal
      )
    );
  }
  
  async function responseApple(response) {
    if (response?.authorization?.id_token) {
      let id_token = response?.authorization?.id_token;
      let base64Data = id_token.split(".")[1];
      let decodedValue = JSON.parse(window.atob(base64Data));
      let values = {
        name:
          response?.user && response?.user?.name?.firstName
            ? response?.user?.name?.firstName +
              (response?.user?.name?.lastName
                ? " " + response?.user?.name?.lastName
                : "")
            : "",
        email: response?.user?.email
          ? response.user.email
          : decodedValue?.email,
        provider_id: decodedValue?.sub,
        login_type: "Apple",
        fcm_token: "",
        device_type: "website"
      };
      let socialLoginUrl = baseUrl + "/customer/social-login";
      dispatch(loginEmailUserAction(socialLoginUrl, values, emailLoginSuccess,setShowInfoModal));
    } else {
      console.error("something went wrong");
    }
  }

  async function responseSuccessGoogle(response) {
    if (response?.profileObj) {
      let values = {
        name: response.profileObj.name,
        email: response.profileObj.email,
        provider_id: response.profileObj.googleId,
        login_type: "Google",
        fcm_token: "",
        device_type: "website",
        profile_image: response.profileObj.imageUrl
          ? response.profileObj.imageUrl
          : ""
      };
      let socialLoginUrl = baseUrl + "/customer/social-login";
      // const results = await socialLoginApi({
      //   baseUrl: socialLoginUrl,
      //   data: values
      // });
      // console.log("results",results );
    //   if (currentUserDetail?.profile_status === 1) {
    //     addSetupAddress(currentUserDetail, token);
       
    //  } else if (currentUserDetail?.profile_status === 2) {
    //    if(!token){
    //      localStorage.setItem("currentToken", null);
    //    }
    //    handleProfileInfoModalShow();
    //  } else if (currentUserDetail?.profile_status === 3) {
    //    addSetupAddress(currentUserDetail, token);
    //    setTimeout(() => {
    //      localStorage.setItem("currentToken", JSON.stringify(token));
    //    }, 2000);
    //    toast.success("User Successfully Login", {
    //      position: "top-center",
    //      autoClose: 5000,
    //      hideProgressBar: false,
    //      closeOnClick: true,
    //      pauseOnHover: true,
    //      draggable: true,
    //      progress: undefined
    //    });
    //  }
      dispatch(
        loginEmailUserAction(
          socialLoginUrl,
          values,
          emailSignUpSuccess,
          setShowInfoModal
        )
      );
    }
  }

  async function responseFailureGoogle(response) {
    console.log(response);
  }
  const handelMobileLogin = async (token, currentUserDetail) => {
    
    if (currentUserDetail?.profile_status === 1) {
       addSetupAddress(currentUserDetail, token);
      // handleProfileInfoModalShow();
      setIsProfileFromMobile(true);
      
    } else if (currentUserDetail?.profile_status === 2) {
      // console.log("currentUserDetail", currentUserDetail);
      if(!token){
        localStorage.setItem("currentToken", null);
      }
      var mobileNumber = parseInt(currentUserDetail?.mobile);
      if(mobileNumber){
        setUserMobile(mobileNumber)
      }
      // setUserMobile(currentUserDetail?.mobile)
      // 
      // var button = document.getElementById("closeButtonId");
      // if (button) {
      //   // Click the button
      //   button.click();
      // } 
     addSetupAddress(currentUserDetail, token ? token : null);
      // handleProfileInfoModalShow();
      // setShowOtpModal(false);
      setIsProfileFromMobile(true);
    } else if (currentUserDetail?.profile_status === 3) {
      addSetupAddress(currentUserDetail, token);
      setTimeout(() => {
        localStorage.setItem("currentToken", JSON.stringify(token));
        // window.location.reload();
      }, 2000);
      toast.success("User Successfully Login", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
    // addSetupAddress(currentUserDetail, token);
  };
  async function emailLoginSuccess(token, cart = null, msg) {
    const currentUserDetail =
      localStorage.getItem("currentUser") &&
      localStorage.getItem("currentUser") !== "null"
        ? JSON.parse(localStorage.getItem("currentUser"))
        : "";
    if (currentUserDetail?.profile_status === 1) {
      handleInfoModalClose();
      //   handleAddressModalShow();
    } else if (currentUserDetail?.profile_status === 2) {
      if(!token){
        localStorage.setItem("currentToken", null);
      }
      handleInfoModalClose();
      setUserSignUpEmail(currentUserDetail?.email);
      setUserSignUpName(currentUserDetail?.name);
      handleProfileInfoModalShow();
    } else if (currentUserDetail?.profile_status === 3) {
      localStorage.setItem("currentToken", JSON.stringify(token));

      if (cart) {
        localStorage.setItem("bookAppointmentId", cart._id);
        if (cart?.is_home_cleaning) {
          localStorage.setItem("frequencyTypeServiceDetailId", cart._id);
          //localStorage.setItem('frequencyCheckoutData', null);
        } else {
          if (
            localStorage.getItem("serviceDetailId") &&
            localStorage.getItem("serviceDetailId") !== cart._id
          ) {
            localStorage.removeItem("bookAppointmentCartData");
            localStorage.removeItem("bookAppointmentDetails");
          }
          localStorage.setItem("serviceDetailId", cart._id);
        }

        if (msg) {
          handleInfoModalClose();
        }
      } else {
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);
        toast.success(msg, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        handleInfoModalClose();
      }
    }
  }

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: googleClientId,
        scope: "email"
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  const handleInfoModalClose = () => setShowInfoModal(false);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  const handelInputChange = (e) => {
    const {name, value} = e.target;
    setLoginDataFields({...loginDataFields, [name]: value});
  };

  const handleLoginWithEmail = (e) => {
    e.preventDefault();

    let loginEmailUrl = baseUrl + "/customer/login";
    let email_id = jQuery("#email_id").val();
    let password = jQuery("#password").val();
    let values = {
      email: loginDataFields.email_id ? loginDataFields.email_id : email_id,
      password: loginDataFields.password ? loginDataFields.password : password,
      device_type: "website"
    };
    dispatch(
      loginEmailUserAction(
        loginEmailUrl,
        values,
        emailLoginSuccess,
        setShowInfoModal
      )
    );
  };

  const handleSignUpWithEmail = (e) => {
    e.preventDefault();
    let signUpEmailUrl = baseUrl + "/customer/signup";
    let values = {
      email: formData.email,
      password: formData.password,
      device_type: "website"
    };
    dispatch(signUpEmailUserAction(signUpEmailUrl, values, emailSignUpSuccess));
  };
  return (
    <>
      <div className="stepsContent  ">
        <LoginWithMobileModal
          handelMobileLogin={handelMobileLogin}
          implementedFrom={"bookingNotLogin"}
          ref={loginWithMobileRef}
        />
        <SignupModal
        showSignupModal={signupModalShow}
        hideSignupModal={handleSignupModalHide}
        implementedFrom={"bookingNotLogin"}
        handelMobileLogin={handelMobileLogin}
      />
        <div className="row">
          <div className="col-lg-7 col-md-12">
            {profileInfoModal ? (
              <ProfileSetupModal
                handleProfileInfoModalClose={handleProfileInfoModalClose}
                profileInfoModal={profileInfoModal}
                userSignUpEmail={userSignUpEmail}
                userSignUpName={userSignUpName}
                setUserSignUpEmail={setUserSignUpEmail}
                setUserSignUpName={setUserSignUpName}
                setIsAddressAdded={setIsAddressAdded}
                userMobile={userMobile} setUserMobile={setUserMobile}
                isProfileFromMobile={isProfileFromMobile} 
                setIsProfileFromMobile={setIsProfileFromMobile}
                addSetupAddress={addSetupAddress}
              />
            ) : (
              <div className="card mb-5">
                <div className="loginFormArea">
                  <div className="loginForm">
                    <ul>
                      <li
                        style={{width: "50%"}}
                        className={
                          toggleState === 1
                            ? "signBtn signBtn-active"
                            : "signBtn"
                        }
                        onClick={() => toggleTab(1)}
                      >
                        {t("login")}
                      </li>

                      <li
                        className={
                          toggleState === 2
                            ? "signBtn signBtn-active"
                            : "signBtn"
                        }
                        onClick={() => toggleTab(2)}
                      >
                        {t("signup")}
                      </li>
                    </ul>
                    <div className="loginTabArea">
                      <div
                        className={
                          toggleState === 1
                            ? "loginTabContent loginTabContentActive"
                            : "loginTabContent"
                        }
                      >
                        <div className="loginArea">
                          <h2>{t("welcome_back")}</h2>
                          <div className="loginButtonGroup">
                            <button
                              className="btn btn-apple"
                              onClick={() =>
                                setLoginWithApple(loginWithApple ? false : true)
                              }
                            >
                              <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/apple.png.webp" />
                              {t("log_in_with_apple")}
                            </button>
                            <button
                              className="btn btn-facebook"
                              onClick={() =>
                                setLoginWithFacebook(
                                  loginWithFacebook ? false : true
                                )
                              }
                            >
                              <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/facebook.png.webp" />
                              {t("continue_with_facebook")}
                            </button>
                            <button
                              className="btn btn-phone"
                              onClick={() => {
                                handleInfoModalClose();
                                loginWithMobileRef.current.handleLoginWithMobileModalShow();
                              }}
                            >
                              <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/phone.png.webp" />
                              {t("sign_in_with_mobile_number")}
                            </button>
                            <button
                              className="btn btn-google"
                              onClick={() =>
                                setLoginWithGoogle(
                                  loginWithGoogle ? false : true
                                )
                              }
                            >
                              <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/google.png.webp" />
                              {t("sign_in_with_Google")}
                            </button>
                            {loginWithApple ? (
                              <AppleLogin
                                clientId="com.customer.web"
                                autoLoad={true}
                                redirectURI="https://betaweb.myrighth.com"
                                usePopup={true}
                                callback={responseApple} // Catch the response
                                scope="email name"
                                responseMode="query"
                                render={(
                                  renderProps //Custom Apple Sign in Button
                                ) => (
                                  <button
                                    onClick={renderProps.onClick}
                                    className="d-none"
                                  >
                                    <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/apple.png.webp" />
                                    Continue with Apple
                                  </button>
                                )}
                              />
                            ) : null}

                            {loginWithGoogle ? (
                              <GoogleLogin
                                clientId={googleClientId}
                                autoLoad={true}
                                className="d-none"
                                onSuccess={responseSuccessGoogle}
                                onFailure={responseFailureGoogle}
                                cookiePolicy={"single_host_origin"}
                              />
                            ) : null}

                            {loginWithFacebook ? (
                              <FacebookLogin
                                appId={facebookAppId}
                                autoLoad={true}
                                cssClass={"d-none"}
                                fields="link,first_name,middle_name,name,birthday,last_name,email,gender,locale,verified,picture.height(2048),age_range"
                                isMobile={false}
                                callback={responseFacebook}
                              />
                            ) : null}
                          </div>
                          <div className="loginWithEmail">
                            <div className="text-center orLogin mb-5">
                              <h3>{t("or")}</h3>
                            </div>
                            <h3> {t("login_with_email")}</h3>
                            <div className="form-group mb-4">
                              <EmailInput
                                type="email"
                                id="email_id"
                                name="email_id"
                                onChange={(e) => handelInputChange(e)}
                                placeholder={t("email_address")}
                                maxLength="80"
                              />
                            </div>
                            <div className="form-group mb-5 changeEyeSignUp">
                              <EmailInput
                                type={passwordShown2 ? "text" : "password"}
                                className="form-control form-input-border"
                                id="password"
                                name="password"
                                onChange={(e) => handelInputChange(e)}
                                placeholder={t("password")}
                                maxLength="80"
                              />
                              <i
                                className={
                                  passwordShown2
                                    ? "fa fa-eye"
                                    : "fa fa-eye-slash"
                                }
                                onClick={togglePasswordVisiblity2}
                              ></i>
                            </div>
                            <div className="mt-5">
                              <button
                                type="submit"
                                className="btn btn-primary w-full"
                                onClick={(e) => handleLoginWithEmail(e)}
                              >
                                {t("login_with_email")}
                              </button>
                            </div>
                            <div className="text-center text-xl">
                              <h3 className="mb-3">
                                {" "}
                                <Link to={"/customer/forgot-password"}>
                                  {t("forgot_password")}
                                </Link>
                              </h3>
                              <p>
                                {t("dont_have_an_account")}{" "}
                                <Link to="" onClick={() => toggleTab(2)}>
                                  {t("signup")}
                                </Link>{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          toggleState === 2
                            ? "loginTabContent loginTabContentActive"
                            : "loginTabContent"
                        }
                      >
                        <div className="signupArea">
                          <h2>{t("sign_up_to_book_services")}</h2>
                          <div className="loginButtonGroup">
                            <button
                              className="btn btn-apple"
                              onClick={() => setLoginWithApple(true)}
                            >
                              <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/apple.png.webp" />
                              {t("sign_up_with_apple")}
                            </button>
                            <button
                              className="btn btn-facebook"
                              onClick={() => setLoginWithFacebook(true)}
                            >
                              <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/facebook.png.webp" />
                              {t("continue_with_facebook")}
                            </button>

                            <button
                              className="btn btn-phone"
                              onClick={handleSignupModalShow}
                            >
                              <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/phone.png.webp" />
                              {t("sign_up_with_mobile_number")}
                            </button>
                            <button
                              className="btn btn-google"
                              onClick={() => setLoginWithGoogle(true)}
                            >
                              <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/google.png.webp" />
                              {t("sign_up_with_google")}
                            </button>
                          </div>
                          <div className="loginWithEmail">
                            <div className="text-center orLogin mb-5">
                              <h3>or</h3>
                            </div>
                            <div className="form-group mb-4">
                              <input
                                type="email"
                                className="form-control form-input-border"
                                id="sign_up_email"
                                name="email"
                                placeholder={t("email_address")}
                                onChange={(e) => handelSingUpInputChange(e)}
                                maxLength="80"
                              />
                            </div>
                            <div className="form-group mb-5 changeEyeSignUp">
                              <input
                                type={passwordShown ? "text" : "password"}
                                className="form-control form-input-border"
                                id="sign_up_password"
                                name="password"
                                onChange={(e) => handelSingUpInputChange(e)}
                                placeholder={t("password")}
                                maxLength="80"
                              />
                              <i
                                className={
                                  passwordShown
                                    ? "fa fa-eye"
                                    : "fa fa-eye-slash"
                                }
                                onClick={togglePasswordVisiblity}
                              ></i>
                            </div>
                            <div className="mt-5">
                              <button
                                type="submit"
                                className="btn btn-primary w-full"
                                onClick={(e) => handleSignUpWithEmail(e)}
                              >
                                {t("sign_up_with_email")}
                              </button>
                            </div>
                            <div className="text-center text-xl">
                              <p>
                                {t("already_have_an_account")}{" "}
                                <Link to="" onClick={() => toggleTab(1)}>
                                  {t("login")}
                                </Link>{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="loginfooter">
                      <h3 className="mb-2">
                        {t("booked_over_phone_or_chat")}{" "}
                      </h3>
                      <p>{t("claim_your_online_account")}</p>
                      <Link to={"/customer/claim-account"}>
                        {t("claim_account")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginSignupStep;
