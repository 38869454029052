import React, {useEffect} from "react";
import { Link, useLocation,NavLink,useParams } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import jQuery from 'jquery';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {updateCustomerCreditCard} from "../../helpers/helpers";
import {ModifiedToastContainer} from "../../components/ModifiedToastContainer";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import {customerCreditCardListsAction,customerCreditCardDeleteAction} from "../../actions/customerDetails";
import Loader from "../../components/Loader";
import { Frames, CardNumber, ExpiryDate, Cvv } from 'frames-react';

const EditCreditCard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = (localStorage.getItem('currentUser') && localStorage.getItem('currentUser') !== "null") ? JSON.parse(localStorage.getItem('currentUser')) : false;
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let REACT_APP_CHECKOUT_PUBLIC_KEY = process.env.REACT_APP_CHECKOUT_PUBLIC_KEY;
  const {source_id} = useParams();

  const loading = false;
  async function checkCardValidation(isValid){ 
          if (!isValid) {
               toast.error(t("correct_card"), {
                 position: "top-center",
                 autoClose: 5000,
                 hideProgressBar: false,
                 closeOnClick: true,
                 pauseOnHover: true,
                 draggable: true,
                 progress: undefined,
               }); 
          } 
  }

  async function updateCustCreditCard(){ 
    let awaitRequestUrl = baseUrl+"/payment/update/instrument/"+source_id;
    await updateCustomerCreditCard(awaitRequestUrl, source_id)
        .then((response) => {
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            window.location.href = "/my-account/creditcard";
          }, 2000);
        }).catch(function (error) {
          toast.error('Something went wrong. please try again.', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }); 
        });
  }

  return (
    <>
      <Header />
      <ModifiedToastContainer />
      <div className="py-5">
        <div className="userContainer">
          <div className="creditCard">
            <div className="heading">
              <Link to="/my-account/creditcard">
              <h5>Credit Cards</h5>
              </Link>
              <h2>{t('edit_credit_card')}</h2>
            </div>
            <div className="card">
              <div className="personalForm">
                  <Frames 
                      config={{
                          publicKey: REACT_APP_CHECKOUT_PUBLIC_KEY,
                          localization: {
                              cardNumberPlaceholder: 'Card number',
                              expiryMonthPlaceholder: 'MM',
                              expiryYearPlaceholder: 'YY',
                              cvvPlaceholder: 'CVV',
                          },
                          style: {
                              base: {
                                  fontSize: '17px',
                              },
                          },
                      }}
                  >   <div className="creditCardDiv">
                      <CardNumber />
                      <ExpiryDate />
                      <Cvv />    
                      </div>              
                      <button className="btn btn-primary w-full mt-4"
                           onClick={() => { 
                            {/*  updateCustCreditCard();  */}
                            checkCardValidation(Frames.isCardValid());
                           }}
                      >{t('submit')}
                      </button>
                  </Frames>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default EditCreditCard;
