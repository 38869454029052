import React, {useState, useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Address from "./address/Address";
import Service from "./service_details/Service";
import DateTime from "./date_time/DateTime";
import jQuery from "jquery";
import Payment from "./payment/Payment";
import Modal from "react-bootstrap/Modal";
import BookingLocationMap from "./components/BookingLocationMap";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {ModifiedToastContainer} from "../../../components/ModifiedToastContainer";
import LoginModal from "../../../components/LoginModal";
import {postBookAppointmentAction} from "../../../actions/appointmentAction";
import UserAddressList from "./components/UserAddressList";
import {saveForm} from "../../../helpers/helpers";
import NextBackButton from "./components/NextBackButton";
import Loader from "../../../components/Loader";
import SuccessBooking from "./components/SuccessBooking";
import LoginSignupModal from "../../../components/LoginSignupModal";
import { customerAddressListsAction, makeDefaultCustomerAddressAction } from "../../../actions/customerAddressAction";

const moment = require("moment");
const ServiceCheckout = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const getAllCountryState = useSelector((state) => state.countryReducer);
  const {country_list} = getAllCountryState;

  const countryCurrency = country_list?.filter(
    (f) => f._id === localStorage.getItem("userCountryId")
  );

  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  const isLoggedIn =
    localStorage.getItem("currentToken") &&
    localStorage.getItem("currentToken") !== "null"
      ? true
      : false;
  const bookAppointmentDetails = localStorage.getItem("bookAppointmentDetails")
    ? JSON.parse(localStorage.getItem("bookAppointmentDetails"))
    : null;
  const [cart, updateCart] = useState([]);
  const loginSignupRef = useRef();

  const [checkoutData, setCheckoutData] = useState({
    appointment_id: "",
    service_id: [localStorage.getItem("serviceDetailId")],
    page: 1,
    cart: [],
    is_home_cleaning: false,
    booking_instruction: "",
    appointment_date: moment().format("YYYY-MM-DD"),
    appointment_date_start: "",
    start_time_between: "",
    is_auto_assign: true,
    qnb_customer_id: "",
    payment_mode: "",
    discount_type: "",
    promo_code: "",
    price: 0,
    totalPrice: 0,
    vatPrice: 0,
    rh_discount: 0,
    discountPrice: 0,
    address_id: "",
    address: "",
    country: "",
    country_id: localStorage.getItem("userCountryId"),
    currency: "",
    latitude: 0.0,
    longitude: 0.0,
    rhClub: localStorage.getItem("rhClub"),
    customer_service_id: "",
    payment_terms_condition: false,
    isWallet: false,
    pay_type: "",
    pay_type: "",
    address_nickname: "",
    area: "",
    address_type: "",
    street: "",
    building: "",
    floor: "",
    house_no: "",
    Office_no: "",
    apartment_no: "",
    wallet_amount: 0,
    hours_count: 2,
    actionStatus: false,
    holidayPrice: 0
  });
  const FormTitle = [
    "",
    checkoutData?.is_home_cleaning
      ? t("service_details")
      : t("service_details_non_cleaning"),
    t("date"),
    t("payment")
  ];
  const getAppointmentPriceState = useSelector(
    (state) => state.appointmentReducer
  );
  const {loading, error} = getAppointmentPriceState;
  //  console.log("error", error)
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  let updateServiceId = params.get("service_id")
    ? params.get("service_id")
    : "";

  useEffect(() => {
    if (!localStorage.getItem("serviceDetailId")) {
      window.location.href = "/";
    }

    // if(!isLoggedIn && (!bookAppointmentDetails || !bookAppointmentDetails?.address)){
    //   handleAddressModalShow();
    // }

    let bookAppointmentCartData =
      localStorage.getItem("bookAppointmentCartData") &&
      localStorage.getItem("bookAppointmentCartData") !== "null"
        ? JSON.parse(localStorage.getItem("bookAppointmentCartData"))
        : [];
    if (
      bookAppointmentCartData?.cart_items &&
      bookAppointmentCartData?.cart_items?.length > 0
    ) {
      // updateCart(bookAppointmentCartData.cart_items);
    }

    const bookAppointmentDetails = localStorage.getItem(
      "bookAppointmentDetails"
    )
      ? JSON.parse(localStorage.getItem("bookAppointmentDetails"))
      : false;
    if (bookAppointmentDetails) {
      setCheckoutData(bookAppointmentDetails);
    }

    setTimeout(() => {
      let updateStep = params.get("step") ? parseInt(params.get("step")) : 0;
      if (bookAppointmentDetails && bookAppointmentDetails.page) {
        updateStep = bookAppointmentDetails.page + 1;
      }
      setCheckoutData((prevState) => ({
        ...prevState,
        ["page"]: updateStep
      }));

      const url = new URL(window.location.href);
      url.searchParams.set("step", updateStep);
      url.searchParams.set("service_id", updateServiceId);
      window.history.replaceState(null, null, url);
    }, 500);
  }, []);

  const [showAddresModal, setShowAddressModal] = useState(false);
  const handleAddressModalShow = () => setShowAddressModal(true);
  const handleAddressModalClose = () => setShowAddressModal(false);
  const [loginModalShow, setLoginModalShow] = useState(false);
  const handleLoginModalShow = () => setLoginModalShow(true);
  const handleLoginModalHide = () => setLoginModalShow(false);

  const [userAddresListModal, setUserAddresListModalShow] = useState(false);
  const handleUserAddresListModalShow = () => setUserAddresListModalShow(true);
  const handleUserAddresListModalHide = () => setUserAddresListModalShow(false);

  const [bookingSuccessModal, setBookingSuccessModal] = useState(false);
  const handleBookingSuccessModalShow = () => setBookingSuccessModal(true);
  const [appointment, setAppointment] = useState("");

  const updateCheckoutData = (key, value) => {
    setCheckoutData((prevState) => ({
      ...prevState,
      [key]: value
    }));
    setTimeout(() => {
      localStorage.setItem("serviceCheckoutData", JSON.stringify(checkoutData));
    }, 3000);
  };

  function addressInformation(data) {
    let apiUrl =
      process.env.REACT_APP_LOCAL_API_URL + "/appointment/create/address";
    dispatch(
      postBookAppointmentAction(
        apiUrl,
        data,
        updateCheckoutData,
        setAppointment,
        handleBookingSuccessModalShow
      )
    );
  }

  async function confirmAppointmentPay(data) {
    let apiUrl = "";
    if (checkoutData.page === 1 && checkoutData.actionStatus) {
      apiUrl =
        process.env.REACT_APP_LOCAL_API_URL + "/appointment/create/service";
        data.appointment_id = checkoutData.appointment_id
    } else if (checkoutData.page === 2) {
      apiUrl = process.env.REACT_APP_LOCAL_API_URL + "/appointment/create/time";
    } else if (checkoutData.page === 3) {
      apiUrl =
        process.env.REACT_APP_LOCAL_API_URL + "/appointment/create/payment";
    }

    if (apiUrl) {
    dispatch(
      postBookAppointmentAction(
        apiUrl,
        data,
        updateCheckoutData,
        setAppointment,
        handleBookingSuccessModalShow
      )
    );
  }

    // if(localStorage.getItem('bookAppointmentDetails') && localStorage.getItem('bookAppointmentDetails') !== "null"){
    //   let data = JSON.parse(localStorage.getItem('bookAppointmentDetails'));
    //   let bookAppointmentCartData = (localStorage.getItem('bookAppointmentCartData') && localStorage.getItem('bookAppointmentCartData') !== "null") ? JSON.parse(localStorage.getItem('bookAppointmentCartData')) : [];
    //   let bookingStartTime = '';
    //   if(data.appointment_time){
    //     var bookingTime = data.start_time_between;
    //     var _bookingTime = bookingTime.split("-");
    //     bookingStartTime = _bookingTime[0]
    //   }
    //   var service_category_id = [];
    //   var service_package_id = [];
    //   var cart_items = bookAppointmentCartData.cart_items;
    //   if(cart_items?.length > 0){
    //     cart_items.map((i, index) => {
    //       service_category_id.push(i.sub_service_id);
    //       service_package_id.push(i.id);
    //     });
    //   }

    //   data['ordered_item'] = (cart_items > 0) ? cart_items : data.cart;
    //   data['appointment_date_start'] = data.appointment_date+' '+bookingStartTime;
    //   data['service_category_id'] = service_category_id;
    //   data['service_package_id'] = service_package_id;

    //   if(jQuery('.PaymentByCredit').is(':checked')){
    //     data.discount_type = "FreeCredit";
    //   }

    //   data.payment_mode = "Card";
    //   if(jQuery('#promo_code_val').val() && jQuery('#promo_code_val').val() !== undefined && jQuery('#promo_code_val').val() !== ""){
    //     data.promo_code = jQuery('#promo_code_val').val();
    //     data.discount_type = "Promo Code";
    //   }else{
    //     data.promo_code = "";
    //   }

    //   if(data.qnb_customer_id !== "" || data.discount_type === "FreeCredit"){
    //     let apiUrl = process.env.REACT_APP_LOCAL_API_URL + "/appointment/create";
    //     dispatch(postBookAppointmentAction(apiUrl,data,setAppointment,handleBookingSuccessModalShow));
    //   }

    //   localStorage.removeItem('bookAppointmentCartData')
    //   localStorage.removeItem('bookAppointmentDetails')
    // }
  }

  const handleCustomerSubmit = async (e) => {
    e.preventDefault();
    var country_short_name = jQuery("#country_short_name").val();
    var message = "";
    if (country_short_name === "IN" || country_short_name === "QA") {
      if (currentUser) {
        var processStatus = jQuery("#processStatus").val();
        if (processStatus !== "Save & Continue") {
          jQuery("#processStatus").val("Save & Continue");
          jQuery("#processStatus").val("Save & Continue");
          jQuery(".addressSubmit").html("Save & Continue");
          jQuery(".mapToogleDiv").removeClass("addressDetailsHide");
          jQuery(".mapToggle").removeClass("hide");
          jQuery(".mapToggle").addClass("show");
        }

        if (processStatus === "Save & Continue") {
          let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
          let awaitUrl = baseUrl + "/customer/address/create";
          let frm = document.getElementById("addressform");
          await saveForm(awaitUrl, frm, "POST")
            .then((response) => {
              toast.success(response.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });
              handleAddressModalClose();
              setTimeout(function () {
                localStorage.removeItem("currentLatitude");
                localStorage.removeItem("currentLongitude");
                window.location.reload();
              }, 700);
            })
            .catch(function (error) {
              if (error.response) {
                if (error.response.status === 419) {
                  setTimeout(function () {
                    window.location.reload();
                  }, 2000);
                }
              }
            });
        }
      } else {
        let country = jQuery("#country").val();
        let latitude = jQuery("#latitude").val();
        let longitude = jQuery("#longitude").val();
        let address = jQuery("#address").val();
        updateCheckoutData("address", address);
        updateCheckoutData("country", country);
        updateCheckoutData("latitude", latitude);
        updateCheckoutData("longitude", longitude);
        updateCheckoutData("longitude", longitude);
        handleAddressModalClose();
      }
    } else {
      message = t("currently_not_operate_in_this_area");
      if (message !== "") {
        toast.error(message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      }
    }
  };


  return (
    <>
      <div className="stepsFromPage ServiceStpes ">
        <ModifiedToastContainer />
        <LoginSignupModal ref={loginSignupRef} />
        {loading ? <Loader /> : ""}
        <Header />
        <div className="container">
          <div className="pt-5">
            <div className="heading">
              <h2>{FormTitle[checkoutData.page]}</h2>
              <p>{t("all_bookings")}</p>
            </div>
          </div>
          {(() => {
            if (isLoggedIn) {
              if (cart?.length === 0 && checkoutData.page !== 0) {
                return (
                  <Service
                    cart={cart}
                    updateCart={updateCart}
                    checkoutData={checkoutData}
                    updateCheckoutData={updateCheckoutData}
                  />
                );
              } else if (checkoutData.page==0){
                return(

                    <Address
                      checkoutData={checkoutData}
                      updateCheckoutData={updateCheckoutData}
                      appointment={appointment}
                      setCheckoutData={setCheckoutData}
                    />
                )
              }else if (checkoutData.page === 1) {
                return (
                  <Service
                    cart={cart}
                    updateCart={updateCart}
                    checkoutData={checkoutData}
                    updateCheckoutData={updateCheckoutData}
                    appointment={appointment}
                  />
                );
              } else if (checkoutData.page === 2) {
                return (
                  <DateTime
                    cart={cart}
                    updateCart={updateCart}
                    checkoutData={checkoutData}
                    updateCheckoutData={updateCheckoutData}
                    appointment={appointment}
                    setCheckoutData={setCheckoutData}
                  />
                );
              } else if (checkoutData.page === 3) {
                return (
                  <Payment
                    cart={cart}
                    checkoutData={checkoutData}
                    updateCheckoutData={updateCheckoutData}
                    setCheckoutData={setCheckoutData}
                  />
                );
              }
            } else {
              if (cart?.length === 0) {
                return (
                  <Service
                    cart={cart}
                    updateCart={updateCart}
                    checkoutData={checkoutData}
                    updateCheckoutData={updateCheckoutData}
                  />
                );
              } else if (checkoutData.page === 1) {
                return (
                  <Service
                    cart={cart}
                    updateCart={updateCart}
                    checkoutData={checkoutData}
                    updateCheckoutData={updateCheckoutData}
                    appointment={appointment}
                  />
                );
              } else if (checkoutData.page === 2) {
                return (
                  <DateTime
                    cart={cart}
                    updateCart={updateCart}
                    checkoutData={checkoutData}
                    updateCheckoutData={updateCheckoutData}
                    appointment={appointment}
                  />
                );
              } else if (checkoutData.page === 3) {
                return <LoginSignupModal ref={loginSignupRef} />;
              }
            }
          })()}

          <NextBackButton
            checkoutData={checkoutData}
            cart={cart}
            updateCheckoutData={updateCheckoutData}
            confirmAppointmentPay={confirmAppointmentPay}
            addressInformation={addressInformation}
            loginSignupRef={loginSignupRef}
            error={error ? error : ""}
            setCheckoutData={setCheckoutData}
          />
          {/* <Modal
            show={showAddresModal}
            onHide={handleAddressModalClose}
            id="addAddress"
            className="addAddressModal"
          >
        <Modal.Header closeButton>
          <Modal.Title> Your location </Modal.Title>
        </Modal.Header>
            <Modal.Body>
              <form onSubmit={handleCustomerSubmit} id="addressform">
                <div className="modal-body">
                  <div className="mapContent">
                    <div className="col-lg-12 mb-0 relative ">
                      <input type="hidden" id="country" name="country" value=""/>
                      <input type="hidden" id="country_short_name" name="country_short_name" value=""/>
                      <input type="hidden" id="latitude" name="latitude" value=""/>
                      <input type="hidden" id="longitude" name="longitude" value=""/>
                      <input type="hidden" id="processStatus" name="processStatus" value="Continue"/>
                    </div>
                    <div className="address_input_div col-lg-12 mb-0 mapToogleDiv addressDetailsHide">
                      <div className="mapToggle toast hide">
                        <button type="button" className="btnclose" data-bs-dismiss="toast">
                          {" "}
                          <span className="fa fa-angle-down"></span>{" "}
                        </button>
                        <div className="col-lg-12 inputdiv mt-4">
                        <label>{t('address_details')}</label>
                          <input
                            type="text"
                            className="form-control pl-0"
                            placeholder={t('building_villa')+ ' / ' +t('unit_no')+' / '+ t('street_no')+' / '+t('zone_no')}
                            id="addressDetails"
                            name="addressDetails"
                          />
                          <div className="help-block"></div>
                        </div>
                      </div>
                      <button className="btn btn-primary addressSubmit">
                      {t('confirm')}
                      </button>
                    </div>
                </div>
                <div className="divMap">
                  <BookingLocationMap translatedValue={t}/>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal> */}
        </div>
        <SuccessBooking
          bookingSuccessModal={bookingSuccessModal}
          handleBookingSuccessModalShow={handleBookingSuccessModalShow}
          appointment={appointment}
          checkoutData={checkoutData}
        />
        {(() => {
          if (isLoggedIn) {
            return (
              <UserAddressList
                userAddresListModal={userAddresListModal}
                handleUserAddresListModalHide={handleUserAddresListModalHide}
                handleUserAddresListModalShow={handleUserAddresListModalShow}
                updateCheckoutData={updateCheckoutData}
              />
            );
          }
        })()}

        <Footer />
      </div>
    </>
  );
};

export default ServiceCheckout;
