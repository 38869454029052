import React, {useState} from "react";
import "./invoice.css";
import {useTranslation} from "react-i18next";

const InvoiceView = ({appointment}) => {
  const {t} = useTranslation();
  var discApplied = 0;
  discApplied =
    appointment?.promo_code_discount_price !== 0
      ? appointment?.promo_code_discount_price
      : appointment?.discount_credit !== 0
      ? appointment?.discount_credit
      : appointment?.referred_amount !== 0
      ? appointment?.referred_amount
      : appointment?.birthday_credit;
  if (discApplied === undefined) {
    discApplied = 0;
  }

  return (
    <>
      <div className="invoice-header"> {t("invoice_view")}:</div>

      <div className="invoice-content">
        <div className="invoice-item">
          <p className="item-label"> {t("price")} </p>
          <p className="item-value">
            {appointment?.FrequencyDetails?.package_name === "2 times daily"
              ? appointment?.per_order_service_total
              : appointment?.FrequencyDetails?.package_name === "Weekly"
              ? appointment?.per_order_service_total
              : appointment?.total_order_service_total
              ? appointment?.total_order_service_total
              : 0}
          </p>
        </div>

        <div className="invoice-item">
          <p className="item-label"> {t("rush_slot")} </p>
          <p className="item-value">
            {appointment?.per_slot_price ? appointment.per_slot_price : 0}
          </p>
        </div>

        <div className="invoice-item">
          <p className="item-label"> {t("holiday_price")} </p>
          <p className="item-value">
            {appointment?.holiday_price ? appointment.holiday_price : 0}
          </p>
        </div>

        <div className="invoice-item">
          <p className="item-label"> {t("codCharge")} </p>
          <p className="item-value">
            {appointment?.total_cod_charges
              ? appointment?.total_cod_charges
              : 0}
          </p>
        </div>

        <hr />

        <div className="invoice-item">
          <p className="item-label">
            <strong> {t("total")} :</strong>
          </p>
          <p className="item-value">
            {(
              (appointment?.FrequencyDetails?.package_name === "2 times daily"
                ? appointment?.per_order_service_total
                : appointment?.FrequencyDetails?.package_name === "Weekly"
                ? appointment?.per_order_service_total
                : appointment?.total_order_service_total
                ? appointment?.total_order_service_total
                : 0) +
              (appointment?.per_slot_price ? appointment.per_slot_price : 0) +
              (appointment?.holiday_price ? appointment.holiday_price : 0) +
              (appointment?.total_cod_charges > 0
                ? appointment?.total_cod_charges
                : 0)
            ).toFixed(2)}
          </p>
        </div>

        {appointment?.is_home_cleaning && (
          <div className="invoice-item">
            {appointment?.isRHUsed ? (
              <p className="item-label">
                {t("freecredit")} &nbsp;{" "}
                {appointment?.offerPercentage ? appointment.offerPercentage : 0}{" "}
                %
              </p>
            ) : (
              <p className="item-label">
                {t("frequency_discount")} &nbsp;{" "}
                {appointment?.offerPercentage ? appointment.offerPercentage : 0}{" "}
                %
              </p>
            )}
            <p className="item-value">{appointment?.per_frequency_discount}</p>
          </div>
        )}
        {appointment?.discount_type && (
          <div className="invoice-item">
            <p className="item-label">
              {t("voucher")} ({appointment?.discount_type})
            </p>
            <p className="item-value">{discApplied}</p>
          </div>
        )}

        <div className="invoice-item">
          <p className="item-label">
            <strong> {t("vipDiscount")} : </strong>
          </p>
          <p className="item-value">
            {(appointment?.FrequencyDetails?.package_name === "2 times daily"
              ? appointment?.per_frequency_discount
              : appointment?.FrequencyDetails?.package_name === "Weekly"
              ? appointment?.per_frequency_discount
              : appointment?.total_discount) + (discApplied || 0)}
          </p>
        </div>

        <hr />

        <div className="invoice-item">
          <p className="item-label">
            <strong>{t("total_amount")} : </strong>
          </p>
          <p className="item-value">
            {appointment?.FrequencyDetails?.package_name === "2 times daily"
              ? appointment?.total_per_display_amount
              : appointment?.FrequencyDetails?.package_name === "Weekly"
              ? appointment?.total_per_display_amount
              : appointment.total_amount +
                (appointment?.total_cod_charges > 0
                  ? appointment?.total_cod_charges
                  : 0)}
          </p>
        </div>

        <div className="invoice-item">
          <p className="item-label">{t("wallet_payment")} </p>
          <p className="item-value">
            {appointment?.per_wallet_amount ? appointment.per_wallet_amount : 0}
          </p>
        </div>

        <div className="invoice-item">
          <p className="item-label"> {t("rh_credits")} </p>
          <p className="item-value">
            {appointment?.per_credit_amount
              ? appointment?.per_credit_amount
              : 0}
          </p>
        </div>

        <div className="invoice-item">
          <p className="item-label">{t("payment_mode")} </p>
          <p className="item-value">
            <span>
              <span>
                {(() => {
                  let cardBYCard = "";
                  let freeCredit = "";
                  let cash = "";

                  if (
                    appointment?.payment_mode === "Cash" ||
                    appointment?.payment_mode === "cod" ||
                    appointment?.payment_mode === "cash"
                  ) {
                    cash = t("cash");
                  } else if (
                    appointment?.payment_mode === "card" ||
                    appointment?.payment_mode === "Card" ||
                    appointment?.payment_mode === ""
                  ) {
                    cardBYCard = t("credit_card");
                  }

                  if (parseInt(appointment?.credit_amount) > 0) {
                    freeCredit = t("free_credit");
                  }
                  return (
                    cardBYCard +
                    (freeCredit !== "" && cardBYCard !== "" ? "/" : "") +
                    freeCredit +
                    cash
                  );
                })()}
              </span>
            </span>
          </p>
        </div>
      </div>
    </>
  );

  //   return (
  //     <>
  //       <div className="header">Invoice View :</div>

  //       <div className="content appointmentPopup overflow-y-auto">
  //         <div className="flex text-base justify-between mb-3 flex-wrap sm:flex-nowrap">
  //           <p className="font-semibold min-w-max sm:w-60 w-full pr-5">
  //             Service Order
  //           </p>
  //           <p className="text-right text-gray-600">
  //             {appointment?.per_order_service_total
  //               ? appointment?.per_order_service_total
  //               : 0}
  //           </p>
  //         </div>

  //         <div className="flex text-base justify-between mb-3 flex-wrap sm:flex-nowrap">
  //           <p className="font-semibold min-w-max sm:w-60 w-full pr-5">
  //             Rush Slot{" "}
  //           </p>
  //           <p className="text-right text-gray-600">
  //             {appointment?.per_slot_price ? appointment?.per_slot_price : 0}
  //           </p>
  //         </div>
  //         <div className="flex text-base justify-between mb-3 flex-wrap sm:flex-nowrap">
  //           <p className="font-semibold min-w-max sm:w-60 w-full pr-5">
  //             Holiday Price{" "}
  //           </p>
  //           <p className="text-right text-gray-600">
  //             {appointment?.holiday_price ? appointment?.holiday_price : 0}
  //           </p>
  //         </div>
  //         <div className="flex text-base justify-between mb-3 flex-wrap sm:flex-nowrap">
  //           <p className="font-semibold min-w-max sm:w-60 w-full pr-5">
  //             Cod Charges{" "}
  //           </p>
  //           <p className="text-right text-gray-600">
  //             {appointment?.cod_charges ? appointment?.cod_charges : 0}
  //           </p>
  //         </div>
  //         <hr />
  //         <div className="flex text-base justify-between mb-3 flex-wrap sm:flex-nowrap">
  //           <p className="font-semibold min-w-max sm:w-60 w-full pr-5">
  //             <strong> Sub Total :</strong>
  //           </p>
  //           <p className="text-right text-gray-600">
  //             {(appointment?.per_order_service_total
  //               ? appointment?.per_order_service_total
  //               : 0) +
  //               (appointment?.per_slot_price ? appointment?.per_slot_price : 0) +
  //               (appointment?.holiday_price ? appointment?.holiday_price : 0)}
  //           </p>
  //         </div>

  //         <hr />

  //         {appointment?.is_home_cleaning && (
  //           <div className="flex text-base justify-between mb-3 flex-wrap sm:flex-nowrap">
  //             {appointment?.isRHUsed ? (
  //               <p className="font-semibold min-w-max sm:w-60 w-full pr-5">
  //                 RH Discount &nbsp;
  //                 {appointment?.offerPercentage
  //                   ? appointment?.offerPercentage
  //                   : 0}{" "}
  //                 %
  //               </p>
  //             ) : (
  //               <p className="font-semibold min-w-max sm:w-60 w-full pr-5">
  //                 Frequency Discount &nbsp;
  //                 {appointment?.offerPercentage
  //                   ? appointment?.offerPercentage
  //                   : 0}{" "}
  //                 %
  //               </p>
  //             )}
  //             <p className="text-right text-gray-600">
  //               {appointment?.per_frequency_discount}
  //             </p>
  //           </div>
  //         )}

  //         {appointment?.discount_type && (
  //           <>
  //             <>
  //               <div className="flex text-base justify-between mb-3 flex-wrap sm:flex-nowrap">
  //                 <p className="font-semibold min-w-max sm:w-60 w-full pr-5">
  //                   Promo Applied ({appointment?.discount_type})
  //                 </p>
  //                 <p className="text-right text-gray-600">{discApplied}</p>
  //               </div>
  //             </>
  //           </>
  //         )}

  //         <div className="flex text-base justify-between mb-3 flex-wrap sm:flex-nowrap">
  //           <p className="font-semibold min-w-max sm:w-60 w-full pr-5">
  //             <strong> Total Discounts : </strong>
  //           </p>
  //           <p className="text-right text-gray-600">
  //             {(appointment?.per_frequency_discount || 0) + (discApplied || 0)}
  //           </p>
  //         </div>
  //         <hr />
  //         <div className="flex text-base justify-between mb-3 flex-wrap sm:flex-nowrap">
  //           <p className="font-semibold min-w-max sm:w-60 w-full pr-5">
  //             <strong> Grand Total : </strong>
  //           </p>
  //           <p className="text-right text-gray-600">
  //             {appointment?.total_per_display_amount}
  //           </p>
  //         </div>
  //         <div className="flex text-base justify-between mb-3 flex-wrap sm:flex-nowrap">
  //           <p className="font-semibold min-w-max sm:w-60 w-full pr-5">
  //             Wallet Amount
  //           </p>
  //           <p className="text-right text-gray-600">
  //             {appointment?.per_wallet_amount
  //               ? appointment?.per_wallet_amount
  //               : 0}
  //           </p>
  //         </div>
  //         <div className="flex text-base justify-between mb-3 flex-wrap sm:flex-nowrap">
  //           <p className="font-semibold min-w-max sm:w-60 w-full pr-5">
  //             RH Credit Amount
  //           </p>
  //           <p className="text-right text-gray-600">
  //             {appointment?.per_credit_amount
  //               ? appointment?.per_credit_amount
  //               : 0}
  //           </p>
  //         </div>
  //         <div className="flex text-base justify-between mb-3 flex-wrap sm:flex-nowrap">
  //           <p className="font-semibold min-w-max sm:w-60 w-full pr-5">
  //             Payment Mode
  //           </p>
  //           <p className="text-right text-gray-600">
  //             {appointment?.payment_mode ? appointment?.payment_mode : "N/A"}
  //           </p>
  //         </div>
  //       </div>
  //     </>
  //   );
};

export default InvoiceView;
