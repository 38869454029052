import React, {useState, useEffect} from "react";
import OwlCarousel from "react-owl-carousel";
import {useDispatch, useSelector} from "react-redux";
import {getProfessionalListAction} from "../../../../actions/getProfessionalListAction";
import {getProfessionalInfoAction} from "../../../../actions/ProfessionalInfoAction";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Loader from "../../../../components/Loader";
import Modal from "react-bootstrap/Modal";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {CarouselItem} from "react-bootstrap";

function ProfessionalSlider({
  checkoutData,
  // updateCheckoutData,
  getTimeSlots,
  isAutoSelected,
  setIsAutoSelected,
  setSelectedProId,
  selectedProId,
  setCheckoutData,
  getMonthSlots
}) {
  const [professionalInfoModal, setProfessionalInfoModal] = useState(false);
  const handleProfessionalInfoModalShow = () => setProfessionalInfoModal(true);
  const handleProfessionalInfoModalClose = () =>
    setProfessionalInfoModal(false);
  const getAllProfessionalState = useSelector(
    (state) => state.professionalListReducer
  );
  const {loading, professionals} = getAllProfessionalState;
  const getProfessionalDetailsState = useSelector(
    (state) => state.professionalInfoReducer
  );
  const {professionalInfo} = getProfessionalDetailsState;
  // const [professionalInfo, setProfessionalInfo] = useState("");
  const dispatch = useDispatch();
  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  const {t} = useTranslation();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;

  useEffect(() => {
    if (currentUser) {
      let serviceId = localStorage.getItem("serviceId");
      let listUrl =
      baseUrl +
      "/appointment/professional/list?" +
      `customer_id=${currentUser ? currentUser.id : ""}&address_id=${
        checkoutData?.address_id
      }&service_id=${
        checkoutData?.service_id
          ? checkoutData?.service_id
          : checkoutData?.service_id?.length > 0
          ? checkoutData?.service_id[0]
          : serviceId ? serviceId :""
      }`;
      dispatch(getProfessionalListAction(listUrl, {}));
    } else {
      let serviceId = localStorage.getItem("serviceId");
      if (checkoutData.latitude === 0) {
        toast.error(t("Location is Missing"), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      } else {
        console.log("checkoutData", checkoutData);
        let listUrl =
          baseUrl +
          "/appointment/professional/list?" +
          `customer_id=${currentUser ? currentUser.id : ""}&latitude=${
            checkoutData?.latitude
          }&longitude=${checkoutData?.longitude}&service_id=${
            checkoutData?.service_id
              ? checkoutData?.service_id
              : checkoutData?.service_id?.length >0
              ? checkoutData?.service_id[0]
              : serviceId ? serviceId :""
          }`;
        dispatch(getProfessionalListAction(listUrl, {}));
      }
    }
  }, []);
  const autoProHandler = (professional, itemType) => {
    setIsAutoSelected(true);
    setCheckoutData({
      ...checkoutData,
      ["is_auto_assign"]: true,
      ["cleaner_id"]: "",
      ["professional_full_name"]: "Auto assign",
      ["professional_profile_image"]: "",
      ["professional_id"]: professional?._id ? professional?._id :''
    });
    getTimeSlots(itemType === "Auto assign" ? "" : professional?.id ?  professional.id : '');
  };
  const selectProHandler = (professionals, itemType) => {
    setIsAutoSelected(false);
    setCheckoutData({
      ...checkoutData,
      ["is_auto_assign"]: false,
      ["cleaner_id"]: professionals?._id,
      ["professional_full_name"]:
        professionals?.first_name + " " + professionals?.last_name,
      ["professional_profile_image"]: professionals?.profile_pic
        ? professionals?.profile_pic
        : "",
      ["professional_id"]: professionals?._id ? professionals?._id : ""
    });
    getMonthSlots(professionals?._id, professionals);
    setSelectedProId(professionals?._id);
  };

  // async function selectProfessionalProfile(professional, itemType) {
  //   if (itemType === "Auto assign") {
  //     updateCheckoutData("is_auto_assign", true);
  //     updateCheckoutData("cleaner_id", "");
  //     updateCheckoutData("professional_full_name", itemType);
  //     updateCheckoutData("professional_profile_image", "");
  //     updateCheckoutData("professional_id", professional._id);
  //   } else {
  //     updateCheckoutData("is_auto_assign", false);
  //     updateCheckoutData("cleaner_id", professional._id);
  //     updateCheckoutData(
  //       "professional_full_name",
  //       professional.first_name + " " + professional.last_name
  //     );
  //     updateCheckoutData(
  //       "professional_profile_image",
  //       professional.profile_pic ? professional.profile_pic : ""
  //     );
  //     updateCheckoutData("professional_id", professional._id);
  //   }
  //   getTimeSlots(itemType === "Auto assign" ? "" : professional.id);
  // }

  function showProfessionalProfile(professional) {
    let apiUrl =
      process.env.REACT_APP_LOCAL_API_URL +
      "/appointment/professional/info/" +
      professional._id +
      (currentUser ? "?customer_id=" + currentUser.id : "");
    dispatch(
      getProfessionalInfoAction(apiUrl, {}, handleProfessionalInfoModalShow)
    );
    // dispatch(getProfessionalDetailsAction(apiUrl));
  }

  // function getRatingPerformance(stage, ratingCount, ratingAverage) {
  //   let percentage = 0;
  //   let totalRating = ratingAverage;
  //   let earnedRating = 0;
  //   if (stage === "Excellent") {
  //     earnedRating = ratingCount;
  //   } else if (stage === "Good") {
  //     earnedRating = ratingCount;
  //   } else if (stage === "Average") {
  //     earnedRating = ratingCount;
  //   } else if (stage === "Bad") {
  //     earnedRating = ratingCount;
  //   }
  //   percentage = ((ratingAverage / earnedRating) * 100).toFixed(1);
  //   return percentage;
  // }

  const options = {
    margin: 15,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    navText: [
      "<button type='button' class='slick-prev'><i class='fa fa-angle-left' aria-hidden='true'></i></button>",
      "<button type='button' class='slick-next'><i class='fa fa-angle-right' aria-hidden='true'></i></button>"
    ],

    nxet: "",
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 2,
        margin: 20
      },
      400: {
        items: 2,
        margin: 20
      },
      600: {
        items: 3,
        margin: 20
      },
      700: {
        items: 3,
        margin: 20
      },
      1000: {
        items: 4
      }
    }
  };

  const randomImageArray = [
    "/images/DefaultPro.webp",
    "/images/DefaultPro.webp",
    "/images/DefaultPro.webp"
  ];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  let imageIndex = Math.floor(Math.random() * randomImageArray.length);

  useEffect(() => {
    setCurrentImageIndex(imageIndex);
  }, []);

  


  return (
    <>
      <div className="professionalSec">
        {/* */}
      
        {professionals?.length > 0 && checkoutData?.professionals_count == 1 ? (
          
          <OwlCarousel
            className="owl-theme"
            items={3}
            key={checkoutData?.cleaner_id}
            margin={25}
            nav
            {...options}
          >
             
            <div
              className="item"
              onClick={() => autoProHandler({}, "Auto assign")}
              style={{zIndex:10}}
            >
              <div
                className={
                  "serviceSlider " +
                  (checkoutData.professional_full_name === "Auto assign"
                    ? "activeProfessional"
                    : "")
                }
              >
                <span style={{  background: `url(/images/window.webp)`,}} >
                  {/* <img  src="https://dvdljkkxpxqo3.cloudfront.net/images/window.png.webp" alt="window" /> */}
                </span>
                <h3>{t("auto_assign")}</h3>
                <p>{t("we_ll_assign_the_best_professional")}</p>
              </div>
            </div>
            {professionals.map((professional, index) => {
              let itemKey = index + 1;
              return (
                <div
                  className="item"
                  key={itemKey}
                  onClick={() => selectProHandler(professional, "single")}
                >
                  <div
                    className={
                      "serviceSlider " +
                      (professional._id === checkoutData?.cleaner_id && !isAutoSelected
                        ? "activeProfessional"
                        : "")
                    }
                  >
                    {professional.myFav ? (
                      <i className="fa fa-heart myfvtclr"></i>
                    ) : (
                      ""
                    )}
                    <span  
                  //   style={{
                  //     background: `url(${
                  //     professional?.profile_pic
                  //       ? professional?.profile_pic
                  //       : randomImageArray[currentImageIndex]
                  //   })`,                  
                  // }}
                  > 
                      <img
                        src={
                          professional.profile_pic
                            ? professional.profile_pic
                            : randomImageArray[currentImageIndex]
                        }
                        alt=""
                      />
                    </span>
                    <h3>
                      {professional.first_name} {" "}
                      <i className="fa fa-star">
                        {professional.ratingAverage
                          ? professional.ratingAverage.toFixed(1)
                          : 5}
                      </i>
                    </h3>
                    <p>{t("served_you_on")}</p>
                    <h6 onClick={() => showProfessionalProfile(professional)}>
                      {t("see_details1")}
                    </h6>
                  </div>
                </div>
              );
            })}
          </OwlCarousel>
        ) : (
          ""
        )}
        {loading ? <Loader /> : ""}
        <Modal
          show={professionalInfoModal}
          onHide={handleProfessionalInfoModalClose}
          id="profDetails"
          className="profDetails"
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("professional_details")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="recentTranstion professionalModal">
              <div className="reviewBar">
                <div className="professionalInfoCard">
                  <img
                    src={
                      professionalInfo
                        ? professionalInfo?.user?.profile_pic
                        : "/images/user-placeholder.webp"
                    }
                    alt=""
                  />
                  <h4 className="text-center mb-2">
                    {professionalInfo?.user?.first_name}{" "}
                    {professionalInfo?.user?.last_name}
                  </h4>
                  <div className="ratingArea">
                    <div className="ratingDiv">
                      <p>{t("ave_rating")}</p>

                      <h4>
                        <span>
                          <i className="fa fa-star"></i>
                          {professionalInfo?.user?.ratingAverage?.toFixed(1)}
                        </span>
                      </h4>
                    </div>
                    <div className="ratingDiv">
                      <p>{t("reviews")}</p>
                      <h4>{professionalInfo?.reviewCount}</h4>
                    </div>
                    <div className="ratingDiv">
                      <p>{t("hired")}</p>
                      <h4>{professionalInfo?.cleanerHired}</h4>
                    </div>
                  </div>
                </div>
                <div className="barArea">
                  <div className="reviewContent">
                    <p>
                      {t("excellent")} ({professionalInfo?.cleaner5count})
                    </p>
                  </div>
                  <div className="reviewProgress">
                    <div
                      className="barcolor"
                      style={{width: professionalInfo?.cleaner5count + "%"}}
                    ></div>
                  </div>
                </div>
                <div className="barArea">
                  <div className="reviewContent">
                    <p>
                      {t("good")} ({professionalInfo?.cleaner4count})
                    </p>
                  </div>
                  <div className="reviewProgress">
                    <div
                      className="barcolor"
                      // style={{width : getRatingPerformance('Good',professionalInfo?.cleaner4count, professionalInfo?.user?.ratingAverage.toFixed(1))+'%'}}
                      style={{width: professionalInfo?.cleaner4count + "%"}}
                    ></div>
                  </div>
                </div>
                <div className="barArea">
                  <div className="reviewContent">
                    <p>
                      {t("average")} ({professionalInfo?.cleaner3count})
                    </p>
                  </div>
                  <div className="reviewProgress">
                    <div
                      className="barcolor"
                      style={{width: professionalInfo?.cleaner3count + "%"}}
                    ></div>
                  </div>
                </div>
                <div className="barArea">
                  <div className="reviewContent">
                    <p>
                      {t("bad")} ({professionalInfo?.cleaner2count})
                    </p>
                  </div>
                  <div className="reviewProgress">
                    <div
                      className="barcolor"
                      style={{width: professionalInfo?.cleaner2count + "%"}}
                    ></div>
                  </div>
                </div>
                <div className="barArea">
                  <div className="reviewContent">
                    <p>
                      {t("terrible")} ({professionalInfo?.cleaner1count})
                    </p>
                  </div>
                  <div className="reviewProgress">
                    <div
                      className="barcolor"
                      style={{width: professionalInfo?.cleaner1count + "%"}}
                    ></div>
                  </div>
                </div>
              </div>
              <h3>{t("reviews")}</h3>
              {professionalInfo?.cleanerReviews?.length > 0
                ? professionalInfo?.cleanerReviews.map(
                    (cleanerReview, index) => {
                      return (
                        <div className="transtionList" key={index}>
                          <h3>
                            {cleanerReview.review ? cleanerReview.review : ""}{" "}
                            {cleanerReview?.additional_tip !== 0 && (
                              <span>+{cleanerReview.additional_tip} QAR</span>
                            )}
                          </h3>
                          {cleanerReview.rating_point === 5 && (
                            <p>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                            </p>
                          )}
                          {cleanerReview.rating_point === 4 && (
                            <p>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                            </p>
                          )}
                          {cleanerReview.rating_point === 3 && (
                            <p>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                            </p>
                          )}
                          {cleanerReview.rating_point === 2 && (
                            <p>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                            </p>
                          )}
                          {cleanerReview.rating_point === 1 && (
                            <p>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                            </p>
                          )}
                        </div>
                      );
                    }
                  )
                : ""}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default ProfessionalSlider;
