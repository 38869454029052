export const freeCreditApplyVoucherReducer = (state = {}, action) => {
    switch (action.type) {
        case'POST_FREECREDIT_APPLY_VOUCHER_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'POST_FREECREDIT_APPLY_VOUCHER_SUCCESS':
            return {
                discount:action.payload.discount,
                loading: false,
                success: true
            }
        case'POST_FREECREDIT_APPLY_VOUCHER_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        default:
            return state
    }
}