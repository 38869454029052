import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Modal from "../../components/Modal";
import Loader from "../../components/Loader";
import {useDispatch, useSelector} from "react-redux";
import { getAllHistoryAction } from "../../actions/FreeCreditAction";
import { useTranslation } from 'react-i18next';

const SeeAllHistory = () => {
  const [history, setHistory] = useState('Credit');
  const [allHistory, setAppHistory] = useState([]);
  const [page, setPage] = useState(1);
  const getAllHistoryState = useSelector(state => state.allHistoryReducer)
  const { loading, free_cr_data } = getAllHistoryState;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let listUrl = baseUrl + "/freeCredit/list?page=1";
  const currentLanguage = localStorage.getItem('currentLanguage') ? localStorage.getItem('currentLanguage') : 'en';
  useEffect(() => {
      dispatch(getAllHistoryAction(listUrl,{},[],setAppHistory));
  }, [listUrl]);
  var options = { year: 'numeric', month: 'long', day: 'numeric' };

  function changeHistory(e) {
    setTimeout(() => {
      setPage(1);
      setHistory(e.target.value);
      // let newHistoryUrl = baseUrl +((e.target.value === "Credit") ? "/freeCredit/list?page=1" : "/payment/history/list?page=1")
      let newHistoryUrl = baseUrl +((e.target.value === "Credit") ? "/freeCredit/list?page=1" : "/wallet/list/")
      dispatch(getAllHistoryAction(newHistoryUrl,{},[],setAppHistory));
    }, 1000);
  }

  function fetchMoreData(){
    let newPage = page+1;
    setPage(newPage);
    setTimeout(() => {
      let newHistoryUrl = baseUrl +((history === "Credit") ? "/freeCredit/list?page="+newPage : "/payment/history/list?page="+newPage)
      dispatch(getAllHistoryAction(newHistoryUrl,{},allHistory,setAppHistory));
    }, 1000);
  };

  return (
    <>
      {loading ? <Loader /> : ""}
      <Header/>
      <div className="py-5">
        <div className="userContainer">
          <div className="rightHandCredit allhistory">
            <div className="heading">
              <h2>{t('transaction_history')}</h2> 
            </div>
            <div className="inputdiv mb-2 selectallhistory">
              <select className="form-select pl-0 selectallhistory" onChange={changeHistory}>
                <option value={"Credit"} selected={history === 'Credit'}>
                  {t("credit")}
                  
                </option>
                <option value={"Payment"} selected={history === 'Payment'}>
                  {t("payment")}
                </option>
              </select>
            </div>

            <div className="card">
              <div className="recentTranstion"> 
              <h3><br/>
                {t('transaction_history')}
              </h3>
              {allHistory?.length > 0 ?
                allHistory.map((free_cr, index) => {
                  if(free_cr && free_cr.details && free_cr.type){
                    var options = { year: 'numeric', month: 'long', day: 'numeric' };
                    var created_at = new Date(free_cr.createdAt).toLocaleDateString([],options);
                    return (
                    <div className="transtionList" key={index}>
                      <h3>
                      {free_cr.details} <span>{free_cr.type === 'Credited' || free_cr.type === 'Refunded' ? '+ '+free_cr.credit : '- '+free_cr.debit} QAR</span>
                      </h3>
                      <p>{created_at}</p>
                    </div>
                    )
                 }
                }) : <span><center>{t('no_transaction_history')}</center><br/></span>}
              </div>
              {(() => {
                if(page <= free_cr_data?.totalPages){
                  return (
                    <div className="col-12">
                      <p className="loadmore text-center" onClick={() => fetchMoreData()}>
                        {t('load_more')}
                      </p>
                    </div>
                  );
                }
              })()}
            </div>
          </div>
        </div>
      </div>
      <Modal />
      <Footer />
    </>
  );
};

export default SeeAllHistory;