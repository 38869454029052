import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getAppointmentListAction} from "../../actions/appointmentAction";
import Loader from "../../components/Loader";
import {useTranslation} from "react-i18next";
import {useCountryCurrency} from "../../helpers/useCountryCurrency";
const moment = require("moment");

const AppointmentList = () => {
  const {t} = useTranslation();
  let navigate = useNavigate();
  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  const [offset, setOffset] = useState(5);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [rangeFilter, setRangeFilter] = useState("ongoing"); //past, upcoming, ongoing

  const getAppointmentState = useSelector((state) => state.appointmentReducer);

  const {loading, appointments, totalCount} = getAppointmentState;
  const countryCurrency = useCountryCurrency();

  const dispatch = useDispatch();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  const pageUrl =
    baseUrl +
    `/appointment/customer/list/?rangeFilter=${rangeFilter}&page=${page}`;

  useEffect(() => {
    dispatch(getAppointmentListAction(pageUrl));
  }, []);

  useEffect(() => {
    setPages(totalCount);
  }, [totalCount, pageUrl]);

  const appointmentFilter = (filter) => {
    setRangeFilter(filter);
    let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
    const pageUrl =
      baseUrl +
      `/appointment/customer/list/?rangeFilter=${filter}&page=${page}&country_id=${localStorage.getItem(
        "userCountryId"
      )}`;
    dispatch(getAppointmentListAction(pageUrl));
  };

  const handleScroll = (e) => {
    const bottom =
      Number((e.target.scrollHeight - e.target.scrollTop).toFixed(0)) -
        e.target.clientHeight <
      50;
    if (bottom) {
      setOffset(offset + 5);
      const pageUrl =
        baseUrl +
        `/appointment/customer/list/?rangeFilter=${rangeFilter}&offset=${offset}`;
      dispatch(getAppointmentListAction(pageUrl));
    }
  };

  function viewAppointmentDetails(id, action, rangeFilter) {
    localStorage.setItem("appointmentDetailsId", id);
    localStorage.setItem("rangeFilter", rangeFilter);
    setTimeout(() => {
      if (action === "View") {
        navigate("/my-account/appointment-details", {
          state: {apptType: rangeFilter}
        });
      } else {
        navigate("/my-account/cancel-my-appointment");
      }
    }, 500);
  }

  return (
    <>
      <div className="appoitmentButton align-items-center justify-content-between d-flex px-4">
        <button
          className={rangeFilter === "past" ? "tabBtn tabBtn-active" : "tabBtn"}
          onClick={() => appointmentFilter("past")}
        >
          {t("past")}
        </button>
        <button
          className={
            rangeFilter === "ongoing" ? "tabBtn tabBtn-active" : "tabBtn"
          }
          onClick={() => appointmentFilter("ongoing")}
        >
          {t("ongoing")}
        </button>
        <button
          className={
            rangeFilter === "upcoming" ? "tabBtn tabBtn-active" : "tabBtn"
          }
          onClick={() => appointmentFilter("upcoming")}
        >
          {t("upcoming")}
        </button>
      </div>
      {loading ? <Loader /> : ""}
      <div
        className="appointmentListBox"
        id="appointmentListBox"
        onScroll={handleScroll}
      >
        {appointments?.length > 0 ? (
          appointments.map((appointment, index) => {
            let ServiceDetail = appointment?.ServiceDetail?.find(
              (ser) => ser?._id === appointment?.service_id[0]
            );

            // let ServiceDetail = appointment.ServiceDetail.find((f) => f.);

            return (
              <div className="upcomingItem" key={index}>
                {/* <Link to="/my-account/appointmentdeatil"> */}
                <div
                  className="upcomingContent"
                  onClick={() =>
                    viewAppointmentDetails(appointment.id, "View", rangeFilter)
                  }
                >
                  <h3 className="align-items-center justify-content-between d-flex">
                    {/* {(appointment.is_home_cleaning) ? ((appointment.FrequencyDetails) ? (currentLanguage === "en" ? appointment.FrequencyDetails.package_name : appointment.FrequencyDetails.package_name_arabic) : '') : (ServiceDetail?.length > 0
                      ? ServiceDetail.name
                      : "Head Massage 30 mins")} */}
                    {currentLanguage === "en"
                      ? ServiceDetail?.name
                      : ServiceDetail?.name_arabic}
                    {/* <span>Reference Code: C21969</span> */}
                  </h3>
                  <p>
                    {moment(appointment?.appointment_date_start, [
                      "YYYY-MM-DDT00:00:00.000Z"
                    ]).format("DD/MM/YYYY")}{" "}
                    {appointment?.start_time_between
                      ? appointment?.start_time_between
                      : ""}{" "}
                  </p>
                  <div className="amount align-items-center justify-content-between d-flex">
                    <p>
                      {t("total_amount")}{" "}
                      <span>
                        {countryCurrency[0]?.currency}{" "}
                        {appointment?.FrequencyDetails?.package_name ===
                        "2 times daily"
                          ? appointment?.total_per_display_amount
                          : appointment?.FrequencyDetails?.package_name ===
                            "Weekly"
                          ? appointment?.total_per_display_amount
                          : appointment?.total_amount}
                      </span>
                    </p>
                    {(() => {
                      if (
                        (appointment.appointment_status === 0 ||
                          appointment.appointment_status === 1) &&
                        rangeFilter !== "ongoing"
                      ) {
                        return (
                          <button
                            className="btn-cancel"
                            onClick={() =>
                              viewAppointmentDetails(
                                appointment.id,
                                "Cancel",
                                rangeFilter
                              )
                            }
                          >
                            {t("cancel")}
                          </button>
                        );
                      }
                      
                      if (appointment.appointment_status === 5) {
                        return (
                          <span className="text-success">
                            {appointment.appointment_status === 5
                              ? t("refunded")
                              : ""}
                          </span>
                        );
                      }
                      if (appointment.appointment_status === 2) {
                        return (
                          <span className="text-success">
                            {appointment.appointment_status === 2
                              ? t("completed")
                              : ""}
                          </span>
                        );
                      }
                      if (appointment.appointment_status === 4) {
                        return (
                          <span className="text-success">
                            {appointment.appointment_status === 4
                              ? t("ongoing")
                              : ""}
                          </span>
                        );
                      }

                      if (appointment.appointment_status === 3) {
                        return (
                          <span className="text-danger">
                            {appointment.appointment_status === 3
                              ? t("cancelled")
                              : ""}
                          </span>
                        );
                      }
                    })()}
                    {/* //0 for pending,1 for assigned,2 for completed,3 for cancel */}
                  </div>
                  <button
                    className="verified"
                    onClick={() =>
                      viewAppointmentDetails(
                        appointment.id,
                        "View",
                        rangeFilter
                      )
                    }
                  >
                    {t("verified_professional")}
                  </button>
                </div>
                {/* </Link> */}
              </div>
            );
          })
        ) : (
          <p className="text-center">
            {rangeFilter === "past"
              ? t("dont_have_any_past_appointments")
              : rangeFilter === "ongoing"
              ? t("dont_have_any_ongoing_appointments")
              : t("dont_have_any_upcoming_appointments")}
          </p>
        )}
      </div>
    </>
  );
};

export default AppointmentList;
