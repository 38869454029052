import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import { getProfessionalInfoAction } from "../../../../actions/appointmentAction";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import Modal from "../../../../components/Modal";

const ProfessionalProfile = () => {
  const [count, setCount] = useState(5);
  const onclickHandler = (event) => {
    setCount(event.target.value);
  };
  if(!localStorage.getItem('professionalProfileId')){
    window.location.href = "/";
  }

  const getProfessionalInfoState = useSelector(state => state.appointmentReducer)
  const { professional } = getProfessionalInfoState
  const dispatch = useDispatch();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let infoUrl = baseUrl + "/appointment/professional/info/"+localStorage.getItem('professionalProfileId');
  useEffect(() => {
      dispatch(getProfessionalInfoAction(infoUrl,{}));
  }, [infoUrl]);

  return (
    <>
      <Header />
      <div className="py-5">
        <div className="userContainer">
          <div className="yourFeedback">
            <div className="heading">
              <h2>Your Rating means a lot to us!</h2>
            </div>
            <div className="card">
              <div className="feedbackProfile">
                <img alt="" src={ professional && professional.profile_pic ? professional.profile_pic : "https://dvdljkkxpxqo3.cloudfront.net/images/user-image.png.webp" } />
                <h4 className="text-center mb-2">{ professional && professional.fullName ? professional.fullName : ''}</h4>
                <div className="stars">
                  <label>
                    <input type="checkbox" />
                    <i className="fa fa-star"></i>
                  </label>
                  <label>
                    <input type="checkbox" />
                    <i className="fa fa-star"></i>
                  </label>
                  <label>
                    <input type="checkbox" />
                    <i className="fa fa-star"></i>
                  </label>
                  <label>
                    <input type="checkbox" />
                    <i className="fa fa-star"></i>
                  </label>
                  <label>
                    <input type="checkbox" />
                    <i className="fa fa-star"></i>
                  </label>
                </div>
                <div className="complementsec">
                  <div className="w-full ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Give a compliment"
                    />
                    <button className="btn btn-primary btn-fav">
                      <span className="fa fa-heart"></span>Add to favourites
                    </button>
                    {/* <textarea
                      className="form-control"
                      placeholder="Give a compliment"
                    ></textarea> */}
                  </div>
                </div>
              </div>
              <div className="addAtip">
                <div className="addAtipitem">
                  <h3>Add a tip for Victor</h3>
                  <p>Your trip was QAR {count}</p>
                  <div className="tipButton">
                    <button onClick={onclickHandler} value=" 5">
                      QAR 5
                    </button>
                    <button onClick={onclickHandler} value=" 6">
                      QAR 6
                    </button>
                    <button onClick={onclickHandler} value=" 7">
                      QAR 7
                    </button>
                  </div>
                  <h3>Enter Custom Amount</h3>
                </div>
                <button className="btn btn-primary w-full">Done</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal />
      <Footer />
    </>
  );
};

export default ProfessionalProfile;
