import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
// import {cancelAppointment} from '../helpers/helpers';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import {
  pendingCancelAppointment,
  // postBookAppointmentAction,
  // unAuthAppointmentBooking
} from "../actions/appointmentAction";
import Loader from "./Loader";
import { useTranslation } from "react-i18next";

const PendingAppointment = ({
  pendingAppointmentData,
  handlePendingAppointmentModalClose,
  showPendingAppointment
}) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const getAppointmentInfoState = useSelector(
    (state) => state.appointmentReducer
  );
let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  const {loading, status, message} = getAppointmentInfoState;
  useEffect(() => {
    if (status) {
      handlePendingAppointmentModalClose();
    }
  }, [getAppointmentInfoState]);

  const handlerPending = async (e) => {
    e.preventDefault();

    const serviceType =
      pendingAppointmentData?.appointment_cart?.ServiceDetail?.find(
        (f) => f?._id == pendingAppointmentData?.appointment_cart?.service_id[0]
      );
    if (pendingAppointmentData?.appointment_cart) {
      navigate(
        `/frequency/checkout/details?step=${pendingAppointmentData?.appointment_cart?.appointment_process}`,
        {state:{appointment_process:pendingAppointmentData?.appointment_cart,ui_type: pendingAppointmentData?.appointment_cart?.ServiceDetail[0]?.ui_type}}
      );
    }
  };
  const handlerCancel = (e) => {
    e.preventDefault();
    dispatch(
      pendingCancelAppointment(
        `${baseUrl}/customer/remove-cart-appointment/${pendingAppointmentData?.appointment_cart?._id}`
      )
    );
  };
  return (
    <>
      {loading ? <Loader /> : ""}
      <Modal
        show={showPendingAppointment}
        onHide={handlePendingAppointmentModalClose}
        className="changeCountry"
        id="changeCountry"
      >
        <Modal.Header closeButton className="modal-header">
          <h3 className="modal-title">{t('pendingAppointment')}</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            <p>
              {t('appointmentContinue')}:-
              {pendingAppointmentData?.appointment_cart?.appointment_no}
            </p>
            <div className="d-flex ">
              <button
                type="button"
                className="btn btn-primary me-1"
                onClick={(e) => handlerPending(e)}
              >
                {t('continue_btn')}
              </button>

              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => handlerCancel(e)}
              >
                {t('cancel')}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PendingAppointment;
