import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink,Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Modal from "react-bootstrap/Modal";
import Loader from "../../components/Loader";
import { useTranslation } from 'react-i18next';
import { myFavouriteProsAction, unfavouriteProsAction } from "../../actions/myFavouriteProsAction";

const MyfavPros = () => {
  const currentUser = (localStorage.getItem('currentUser') && localStorage.getItem('currentUser') !== "null") ? JSON.parse(localStorage.getItem('currentUser')) : false;

  if(!currentUser){
    setTimeout(() => {
      window.location.href = "/";
    }, 500) 
  }

  const { t } = useTranslation();
  const [showUnfevModal, setShowUnfevModal] = useState(false);
  const handleShowUnfevShow = () => setShowUnfevModal(true);
  const handleShowUnfevClose = () => setShowUnfevModal(false);
  const [prosId, setProsId] = useState('');

  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;

  const dispatch = useDispatch();
  useEffect(() => {
    const pageUrl = baseUrl + `/customer/favourite-pros/list`;
    dispatch(myFavouriteProsAction(pageUrl));
  }, []);

  const myFavouriteProsState = useSelector((state) => state.myFavouriteProsReducer);
  const { loading, favourites } = myFavouriteProsState;

  async function unfevpros(id){
    setProsId(id)
    handleShowUnfevShow();
  }

  async function submitUnfevpros(){
    const awaitUrl = baseUrl + `/customer/cleaner/unfavorite`;
    dispatch(unfavouriteProsAction(awaitUrl,{
      cleaner_id:prosId
    },handleShowUnfevClose));
}

  return (
    <>
      <Header />
      {loading ? <Loader /> : ""}
      <div className="py-5">
        <div className="userContainer">
          <div className="appointments">
            <div className="heading">
              <h2>{t('my_favourite_pros')}</h2>
            </div>
            <div className="card">
            <div className="accountSettings myFavpros">
            <Link to="/my-account/prosettings"><span className="fa fa-cog favsetting"></span></Link>
              <ul className="list">
                {favourites?.length > 0 ? (
                  favourites.map((favourite, index) => {
                    if(favourite.CleanerDetails){
                      return (
                        <li>
                          <NavLink to="" key={index} onClick={() => unfevpros(favourite.CleanerDetails._id)}>
                          <span>
                            <img alt="" src={favourite.CleanerDetails.profile_pic ? favourite.CleanerDetails.profile_pic : 'https://dvdljkkxpxqo3.cloudfront.net/images/userimg3.jpg.webp'} className="me-4 w-5" />  
                            {favourite.CleanerDetails.fullName}
                          </span>
                          <label className="heartChecked">
                            <i className="fa fa-heart"></i>
                          </label>
                          </NavLink>
                        </li>
                      );
                    }
                  })
                ) : (
                  <li><p className="text-center">{t('no_favourite_pros')}</p></li>
                )}
              </ul>
            </div>
            </div>
          </div>
        </div>
      </div>

      {/*  unfev Modal */}
      <Modal show={showUnfevModal} onHide={handleShowUnfevClose} className="modal popupNoheader" id="favPro">
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="p-4">
            <p>{t('confirmation_for_remove_fav_pros')}</p>                
          </div>
          <div className="w-full d-flex btnGroup">
            <button className="btn btn-primary btn-outline me-3" >{t('no')}</button>
            <button className="btn btn-primary" onClick={() => submitUnfevpros()}>{t('yes')}</button>
          </div>
        </Modal.Body>
      </Modal>

      <Footer />
    </>
  );
};

export default MyfavPros;
