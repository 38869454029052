import React, {useState} from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {Link} from "react-router-dom";
import jQuery from "jquery";
import {useDispatch, useSelector} from "react-redux";
import {changePasswordAction} from "../../actions/userActions";
import {useTranslation} from "react-i18next";
import EmailInput from "../../helpers/EmailInput";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  const handleChangePassword = (e) => {
    let changePasswordUrl =
      process.env.REACT_APP_LOCAL_API_URL + "/customer/change-password";
    let password = jQuery("#password").val();
    let new_password = jQuery("#new_password").val();
    let confirmation_password = jQuery("#confirmation_password").val();
    let values = {
      password: password,
      new_password: new_password,
      password_confirm: confirmation_password
    };
    dispatch(changePasswordAction(changePasswordUrl, values, t));
  };

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const [passwordShown2, setPasswordShown2] = useState(false);
  const togglePasswordVisiblity2 = () => {
    setPasswordShown2(passwordShown2 ? false : true);
  };

  const [passwordShown3, setPasswordShown3] = useState(false);
  const togglePasswordVisiblity3 = () => {
    setPasswordShown3(passwordShown3 ? false : true);
  };

  return (
    <>
      <Header />
      <div className="py-5">
        <div className="userContainer">
          <div className="editPersonalDetail">
            <div className="heading">
              <Link to="/my-account/account-details">
                <h5>{t("account_details")}</h5>
              </Link>
              <h2>{t("change_password")}</h2>
            </div>
            <div className="card">
              <div className="personalForm ">
                <div className="inputdiv col-lg-12 changeEye">
                  <label>{t("old_password")}</label>
                  <EmailInput
                    type={passwordShown ? "text" : "password"}
                    id="password"
                    name="password"
                    className="form-control"
                    placeholder={t("enter_your_old_password")}
                  />
                  <i
                    className={passwordShown ? "fa fa-eye" : "fa fa-eye-slash"}
                    onClick={togglePasswordVisiblity}
                  ></i>
                </div>
                <div className="inputdiv col-lg-12 changeEye ">
                  <label>{t("new_password")}</label>
                  <EmailInput
                    type={passwordShown2 ? "text" : "password"}
                    id="new_password"
                    name="new_password"
                    className="form-control"
                    placeholder={t("enter_your_new_password")}
                  />
                  <i
                    className={passwordShown2 ? "fa fa-eye" : "fa fa-eye-slash"}
                    onClick={togglePasswordVisiblity2}
                  ></i>
                </div>
                <div className="inputdiv col-lg-12 changeEye">
                  <label>{t("confirm_new_password")}</label>
                  <EmailInput
                    type={passwordShown3 ? "text" : "password"}
                    id="confirmation_password"
                    name="confirmation_password"
                    className="form-control"
                    placeholder={t("confirm_new_password")}
                  />
                  <i
                    className={passwordShown3 ? "fa fa-eye" : "fa fa-eye-slash"}
                    onClick={togglePasswordVisiblity3}
                  ></i>
                </div>
                <div className="inputdiv col-lg-12 mt-3 mb-0 ">
                  <button
                    className="btn btn-primary w-full"
                    onClick={handleChangePassword}
                  >
                    {t("change_password")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ChangePassword;
