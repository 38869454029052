import React, {useState, useEffect} from "react";

import Header from "../../components/Header";
import Banner from "../../components/Banner";
import ServiceBanner from "../../components/ServiceBanner";
import EmergencyCard from "../../components/EmergencyCard";
import Blog from "../../components/Blog";
import LifeEasy from "../../components/LifeEasy";
import ManyReason from "../../components/ManyReason";
import Testimonilas from "../../components/Testimonilas";
import AppDownload from "../../components/AppDownload";
import Footer from "../../components/Footer";
import ScreenedProfessionals from "../../components/ScreenedProfessionals";
import HappynessGuarantee from "../../components/HappynessGuarantee";
import NowCheck from "../../components/NowCheck";
import ProblemsNone from "../../components/ProblemsNone";
import FullImageSlider from "../../components/FullImageSlider";
import {useDispatch, useSelector} from "react-redux";
import {getHomeMainService} from "../../actions/homeAction";
import PendingAppointment from "../../components/PendingAppointment";
// import AboutUs from "../../components/AboutUs";
// import Frequency from "./Steps/Frequency";
import SwipeSlider from "../../components/SwipeSlider";
import {pendingAppointment} from "../../helpers/helpers";
// import SetupAddressModal from "../../components/LoginSignup/SetupAccountModals/SetupAddressModal";
import {useRef} from "react";
import AddressSetupComponent from "../../components/LoginSignup/SetupAccountModals/AddressSetupComponent";
function Home() {
  const [serviceId, setServiceId] = useState();
  const setAddressRef = useRef();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  // const allStates = useSelector((state) => state);
  const getAllServicesState = useSelector((state) => state.homeReducer);
  const {services} = getAllServicesState;
  const dispatch = useDispatch();
  const currentUserData = useSelector((state) => state?.userLoginReducer?.user);

  const [showPendingAppointment, setShowPendingAppointment] = useState(false);
  const [showSetupAddressModal, setShowSetupAddressModal] = useState(false);
  const handlePendingAppointmentModalClose = () =>
    setShowPendingAppointment(false);
  const handlePendingAppointmentModalShow = () =>
    setShowPendingAppointment(true);
  const [pendingAppointmentData, setPendingAppointmentData] = useState();
  useEffect(() => {
    const countryID = localStorage.getItem("userCountryId");
    let listMainUrl =
      baseUrl +
      `/home/dashboard?country_id=${localStorage.getItem("userCountryId")}`;
    if (countryID) {
      dispatch(getHomeMainService(listMainUrl));
    }
  }, []);
  useEffect(() => {
    if (currentUserData) {
      // console.log("currentUserData", currentUserData);
      if (currentUserData?.profile_status === 1) {
        setShowSetupAddressModal(true);
      }
    }
  }, [currentUserData]);

  useEffect(() => {
    let filterService = services?.filter((f) => f?.name === "HOME CLEANING");
    filterService?.forEach((sfilter) => {
      sfilter?.serviceTypes?.forEach((filter) => {
        if (filter.name === "Home Cleaning") {
          localStorage.setItem("fservice", filter?._id);
        }
      });
    });
  }, [services]);
  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  async function appointmentPending() {
    await pendingAppointment()
      .then((res) => {
        localStorage.removeItem("pendingStatus");
        setPendingAppointmentData(res?.data);
        if (res?.data?.appointment_cart) {
          handlePendingAppointmentModalShow();
        }
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  }
  useEffect(() => {
    if (currentUser) {
      appointmentPending();
    }
  }, []);

  return (
    <>
      <Header />
      <Banner />
      <PendingAppointment
        pendingAppointmentData={pendingAppointmentData}
        handlePendingAppointmentModalClose={handlePendingAppointmentModalClose}
        showPendingAppointment={showPendingAppointment}
      />
      <SwipeSlider />
      <AddressSetupComponent
        ref={setAddressRef}
        showSetupAddressModal={showSetupAddressModal}
        setShowSetupAddressModal={setShowSetupAddressModal}
      />

      {/* <AllSwiperSlider serviceName={showService}/> */}
      {/* <Frequency/> */}

      <ServiceBanner />
      <EmergencyCard />
      <FullImageSlider />
      <ScreenedProfessionals />
      <HappynessGuarantee serviceId={serviceId} />
      <Blog />
      <LifeEasy />
      <NowCheck />
      <ProblemsNone />
      <ManyReason />
      <Testimonilas />
      <AppDownload />
      {/* <AboutUs/> */}

      <Footer />
    </>
  );
}

export default Home;
