import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { ModifiedToastContainer } from "../../../components/ModifiedToastContainer";
import { saveForm } from "../../../helpers/helpers";
import { toast } from "react-toastify";
import LocationSearchModal from "../../../components/LocationSearchModal";
import { useDispatch, useSelector } from "react-redux";
import jQuery from "jquery";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/Loader";
import { countryListAction } from "../../../actions/userActions";

import ReactPhoneInput from "react-phone-input-2";
import es from "react-phone-input-2/lang/es.json";
import ar from "react-phone-input-2/lang/ar.json";
import LocationSearch from "../../../components/LocationSearch";
import { updateAddressAction } from "../../../actions/updateAddressAction";

const EditAddress = ({
  editableAddress,
  setEditableAddress,
  isEdit,
  setIsEdit,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showAddresAddModal, setShowAddressAddModal] = useState(false);
  const [showError, setShowError] = useState({
    areaError: false,
    address_typeError: false,
    streetError: false,
    buildingError: false,
    floorError: false,
    houseError: false,
    message: "",
  });
  const [isToggle, setIsToggle] = useState(true);
  const [shortName, setShortName] = useState("");
  const handleAddressAddModalShow = () => setIsEdit(true);
  const handleAddressAddModalClose = () => setIsEdit(false);
  const getCustomerAllAddressesState = useSelector(
    (state) => state.customerDetailsReducer
  );
  //   const { customerAddresses } = getCustomerAllAddressesState;

  const [addressObject, setAddressObject] = useState();
  useEffect(() => {
    setAddressObject(editableAddress);
  }, [editableAddress]);
  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  const currentLanguageCode = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;

  const getAllCountryState = useSelector((state) => state.countryReducer);
  const { loading, country_list } = getAllCountryState;
  let countryListUrl = baseUrl + "/country/list";
  let addressListUrl = baseUrl + "/customer/address/list";

  useEffect(() => {
    dispatch(countryListAction(countryListUrl, {}));
  }, [countryListUrl]);

  const country_arr = [];
  for (var i = 0; i < country_list?.length; i++) {
    if (country_list[i]?.isActive) {
      country_arr.push(country_list[i]?.name);
    }
  }
  const handleCustomerSubmit = (e) => {
    e.preventDefault();
    if (!addressObject.area) {
      setShowError({ areaError: true, message: "Area cannot be empty" });
      // alert("area");
    } else if (!addressObject.address_type) {
      setShowError({
        address_typeError: true,
        areaError: false,
        message: "Address_type cannot be empty",
      });
    } else if (!addressObject.street) {
      setShowError({ streetError: true, message: "Street cannot be empty" });
    } else if (!addressObject.building) {
      setShowError({
        buildingError: true,
        message: "Building cannot be empty",
      });
    } else if (!addressObject.floor) {
      setShowError({ floorError: true, message: "Floor cannot be empty" });
    } else if (!addressObject.house_no) {
      setShowError({ houseError: true, message: "House_No cannot be empty" });
    } else {
      setShowError({
        areaError: false,
        address_typeError: false,
        streetError: false,
        buildingError: false,
        floorError: false,
        houseError: false,
        message: "",
      });
      let awaitUrl = baseUrl + "/customer/address/update/" + addressObject.id;
      dispatch(updateAddressAction(awaitUrl, addressObject, shortName));
      handleAddressAddModalClose();
    }
  };

  const locationChangeHandel = ({ address, lat, lng, shortName }) => {
    // console.log("locationChangeHandel", address, lat, lng, shortName);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setAddressObject({
      ...addressObject,
      [name]: value,
    });
  };
  const handelShowForm = () => {
    setIsToggle(false);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAddressObject({
      ...addressObject,
      [name]: value,
    });
  };

  return (
    <div>
      <div>
        {loading ? <Loader /> : ""}
        <ModifiedToastContainer />

        {/*  Address Modal */}
        <Modal
          show={isEdit}
          onHide={handleAddressAddModalClose}
          id="addAddress"
          className="addAddressModal"
        >
          <Modal.Header closeButton>
            <Modal.Title> {t("your_location")} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleCustomerSubmit} id="addressform">
              <input
                type="hidden"
                id="_id"
                name="_id"
                value={addressObject?.address ? addressObject?.address : ""}
              />
              <input type="hidden" id="method" name="method" value="post" />
              <input type="hidden" id="country" name="country" value="" />
              <input
                type="hidden"
                id="country_short_name"
                name="country_short_name"
                value=""
              />
              <div className="modal-body" style={{ minHeight: "77vh" }}>
                <div className="mapContent">
                  <div className="col-lg-12 mb-0 relative ">
                    <input
                      type="hidden"
                      id="latitude"
                      name="latitude"
                      value={addressObject?.latitude}
                    />
                    <input
                      type="hidden"
                      id="longitude"
                      name="longitude"
                      value={addressObject?.longitude}
                    />
                  </div>
                
                  <div
                    className={
                      isToggle
                        ? "address_input_div col-lg-12 mb-0 mapToogleDiv"
                        : "address_input_div col-lg-12 mb-0 mapToogleDiv addressDetailsHide"
                    }
                    style={{ marginTop: 75 }}
                  >
                    <div
                      className={
                        isToggle ? "mapToggle  show" : "mapToggle toast hide"
                      }
                    >
                      <button
                        type="button"
                        className="btnclose"
                        data-bs-dismiss="toast"
                        onClick={handelShowForm}
                      >
                        <span className="fa fa-angle-down"></span>
                      </button>

                      <div className="row mt-4">
                        <div className="col-lg-12 inputdiv ">
                          <input
                            type="text"
                            className="form-control pl-0"
                            id="address_nickname"
                            name="address_nickname"
                            value={addressObject?.address_nickname}
                            onChange={handleInputChange}
                            placeholder={t("address_nickname")}
                          />
                          <div className="help-block"></div>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-lg-12 inputdiv ">
                          <input
                            type="text"
                            className="form-control pl-0 error"
                            id="area"
                            name="area"
                            value={addressObject?.area}
                            onChange={handleInputChange}
                            placeholder={t("area")}
                          />
                          {showError.areaError === true ? (
                            <div className="help-block error">
                              {showError.message}
                            </div>
                          ) : (
                            <div className="help-block"></div>
                          )}
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-lg-12 inputdiv ">
                          <select
                            className="form-select"
                            name="address_type"
                            value={addressObject?.address_type}
                            id="address_type"
                            onChange={onChange}
                          >
                            <option>{t("address_type")} </option>
                            <option value="Home">{t("home")} </option>
                            <option value="Apartment">{t("apartment")} </option>
                            <option value="Office">{t("office")}</option>
                          </select>
                          {showError.address_typeError === true ? (
                            <div className="help-block error">
                              {showError.message}
                            </div>
                          ) : (
                            <div className="help-block"></div>
                          )}
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-lg-6 inputdiv ">
                          <input
                            type="text"
                            className="form-control pl-0"
                            id="street"
                            value={addressObject?.street}
                            onChange={handleInputChange}
                            name="street"
                            placeholder={t("street")}
                          />
                          {showError.streetError === true ? (
                            <div className="help-block error">
                              {showError.message}
                            </div>
                          ) : (
                            <div className="help-block"></div>
                          )}
                        </div>
                        <div className="col-lg-6 inputdiv ">
                          <input
                            type="text"
                            className="form-control pl-0"
                            id="building"
                            name="building"
                            value={addressObject?.building}
                            onChange={handleInputChange}
                            placeholder={t("building")}
                          />
                          {showError.buildingError === true ? (
                            <div className="help-block error">
                              {showError.message}
                            </div>
                          ) : (
                            <div className="help-block"></div>
                          )}
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-lg-12 inputdiv ">
                          <input
                            type="text"
                            className="form-control pl-0"
                            id="floor"
                            name="floor"
                            value={addressObject?.floor}
                            onChange={handleInputChange}
                            placeholder={t("floor")}
                          />
                          {showError.floorError === true ? (
                            <div className="help-block error">
                              {showError.message}
                            </div>
                          ) : (
                            <div className="help-block"></div>
                          )}
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-lg-12 inputdiv ">
                          {addressObject?.address_type === "office_no" ? (
                            <input
                              type="text"
                              className="form-control pl-0"
                              id="home_number"
                              value={addressObject?.office_no}
                              name={"office_no"}
                              placeholder={`${t("office")}${t("number")}`}
                              onChange={handleInputChange}
                            />
                          ) : addressObject?.address_type === "apartment_no" ? (
                            <input
                              type="text"
                              className="form-control pl-0"
                              id="home_number"
                              value={addressObject?.apartment_no}
                              name={"apartment_no"}
                              placeholder={`${t("apartment")}${t("number")}`}
                              onChange={handleInputChange}
                            />
                          ) : (
                            <input
                              type="text"
                              className="form-control pl-0"
                              id="home_number"
                              value={addressObject?.house_no}
                              name={"house_no"}
                              placeholder={`${t("house")}${t("number")}`}
                              onChange={handleInputChange}
                            />
                          )}
                          {showError.houseError === true ? (
                            <div className="help-block error">
                              {showError.message}
                            </div>
                          ) : (
                            <div className="help-block"></div>
                          )}
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-lg-12 inputdiv ">
                          <input
                            type="text"
                            className="form-control pl-0"
                            value={addressObject?.additional_directions}
                            placeholder={t("additional_directions")}
                            onChange={handleInputChange}
                          />
                          <div className="help-block"></div>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-lg-12 inputdiv ">
                          <input
                            type="text"
                            className="form-control pl-0"
                            id="landline_number"
                            name="landline_number"
                            value={addressObject?.landline_number}
                            onChange={handleInputChange}
                            placeholder={t("landline_number")}
                          />
                          <div className="help-block"></div>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-lg-12 inputdiv ">
                          <div className="row loginMobileInput mb-2">
                            <div className="inputgroup phonecodeselect col-lg-3">
                              <ReactPhoneInput
                                inputProps={{
                                  className: "login_country_code form-control",
                                  name: "country_code",
                                  id: "country_code",
                                  autoFocus: true,
                                  placeholder: t("enter_mobile_number"),
                                  readOnly: "readonly",
                                }}
                                value={addressObject?.country_code}
                                localization={
                                  currentLanguageCode === "en" ? es : ar
                                }
                                countryCodeEditable={false}
                                placeholder=""
                                disableCountryCode={false}
                                searchClass="search-class"
                                searchStyle={{
                                  margin: "0",
                                  width: "97%",
                                  height: "30px",
                                }}
                                enableSearch
                                disableSearchIcon
                              />
                              <div className="help-block text-danger"></div>
                            </div>

                            <div className="inputgroup phonecodeselect col-lg-9">
                              <input
                                type="number"
                                name="mobile"
                                id="mobile"
                                className="form-control"
                                onChange={handleInputChange}
                                value={addressObject?.mobile}
                                placeholder={t("enter_mobile_number")}
                              />
                              <div className="help-block text-danger"></div>
                            </div>
                          </div>
                          <div className="help-block"></div>
                        </div>
                      </div>
                    </div>
                    <button className="btn btn-primary">{t("confirm")}</button>
                  </div>
                </div>
                <div className="divMap">
                  <LocationSearch
                    translatedValue={t}
                    defaultLat={addressObject ? addressObject?.latitude : ""}
                    defaultLng={addressObject ? addressObject?.longitude : ""}
                    onLocationChange={locationChangeHandel}
                    setIsToggle={setIsToggle}
                    setShortName={setShortName}
                    setAddressObject={setAddressObject}
                    addressObject={addressObject}
                  />

                  {/* <LocationSearchModal
                    translatedValue={t}
                    Latitude={addressObject ? addressObject?.latitude : ""}
                    Longitude={addressObject ? addressObject?.longitude : ""}
                    saveContinue={true}
                  /> */}
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default EditAddress;
