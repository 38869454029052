import React from 'react';
import {Link} from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";


const RighthandsClub = () => {
 
return (
    <>    
        <Header/>
        <div className="py-5">
        <div className="userContainer ">
          <div className="yourFeedback">
          <div className="heading">
          <h2>Your Righthands Life Awaits</h2>
          <p>Join the club and let us do the Job. Just QR. 499/- to enjoy the following<br/> benefits for a whole year.</p>
          </div>
            <div className="card ">
            <div className='p-sm-5 p-3'>
            <div className="rightClubList">
            <ul>
              <li><img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/money.png.webp"/>
              30 Days Money-back guarantee
              </li>
              <li><img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/credit.png.webp"/> QR.1500 in Credits </li>
              <li> <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/rlabel.png.webp"/>Preferential member rates </li>
              <li><img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/offer.png.webp"/>Special Offers</li>
            </ul>            
            </div>
            <p>*You have 3 months to decide if the Righthands Club fits your lifestyle. If you had 1 booking,you are eligible for a refund of up to QAR59.</p>
            </div>
           
            <div className="popupFooter">
              <div className="checkboxItem p-4 ">
              <div className="form-check ">
              <input type="radio" className="form-check-input" name="optradio" defaultChecked/>
              <label className="form-check-label">I agree with Right Hands' <Link to="">Privacy Policy , Terms and Conditions</Link></label>
              </div>  </div>
              <div className="w-full d-flex px-4 mb-4 ">       
              <button className="btn w-full me-1 fw-bold  ">Not Now</button>      
               <button className="btn btn-primary w-full  "    
                    >Yes, Please!
                    </button>
                </div>
              </div>
            </div>
        </div>
        </div>
       </div>
     <Footer/>
    </>
  )
}

export default RighthandsClub