import React, { useState,forwardRef,useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next'; 
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { removeCartAppointmentAction } from "../../../actions/appointmentAction";

const AppointmentCartModal = forwardRef((props, ref) => {

  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  const { t } = useTranslation();
  const [appointmentCartModal, setAppointmentCartModal] = useState(false);
  const handleAppointmentCartModalShow = () => setAppointmentCartModal(true);
  const handleAppointmentCartModalClose = () => setAppointmentCartModal(false);  
  const [bookingUrl, setBookingUrl] = useState("/~mantis/");
  const dispatch = useDispatch();
  
  async function removeCartAppointment(){
    let bookAppointmentId = localStorage.getItem('bookAppointmentId');
    if(bookAppointmentId){
      let removeCartUrl = baseUrl+`/customer/remove-cart-appointment/${bookAppointmentId}`;
      dispatch(removeCartAppointmentAction(removeCartUrl,handleAppointmentCartModalClose));
    }
  }

  useImperativeHandle(ref, () => ({
    handleShowAppointmentCartItem() {
      handleAppointmentCartModalShow(true);
      /* let mybookingUrl = (cart?.is_home_cleaning) ? '/frequency/checkout/details' : '/service/checkout/details?step=1&service_id='+cart._id;
      setTimeout(() => {
        setBookingUrl(mybookingUrl);
      }, 500); */
    }
  }),[props]);

    return (
      <div>
       {/* appointment cart Modal start */}
        <Modal show={appointmentCartModal} onHide={handleAppointmentCartModalClose} className="appointmentCart" id="appointmentCart">
          <Modal.Header className="appointmentCartModalHead">
            <button type="button" className="btn-close" onClick={removeCartAppointment}></button>
          </Modal.Header>
          <Modal.Body className="appointmentCartModalBody">
            <div className="appointmentCartModal">                 
              <div className="p-4">            
                <p>{ t('finish_your_one_off_boooking') }</p>              
              </div>
              <div className="w-full d-flex btnGroup mb-2">
                <Link to="/" className="btn btn-primary finish-my-booking">{ t('finish_my_booking') }</Link>
              </div>
            </div>
          </Modal.Body>
        </Modal>  
        {/* appointment cart Modal end */}
      </div>
    );
})

export default AppointmentCartModal;
