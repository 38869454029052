export const userLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case'USER_LOGIN_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'USER_LOGIN_SUCCESS':
            return {
                ...state,
                loading: false,
                success: true,
                user: action.payload
            }
        case'USER_LOGIN_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        case'USER_OTP_VERIFICATION_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'USER_OTP_VERIFICATION_SUCCESS':
            return {
                ...state,
                loading: false,
                success: true,
                user: action.payload
            }
        case'USER_OTP_VERIFICATION_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        case'USER_PROFILE_SETUP_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'USER_PROFILE_SETUP_SUCCESS':
            return {
                ...state,
                loading: false,
                success: true,
                user: action.payload
            }
        case'USER_PROFILE_SETUP_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        case'USER_ADDRESS_SETUP_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'USER_ADDRESS_SETUP_SUCCESS':
            return {
                ...state,
                loading: false,
                success: true,
                user: action.payload
            }
        case'USER_ADDRESS_SETUP_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        case'PASSWORD_CHANGE_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'PASSWORD_CHANGE_SUCCESS':
            return {
                ...state,
                loading: false,
                success: true,
                user: action.payload
            }
        case'PASSWORD_CHANGE_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        case'PROSETTING_CHANGE_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'PROSETTING_CHANGE_SUCCESS':
            return {
                ...state,
                loading: false,
                success: true,
                user: action.payload
            }
        case'PROSETTING_CHANGE_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        case'FORGOT_PASS_MAIL_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'FORGOT_PASS_MAIL_SUCCESS':
            return {
                ...state,
                loading: false,
                success: true,
                user: action.payload
            }
        case'FORGOT_PASS_MAIL_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        case'RESET_PASSWORD_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'RESET_PASSWORD_SUCCESS':
            return {
                ...state,
                loading: false,
                success: true,
                user: action.payload
            }
        case'RESET_PASSWORD_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        case'USER_LOGOUT':
            return {
                ...state,
            }
        default:
            return state
    }
}