import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import jQuery from 'jquery';
import ReactPhoneInput from 'react-phone-input-2';
import es from 'react-phone-input-2/lang/es.json'
import ar from 'react-phone-input-2/lang/ar.json';
const currentLanguageCode = localStorage.getItem('currentLanguage') ? localStorage.getItem('currentLanguage') : 'en';

const SignwithMobile = () => {
  const userCountryCode = localStorage.getItem('userCountryCode') ? localStorage.getItem('userCountryCode') : 'en';
  const handleFormSubmit = (e) => {
   
    e.preventDefault();
    let mobile = jQuery('#mobile').val();

    let country_code = jQuery('#country_code').val();
    let frm = document.getElementById('loginForm');
    // console.log(country_code, '6666');
    var mobileNumberRange = 10;
    if(country_code === '+974'){
      mobileNumberRange = 8;
    }
    if(mobile && mobile.length === mobileNumberRange){
      jQuery('.createOtpSubmit').removeClass('disabled');
      jQuery('.createOtpSubmit').removeAttr('disabled');
    
    }else{
      jQuery('.createOtpSubmit').addClass('disabled');
      jQuery('.createOtpSubmit').attr('disabled', 'true');
    }
  }

  
  return (
    <>
      <Header />

      <div className="py-5">
        <div className="userContainer">
          <div className="editPersonalDetail">
            <div className="heading">
              <h2>Welcome</h2>
              <p>We Will Send You An One Time Password On This Mobile Number</p>
            </div>
            <div className="card">
              <div className="personalForm">
              <form onSubmit={handleFormSubmit} id="loginForm">
              <div className="inputdiv">
                <label>Mobile Number</label>
                  <div className="row loginMobileInput mt-0">
                    <div className="inputgroup phonecodeselect col-lg-5 pr-0">
                    <ReactPhoneInput
                      inputProps={{
                        className:"login_country_code form-control",
                        name: "country_code",
                        id: "country_code",
                        autoFocus: true,
                        placeholder: "enter_mobile_number",
                        readOnly: "readonly",
                      }}
                      country={userCountryCode.toLocaleLowerCase()}
                      localization={currentLanguageCode === 'en' ? es : ar}
                      countryCodeEditable={false}
                      placeholder=""
                      disableCountryCode={false}
                      searchClass="search-class"
                      searchStyle={{
                        margin: "0",
                        width: "97%",
                        height: "30px",
                      }}
                      enableSearch
                      disableSearchIcon
                    />
                    <div className="help-block text-danger"></div>
                    </div>
                    
                    <div className="inputgroup phonecodeselect col-lg-7">
                      <input
                          type="number"
                          name="mobile"
                          id="mobile"
                          className="form-control login_mobile"
                        
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          placeholder="Enter mobile number"
                        />
                      <div className="help-block text-danger"></div>
                    </div>
                  </div>
              </div>
            </form>
               
                <div className="inputdiv col-lg-12 mt-3 mb-0 ">
                  <button className="btn btn-primary w-full ">Get OTP</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SignwithMobile;
