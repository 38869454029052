import React from "react";
import {Spinner} from "react-bootstrap";

const Loader = () => {
  return (
    // <div className="PageLoader">
    //     <div>
    //         <div className="spinner-border text-primary" role="status">
    //     </div>
    //     <p >Loading...</p></div>
    // </div>
    <div className="position-absolute top-50 start-50 translate-middle">
      <Spinner animation="border" variant="warning" />
    </div>
  );
};

export default Loader;
