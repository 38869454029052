/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useCallback, useEffect, useRef} from "react";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getFrequencyListAction} from "../../../../actions/frequencyAction";
import {freeCreditApplyVoucherAction} from "../../../../actions/freeCreditApplyVoucherAction";
import Loader from "../../../../components/Loader";
import CheckoutSummary from "../components/CheckoutSummary";
import {useTranslation} from "react-i18next";
import {fromLatLng} from "react-geocode";
// import AddressAddModal from "../../../my_account/Address/AddressAddModal";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker
} from "react-google-maps";
import AddressBooking from "./AddressBooking";
import {useState} from "react";
import {ValidateCountry} from "../../../../helpers/bookingAPIsteps";
import AddressForm from "./addressForm";
// import {useCountryCurrency} from "../../../../helpers/useCountryCurrency";

export const Frequency = ({
  checkoutData,
  updateCheckoutData,
  appointment,
  isLoggedIn,
  setCheckoutData,
  isLocation,
   setIsLocation,
   packageType,
}) => {
  const [marker, setMarker] = useState();
  const [center, setCenter] = useState();
  const [isToggle, setIsToggle] = useState(true);
  const [isValidLocation, setIsValidLocation] = useState(false);
  const [isAddress, setIsAddress] = useState(false);
  const [addressObject, setAddressObject] = useState();
  const [showAddressAddModal, setShowAddressAddModal] = useState(false);
  const getAllFrequencyState = useSelector((state) => state.frequencyReducer);
  const getAllCountryState = useSelector((state) => state.countryReducer);
  const {country_list} = getAllCountryState;
  const {loading, frequencies} = getAllFrequencyState;
  const [newCustomerAddress, setNewCustomerAddress] = useState();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let listUrl = baseUrl + "/home/dashboard";
  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  let voucher = params.get("voucher") ? params.get("voucher") : "";
  let serviceID = params.get("service_id") ? params.get("service_id") : "";
  useEffect(() => {
    if (serviceID !== "") {
      setCheckoutData({
        ...checkoutData,
        service_id: [serviceID],
        service_type: 'Frequency'
      });
      packageType = 'Frequency'
    }
  }, [serviceID])

  const checkValidateCountry = async ({baseUrl, data}) => {
    const isCountryValid = await ValidateCountry({baseUrl, data});
    setIsValidLocation(isCountryValid === false ? false : true);
  };
  const getAddressDetails = (addressArray) => {
    const getComponent = (type) => {
      const component = addressArray.find((comp) => comp.types.includes(type));
      return component ? component.long_name : "";
    };

    return {
      area: getComponent("sublocality"),
      building: getComponent("premise"),
      street: getComponent("route"),
      city: getComponent("locality"),
      country: getComponent("country"),
      postalCode: getComponent("postal_code")
    };
  };
  // useEffect(() =>{
  // if(!showAddressAddModal){
  //   setIsLocation(true);
  // }
  // }, [showAddressAddModal])
  const handleMarkerDragEnd = useCallback((event) => {
    const {latLng} = event;
    const lat = latLng.lat();
    const lng = latLng.lng();

    setCenter({lat, lng});
    setMarker({lat, lng});
    setAddressObject({
      ...addressObject,
      latitude: lat,
      longitude: lng
    });

    fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        const addressArray = response.results[0].address_components;
        const countryComponent = addressArray.find((component) =>
          component.types.includes("country")
        );
        const addressDetails = getAddressDetails(addressArray);

        // Now you can use the values if they exist
        const area = addressDetails?.area ? addressDetails?.area : "";
        const building = addressDetails?.building
          ? addressDetails?.building
          : "";
        const street = addressDetails?.street ? addressDetails?.street : "";
        const city = addressDetails?.city ? addressDetails?.city : "";
        let newShortName = countryComponent.short_name;
        let longName = countryComponent.long_name;
        let url = baseUrl + `/validate/location`;
        let data = {country_code_short: newShortName};
        checkValidateCountry({baseUrl: url, data});

        // setShortName(countryComponent.short_name);
        setAddressObject({
          ...addressObject,
          address: address,
          countryLong: longName,
          latitude: lat,
          longitude: lng,
          area: area,
          building: building,
          street: street,
          city: city
        });
        setIsToggle(true);
      },
      (error) => {
        console.error(error);
      }
    );
  }, []);
  const AsyncMap = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        defaultZoom={15}
        center={{
          lat: center?.lat ? center?.lat : 0,
          lng: center?.lng ? center?.lng : 0
        }}
        onClick={(event) => handleMarkerDragEnd(event)}
      >
        {marker && (
          <Marker
            google={props?.google}
            position={{lat: marker?.lat, lng: marker?.lng}}
            draggable={true}
            onDragEnd={(event) => handleMarkerDragEnd(event)}
          />
        )}
      </GoogleMap>
    ))
  );

  const get_isHome_cleaning = () => {
    if (
      checkoutData?.is_home_cleaning &&
      checkoutData?.country_id !== undefined
    ) {
      let frequencyUrl =
        baseUrl +
        `/get/frequency?country_id=${
          checkoutData?.country_id
            ? checkoutData?.country_id
            : localStorage.getItem("userCountryId")
        }&service_type=${packageType === "Frequency"  ? "Home" : 'Baby'}`;
      dispatch(getFrequencyListAction(frequencyUrl, {}));
    } else {
      let frequencyUrl =
        baseUrl +
        `/get/frequency?country_id=${
          checkoutData?.country_id
            ? checkoutData?.country_id
            : localStorage.getItem("userCountryId")
        }&service_type=Baby for babysitting`;
      if (checkoutData?.country_id !== undefined) {
        dispatch(getFrequencyListAction(frequencyUrl, {}));
      }
    }
  };
  useEffect(async () => {
    const data = await get_isHome_cleaning();
    window.scrollTo(0, 0);
  }, []);
  useEffect(async () => {
    if (newCustomerAddress) {
      const filteredArray = country_list.filter(
        (item) => item?.name === newCustomerAddress?.countryLong
      );
      updateCheckoutData("country_id", filteredArray[0]?._id);
      if (filteredArray?.length > 0) {
        if (filteredArray[0]?._id) {
          const data = await get_isHome_cleaning();
        }
      }
    }
  }, [newCustomerAddress]);

  useEffect(() => {
    localStorage.removeItem("discoverBookingPage");
    // // dispatch(getFrequencyListAction(listUrl,{}));
    // updateCheckoutData("professional_full_name", "Auto assign");
    // updateCheckoutData("professional_profile_image", "");
  }, [listUrl]);

  useEffect(() => {
    if (
      voucher &&
      voucher !== "" &&
      checkoutData?.voucher !== "" &&
      voucher !== checkoutData.voucher
    ) {
      let postUrl = baseUrl + "/freeCredit/apply/voucher";
      dispatch(
        freeCreditApplyVoucherAction(
          postUrl,
          {
            voucher: voucher,
            customer_id: currentUser ? currentUser._id : ""
          },
          updateCheckoutData
        )
      );
    }
  }, []);
  const setAddAddressRef = useRef();

  useEffect(() => {
    if (currentUser === false) {
      // setAddAddressRef.current.handleAddress();
      handleAddressAddModalShow();
      window.scrollTo(0, 0);
    }
  }, []);
  const handleAddressAddModalShow = () => { 
    setShowAddressAddModal(true);

  };
  const setFrequency = (frequency) => {
    updateCheckoutData("frequency_id", frequency?.id);
    updateCheckoutData("frequency_name", frequency?.package_name);
    updateCheckoutData("frequency_nameArabic", frequency?.package_name_arabic);
  };

  return (
    <>
      {showAddressAddModal ? (
        <>
          <div className="stepsContent">
            <div className="row">
              <AddressBooking
                showAddressAddModal={showAddressAddModal}
                newCustomerAddress={newCustomerAddress}
                setShowAddressAddModal={setShowAddressAddModal}
                setIsLocation={setIsLocation}
                handleAddressAddModalShow={handleAddressAddModalShow}
                setNewCustomerAddress={setNewCustomerAddress}
                updateCheckoutData={updateCheckoutData}
                setCheckoutData={setCheckoutData}
                checkoutData={checkoutData}
                AsyncMap={AsyncMap}
                setMarker={setMarker}
                setCenter={setCenter}
                isToggle={isToggle}
                setIsToggle={setIsToggle}
                addressObject={addressObject}
                setAddressObject={setAddressObject}
                handleMarkerDragEnd={handleMarkerDragEnd}
                setIsValidLocation={setIsValidLocation}
                isValidLocation={isValidLocation}
                isAddress={isAddress} setIsAddress={setIsAddress}
              />
              
              <div className="col-lg-5 col-md-12">
              {isAddress &&  <div className=" p-4 addresFormHeight">
                  <AddressForm
                    isToggle={isToggle}
                    setIsToggle={setIsToggle}
                    addressObject={addressObject}
                    setAddressObject={setAddressObject}
                    setIsLocation={setIsLocation}
                    updateCheckoutData={updateCheckoutData}
                    setCheckoutData={setCheckoutData}
                    checkoutData={checkoutData}
                    setShowAddressAddModal={setShowAddressAddModal}
                  />
                </div>}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="stepsContent  ">
          <div className="row">
            <div className="col-lg-7 col-md-12">
              <div className="card">
                <div className="stepsCard justify-content-start">
                  <div className="checkboxSec">
                    <div className="heading">
                      <h3>{t("how_often_need_professional")}</h3>
                    </div>

                    {loading ? <Loader /> : ""}
                    {frequencies?.length > 0
                      ? frequencies.map((frequency, index) => {
                          if (
                            index === 0 &&
                            checkoutData?.frequency_id === ""
                          ) {
                            setFrequency(frequency);
                          }

                          return (
                            <div className="checkboxItem" key={index}>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  id={"frequency_" + frequency._id}
                                  name="optradio"
                                  defaultChecked={
                                    checkoutData.frequency_id === "" &&
                                    index === 0
                                      ? true
                                      : checkoutData.frequency_id ===
                                        frequency._id
                                      ? true
                                      : false
                                  }
                                  onClick={() => setFrequency(frequency)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={"frequency_" + frequency.id}
                                >
                                  {currentLanguage === "en"
                                    ? frequency.package_name
                                    : frequency.package_name_arabic}
                                  {(
                                    currentLanguage === "en"
                                      ? frequency.offer
                                      : frequency.offer_arabic
                                  ) ? (
                                    <span>
                                      <i className="fa fa-tag"></i>
                                      {frequency.offer}% {t("off")}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </label>
                              </div>
                              <p>
                                {localStorage.getItem("serviceName") ===
                                  "Baby Sitting & Caregivers" &&
                                frequency.package_name === "2 times daily"
                                  ? ""
                                  : currentLanguage === "en"
                                  ? frequency.description
                                  : frequency.description_arabic}
                              </p>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                  <div className="checkboxItem cancelPolicy mt-5">
                    <p>
                      {" "}
                      <span className="fa fa-book me-2"></span>
                      <Link to="/cancellation/policy" target="_blank">
                        {t("cancellation_policy")}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-5">
              <CheckoutSummary
                checkoutData={checkoutData}
                appointment={appointment}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Frequency;
