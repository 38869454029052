import axios from 'axios';
import {authHeader, handlingAuthErrors} from "../helpers/helpers";
import { toast } from 'react-toastify';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// const MySwal = withReactContent(Swal);

export const extendMemberShipAction = (baseUrl,values,setPageLoading,navigate,form) => dispatch => {
    setPageLoading(true);
    dispatch({type:'EXTEND_MEMBERSHIP_REQUEST'});

    axios({
        method: 'PUT',
        headers:authHeader(),
        url: baseUrl,
        data: values
    }).then((res) => {
        dispatch({
            type: "EXTEND_MEMBERSHIP_SUCCESS",
            payload: {
                appointmentsData:res.data.appointment
            }
          });
        toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
       setPageLoading(false)
       if(form !== "paymentGateway"){
        setTimeout(function () {
            navigate("/my-account/appointment-details",{state:{apptType:localStorage.getItem("rangeFilter")}})
              }, 2000);
       }
      
    }).catch((err) => {
        setPageLoading(false)
      dispatch({type: 'EXTEND_MEMBERSHIP_FAILED', payload: err});
        handlingAuthErrors(err)
    });
};

 