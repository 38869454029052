import axios from 'axios'
import {toast} from "react-toastify";
import {authHeader, handlingAuthErrors} from "../helpers/helpers";

export const getSubscriptionPlanAction = (baseUrl,values) => dispatch => {

    dispatch({type: 'GET_SUBS_PLAN_LIST_REQUEST'})
    axios({
        method: 'GET',
        headers:authHeader(),
        url: baseUrl,
        data: values
    }).then((res) => {
        dispatch({type: 'GET_SUBS_PLAN_LIST_SUCCESS', payload: {
            subscription_list:res.data.list
        }});
    }).catch((err) => {
        dispatch({type: 'GET_SUBS_PLAN_LIST_FAILED', payload: err});
        handlingAuthErrors(err)
    })
}

export const postBuyPackageAction = (baseUrl,values) => dispatch => {
    dispatch({type: 'POST_BUY_PACKAGE_REQUEST'})
    axios({
        method: 'POST',
        headers:authHeader(),
        url: baseUrl,
        data: values
    }).then((res) => {
        dispatch({type: 'POST_BUY_PACKAGE_SUCCESS', payload: {}});
        localStorage.removeItem('subscriptionId');
        toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        localStorage.removeItem('frequencyCheckoutData');
        setTimeout(function () {
            window.location.href = "/my-account/righthandcredit";
        }, 1000);
    }).catch((err) => {
        dispatch({type: 'POST_BUY_PACKAGE_FAILED', payload: err});
        handlingAuthErrors(err);
    })
}