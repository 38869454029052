import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import es from "react-phone-input-2/lang/es.json";
import { useTranslation } from "react-i18next";
import AddImage from "./AddImage";

const Modal = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="modal popupNoheader finishMyBooking" id="finishBooking">
        <div className="modal-dialog modal-dialog-centered text-center">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
            ></button>
            <div className="modal-body mt-2   ">
              <div className="p-4">
                <p>
                  Hurry up! The available slots are quickly running out. To
                  finish your One off boooking, click on the button below and
                  pick up where you left off.
                </p>
              </div>
              <div className="w-full d-flex btnGroup  mb-2">
                <button className="btn btn-primary  " data-bs-dismiss="modal">
                  Finish My Booking
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal " id="moreService">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">Add More Services </h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body text-center announcementsModal">
              <div className="py-3">
                <img
                  src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/service/announcements.png.webp"
                  className="mb-4 p-3"
                />
                <h3>You don't have any announcements yet</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal popupNoheader " id="addPhoto">
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: "700px" }}
        >
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
            ></button>
            <div className="modal-body  ">
              <div className="taketourPopup">
                <h3>{t("add_photos")}</h3>
                <AddImage />
              </div>
             
            </div>
          </div>
        </div>
      </div>
      <div className="modal popupNoheader " id="takeTourOne">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
            ></button>
            <div className="modal-body text-center ">
              <div className="taketourPopup">
                <img
                  src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/tour-1.png.webp"
                  className="mb-4 p-3 img-fluid"
                />
                <h1 className="mb-3">
                  Welcome <br />
                  to My Credits
                </h1>
                <p>
                  Track credits earned through Rh Services
                  <br /> and spend them too!
                </p>
                <ul>
                  <li>
                    <span
                      className="active"
                      data-bs-toggle="modal"
                      data-bs-target="#takeTourOne"
                    ></span>
                  </li>
                  <li>
                    <span
                      data-bs-toggle="modal"
                      data-bs-target="#takeTourTwo"
                    ></span>
                  </li>
                  <li>
                    <span
                      data-bs-toggle="modal"
                      data-bs-target="#takeTourThree"
                    ></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal popupNoheader " id="takeTourTwo">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
            ></button>
            <div className="modal-body text-center ">
              <div className="taketourPopup">
                <img
                  src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/tour-2.png.webp"
                  className="mb-4 p-3 img-fluid"
                />
                <h1 className="mb-3">
                  Right Hands <br />
                  Club Credits
                </h1>
                <p>
                  All Right Hands Club Members get QR. 1500* or equivalent in
                  any other currency in credits to spend on 10 different
                  services, with QR 150 OFF the price total of each one.
                </p>
                <ul>
                  <li>
                    <span
                      data-bs-toggle="modal"
                      data-bs-target="#takeTourOne"
                    ></span>
                  </li>
                  <li>
                    <span
                      data-bs-toggle="modal"
                      data-bs-target="#takeTourTwo"
                      className="active"
                    ></span>
                  </li>
                  <li>
                    <span
                      data-bs-toggle="modal"
                      data-bs-target="#takeTourThree"
                    ></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal popupNoheader " id="takeTourThree">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
            ></button>
            <div className="modal-body text-center ">
              <div className=" taketourPopup">
                <img
                  src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/tour-3.png.webp"
                  className="mb-4 p-3 img-fluid"
                />
                <h1 className="mb-3">
                  Send Invites <br /> and Earn
                </h1>
                <p>
                  {" "}
                  Invite friends to use Right Hands Services. This gives them
                  QR. 40{" "}
                </p>
                <button className="btn btn-primary mt-3">GOT IT</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal " id="announcements">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">Announcements </h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body text-center announcementsModal">
              <div className="py-3">
                <img
                  src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/service/announcements.png.webp"
                  className="mb-4 p-3"
                />
                <h3>You don't have any announcements yet</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal " id="trackPro">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">
                Track Pro<i className="flag flag-us"></i>
              </h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body p-0">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1846202.3191063385!2d50.096336329365315!3d25.342347489106213!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45c534ffdce87f%3A0x1cfa88cf812b4032!2sQatar!5e0!3m2!1sen!2sin!4v1645441928197!5m2!1sen!2sin"
                width="100%"
                height="240"
                allowFullScreen=""
                loading="lazy"
              ></iframe>
              <div className="trackPoContent">
                <p>Estimated arrival</p>
                <h2>9:15AM-9:25AM</h2>
                <div
                  className="progressbar my-3"
                  style={{ width: "70%" }}
                ></div>
                <h3>Your Pro is on the way</h3>
                <div className="trackProList">
                  <ul className="list">
                    <li>
                      <div>
                        <h3>Abdulla</h3>
                        <p>is your hero for today</p>
                      </div>
                      <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/driver.png.webp" className="w100" />
                    </li>
                    <li>
                      <div>
                        <h3>Support</h3>
                        <p>Booking Reference #66236262</p>
                      </div>
                    </li>
                    <li>
                      <a href="#">
                        {" "}
                        Help Center
                        <span>
                          <i className="fa fa-angle-right"></i>
                        </span>{" "}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal popupNoheader " id="favPro">
        <div className="modal-dialog modal-dialog-centered text-center">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
            ></button>

            <div className="modal-body  ">
              <div className="p-4">
                <p>
                  Are you sure you want to remove this pro from your favorites
                  list?
                </p>
              </div>
              <div className="w-full d-flex btnGroup">
                <button
                  className="btn btn-primary btn-outline  me-3 "
                  data-bs-dismiss="modal"
                >
                  No
                </button>
                <button className="btn btn-primary  " data-bs-dismiss="modal">
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal " id="customTip">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">Custom Tip Amount</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body text-center">
              <div className="inputdiv col-lg-12 mt-4 ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Amount"
                ></input>
              </div>

              <div className="row">
                <div className="w-full d-flex btnGroup mb-2">
                  <button
                    className="btn btn-primary btn-outline  me-3 "
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button className="btn btn-primary  " data-bs-dismiss="modal">
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal " id="claimAccount">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">Claim Your Account</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body text-center">
              <p className="py-2">
                Claim your online account to get access to bookings you made
                over the phone or via chat. Claim your online account to get
                access to bookings.
              </p>
              <div className="inputdiv col-lg-12  ">
                <input
                  type="text"
                  className="form-control"
                  placeholder=" Email Address"
                ></input>
              </div>

              <div className="row">
                <div className="w-full d-flex ">
                  <button className="btn btn-primary  " data-bs-dismiss="modal">
                    Claim Account
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal popupNoheader " id="claim-account-popup">
        <div className="modal-dialog modal-dialog-centered text-center">
          <div className="modal-content">
            <div className="modal-body  ">
              <div className="p-4">
                <h3 className="mb-2">Booked over phone or chat?</h3>
                <p>
                  Claim your online account to get access to bookings you made
                  over the phone or via chat.
                </p>
              </div>
              <div className="w-full d-flex btnGroup">
                <button className="btn btn-primary  " data-bs-dismiss="modal">
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal " id="customTip">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">Custom Tip Amount</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body text-center">
              <div className="row">
                <div className="inputdiv col-lg-12 mt-2 ">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter amount"
                  ></input>
                </div>
                <div className="w-full d-flex btnGroup">
                  <button
                    className="btn btn-primary btn-outline  me-3 "
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button className="btn btn-primary  " data-bs-dismiss="modal">
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal " id="share">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">Share</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body text-center">
              <div className="row sharSec py-3">
                <div className="col-lg-4 col-4 mb-4">
                  <a href="#">
                    <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/email.png.webp" className="mb-2"/>
                    Email
                  </a>
                </div>
                <div className="col-lg-4 mb-4 col-4">
                  <a href="#">
                    <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/sms.png.webp" className="mb-2"/>
                    Sms
                  </a>
                </div>
                <div className="col-lg-4 mb-4 col-4">
                  <a href="#">
                    <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/twitter.png.webp" className="mb-2"/>
                    {/* Twitter */}
                  </a>
                </div>
                <div className="col-lg-4 col-4">
                  <a href="#">
                    <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/facebook.png.webp" className="mb-2"/>
                    Facebook
                  </a>
                </div>
                <div className="col-lg-4 col-4">
                  <a href="#">
                    <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/share.png.webp" className="mb-2"/>
                    Others
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal popupNoheader" id="righthandsClub">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
            ></button>

            <div className="modal-body p-0">
              <div className="p-4 pt-5 clubScroll">
                <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/logo.png.webp" className="mb-3" />
                <h3>Your Righthands Life Awaits</h3>
                <p className="py-2">
                  Join the club and let us do the Job. Just QR. 499/- to enjoy
                  the following benefits for a whole year.
                </p>
                <div className="rightClubList">
                  <ul>
                    <li>
                      <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/money.png.webp" />
                      30 Days Money-back guarantee
                    </li>
                    <li>
                      <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/credit.png.webp" /> QR.1500 in Credits{" "}
                    </li>
                    <li>
                      {" "}
                      <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/rlabel.png.webp" />
                      Preferential member rates{" "}
                    </li>
                    <li>
                      <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/offer.png.webp" />
                      Special Offers
                    </li>
                  </ul>
                </div>
                <p>
                  *You have 3 months to decide if the Righthands Club fits your
                  lifestyle. If you had 1 booking,you are eligible for a refund
                  of up to QAR59.
                </p>
              </div>
              <div className="popupFooter">
                <div className="checkboxItem p-4 mb-0">
                  <div className="form-check ">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="optradio"
                      defaultChecked
                    />
                    <label className="form-check-label">
                      I agree with Right Hands'{" "}
                      <Link to="">Privacy Policy , Terms and Conditions</Link>
                    </label>
                  </div>{" "}
                </div>
                <div className="row ">
                  <div className="w-full d-flex px-4 mb-4 ">
                    <button className="btn  me-1 fw-bold  ">Not Now</button>
                    <button className="btn btn-primary  ">Yes, Please!</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="loginModal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">
                Log in or sign up<i className="flag flag-us"></i>
              </h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body">
              <div className="">
                <label>Your Mobile Number</label>
                <div className="inputgroup">
                  <PhoneInput localization={es} />
                </div>

                {/* <div className="inputgroup phonecodeselect ">
                  <span className="select" id="select">
                    <img alt="" src="" className="mgcode" />
                    <span>
                      <img alt="" src="images/flag.png.webp" />
                      +974
                    </span>
                  </span>
                  <ul className="list" id="list">
                    <li id="iq">
                      <img alt="" src="images/flag.png.webp" />
                      +974
                    </li>
                    <li id="eg">
                      <img alt="" src="images/dub.png.webp" />
                      +971
                    </li>

                    <li id="ir">
                      <img alt="" src="images/ind.png.webp" />
                      +91
                    </li>
                    <li id="sy">
                      <img alt="" src="images/usa.png.webp" />
                      +92
                    </li>
                    <li id="qa">
                      <img alt="" src="images/aus.png.webp" />
                      +1
                    </li>
                    <li id="tu">
                      <img alt="" src="images/africa.jpg.webp" />
                      +27
                    </li>
                  </ul>
                  <input
                    type="text"
                    className="form-control "
                    placeholder="Enter Mobile Number"
                  />
                </div> */}
                <button type="submit" className="btn btn-primary w-full">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="verifyModal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">
                Verify phone number
                <i className="flag flag-us"></i>
              </h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body text-center">
              <div className="">
                <label>Enter the code that was sent to</label>
                <h3 className="mb-4">+91 85276 52585</h3>
                <input type="text" className="form-control " />
                <p className="mb-0 py-3">Resend code in: 00:56</p>
                <p className="text-danger">
                  Please check your code and try again
                </p>
                <div className="loader">
                  <button type="submit" className="btn btn-primary w-full">
                    Submit
                  </button>
                  <div className="spinner-border text-dark" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="verifyModal2">
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">
                Select your language
                <i className="flag flag-us"></i>
              </h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body ">
              <label className="w-full language ">
                <p>English</p>
                <input
                  type="radio"
                  value="arabic"
                  name="Language"
                  default={true}
                />
                <i className="fa fa-check"></i>
              </label>
              <label className="w-full language ">
                <p>Arabic</p>
                <input type="radio" value="arabic" name="Language"></input>
                <i className="fa fa-check"></i>
              </label>
              <button type="submit" className="btn btn-primary w-full mt-2">
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal " id="chatModal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">Log in or sign up</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body">
              <form className="">
                <label>Your Mobile Number</label>
                <div className="inputgroup mb-4 ">
                  <div className="flagdiv">
                    <img alt="" src="images/flag.png.webp" />
                    <select>
                      <option>+971</option>
                    </select>
                  </div>
                  <input
                    type="text"
                    className="form-control "
                    placeholder="Enter Mobile Number"
                  />
                </div>
                <div className="col mb-4 ">
                  <input
                    type="text"
                    className="form-control "
                    placeholder="Email"
                  />
                </div>
                <div className="col mb-4 ">
                  <input
                    type="text"
                    className="form-control "
                    placeholder="Your full name"
                  />
                </div>
                <div
                  className="col mb-4 relativeselect"
                  data-bs-toggle="modal"
                  data-bs-target="#issueModal"
                >
                  <select className="form-control ">
                    <option>Please select issue</option>
                  </select>
                  <i className="fa fa-angle-right"></i>
                </div>

                <button type="submit" className="btn btn-primary w-full">
                  Start Chat
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="modal " id="issueModal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">Select Issue</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body">
              <ul className="selectIssue">
                <li>
                  What are the services you offer
                  <i className="fa fa-angle-right"></i>
                </li>
                <li>
                  What are your prices <i className="fa fa-angle-right"></i>
                </li>
                <li>
                  What are your ongoing promotions
                  <i className="fa fa-angle-right"></i>
                </li>
                <li>
                  How to use the referral code
                  <i className="fa fa-angle-right"></i>
                </li>
                <li>
                  Measures and response to COVID-19
                  <i className="fa fa-angle-right"></i>
                </li>
                <li>
                  What is your area of coverage
                  <i className="fa fa-angle-right"></i>
                </li>
                <li>
                  What are your timings <i className="fa fa-angle-right"></i>
                </li>
                <li>
                  How much time is needed to complete the service
                  <i className="fa fa-angle-right"></i>
                </li>
                <li>
                  Other <i className="fa fa-angle-right"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="modal " id="addAddress">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">Your location</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body">
              <div className="toast show">
                <div className="toast-header text-right">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="toast"
                  ></button>
                </div>
                <div className="toast-body">
                  <div className="flex">
                    <div className="checkgrren">
                      <span className="fa fa-check"></span>
                    </div>
                    <div className="toastContent">
                      <strong className="me-auto">Success</strong>
                      <p>Your account has been updated successfully.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mapContent">
                <div className="inputdiv col-lg-12 mb-0 relative ">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search for your building or area"
                  />

                  <i className="fa fa-search"></i>
                </div>
                <div className="inputdiv col-lg-12 mb-0 mapToogleDiv  ">
                  <div className="mapToggle toast show">
                    <button
                      type="button"
                      className="btnclose"
                      data-bs-dismiss="toast"
                    >
                      <span className="fa fa-angle-down"></span>
                    </button>
                    <label>Address Details</label>
                    <input
                      type="text"
                      className="form-control pl-0"
                      placeholder="Search for your building or area"
                    />
                  </div>
                  <button className="btn btn-primary">Confirm</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal " id="editAddress">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">Your location</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body">
              <div className="mapContent">
                <div className="inputdiv col-lg-12 mb-0 relative ">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search for your building or area"
                  />

                  <i className="fa fa-search"></i>
                </div>
                <div className="inputdiv col-lg-12 mb-0  ">
                  <button className="btn btn-primary">Confirm</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal " id="addCard">
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">Add new card</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body">
              <div className="inputdiv col-lg-12 ">
                <label>Card Number</label>
                <input
                  type="nubmer"
                  className="form-control rds-8 h-47"
                  placeholder="0000 0000 0000 "
                />
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="inputdiv col-lg-12 ">
                    <label>Expiry Date</label>
                    <input
                      type="nubmer"
                      max="5"
                      className="form-control rds-8 h-47"
                      placeholder="12/23 "
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="inputdiv col-lg-12 ">
                    <label>Security Code</label>
                    <input
                      type="nubmer"
                      className="form-control rds-8 h-47"
                      placeholder="Security Code "
                    />
                  </div>
                </div>
                <div className="w-full">
                  <button className="btn btn-primary mt-3">Add New Card</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal " id="cancelAppoitment">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">Cancel this booking</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body text-center">
              <div className="row">
                <p>Are you sure you want to cancel this booking?</p>

                <div className="w-full">
                  <Link to="/my-account/CancelReason">
                    <button
                      className="btn btn-primary mt-3 "
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal " id="resechduleModal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <div className="d-flex">
                <span className="fa fa-check"></span>
                <div>
                  <h3>Sucess</h3>
                  <p>Are you sure you want to cancel this booking?</p>
                </div>
              </div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal " id="info">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">How long should I book for?</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body ">
              <p>
                Every house is different, but as a general rule of thumb; each
                bedroom you have will mean an extra hour of cleaning. You can
                refer to the table below:
              </p>
              <table className="table">
                <thead>
                  <tr>
                    <th>Number of Bedrooms</th>
                    <th>Duration of Celaning*</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Studio</td>
                    <td>2 hours</td>
                  </tr>
                  <tr>
                    <td>1 Bedroom</td>
                    <td>2-3 hours</td>
                  </tr>
                  <tr>
                    <td>2 Bedrooms</td>
                    <td>3-4 hours</td>
                  </tr>
                  <tr>
                    <td>3 Bedrooms</td>
                    <td>4-5 hours</td>
                  </tr>
                  <tr>
                    <td>4 Bedrooms</td>
                    <td>5-6 hours</td>
                  </tr>
                  <tr>
                    <td>5 Bedrooms</td>
                    <td>6-7 hours</td>
                  </tr>
                </tbody>
              </table>
              <p>
                *Regular cleaning includes general cleaning, wiping of surfaces,
                dusting, sweeping, mopping, and vacuuming. If you are planning
                to have extra tasks such as cleaning the oven or fridge, wiping
                blinds, or cleaning balcony, we suggest adding an extra 30-45
                min per task.
              </p>
              <p>If you live in a villa, we suggest you book an extra hour.</p>
              <p>
                Below is a checklist for a regular cleaning, but you should talk
                to your professional about what you would like done within your
                home:
              </p>
              <h3>
                <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/kitchen.png.webp" />
                Kitchen - 30 mins
              </h3>
              <ul>
                <li>Wash dishes </li>
                <li>Wipe sink</li>
                <li>countertops Wipe kitchen appliances</li>
              </ul>
              <h3>
                <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/bathroom.png.webp" />
                Bathroom - 30 mins
              </h3>
              <ul>
                <li> Clean bathtub, showers</li>
                <li> Clean toilet, bidet</li>
                <li> Wipe sink,countertops</li>
                <li> Hang/Fold towels</li>
                <li> Clean mirrors</li>
              </ul>
              <h3>
                <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/bedroom.png.webp" />
                Bedroom - 20 mins
              </h3>
              <ul>
                <li>Make beds</li>
                <li> Fold clothes</li>
                <li>Clear mirrors</li>
              </ul>
              <h3>
                <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/general.png.webp" />
                General - 40 mins
              </h3>
              <ul>
                <li>Organize items </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="modal " id="cleaningMaterial">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">What materials should I have?</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body ">
              <p>
                If you prefer us to bring our cleaning materials, our crew
                member will come with a Justlife Cleaning Kit containing the
                following items:
              </p>
              <table className="table">
                <thead>
                  <tr>
                    <th>Cleaning Products</th>
                    <th>Cleaning Equipment</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Floor cleaner</td>
                    <td>Vacuum cleaner</td>
                  </tr>
                  <tr>
                    <td>Toilet cleaner</td>
                    <td>Mop</td>
                  </tr>
                  <tr>
                    <td>Multi-purpose cleaner</td>
                    <td>Bucket & spinner</td>
                  </tr>
                  <tr>
                    <td>Glass cleaner</td>
                    <td>Microfibere cloth</td>
                  </tr>
                  <tr>
                    <td>Cream cleaner</td>
                    <td>Sponge cloth</td>
                  </tr>
                  <tr>
                    <td>Wood furtniture polish</td>
                    <td>Scouring pad</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="modal " id="addVocher">
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">{t("add_voucher_code")}</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body">
              <div className="inputdiv col-lg-12 ">
                <label>{t("voucher_code")}</label>
                <input
                  type="nubmer"
                  className="form-control rds-8 h-47"
                  placeholder="0000 0000 0000"
                />
              </div>
              <div className="row">
                <div className="w-full">
                  <button className="btn btn-primary mt-3">{t("apply")}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal " id="profDetails">
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">Professional Details</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body p-0">
              <div className="successPage">
                <div className="userContainer">
                  <div className="successCard ">
                    <span className="fa fa-check checkicon"></span>
                    <div className="mt-4 mb-4">
                      <h2>Your Booking Successful</h2>
                    </div>
                    <div className="card-content ">
                      <h3>Details</h3>
                      <ul>
                        <li>
                          Reference Code <span>REFNO124588546249</span>
                        </li>
                        <li>
                          Service <span>Home Cleaning</span>
                        </li>
                        <li>
                          Details <span>Bi-weekly</span>
                        </li>
                      </ul>
                    </div>

                    <div className="card-content ">
                      <h3>Date & Time</h3>
                      <ul>
                        <li>
                          Date <span>06/05/2022</span>
                        </li>
                        <li>
                          Start Time <span>12:00Pm-12:30PM</span>
                        </li>
                      </ul>
                    </div>

                    <div className="card-content ">
                      <h3>Address</h3>
                      <ul>
                        <li>
                          Address<span>56,Noida</span>
                        </li>
                      </ul>
                    </div>

                    <div className="card-content ">
                      <h3>Price Details</h3>
                      <ul>
                        <li>
                          Total <strong>QAR 266.00</strong>
                        </li>
                        <li>
                          Paid with<span>Visa</span>
                        </li>
                      </ul>
                    </div>
                    <div className="card-content justify-content-center ">
                      <button className="btn btn-primary">
                        Go to Next Booking
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="modal " id="profDetails">
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">Professional Details</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body p-0">
              <div className="recentTranstion professionalModal">
              <div className="ratingArea">
                <div className="ratingDiv">
                  <p>AVG. RATING</p>
                  <h4><span><i className="fa fa-star"></i>4.8</span></h4>
                </div>
                <div className="ratingDiv">
                  <p>REVIEWS</p>
                  <h4>990</h4>
                </div>
                <div className="ratingDiv">
                  <p>HIRED</p>
                  <h4>1858</h4>
                </div>
              </div>
                <div className="reviewBar">
                  <div className="barArea">
                    <div className="reviewContent">
                      <p>Excellent (112)22</p>
                    </div>
                    <div className="reviewProgress">
                      <div className="barcolor"></div>
                    </div>
                  </div>
                  <div className="barArea">
                    <div className="reviewContent">
                      <p>Good (8)</p>
                    </div>
                    <div className="reviewProgress">
                      <div className="barcolor w30"></div>
                    </div>
                  </div>
                  <div className="barArea">
                    <div className="reviewContent">
                      <p>Average (6)</p>
                    </div>
                    <div className="reviewProgress">
                      <div className="barcolor w20"></div>
                    </div>
                  </div>
                  <div className="barArea">
                    <div className="reviewContent">
                      <p>Bad (3)</p>
                    </div>
                    <div className="reviewProgress">
                      <div className="barcolor w10"></div>
                    </div>
                  </div>
                  <div className="barArea">
                    <div className="reviewContent">
                      <p>Terrible (0)</p>
                    </div>
                    <div className="reviewProgress"></div>
                  </div>
                </div>

                <h3>Reviews</h3>
                <div className="transtionList">
                  <h3>
                    Mark <span>+30. 00 QAR</span>
                  </h3>
                  <p>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                  </p>
                </div>
                <div className="transtionList">
                  <h3>
                    Mark <span>+30. 00 QAR</span>
                  </h3>
                  <p>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                  </p>
                </div>
                <div className="transtionList">
                  <h3>
                    Mark <span>+30. 00 QAR</span>
                  </h3>
                  <p>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                  </p>
                </div>
                <div className="transtionList">
                  <h3>
                    Mark <span>+30. 00 QAR</span>
                  </h3>
                  <p>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                  </p>
                </div>
                <div className="transtionList">
                  <h3>
                    Mark <span>+30. 00 QAR</span>
                  </h3>
                  <p>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                  </p>
                </div>

                <div className="transtionList">
                  <h3>
                    Mark <span>+30. 00 QAR</span>
                  </h3>
                  <p>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="modal " id="selectAddress">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">Select your address</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body p-0">
              <div
                className="selctAddressItem"
                data-bs-toggle="modal"
                data-bs-target="#addAddress"
              >
                <h3>
                  <i className="fa fa-plus"></i>Add new address
                </h3>
              </div>
              <div className="selctAddressItem">
                <div className="checkboxItem ">
                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="radioD"
                      name="optradio2"
                    />
                    <label className="form-check-label" htmlFor="radioD">
                      Dubai
                    </label>
                  </div>
                  <p>16 29A St</p>
                </div>
              </div>
              <div className="selctAddressItem">
                <p>
                  To manage your addresses go to your
                  <NavLink to="/my-account/address">
                    <span className="btn-close" data-bs-dismiss="modal">
                      Settings My Addresses page
                    </span>
                  </NavLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal " id="covidModal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">COVID-19 Self Health Declaration </h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body p-0 ">
              <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/covid.jpg.webp" className="w-full "/>
              <div className="p-4">
                <p>
                  A safe environment is our shared responsibility. Your response
                  is going to help us protect the health of all the stakeholders
                  in our ecosystem.
                </p>
                <ul>
                  <li>
                    I do not have any flu-like symptoms such as fever, cough,
                    sore throat, etc.
                  </li>
                  <li>
                    I have not travelled internationally in the last 14 days.
                  </li>
                  <li>
                    I have not contacted anyone with COVID-19 in the last 14
                    days.
                  </li>
                </ul>

                <div className="form-check mt-4">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="radiot"
                    name="optradio"
                  />
                  <label className="form-check-label" htmlFor="radiot">
                    I confirm that I am healthy
                  </label>
                </div>
              </div>
              <div className="p-4 border-top mt-2">
                <button className="btn btn-primary">Next</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal " id="ServicePopup">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body p-0 ">
              <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/hair.jpg.webp" className="w-full"/>
              <div className="p-4">
                <h3>
                  Haircut
                  <div>
                    <p>
                      QAR 89 <span>QAR 100</span>
                    </p>
                  </div>
                </h3>
                <p>
                  Get a neat and slick cut from the comfort of your home in 30
                  minutes.
                </p>
                <p>
                  <b>Duration:</b> 30 mins
                </p>
                <h4>What is included:</h4>
                <ul>
                  <li>Sterilized & sealed tool kit</li>
                  <li>Disposable items</li>
                  <li>Plastic floor sheet cover</li>
                </ul>
                <p>
                  Note: Standard Men's salon does not include barber's chair and
                  mirror. Kindly ensure that seating for the service is
                  available at home.
                </p>
                <div className="button-with-counter">
                  <i className="fa fa-minus"></i>
                  <span>1</span>
                  <i className="fa fa-plus"></i>
                </div>
              </div>
              <div className="p-4  mt-2">
                <button className="btn btn-primary">Update</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal " id="bookingSummary">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">Booking Summary</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body p-0 ">
              <div className="card ">
                <div className="card-content ">
                  <p>City</p>
                  <p>Duhail</p>
                </div>
                <div className="card-content ">
                  <p>Service Details</p>
                  <ul>
                    <li>
                      Frequency <span>Bi-weekly</span>
                    </li>
                    <li>
                      Duration <span>2 hours</span>
                    </li>
                    <li>
                      Number of Professionals <span>1</span>
                    </li>
                    <li>
                      Material <span>No</span>
                    </li>
                  </ul>
                </div>
                <div className="card-content ">
                  <p>Date & Time</p>
                </div>
                <div className="card-content ">
                  <p>Address </p>
                </div>
                <div className="card-content ">
                  <p>Price Details</p>
                  <ul>
                    <li>
                      Price <span>QAR 79.05</span>
                    </li>
                    <li>
                      VAT <span>QAR 3.95</span>
                    </li>
                    <li>
                      Discount <span>-QAR 4.15</span>
                    </li>
                    <li>
                      Total <span className="total">QAR 78.85</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
