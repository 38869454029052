import React, {useState} from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker
} from "react-google-maps";
import Autocomplete from "react-google-autocomplete";
import { fromLatLng } from "react-geocode";
import {useEffect} from "react";

const LocationSearch = ({
  defaultLat,
  defaultLng,
  onLocationChange,
  setIsToggle,
  setShortName,
  setAddressObject,
  addressObject
}) => {
  const [center, setCenter] = useState({
    lat: defaultLat || localStorage.getItem("currentLatitude"),
    lng: defaultLng || localStorage.getItem("currentLongitude")
  });
  const [marker, setMarker] = useState(null);
  const [address, setAddress] = useState("");

  // Current Location Handler
  const getCurrentLocation = (defaultLat, defaultLng) => {
    fromLatLng(defaultLat, defaultLng).then(
      (response) => {
        let country_short_name;
        const address = response.results[0].formatted_address;
        setCenter({lat: parseFloat(defaultLat), lng: parseFloat(defaultLng)});
        setMarker({lat: parseFloat(defaultLat), lng: parseFloat(defaultLng)});
        const addressArray = response.results[0].address_components;
        const countryComponent = addressArray.find((component) =>
          component.types.includes("country")
        );
        let newShortName = countryComponent.short_name;
        setShortName(countryComponent.short_name);
        setAddress(address);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  // console.log("localStorage.getItem", defaultLat, defaultLng);
  const handleMarkerDragEnd = (event) => {
    const {latLng} = event;
    const lat = latLng.lat();
    const lng = latLng.lng();

    setCenter({lat, lng});
    setMarker({lat, lng});
    setAddressObject({
      ...addressObject,
      latitude: lat,
      longitude: lng
    });

   fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        const addressArray = response.results[0].address_components;
        const countryComponent = addressArray.find((component) =>
          component.types.includes("country")
        );
        let newShortName = countryComponent.short_name;
        setShortName(countryComponent.short_name);
        setAddressObject({
          ...addressObject,
          address: address
        });
        setIsToggle(true);
        // setAddress(address);
        onLocationChange({address, lat, lng, newShortName});
      },
      (error) => {
        console.error(error);
      }
    );
  };

  useEffect(() => {
    getCurrentLocation(defaultLat, defaultLng);
  }, [defaultLat, defaultLng]);

  const handlePlaceSelect = (place) => {
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    setCenter({lat, lng});
    setMarker({lat, lng});
    setAddressObject({
      ...addressObject,
      latitude: lat,
      longitude: lng
    });
   fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        const addressArray = response.results[0].address_components;
        const countryComponent = addressArray.find((component) =>
          component.types.includes("country")
        );

        let newShortName = countryComponent.short_name;
        setShortName(countryComponent.short_name);
        setAddressObject({
          ...addressObject,
          address: address
        });
        onLocationChange({address, lat, lng, newShortName});
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const AsyncMap = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        defaultZoom={15}
        center={{lat: center.lat, lng: center.lng}}
        onClick={(event) => handleMarkerDragEnd(event)}
      >
        {marker && (
          <Marker
            google={props.google}
            position={{lat: marker.lat, lng: marker.lng}}
            draggable={true}
            onDragEnd={(event) => handleMarkerDragEnd(event)}
          />
        )}

        <div className="searchPlace">
          <Autocomplete
            style={{
              width: "100%",
              height: "40px",
              paddingLeft: "16px",
              marginTop: "2px",
              marginBottom: "2rem"
            }}
            value={addressObject?.address}
            onPlaceSelected={handlePlaceSelect}
            types={["(regions)"]}
          />
          <i className="fa fa-search"></i>
        </div>
      </GoogleMap>
    ))
  );

  return (
    <div style={{height: "400px", width: "100%"}}>
      <AsyncMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`}
        loadingElement={<div style={{height: "100%"}} />}
        containerElement={<div style={{height: "100%"}} />}
        mapElement={<div style={{height: "100%"}} />}
      />
    </div>
  );
};

export default LocationSearch;
