export const cleanerTimeAvailabilityReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_CLEANER_TIME_AVAILABILITY_REQUEST':
            return {
                available_time: [],
                loading: true,
            }
        case'GET_CLEANER_TIME_AVAILABILITY_SUCCESS':
            return {
                available_time: action.payload.available_time,
                selectedCleaner: action.payload.selectedCleaner,
                loading: false,
                success: true,
                status: action.payload.status
            }
        case'GET_CLEANER_TIME_AVAILABILITY_FAILED':
        
            return {
                ...state,
                loading: false,
                error: true
            }
        default:
            return state
    }
}