import React from 'react'
import Footer from './Footer'
import Header from './Header'
import Modal from './Modal'

const Popups = () => {
  return (
   <>
   <Header></Header>
   <div className='container'>
   <div className='py-7 my-5'>
   <button data-bs-toggle="modal" data-bs-target="#trackPro" className='me-2'>track-pro</button>
   <button data-bs-toggle="modal" data-bs-target="#cancelAppoitment" className='me-2'>Start from where you left popup</button>
   <button data-bs-toggle="modal" data-bs-target="#customTip" className='me-2'>customTip</button>
   <button data-bs-toggle="modal" data-bs-target="#share" className='me-2'>Share</button>
   <button data-bs-toggle="modal" data-bs-target="#favPro" className='me-2'>favPro</button>
   <button data-bs-toggle="modal" data-bs-target="#claimAccount" className='me-2'>Claim Your Account</button>
   <button data-bs-toggle="modal" data-bs-target="#claim-account-popup" className='me-2'>claim-account-popup</button>
   <button data-bs-toggle="modal" data-bs-target="#righthandsClub" className='me-2 mb-2'>righthands-club</button>
   <button data-bs-toggle="modal" data-bs-target="#takeTourOne" className='me-2'>TakeTourOne</button>
   <button data-bs-toggle="modal" data-bs-target="#addPhoto" className='me-2'>Add Photo</button>
   <button data-bs-toggle="modal" data-bs-target="#moreService" className='me-2'>More services</button>
   <button data-bs-toggle="modal" data-bs-target="#finishBooking" className='me-2'>Finish My Booking</button>
   </div></div>
   <Modal/>
   <Footer></Footer>
   </>
  )
}

export default Popups