import React, { useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Modal from "../../components/Modal";
import Loader from "../../components/Loader";
import {useDispatch, useSelector} from "react-redux";
import { getFreeCreditAction } from "../../actions/FreeCreditAction";
import { useTranslation } from 'react-i18next';
import {NavLink, Link } from "react-router-dom";
import { customerDetailAction } from "../../actions/homeAction";
import CreditPackages from "./CreditPackages";

const RighthandCredit = () => {
  const getFreeCrDataState = useSelector(state => state.getFreeCreditReducer)
  const { loading, free_cr_data } = getFreeCrDataState;
  const dispatch = useDispatch();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let listUrl = baseUrl + "/freeCredit/rh/list";
  useEffect(() => {
      dispatch(getFreeCreditAction(listUrl,{}));
  }, [listUrl]);
  const { t } = useTranslation();
  var options = { year: 'numeric', month: 'long', day: 'numeric' };
  var latestExpiryDate = new Date(free_cr_data?.latestExpiryCredit?.expiry_at).toLocaleDateString([],options);

  const getCustomerDetailState = useSelector((state) => state.customerReducer);
  const {  customerMembership } = getCustomerDetailState;
  let customerMembershipUrl = baseUrl+"/customer/get/profile";
  useEffect(() => {
    dispatch(customerDetailAction(customerMembershipUrl));
  }, [customerMembershipUrl,dispatch]);

  return (
    <>
      <Header/>
      {loading ? <Loader /> : ""}
      <div className="py-5">
        <div className="userContainer">
          <div className="rightHandCredit">
            <div className="heading">
              <h2>{t('rh_credits')}</h2> 
            </div>
            <div className="card">
              <div className="totalCredit">
                <h3>{t('available_credits')}</h3> 
                {/* <h2>{customerMembership?.currency} {free_cr_data?.totalCreditsRemaining}</h2> */}
                <h2>{!customerMembership || customerMembership?.isCanceled ? "0" : customerMembership?.currency+" "+customerMembership?.remaining_credit}</h2>
                <Link to="/my-account/creditdetails">
                  <h6> 
                    {t('see_details1')} <span className="fa fa-angle-right"></span>
                  </h6>
                </Link>
              </div>
              <CreditPackages/>
              { (free_cr_data?.latestExpiryCredit?.credit) ?
              <div className="creditExpiry">
                <h3>
                  <span> 
                    <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/info.png.webp" />
                  </span>
                  {customerMembership?.currency} {free_cr_data?.latestExpiryCredit?.credit} {t('credit_is_expiring')}
                </h3>
                <p>{t('expires_on')} {latestExpiryDate}</p>
              </div> : ""
              }

              <div className="recentTranstion"> 
              <h3 className="mt-4">
                {t('recent_transtions')}
              </h3>
              {free_cr_data?.list?.length > 0 ?
                free_cr_data?.list.map((free_cr, index) => {
                  if(free_cr && free_cr.details && free_cr.type){
                     var options = { year: 'numeric', month: 'long', day: 'numeric' };
                     var created_at = new Date(free_cr.createdAt).toLocaleDateString([],options);
                    return (
                      <div key={index}>
                        {index < 4 &&
                        <div className="transtionList">
                          <h3>
                            {free_cr?.details} <span>{free_cr.type === 'Credited' || free_cr.type === 'Refunded' ? '+ '+free_cr.credit : '- '+free_cr.debit} {customerMembership?.currency}</span>
                          </h3>
                          <p>{created_at}</p>
                        </div>
                        }
                      </div>
                    )
                  }
              }): <center>{t('no_recent_transactions')}</center>}   
             
                {free_cr_data?.list?.length > 0 ?            
                <div className="transtionList">
                  <p>            
                    <NavLink to={"/my-account/see/all/history/"} state={{free_cr_data: free_cr_data}} className="dropdown-item">
                      {t('see_all_history')}<i className='fa fa-arrow-right px-2'></i>
                    </NavLink>
                  </p>
                </div>
                : ""}   

              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal />
      <Footer />
    </>
  );
};

export default RighthandCredit;
