import {Link} from "react-router-dom";
import React, {useState} from "react";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {customerDetailAction} from "../../../../actions/homeAction";
import {BuyOnline} from "../../../../helpers/bookingAPIsteps";
import Modal from "react-bootstrap/Modal";
import CardsComponent from "../../../../helpers/CardsComponent";
import {hasStripePayment} from "../../../../helpers/helpers";
const CashCreditComponent = ({
  checkoutData,
  setCheckoutData,
  // updateCheckoutData,
  // walletAmount,
  rhCreditDetails,
  isCashData,
  setCheckedValue,
  setIsCashPrice,
  // handelNewCardModel,
  selectedPayment,
  setSelectedPayment,
  isBuyMemberShipChecked,
  handelBuyMembership,
  currentUser,
  showBuyButton,
  setIsBuyMemberShipChecked
}) => {
  const {t} = useTranslation();

  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  const dispatch = useDispatch();
  const [useCard, setUseCard] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [tokenId, setTokenId] = useState();
  const [visibleCards, setVisibleCards] = useState(4);
  // const [modalVisible, setModalVisible] = useState(false);
  const getCustomerDetailState = useSelector((state) => state.customerReducer);
  const {customerMembership} = getCustomerDetailState;
  const getRHClubState = useSelector((state) => state.rhClubReducer);
  const {rh_club} = getRHClubState;
  const getAllCountryState = useSelector((state) => state.countryReducer);
  const {country_list} = getAllCountryState;
  let customerMembershipUrl = baseUrl + "/customer/get/profile";
  useEffect(() => {
    dispatch(customerDetailAction(customerMembershipUrl));
  }, [customerMembershipUrl, dispatch]);
  const getCustomerAllCreditCardState = useSelector(
    (state) => state.customerDetailsReducer
  );
  const {customerCreditCards, stripCustomerCreditCards} =
    getCustomerAllCreditCardState;
  // console.log("customerCreditCards", customerCreditCards, stripCustomerCreditCards)
  useEffect(() => {
    const isStripCards = hasStripePayment(
      checkoutData?.country_id,
      country_list
    );
    if (isStripCards === true) {
      setUseCard(stripCustomerCreditCards);
    } else {
      setUseCard(customerCreditCards);
    }
  }, [customerCreditCards, stripCustomerCreditCards]);
  useEffect(() => {
    // console.log("useCard", useCard);
    if (useCard?.length > 0) {
      setIsLoading(true);
      if (
        rhCreditDetails?.total_wallet_credits === 0 &&
        rhCreditDetails?.isRhValid !== true
      ) {
        setTokenId(useCard[0]?.token);
        setSelectedPayment("credit");
      }
      // setTokenId(customerCreditCards[0]?.token);
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [useCard]);

  useEffect(() => {
    if (selectedPayment === "credit") {
      setCheckoutData({
        ...checkoutData,
        CodCharges: 0,
        payment_mode: "Card",
        pay_type: "online",
        tokenId: tokenId ? tokenId : ""
      });
      setCheckedValue("card");
      setIsCashPrice(0);
    } else if (selectedPayment === "cash") {
      setIsBuyMemberShipChecked(false);
      setTokenId("");
      setCheckoutData({
        ...checkoutData,
        CodCharges: checkoutData.ActualCodCharges,
        payment_mode: "cash",
        pay_type: "cod"
      });
      setIsCashPrice(checkoutData.ActualCodCharges);
      setCheckedValue("cash");
    } else if (selectedPayment === "freeCredit") {
      setIsBuyMemberShipChecked(false);
      setCheckoutData({
        ...checkoutData,
        CodCharges: 0,
        payment_mode: "online",
        pay_type: ""
      });
      setCheckedValue("");
      setIsCashPrice(0);
      setTokenId("");
    } else if (selectedPayment === "") {
      setIsBuyMemberShipChecked(false);
      setCheckoutData({
        ...checkoutData,
        CodCharges: 0,
        payment_mode: "",
        pay_type: ""
      });
      setCheckedValue("");
      setIsCashPrice(0);
      setTokenId("");
    }
  }, [selectedPayment]);

  const handleSeeMore = () => {
    // Show all cards when "See More" is clicked
    setVisibleCards(useCard.length);
  };

  const letterChange = (event) => {
    setTokenId("");
    setSelectedPayment(event.target.value);
  };
  const handelCardChange = (event, id) => {
    setSelectedPayment(event.target.value);
    setTokenId(id);
    setCheckoutData({
      ...checkoutData,
      payment_mode: "Card",
      pay_type: "online",
      tokenId: tokenId ? tokenId : ""
    });
    setCheckedValue("card");
  };

  //   const openPaymentTab = (payUrl) => {
  //   // Show overlay to disable user interaction
  //   // setOverlayVisible(true);

  //   // Open a new tab with the payment URL
  //   window.location.href = payUrl;
  //   // window.open(payUrl);
  // };

  const handelOnlinePay = async (event) => {
    setTokenId("");
    setCheckoutData({
      ...checkoutData,
      payment_mode: "Card",
      pay_type: "online",
      tokenId: ""
    });
    setCheckedValue("card");
    setSelectedPayment("credit");
    // setSelectedPayment(event.target.value);
  };

  return (
    <>
      <div className="payment-container">
        {/* {overlayVisible && <div className="overlay"></div>} */}
        <div className="checkboxItem payBYcredit">
          {/* <h3 className="mb-4">{t("pay_by_cc")}</h3> */}
          
          <div className="form-check">
            <input
              type="radio"
              className="form-check-input PaymentByCash"
              value="onlinePay"
              disabled={
                (checkoutData?.isWallet && checkoutData?.totalPrice === 0) ||
                (checkoutData?.payment_mode == "online" &&
                  checkoutData?.totalPrice - checkoutData?.rh_discount === 0 )
              }
              name="radioButton"
              onChange={(event) => handelOnlinePay(event)}
              checked={selectedPayment === "credit" && tokenId === ""}
            />
            <div>
              <h3 className="form-check-label line-height-0">
                {t("pay_by_cc")}
              </h3>
              <a
                data-bs-toggle="collapse"
                className="text-decoration-underline "
                style={{fontSize: "14px"}}
                href="#learnMore"
                role="button"
                aria-expanded="false"
                aria-controls="learnMore"
              >
                Learn more
              </a>
            </div>
          </div>
          <div className="collapse border p-2 rounded mb-2" id="learnMore">
            <p className="fw-medium">
              "USD 1 or equivalent in other currencies will be charged to verify
              your card. The amount will be refunded immediately."
            </p>
          </div>
          {useCard?.length > 0 && (
            <h3 className="mb-4">{t("use_another_card")}</h3>
          )}
          {useCard?.length > 0 ? (
            useCard.slice(0, visibleCards).map((customerCreditCard, index) => (
              <div key={index}>
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input useCardPayment PaymentByCard"
                    id={"PaymentByCard_" + index}
                    value="credit"
                    onChange={(event) =>
                      handelCardChange(event, customerCreditCard?.token)
                    }
                    disabled={
                      (checkoutData?.isWallet && checkoutData?.totalPrice === 0) ||
                      (checkoutData?.payment_mode == "online" &&
                        checkoutData?.totalPrice - checkoutData?.rh_discount === 0 )
                    }
                    checked={customerCreditCard?.token === tokenId}
                  />
                  <div className="ms-4">
                    <label className="form-check-label" htmlFor={index}>
                      ...{customerCreditCard?.card?.cardNumber}
                    </label>
                    <p>{customerCreditCard?.card?.cardExpiry}</p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>{t("no_Card")}</p>
          )}

          {useCard?.length > 4 && visibleCards < useCard?.length && (
            <p onClick={handleSeeMore}>...See More</p>
          )}
          {/* {customerCreditCards?.length > 0
            ? customerCreditCards.map((customerCreditCard, index) => {
                return (
                  <div key={index}>
                    <div className="form-check">
                      <input
                        type="radio"
                        className="form-check-input useCardPayment PaymentByCard"
                        id={"PaymentByCard_" + index}
                        value="credit"
                        onChange={(event) =>
                          handelCardChange(event, customerCreditCard?.token)
                        }
                        disabled={
                          checkoutData?.isWallet &&
                          checkoutData?.totalPrice === 0
                        }
                        checked={customerCreditCard?.token === tokenId}
                      />
                      <div className="ms-4">
                        <label className="form-check-label" htmlFor={index}>
                          ...{customerCreditCard?.card?.cardNumber}
                        </label>
                        <p>{customerCreditCard?.card?.cardExpiry}</p>
                      </div>
                    </div>
                  </div>
                );
              })
            : 
              t("no_Card")} */}
          {/* {!customerCreditCards && (
            <div className="cardsec">
              <h3 onClick={() => handelNewCardModel()}>
                {t("add_new_card")}
                <img
                  src="https://dvdljkkxpxqo3.cloudfront.net/images/plus2.png.webp"
                  alt=""
                />
              </h3>
            </div>
          )} */}
        </div>

        {isCashData?.isCash && (
          <div className="checkboxItem payBYcash">
            <h3 className="mb-4">{t("pay_by_cash")}</h3>
            <div className="form-check">
              <input
                type="radio"
                className="form-check-input PaymentByCash"
                value="cash"
                disabled={
                  (checkoutData?.isWallet && checkoutData?.totalPrice === 0) ||
                  (checkoutData?.payment_mode == "online" &&
                    checkoutData?.totalPrice - checkoutData?.rh_discount === 0 )
                }
                name="radioButton"
                onChange={(event) => letterChange(event)}
                checked={selectedPayment === "cash"}
              />
              <div>
                <label className="form-check-label line-height-0">
                  {t("pay_by_cash")}{" "}
                  <span>
                    ({t("Pay_extra_amount")} {isCashData?.currency}{" "}
                    {checkoutData?.ActualCodCharges})
                  </span>
                </label>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* <Modal
        show={modalVisible}
        onHide={() => setModalVisible(false)}
        id="PayOnline"
        size="lg"
        // fullscreen={fullscreen}
         style={{overflow:'auto'}}
      >

        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {t("Pay")}
            <i className="flag flag-us" ></i>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <iframe title="Payment" src={payUrl}  height="600px" width="1000px"/>
      
        </Modal.Body>
      </Modal> */}
    </>
  );
};

export default CashCreditComponent;
