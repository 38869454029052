import React from "react";
import { useTranslation } from 'react-i18next';

export const Steps = ({
  checkoutData
}) => {
  const { t } = useTranslation();
  let serviceName = localStorage.getItem("serviceName");
  return (
    <>
      <div className="step-wizard ">
      <div className={"item-container is-flex "+((checkoutData.page >= 1) ? "is-active " : "")+((checkoutData.page > 1) ? "isDone " : "")}>
          <div className="item">
            <div className="marker tag">
              <p className="helper-text-item ">1</p>
            </div>
            <div className="details">
              <p className=" step-title">{t('address')}</p>
            </div>
          </div>
          <div className="divider"></div>
        </div>
        <div className={"item-container is-flex "+((checkoutData.page >= 2) ? "is-active " : "")+((checkoutData.page > 2) ? "isDone " : "")}>
          <div className="item">
            <div className="marker tag">
              <p className="helper-text-item ">2</p>
            </div>
            <div className="details">
              <p className=" step-title">{ t('frequency') }</p>
            </div>
          </div>
          <div className="divider"></div>
        </div>
        <div className={"item-container is-flex "+((checkoutData.page >= 3) ? "is-active " : "")+((checkoutData.page > 3) ? "isDone " : "")}>
          <div className="item">
            <div className="marker tag">
              <p className="helper-text-item">3</p>
            </div>
            <div className="details">
              <p className=" step-title ">{serviceName === 'Baby Sitting & Caregivers' ?t('service_details_non_cleaning'):t('service_details') }</p>
            </div>
          </div>
          <div className="divider"></div>
        </div>
        <div className={"item-container is-flex "+((checkoutData.page >= 4) ? "is-active " : "")+((checkoutData.page > 4) ? "isDone " : "")}>
          <div className="item">
            <div className="marker tag">
              <p className="helper-text-item ">4</p>
            </div>
            <div className="details">
              <p className=" step-title">{ t('date_and_time') }</p>
            </div>
          </div>
          <div className="divider"></div>
        </div>
        <div className={"item-container is-flex "+((checkoutData.page >= 5) ? "is-active " : "")+((checkoutData.page > 5) ? "isDone " : "")}>
          <div className="item">
            <div className="marker tag ">
              <p className="helper-text-item ">5</p>
            </div>
            <div className="details">
              <p className="step-title">{ t('payment') }</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Steps;
