import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
const StepsPayment = ({
  checkoutData,
  setCheckoutData,
  updateCheckoutData,
  walletAmount,
  rhCreditDetails,
  freeCredit,
  totalRemainingSubscriptionCredits,
  setFreeCredit,
  setWallet,
  checkedValue,
  setWalletStatus,
  setTransaction,
  setCheckedValue,
  selectedPayment,
  setSelectedPayment
}) => {
  const [isCreditSelected, setIsCreditSelected] = useState(false);
  const [isWalletSelected, setWalletSelected] = useState(false);
  const [hasNoCredits, setHasNoCredits] = useState(false);
  const initialWalletAmount = rhCreditDetails?.total_wallet_credits;
  const [initialTotalPrice, setInitialTotalPrice] = useState();
  const {t} = useTranslation();

  useEffect(() => {
    setInitialTotalPrice(checkoutData?.totalPrice);
    if (initialWalletAmount === 0) {
      setCheckoutData((prev) => ({
        ...prev,
        isWallet: false
      }));
    }
    if (
      initialWalletAmount > 0 &&
      initialWalletAmount !== undefined &&
      rhCreditDetails?.isRhValid === true
    ) {
      let walletAmount = initialWalletAmount;
      let totalAmount =
        checkoutData?.totalPrice +
        (checkoutData?.CodCharges > 0 ? checkoutData?.CodCharges : 0) +
        (checkoutData?.holidayPrice > 0 ? checkoutData?.holidayPrice : 0) -
        (isCreditSelected ? 150 : 0) +
        (checkoutData?.rushSlot > 0 ? checkoutData?.rushSlot : 0);
      if (totalAmount < 150) {
        setCheckoutData((prev) => ({
          ...prev,
          discount_type: "FreeCredit",
          rh_discount: totalAmount,
          payment_mode:
            checkedValue === "cash"
              ? "cash"
              : checkedValue === "card"
              ? "Card"
              : "online",
          pay_type: checkedValue === "cash" ? "cod" : "online",
          rhClub: true,
          totalPrice: 0
        }));
        totalRemainingSubscriptionCredits =
          totalRemainingSubscriptionCredits - totalAmount;
        setFreeCredit(totalRemainingSubscriptionCredits);
        setIsCreditSelected(true);
      } else if (rhCreditDetails?.isRhValid === true && totalAmount > 150) {
        setCheckoutData((prev) => ({
          ...prev,
          discount_type: "FreeCredit",
          rh_discount: 150,
          payment_mode:
            checkedValue === "cash"
              ? "cash"
              : checkedValue === "card"
              ? "Card"
              : "online",
          pay_type: checkedValue === "cash" ? "cod" : "online",
          rhClub: true
        }));
        totalRemainingSubscriptionCredits =
          totalRemainingSubscriptionCredits - 150;
        setFreeCredit(totalRemainingSubscriptionCredits);
        setIsCreditSelected(true);
        if (walletAmount > totalAmount) {
          let deducted_amount = walletAmount - totalAmount;
          setTransaction("");
          setSelectedPayment("");
          setCheckoutData((prev) => ({
            ...prev,
            payment_mode: "online",
            pay_type: "online",
            isWallet: true,
            deducted_amount: deducted_amount,
            wallet_amount: totalAmount,
            CodCharges: 0,
            showContinue: true,
            totalPrice: 0
          }));

          if (checkedValue === "cash") {
            setCheckedValue("");
          }
          setWallet(deducted_amount);
        } else {
          let deducted_amount = totalAmount - walletAmount;
          setCheckoutData((prev) => ({
            ...prev,
            payment_mode:
              checkedValue === "cash"
                ? "cash"
                : checkedValue === "card"
                ? "Card"
                : "",
            pay_type: "online",
            isWallet: true,
            wallet_amount: walletAmount,
            deducted_amount: walletAmount
            // totalPrice:initialTotalPrice
          }));
          setWallet(0);
        }
        setWalletSelected(true);
      }
    } else if (initialWalletAmount > 0 && initialWalletAmount !== undefined) {
      let walletAmount = initialWalletAmount;
      let totalAmount =
        checkoutData?.totalPrice +
        (checkoutData?.CodCharges > 0 ? checkoutData?.CodCharges : 0) +
        (checkoutData?.holidayPrice > 0 ? checkoutData?.holidayPrice : 0) -
        (isCreditSelected ? 150 : 0) +
        (checkoutData?.rushSlot > 0 ? checkoutData?.rushSlot : 0);
      if (rhCreditDetails?.isRhValid === true && totalAmount > 150) {
        setCheckoutData((prev) => ({
          ...prev,
          discount_type: "FreeCredit",
          rh_discount: 150,
          payment_mode:
            checkedValue === "cash"
              ? "cash"
              : checkedValue === "card"
              ? "Card"
              : "online",
          pay_type: checkedValue === "cash" ? "cod" : "online",
          rhClub: true
        }));
        totalRemainingSubscriptionCredits =
          totalRemainingSubscriptionCredits - 150;
        setFreeCredit(totalRemainingSubscriptionCredits);
        setIsCreditSelected(true);
      } else  if (rhCreditDetails?.isRhValid === true && totalAmount < 150) {
        setCheckoutData((prev) => ({
          ...prev,
          discount_type: "FreeCredit",
          rh_discount: totalAmount,
          payment_mode:
            checkedValue === "cash"
              ? "cash"
              : checkedValue === "card"
              ? "Card"
              : "online",
          pay_type: checkedValue === "cash" ? "cod" : "online",
          rhClub: true
        }));
        totalRemainingSubscriptionCredits =
          totalRemainingSubscriptionCredits - totalAmount;
        setFreeCredit(totalRemainingSubscriptionCredits);
        setIsCreditSelected(true);
      }


      if (walletAmount > totalAmount) {
        let deducted_amount = walletAmount - totalAmount;
        setTransaction("");
        setSelectedPayment("");
        setCheckoutData((prev) => ({
          ...prev,
          payment_mode: "online",
          pay_type: "online",
          isWallet: true,
          deducted_amount: deducted_amount,
          wallet_amount: totalAmount,
          CodCharges: 0,
          showContinue: true,
          totalPrice: 0
        }));

        if (checkedValue === "cash") {
          setCheckedValue("");
        }
        setWallet(deducted_amount);
      } else {
        let deducted_amount = totalAmount - walletAmount;

        setCheckoutData((prev) => ({
          ...prev,
          payment_mode:
            checkedValue === "cash"
              ? "cash"
              : checkedValue === "card"
              ? "Card"
              : "",
          pay_type: "online",
          isWallet: true,
          wallet_amount: walletAmount,
          deducted_amount: walletAmount
        }));
        setWallet(0);
      }
      setWalletSelected(true);
    } else if (rhCreditDetails?.isRhValid === true) {
      let totalAmount =
        checkoutData?.totalPrice +
        (checkoutData?.CodCharges > 0 ? checkoutData?.CodCharges : 0) +
        (checkoutData?.holidayPrice > 0 ? checkoutData?.holidayPrice : 0) -
        (isCreditSelected ? 150 : 0) +
        (checkoutData?.rushSlot > 0 ? checkoutData?.rushSlot : 0);
      if (rhCreditDetails?.isRhValid === true && totalAmount > 150) {
        setCheckoutData((prev) => ({
          ...prev,
          discount_type: "FreeCredit",
          rh_discount: 150,
          payment_mode:
            checkedValue === "cash"
              ? "cash"
              : checkedValue === "card"
              ? "Card"
              : "online",
          pay_type: checkedValue === "cash" ? "cod" : "online",
          rhClub: true
        }));
        totalRemainingSubscriptionCredits =
          totalRemainingSubscriptionCredits - 150;
        setFreeCredit(totalRemainingSubscriptionCredits);
        setIsCreditSelected(true);
      } else {
        setCheckoutData((prev) => ({
          ...prev,
          discount_type: "FreeCredit",
          rh_discount: totalAmount,
          payment_mode:
            checkedValue === "cash"
              ? "cash"
              : checkedValue === "card"
              ? "Card"
              : "online",
          pay_type: checkedValue === "cash" ? "cod" : "online",
          rhClub: true
        }));
        totalRemainingSubscriptionCredits =
          totalRemainingSubscriptionCredits - totalAmount;
        setFreeCredit(totalRemainingSubscriptionCredits);
        setIsCreditSelected(true);
      }
    }
  }, [initialWalletAmount]);

  const handleCreditChange = (mode) => {
    
    if (mode === "PaymentByWallet" && isWalletSelected) {
      setCheckoutData((prev) => ({
        ...prev,
        payment_mode:
          checkedValue === "cash"
            ? "cash"
            : checkedValue === "card"
            ? "Card"
            : "",
        pay_type:
          checkedValue === "cash" ? "cod" : checkedValue === "" ? "" : "online",
        isWallet: false,
        deducted_amount: 0,
        wallet_amount: 0,
        totalPrice: initialTotalPrice
      }));
      setWallet(initialWalletAmount);
      setWalletSelected(false);
    } else if (mode === "PaymentByWallet" && !isWalletSelected) {
      let totalAmount =
        checkoutData?.totalPrice +
        (checkoutData?.holidayPrice > 0 ? checkoutData?.holidayPrice : 0) -
        (isCreditSelected ? 150 : 0) +
        (checkoutData?.rushSlot > 0 ? checkoutData?.rushSlot : 0);

      if (walletAmount > totalAmount) {
        let deducted_amount = walletAmount - totalAmount;
        setTransaction("");
        setSelectedPayment("");
        setCheckoutData((prev) => ({
          ...prev,
          payment_mode: "online",
          pay_type: "online",
          isWallet: true,
          deducted_amount: deducted_amount,
          wallet_amount: totalAmount,
          CodCharges: 0,
          showContinue: true,
          totalPrice: 0
        }));

        if (checkedValue === "cash") {
          setCheckedValue("");
        }
        setWallet(deducted_amount);
      } else {
        let deducted_amount = totalAmount - walletAmount;
        setCheckoutData((prev) => ({
          ...prev,
          payment_mode:
            checkedValue === "cash"
              ? "cash"
              : checkedValue === "card"
              ? "Card"
              : "",
          pay_type: "online",
          isWallet: initialWalletAmount > 0 ? true : false,
          wallet_amount: walletAmount,
          deducted_amount: walletAmount,
          totalPrice: initialTotalPrice
        }));
        setWallet(0);
      }
      setWalletSelected(true);
    } else if (mode === "PaymentByCredit" && isCreditSelected) {
      setCheckoutData((prev) => ({
        ...prev,
        discount_type: "",
        rh_discount: 0,
        payment_mode: isWalletSelected
          ? "online"
          : checkedValue === "cash"
          ? "cash"
          : checkedValue === "card"
          ? "Card"
          : "",
        pay_type: "online",
        rhClub: false,
        totalPrice:
          checkoutData?.frequency_name === "Weekly"
            ? checkoutData?.total_amount
            : checkoutData.total_per_display_amount
      }));
      setFreeCredit(totalRemainingSubscriptionCredits);
      setIsCreditSelected(false);
    } else if (mode === "PaymentByCredit" && !isCreditSelected) {
      let totalAmount =
        checkoutData?.totalPrice +
        (checkoutData?.holidayPrice > 0 ? checkoutData?.holidayPrice : 0) -
        (isWalletSelected ? checkoutData?.deducted_amount : 0) +
        (checkoutData?.rushSlot > 0 ? checkoutData?.rushSlot : 0);
      if (rhCreditDetails?.isRhValid === true && totalAmount > 150) {
        setCheckoutData((prev) => ({
          ...prev,
          discount_type: "FreeCredit",
          rh_discount: 150,
          payment_mode:
            checkedValue === "cash"
              ? "cash"
              : checkedValue === "card"
              ? "Card"
              : "online",
          pay_type: checkedValue === "cash" ? "cod" : "online",
          rhClub: true
        }));
        totalRemainingSubscriptionCredits =
          totalRemainingSubscriptionCredits - 150;
        setFreeCredit(totalRemainingSubscriptionCredits);
        setIsCreditSelected(true);
      } else if (rhCreditDetails?.isRhValid === true && totalAmount < 150) {
        setSelectedPayment("freeCredit");
        // setCheckedValue("online");
        setCheckoutData((prev) => ({
          ...prev,
          discount_type: "FreeCredit",
          rh_discount: totalAmount,
          payment_mode: "online",
          pay_type: checkedValue === "cash" ? "cod" : "online",
          rhClub: true
        }));
        totalRemainingSubscriptionCredits =
          totalRemainingSubscriptionCredits - totalAmount;
        setFreeCredit(totalRemainingSubscriptionCredits);
        setIsCreditSelected(true);
      } else {
        setCheckoutData((prev) => ({
          ...prev,
          rhClub: false
        }));
      }
    }
  };
  // Reusable Input Component
  const CommonInput = ({
    type,
    name,
    checked,
    onChange,
    disabled,
    labelName,
    displayAmount
  }) => {
    return (
      <div className="checkboxItem">
        <div className="form-check switchBtn">
          <div className="switchFlex">
            <label className="form-check-label" htmlFor="radio3">
              {labelName === "Use credit first" ? (
                <img
                  style={{width: 40}}
                  src={
                    "https://dvdljkkxpxqo3.cloudfront.net/images/CreditPolicy.png.webp"
                  }
                  alt=""
                />
              ) : (
                <img
                  src={
                    "https://dvdljkkxpxqo3.cloudfront.net/images/card.png.webp"
                  }
                  alt=""
                />
              )}
              {labelName} <span>{displayAmount}</span>
            </label>
            <div className="useMyCredit">
              <label className="switch">
                <input
                  type={type}
                  name={name}
                  checked={checked}
                  onChange={onChange}
                  disabled={disabled}
                />
                <span className="switchslide round"></span>
              </label>
            </div>
          </div>
        </div>
        <Link
          to={
            name === "credit"
              ? "/my-account/creditdetails"
              : "/my-account/righthandwallet"
          }
          target="_blank"
          onClick={() => window.scrollTo(0, 0)}
        >
          {t("see_details1")}
        </Link>
      </div>
    );
  };
  return (
    <>
      {rhCreditDetails?.isRhValid === true && (
        <CommonInput
          type="checkbox"
          name="credit"
          checked={isCreditSelected}
          onChange={() =>
            handleCreditChange("PaymentByCredit", isCreditSelected)
          }
          disabled={hasNoCredits}
          labelName={t("use_credit_first")}
          displayAmount={
            freeCredit > 0 ? freeCredit : totalRemainingSubscriptionCredits
          }
        />
      )}
      <CommonInput
        type="checkbox"
        name="wallet"
        checked={isWalletSelected}
        onChange={() => handleCreditChange("PaymentByWallet", isWalletSelected)}
        disabled={
          isWalletSelected
            ? false
            : initialWalletAmount > 0 && checkoutData.totalPrice > 0
            ? false
            : true
        }
        labelName={t("use_wallet")}
        displayAmount={walletAmount?.toFixed(2)}
      />
    </>
  );
};

export default StepsPayment;
