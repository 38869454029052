export const getHelpReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_HELP_LIST_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_HELP_LIST_SUCCESS':
            return {
                help_data:action.payload.help_data,
                loading: false,
                success: true
            }
        case'GET_HELP_LIST_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        default:
            return state
    }
}