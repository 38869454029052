import axios from 'axios'
import {authHeader, handlingAuthErrors} from "../helpers/helpers";

export const getFreeCreditAction = (baseUrl,values) => dispatch => {

    dispatch({type: 'GET_FREE_CR_LIST_REQUEST'})
    axios({
        method: 'GET',
        headers:authHeader(),
        url: baseUrl,
        data: values
    }).then((res) => {
        dispatch({type: 'GET_FREE_CR_LIST_SUCCESS', payload: {
            free_cr_data:res.data
        }});
    }).catch((err) => {
        dispatch({type: 'GET_FREE_CR_LIST_FAILED', payload: err});
        handlingAuthErrors(err);
    })
}

export const getAllHistoryAction = (baseUrl,values,allHistory,setAppHistory) => dispatch => {

    dispatch({type: 'GET_ALL_HISTORY_REQUEST'})
    axios({
        method: 'GET',
        headers:authHeader(),
        url: baseUrl,
        data: values
    }).then((res) => {
        dispatch({type: 'GET_ALL_HISTORY_SUCCESS', payload: {
            free_cr_data:res.data
        }});
        if(res.data.list?.length > 0){
            let listData = res.data.list;
            listData.map((free_cr, index) => {
                allHistory.push(free_cr);
            })
            setAppHistory(allHistory);
        }
    }).catch((err) => {
        dispatch({type: 'GET_ALL_HISTORY_FAILED', payload: err});
        handlingAuthErrors(err);
    })
}

export const getAllPaymentHistoryAction = (baseUrl,values) => dispatch => {
     dispatch({type: 'GET_ALL_PAYMNET_HISTORY_REQUEST'})
   
    axios({
        method: 'GET',
        headers:authHeader(),
        url: baseUrl,
        data: values
    }).then((res) => {
        dispatch({type: 'GET_ALL_PAYMENT_HISTORY_SUCCESS', payload: {
            appointment_list:res.data.list
        }});
    })
    .catch((err) => {
      dispatch({type: 'GET_ALL_PAYMENT_HISTORY_FAILED', payload: err});
    // handlingAuthErrors(err);
    console.log("err",err)
})
}