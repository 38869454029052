import {combineReducers} from 'redux'
import {createStore, applyMiddleware, compose} from "redux";
import thunk from 'redux-thunk'
import {customerDetailsReducer} from './reducers/customerDetailsReducer';
import {userLoginReducer} from './reducers/userReducers';
import {homeReducer} from './reducers/homeReducer';
import {appointmentReducer} from './reducers/appointmentReducer';
import {professionalListReducer} from './reducers/professionalListReducer';
import {frequencyReducer} from './reducers/frequencyReducer';
import {customerAddressReducer} from './reducers/customerAddressReducer';
import {cleanerTimeAvailabilityReducer} from './reducers/cleanerTimeAvailabilityReducer';
import {getFAQReducer} from './reducers/getFAQReducer';
import {footerDataReducer} from './reducers/footerDataReducer';
import {serviceDetailsReducer} from './reducers/serviceDetailsReducer';
import {getTCReducer} from './reducers/getTCReducer';
import {getPrivacyReducer} from './reducers/getPrivacyReducer';
import {getCancellationReducer} from './reducers/getCancellationReducer';
import {getReschedulePolicyReducer} from './reducers/getReschedulePolicyReducer';
import {getFreeCreditReducer, getAllHistoryReducer} from './reducers/getFreeCreditReducer';
import {getSubscriptionReducer} from './reducers/getSubscriptionReducer';
import {getHelpReducer} from './reducers/getHelpReducer';
import {professionalDetailsReducer} from './reducers/professionalDetailsReducer';
import {professionalInfoReducer} from './reducers/professionalInfoReducer';
import {discountFreeReducer} from './reducers/discountFreeReducer';
import {blogsReducer} from './reducers/blogsReducer';
import {supportTicketReducer} from './reducers/supportTicketReducer';
import {eulaReducer} from './reducers/eulaReducer';
import {disclaimerReducer} from './reducers/disclaimerReducer';
import {cookiesPolicyReducer} from './reducers/cookiesPolicyReducer';
import {freeCreditApplyVoucherReducer} from './reducers/freeCreditApplyVoucherReducer';
import {freeCreditDiscountApplyReducer} from './reducers/freeCreditDiscountApplyReducer';
import {rescheduleAppointmentReducer} from './reducers/rescheduleAppointmentReducer';
import {applyVoucherCodeReducer} from './reducers/applyVoucherCodeReducer';
import {updateLanguageReducer} from './reducers/updateLanguageReducer';
import {myFavouriteProsReducer} from './reducers/myFavouriteProsReducer';
import {invitationReducer} from './reducers/invitationReducer';
import {bannerReducer} from './reducers/bannerReducer';
import {countryReducer} from './reducers/countryReducer';
import {rhClubReducer} from './reducers/rhClubReducer';
import {membershipReducer} from './reducers/membershipReducer';
import {customerReducer} from './reducers/customerReducer';
import {buyMemberShipReducer} from './reducers/buyMemberShipReducer';
import {extendMemberShipReducer} from './reducers/extendMemberShipReducer';
import {getAllPaymentHistoryReducer} from './reducers/getFreeCreditReducer';
import saveAddressReducer from './reducers/SaveAddressDataReducer';

const finalReducer = combineReducers({
    customerDetailsReducer:customerDetailsReducer,
    userLoginReducer:userLoginReducer,
    homeReducer:homeReducer,
    appointmentReducer:appointmentReducer,
    professionalListReducer:professionalListReducer,
    frequencyReducer:frequencyReducer,
    customerAddressReducer:customerAddressReducer,
    cleanerTimeAvailabilityReducer:cleanerTimeAvailabilityReducer,
    getFAQReducer:getFAQReducer,
    footerDataReducer:footerDataReducer,
    serviceDetailsReducer:serviceDetailsReducer,
    getTCReducer:getTCReducer,
    getPrivacyReducer:getPrivacyReducer,
    getCancellationReducer:getCancellationReducer,
    getReschedulePolicyReducer:getReschedulePolicyReducer,
    getFreeCreditReducer:getFreeCreditReducer,
    getSubscriptionReducer:getSubscriptionReducer,
    getHelpReducer:getHelpReducer,
    professionalDetailsReducer:professionalDetailsReducer,
    professionalInfoReducer:professionalInfoReducer,
    discountFreeReducer:discountFreeReducer,
    blogsReducer:blogsReducer,
    supportTicketReducer:supportTicketReducer,
    eulaReducer:eulaReducer,
    disclaimerReducer:disclaimerReducer,
    cookiesPolicyReducer:cookiesPolicyReducer,
    freeCreditApplyVoucherReducer:freeCreditApplyVoucherReducer,
    freeCreditDiscountApplyReducer:freeCreditDiscountApplyReducer,
    rescheduleAppointmentReducer:rescheduleAppointmentReducer,
    applyVoucherCodeReducer:applyVoucherCodeReducer,
    updateLanguageReducer:updateLanguageReducer,
    allHistoryReducer:getAllHistoryReducer,  
    myFavouriteProsReducer:myFavouriteProsReducer,
    invitationReducer:invitationReducer,
    bannerReducer:bannerReducer,
    countryReducer:countryReducer,
    rhClubReducer:rhClubReducer,
    membershipReducer:membershipReducer,
    customerReducer:customerReducer,
    buyMemberShipReducer:buyMemberShipReducer,
    extendMemberShipReducer:extendMemberShipReducer,
    allPaymnetHistoryReducer:getAllPaymentHistoryReducer,
    saveAddressReducer:saveAddressReducer,
})
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const currentUser = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : null
const currentToken = localStorage.getItem('currentToken') ? JSON.parse(localStorage.getItem('currentToken')) : null

const initialState = {
    userLoginReducer: {currentUser: currentUser, currentToken: currentToken}
}

const store = createStore(finalReducer, initialState, composeEnhancers(
    applyMiddleware(thunk)
))

export default store;