import axios from "axios";
import { toast } from "react-toastify";
import { authHeader, handlingNonauthErrors } from "../helpers/helpers";

export const updateAddressAction =
  (baseUrl, values, country_short_name) => (dispatch) => {
    axios({
      method: "PUT",
      headers: authHeader(),
      url: baseUrl,
      data: {
        customer_id: values.id,
        address_nickname: values.address_nickname,
        address: values.address,
        area: values.area,
        address_type: values.address_type,
        street: values.street,
        building: values.building,
        floor: values.floor,
        house_no: values.house_no ? values.house_no : "",
        Office_no: values.Office_no,
        apartment_no: values.apartment_no,
        country_code: values.country_code,
        mobile: values.mobile,
        landline_number: values.landline_number,
        latitude: `${values.latitude}`,
        longitude: `${values.longitude}`,
        country_short_name: country_short_name,
        isDefault: false,
      },
    })
      .then((res) => {
        // dispatch({
        //   type: "POST_UPDATE_COUNTRY_SUCCESS",
        //   payload: {
        //     // current_language: values.current_language,
        //   },
        // });
        // changeLanguage(values.current_language);
      })
      .catch((err) => {
        dispatch({ type: "POST_UPDATE_COUNTRY_FAILED", payload: err });
        handlingNonauthErrors(err);
      });
  };
