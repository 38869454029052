import React, { useEffect } from "react";

import { useParams, useSearchParams } from "react-router-dom";
import SwipeSlider from "./SwipeSlider";
import Header from "./Header";
import Banner from "./Banner";
import ServiceBanner from "./ServiceBanner";
import EmergencyCard from "./EmergencyCard";
import Blog from "./Blog";
import LifeEasy from "./LifeEasy";
import ManyReason from "./ManyReason";
import Testimonilas from "./Testimonilas";
import AppDownload from "./AppDownload";
import Footer from "./Footer";
import ScreenedProfessionals from "./ScreenedProfessionals";
import HappynessGuarantee from "./HappynessGuarantee";
import NowCheck from "./NowCheck";
import ProblemsNone from "./ProblemsNone";
import FullImageSlider from "./FullImageSlider";

const BirthDayAutoFill = () => {
  
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get('couponCode');
  
  useEffect(() => {
     if(code) {
      localStorage.setItem('couponCode', code);
     }
  }, [code]);


      return (
        <>
          <Header />
          <Banner />
          {/* <div className="container pt-7">
        
        
                </div> */}
          <SwipeSlider/>
         
          {/* <AllSwiperSlider serviceName={showService}/> */}
          {/* <Frequency/> */}
         
          <ServiceBanner />
          <EmergencyCard />
          <FullImageSlider/>
          <ScreenedProfessionals/>
          <HappynessGuarantee/>
          <Blog />
          <LifeEasy />
          <NowCheck/>
          <ProblemsNone/>
          <ManyReason />
          <Testimonilas />
          <AppDownload />
          {/* <AboutUs/> */}
    
          <Footer
          />
          
        </>
      );

};
export default BirthDayAutoFill;