import React from 'react'
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ProSettingContent from './ProSettingContent ';

const ProSettings = () => {
    return (
      <>
        <Header />
        <ProSettingContent/> 
        <Footer />
      </>
    );
}

export default ProSettings;
