import axios from 'axios'
import {toast} from "react-toastify";
import {authHeader, handlingNonauthErrors} from "../helpers/helpers";

export const getDiscountFreeCredit = (baseUrl,values) => dispatch => {
   
    dispatch({type: 'GET_DISCOUNT_FREE_REQUEST'})
    axios({
        method: 'GET',
        headers:authHeader(),
        url: baseUrl,
        data: values
    }).then((res) => {
        dispatch({type: 'GET_DISCOUNT_FREE_SUCCESS', payload: {
            discountFree:{
                latestExpiryCredit:res.data.latestExpiryCredit,totalCreditsEarned:res.data.totalCreditsEarned,
                list:res.data.list
            }
        }});
    }).catch((err) => {
        dispatch({type: 'GET_DISCOUNT_FREE_FAILED', payload: err});
        handlingNonauthErrors(err);
    })
}

