import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import AppDownload from "../../components/AppDownload";
import Loader from "../../components/Loader";
import { createSupportTicketAction } from "../../actions/supportTicketAction";
import { useTranslation } from 'react-i18next';
import {useLocation} from 'react-router-dom';

const CreateSupportTicket = () => {
    const dispatch = useDispatch();
    const supportTicketState = useSelector(state => state.supportTicketReducer)
    const {loading} = supportTicketState;
    const { t } = useTranslation();  
    const location = useLocation();
    const question = location.state.question;

    const [newTicket, setNewTicket] = useState({
        appointment_reference:"no_appointment",
        subject: question,
        description:'',
        role:'cu'
    });

    const updateTicketData = (key,value) => {
        setNewTicket((prevState) => ({
          ...prevState,
          [key]: value
        }));
    }

    async function submitNewTicket(){
      let apiUrl = process.env.REACT_APP_LOCAL_API_URL + "/zendesk/create";
      dispatch(createSupportTicketAction(apiUrl,newTicket));
    }
  return (
    <>
    {loading ? <Loader /> : ""}
      <Header />
      <div className="py-5">
        <div className="userContainer">
          <div className="editPersonalDetail">
            <div className="heading">
           <h2>{t('create_support_ticket')}</h2>
            </div>
            <div className="card">
              <div className="personalForm">
                <div className="inputdiv col-lg-12 ">
                    <textarea 
                    className="form-control h-45" 
                    placeholder={t('how_can_we_help_you')}
                    onChange={(event) => {
                        updateTicketData('description',event.target.value);
                    }}
                    ></textarea>
                </div>
                <div className="inputdiv col-lg-12 mt-3 mb-0 ">
                    <button 
                    className={"btn btn-primary w-full submitTicket "+ ((newTicket.description) ? '' : 'disabled')}
                    onClick={() => submitNewTicket()}
                    disabled={(newTicket.description) ? null : 'disabled'}
                    >
                      {t('create_support_ticket')}
                    </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppDownload />
      <Footer />
    </>
  );
};

export default CreateSupportTicket;
