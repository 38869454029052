import React, {useEffect, useState} from "react";
import {Link, useLocation, NavLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import jQuery from "jquery";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {createUpdateCustCreditCard} from "../../helpers/helpers";
import {ModifiedToastContainer} from "../../components/ModifiedToastContainer";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {
  customerCreditCardListsAction,
  customerCreditCardDeleteAction
} from "../../actions/customerDetails";
import Loader from "../../components/Loader";

const CreditCard = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  const getCustomerAllCreditCardState = useSelector(
    (state) => state.customerDetailsReducer
  );
  const [loading, setLoading] =useState(false);
  const { customerCreditCards} = getCustomerAllCreditCardState;
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  // let listUrl = baseUrl + "/payment/instruments";
  // let listUrl = baseUrl + " creditCard/get/qnbCustomer";
  useEffect(() => {
    dispatch(customerCreditCardListsAction());
    // setLoading(true)
  }, []);

  async function deleteCreditCard(source_id) {
    let url = baseUrl + "/creditCard/delete/qnbCustomer";
    dispatch(customerCreditCardDeleteAction(url));
    jQuery("#swal2-title").html(t("are_you_sure"));
    jQuery("#swal2-html-container").html(t("are_you_sure_want_to_delete_card"));
    jQuery(".swal2-confirm").html(t("delete"));
    jQuery(".swal2-cancel").html(t("cancel"));
  }

  return (
    <>
      <Header />
      <ModifiedToastContainer />
      {loading ? <Loader /> : ""}
      <div className="py-5">
        <div className="userContainer">
          <div className="creditCard">
            <div className="heading">
              <Link to="/my-account/account-details">
                <h5>{t("account_details")}</h5>
              </Link>
              <h2>{t("credit_cards")}</h2>
            </div>
            <div className="card">
              <div className="personalForm">
                <div className="cardsItems">
                  <h3>
                    {!loading ? (customerCreditCards !== null ? "" : "") : ""}
                  </h3>
                  <ul className="list-group">
                    {customerCreditCards ? (
                      //  customerCreditCards.map((customerCreditCard, index) => {
                      //  return (
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div className="fw-bold">
                            ...{customerCreditCards?.last4}
                          </div>
                          {customerCreditCards?.expMonth +
                            "/" +
                            customerCreditCards?.expYear}
                        </div>
                        {/* <NavLink to={"/my-account/edit-credit-card/"+customerCreditCard?.id} className="deleteCard">
                                <i className="fa fa-edit"></i>
                          </NavLink> */}
                        <div>&nbsp;</div>
                        <span
                          className="deleteCard"
                          onClick={() =>
                            deleteCreditCard(customerCreditCards?.id)
                          }
                        >
                          <i className="fa fa-trash"></i>
                        </span>
                      </li>
                    ) : (
                      // )})
                      <center>{t("no_card")}</center>
                    )}
                  </ul>
                </div>
                {!customerCreditCards && (
                  <div className="inputdiv col-lg-12 mb-0">
                    <NavLink
                      to="/my-account/add-credit-card"
                      state={{redirect: "/my-account/creditcard"}}
                      className="btn btn-primary w-full mt-4"
                    >
                      {t("add_card")}
                    </NavLink>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CreditCard;
