import React from "react";
import {  Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import AppDownload from "../../components/AppDownload";
import { useTranslation } from 'react-i18next';
import {useLocation} from 'react-router-dom';

const HelpDetail = () => {
    const currentLanguage = localStorage.getItem('currentLanguage') ? localStorage.getItem('currentLanguage') : 'en';
   
    const { t } = useTranslation();  
    const location = useLocation();
    const question = location.state.question
    const question_arabic = location.state.question_arabic
    const answer = location.state.answer
    const answer_arabic = location.state.answer_arabic
    const support_type = location.state.support_type
  return (
    <>
      <Header />
      <div className="py-5">
        <div className="userContainer">
          <div className="helpPage helpPage2">
            <div className="heading">
              <Link to="/my-account/help">
                <h5>{t('help')}</h5>
              </Link>
              <h2>{ t('help') }</h2>
            </div>
            <div className="card">
              <div className="personalForm">
                <div className="helpItem">
                  <h3 className="mb-2">
                    {(currentLanguage === "en" ? question : question_arabic)}
                  </h3>
                    <p dangerouslySetInnerHTML={{__html: (currentLanguage === "en" ? answer : answer_arabic)}}></p>
                    {(() => {
                      if (support_type === 'ticket') {
                        return (
                        <div className="inputdiv col-lg-12 mt-3 mb-0 ">
                          <Link to={"/my-account/help/create/ticket"} state={{question: question}} className="dropdown-item">
                            <button className="btn btn-primary w-full">
                              {t('create_support_ticket')}
                            </button>
                          </Link>
                        </div>
                        )
                      }
                      if (support_type === 'chat') {
                        return (
                        <div className="inputdiv col-lg-12 mt-3 mb-0 ">
                          <button className="btn btn-primary w-full">
                            {t('chat_with_us')}
                          </button>
                        </div>
                        )
                      }
                    })()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AppDownload />
      <Footer />
    </>
  );
};

export default HelpDetail;
