import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from 'react-i18next';
import { customerAddressListsAction, makeDefaultCustomerAddressAction } from "../../../../actions/customerAddressAction";
import Loader from "../../../../components/Loader";

const UserAddressList = ({
  userAddresListModal,
  handleUserAddresListModalHide,
  handleAddressModalShow,
  updateCheckoutData
}) => {
    const { t } = useTranslation();
    const getCustomerAllAddressesState = useSelector(state => state.customerAddressReducer)
    const {loading, customerAddresses} = getCustomerAllAddressesState;
    const dispatch = useDispatch();
    let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
    let listUrl = baseUrl + "/customer/address/list";

    useEffect(() => {
      dispatch(customerAddressListsAction(listUrl,findDefaultAddress));
    }, []);

    useEffect(() => {
      if(customerAddresses?.length > 0){
        customerAddresses.map((address, index) => {
          if(address.isDefault){
            setAppointmentAddress(address);
          }
        })
      }
    }, []);
  
    const addNewAddress = () => {
      handleUserAddresListModalHide();
      handleAddressModalShow();
    }

    const getAddresses = () => {
      dispatch(customerAddressListsAction(listUrl,findDefaultAddress));
    }

    const findDefaultAddress = (addresses) => {
      setTimeout(() => {
        if(addresses?.length > 0){
          addresses.map((address, index) => {
            if(address.isDefault){
              setAppointmentAddress(address);
            }
          })
        }
      }, 2000);
    }

    async function setAppointmentAddress(address) {
      updateCheckoutData('address',address.address);
      updateCheckoutData('country',address.country);
      updateCheckoutData('latitude',address.latitude);
      updateCheckoutData('longitude',address.longitude);
      updateCheckoutData('address_id',address.id);
      handleUserAddresListModalHide();
    }

    async function selectAppointmentAddress(address) {
      updateCheckoutData('address',address.address);
      updateCheckoutData('country',address.country);
      updateCheckoutData('latitude',address.latitude);
      updateCheckoutData('longitude',address.longitude);
      updateCheckoutData('address_id',address.id);
      makeDefaultAddress(address.id);
      handleUserAddresListModalHide();
    }

    async function makeDefaultAddress(id){
      let actionUrl = baseUrl + "/customer/address/default/"+id;
      dispatch(makeDefaultCustomerAddressAction(actionUrl,getAddresses));
    }
  return (
    <>
      <Modal
        show={userAddresListModal}
        onHide={handleUserAddresListModalHide}
        id="addAddress"
        className="addAddressModal"
      >
        <Modal.Header closeButton>
          <Modal.Title> { t('select_your_address') } </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="selctAddressItem">
          <h3 onClick={() => addNewAddress()}>
            <i className="fa fa-plus"></i>{ t('add_new_address') }
          </h3>
        </div>
        <div className="selctAddressItem">
        {customerAddresses && customerAddresses?.length > 0 ? (
          customerAddresses.map((address, index) => {
            return (
            <div className="checkboxItem mb-4" key={index}  onClick={() => selectAppointmentAddress(address)}>
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id={"addresss_"+index}
                    name="optradio2"
                    defaultChecked={address.isDefault}
                  />
                <label className="form-check-label" htmlFor={"addresss_"+index}>
                {address.address}
                </label>
              </div>
              <p className="newAddresDetails">{address.addressDetails}</p>
            </div>
              );
            })
          ) : (
            <p className="">{ t('no_address_found') }</p>
          )}
        </div>
        <div className="selctAddressItem">
            <p>
            { t('to_manage_your_addresses') }
            <Link to="/my-account/address">
                <span className="btn-close" data-bs-dismiss="modal">
                { t('settings_my_addresses_page') }
                </span>
            </Link>
            </p>
        </div>
        </Modal.Body>
      </Modal>
      {loading ? <Loader/> : ""}
    </>
  );
};

export default UserAddressList;
