import axios from 'axios'
import {toast} from "react-toastify";
import {authHeader, handlingAuthErrors} from "../helpers/helpers";

export const rescheduleAppointmentAction = (baseUrl,values, navigate, state) => dispatch => {
    dispatch({type: 'POST_APPOINTMENT_RESCHEDULE_REQUEST'})
    axios({
        method: 'PUT',
        headers:authHeader(),
        url: baseUrl,
        data: values
    }).then((res) => {
        dispatch({type: 'POST_APPOINTMENT_RESCHEDULE_SUCCESS', payload: {
            appointmentsData:[]
        }});
        if(res.data.rescheduled){
            toast.success(res.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            localStorage.removeItem('appointmentDetailsUpdateData');
            localStorage.removeItem('appointmentDetailsId');
            navigate("/my-account/appointments");
            // setTimeout(function () {
            //     window.location.href = "/my-account/appointments";
            // }, 500);
        }else{
            values['apiEndPoint'] = baseUrl;
            values['type'] = "Reschedule";
            values['payableAmount'] = res.data.reschedule_charge;
            localStorage.setItem('appointmentDetailsUpdateData', JSON.stringify(values));
            navigate("/my-account/common-checkout", {
                state: state
              });
            // setTimeout(() => {
            //     window.location.href = '/my-account/common-checkout';
            // }, 1000);
        }
    }).catch((err) => {
        dispatch({type: 'POST_APPOINTMENT_RESCHEDULE_FAILED', payload: err});
        handlingAuthErrors(err);
        // setTimeout(() => {
        //     window.location.href = '/my-account/appointments';
        // }, 1000);
    });
}