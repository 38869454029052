import React, {useState, useEffect} from "react";
import { useTranslation } from 'react-i18next';
//import { toast } from 'react-toastify';
import Modal from "react-bootstrap/Modal";
import {ModifiedToastContainer} from '../../components/ModifiedToastContainer';
import jQuery from 'jquery';
import {changeProSettingAction} from "../../actions/userActions";
import {useDispatch, useSelector} from "react-redux";

const ProSettingContent  = () => {

    const [showModal, setShowModal] = useState(false);
    const handleModalShow = () => setShowModal(true);
    const handleModalClose = () => setShowModal(false);
    async function changeGenderSetting(){
      handleModalShow();
    }  

    /* async function changeGenderSettingSubmit(){
      let language = jQuery('input[name=language]:checked').val();
      localStorage.setItem('currentLanguage',language);
      i18next.changeGenderSetting(language);
      handleModalClose();
    } */
    const { t } = useTranslation();
    const currentUser = (localStorage.getItem('currentUser') && localStorage.getItem('currentUser') !== "null") ? JSON.parse(localStorage.getItem('currentUser')) : false;
    
    useEffect(() => {
      if(!currentUser){
        window.location.href = '/';
      }
    }, [currentUser]);

    const dispatch = useDispatch();
    let ProSettingUrl = process.env.REACT_APP_LOCAL_API_URL+"/customer/pro-settings";
    const [petFriendly, setPetFriendly] = React.useState(""); 
    const handleChange = (e) => { 
     let checkPetFriendly = jQuery('input[name=pet_friendly]:checked').val();
     let pet_friendly = "";
      if (checkPetFriendly=="on") {
             pet_friendly = true;
      }else{
             pet_friendly = false;
      }  

      let values = {
          pet_friendly: pet_friendly,
          gender: currentUser.pro_setting_gender
      }
      setPetFriendly(pet_friendly);
      dispatch(changeProSettingAction(ProSettingUrl,values));
    }

    async function handleGender() { 
        const gender = jQuery('input[name=gender]:checked').val();
        let values = {
            pet_friendly: currentUser.pro_setting_pet_friendly,
            gender: gender
        }
        dispatch(changeProSettingAction(ProSettingUrl,values));
    }

  return (
      <>
      <ModifiedToastContainer/>
        <div className="py-5">
          <div className="userContainer">
            <div className="userSettings">
             <div className="heading">
              <h2>{t('pro_settings')}</h2>
              <p>{t('pro_settings_info')}</p>
             </div>
              <div className="card">
                <div className="accountSettings">
                  <h3>{t('cleaning_pros')}</h3>
                 <ul className="list">
                    <li>
                    <a  onClick={changeGenderSetting}>
                    {t('gender')}
                        <span>{t(currentUser.pro_setting_gender)}
                         <i className="fa fa-angle-right"></i>
                        </span>
                      </a>                   
                    </li>
                    <li>
                      <a>
                     {t('pet_friendly')}
                        <span>
                        <label className="switch">
                          <input type="checkbox" name="pet_friendly" className="usermycredit PaymentByCredit" onChange={handleChange} defaultChecked={currentUser.pro_setting_pet_friendly ? true : false } />
                          <span className="switchslide round"></span>
                        </label>
                        </span>
                      </a>
                    </li>               
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  language Modal */}
        
      <Modal show={showModal} onHide={handleModalClose} id="language" className="addAddressModal">
        <Modal.Header closeButton>
          <Modal.Title>{t('select_gender')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="modal-body ">
          <label className="w-full languageChecked">
            <p>{t('Male')}</p>
            <input type="radio" value="Male" name="gender" className="language" defaultChecked={currentUser.pro_setting_gender==="Male" ? true : false }></input>
            <i className="fa fa-check"></i>
          </label>
          <label className="w-full languageChecked">
            <p>{t('Female')}</p>
            <input type="radio" value="Female" name="gender" className="language" defaultChecked={currentUser.pro_setting_gender==="Female" ? true : false }></input>
            <i className="fa fa-check"></i>
          </label>
          <label className="w-full languageChecked">
            <p>{t('Any')}</p>
            <input type="radio" value="Any" name="gender" className="language" defaultChecked={currentUser.pro_setting_gender==="Any" ? true : false }></input>
            <i className="fa fa-check"></i>
          </label>
          <button type="submit" className="btn btn-primary w-full mt-2" onClick={() => handleGender()}>
          {t('ok')}
          </button>
        </div>
        </Modal.Body>
      </Modal>
      
      </>
    );
}

export default ProSettingContent;
