import React from 'react'
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import SettingContent from "./SettingContent";
const Settings = () => {
    return (
      <>
        <Header />
        <SettingContent/>
            
        <Footer />
      </>
    );
}

export default Settings;
