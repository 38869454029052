import React from "react";
// import StepThreeSlider from '../Steps/StepThreeSlider'
export const Date = () => {
  return (
    <>
      <div className="">
        <div className="step-wizard ">
          <div className="item-container is-flex is-active isDone">
            <div className="item">
              <div className="marker tag ">
                <p className="helper-text-item">1</p>
              </div>
              <div className="details">
                <p className=" step-title ">Service Details</p>
              </div>
            </div>
            <div className="divider"></div>
          </div>
          <div className="item-container is-flex is-active">
            <div className="item">
              <div className="marker tag ">
                <p className="helper-text-item ">2</p>
              </div>
              <div className="details">
                <p className=" step-title">Date &amp; Time</p>
              </div>
            </div>
            <div className="divider"></div>
          </div>
          <div className="item-container is-flex">
            <div className="item">
              <div className="marker tag ">
                <p className="helper-text-item ">3</p>
              </div>
              <div className="details">
                <p className="step-title">Payment</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="stepsContent stepDate ">
        <div className="row">
          <div className="col-lg-7 col-md-12">
            <div className="card">
              <div className="stepsCard ">
                
                {/* <StepThreeSlider /> */}
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5">
            <div className="card cardDetails">
              <div className="card-content ">
                <p>City</p>
                <p>Duhail</p>
              </div>
              <div className="card-content ">
                <p>Service Details</p>
                <ul>
                  <li>
                    Frequency <span>Bi-weekly</span>
                  </li>
                  <li>
                    Duration <span>2 hours</span>
                  </li>
                  <li>
                    Number of Professionals <span>1</span>
                  </li>
                  <li>
                    Material <span>No</span>
                  </li>
                </ul>
              </div>
              <div className="card-content ">
                <p>Date & Time</p>

                <ul>
                  <li>
                    Professional <span>Auto assign</span>
                  </li>
                  <li>
                    Date <span>Thursday, February 24</span>
                  </li>
                  <li>
                    Start Time
                    <span>17:00-17:30</span>
                  </li>
                </ul>
              </div>
              <div className="card-content ">
                <p>Address </p>
                <span>Noia Avenue, Antioch, CA, USA</span>
              </div>
              <div className="card-content ">
                <p>Price Details</p>
                <ul>
                  <li>
                    Price <span>QAR 79.05</span>
                  </li>
                  <li>
                    VAT <span>QAR 3.95</span>
                  </li>
                  <li>
                    Discount <span>-QAR 4.15</span>
                  </li>
                  <li>
                    Total <span className="total">QAR 78.85</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Date;
