import React, { useState, useEffect } from "react";
import {Link} from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import AppDownload from "./AppDownload";
import Loader from "./Loader";
import {useDispatch, useSelector} from "react-redux";
import { getprivacyPolicyAction } from "../actions/privacyPolicyAction";
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
    const getPrivacyPolicyState = useSelector(state => state.getPrivacyReducer)
    const { loading, pp_data } = getPrivacyPolicyState;
    const dispatch = useDispatch();
    let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
    let listUrl = baseUrl + "/staticPage/info/privacy";
    const currentLanguage = localStorage.getItem('currentLanguage') ? localStorage.getItem('currentLanguage') : 'en';
    useEffect(() => {
        dispatch(getprivacyPolicyAction(listUrl,{}));
    }, [listUrl]);
  const { t } = useTranslation();

  return (
    <>
      <Header />
      {loading ? <Loader /> : ""}
      <div className="container">
        <div className="pt-5">
          <div className="heading">
            <h5><Link to="/">{ t('home') }</Link></h5> 
            <h2>{ t('privacy_policy') }</h2> 
          </div>
          <div className="card">
            {pp_data ?
            <div className="accordion-body" dangerouslySetInnerHTML={ { __html: (currentLanguage === "en" ? pp_data.content : pp_data.content_arabic)}}>
            </div>
            : <div className="accordion-body content_not_found"><div className="col-12"><h6 className="text-center content_not_found_text">{ t('content_not_found') }</h6></div></div>}
          </div>
        </div>
      </div>
      <AppDownload />
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
