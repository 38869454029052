import {Link, useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Modal from "../components/Modal";
import jQuery from "jquery";
// import { getHomeMainService} from "../actions/homeAction";


const Footer = () => {
  const {t} = useTranslation();
  let navigate = useNavigate();
  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  // const getAllServicesState = useSelector((state) => state.footerDataReducer);
  // const { services, banner } = getAllServicesState;
  const dispatch = useDispatch();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  // let listUrl = baseUrl + "/home/services";
  const getAllServicesState = useSelector((state) => state.homeReducer);
  // const allStates = useSelector((state) => state);
  const { footerServices} = getAllServicesState;
  // useEffect(() => {
  //   dispatch(getFooterDataAction(listUrl));
  // }, [listUrl]);
  // useEffect(() => {
  //   const countryID = localStorage.getItem("userCountryId");
  //   let listMainUrl =
  //     baseUrl +
  //     `/home/dashboard?country_id=${localStorage.getItem("userCountryId")}`;
  //   if (countryID) {
  //     dispatch(getHomeMainService(listMainUrl));
  //   }
  // }, []);
  const bookService = (serviceId, ui_type, service) => {
    // debugger
    if (currentUser) {
      if (ui_type === "Frequency" || ui_type === "Wheel") {
        navigate("/frequency/checkout/details", {
          state: {
            serviceId: serviceId,
            isBookNow: true,
            isLogin: true,
            ui_type: ui_type
          }
        });
      } else if (ui_type === "Package") {
        navigate("/");
      } else {
        navigate("/frequency/checkout/details?step=2", {
          state: {
            serviceId: serviceId,
            isBookNow: true,
            isLogin: false,
            ui_type: ui_type
          }
        });
      }
    } else {
      navigate("/frequency/checkout/details?step=2", {
        state: {
          serviceId: serviceId,
          isBookNow: true,
          isLogin: false,
          ui_type: ui_type
        }
      });
    }
  };

  function viewServiceDetails(id) {
    jQuery("html, body").animate({scrollTop: 0}, "slow");
    localStorage.setItem("serviceDetailId", id);
    setTimeout(() => {
      navigate("/service-details");
    }, 500);
  }

  function viewStaticContent(id) {
    jQuery("html, body").animate({scrollTop: 0}, "slow");
    setTimeout(() => {
      navigate(id);
    }, 500);
  }

  function gotoExternalSite(site) {
    window.open(site, "_blank");
  }

  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="d-flex justify-content-between flex-wrap">
            <div className="footerLogo">
              <Link to="/">
                {" "}
                <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/logo-white.webp" />
              </Link>
            </div>
            <div className="footerLinks footerserviceLinks ">
              <ul>
                {footerServices?.length > 0
                  ? footerServices.map((service, index) => {
                      return (
                        <li key={index}>
                          <span
                            style={{
                              cursor: "pointer",
                              color: "#b793ee",
                              marginRight: 15
                            }}
                            onClick={() =>
                              bookService(
                                service._id,
                                service?.ui_type,
                                service
                              )
                            }
                          >
                            {currentLanguage === "en"
                              ? service?.name
                              : service?.name_arabic}
                          </span>
                        </li>
                      );
                    })
                  : ""}
              </ul>
            </div>
            <div className="footerLinks">
              <ul>
                <li>
                  <Link to={"/about"} onClick={() => window.scrollTo(0, 0)}>
                    {t("about")}
                  </Link>
                </li>
                {/* <li>
                    <Link to={"/faq"} onClick={() => window.scrollTo(0, 0)}>{t("faq")}</Link>
                  </li> */}
                <li>
                  <Link
                    to={"/terms/conditions"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {t("terms_conditions")}
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/privacy/policy"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {t("privacy_policy")}
                  </Link>
                </li>
                {/*
                  <li>
                    <Link to="#" onClick={() => viewStaticContent('/cancellation/policy')}>{t("cancellation_policy")}</Link>
                  </li>
                  */}
                <li>
                  <Link
                    to={"/cancellation/policy"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {t("reschedule_policy")}
                  </Link>
                </li>
                <li>
                  <a
                    href="https://righthands.applytojob.com/apply"
                    target="_blank"
                  >
                    {t("career")}
                  </a>
                </li>
                <li>
                  <Link
                    to={"/blogs/list"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {t("blog")}
                  </Link>
                </li>
                <li>
                  <Link to={"/eula"} onClick={() => window.scrollTo(0, 0)}>
                    {t("eula")}
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/disclaimer"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {t("disclaimer")}
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/cookies/policy"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {t("cookies_policy")}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="socialIcon">
              <div className="">
                <Link
                  className="iconbtn"
                  to={"#"}
                  onClick={() =>
                    gotoExternalSite(
                      "https://www.facebook.com/RightHandService"
                    )
                  }
                >
                  <i className="fa fa-facebook"></i>
                  {t("facebook")}
                </Link>
                <Link
                  className="iconbtn"
                  to={"#"}
                  onClick={() =>
                    gotoExternalSite("https://twitter.com/righthandsqa")
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1.3em"
                    viewBox="0 0 512 512"
                  >
                    <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                  </svg>
                  {/* {t("twitter")} */}
                </Link>
                <Link
                  className="iconbtn"
                  to={"#"}
                  onClick={() =>
                    gotoExternalSite("https://instagram.com/right.hands")
                  }
                >
                  <i className="fa fa-instagram"></i>
                  {t("instagram")}
                </Link>
                <Link
                  className="iconbtn"
                  to={"#"}
                  onClick={() =>
                    gotoExternalSite(
                      "https://in.pinterest.com/righthandsqa/_saved"
                    )
                  }
                >
                  <i className="fa fa-pinterest"></i>
                  {t("Pinterest")}
                </Link>
                <Link
                  className="iconbtn"
                  to={"#"}
                  onClick={() =>
                    gotoExternalSite(
                      "http://www.linkedin.com/in/right-hands-qa"
                    )
                  }
                >
                  <i className="fa fa-linkedin"></i>
                  {t("Linkedin")}
                </Link>
              </div>
            </div>
            {/* <div
                className="helpQuote"
                data-bs-toggle="modal"
                data-bs-target="#chatModal"
              >
                 
                   <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/quote2.png.webp" />
               
              </div> */}
          </div>
        </div>
      </div>
      <Modal />
    </>
  );
};
export default Footer;
