import axios from 'axios';
import {toast} from "react-toastify";
import {authHeader, handlingAuthErrors} from "../helpers/helpers";

export const getProfessionalDetailsAction = (baseUrl,values) => dispatch => {

    dispatch({type: 'GET_PROFESSIONAL_DETAILS_REQUEST'})
    axios({
        method: 'GET',
        headers:authHeader(),
        url: baseUrl,
        data: values
    }).then((res) => {
        dispatch({type: 'GET_PROFESSIONAL_DETAILS_SUCCESS', payload: {
            professionalInfo:{
                user:res.data.user
            }
        }});
    }).catch((err) => {
        dispatch({type: 'GET_PROFESSIONAL_DETAILS_FAILED', payload: err});
        handlingAuthErrors(err);
    });
}

export const postProfessionalReviewAction = (baseUrl,values) => dispatch => {
    dispatch({type: 'POST_PROFESSIONAL_REVIEW_REQUEST'})
    axios({
        method: 'POST',
        headers:authHeader(),
        url: baseUrl,
        data: values
    }).then((res) => {
        dispatch({type: 'POST_PROFESSIONAL_REVIEW_SUCCESS'});
        toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setTimeout(function () {
            localStorage.removeItem('appointmentDetailsUpdateData');
            localStorage.removeItem('appointmentDetailsId');
            localStorage.removeItem('appointmentCleanersId');
            localStorage.removeItem('appointmentCleanerId');
             window.location.href = "/my-account/appointments";
          }, 2000);
    }).catch((err) => {
         dispatch({type: 'POST_PROFESSIONAL_REVIEW_FAILED', payload: err});
        handlingAuthErrors(err);
    })
}