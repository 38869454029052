import React from "react";
import {Link } from 'react-router-dom';
const ServiceList = () => {
  return (
    <>
     <div className="py-5 ">
      <div className=" serviceList">
      <div className="serviceItemservice text-center">
          <Link to="/service/service-cat"><img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/service/HomeCleaning.png.webp" /></Link>
        </div>
        <div className="serviceItemservice text-center">
        <Link to="/service/service-cat"> <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/service/furniture.png.webp" /></Link>
        </div>
        <div className="serviceItemservice text-center">
        <Link to="/service/service-cat"><img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/service/BabySitting.png.webp" /></Link>
        </div>
        <div className="serviceItemservice text-center">
        <Link to="/service/service-cat"><img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/service/BEAUTY _ SPA.png.webp" /></Link>
        </div>
        <div className="serviceItemservice text-center">
        <Link to="/service/service-cat"> <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/service/PestControl.png.webp" /></Link>
        </div>
        <div className="serviceItemservice text-center">
        <Link to="/service/service-cat"><img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/service/Gardening _ Landscaping.png.webp" /></Link>
        </div>  
        <div className="serviceItemservice text-center">
        <Link to="/service/service-cat"><img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/service/HOSPITALITY_ SERVICES.png.webp" /></Link>
        </div> 
        <div className="serviceItemservice text-center">
          <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/service/Bird _ Pigeon.png.webp" />
        </div>   
        <div className="serviceItemservice text-center">
        <Link to="/service/service-cat"> <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/service/Gardening _ Landscaping.png.webp" /></Link>
        </div>  
        <div className="serviceItemservice text-center">
          <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/service/MOBILE CAR WASH _ DETAIL CLEANSING.png.webp" />
        </div>  
        <div className="serviceItemservice text-center">
          <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/service/Removals.png.webp" />
        </div>  
        <div className="serviceItemservice text-center">
          <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/service/PROPERTY_RENOVATIONS.png.webp" />
        </div>  
      </div>
      <div className="needSomething">
      <h2 className="text-center mb-5" >Need something else ? Just let us Know .<br></br> You'll be amazed at what we can do.</h2>
      <ul>
        <li><Link to="/"> <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/service/call.png.webp" /> Call Now</Link></li>
      <li>  <Link to="/"> <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/service/chat.png.webp" /> Start Chat</Link></li>
      </ul>
      </div>
      </div>
    </>
  );
};

export default ServiceList;
