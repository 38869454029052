import React, {useState, useEffect} from "react";
import {Autocomplete} from "@react-google-maps/api";
import {useTranslation} from "react-i18next";
const SearchLocation = ({
  center,
  setCenter,
  setAddressObject,
  addressObject,
  setShortName,
  setMarker,
  setIsToggle,
  currentLocationGetter
}) => {
  const [autocomplete, setAutocomplete] = useState(null);
  const {t} = useTranslation(); // useEffect(()=>{
  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  //   const place = autocomplete?.getPlace();
  //   if (place?.geometry && place.geometry.location  && place !== undefined) {
  //   handlePlaceChanged();
  //   }
  // }, [autocomplete]);
  const handlePlaceChanged = async () => {
    if (autocomplete && autocomplete !== undefined && autocomplete !== "") {
      const place = autocomplete?.getPlace();
      const addressComponents = place.address_components || [];

      let street = "";
      let area = "";

      for (const component of addressComponents) {
        const types = component.types || [];

        if (types.includes("route")) {
          // 'route' type represents the street
          street = component.long_name;
        } else if (
          types.includes("neighborhood") ||
          types.includes("sublocality")
        ) {
          // 'neighborhood' or 'sublocality' type represents the area
          area = component.long_name;
        }
      }

      if (place?.geometry && place.geometry.location && place !== undefined) {
        const latLng = {
          lat: Number(place.geometry.location.lat()),
          lng: Number(place.geometry.location.lng())
        };

        if (!isNaN(latLng.lat) && !isNaN(latLng.lng)) {
          setCenter(latLng);
          setMarker(latLng);
          const countryComponent = place.address_components.find((component) =>
            component.types.includes("country")
          );
          setShortName(countryComponent ? countryComponent.short_name : "");

          setAddressObject({
            ...addressObject,
            latitude: latLng.lat,
            longitude: latLng.lng,
            address: place.formatted_address,
            street: street,
            area: area
          });
          setIsToggle(true);
        } else {
          console.error("Invalid coordinates:", latLng);
        }
      } else {
        console.error("Invalid place object:", place);
      }
    }
  };

  // const handlePlaceChanged = async () => {
  //   if (autocomplete) {
  //     const place = autocomplete.getPlace();
  //     console.log("place", place);
  //     if (place?.geometry && place!==undefined) {
  //       const latLng = {
  //         lat: place.geometry.location.lat(),
  //         lng: place.geometry.location.lng()
  //       };
  //       debugger
  //       // setCenter(latLng);

  //       const countryComponent = place.address_components.find((component) =>
  //         component.types.includes("country")
  //       );

  //       setShortName(countryComponent ? countryComponent.short_name : "");

  //       setAddressObject({
  //         ...addressObject,
  //         latitude: latLng.lat,
  //         longitude: latLng.lng,
  //         address: place.formatted_address
  //       });
  //     }
  //   }
  // };

  return (
    <>
      {/* <div className="searchPlace" style={{zIndex: 1}}></div> */}
      <Autocomplete
        onLoad={(auto) => setAutocomplete(auto)}
        onPlaceChanged={handlePlaceChanged}
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      >
        <>
          <i
            class="fa fa-map-marker"
            style={{
              position: "absolute",
              top: "10px",
              right: currentLanguage === "ar" ? 30 : "auto",
              left: currentLanguage === "ar" ? "auto" : 30,
              fontSize: 20
            }}
            aria-hidden="true"
          ></i>
          <input
            type="text"
            placeholder={t("search_for_your_building_or_area")}
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `240px`,
              height: `32px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`
            }}
            onChange={(e) => {
              setAddressObject({
                ...addressObject,
                address: e.target.value
              });
            }}
            value={addressObject?.address}
          />
          <button
            className="autosign"
            onClick={(e) => currentLocationGetter()}
            style={{
              position: "absolute",
              top: "5px",
              right: currentLanguage === "ar" ? "auto" : "15px",
              left: currentLanguage === "ar" ? 15 : "auto"
            }}
          >
            <img
              src="https://dvdljkkxpxqo3.cloudfront.net/images/gps.png.webp"
              height="30"
              width="30"
              alt=""
            />{" "}
          </button>
        </>
      </Autocomplete>
    </>
  );
};

export default SearchLocation;
