import axios from "axios";
import {authHeader, handlingAuthErrors} from "../helpers/helpers";
import {toast} from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { customerDetailAction } from "./homeAction";
const MySwal = withReactContent(Swal);

export const buyMemberShipAction =
  (baseUrl, values, setPageLoading, from, setIsBuyMemberShip, setShowBuyButton) => (dispatch) => {
    setPageLoading(true);
    dispatch({type: "BUY_MEMBERSHIP_REQUEST"});
    axios({
      method: "POST",
      headers: authHeader(),
      url: baseUrl,
      data: values
    })
      .then((res) => {
        toast.success(res.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });

        setPageLoading(false);
        if (res?.data?.code === 200) {
          if (from === "paymentPage") {
            // let customerMembershipUrl = baseUrl + "/customer/get/profile";
            // dispatch(customerDetailAction(customerMembershipUrl));
            // setShowBuyButton(true);
            // setIsBuyMemberShip(false);
           
          } else {
            window.location.href = "/my-account/righthandcredit";
          }
        }
        // setTimeout(function () {

        //

        // }, 2000);
      })
      .catch((err) => {
        setPageLoading(false);
        dispatch({type: "BUY_MEMBERSHIP_FAILED", payload: err});
        handlingAuthErrors(err);
      });
  };
