import React, {useState, useEffect} from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import AppointmentList from "./AppointmentList";
import { useTranslation } from 'react-i18next';

const Appointments = () => {
  const { t } = useTranslation();
  const currentUser = (localStorage.getItem('currentUser') && localStorage.getItem('currentUser') !== "null") ? JSON.parse(localStorage.getItem('currentUser')) : false;
  useEffect(() => {
    if(!currentUser){
      window.location.href = '/';
    }
  }, [currentUser]);
    
  return (
    <>
      <Header />
      <div className="py-5">
        <div className="userContainer">
          <div className="appointments">
            <div className="heading">
              <h2>{t('appointments')}</h2>
            </div>
            <div className="card">
              <AppointmentList/>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Appointments;
