import React, {useEffect, useState, useRef} from "react";
import OwlCarousel from "react-owl-carousel";
import {useDispatch, useSelector} from "react-redux";
import {getCleanerTimeAvailability} from "../../../../actions/cleanerTimeAvailability";
import Loader from "../../../../components/Loader";
import {useTranslation} from "react-i18next";
import {getVisitTimeInfoAction} from "../../../../actions/tcDataAction";
import Modal from "react-bootstrap/Modal";
import {holidayList} from "../../../../helpers/helpers";
import jQuery from 'jquery';
const moment = require("moment");

const SelectDateTime = ({checkoutData, updateCheckoutData, setCheckoutData}) => {
  const [holidayLists, setHolidayLists] = useState();
  const [disabledHoliday, setDisabledHoliday] = useState(false);
  const [holidayStatus, setHolidayStatus] = useState(false);
  async function listHoliday() {
    let data = {country_id: localStorage.getItem("userCountryId")};
    await holidayList(data)
      .then((res) => {
        setHolidayLists(res.data.holidayLists);
        setDisabledHoliday(res.data.holidayAllow);
        setHolidayStatus(res.data.status);
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  }
  useEffect(() => {
    listHoliday();
  }, []);
  const getCleanerTimeAvailabilitySlot = useSelector(
    (state) => state.cleanerTimeAvailabilityReducer
  );
  const {loading, available_time} = getCleanerTimeAvailabilitySlot;
  const dispatch = useDispatch();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let listUrl = baseUrl + "/appointment/cleaner/time/availability";
  const {t} = useTranslation();
  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";

  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  const getSlotDataCurrentUser = () => {
    dispatch(
      getCleanerTimeAvailability(
        listUrl,
        {
          cleaner_id: !checkoutData.is_auto_assign
            ? checkoutData?.cleaner_id
            : "",
          is_auto_assign: checkoutData.is_auto_assign,
          appointment_date: checkoutData.appointment_date,
          hours_count: checkoutData.hours_count,
          address_id: checkoutData.address_id ? checkoutData.address_id : "",
          customer_id: currentUser?.id ? currentUser?.id : " ",
          service_id: typeof checkoutData?.service_id === "string"
          ? checkoutData.service_id
          : checkoutData?.service_id?.length > 0
          ? checkoutData?.service_id[0]
          : "",
          frequency_id: checkoutData?.frequency_id,
          is_home_cleaning: false,
          country_id: localStorage.getItem("userCountryId"),
          latitude: !checkoutData.address_id ? checkoutData.latitude : "",
          longitude: !checkoutData.address_id ? checkoutData.longitude : ""
        },
        updateCheckoutData,
        t,
        setCheckoutData
      )
    );
  };

  const getSlotData = () => {
    dispatch(
      getCleanerTimeAvailability(
        listUrl,
        {
          cleaner_id: !checkoutData.is_auto_assign
            ? checkoutData?.cleaner_id
            : "",
          is_auto_assign: checkoutData.is_auto_assign,
          appointment_date: checkoutData.appointment_date,
          hours_count: checkoutData.hours_count,
          address_id: checkoutData.address_id ? checkoutData.address_id : "",
          service_id:
          typeof checkoutData?.service_id === "string"
          ? checkoutData.service_id
          : checkoutData?.service_id?.length > 0
          ? checkoutData?.service_id[0]
          : "",
          frequency_id: checkoutData?.frequency_id,
          is_home_cleaning: false,
          country_id: localStorage.getItem("userCountryId"),
          latitude: !checkoutData.address_id ? checkoutData.latitude : "",
          longitude: !checkoutData.address_id ? checkoutData.longitude : ""
        },
        updateCheckoutData,
        t,
        setCheckoutData
      )
    );
  };

  useEffect(() => {
    if (currentUser) {
      getSlotDataCurrentUser();
    } else {
      getSlotData();
    }
  }, []);

  const [visitTimeInfoShow, setVisitTimeInfoShow] = useState(false);
  const handleVisitTimeInfoShow = () => setVisitTimeInfoShow(true);
  const handleVisitTimeInfoHide = () => setVisitTimeInfoShow(false);

  const getStartTimeInfoState = useSelector((state) => state.getTCReducer);
  const {start_time_info} = getStartTimeInfoState;
  let startTimeUrl = baseUrl + "/staticPage/info/StartTimeInfo";
  const visitTimeInformation = () => {
    dispatch(getVisitTimeInfoAction(startTimeUrl, {}));
    handleVisitTimeInfoShow();
  };

  const availableDatesInput = [];
  function availableDates() {
    let startDate = moment();
    let endDate = moment().add(30, "days");

    while (startDate.isSameOrBefore(endDate)) {
      var days = [
        "اﻷحد",
        "اﻷثنين",
        "الثلاثاء",
        "اﻷربعاء",
        "الخميس",
        "الجمعة",
        "السبت"
      ];
      var week_day_arabic = days[startDate.day()];
      availableDatesInput.push({
        week_day: startDate.format("dd"),
        week_day_arabic: week_day_arabic,
        month_day: startDate.format("D"),
        month: startDate.format("MMM"),
        date: startDate.format("YYYY-MM-DD")
      });
      startDate = startDate.add(1, "day");
    }
  }
  availableDates();

  const options = {
    margin: 9.1,
    responsiveClass: true,
    nav: true,

    dots: false,
    autoplay: false,
    autoWidth: true,

    navText: [
      "<button type='button' class='slick-prev'><i class='fa fa-angle-left' aria-hidden='true'></i></button>",
      "<button type='button' class='slick-next'><i class='fa fa-angle-right' aria-hidden='true'></i></button>"
    ],
    nxet: "",
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 2,
        margin: 7
      },
      375: {
        items: 3,
        margin: 10
      },
      380: {
        items: 3,
        margin: 11
      },
      480: {
        items: 3,
        margin: 10
      },
      550: {
        items: 4,
        margin: 12
      },
      600: {
        items: 4,
        margin: 13
      },
      768: {
        items: 4,
        margin: 12
      },
      992: {
        items: 7,
        margin: 12
      },
      1200: {
        items: 9,
        margin: 8.1
      },
      1400: {
        margin: 11
      }
    }
  };

  function setBookingDateTime(time, dateTime, type) {
    if (type === "date") {
      updateCheckoutData("start_time_between", "");
      updateCheckoutData("appointment_date", dateTime);
      if (currentUser) {
        dispatch(
          getCleanerTimeAvailability(
            listUrl,
            {
              cleaner_id: !checkoutData.is_auto_assign
                ? checkoutData?.cleaner_id
                : "",
              is_auto_assign: checkoutData.is_auto_assign,
              appointment_date: dateTime? dateTime: checkoutData.appointment_date,
              hours_count: checkoutData.hours_count,
              address_id: checkoutData.address_id ? checkoutData.address_id : "",
              customer_id: currentUser?.id ? currentUser?.id : " ",
              service_id: typeof checkoutData?.service_id === "string"
              ? checkoutData.service_id
              : checkoutData?.service_id?.length > 0
              ? checkoutData?.service_id[0]
              : "",
              frequency_id: checkoutData?.frequency_id,
              is_home_cleaning: false,
              country_id: localStorage.getItem("userCountryId"),
              latitude: !checkoutData.address_id ? checkoutData.latitude : "",
              longitude: !checkoutData.address_id ? checkoutData.longitude : ""
            },
            updateCheckoutData,
            t
          )
        );
      } else {
        getSlotData();
      }
      // dispatch(getCleanerTimeAvailability(listUrl,{
      // //  cleaner_id: "",
      // cleaner_id: !checkoutData.is_auto_assign ? checkoutData?.cleaner_id : "",
      // is_auto_assign: checkoutData.is_auto_assign,
      // appointment_date: checkoutData.appointment_date,
      // hours_count: checkoutData.hours_count,
      // address_id: checkoutData.address_id?checkoutData.address_id:'',
      // // customer_id: currentUser?.id ? currentUser?.id : " "  ,
      // service_id:checkoutData?.service_id[0] ? checkoutData?.service_id[0] :"",
      // frequency_id: checkoutData?.frequency_id,
      // is_home_cleaning:false,
      // country_id :localStorage.getItem('userCountryId'),
      // latitude: !checkoutData.address_id ? checkoutData.latitude : '',
      // longitude: !checkoutData.address_id ? checkoutData.longitude : ''
      // },updateCheckoutData,t));
    } else {
      updateCheckoutData(
        "appointment_date_start",
        checkoutData.appointment_date + " " + time
      );
      updateCheckoutData("start_time_between", dateTime);
    }
  }

  /*  function getTimeSlots(id) {
    dispatch(getCleanerTimeAvailability(listUrl,{
      cleaner_id: id,
      is_auto_assign: (id === "") ? true : false,
      appointment_date:checkoutData.appointment_date
    }));
  } */

  const options2 = {
    margin: 25,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    autoWidth: true,

    navText: [
      "<i class='fa fa-angle-left' aria-hidden='true'></i>",
      "<i class='fa fa-angle-right' aria-hidden='true'></i>"
    ],

    nxet: "",
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 3
      },
      600: {
        items: 4
      },
      700: {
        items: 4
      },
      1000: {
        items: 7
      }
    }
  };

  const holidayDisableDate = (day, fullDate) => {
    let isDisabled = false;
    let findFriday = new Date(fullDate).getDay();
    // if (day < 10) {
    //     day = '0' + day
    // }
    // console.log(day,fullDate)
    for (let y = 0; y < holidayLists?.length; y++) {
      let dateDay = moment(holidayLists[y]?.dmyJava).format("YYYY-MM-DD");

      if (dateDay === fullDate || findFriday === 5) {
        if (!disabledHoliday) {
          isDisabled = true;
          break;
        }
      }
    }
    if (isDisabled === true) return isDisabled;
  };

  const styles = {
    color: "#fff",
    background: "#432375",
    border: "2px solid #432375"
  };
  const styles2 = {
    margin: "0 auto"
  };

  const disableStyles = {
    color: "#fff",
    background: "#999999",
    border: "2px solid #999999"
  };

  const stylesTime = {
    background: "#432375",
    border: "2px solid #432375",
    color: "#fff"
  };
  return (
    <>
      <div className="checkboxSec">
        <div className="datesec">
          {loading ? <Loader /> : ""}
          <h3>{t("like_your_service")}</h3>
          <OwlCarousel
            className="dateSlot owl-theme mt-4"
            loop={false}
            touchDrag={false}
            mouseDrag={false}
            mergeFit={true}
            {...options}
          >
            {availableDatesInput?.length > 0
              ? availableDatesInput.map((item, index) => {
                  let holidayDisabled = holidayDisableDate(
                    item?.month_day,
                    item?.date
                  );
                  return (
                    <div className="item" key={index}>
                      {holidayDisabled ? (
                        <>
                          <span className="dia_semana">
                            <label>
                              <input
                                type="radio"
                                name="booking_date"
                                id="booking_date"
                                defaultValue={item.date}
                                onClick={() =>
                                  setBookingDateTime("", item.date, "date")
                                }
                                defaultChecked={
                                  item.date === checkoutData.appointment_date
                                }
                              />
                              <span>
                                {currentLanguage === "en"
                                  ? item.week_day
                                  : item.week_day_arabic}
                              </span>
                              {/* className={"dia "+((index === 0) ? "disabled_date" : "")} */}
                              <span
                                className={
                                  "dia " +
                                  (item.date === checkoutData.appointment_date
                                    ? "date_selected"
                                    : "")
                                }
                                style={disableStyles}
                              >
                                {item.month_day}
                              </span>
                            </label>
                          </span>
                          <span className="mes">{item.month}</span>
                        </>
                      ) : (
                        <>
                          <span className="dia_semana">
                            <label>
                              <input
                                type="radio"
                                name="booking_date"
                                id="booking_date"
                                defaultValue={item.date}
                                onClick={() =>
                                  setBookingDateTime("", item.date, "date")
                                }
                                defaultChecked={
                                  item.date === checkoutData.appointment_date
                                }
                              />
                              <span>
                                {currentLanguage === "en"
                                  ? item.week_day
                                  : item.week_day_arabic}
                              </span>
                              {/* className={"dia "+((index === 0) ? "disabled_date" : "")} */}
                              <span
                                className={
                                  "dia " +
                                  (item.date === checkoutData.appointment_date
                                    ? "date_selected"
                                    : "")
                                }
                              >
                                {item.month_day}
                              </span>
                            </label>
                          </span>
                          <span className="mes">{item.month}</span>
                        </>
                      )}
                    </div>
                  );
                })
              : ""}
          </OwlCarousel>
        </div>
        {available_time?.length > 0 ? (
          <div className="timeButtons">
            <h3>
              {t("what_time_to_start")}{" "}
              <img
                src="https://dvdljkkxpxqo3.cloudfront.net/images/info2.png.webp"
                alt=""
                onClick={() => visitTimeInformation()}
              />
            </h3>
            <div className="buttonArea">
              <div className="  w-full pt-2 ">
                <OwlCarousel
                  className="owl-theme"
                  items={3}
                  loop={false}
                  margin={25}
                  {...options2}
                >
                  {available_time?.length > 0
                    ? available_time.map((time, index) => {
                        let selectTime = false;
                        if (
                          index === 0 &&
                          checkoutData.start_time_between === ""
                        ) {
                          setBookingDateTime(
                            time.start_time,
                            time.time,
                            "time"
                          );
                          selectTime = true;
                        }
                        if (checkoutData.start_time_between === time.time) {
                          selectTime = true;
                        }
                        return (
                          <div className="item" key={index}>
                            <div
                              className="buttonItem"
                              onClick={() =>
                                setBookingDateTime(
                                  time.start_time,
                                  time.time,
                                  "time"
                                )
                              }
                            >
                              <label>
                                <input
                                  type="radio"
                                  value="11:00 - 11:30"
                                  name="start_time"
                                  defaultChecked={selectTime}
                                />
                                <span>{time.time}</span>
                              </label>
                            </div>
                          </div>
                        );
                      })
                    : ""}
                </OwlCarousel>
              </div>
            </div>
          </div>
        ) : (
          <center>{t("no_slot_available")}</center>
        )}
        <p>
          {checkoutData.start_time_between
            ? t("professional_arrive_time") +
              " " +
              checkoutData.start_time_between +
              " " +
              t("at_doorstep")
            : ""}
        </p>
      </div>
      <Modal
        show={visitTimeInfoShow}
        onHide={handleVisitTimeInfoHide}
        id="cleaningMaterial"
        className="cleaningMaterial"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {start_time_info ? (
            <div
              className="accordion-body"
              dangerouslySetInnerHTML={{
                __html:
                  currentLanguage === "en"
                    ? start_time_info?.content
                    : start_time_info?.content_arabic
              }}
            ></div>
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SelectDateTime;
