import axios from 'axios'
import {toast} from "react-toastify";
import {authHeader, handlingAuthErrors} from "../helpers/helpers";

export const createSupportTicketAction = (baseUrl,values) => dispatch => {

    dispatch({type: 'POST_CREATE_SUPPORT_TICKET_REQUEST'})
    axios({
        method: 'POST',
        headers:authHeader(),
        url: baseUrl,
        data: values
    }).then((res) => {
        dispatch({type: 'POST_CREATE_SUPPORT_TICKET_SUCCESS', payload: {}});
        toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setTimeout(function () {
            window.location.href = "/my-account/Help";
        }, 2000);
    }).catch((err) => {
        dispatch({type: 'POST_CREATE_SUPPORT_TICKET_FAILED', payload: err});
        handlingAuthErrors(err);
    })
}