import axios from 'axios';
import {authHeader, handlingNonauthErrors,handlingAuthErrors} from "../helpers/helpers";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { LaptopWindows } from '@material-ui/icons';
const MySwal = withReactContent(Swal);

export const getHomeDataAction = (baseUrl) => dispatch => {
  
    const userCountryId = localStorage.getItem('userCountryId');
    dispatch({type: 'GET_HOME_DATA_REQUEST'})
    axios.get(baseUrl,{
        headers: authHeader(),
        params: {
          country_id: userCountryId
        }
    }).then((res) => {
        res.data.services.forEach((service)=>{

            // else {
            //     if(service.ui_type === "Package" ){
            //         if(localStorage.getItem("serviceCheckoutData") !== null){
            //             localStorage.removeItem("serviceCheckoutData");
                      
            //         }
            //     }

            // }

        })
        dispatch({type: 'GET_HOME_DATA_SUCCESS', payload: {
            frequencies:res.data.frequencies,
            services:res.data.services,
            banner:res.data.banner,
            servicesResult:res.data.servicesResult,
            status: res?.data?.status,
            footerServices:res.data.footerServices,
            allData:res.data,
        }});
        
       
    }).catch((err) => {
        dispatch({type: 'GET_HOME_DATA_FAILED', payload: err});
        handlingNonauthErrors(err);
    })
}

export const getHomeBannerAction = (baseUrl) => dispatch => {
    dispatch({type: 'GET_HOME_BANNER_REQUEST'})
    axios.get(baseUrl,{
        headers: authHeader(),
    }).then((res) => {
        dispatch({type: 'GET_HOME_BANNER_SUCCESS', payload: {
            home_banner:res?.data?.banner
        }});
    }).catch((err) => {
        dispatch({type: 'GET_HOME_BANNER_FAILED', payload: err});
        {/*
        handlingNonauthErrors(err);
        */}
    })
}

export const getRHClubAction = (baseUrl) => dispatch => {

    dispatch({type: 'GET_RH_CLUB_REQUEST'})
    axios.get(baseUrl,{
        headers: authHeader(),
    }).then((res) => {
        dispatch({type: 'GET_RH_CLUB_SUCCESS', payload: {
            rh_club:res.data.country
        }});
    }).catch((err) => {
        dispatch({type: 'GET_RH_CLUB_FAILED', payload: err});
        handlingAuthErrors(err);
    })
}

export const getHomeServiceCategoryAction = (baseUrl,values) => dispatch => {

    dispatch({type: 'GET_HOME_SERVICE_CATEGORY_REQUEST'})
    axios({
        method: 'POST',
        headers:authHeader(),
        url: baseUrl,
        data: values
    }).then((res) => {
        dispatch({type: 'GET_HOME_SERVICE_CATEGORY_SUCCESS', payload: {
            servicesCategory:res.data.serviceCategory
        }});
        localStorage.setItem("serviceCategoryPackageId",res.data.serviceCategory[0]._id)
        localStorage.setItem("minOrderAmount", res.data.minOrderAmount)
    }).catch((err) => {
        dispatch({type: 'GET_HOME_SERVICE_CATEGORY_FAILED', payload: err});
        handlingNonauthErrors(err);
    })
}

export const getHomeProfessionalListAction = (baseUrl,values) => dispatch => {

    dispatch({type: 'GET_HOME_PROFESSIONAL_LIST_REQUEST'})
    axios({
        method: 'GET',
        headers:authHeader(),
        url: baseUrl,
        data: values
    }).then((res) => {
        dispatch({type: 'GET_HOME_PROFESSIONAL_LIST_SUCCESS', payload: {
            professionals:res.data.list
        }});
    }).catch((err) => {
        dispatch({type: 'GET_HOME_PROFESSIONAL_LIST_FAILED', payload: err});
        handlingNonauthErrors(err);
    })
}

export const cancelMemberShipAction = (baseUrl,values,t) => dispatch => {
    MySwal.fire({
        title: t('are_you_sure'),
        text: t('cancel_membership_info'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',    
        cancelButtonColor: '#d33',
        confirmButtonText: t('confirm'),
        cancelButtonText: t('cancel')
    }).then((result) => {
            if (result.isConfirmed) {
                   axios({
                       method: 'POST',
                       headers:authHeader(),
                       url: baseUrl,
                       data: values
                   }).then((res) => {
                       toast.success(res.data.message, {
                           position: "top-center",
                           autoClose: 5000,
                           hideProgressBar: false,
                           closeOnClick: true,
                           pauseOnHover: true,
                           draggable: true,
                           progress: undefined,
                       });
                       setTimeout(function () {
                           window.location.href = "/my-account/righthandcredit";
                       }, 2000);
                   }).catch((err) => {
                       dispatch({type: 'CANCEL_MEMBERSHIP_FAILED', payload: err});
                       handlingAuthErrors(err)
                   });
            }
        }
    )

}

export const customerDetailAction = (baseUrl) => dispatch => {
    dispatch({type: 'GET_CUSTOMER_DETAIL_REQUEST'})
    axios.get(baseUrl,{
        headers: authHeader()
    }).then((res) => {
        dispatch({type: 'GET_CUSTOMER_DETAIL_SUCCESS', payload: {
            user:res.data.user,
            customerMembership:res.data.customerMembership
        }});
    }).catch((err) => {
        dispatch({type: 'GET_CUSTOMER_DETAIL_FAILED', payload: err});
        handlingAuthErrors(err);
    })
}

export const getHomeMainService = (baseUrl) => dispatch =>{    
    dispatch({type: 'GET_HOME_MAIN_DATA_REQUEST'})
    axios.get(baseUrl,{
        headers: authHeader() 
    }).then((res) => {
        dispatch({type: 'GET_HOME_MAIN_DATA_SUCCESS', payload: {
            frequencies:res.data.frequencies,
            services:res.data.services,
            banner:res.data.banner,
            servicesResult:res.data.servicesResult,
            status: res?.data?.status,
            footerServices:res.data.footerServices,
            allData:res.data
        }});
       
    }
    ).catch((err) => {
        dispatch({type: 'GET_CUSTOMER_DETAIL_FAILED', payload: err});
        handlingAuthErrors(err);
    })
}