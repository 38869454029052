import React, {useState, useEffect} from "react";
import ReactPhoneInput from "react-phone-input-2";
import {useTranslation} from "react-i18next";
import es from "react-phone-input-2/lang/es.json";
import ar from "react-phone-input-2/lang/ar.json";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";

const AddressForm = ({
  setCheckoutData,
  setIsToggle,
  addressObject,
  checkoutData,
  setProfileRef,
  updateCheckoutData,
  setShowAddressAddModal,
  setIsLocation
}) => {
  const {t} = useTranslation();

  const userCountryCode = localStorage.getItem("userCountryCode")
    ? localStorage.getItem("userCountryCode")
    : "en";
  const currentLanguageCode = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  const getAllCountryState = useSelector((state) => state.countryReducer);
  const {country_list} = getAllCountryState;
  const [formData, setFormData] = useState({
    address: "",
    area: "",
    building: "",
    city: "",
    state: "",
    street: "",
    address_type: "null",
    mobile: "",
    floor: "",
    house_no: "",
    appartment_no: "",
    office_no: ""
  });
  let errorMessage = "";
    const [addError, setAdderror] = useState({
    address_type: "",
    street: " ",
    floor: "",
    house_no: "",
    area: ""
  });
  const validation = (
    area,
    address_type,
    street,
    floor,
    house_no,
    building
  ) => {
    let isValid = true;
    const error = {};
    if (area === undefined || area === "") {
      errorMessage = "fill_address_error";
      setAdderror({
        ...addError,
        ["area"]: t("no_address_found")
      });
      setIsToggle(true);
    } else if (address_type === "none" || address_type === "null") {
      errorMessage = "fill_address_error_type";
      setAdderror({
        ...addError,
        ["address_type"]: t("fill_address_error_type"),
        ["area"]: ""
      });
      isValid = false;
    } else if (street === "") {
      errorMessage = "fill_address_error";
      setAdderror({
        ...addError,
        ["street"]: t("street-Error"),
        ["address_type"]: ""
      });
      isValid = false;
    } else if (building === "") {
      setAdderror({
        ...addError,
        ["building"]: t("building-Error"),
        ["street"]: ""
      });
      errorMessage = "fill_address_error";
      // error.street = " ";
      isValid = false;
    } else if (floor === "") {
      setAdderror({
        ...addError,
        ["floor"]: t("floor_error"),
        ["building"]: ""
      });
      errorMessage = "fill_address_error";
      isValid = false;
    } else if (house_no === "") {
      setAdderror({
        ...addError,
        ["house_no"]: t("house_error"),
        ["floor"]: ""
      });
      errorMessage = "fill_address_error";
      error.house_no = t("house_error");
      isValid = false;
    } else {
      setAdderror({
        ...addError,
        ["area"]: "",
        ["house_no"]: "",
        ["address_type"]: "",
        ["floor"]: "",
        ["street"]: "",
        ["building"]: ""
      });
      errorMessage = "";
    }
    return isValid;
  };
  // Function to update form data when addressObject changes
  const updateFormData = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      address: addressObject?.address || "",
      area: addressObject?.area || "",
      building: addressObject?.building || "",
      city: addressObject?.city || "",
      state: addressObject?.state || "",
      street: addressObject?.street || "",
      address_type: addressObject?.address_type || "null",
      mobile: addressObject?.mobile || "",
      floor: addressObject?.floor || "",
      house_no: addressObject?.house_no || "",
      appartment_no: addressObject?.appartment_no || "",
      office_no: addressObject?.office_no || ""
    }));
  };
  useEffect(() => {
    // console.log("addressObject", addressObject);
    updateFormData(); // Call the function when the component mounts or when addressObject changes
  }, [addressObject]);
  const onChange = (e) => {
    const {name} = e.target;
    setFormData({...formData, [name]: e.target.value});
  };
  const handleCustomerSubmit = async (e) => {
    localStorage.getItem("latitude");
    localStorage.getItem("longitude");
    const isValid = validation(
      formData?.area,
      formData?.address_type,
      formData?.street,
      formData?.floor,
      formData.house_no,
      formData?.building
    );
    e.preventDefault();
    setCheckoutData((prevState) => ({
      ...prevState,
      latitude: addressObject?.latitude
        ? addressObject?.latitude
        : localStorage.getItem("latitude"),
      longitude: addressObject?.longitude
        ? addressObject?.longitude
        : localStorage.getItem("longitude"),

      address: addressObject?.address !== "" ? addressObject?.address : "",
      address_nickname: addressObject?.nick_name,
      addressData: isValid ? formData : ""
    }));
    if (addressObject?.countryLong) {
      const filteredArray = country_list.filter(
        (item) => item?.name === addressObject?.countryLong
      );

      if (filteredArray?.length > 0) {
        updateCheckoutData("country_id", filteredArray[0]?._id);
      }
    }
    if (
      addressObject?.longitude !== "" &&
      addressObject?.longitude !== undefined &&
      isValid
    ) {
      setShowAddressAddModal(false);
      setIsLocation(true);
    } else {
        toast.error(t(`${errorMessage}`), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
     
      // alert("Please select location correctly");
      setShowAddressAddModal(true);
      setIsLocation(false);
    }
    // Remove all jQuery usage for form values here.
  };
  return (
    <>
      <div>
        <div className="row">
          <div className="col-lg-12 inputdiv ">
            <input
              type="text"
              className="form-control pl-0"
              id="address_nickname"
              name="address_nickname"
              placeholder={t("address_nickname")}
              value={
                addressObject?.address !== "" ? addressObject?.address : ""
              }
            />
            <div className="help-block"></div>
          </div>
        </div>
        <div className="row ">
          <div className="col-lg-12 inputdiv ">
            <input
              type="text"
              className="form-control pl-0"
              id="area"
              name="area"
              placeholder={t("area")}
              onChange={(e) => onChange(e)}
              value={formData?.area !== "" ? formData?.area : ""}
            />
            <div className="help-block">
              {addError?.area ? addError.area : ""}
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-lg-12 inputdiv ">
            <select
              className="form-select"
              style={{
                color: formData?.address_type === "none" ? "red" : "#000"
              }}
              name="address_type"
              id="address_type"
              onChange={(e) => onChange(e)}
              value={formData?.address_type}
            >
              <option style={{color: "red"}} value="none">{`${t(
                "address_type"
              )}*`}</option>
              <option style={{color: "#000"}} value="Home">
                {t("home")}{" "}
              </option>
              <option style={{color: "#000"}} value="Apartment">
                {t("apartment")}{" "}
              </option>
              <option style={{color: "#000"}} value="Office">
                {t("office")}
              </option>
            </select>
            <div className="help-block">
              {addError?.address_type ? addError?.address_type : ""}
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-lg-6 inputdiv ">
            <input
              type="text"
              className="form-control pl-0"
              id="street"
              name="street"
              placeholder={t("street")}
              value={formData?.street}
              onChange={(e) => onChange(e)}
            />
            <div className="help-block">
              {addError?.street ? addError.street : ""}
            </div>
          </div>
          <div className="col-lg-6 inputdiv ">
            <input
              type="text"
              className="form-control pl-0"
              id="building"
              name="building"
              placeholder={t("building")}
              value={formData.building !== "" ? formData.building : ""}
              onChange={(e) => onChange(e)}
            />
            <div className="help-block">
              {addError?.building ? addError.building : ""}
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-lg-12 inputdiv ">
            <input
              type="text"
              className="form-control pl-0"
              id="floor"
              name="floor"
              value={formData.floor ? formData.floor : ""}
              placeholder={t("floor")}
              onChange={(e) => onChange(e)}
            />
            <div className="help-block">
              {addError.floor ? addError.floor : ""}
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-lg-12 inputdiv ">
            <input
              type="text"
              className="form-control pl-0"
              id="home_number"
              name="house_no"
              placeholder={
                formData.address_type === "Apartment"
                  ? `${t("apartment")} ${t("number")}`
                  : formData.address_type === "Office"
                  ? `${t("office")} ${t("number")}`
                  : `${t("house")} ${t("number")}`
              }
              onChange={(e) => onChange(e)}
              value={formData.house_no ? formData.house_no : ""}
            />
            <div className="help-block">
              {addError.house_no ? addError.house_no : ""}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 inputdiv ">
            <input
              type="text"
              className="form-control pl-0"
              placeholder={t("additional_directions")}
              name="additional_directions"
              onChange={(e) => onChange(e)}
              value={
                formData.additional_directions
                  ? formData.additional_directions
                  : ""
              }
            />
            <div className="help-block"></div>
          </div>
        </div>
        {/* <div className="row ">
          <div className="col-lg-12 inputdiv ">
            <input
              type="text"
              className="form-control pl-0"
              id="landline_number"
              name="landline_number"
              placeholder={t("landline_number")}
              onChange={(e) => onChange(e)}
              value={formData.landline_number ? formData.landline_number : ""}
            />
            <div className="help-block"></div>
          </div>
        </div> */}
        <div className="row ">
          <div className="col-lg-12 inputdiv ">
            <div className="row loginMobileInput mb-2">
              <div className="inputgroup phonecodeselect col-lg-3">
                <ReactPhoneInput
                  inputProps={{
                    className: "login_country_code form-control",
                    name: "country_code",
                    id: "country_code",
                    autoFocus: true,
                    placeholder: t("enter_mobile_number"),
                    readOnly: "readonly"
                  }}
                  country={userCountryCode.toLocaleLowerCase()}
                  localization={currentLanguageCode === "en" ? es : ar}
                  countryCodeEditable={false}
                  placeholder=""
                  disableCountryCode={false}
                  searchClass="search-class"
                  searchStyle={{
                    margin: "0",
                    width: "97%",
                    height: "30px"
                  }}
                  enableSearch
                  disableSearchIcon
                />
                <div className="help-block text-danger"></div>
              </div>

              <div className="inputgroup phonecodeselect col-lg-9">
                <input
                  type="number"
                  name="mobile"
                  id="mobile"
                  className="form-control"
                  placeholder={t("enter_mobile_number")}
                  onChange={(e) => onChange(e)}
                  value={formData.mobile ? formData.mobile : ""}
                />
                <div className="help-block text-danger"></div>
              </div>
            </div>
            <div className="help-block"></div>
          </div>
        </div>
        <button
          onClick={(e) => handleCustomerSubmit(e)}
          className="btn btn-primary"
        >
          {t("confirm")}
        </button>
      </div>
      {/* <div
        className={isToggle ? "mapToggle hide" : "mapToggle toast hide"}
        style={{marginTop: "-270px"}}
      >
        <button
          type="button"
          className="btnclose"
          onClick={() => setIsToggle(false)}
        >
          <span className="fa fa-angle-down"></span>
        </button>
      </div> */}
    </>
  );
};

export default AddressForm;
