import React from "react";
import { NavLink, Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import AppDownload from "../../components/AppDownload";
import { useTranslation } from 'react-i18next';
import {useLocation} from 'react-router-dom';

const HelpSubQuestions = () => {
    const currentLanguage = localStorage.getItem('currentLanguage') ? localStorage.getItem('currentLanguage') : 'en';
    const { t } = useTranslation();  
    const location = useLocation();
    const questions = location.state.questions
    const name = location.state.name
    const name_arabic = location.state.name_arabic
 
  return (
    <>
      <Header />
      <div className="py-5">
        <div className="userContainer">
          <div className="helpPage helpPage">
            <div className="heading">
              <Link to="/my-account/help">
                <h5>{t('help')}</h5>
              </Link>
              <h2>{ t('help') }</h2> 
            </div>
            <div className="card">
              <div className="personalForm">
                <div className="helpItem">
                  <h3>{(currentLanguage === "en" ? name : name_arabic)}</h3>
                </div>
              </div>
              <div className="accountSettings">
                <ul className="list">
                  {questions?.length > 0
                  ? questions.map((question, index) => {
                      return (
                              <li key={index}>
                                <NavLink to={"/my-account/help/detail/"} state={{question: question.question,question_arabic: question.question_arabic, answer: question.answer, answer_arabic: question.answer_arabic, support_type: question.support_type}} className="dropdown-item">
                                  {(currentLanguage === "en" ? question.question : question.question_arabic)}
                                  <i className="fa fa-angle-right"></i>
                                </NavLink>
                              </li>
                              );
                          })
                      : ""}
                  <li>
                    <div className="termsLink">
                      <Link to={"../terms/conditions"}>{t("terms_conditions")}</Link>
                      <Link to={"../terms/conditions"}>{t("privacy_policy")}</Link>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppDownload />
      <Footer />
    </>
  );
};

export default HelpSubQuestions;
