import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import {useTranslation} from "react-i18next";
import jQuery from "jquery";
import es from "react-phone-input-2/lang/es.json";
import ar from "react-phone-input-2/lang/ar.json";
import {useDispatch} from "react-redux";
import {profileSetupAction} from "../../../../actions/userActions";
import {toast} from "react-toastify";
import ReactPhoneInput from "react-phone-input-2";
import {profileSetupApi} from "../../../../helpers/bookingAPIsteps";
const ProfileSetupModal = ({
  profileInfoModal,
  handleProfileInfoModalClose,
  userSignUpName,
  userSignUpEmail,
  setUserSignUpName,
  setIsAddressAdded,
  isProfileFromMobile, 
  setIsProfileFromMobile,
  addSetupAddress,
  setUserSignUpEmail,
  userMobile, 
  setUserMobile
}) => {
  const {t} = useTranslation();
  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  const userCountryCode = localStorage.getItem("userCountryCode")
    ? localStorage.getItem("userCountryCode")
    : "en";
  const currentLanguageCode = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;

  const [file, setFile] = useState();
  const [preview1, setPreview1] = useState(
    `/images/user-placeholder.webp`
  );

  const dispatch = useDispatch();
  let object1Url;
  function handleUpload (event) {
    setFile(event.target.files[0]);
    object1Url = URL.createObjectURL(event.target.files[0]);
    setPreview1(object1Url);
  }
  const handleProfileSetupSubmit = async (e) => {
    
    e.preventDefault();
    const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
    if (file && !validImageTypes.includes(file.type)) {
      toast.error(t("image_only"), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    } else {
      const currentUserDetail =
        localStorage.getItem("currentUser") &&
        localStorage.getItem("currentUser") !== "null"
          ? JSON.parse(localStorage.getItem("currentUser"))
          : "";
      const formData = new FormData();
      formData.append("customer_id", currentUserDetail.id);
      formData.append("profile_image", file);
      formData.append("name", jQuery("#full_name").val());
      formData.append("email", jQuery("#email").val());
      formData.append("country_code", jQuery("#country_code").val());
      formData.append("mobile", jQuery("#mobile").val());
      formData.append("dob", jQuery("#dob").val());
      formData.append("gender", jQuery("#gender").val());
      formData.append("referral_code", jQuery("#referral_code").val());
      let frm = document.getElementById("profileSetupForm");
      let apiProfileSetupUrl = baseUrl + "/profile/setup";
      const profileSetupResponse = await profileSetupApi({
        baseUrl: apiProfileSetupUrl,
        data: formData,
        currentLanguage: currentLanguage
      });
    //   console.log("profileSetupResponse", profileSetupResponse);
      if(profileSetupResponse){
        localStorage.setItem("currentUser", JSON.stringify(profileSetupResponse?.user));
        // setUserInfo(profileSetupResponse.user);
        if (profileSetupResponse?.token && profileSetupResponse?.token !== null) {
          localStorage.setItem(
            "currentToken",
            JSON.stringify(profileSetupResponse?.token)
          );
          handleProfileInfoModalClose();
          if(isProfileFromMobile){
            setIsAddressAdded(true);
          }else{
            setIsAddressAdded(true);
          }
         
      }
    }
       
      //   dispatch(
      //     profileSetupAction(formData, apiProfileSetupUrl, currentLanguage)
      //   );
      if (object1Url) URL.revokeObjectURL(object1Url);
    }
  };

  return (
    <>
    <div className="card p-3 mb-4">
    <form onSubmit={handleProfileSetupSubmit} id="profileSetupForm">
            <div className="userInfo mb-3 pt-0">
              <div className="profileImage">
                <img alt="" src={preview1} />
                <div className="file-upload">
                  <input
                    type="file"
                    accept="image/*"
                    name="profile_image"
                    onChange={handleUpload}
                    className="formControl"
                    placeholder="Password*"
                  />
                  <i className="fa fa-image"></i>
                </div>
              </div>
            </div>
            <div className="profileForm" id="profileForm">
              <div className="row">
                <input
                  type="hidden"
                  id="profile_customer_id"
                  name="customer_id"
                />
                <div className="col-lg-12 inputdiv mb-2">
                  <input
                    type="text"
                    className="form-control pl-0"
                    name="name"
                    id="full_name"
                    defaultValue={userSignUpName}
                    placeholder={t("full_name")}
                  />
                  <div className="help-block"></div>
                </div>
                <div className="col-lg-12 inputdiv mb-2">
                  <input
                    type="email"
                    className="form-control pl-0"
                    defaultValue={userSignUpEmail}
                    onChange={(e) => {setUserSignUpEmail(e.target.value)}}
                    disabled={!isProfileFromMobile}
                    name="email"
                    id="email"
                    placeholder={t("email")}
                  />
                  <div className="help-block"></div>
                </div>
                <div className="row loginMobileInput mb-2">
                  <div className="inputgroup phonecodeselect col-lg-5">
                    <ReactPhoneInput
                      inputProps={{
                        className: "login_country_code form-control",
                        name: "country_code",
                        id: "country_code",
                        autoFocus: true,
                        placeholder: t("enter_mobile_number"),
                        readOnly: "readonly"
                      }}
                      country={userCountryCode.toLocaleLowerCase()}
                      localization={currentLanguageCode === "en" ? es : ar}
                      countryCodeEditable={false}
                      placeholder=""
                      disableCountryCode={false}
                      searchClass="search-class"
                      searchStyle={{
                        margin: "0",
                        width: "97%",
                        height: "30px"
                      }}
                      enableSearch
                      disableSearchIcon
                    />
                    <div className="help-block text-danger"></div>
                  </div>
                  <div className="inputgroup phonecodeselect col-lg-7">
                    <input
                      type="number"
                      name="mobile"
                      id="mobile"
                      className="form-control rounded-pill"
                      defaultValue={userMobile}
                      onChange={(e) => {setUserMobile(e.target.value)}}
                      disabled={isProfileFromMobile}
                      placeholder={t("enter_mobile_number")}
                    />
                    <div className="help-block text-danger"></div>
                  </div>
                </div>
                <div className="col-lg-12 inputdiv mb-2">
                  <label>{t("date_of_birth_optional")}</label>
                  <input
                    type="date"
                    name="dob"
                    id="dob"
                    placeholder={t("enter_your_dob")}
                    className="form-control pl-0"
                    min="1965-01-01"
                    max="2020-12-31"
                  />
                  <div className="help-block"></div>
                </div>
                <div className="col-lg-12 inputdiv mb-2">
                  <label>{t("gender")}</label>
                  <select
                    className="form-select pl-0 genderSelect"
                    defaultValue={"Mr"}
                    name="gender"
                    id="gender"
                  >
                    <option value="Mr.">{t("gender_mr")}</option>
                    <option value="Ms.">{t("gender_ms")}</option>
                    <option value="Rather Not Say">
                      {t("gender_rather_not_say")}
                    </option>
                  </select>
                </div>
                <div className="col-lg-12 inputdiv mb-2">
                  <label>{t("referral_code_optional")}</label>
                  <input
                    type="text"
                    name="referral_code"
                    id="referral_code"
                    placeholder={t("enter_referral_code")}
                    className="form-control pl-0"
                  />
                </div>
                <div className="inputdiv col-lg-12 mt-3 mb-0">
                  <button className="btn btn-primary w-full">
                    {t("save_profile")}
                  </button>
                </div>
              </div>
            </div>
          </form>
      {/* <Modal
        show={profileInfoModal}
        onHide={handleProfileInfoModalClose}
        className="addAddressModal"
        id="ProfileSetup"
      >
      
        <Modal.Body>
          
        </Modal.Body>
      </Modal> */}
      </div>
    </>
  );
};

export default ProfileSetupModal;
