import React from "react";
import $ from 'jquery'; 

class Application extends React.Component {
    constructor(){
      super()
      this.scroll = this.scroll.bind(this)
    }
  
  
    scroll(direction){
      let far = $( '.image-container' )?.width()/2*direction;
      let pos = $('.image-container')?.scrollLeft() + far;
      $('.image-container')?.animate( { scrollLeft: pos }, 1000)
    }
  
    render() {
      return (
        <>
     
     
      <div className="scrollslider">
     
        <div className="wrapper">
          <a className="prev" onClick={this.scroll.bind(null,1)}><i class='fa fa-angle-right' aria-hidden='true'></i></a>
          
          <div className="image-container">
            
            <div class="serviceSlider"><span><img  src="https://dvdljkkxpxqo3.cloudfront.net/services/1656233334221-285719-1600x1066-lucky-bamboo-in-home.jpg.webp" alt="window"/></span><h3>Home Cleaning</h3><p>The Best home cleaning service.</p><button class="btn btn-primary"></button></div>
            
            
            <div class="serviceSlider"><span><img  src="https://dvdljkkxpxqo3.cloudfront.net/services/1656233334221-285719-1600x1066-lucky-bamboo-in-home.jpg.webp" alt="window"/></span><h3>Home Cleaning</h3><p>The Best home cleaning service.</p><button class="btn btn-primary">Book Now</button></div>
         
           
            <div class="serviceSlider"><span><img  src="https://dvdljkkxpxqo3.cloudfront.net/services/1656233334221-285719-1600x1066-lucky-bamboo-in-home.jpg.webp" alt="window"/></span><h3>Home Cleaning</h3><p>The Best home cleaning service.</p><button class="btn btn-primary">Book Now</button></div>
           
          
            <div class="serviceSlider"><span><img  src="https://dvdljkkxpxqo3.cloudfront.net/services/1656233334221-285719-1600x1066-lucky-bamboo-in-home.jpg.webp" alt="window"/></span><h3>Home Cleaning</h3><p>The Best home cleaning service.</p><button class="btn btn-primary">Book Now</button></div>
         
        
            <div class="serviceSlider"><span><img  src="https://dvdljkkxpxqo3.cloudfront.net/services/1656233334221-285719-1600x1066-lucky-bamboo-in-home.jpg.webp" alt="window"/></span><h3>Home Cleaning</h3><p>The Best home cleaning service.</p><button class="btn btn-primary">Book Now</button></div>
        
         
            <div class="serviceSlider"><span><img  src="https://dvdljkkxpxqo3.cloudfront.net/services/1656233334221-285719-1600x1066-lucky-bamboo-in-home.jpg.webp" alt="window"/></span><h3>Home Cleaning</h3><p>The Best home cleaning service.</p><button class="btn btn-primary">Book Now</button></div>
         
        
            <div class="serviceSlider"><span><img  src="https://dvdljkkxpxqo3.cloudfront.net/services/1656233334221-285719-1600x1066-lucky-bamboo-in-home.jpg.webp" alt="window"/></span><h3>Home Cleaning</h3><p>The Best home cleaning service.</p><button class="btn btn-primary">Book Now</button></div>
            <div class="serviceSlider"><span><img  src="https://dvdljkkxpxqo3.cloudfront.net/services/1656233334221-285719-1600x1066-lucky-bamboo-in-home.jpg.webp" alt="window"/></span><h3>Home Cleaning</h3><p>The Best home cleaning service.</p><button class="btn btn-primary">Book Now</button></div>
            <div class="serviceSlider"><span><img  src="https://dvdljkkxpxqo3.cloudfront.net/services/1656233334221-285719-1600x1066-lucky-bamboo-in-home.jpg.webp" alt="window"/></span><h3>Home Cleaning</h3><p>The Best home cleaning service.</p><button class="btn btn-primary">Book Now</button></div>
            <div class="serviceSlider"><span><img  src="https://dvdljkkxpxqo3.cloudfront.net/services/1656233334221-285719-1600x1066-lucky-bamboo-in-home.jpg.webp" alt="window"/></span><h3>Home Cleaning</h3><p>The Best home cleaning service.</p><button class="btn btn-primary">Book Now</button></div>
        
          
          </div>
          <a className="next" onClick={this.scroll.bind(null,-1)}><i class='fa fa-angle-left' aria-hidden='true'></i></a>
        </div>
      </div>
      </>
      );
    }
  }
  
export default Application;