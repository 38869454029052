import React from 'react';
import { Upload } from 'antd';

class AddImage extends React.Component {
 
  render(){

    //Uploaded url
    function getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }
    

    class PicturesWall extends React.Component {
      state = {
        previewVisible: false,
        previewImage: '',
        // previewTitle: '',
        fileList: [
          
        ],
      };
    
      handleCancel = () => this.setState({ previewVisible: false });
      //Image Preview
      handlePreview = async file => {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
    
        this.setState({
          previewImage: file.url || file.preview,
          previewVisible: true,
          previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
      };
    
      handleChange = ({ fileList }) => this.setState({ fileList });
    
      render() {
        const { previewVisible, previewImage, fileList, previewTitle } = this.state;
        const uploadButton = (
          <div className='mt-3'>
            <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/camera.png.webp"/>
           </div>
        );
        return (
          <>
            <Upload
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              listType="picture-card"
              fileList={fileList}
              onPreview={this.handlePreview}
              onChange={this.handleChange}  >
              {fileList.length >= 8 ? null : uploadButton}
            </Upload>
           
          </>
        );
      }
    }


    return (
    <div className="MainDiv">
     
      
      <div className="container">
          
          <PicturesWall />
        </div>
      </div>
  );
}
}

export default AddImage;