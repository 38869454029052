import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Modal from "../../components/Modal";
import { useTranslation } from 'react-i18next';
import {NavLink, Link} from "react-router-dom";
import PackageSlider from "./PackageSlider";
import Faq from "./Faq";
import Loader from "../../components/Loader";
import {useDispatch, useSelector} from "react-redux";
import { getSubscriptionPlanAction } from "../../actions/subscriptionPlanAction";

const CreditPackages = () => {
  const getSubscriptionState = useSelector(state => state.getSubscriptionReducer)
  const { loading, subscription_list } = getSubscriptionState;
  const dispatch = useDispatch();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let listUrl = baseUrl + "/subscription/plan/list";
  const currentLanguage = localStorage.getItem('currentLanguage') ? localStorage.getItem('currentLanguage') : 'en';
  useEffect(() => {
      dispatch(getSubscriptionPlanAction(listUrl,{}));
  }, [listUrl]);
  const { t } = useTranslation();

  return (
    <>
      {loading ? <Loader /> : ""}
      <div className="py-5">
        <div className="userContainer">
          <div className="creditPackage">
          
            <div className="card">
             
              <PackageSlider/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreditPackages;
