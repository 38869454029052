export const footerDataReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_FOOTER_DATA_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_FOOTER_DATA_SUCCESS':
            return {
                services:action.payload.services,
                banner:action.payload.banner,
                loading: false,
                success: true
            }
        case'GET_FOOTER_DATA_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        default:
            return state
    }
}