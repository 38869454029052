import React, {useEffect, useRef, useState, useCallback} from "react";
import {Navigation, Pagination, Scrollbar, A11y} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import {Link} from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import { getHomeMainService} from "../actions/homeAction";
// import Loader from "./Loader";
import LoginSignupModal from "./LoginSignup/LoginSignupModal";
import {toast} from "react-toastify";
import {Callbacks} from "jquery";
import Header from "./Header";
import Banner from "./Banner";
import ServiceBanner from "./ServiceBanner";
import EmergencyCard from "./EmergencyCard";
import Blog from "./Blog";
import LifeEasy from "./LifeEasy";
import ManyReason from "./ManyReason";
import Testimonilas from "./Testimonilas";
import AppDownload from "./AppDownload";
import Footer from "./Footer";
import ScreenedProfessionals from "./ScreenedProfessionals";
import HappynessGuarantee from "./HappynessGuarantee";
import NowCheck from "./NowCheck";
import ProblemsNone from "./ProblemsNone";
import FullImageSlider from "./FullImageSlider";

const AllSwiperSlider = ({serviceName = false}) => {
  const {serviceId} = useParams();
  const {t} = useTranslation();
  let navigate = useNavigate();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  const dispatch = useDispatch();
  const loginSignupRef = useRef();
  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  const getAllServicesState = useSelector((state) => state.homeReducer);
  const allStates = useSelector((state) => state);
  const {loading, servicesResult, services, success} = getAllServicesState;
  const [subServices, setSubService] = useState([]);
  const [abort, setAbort] = React.useState(() => {});
  const [serviceResultStatus, setServiceResultStatus] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    setAbort(abortController.abort);
    const countryID = localStorage.getItem("userCountryId");
    let listMainUrl =
      baseUrl +
      `/home/dashboard?country_id=${localStorage.getItem("userCountryId")}`;
    if (countryID) {
      dispatch(getHomeMainService(listMainUrl));
    }
    return () => {
      abort();
    }
  }, []);

  useEffect(() => {
    if (serviceId) {
      const ser = servicesResult?.filter(
        (f) => f?.service_type_id == serviceId
      );
      setSubService(ser);
    }
  }, [serviceId, servicesResult]);

  async function viewServiceDetails(
    id,
    ui_type,
    subServicesCount,
    rhClub,
    serviceName
  ) {
    if (currentUser) {
      if (ui_type === "Frequency" || ui_type === "Wheel") {
        if (localStorage.getItem("frequencyAppointmentData") !== null) {
          localStorage.removeItem("frequencyAppointmentData");
        }
        if (localStorage.getItem("frequencyCheckoutData") !== null) {
          localStorage.removeItem("frequencyCheckoutData");
          localStorage.removeItem("bookAppointmentId");
        }
        localStorage.setItem("frequencyTypeServiceDetailId", id);
        localStorage.setItem("rhClub", rhClub);
        localStorage.setItem("serviceName", serviceName);
      } else {
        if (
          localStorage.getItem("serviceDetailId") &&
          localStorage.getItem("serviceDetailId") !== id
        ) {
          localStorage.removeItem("bookAppointmentCartData");
          localStorage.removeItem("bookAppointmentDetails");
        }
        localStorage.setItem("serviceDetailId", id);
        localStorage.setItem("rhClub", rhClub);
      }
      setTimeout(() => {
        navigate(`/frequency/checkout/details`, {
          state: {
            serviceId: id,
            isBookNow: false,
            isLogin: currentUser ? true : false,
            ui_type: ui_type
          }
        });
        // navigate(
        //   ui_type === "Frequency" || ui_type === "Wheel"
        //     ? "/frequency/checkout/details"
        //     : ui_type === "Package" && subServicesCount === 0
        //     ? "/"
        //     : "/service/checkout/details?step=1&service_id=" + id
        // );
        window.scrollTo(0, 0);
      }, 500);
      if (ui_type === "Package" && subServicesCount === 0) {
        toast.error(t("no_services_available"), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      }
      //   if(ui_type === "Frequency"){
      //     navigate('/frequency/checkout/details');
      //   } else if (ui_type === "Package"){
      //     if(subServicesCount === 0){
      //       navigate("/")
      //       toast.error( t("no_services_available"), {
      //             position: "top-center",
      //             autoClose: 5000,
      //             hideProgressBar: false,
      //             closeOnClick: true,
      //             pauseOnHover: true,
      //             draggable: true,
      //             progress: undefined,
      //           });
      //     } else {
      //       navigate('/service/checkout/details?step=1&service_id='+id)
      //     }
      //   } else if(ui_type === "Wheel"){
      //     if(subServicesCount === 0){
      //       navigate("/")
      //       toast.error( t("no_services_available"), {
      //             position: "top-center",
      //             autoClose: 5000,
      //             hideProgressBar: false,
      //             closeOnClick: true,
      //             pauseOnHover: true,
      //             draggable: true,
      //             progress: undefined,
      //           });
      //   }
      // }
      //   })
    }
    // else{
    //    loginSignupRef.current.handleLoginSignupModalShow();
    // }
    if (currentUser === false) {
      if (ui_type === "Frequency") {
        if (localStorage.getItem("frequencyAppointmentData") !== null) {
          localStorage.removeItem("frequencyAppointmentData");
        }
        localStorage.setItem("frequencyTypeServiceDetailId", id);
        localStorage.setItem("rhClub", rhClub);
        localStorage.setItem("serviceName", serviceName);
      } else {
        if (
          localStorage.getItem("serviceDetailId") &&
          localStorage.getItem("serviceDetailId") !== id
        ) {
          localStorage.removeItem("bookAppointmentCartData");
          localStorage.removeItem("bookAppointmentDetails");
        }
        localStorage.setItem("serviceDetailId", id);
        localStorage.setItem("rhClub", rhClub);
      }
      // setTimeout(() => {
        navigate(`/frequency/checkout/details?step=2`, {
          state: {
            serviceId: id,
            isBookNow: false,
            isLogin: currentUser ? true : false,
            ui_type: ui_type
          }
        });
        window.scrollTo(0, 0);
      // }, 500);
      //  loginSignupRef.current.handleLoginSignupModalShow();
    }
  }

  // const handlerChangeService =(e) => {

  //   e.preventDefault();
  //   if(e.target.value === '') {
  //     let url =`https://betaapi.myrighth.com/api/v1/home/dashboard?country_id=${localStorage.getItem('userCountryId')}`;
  //     dispatch( getHomeMainService(url));

  //   } else {
  //   let serviceName= e.target.value.toUpperCase();
  //  let url =`https://betaapi.myrighth.com/api/v1/home/dashboard?country_id=${localStorage.getItem('userCountryId')}&name=${serviceName}`;
  //     dispatch( getHomeMainService(url));

  //   }
  // };
  return (
    <>
      <Header />
      <Banner />
      {/* <LoginSignupModal ref={loginSignupRef}/>  */}
      <div className="serviceBanner w-full pt-7 " id="serviceShow">
        <div className="heading">
          <h2>{t("you_name_it_we_do_it")}</h2>
          <p>
            {t("pick_any_of_the_services_below")} {serviceName}
            <Link to="/">Choose Main Service</Link>
          </p>
          {/* <div className="bannerSearch inputdiv ">
            <input type='text' className="form-control" onChange={(e) => handlerChangeService(e)}/>
            <span className="fa fa-search"></span>
            </div> */}
        </div>
        {success ? 
        <div className="container">
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={5}
            slidesPerView={6}
            navigation
            pagination={{clickable: true}}
            scrollbar={{draggable: true}}
            onSwiper={(swiper) => {}}
            onSlideChange={() => {}}
            breakpoints={{
              0: {
                slidesPerView: 2
              },
              640: {
                slidesPerView: 2
              },
              768: {
                slidesPerView: 4
              },
              810: {
                slidesPerView: 5
              },
              1200: {
                slidesPerView: 6
              }
            }}
          >
            {subServices?.length > 0
              ? subServices.map((service, index) => {
                  return (
                    <SwiperSlide key={index}>
                      {/* <div className="serviceSlider" onClick={() => viewMainServiceDetails(service)}> */}
                      <div
                        className="serviceSlider"
                        onClick={() =>
                          viewServiceDetails(
                            service._id,
                            service.ui_type,
                            service.serviceCategoryCount,
                            service.rhClub,
                            service.name
                          )
                        }
                      >
                        <span>
                          {/* <img
                            src={
                              service?.icon
                                ? service?.icon
                                : "https://dvdljkkxpxqo3.cloudfront.net/images/no_image.jpg.webp"
                            }
                            alt="window"
                          /> */}
                        </span>
                        <h3>
                          {currentLanguage === "en"
                            ? service.name
                            : service.name_arabic}
                        </h3>
                        {/*<p>{ currentLanguage === 'en' ? service.description : service.description_arabic  }</p> */}
                        <button className="btn btn-primary">
                          {/* {t("book_now")} */}
                        </button>
                      </div>
                    </SwiperSlide>
                  );
                })
              : ""}
          </Swiper>
        </div> : null }
      </div>

      <ServiceBanner />
      <EmergencyCard />
      <FullImageSlider />
      <ScreenedProfessionals />
      <HappynessGuarantee />
      <Blog />
      <LifeEasy />
      <NowCheck />
      <ProblemsNone />
      <ManyReason />
      <Testimonilas />
      <AppDownload />

      <Footer />
    </>
  );
};
export default AllSwiperSlider;
