import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import {NavLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import { getRHClubAction } from "../../actions/homeAction";
import { buyMemberShipAction } from "../../actions/buyMemberShipAction";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Modal from "../../components/Modal";
import Loader from "../../components/Loader";
import {customerCreditCardListsAction} from "../../actions/customerDetails";
import { BuyOnline } from "../../helpers/bookingAPIsteps";
import { useCountryCurrency } from "../../helpers/useCountryCurrency";
import { hasStripePayment } from "../../helpers/helpers";

const BuyMemberShip = ({from, setIsBuyMemberShip}) => {
  const dispatch = useDispatch();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;

  const currentUser =
  localStorage.getItem("currentUser") &&
  localStorage.getItem("currentUser") !== "null"
    ? JSON.parse(localStorage.getItem("currentUser"))
    : false;
  const { t } = useTranslation();
  const [pageLoading, setPageLoading] = useState(false);
  const [useCard, setUseCard] = useState('');
  const getRHClubState = useSelector((state) => state.rhClubReducer);
  const {  rh_club, loading } = getRHClubState;
  const getAllCountryState = useSelector((state) => state.countryReducer);
  const {country_list} = getAllCountryState;
  const countryCurrency = useCountryCurrency();
  const getCustomerAllCreditCardState = useSelector(state => state.customerDetailsReducer);
  const { customerCreditCards} = getCustomerAllCreditCardState;
  let rhClubUrl = baseUrl+"/country/edit/"+((localStorage.getItem('userCountryId')) ? localStorage.getItem('userCountryId') : "");
  useEffect(() => {
    dispatch(getRHClubAction(rhClubUrl));
  }, [rhClubUrl,dispatch]);

  const buyMemberShip = async(e) => { 
    let onlinePayUrl = `${baseUrl}/appointment/generate/payment/link/`
  // console.log("onlinePayUrl", price);
  
    const data ={
      "amount": countryCurrency?.length > 0 ? countryCurrency[0]?.rhPrice : 1000,
      "name": currentUser?.name,
      "phone":`${currentUser?.country_code}${currentUser?.mobile}`,
      "email": currentUser.email,
      "reference_no":  "",
      "custom":"memberShip",
       isStripe: hasStripePayment(localStorage.getItem("userCountryId"), country_list)
      ? true
      : false
   }
    try{
      const stepOneData = await BuyOnline({
        baseUrl: onlinePayUrl,
        data
      });
    if(stepOneData?.data){
      window.location.href = stepOneData?.data?.payUrl;
    }

    
     
     
     
      //  setPayUrl(stepOneData?.data?.payUrl)
      // setModalVisible(true)
    }catch (error) {
      // Handle errors here
      console.error("stepOneData-error", error);
    }
    // let buyMemberShipUrl = baseUrl+"/customer/buy-rhclub-membership";
   
    // let rh_club_id = localStorage.getItem('userCountryId');
    // // console.log("rh_club_id", rh_club_id);
    // let values = {
    //   rh_club_id: rh_club_id,
    //   source_id: useCard
    // } 
    // dispatch(buyMemberShipAction(buyMemberShipUrl,values,setPageLoading, from, setIsBuyMemberShip));
  }

  let listUrl = baseUrl + "/payment/instruments"; 
  useEffect(() => {
    dispatch(customerCreditCardListsAction());
  }, [listUrl,dispatch]);

  useEffect(() => {
    if(customerCreditCards ){
      setTimeout(() => {
        setUseCard(customerCreditCards?.id);
      }, 2000);
    }
  }, [customerCreditCards,setUseCard]);

  return (
    <>
      <Header/>  
      {loading || pageLoading ? <Loader /> : ""}
      <div className="py-5">
        <div className="userContainer">
          <div className="rightHandCredit">
            <div className="heading">
              <h2>{t('buy_membership')}</h2> 
            </div>
            <div className="card rhClubMember creditCard">
            <div className="personalForm">
           <div>
           <div className="creditHeading">
            <h5>{t('select_card')}</h5> 
            {!customerCreditCards &&
              (<NavLink 
                to="/my-account/add-credit-card"
                state={{ redirect: "/buy/membership"}}
                className="btn btn-primary">

                <i className="fa fa-plus me-2"></i>{ t('add_card') }
              </NavLink>) }
                </div>
                {customerCreditCards ?
                      <div className="list-group-item" key={customerCreditCards.id}>
                        <div className="form-check">                    
                          <input
                            type="radio"
                            className="form-check-input useCardPayment PaymentByCard"
                            id={'PaymentByCard_'+customerCreditCards.id}
                          
                            name="cardNo"
                           defaultChecked={customerCreditCards?.id}
                          />
                          <div className="ms-4">
                            <label className="form-check-label" htmlFor={customerCreditCards.id}>
                            ...{customerCreditCards?.last4}
                            </label>
                            <p>{customerCreditCards?.expMonth+'/'+customerCreditCards?.expYear}</p>
                          </div>                    
                        </div>  
                      </div>      
                   
                    :<>{<center>{t('no_card')}</center>}</>}
                </div>
                <div className="inputdiv col-lg-12 mb-0">
                  {customerCreditCards  ?
                  <button onClick={() => buyMemberShip()} className="btn btn-primary w-full">{t('buy_now')}</button>
                  :
                   "" }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal />
      <Footer />
    </>
  );
}

export default BuyMemberShip;