import React, { useState } from "react";
import Modal from "../../../components/Modal";
import ServicePage from "./ServicePage";
import {Link} from "react-router-dom";

const TabsServicePage = () => {
    const [toggleState, setToggleState] = useState(3);
    const toggleTab = (index) => {
      setToggleState(index);
    };
  return (
    <>
    <div className='serviceMain'>
    <div className='servicepageFooter'>
    <div className='container'>
    <ul>
        <li className={ toggleState === 1 ? "serviceNavLink serviceNavLink-active" : "serviceNavLink" }
           
          >
      
        <Link to="/my-account/appointments">
        <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/service/calendar.png.webp" />
        <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/service/calendar-active.png.webp" className='navImgActive' />
        <span>Bookings</span>
        </Link>
         
        </li>
        <li className={ toggleState === 2 ? "serviceNavLink serviceNavLink-active" : "serviceNavLink"  }
           data-bs-target="#share" data-bs-toggle="modal"
          >
      
        <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/service/invite.png.webp" />
        <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/service/invite-active.png.webp" className='navImgActive' />
        <span>Invite & Earn</span>
     
        </li>
        <li className={ toggleState === 3 ? "serviceNavLink serviceNavLink-active" : "serviceNavLink " }
            onClick={() => toggleTab(3)}
          >
        
        <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/service/plus.png.webp" />
        <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/service/plus-active.png.webp" className='navImgActive' />
        <span>Services</span>
         
        </li>
        <li className={ toggleState === 4 ? "serviceNavLink serviceNavLink-active" : "serviceNavLink" }
             data-bs-target="#righthandsClub" data-bs-toggle="modal"
          >
        
        <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/service/Rh.png.webp" />
        <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/service/Rh-active.png.webp" className='navImgActive' />
        <span>RH Club</span>
      
        </li>
        <li className={ toggleState === 5 ? "serviceNavLink serviceNavLink-active" : "serviceNavLink" }
           
          >
     
        <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/service/more.png.webp" />
        <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/service/more-active.png.webp" className='navImgActive' />
        <span>More</span>
  
        </li>
    </ul>
    </div>
</div>
<div className="servicePageTab">
      {/* <div
        className={
          toggleState === 1 ? "servicePagetabContent servicePageTabActive" : "servicePagetabContent"
        }
      >
         1
      </div> */}

      {/* <div
        className={
          toggleState === 2 ? "servicePagetabContent servicePageTabActive" : "servicePagetabContent"
        }
      >
         2
      </div> */}
      <div
        className={
          toggleState === 3 ? "servicePagetabContent servicePageTabActive" : "servicePagetabContent"
        }
      >
         <ServicePage/>
      </div>
      {/* <div
        className={
          toggleState === 4 ? "servicePagetabContent servicePageTabActive" : "servicePagetabContent"
        }
      >
         4
      </div> */}
      {/* <div
        className={
          toggleState === 5 ? "servicePagetabContent servicePageTabActive" : "servicePagetabContent"
        }
      >
         5
      </div> */}
</div>
</div>
<Modal/>
</>
  )
}

export default TabsServicePage