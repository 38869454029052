export const getTCReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_TC_LIST_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_TC_LIST_SUCCESS':
            return {
                tc_data:action.payload.tc_data,
                loading: false,
                success: true
            }
        case'GET_TC_LIST_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        case'GET_PRO_INFO_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_PRO_INFO_SUCCESS':
            return {
                pro_hour_info:action.payload.pro_hour_info,
                loading: false,
                success: true
            }
        case'GET_PRO_INFO_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }     
        case'GET_CLEANING_INFO_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_CLEANING_INFO_SUCCESS':
            return {
                cleaning_material_info:action.payload.cleaning_material_info,
                loading: false,
                success: true
            }
        case'GET_CLEANING_INFO_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }    
        case'GET_FAV_PRO_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_FAV_PRO_SUCCESS':
            return {
                fav_pro_info:action.payload.fav_pro_info,
                loading: false,
                success: true
            }
        case'GET_FAV_PRO_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }     
        case'GET_VISIT_TIME_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_VISIT_TIME_SUCCESS':
            return {
                start_time_info:action.payload.start_time_info,
                loading: false,
                success: true
            }
        case'GET_VISIT_TIME_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            } 
        case'GET_COVID_GL_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_COVID_GL_SUCCESS':
            return {
                covid_guidlines:action.payload.covid_guidlines,
                loading: false,
                success: true
            }
        case'GET_COVID_GL_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }                                                    
        default:
            return state
    }
}