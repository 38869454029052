import React from 'react'
import {Link } from 'react-router-dom';
const ServiceByCategorie = () => {
  return (
    <>
   <div className='py-5 ServiceByCategorie'>
   <div className='container max1060 '>
   <div className='row'>
    <Link to="/service"><span className='fa fa-angle-left'></span></Link>
   </div>
        <div className='row'>
            <div className='col-lg-12 mb-4'>
                <div className='serviceCatdiv'>
                    <img alt="" src='https://dvdljkkxpxqo3.cloudfront.net/images/whybg.jpg.webp'>                        
                    </img>
                    <h3>Furniture Cleaning</h3>
                </div>
            </div>
            <div className='col-lg-6 col-sm-6 mb-4'>
                <div className='serviceCatdiv'>
                    <img alt="" src='https://dvdljkkxpxqo3.cloudfront.net/images/whybg.jpg.webp'>                        
                    </img>
                    <h3>Furniture Cleaning</h3>
                </div>
            </div>
            <div className='col-lg-6 col-sm-6 mb-4'>
                <div className='serviceCatdiv'>
                    <img alt="" src='https://dvdljkkxpxqo3.cloudfront.net/images/whybg.jpg.webp'>                        
                    </img>
                    <h3>Furniture Cleaning</h3>
                </div>
            </div>
            <div className='col-lg-6 col-sm-6 mb-4'>
                <div className='serviceCatdiv'>
                    <img alt="" src='https://dvdljkkxpxqo3.cloudfront.net/images/whybg.jpg.webp'>                        
                    </img>
                    <h3>Furniture Cleaning</h3>
                </div>
            </div><div className='col-lg-6 col-sm-6 mb-4'>
                <div className='serviceCatdiv'>
                    <img alt="" src='https://dvdljkkxpxqo3.cloudfront.net/images/whybg.jpg.webp'>                        
                    </img>
                    <h3>Furniture Cleaning</h3>
                </div>
            </div>
            <div className='col-lg-6 col-sm-6 mb-4'>
                <div className='serviceCatdiv'>
                    <img alt="" src='https://dvdljkkxpxqo3.cloudfront.net/images/whybg.jpg.webp'>                        
                    </img>
                    <h3>Furniture Cleaning</h3>
                </div>
            </div>
            <div className='col-lg-6 col-sm-6 mb-4'>
                <div className='serviceCatdiv'>
                    <img alt="" src='https://dvdljkkxpxqo3.cloudfront.net/images/whybg.jpg.webp'>                        
                    </img>
                    <h3>Furniture Cleaning</h3>
                </div>
            </div>
        </div>
    </div>
   </div>
    </>
  )
}

export default ServiceByCategorie