

const initialState = {
  loading: true,
  users: [],
};

const saveAddressReducer = (state = initialState, action) => {
  switch (action.type) {
    case "INITIATE DATA": {
      return { ...state, loading: true };
    }
    case "DATA SUCCESS": {
      const obj =action.payload;
      return { ...state, dataObj: obj, loading: false };
    }
    case "DATA FAILED": {
      return { ...state, loading: false };
    }
    default:
      return state;
  }
};

export default saveAddressReducer;
