import React from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  InfoWindow,
  Marker,
  DirectionsRenderer
} from "react-google-maps";
import  { setKey, fromLatLng } from "react-geocode";
import Autocomplete from "react-google-autocomplete";
import jQuery from "jquery";
import axios from "axios";
/* const { MarkerWithLabel } = require("react-google-maps/lib/components/addons/MarkerWithLabel"); */
setKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
// Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
// Geocode.enableDebug();
class TrackingLocationModel extends React.Component {
  state = {
    currentLanguage: localStorage.getItem("currentLanguage")
      ? localStorage.getItem("currentLanguage")
      : "en",
    address: "",
    city: "",
    area: "",
    state: "",
    zoom: 15,
    height: 601,
    mapPosition: {
      // lat : parseFloat(this.props.newMarkers.lat),
      // lng :  parseFloat(this.props.newMarkers.lng)
      lat: localStorage.getItem("currentLatitude")
        ? parseFloat(localStorage.getItem("currentLatitude"))
        : 0.0,
      lng: localStorage.getItem("currentLongitude")
        ? parseFloat(localStorage.getItem("currentLongitude"))
        : 0.0
    },
    markerPosition: {
      // lat : parseFloat(this.props.newMarkers.lat),
      // lng :  parseFloat(this.props.newMarkers.lng)
      lat: localStorage.getItem("currentLatitude")
        ? parseFloat(localStorage.getItem("currentLatitude"))
        : 0.0,
      lng: localStorage.getItem("currentLongitude")
        ? parseFloat(localStorage.getItem("currentLongitude"))
        : 0.0
    }
  };

  componentDidMount() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.setState(
          {
            mapPosition: {
              // lat : parseFloat(this.props.newMarkers.lat),
              // lng :  parseFloat(this.props.newMarkers.lng)
              lat: localStorage.getItem("currentLatitude")
                ? parseFloat(localStorage.getItem("currentLatitude"))
                : position.coords.latitude,
              lng: localStorage.getItem("currentLongitude")
                ? parseFloat(localStorage.getItem("currentLongitude"))
                : position.coords.longitude
            },
            markerPosition: {
              // lat : parseFloat(this.props.newMarkers.lat),
              // lng :  parseFloat(this.props.newMarkers.lng)
              lat: localStorage.getItem("currentLatitude")
                ? parseFloat(localStorage.getItem("currentLatitude"))
                : position.coords.latitude,
              lng: localStorage.getItem("currentLongitude")
                ? parseFloat(localStorage.getItem("currentLongitude"))
                : position.coords.longitude
            },
          },
          () => {
           fromLatLng(
              position.coords.latitude,
              position.coords.longitude
            ).then(
              (response) => {
                const address = response.results[0].formatted_address,
                  addressArray = response.results[0].address_components,
                  city = this.getCity(addressArray),
                  area = this.getArea(addressArray),
                  state = this.getState(addressArray);
                this.setState({
                  address: address ? address : "",
                  area: area ? area : "",
                  city: city ? city : "",
                  state: state ? state : ""
                });
              },
              (error) => {
                console.error(error);
              }
            );
          }
        );
      });
      // Calculate directions when component mounts
    this.calculateDirections();

    } else {
      console.error("Geolocation is not supported by this browser!");
    }
  }
  calculateDirections = () => {
    const { mapPosition, markerPosition } = this.state;
    const DirectionsService = new window.google.maps.DirectionsService();

    DirectionsService.route(
      {
        origin: new window.google.maps.LatLng(
          mapPosition.lat,
          mapPosition.lng
        ),
        destination: new window.google.maps.LatLng(
          markerPosition.lat,
          markerPosition.lng
        ),
        travelMode: window.google.maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          this.setState({
            directions: result
          });
        } else {
          console.error(`Directions request failed: ${status}`);
        }
      }
    );
  };

  getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_2" === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  getArea = (addressArray) => {
    let area = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "sublocality_level_1" === addressArray[i].types[j] ||
            "locality" === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };

  getState = (addressArray) => {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };

  onChange = (event) => {
    this.setState({[event.target.name]: event.target.value});
  };

  onInfoWindowClose = (event) => {};

  onMarkerDragEnd = (event) => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();
    jQuery("#latitude").val(newLat);
    jQuery("#longitude").val(newLng);
    jQuery(".mapToogleDiv").toggleClass("addressDetailsHide");
    jQuery(".mapToggle").toggleClass("hide");
    jQuery(".mapToggle").toggleClass("show");
    var address = "";
    var country_long_name = "";
    var country_short_name = "";
   fromLatLng(newLat, newLng).then(
      (response) => {
        const addressArray = response.results[0].address_components;
        const newAddress = response.results;
        for (let j = 0; j < newAddress[0].address_components.length; j++) {
          if (
            newAddress[0].address_components[j] &&
            newAddress[0].address_components[j].types[0] !== "plus_code"
          ) {
            address += newAddress[0].address_components[j].long_name + ", ";
          }
          if (
            newAddress[0].address_components[j] &&
            newAddress[0].address_components[j].types[0] === "country"
          ) {
            country_long_name = newAddress[0].address_components[j].long_name;
            country_short_name = newAddress[0].address_components[j].short_name;
          }
        }
        const city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          state = this.getState(addressArray);
        this.setState({
          address: address ? address : "",
          area: area ? area : "",
          city: city ? city : "",
          state: state ? state : "",
          markerPosition: {
            lat: newLat,
            lng: newLng
          },
          mapPosition: {
            lat: newLat,
            lng: newLng
          }
        });
      },
      (error) => {
        console.error(error);
      }
    );
    setTimeout(function () {
      jQuery("#address").val(address);
      jQuery("#country").val(country_long_name);
      jQuery("#country_short_name").val(country_short_name);
    }, 500);
  };

  render() {
    const AsyncMap = withScriptjs(
      withGoogleMap((props) => (
        <GoogleMap
          defaultZoom={this.state.zoom}
          defaultCenter={{
            lat: parseFloat(this.props.customerGeoCode.latitude),
            lng: parseFloat(this.props.customerGeoCode.longitude)
          }}
        >
          <Marker
            google={this.props.google}
            name={"Dolores park"}
            draggable={true}
            onDragEnd={this.onMarkerDragEnd}
            position={{
              lat: this.state.mapPosition.lat,
              lng: this.state.mapPosition.lng
            }}
          />
          <Marker />
          {this.state.directions && (
            <DirectionsRenderer
              directions={this.state.directions}
            />
          )}
        </GoogleMap>
      ))
    );

    return (
      <div style={{margin: "0 auto", maxWidth: 1000}}>
        <AsyncMap
          googleMapURL={
            "https://maps.googleapis.com/maps/api/js?key=" +
            process.env.REACT_APP_GOOGLE_MAPS_API_KEY +
            "&libraries=places&language=" +
            this.state.currentLanguage
          }
          loadingElement={<div style={{height: `100%`}} />}
          containerElement={<div style={{height: this.state.height}} />}
          mapElement={<div style={{height: `100%`}} />}
        />
      </div>
    );
  }
}

export default TrackingLocationModel;
