import React from "react";
import Modal from "../../../../components/Modal";
import ServiceDetails from "./ServiceDetails";
import Steps from "../components/Steps";
import CheckoutSummary from "../components/CheckoutSummary";

export const Service = ({ 
  cart, updateCart,
  checkoutData,
  updateCheckoutData,
  appointment,
  addressInformation
}) => {
  return (
    <>
      <div className="">
        <Steps checkoutData={checkoutData}/>
      </div>
      <div className="stepsContent stepService ">
        <div className="row">
          <div className="col-lg-7 col-md-12">
            <div className="card">
              <div className="hairService">
                <ServiceDetails 
                  cart={cart} 
                  updateCart={updateCart} 
                  checkoutData={checkoutData}
                  updateCheckoutData={updateCheckoutData}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5">
            <CheckoutSummary 
              checkoutData={checkoutData}
              cart={cart}
              updateCheckoutData={updateCheckoutData}
              appointment={appointment}
            />
          </div>
        </div>
      </div>
      <Modal />
    </>
  );
};
export default Service;
