import React from 'react'
import { useTranslation } from 'react-i18next';

const AppDownload = () => {
    const { t } = useTranslation();
    
    return (
      <>
        <div className="container">
          <div className="rightHandsApp">
            <div className="row">
              <div className="col-lg-6">
                <div className="heading">
                  <h5>{ t('take_us_everywhere') }</h5>
                  <h2>{ t('get_the_right_hands') }</h2>
                  <p>
                    { t('book_and_manage') }
                  </p>
                </div>
                <div className=" burttonImage mt-5">
                  <a href={'https://play.google.com/store/apps/details?id=com.righthands'}>
                    <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/google-play.webp" />
                  </a>
                  <a href={'https://apps.apple.com/in/app/right-hands/id1641289996'}>
                    <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/app-store.webp" />
                  </a>
                </div>
              </div>
              <div className="col-lg-6 appImage ">
                <img alt="" src="/images/app-frame.webp"  width="280"/>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default AppDownload
