export const freeCreditDiscountApplyReducer = (state = {}, action) => {
    switch (action.type) {
        case'POST_FREECREDIT_DISCOUNT_APPLY_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'POST_FREECREDIT_DISCOUNT_APPLY_SUCCESS':
            return {
                discount:action.payload.discount,
                loading: false,
                success: true
            }
        case'POST_FREECREDIT_DISCOUNT_APPLY_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        default:
            return state
    }
}