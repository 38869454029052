import React from 'react'
import Modal from '../../../components/Modal'

const serviceHeader = () => {
  return (
    <>
        <div className='announcementArea'>
        <div className='container max1060'>
        <div className='announcementIcon' >
            <img alt="" src='https://dvdljkkxpxqo3.cloudfront.net/images/announcement.png.webp' data-bs-toggle="modal"
            data-bs-target="#announcements"/>
        </div>
        <p> <img alt="" src='https://dvdljkkxpxqo3.cloudfront.net/images/shield.png.webp' className='me-3'/>We've got you covered</p>
        </div>        
        </div>
       <Modal/>
    </>
  )
}

export default serviceHeader