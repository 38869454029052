import {Link} from "react-router-dom";
import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useTranslation } from 'react-i18next';

const BookingCancel = () => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <div className="py-5">
        <div className="userContainer">
          <div className="bookingCancel">
            <div className="heading">
              <h2>{t('booking_has_been_cancelled')}</h2> 
            </div>
            <div className="card">
              <div className="personalForm">
                <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/dollar.png.webp" />
                <h3>  {t('booking_has_been_cancelled')}
                  {t('booking_reference')} 23F426 {t('cancelled_on')} 16 Feb 2022 {t('is_cancelled')}
                </h3>
                <p> 
                  {t('any_paid_balance')}
                </p>
                <p>
                  {t('cancelled_your_booking_by_mistake')}
                </p>
                <Link to="/my-account/bookingCancel">
                  <button className="btn btn-primary w-full mb-0 mt-4">
                    {t('book_now')}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BookingCancel;
