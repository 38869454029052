import React, {useState, useEffect} from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  DirectionsRenderer,
  Marker,
  InfoWindow
} from "react-google-maps";
import Geocode, { setKey } from "react-geocode";

// Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
setKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
// Geocode.enableDebug();

const MapComponent = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={8}
      defaultCenter={props.startLocation || {lat: 0, lng: 0}}
    >
      {props.startLocation && <Marker position={props?.startLocation} />}
      {props.endLocation && <Marker position={props?.endLocation} />}
      {/* {props.directions && <DirectionsRenderer directions={props.directions} />} */}
      {props.directions && (
        <>
       
         <DirectionsRenderer
            directions={props.directions}
            options={{
              polylineOptions: {
                strokeColor:"#f58849",
              },
            }}
          />
          {props.estimatedTime && (
            <>
            <InfoWindow
              position={props?.startLocation}
              options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
            >
              <div>
                <strong>Estimated Time: {props.estimatedTime}</strong>
              </div>
            </InfoWindow>
            <InfoWindow
            position={props?.endLocation}
            options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
          >
            <div>
            
              <strong>Distance: {props.estimatedDistance}</strong>
            </div>
          </InfoWindow>
          </>
          )}
        </>
      )}
    </GoogleMap>
  ))
);

const TrackingModal = ({isOpen, onClose, startLocation, endLocation}) => {
  const [directions, setDirections] = useState(null);
  const [estimatedTime, setEstimatedTime] = useState(null);
  const [estimatedDistance, setEstimatedDistance] = useState(null);
  useEffect(() => {
    // if (isOpen) {
    // Calculate directions when the modal is opened
    calculateDirections();
  }, [startLocation, endLocation]);

  // Helper function to determine route color based on traffic duration
const getRouteColor = (durationInTraffic) => {
  // Customize this logic based on your preferences
  const normalDuration = 30; // Adjust this value based on your expected normal duration
  const trafficRatio = durationInTraffic?.value / normalDuration;

  // Example: Red for heavy traffic, green for normal, yellow for moderate
  const red = Math.min(255, Math.round(255 * trafficRatio));
  const green = Math.min(255, Math.round(255 * (1 - trafficRatio)));

  return `rgb(${red}, ${green}, 0)`;;
};

  const calculateDirections = () => {
    // console.log("startLocation", startLocation, "endLocation", endLocation);
    if (!startLocation || !endLocation) {
      console.error("Missing startLocation or endLocation");
      return;
    }
    const DirectionsService = new window.google.maps.DirectionsService(); // Create DirectionsService instance

    DirectionsService.route(
      {
        origin: startLocation,
        destination: endLocation,
        travelMode: window?.google?.maps?.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result);
          // console.log("Directions",  result.routes)
          const time = result.routes[0].legs[0].duration.text;
          setEstimatedTime(time);
          const distance = result.routes[0].legs[0].distance.text;
          setEstimatedDistance(distance);
        } else {
          console.error(`Directions request failed: ${status}`);
        }
      }
    );
  };

  return (
    <div style={{margin: "0 auto", maxWidth: 1000}}>
      <div style={{height: "80vh"}}>
        <MapComponent
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`}
          loadingElement={<div style={{height: `100%`}} />}
          containerElement={<div style={{height: `100%`}} />}
          mapElement={<div style={{height: `100%`}} />}
          startLocation={startLocation}
          endLocation={endLocation}
          directions={directions}
          estimatedTime={estimatedTime}
          estimatedDistance={estimatedDistance}
          getRouteColor={getRouteColor}
        />
      </div>
    </div>
  );
};

export default TrackingModal;
