import axios from 'axios'
import {toast} from "react-toastify";
import {authHeader, handlingNonauthErrors} from "../helpers/helpers";

export const getServiceDetailsAction = (baseUrl) => dispatch => {

    dispatch({type: 'GET_SERVICE_DETAILS_REQUEST'})

    axios.get(baseUrl,{
        headers: authHeader(),
    }).then((res) => {
        dispatch({type: 'GET_SERVICE_DETAILS_SUCCESS', payload: {
            service:res.data.service
        }});
    }).catch((err) => {
        dispatch({type: 'GET_SERVICE_DETAILS_FAILED', payload: err});
        handlingNonauthErrors(err)
    })
}