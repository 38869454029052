import axios from 'axios';
import {toast} from "react-toastify";
import {authHeader, handlingNonauthErrors} from "../helpers/helpers";

export const freeCreditApplyVoucherAction = (baseUrl,values,updateCheckoutData) => dispatch => {

    dispatch({type: 'POST_FREECREDIT_APPLY_VOUCHER_REQUEST'})
    axios({
        method: 'POST',
        headers:authHeader(),
        url: baseUrl,
        data: values
    }).then((res) => {
        toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setTimeout(() => {
            updateCheckoutData("promo_code","");
            updateCheckoutData("discount_type","");
            updateCheckoutData("voucher",values.voucher);
            updateCheckoutData("discountPrice",res.data.discount);
            dispatch({type: 'POST_FREECREDIT_APPLY_VOUCHER_SUCCESS', payload: {
                discount:res.data.discount
            }});
        }, 2000);
    }).catch((err) => {
        dispatch({type: 'POST_FREECREDIT_APPLY_VOUCHER_FAILED', payload: err});
        handlingNonauthErrors(err);
    })
}