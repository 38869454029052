export const supportTicketReducer = (state = {}, action) => {
    switch (action.type) {
        case'POST_CREATE_SUPPORT_TICKET_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'POST_CREATE_SUPPORT_TICKET_SUCCESS':
            return {
                ...state,
                loading: false,
                success: true
            }
        case'POST_CREATE_SUPPORT_TICKET_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        default:
            return state
    }
}