import React, { useState, useEffect } from "react";
import {Link} from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Modal from "../../components/Modal";
import {useDispatch, useSelector} from "react-redux";
import { getDiscountFreeCredit } from "../../actions/DiscountFreeCredit";
import { useTranslation } from 'react-i18next';
import Loader from "../../components/Loader";

const CreditDetails = () => {
  const getDiscountFreeDataState = useSelector(state => state.discountFreeReducer)
  const { loading, discountFree } = getDiscountFreeDataState;
  const dispatch = useDispatch();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let listUrl = baseUrl + "/discount/free/credit/list";
  const currentLanguage = localStorage.getItem('currentLanguage') ? localStorage.getItem('currentLanguage') : 'en';
  useEffect(() => {
      dispatch(getDiscountFreeCredit(listUrl,{}));
  }, [listUrl]);
  const { t } = useTranslation();

  return (
    <>
      <Header/>
      {loading ? <Loader /> : ""}
      <div className="py-5">
        <div className="userContainer">
          <div className="rightHandCredit creditDetails">
            <div className="heading">
              <h2>{t('credit_details')}</h2> 
            </div>
            <div className="card">
              <div className="creditExpiry">
                <h4>{t('total_credit')}</h4> 
                <h3>{discountFree?.list[0]?.currency} { discountFree?.totalCreditsEarned }</h3>
                <p>{t('any_available_credit_can_be_viewed_below')}</p> 
              </div>
              <div className="recentTranstion">
                {discountFree?.list?.length > 0 ?
                discountFree?.list?.map((discount_free, index) => { 
                  var options = { year: 'numeric', month: 'long', day: 'numeric' };
                  var expiryAt = new Date(discount_free?.expiry_at)?.toLocaleDateString([],options);
                  return (
                    <div className="transtionList">
                      <h3>
                        {discount_free?.details} <span>+{discount_free?.credit} {discount_free?.currency}</span>
                      </h3>       
                      <p>{t('expires_on')}  {(expiryAt!== "Invalid Date" && expiryAt)}</p>
                    </div>
                  )
                }): <span><center>{t('no_credit_card')}</center><br/></span>}

                <div className="transtionList creditTotald">
                  <h3>
                    {t('total')} 
                    <span> {discountFree?.list[0]?.currency} { discountFree?.totalCreditsEarned } </span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal />
      <Footer />
    </>
  );
};

export default CreditDetails;
