export const rescheduleAppointmentReducer = (state = {}, action) => {
    switch (action.type) {
        case'POST_APPOINTMENT_RESCHEDULE_REQUEST':
            return {
                ...state,
                page_loading: true,
            }
        case'POST_APPOINTMENT_RESCHEDULE_SUCCESS':
            return {
                appointmentsData:action.payload.appointmentsData,
                page_loading: false,
                success: true
            }
        case'POST_APPOINTMENT_RESCHEDULE_FAILED':
            return {
                ...state,
                page_loading: false,
                error: true
            }
        default:
            return state
    }
}