export const getFreeCreditReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_FREE_CR_LIST_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_FREE_CR_LIST_SUCCESS':
            return {
                free_cr_data:action.payload.free_cr_data,
                loading: false,
                success: true
            }
        case'GET_FREE_CR_LIST_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        default:
            return state
    }
}

export const getAllHistoryReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_ALL_HISTORY_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_ALL_HISTORY_SUCCESS':
            return {
                free_cr_data:action.payload.free_cr_data,
                loading: false,
                success: true
            }
        case'GET_ALL_HISTORY_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        default:
            return state
    }
}

export const getAllPaymentHistoryReducer  = (state = {}, action) => {
    switch (action.type) {
        case'GET_ALL_PAYMNET_HISTORY_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_ALL_PAYMENT_HISTORY_SUCCESS':
            return {
                ...state,
                payment_list:action.payload.appointment_list,
                loading: false,
                success: true
            }
        case'GET_ALL_PAYMENT_HISTORY_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        default:
            return state
    }
}