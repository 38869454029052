import React, {useRef, useState} from "react";
import {Link} from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LoginSignupModal from "../../components/LoginSignup/LoginSignupModal";
import jQuery from "jquery";
import {useDispatch} from "react-redux";
import {sendForgotPasswordMailAction} from "../../actions/userActions";
import {useTranslation} from "react-i18next";
import EmailInput from "../../helpers/EmailInput";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const loginSignupRef = useRef();
  const inputValueRef = useRef();
  const [emailId, setEmailId] = useState();
  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  // if (currentUser) {
  //   setTimeout(() => {
  //     window.location.href = "/";
  //   }, 1000);
  // }
  const handelInputChange = (e) => {
    const {value} = e.target;
    setEmailId(value);
  };

  const handleForgotPassword = (e) => {
    if(emailId){
      let forgotPassUrl =
      process.env.REACT_APP_LOCAL_API_URL + "/forgot/customer/password";
    // let email_id = jQuery('#email_id').val();

    let values = {email: emailId};
    dispatch(sendForgotPasswordMailAction(forgotPassUrl, values, t));
    setEmailId("");
    }else{
      let message =t("enter_email_address");
      toast.error(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
 
  };

  return (
    <>
      <Header />
      <LoginSignupModal ref={loginSignupRef} />
      <div className="py-5">
        <div className="userContainer forgotPassword">
          <div className="yourFeedback">
            <div className="heading">
              <h2>{t("forgot_password")}</h2>
              <p>{t("enter_registered_email_password_reset_link")}</p>
            </div>
            <div className="card p-0">
              <div className="personalForm">
                <div className="inputdiv col-lg-12 ">
                  <label>{t("email_address")}</label>
                  <EmailInput
                    type="email"
                    id="email_id"
                    name="email_id"
                    value={emailId ? emailId : ""}
                    onChange={(e) => handelInputChange(e)}
                    placeholder={t("email_address")}
                    maxLength="80"
                  />
                  {/* <input type="email" id="email_id" className="form-control" placeholder={t('enter_email_address')} ref={inputValueRef}/> */}
                </div>
                <div className="inputdiv col-lg-12 mt-3 mb-0">
                  <button
                    className="btn btn-primary w-full"
                    onClick={handleForgotPassword}
                  >
                    {t("submit")}
                  </button>
                </div>
                <h3 className="text-center pt-3">
                  <Link
                    to=""
                    onClick={() =>
                      loginSignupRef.current.handleLoginSignupModalShow()
                    }
                    className="text-center"
                  >
                    {t("login")}
                  </Link>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ForgotPassword;
