import React,{useState,useEffect} from 'react';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import { getAllPaymentHistoryAction } from "../../actions/FreeCreditAction";
import Pagination from "../../components/inc/pagination";
import {useSearchParams}from 'react-router-dom';

const PaymentHistory = () => {
  
  const [page, setPage] = useState(1);
  const [ searchParams, setSearchParams ] = useSearchParams();
  const pageTerm = searchParams.get('page') || '';
 
  const [ pages, setPages ] = useState(0);
 
  const paymentList = useSelector(state => state.allPaymnetHistoryReducer)
 const [appointmentPaymentData,setAppointmentPaymentData] = useState([]);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let listUrl =`${baseUrl}/appointment/customer/payment/list`;
  const currentLanguage = localStorage.getItem('currentLanguage') ? localStorage.getItem('currentLanguage') : 'en';
  useEffect(() => {
      dispatch(getAllPaymentHistoryAction(listUrl,{}));
  }, [listUrl]);
  var options = { year: 'numeric', month: 'long', day: 'numeric' };
  const {loading, payment_list, success} = paymentList;
  // useEffect(() => {
  //  if(success){
  // //   if(departmentData?.data?.length < 1 && departmentData?.currentPage >1 ) {
  // //     getAllDepartment(departmentData?.currentPage - 1);
  // //   }
  // //   setPages(departmentData?.totalPages ? departmentData?.totalPages : 1);
  // //  } 
   
  // }, [success])

  const paymentModeArray = [
    'Cash','Bank Transfer', 'Cheque', 'Gateway','Other','Cod', 'Card', 'Apple pay',
    'Online'];
 
  function changeHistory(e) {
    e.preventDefault();
    if(paymentModeArray.includes(e.target.value)){
      const dataB= payment_list?.filter((list)=>list.payment_mode === e.target.value)
  let listUrl  =  `${baseUrl}/appointment/customer/payment/list?payment_method=${e.target.value}&page=1&offset=10` 
      dispatch(getAllPaymentHistoryAction(listUrl,{}));    
    }
  }
    return(
        <>
        <Header />
        <div className="py-5">
        <div className="userContainer">
          <div className="rightHandCredit allhistory">
            <div className="heading">
              <h2>{t('Payment_History')}</h2> 
            </div>
            <div className="inputdiv mb-2 selectallhistory">
              <select className="form-select pl-0 selectallhistory" onChange={changeHistory}>
                {/* <option value={"credit"} >
                  {t("credit")}
                </option>
                <option value={"cash"} >
                  {t("Cash")}
                </option>
                <option value={"ApplePay"} >
                  {t("ApplyPay")}
                </option>
                <option value={"Cheque"} >
                  {t("Cash")}
                </option> */}
                {paymentModeArray.map(
                 (paymentType => <option value={paymentType }>{paymentType }</option>)
                )}
               
              </select>
            </div>

            <div className="card">
              <div className="recentTranstion"> 
              <h3><br/>
                {t('Payment_History')}
              </h3>
              <div>
              {payment_list?.length > 0 ?
                payment_list.map((payList, index) => {
                  return (
                    <>
                     <div className="transtionList" key={index}>
                     <h3>{payList.appointment_no} <span>{`-${payList.total_per_display_amount}`}</span></h3>
                     <p>{ new Date(payList.createdAt).toLocaleDateString([],options)}</p>
                    </div>
                    </>
                  
                  )
                  // // if(free_cr && free_cr.details && free_cr.type){
                  //   var options = { year: 'numeric', month: 'long', day: 'numeric' };
                  // <>
               
                  // {/* {console.log("111",payList.appointment_no)}
                  //   let created_at = new Date(payList.updatedAt).toLocaleDateString([],options); */}
                    
                  //     <p>{payList.appointment_no}</p>
                  // <p>new Date(payList.updatedAt).toLocaleDateString([],options)</p>
                  //   <div className="transtionList" key={index}>
                  //     <h3>
                  //     {payList?.appointment_no} <span>{'free_cr.type'  || 'Refunded' ? '+ ' : '- '+'free_cr.debit'} QAR</span>
                  //     </h3>
                  //     <p>{ new Date(payList.updatedAt).toLocaleDateString([],options)}</p>
                  //   </div>
                    
                 
                 
                }) : <span><center>{t('no_transaction_history')}</center><br/></span>}
                  <Pagination page={page} pages={pages} changePage={setPage} />
              </div>
              </div>
              {/* {(() => {
                if(page <= free_cr_data?.totalPages){
                  return (
                    <div className="col-12">
                      <p className="loadmore text-center" onClick={() => fetchMoreData()}>
                        {t('load_more')}
                      </p>
                    </div>
                  );
                }
              })()} */}
            </div>
          </div>
        </div>
      </div>
        <Footer />
        </>
    )
}

export default PaymentHistory;