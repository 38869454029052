import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {extendMemberShipAction} from "../../actions/extendMembershipAction";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Modal from "../../components/Modal";
import Loader from "../../components/Loader";
import {customerCreditCardListsAction} from "../../actions/customerDetails";
import {useNavigate} from "react-router-dom";
import {getRhValid} from "../../helpers/helpers";

const ExtendedMembership = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let listUrl = baseUrl + "/payment/instruments";
  const {t} = useTranslation();
  const [useCard, setUseCard] = useState("");
  const [pageLoading, setPageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [rhCreditDetails, setRhCreditDetails] = useState([]);
  const [wallet, setWallet] = useState();
  useEffect(() => {
    dispatch(customerCreditCardListsAction());
  }, []);

  const getCustomerAllCreditCardState = useSelector(
    (state) => state.customerDetailsReducer
  );
  const {customerCreditCards} = getCustomerAllCreditCardState;

  useEffect(() => {
    if (customerCreditCards?.length > 0) {
      setIsLoading(true);
      setUseCard(customerCreditCards[0]?.id);
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 4000);
    }
  }, [customerCreditCards, setUseCard]);

  const getAppointmentInfoState = useSelector(
    (state) => state.appointmentReducer
  );

  async function validRhCredit() {
    let data = {service_id: localStorage.getItem("serviceDetailId")};
    await getRhValid(data)
      .then((res) => {
        setRhCreditDetails(res.data);
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  }

  useEffect(() => {
    validRhCredit();
  }, []);

  let totalWalletAmount = rhCreditDetails.total_wallet_credits
    ? rhCreditDetails.total_wallet_credits
    : 0;

  let values = {
    additional_hours: localStorage.getItem("additional_hours"),
    source_id: useCard
  };
  let id = localStorage.getItem("appointmentDetailsId");

  let extendMemberShipUrl =
    baseUrl +
    `/appointment/extend/${id}?additional_hours=${localStorage.getItem(
      "additional_hours"
    )}`;

  const extendMemberShip = () => {
    dispatch(
      extendMemberShipAction(
        extendMemberShipUrl,
        values,
        setPageLoading,
        navigation
      )
    );
  };

  return (
    <>
      <Header />
      {pageLoading ? <Loader /> : ""}
      <div className="py-5">
        <div className="userContainer">
          <div className="rightHandCredit">
            <div className="heading">
              <h2>{t("Pay_For_Extend_Hrs")}</h2>
            </div>
            <div className="card rhClubMember creditCard">
              <div className="personalForm">
                <div>
                  <div className="creditHeading">
                    <h5>{t("select_card")}</h5>
                    {!customerCreditCards && (
                      <NavLink
                        to="/my-account/add-credit-card"
                        state={{redirect: "/buy/extendmembership"}}
                        className="btn btn-primary"
                      >
                        <i className="fa fa-plus me-2"></i>
                        {t("add_card")}
                      </NavLink>
                    )}
                  </div>

                  {customerCreditCards ? (
                    //  customerCreditCards.map((customerCreditCard, index) => {
                    //     return (
                    <div className="list-group-item" key={id}>
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input useCardPayment PaymentByCard"
                          id={"PaymentByCard_" + id}
                          // onChange={() => setUseCard(customerCreditCards.id)}
                          name="cardNo"
                          // defaultChecked={customerCreditCards?.id}
                        />
                        <div className="ms-4">
                          <label className="form-check-label" htmlFor={id}>
                            ...{customerCreditCards?.last4}
                          </label>
                          <p>
                            {customerCreditCards?.expMonth +
                              "/" +
                              customerCreditCards?.expYear}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    // )})
                    <>
                      {isLoading ? "loader" : <center>{t("no_card")}</center>}
                    </>
                  )}
                </div>
                <div className="switchFlex">
                  <label className="form-check-label" htmlFor="radio3">
                    <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/card.png.webp" />
                    {t("use_wallet")}{" "}
                    <span>{wallet ? wallet : totalWalletAmount}</span>
                  </label>
                  <div className="useMyCredit">
                    <label className="switch">
                      <input
                        type="checkbox"
                        className="usermycredit PaymentByWallet"
                        // onChange={(event) =>
                        //   event.target.checked
                        //     ? myPaymentMode("PaymentByWallet", true)
                        //     : myPaymentMode("PaymentByWallet", false)
                        // }
                        disabled={totalWalletAmount === 0}
                      />
                      <span className="switchslide round"></span>
                    </label>
                  </div>
                </div>
                <div className="inputdiv col-lg-12 mb-0">
                  {customerCreditCards ? (
                    <button
                      onClick={() => extendMemberShip()}
                      className="btn btn-primary w-full"
                    >
                      {t("pay_now")}
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal />
      <Footer />
    </>
  );
};

export default ExtendedMembership;
