import axios from 'axios'
import {authHeader, handlingAuthErrors, handlingNonauthErrors} from "../helpers/helpers";

export const myFavouriteProsAction = (baseUrl) => dispatch => {

    dispatch({type: 'GET_FAVOURITE_LIST_REQUEST'})
    axios({
        method: 'GET',
        headers:authHeader(),
        url: baseUrl,
        data: {}
    }).then((res) => {
        dispatch({type: 'GET_FAVOURITE_LIST_SUCCESS', payload: {
            favourites:res.data.result
        }});
    }).catch((err) => {
        dispatch({type: 'GET_FAVOURITE_LIST_FAILED', payload: err});
        handlingAuthErrors(err);
    })
}

export const unfavouriteProsAction = (baseUrl,values,handleShowUnfevClose) => dispatch => {

    dispatch({type: 'POST_UNFAVOURITE_REQUEST'});
    axios({
        method: 'POST',
        headers:authHeader(),
        url: baseUrl,
        data: values
    }).then((res) => {
        handleShowUnfevClose()
        dispatch({type: 'GET_FAVOURITE_LIST_SUCCESS', payload: {
            favourites:res.data.result
        }});
    }).catch((err) => {
        dispatch({type: 'POST_UNFAVOURITE_FAILED', payload: err});
        handlingAuthErrors(err);
    })
}