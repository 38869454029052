import React, { useState } from "react";
import { Upload } from "antd";
import {useDispatch, useSelector} from "react-redux";
import { uploadImagesAction } from "../actions/appointmentAction";

const UploadImages = ({setImageData}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const dispatch = useDispatch();
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file?.url.substring(file?.url.lastIndexOf("/") + 1)
    );
  };
  const handleChange = ({ fileList: newFileList }) => {setFileList(newFileList);
    dispatch(uploadImagesAction(newFileList))}

  //   const handlePreview = async (file) => {
  //     if (!file.url && !file.preview) {
  //       file.preview = await getBase64(file.originFileObj);
  //     }
  //   setState({
  //       previewImage: file.url || file.preview,
  //       previewVisible: true,
  //       previewTitle:
  //         file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
  //     });

  //   };
  const uploadButton = (
    <div className="mt-0 ms-2 me-2">
      <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/camera.png.webp" />
    </div>
  );

  return (
    <div className="MainDiv">
      <div className="container">
        <>
          <Upload
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
          >
            {fileList.length >= 5 ? null : uploadButton}
           
          </Upload>
        </>
      </div>
    </div>
  );
};

export default UploadImages;
