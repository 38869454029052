import React from 'react';
import { withGoogleMap, GoogleMap, withScriptjs, Marker } from "react-google-maps";
import  { setKey, fromLatLng } from "react-geocode";
import Autocomplete from 'react-google-autocomplete';
import jQuery from 'jquery';
// Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
setKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
// Geocode.enableDebug();
class BookingLocation extends React.Component {
    state = {
        currentLanguage:(localStorage.getItem('currentLanguage')) ? localStorage.getItem('currentLanguage') : 'en',
        address: '',
        city: '',
        area: '',
        state: '',
        zoom: 15,
        height: 601,
        mapPosition: {
            lat: (localStorage.getItem('currentLatitude')) ? parseFloat(localStorage.getItem('currentLatitude')) : 0.0,
            lng: (localStorage.getItem('currentLongitude')) ? parseFloat(localStorage.getItem('currentLongitude')) : 0.0,
        },
        markerPosition: {
            lat: (localStorage.getItem('currentLatitude')) ? parseFloat(localStorage.getItem('currentLatitude')) : 0.0,
            lng: (localStorage.getItem('currentLongitude')) ? parseFloat(localStorage.getItem('currentLongitude')) : 0.0,
        }
    }
    componentDidMount() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
            
                this.setState({
                    mapPosition: {
                        lat: (localStorage.getItem('currentLatitude')) ? parseFloat(localStorage.getItem('currentLatitude')) : position.coords.latitude,
                        lng: (localStorage.getItem('currentLongitude')) ? parseFloat(localStorage.getItem('currentLongitude')) : position.coords.longitude
                    },
                    markerPosition: {
                        lat: (localStorage.getItem('currentLatitude')) ? parseFloat(localStorage.getItem('currentLatitude')) : position.coords.latitude,
                        lng: (localStorage.getItem('currentLongitude')) ? parseFloat(localStorage.getItem('currentLongitude')) : position.coords.longitude
                    }
                },
                    () => {
                        var address = '';
                        var country_long_name = '';
                        var country_short_name = '';
                      fromLatLng(position.coords.latitude, position.coords.longitude).then(
                            response => {
                                const addressArray = response.results[0].address_components;
                                const newAddress = response.results;
                                for (let j = 0; j < newAddress[0].address_components.length; j++) {
                                    if(newAddress[0].address_components[j] && newAddress[0].address_components[j].types[0] !== "plus_code"){
                                        address += newAddress[0].address_components[j].long_name+', ';
                                    }
                                    if(newAddress[0].address_components[j] && newAddress[0].address_components[j].types[0] === "country"){
                                        country_long_name = newAddress[0].address_components[j].long_name;
                                        country_short_name = newAddress[0].address_components[j].short_name;
                                    }
                                }
                                const city = this.getCity(addressArray),
                                    area = this.getArea(addressArray),
                                    state = this.getState(addressArray);
                                this.setState({
                                    address: (address) ? address : '',
                                    area: (area) ? area : '',
                                    city: (city) ? city : '',
                                    state: (state) ? state : '',
                                });
                                setTimeout(function () {
                                    jQuery('#address').val(address);
                                    jQuery('#country').val(country_long_name);
                                    jQuery('#country_short_name').val(country_short_name);
                                    jQuery('#latitude').val(position.coords.latitude);
                                    jQuery('#longitude').val(position.coords.longitude);
                                }, 500);
                            },
                            error => {
                                console.error(error);
                            }
                        );

                    })
            });
        } else {
            console.error("Geolocation is not supported by this browser!");
        }
    };

    getCity = (addressArray) => {
        let city = '';
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0]) {
                city = addressArray[i].long_name;
                return city;
            }
        }
    };

    getArea = (addressArray) => {
        let area = '';
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0]) {
                for (let j = 0; j < addressArray[i].types.length; j++) {
                    if ('sublocality_level_1' === addressArray[i].types[j] || 'locality' === addressArray[i].types[j]) {
                        area = addressArray[i].long_name;
                        return area;
                    }
                }
            }
        }
    };

    getState = (addressArray) => {
        let state = '';
        for (let i = 0; i < addressArray.length; i++) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
                    state = addressArray[i].long_name;
                    return state;
                }
            }
        }
    };

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onInfoWindowClose = (event) => { };

    onMarkerDragEnd = (event) => {
        let newLat = event.latLng.lat(),
            newLng = event.latLng.lng();
            jQuery('#latitude').val(newLat);
            jQuery('#longitude').val(newLng);
            jQuery('.mapToogleDiv').toggleClass('addressDetailsHide');
            jQuery('.mapToggle').toggleClass('hide');
            jQuery('.mapToggle').toggleClass('show');
            var address = '';
            var country_long_name = '';
            var country_short_name = '';
       fromLatLng(newLat, newLng).then(
            response => {
                const addressArray = response.results[0].address_components;
                const newAddress = response.results;

                for (let j = 0; j < newAddress[0].address_components.length; j++) {
                    if(newAddress[0].address_components[j] && newAddress[0].address_components[j].types[0] !== "plus_code"){
                        address += newAddress[0].address_components[j].long_name+', ';
                    }
                    if(newAddress[0].address_components[j] && newAddress[0].address_components[j].types[0] === "country"){
                        country_long_name = newAddress[0].address_components[j].long_name;
                        country_short_name = newAddress[0].address_components[j].short_name;
                    }
                }
                const city = this.getCity(addressArray),
                    area = this.getArea(addressArray),
                    state = this.getState(addressArray);
                this.setState({
                    address: (address) ? address : '',
                    area: (area) ? area : '',
                    city: (city) ? city : '',
                    state: (state) ? state : '',
                    markerPosition: {
                        lat: newLat,
                        lng: newLng
                    },
                    mapPosition: {
                        lat: newLat,
                        lng: newLng
                    },
                })
            },
            error => {
                console.error(error);
            }
        );
        setTimeout(function () {
            jQuery('#country').val(country_long_name);
            jQuery('#country_short_name').val(country_short_name);
        }, 500);
    };

    onPlaceSelected = (place) => {
        /* console.log('plc', place); */
        const address = place.formatted_address,
            addressArray = place.address_components,
            city = this.getCity(addressArray),
            area = this.getArea(addressArray),
            state = this.getState(addressArray),
            latValue = place.geometry.location.lat(),
            lngValue = place.geometry.location.lng();
            setTimeout(function () {
                jQuery('#address').val(address);
                jQuery('#latitude').val(latValue);
                jQuery('#longitude').val(lngValue);

                for (var i = 0; i < place.address_components.length; i++) {
                    var addressType = place.address_components[i].types[0];
                    if (addressType === "country") {
                        jQuery('#country').val(place.address_components[i].long_name);
                        jQuery('#country_short_name').val(place.address_components[i].short_name);
                        break;
                    }
                  }
                jQuery('#address').val(address);
            }, 500);
        
        // Set these values in the state.
        this.setState({
            address: (address) ? address : '',
            area: (area) ? area : '',
            city: (city) ? city : '',
            state: (state) ? state : '',
            markerPosition: {
                lat: latValue,
                lng: lngValue
            },
            mapPosition: {
                lat: latValue,
                lng: lngValue
            },
        })
    };

    render() {
        const AsyncMap = withScriptjs(
            withGoogleMap(
                props => (
                    <GoogleMap
                        defaultZoom={this.state.zoom}
                        defaultCenter={{ lat: this.state.mapPosition.lat, lng: this.state.mapPosition.lng }}
                    >
                        <Marker
                            google={this.props.google}
                            name={'Dolores park'}
                            draggable={true}
                            onDragEnd={this.onMarkerDragEnd}
                            position={{ lat: this.state.mapPosition.lat, lng: this.state.mapPosition.lng }}
                        />
                        <Marker />

                        {/* For Auto complete Search Box */}
                        <div className='searchPlace'>
                            <Autocomplete
                                style={{
                                    width: '100%',
                                    height: '40px',
                                    paddingLeft: '16px',
                                    marginTop: '2px',
                                    marginBottom: '2rem'
                                }}
                                name="address"
                                id="address"
                                autoComplete="off"
                                placeholder={this.props.translatedValue('search_for_your_building_or_area')}
                                onPlaceSelected={this.onPlaceSelected}
                                options={{
                                    types: ["(regions)"],
                                    componentRestrictions: { country: [] },/* "QA","IN" */
                                }}
                            />
                            <i className="fa fa-search"></i>
                        </div>
                    </GoogleMap>
                )
            )
        );

        return (
            <div style={{ margin: '0 auto', maxWidth: 1000 }}>
                <AsyncMap
                    googleMapURL={"https://maps.googleapis.com/maps/api/js?key="+process.env.REACT_APP_GOOGLE_MAPS_API_KEY+"&libraries=places&language="+this.state.currentLanguage}
                    loadingElement={
                        <div style={{ height: `100%` }} />
                    }
                    containerElement={
                        <div style={{ height: this.state.height }} />
                    }
                    mapElement={
                        <div style={{ height: `100%` }} />
                    }
                />
            </div>
        )
    }

}

export default BookingLocation;