export const extendMemberShipReducer = (state = {}, action) => {
    switch (action.type) {
        case'EXTEND_MEMBERSHIP_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'EXTEND_MEMBERSHIP_SUCCESS':
            return {
                appointment:action.payload.appointment,
                loading: false,
                success: true
            }
        case'EXTEND_MEMBERSHIP_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }          
        default:
            return state
    }
}