export const disclaimerReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_DISCLAIMER_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_DISCLAIMER_SUCCESS':
            return {
                disclaimer_data:action.payload.disclaimer_data,
                loading: false,
                success: true
            }
        case'GET_DISCLAIMER_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        default:
            return state
    }
}