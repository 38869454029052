import React from "react";
// import Buttons from "./Buttons";

 const Payment = () => {
  return (
    <>
      <div className="">
        <div className="step-wizard ">
          <div className="item-container is-flex is-active isDone">
            <div className="item">
              <div className="marker tag ">
                <p className="helper-text-item">1</p>
              </div>
              <div className="details">
                <p className=" step-title ">Service Details</p>
              </div>
            </div>
            <div className="divider"></div>
          </div>
          <div className="item-container is-flex is-active isDone">
            <div className="item">
              <div className="marker tag ">
                <p className="helper-text-item ">2</p>
              </div>
              <div className="details">
                <p className=" step-title">Date &amp; Time</p>
              </div>
            </div>
            <div className="divider"></div>
          </div>
          <div className="item-container is-flex">
            <div className="item">
              <div className="marker tag ">
                <p className="helper-text-item ">3</p>
              </div>
              <div className="details">
                <p className="step-title">Payment</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="stepsContent Payment  ">
        <div className="row">
          <div className="col-lg-7 col-md-12">
            <div className="card">
              <div className="stepsCard">
                <div className="checkboxSec">
                  <div className="heading">
                    <h3>How often do you need your professional?</h3>
                  </div>
                  <div className="checkboxItem creditfirst">
                    <div className="form-check">
                      <h3>
                        <img alt="" src="http://localhost:3000https://dvdljkkxpxqo3.cloudfront.net/images/card.png.webp"/>
                        Use credit first <span>30.00</span>
                      </h3>
                    </div>
                    <p>
                      <a href="">See details</a>
                    </p>
                  </div>

                  <div className="checkboxItem">
                    <div className="form-check">
                      <input
                        type="radio"
                        className="form-check-input"
                        id="radio3"
                        name="optradio"
                        checked
                      />
                      <label className="form-check-label" for="radio3">
                        Pay by credit /debit card
                      </label>
                    </div>
                    <p>QAR 1000 insurance when you pay online</p>
                    <p>
                      <a href="#">Learn more</a>
                    </p>
                    <div className="cardsec">
                      <input type="text" className="form-control"></input>
                      <h3>
                        Add New Card
                        <img alt="" src="http://localhost:3000https://dvdljkkxpxqo3.cloudfront.net/images/plus2.png.webp"/>
                      </h3>
                      <h3 data-bs-toggle="modal" data-bs-target="#covidModal">
                        Use Another Card
                      </h3>
                    </div>
                  </div>
                  <div className="checkboxItem">
                    <div className="form-check">
                      <input
                        type="radio"
                        className="form-check-input"
                        id="radio3"
                        name="optradio"
                      />
                      <label className="form-check-label" for="radio3">
                        <img
                          src="http://localhost:3000https://dvdljkkxpxqo3.cloudfront.net/images/apple-pay.png.webp"
                          height="22"
                        />
                      </label>
                    </div>
                  </div>
                  <div className="checkboxItem">
                    <button
                      className="addVoucher"
                      data-bs-toggle="modal"
                      data-bs-target="#addVocher"
                    >
                      Add voucher code <i className="fa fa-angle-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5">
            <div className="card cardDetails">
              <div className="card-content ">
                <p>City</p>
                <p>Duhail</p>
              </div>
              <div className="card-content ">
                <p>Service Details</p>
                <ul>
                  <li>
                    Frequency <span>Bi-weekly</span>
                  </li>
                  <li>
                    Duration <span>2 hours</span>
                  </li>
                  <li>
                    Number of Professionals <span>1</span>
                  </li>
                  <li>
                    Material <span>No</span>
                  </li>
                </ul>
              </div>
              <div className="card-content ">
                <p>Date & Time</p>
              </div>
              <div className="card-content ">
                <p>Address </p>
              </div>
              <div className="card-content ">
                <p>Price Details</p>
                <ul>
                  <li>
                    Price <span>QAR 79.05</span>
                  </li>
                  <li>
                    VAT <span>QAR 3.95</span>
                  </li>
                  <li>
                    Discount <span>-QAR 4.15</span>
                  </li>
                  <li>
                    Total <span className="total">QAR 78.85</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Payment;
