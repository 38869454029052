export const customerDetailsReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_CUSTOMER_ADDRESSES_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_CUSTOMER_ADDRESSES_SUCCESS':
            return {
                customerAddresses: action?.payload?.customerAddresses,
                loading: false,
                success: true
            }
        case'GET_CUSTOMER_ADDRESSES_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        case'DELETE_CUSTOMER_ADDRESSES_SUCCESS':
            return {
                customerAddresses: action.payload.customerAddresses,
                loading: false,
                success: true
            }
        case'DELETE_CUSTOMER_ADDRESSES_FAILED':
            return {
                loading: false,
                error: action.payload
            }
        case'POST_DEFAULT_CUSTOMER_ADDRESSES_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'POST_DEFAULT_CUSTOMER_ADDRESSES_SUCCESS':
            return {
                customerAddresses: action.payload,
                loading: false,
                success: true
            }
        case'POST_DEFAULT_CUSTOMER_ADDRESSES_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        case'GET_CUSTOMER_CREDIT_CARD_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_CUSTOMER_CREDIT_CARD_SUCCESS':
            return {
                customerCreditCards: action?.payload?.data,
                stripCustomerCreditCards:  action?.payload?.stripeCards,
                loading: false,
                success: true
            }
        case'GET_CUSTOMER_CREDIT_CARD_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        case'DELETE_CUSTOMER_CREDIT_CARD_SUCCESS':
            return {
                loading: false
            }
        case'DELETE_CUSTOMER_CREDIT_CARD_FAILED':
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}