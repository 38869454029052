import React, {useState, useEffect} from "react";
import ReactPhoneInput from "react-phone-input-2";
import {useTranslation} from "react-i18next";
import es from "react-phone-input-2/lang/es.json";
import ar from "react-phone-input-2/lang/ar.json";
import {toast} from "react-toastify";
import {saveForm} from "../../../helpers/helpers";
// import {customerAddressListsAction} from "../../../actions/customerDetails";
import {useDispatch} from "react-redux";
import {addressSetupAction} from "../../../actions/userActions";
const SetUpMap = ({
  center,
  AsyncMap,
  isToggle,
  setIsToggle,
  addressObject,
  handleAddressAddModalClose,
  setProfileRef,
  signupFrom
}) => {
  const {t} = useTranslation();
  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  let customer_id = currentUser?._id;
  let country_code = document?.getElementById("country_code")?.value;
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  const dispatch = useDispatch();
  const userCountryCode = localStorage.getItem("userCountryCode")
    ? localStorage.getItem("userCountryCode")
    : "en";
  const [formData, setFormData] = useState({
    address: "",
    area: "",
    building: "",
    city: "",
    state: "",
    street: "",
    address_type: "null",
    mobile: "",
    floor: "",
    house_no: "",
    appartment_no: "",
    office_no: ""
  });
  const [addError, setAdderror] = useState({
    address_type: "",
    street: " ",
    floor: "",
    house_no: "",
    area: ""
  });
  const currentLanguageCode = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  useEffect(() => {
    if (addressObject) {
      // console.log("address", addressObject);
      setFormData({
        ...formData,
        area: addressObject.area ? addressObject.area : "",
        street: addressObject.street ? addressObject.street : ""
      });
    }
  }, [addressObject]);
  const validation = (
    area,
    address_type,
    street,
    floor,
    house_no,
    building
  ) => {
    let isValid = true;
    const error = {};
    if (area === undefined || area === "") {
      setAdderror({
        ...addError,
        ["area"]: t("no_address_found")
      });
      setIsToggle(true);
    } else if (address_type === "none" || address_type === "null") {
      setAdderror({
        ...addError,
        ["address_type"]: t("no_address_found"),
        ["area"]:""
      });
      // error.address_type = "Please Insert Address Type ";
      isValid = false;
    } else if (street === "") {
      setAdderror({
        ...addError,
        ["street"]: t("street-Error"),
        ["address_type"]: ""
      });
      // error.street = " ";
      isValid = false;
    } else if (building === "") {
      setAdderror({
        ...addError,
        ["building"]: t("building-Error"),
        ["street"]: ""
      });
      // error.street = " ";
      isValid = false;
    } else if (floor === "") {
      setAdderror({
        ...addError,
        ["floor"]: t("floor_error"),
        ["building"]: ""
      });
      isValid = false;
    } else if (house_no === "") {
      setAdderror({
        ...addError,
        ["house_no"]: t("house_error"),
        ["floor"]: ""
      });
      error.house_no = t("house_error");
      isValid = false;
    } else {
      setAdderror({
        ...addError,
        ["area"]:"",
        ["house_no"]: "",
        ["address_type"]: "",
        ["floor"]: "",
        ["street"]: "",
        ["building"]:'',
      });
    }
    return isValid;
  };

  async function handleProfileModalShow(address, msg) {
    setTimeout(() => {
      handleAddressAddModalClose();
      setProfileRef.current.handleSetProfileModalShow();
    }, 1000);
  }

  const handleCustomerSubmit = () => {

    if (addressObject?.address && addressObject.address !== undefined) {
      const isValid = validation(
        addressObject.area ? addressObject.area : formData?.area,
        formData?.address_type,
        formData?.street,
        formData?.floor,
        formData.house_no,
        formData?.building
      );
      // console.log("isValid", isValid, addressObject);
      if (isValid) {
        if (customer_id) {
          let buldingNo =
            formData?.address_type === "Home"
              ? "house_no"
              : formData?.address_type === "Apartment"
              ? "apartment_no"
              : "office_no";

          let frm = {
            customer_id: customer_id ? customer_id : "",
            address_nickname: formData?.address_nickname
              ? formData?.address_nickname
              : "",
            area: formData?.area ? formData?.area : "",
            address_type: formData?.address_type,
            street: formData?.street ? formData?.street : "",
            building: formData?.building ? formData?.building : "",
            floor: formData?.floor ? formData?.floor : "",
            country_code: document?.getElementById("country_code")?.value
              ? document?.getElementById("country_code")?.value
              : country_code
              ? country_code
              : "",
            country_id: localStorage.getItem("userCountryId"),
            mobile: formData?.mobile ? formData?.mobile : "",
            landline_no: formData?.landline_no ? formData?.landline_no : "",
            additional_direction: formData?.additional_direction
              ? formData?.additional_direction
              : "",
            address: addressObject.address ? addressObject.address : "",
            [buldingNo]: formData.house_no,
            latitude: addressObject?.latitude
              ? addressObject?.latitude?.toString()
              : localStorage.getItem("latitude")
              ? localStorage.getItem("latitude")
              : "",
            longitude: addressObject?.longitude
              ? addressObject?.longitude?.toString()
              : localStorage.getItem("longitude")
              ? localStorage.getItem("longitude")
              : "",
            isDefault: true
          };
          let apiAddressSetupUrl = baseUrl + "/customer/address/setup";
          dispatch(
            addressSetupAction(
              frm,
              apiAddressSetupUrl,
              handleProfileModalShow,
              currentLanguage,
              "newComponent",
              signupFrom
            )
          );
          //   saveAddressHandler(awaitUrl, frm);
        }
      }else{
        setIsToggle(true);
      }
    } else {
     
      toast.error(t("drag_no_address"), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
  };

  //   const saveAddressHandler = async (awaitUrl, frm) => {
  //     let message = "";
  //     await saveForm(awaitUrl, frm, "POST")
  //       .then((response) => {
  //         toast.success(response.data.message, {
  //           position: "top-center",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined
  //         });
  //         dispatch(customerAddressListsAction(addressListUrl));
  //         handleAddressAddModalClose();
  //       })
  //       .catch(function (error) {
  //         if (error.response) {
  //           if (error.response.status === 419) {
  //             message = error.response;
  //             setTimeout(function () {
  //               window.location.reload();
  //             }, 2000);
  //           }
  //         }
  //       });
  //     if (message !== "") {
  //       toast.error(message, {
  //         position: "top-center",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined
  //       });
  //     }
  //   };
  const onChange = (e) => {
    const {name} = e.target;
    setFormData({...formData, [name]: e.target.value});
  };

  return (
    <>
      <div style={{height: "400px", width: "100%"}}>
        <AsyncMap
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`}
          loadingElement={<div style={{height: "100%"}} />}
          containerElement={<div style={{height: "100%"}} />}
          mapElement={<div style={{height: "100%"}} />}
        />
        <div
          className={
            isToggle
              ? "address_input_div col-lg-12 mb-0 mapToogleDiv"
              : "address_input_div col-lg-12 mb-0 mapToogleDiv addressDetailsHide"
          }
        >
          <div
            className={isToggle ? "mapToggle hide" : "mapToggle toast hide"}
            style={{marginTop: "-270px"}}
          >
            <button
              type="button"
              className="btnclose"
              onClick={() => setIsToggle(false)}
            >
              <span className="fa fa-angle-down"></span>
            </button>

            <div className="row mt-4">
              <div className="col-lg-12 inputdiv ">
                <input
                  type="text"
                  className="form-control pl-0"
                  id="address_nickname"
                  name="address_nickname"
                  placeholder={t("address_nickname")}
                  value={
                    addressObject?.address !== "" ? addressObject?.address : ""
                  }
                />
                <div className="help-block"></div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-lg-12 inputdiv ">
                <input
                  type="text"
                  className="form-control pl-0"
                  id="area"
                  name="area"
                  placeholder={t("area")}
                  onChange={(e) => onChange(e)}
                  value={addressObject?.area !== "" ? addressObject?.area : ""}
                />
                <div className="help-block">
                  {addError?.area ? addError.area : ""}
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-lg-12 inputdiv ">
                <select
                  className="form-select"
                  name="address_type"
                  id="address_type"
                  onChange={(e) => onChange(e)}
                  value={formData?.address_type}
                >
                  <option value="none">{t("address_type")} </option>
                  <option value="Home">{t("home")} </option>
                  <option value="Apartment">{t("apartment")} </option>
                  <option value="Office">{t("office")}</option>
                </select>
                <div className="help-block">
                  {addError?.address_type ? addError.address_type : ""}
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-lg-6 inputdiv ">
                <input
                  type="text"
                  className="form-control pl-0"
                  id="street"
                  name="street"
                  placeholder={t("street")}
                  value={formData?.street}
                  onChange={(e) => onChange(e)}
                />
                <div className="help-block">
                  {addError?.street ? addError.street : ""}
                </div>
              </div>
              <div className="col-lg-6 inputdiv ">
                <input
                  type="text"
                  className="form-control pl-0"
                  id="building"
                  name="building"
                  placeholder={t("building")}
                  value={formData.building !== "" ? formData.building : ""}
                  onChange={(e) => onChange(e)}
                />
                <div className="help-block">
                  {addError?.building ? addError.building : ""}
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-lg-12 inputdiv ">
                <input
                  type="text"
                  className="form-control pl-0"
                  id="floor"
                  name="floor"
                  value={formData.floor ? formData.floor : ""}
                  placeholder={t("floor")}
                  onChange={(e) => onChange(e)}
                />
                <div className="help-block">
                  {addError.floor ? addError.floor : ""}
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-lg-12 inputdiv ">
                <input
                  type="text"
                  className="form-control pl-0"
                  id="home_number"
                  name="house_no"
                  placeholder={
                    formData.address_type === "Apartment"
                      ? `${t("apartment")} ${t("number")}`
                      : formData.address_type === "Office"
                      ? `${t("office")} ${t("number")}`
                      : `${t("house")} ${t("number")}`
                  }
                  onChange={(e) => onChange(e)}
                  value={formData.house_no ? formData.house_no : ""}
                />
                <div className="help-block">
                  {addError.house_no ? addError.house_no : ""}
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-lg-12 inputdiv ">
                <input
                  type="text"
                  className="form-control pl-0"
                  placeholder={t("additional_directions")}
                  name="additional_directions"
                  onChange={(e) => onChange(e)}
                  value={
                    formData.additional_directions
                      ? formData.additional_directions
                      : ""
                  }
                />
                <div className="help-block"></div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-lg-12 inputdiv ">
                <input
                  type="text"
                  className="form-control pl-0"
                  id="landline_number"
                  name="landline_number"
                  placeholder={t("landline_number")}
                  onChange={(e) => onChange(e)}
                  value={
                    formData.landline_number ? formData.landline_number : ""
                  }
                />
                <div className="help-block"></div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-12 inputdiv ">
                <div className="row loginMobileInput mb-2">
                  <div className="inputgroup phonecodeselect col-lg-3">
                    <ReactPhoneInput
                      inputProps={{
                        className: "login_country_code form-control",
                        name: "country_code",
                        id: "country_code",
                        autoFocus: true,
                        placeholder: t("enter_mobile_number"),
                        readOnly: "readonly"
                      }}
                      country={userCountryCode.toLocaleLowerCase()}
                      localization={currentLanguageCode === "en" ? es : ar}
                      countryCodeEditable={false}
                      placeholder=""
                      disableCountryCode={false}
                      searchClass="search-class"
                      searchStyle={{
                        margin: "0",
                        width: "97%",
                        height: "30px"
                      }}
                      enableSearch
                      disableSearchIcon
                    />
                    <div className="help-block text-danger"></div>
                  </div>

                  <div className="inputgroup phonecodeselect col-lg-9">
                    <input
                      type="number"
                      name="mobile"
                      id="mobile"
                      className="form-control"
                      placeholder={t("enter_mobile_number")}
                      onChange={(e) => onChange(e)}
                      value={formData.mobile ? formData.mobile : ""}
                    />
                    <div className="help-block text-danger"></div>
                  </div>
                </div>
                <div className="help-block"></div>
              </div>
            </div>
          </div>
          <button
            onClick={(e) => handleCustomerSubmit(e)}
            className="btn btn-primary"
          >
            {t("confirm")}
          </button>
        </div>
      </div>
    </>
  );
};

export default SetUpMap;
