import axios from "axios";
import {authHeader, handlingAuthErrors} from "./helpers";
import {error} from "jquery";

export const stepOneBooking = async ({baseUrl, data}) => {
  try {
    const response = await axios({
      method: "POST",
      headers: authHeader(),
      url: baseUrl,
      data: data
    });

    if (response.data.code === 200) {
      return response.data.appointment;
    } else {
      // Handle other cases or return an appropriate value.
      return null;
    }
  } catch (err) {
    console.error(err);
    handlingAuthErrors(err);
    throw err; // Re-throw the error so it can be handled by the calling component.
  }
};
export const stepTwoBooking = async ({baseUrl, data}) => {
  try {
    const response = await axios({
      method: "POST",
      headers: authHeader(),
      url: baseUrl,
      data: data
    });

    if (response.data.code === 200) {
      return response?.data?.appointment;
    } else {
      // Handle other cases or return an appropriate value.
      return response;

    }
  } catch (err) {
    // console.error(err);
    handlingAuthErrors(err);
    return err?.response?.data;
  
    throw err; // Re-throw the error so it can be handled by the calling component.
  }
};
export const getWalletData = async (baseUrl) => {
  try {
    const response = await axios({
      method: "GET",
      headers: authHeader(),
      url: baseUrl
    });

    if (response.data.code === 200) {
      return response.data;
    } else {
      // Handle other cases or return an appropriate value.
      return null;
    }
  } catch (err) {
    // console.error(err);
    handlingAuthErrors(err);
    throw err; // Re-throw the error so it can be handled by the calling component.
  }
};
export const BuyMembership = async ({baseUrl, data}) => {
  try {
    const response = await axios({
      method: "POST",
      headers: authHeader(),
      url: baseUrl,
      data: data
    });
    if (response.data.code === 200) {
      return response.data;
    } else {
      // Handle other cases or return an appropriate value.
      return response;
    }
  } catch (err) {
    // console.error(err);

    handlingAuthErrors(err);
    return  err?.response?.data
    throw err; // Re-throw the error so it can be handled by the calling component.
  }
};

export const getMonthSlotsAPI = async (baseUrl, data) => {
  try {
    const response = await axios({
      method: "POST",
      headers: authHeader(),
      url: baseUrl,
      data: data
    });
    // console.log("response--discount", response);
    if (response?.data?.code === 200) {
      return response.data;
    } else {
      // Handle other cases or return an appropriate value.

      return response.data;
    }
  } catch (error) {
    if (
      error.response &&
      (error.response.status === 404 || error.response.status === 400)
    ) {
      return error.response;
    } else if (error.response && error.response.status === 406) {
      return error.response;
    }
    return error.response;
    // handlingAuthErrors(err);
    // throw err; // Re-throw the error so it can be handled by the calling component.
  }
};

export const getDiscount = async (baseUrl, data) => {
  try {
    const response = await axios({
      method: "POST",
      headers: authHeader(),
      url: baseUrl,
      data: data
    });
    // console.log("response--discount", response);
    if (response?.data?.code === 200) {
      return response.data;
    } else {
      // Handle other cases or return an appropriate value.

      return response.data;
    }
  } catch (error) {
    if (
      error.response &&
      (error.response.status === 404 || error.response.status === 400)
    ) {
      return "Voucher not found";
    } else if (error.response && error.response.status === 406) {
      return "This Voucher is Expire";
    }
    return error;
    // handlingAuthErrors(err);
    // throw err; // Re-throw the error so it can be handled by the calling component.
  }
};

export const BuyOnline = async ({baseUrl, data}) => {
  try {
    const response = await axios({
      method: "POST",
      headers: authHeader(),
      url: baseUrl,
      data: data
    });
    if (response.data) {
      return response.data;
    } else {
      // Handle other cases or return an appropriate value.
      return null;
    }
  } catch (err) {
    // console.error(err);
    handlingAuthErrors(err);
    throw err; // Re-throw the error so it can be handled by the calling component.
  }
};

export const addressSetup = async ({baseUrl, data}) => {
  try {
    const response = await axios({
      method: "POST",
      headers: authHeader(),
      url: baseUrl,
      data: data
    });
    // console.log("Response", response);
    
    if (response.data) {
      return response.data;
    } else {
      // Handle other cases or return an appropriate value.
      // console.log("Response", response);
      return response;
    }
  } catch (err) {
    // console.log(err);

    //  console.log("err", err, err?.response); 
    if (err?.response?.data?.code === 400 && err?.response?.data?.message === "Address already setup") {
      return err?.response?.data;
    } else {
      handlingAuthErrors(err);
      return false;
    }
    // throw err; // Re-throw the error so it can be handled by the calling component.
  }
};
export const socialLoginApi = async ({baseUrl, data, currentLanguage}) => {
  try {
    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        "Accept-Language": currentLanguage
      },
      url: baseUrl,
      data: data
    });
    if (response.data) {
      return response.data;
    } else {
      // Handle other cases or return an appropriate value.

      return false;
    }
  } catch (err) {
    console.log(err);
    if (err.code === 400) {
      return err;
    } else {
      handlingAuthErrors(err);
      return false;
    }

    // throw err; // Re-throw the error so it can be handled by the calling component.
  }
};
export const profileSetupApi = async ({baseUrl, data, currentLanguage}) => {
  try {
    const response = await axios({
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        "Accept-Language": currentLanguage
      },
      url: baseUrl,
      data: data
    });
    if (response.data) {
      return response.data;
    } else {
      // Handle other cases or return an appropriate value.

      return false;
    }
  } catch (err) {
    console.log(err);
    if (err.code === 400) {
      return err;
    } else {
      handlingAuthErrors(err);
      return false;
    }

    // throw err; // Re-throw the error so it can be handled by the calling component.
  }
};
export const ValidateCountry = async ({baseUrl, data}) => {
  try {
    const response = await axios({
      method: "POST",
      headers: authHeader(),
      url: baseUrl,
      data: data
    });
    if (response.data) {
      return response.data;
    } else {
      // Handle other cases or return an appropriate value.
      // console.log("Response", response);
      return false;
    }
  } catch (err) {
    // console.log(err);

    handlingAuthErrors(err);
    return false;
    // throw err; // Re-throw the error so it can be handled by the calling component.
  }
};
