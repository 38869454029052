import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import jQuery from "jquery";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Loader from "../../components/Loader";
import { ModifiedToastContainer } from "../../components/ModifiedToastContainer";
import { updateLanguageAction } from "../../actions/updateLanguageAction";
import { countryListAction } from "../../actions/userActions";

const SettingContent = () => {
  const dispatch = useDispatch();
  const [userSelectCountry, setUserSelectCountry] = useState({});
  const [showModal, setShowModal] = useState(false);
  const handleModalShow = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  const [showCountryModal, setShowCountryModal] = useState(false);
  const handleCountryModalShow = () => setShowCountryModal(true);
  const handleCountryModalClose = () => setShowCountryModal(false);
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  const { t } = useTranslation();
  const currentLanguageCode = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  const getLanguageState = useSelector((state) => state.updateLanguageReducer);
  const { loading } = getLanguageState;

  const getAllCountryState = useSelector((state) => state.countryReducer);
  const { country_list } = getAllCountryState;
  let listUrl = baseUrl + "/country/list";
  useEffect(() => {
    dispatch(countryListAction(listUrl, {}));
  }, [listUrl]);

  useEffect(() => {
    if (!currentUser) {
      window.location.href = "/";
    }
  }, [currentUser]);

  async function changeLanguage() {
    handleModalShow();
  }

  async function changeLanguageSubmit() {
    let language = jQuery("input[name=language]:checked").val();
    let listUrl = baseUrl + "/customer/update/language";
    dispatch(
      updateLanguageAction(
        listUrl,
        {
          current_language: language,
        },
        updateLanguage
      )
    );
  }

  async function updateLanguage(current_language) {
    localStorage.setItem("currentLanguage", current_language);
    i18next.changeLanguage(current_language);
    //window.location.reload();
    handleModalClose();
  }

  var selectedCountryId;
  if (
    localStorage.getItem("userCountryId") &&
    localStorage.getItem("userCountryCode")
  ) {
    selectedCountryId = localStorage.getItem("userCountryId");
  } else {
    selectedCountryId = "638452497da67f7c6bf4fdcd";
    localStorage.setItem("userCountryId", selectedCountryId);
    localStorage.setItem("userCountryCode", "AE");
  }

  async function submitCountry() {
 
    const country_and_flag = jQuery("input[name=country_id]:checked").val();
    const country_id = country_and_flag.substring(
      0,
      country_and_flag.indexOf(",")
    );
    const country_code = country_and_flag.split(",")[1].trim();

    // setTimeout(() => {
      localStorage.removeItem("userCountryId");
      localStorage.setItem("userCountryId", country_id);
      localStorage.removeItem("userCountryCode");
      localStorage.setItem("userCountryCode", country_code);
      window.location.reload();
    // }, 500);
  }

  const countryInformation = () => {
    handleCountryModalShow();
  };

  const [preview1, setPreview1] = useState(currentUser.profile_image);

  /* async function changeLanguageSubmit(){
    let language = jQuery('input[name=language]:checked').val();
    localStorage.setItem('currentLanguage',language);
    i18next.changeLanguage(language);
    handleModalClose();
  } */

  const logout = (e) => {
    toast.success(t("logout_successfully"), {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    setTimeout(() => {
      localStorage.removeItem("currentUser");
      localStorage.removeItem("currentToken");
      localStorage.removeItem("currentLatitude");
      localStorage.removeItem("currentLongitude");
      // localStorage.clear();
      window.location.href = "/";
    }, 1000);
  };

  return (
    <>
      {loading ? <Loader /> : ""}
      <ModifiedToastContainer />
      <div className="py-5">
        <div className="userContainer">
          <div className="userSettings">
            <div className="userInfo">
              <img alt="" src={preview1} />
              <h2>
                {currentUser && currentUser.name ? currentUser.name : "User"}
              </h2>
              <p className="phoneNumberDir">
                {currentUser && currentUser.mobile
                  ? currentUser.country_code + "" + currentUser.mobile
                  : ""}
              </p>
            </div>
            <div className="card">
              <div className="accountSettings">
                <h3>{t("account_settings")}</h3>
                {/* <ul className="list">
                    <li>
                      <NavLink to="/my-account/personal-details">
                        {t('edit_personal_details')} <i className="fa fa-angle-right"></i>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/my-account/address">
                        {t('manage_addresses')} <i className="fa fa-angle-right"></i>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/my-account/creditcard">
                        {t('manage_credit_cards')} <i className="fa fa-angle-right"></i>
                      </NavLink>
                    </li>
                  </ul> */}

                {/* <h3>{t('app_settings')}</h3> */}
                <ul className="list">
                  <li>
                    <NavLink to="/my-account/settings" onClick={changeLanguage}>
                      {t("language")}
                      <span>
                        {currentLanguageCode === "ar"
                          ? t("arabic")
                          : t("english")}{" "}
                        <i className="fa fa-angle-right"></i>
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/my-account/settings"
                      onClick={countryInformation}
                    >
                      {t("country")}
                      <span>
                        {country_list?.length > 0
                          ? country_list.map((country, index) => {
                              if (selectedCountryId === country._id) {
                                return currentLanguageCode === "ar"
                                  ? country.name_arabic
                                  : country.name;
                              }
                            })
                          : ""}
                        <i className="fa fa-angle-right"></i>
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/terms/conditions">
                      {t("terms_and_condition")}{" "}
                      <i className="fa fa-angle-right"></i>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/privacy/policy">
                      {t("privacy_policy")}{" "}
                      <i className="fa fa-angle-right"></i>
                    </NavLink>
                  </li>
                  <li
                    onClick={() =>
                      (window.location.href =
                        "https://righthandssupport.zendesk.com/hc/en-us")
                    }
                  >
                    <NavLink to="">
                      {t("help")} <i className="fa fa-angle-right"></i>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/my-account/settings"
                      className="slogout active"
                      onClick={logout}
                    >
                      {t("logout")}
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  language Modal */}

      <Modal
        show={showModal}
        onHide={handleModalClose}
        id="language"
        className="addAddressModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("select_your_language")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body ">
            <label className="w-full languageChecked">
              <p>English</p>
              <input
                type="radio"
                value="en"
                name="language"
                className="language"
                defaultChecked={currentLanguageCode === "en"}
              ></input>
              <i className="fa fa-check"></i>
            </label>
            <label className="w-full languageChecked">
              <p>عربي</p>
              <input
                type="radio"
                value="ar"
                name="language"
                className="language"
                defaultChecked={currentLanguageCode === "ar"}
              ></input>
              <i className="fa fa-check"></i>
            </label>
            <button
              type="submit"
              className="btn btn-primary w-full mt-2"
              onClick={changeLanguageSubmit}
            >
              {t("ok")}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showCountryModal}
        onHide={handleCountryModalClose}
        className="changeCountry"
        id="changeCountry"
      >
        <Modal.Header closeButton className="modal-header">
          <h3 className="modal-title">{t("select_your_country")}</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            {country_list?.length > 0
              ? country_list.map((country, index) => {
                  return (
                    <div key={index}>
                      {country?.isActive && (
                        <label className="w-full languageChecked">
                          <p>
                            {currentLanguageCode === "en"
                              ? country?.name
                              : country?.name_arabic}
                          </p>
                          <input
                            type="radio"
                            value={country?.id + "," + country?.country_flag}
                            name="country_id"
                            className="language"
                            defaultChecked={country?.id === selectedCountryId}
                          ></input>
                          <i className="fa fa-check"></i>
                        </label>
                      )}
                    </div>
                  );
                })
              : ""}
            <button
              type="submit"
              className="btn btn-primary w-full mt-2"
              onClick={() => submitCountry()}
            >
              OK
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {/* <CountryModal/> */}
    </>
  );
};

export default SettingContent;
