import axios from 'axios'
import {authHeader, handlingNonauthStaticContentErrors} from "../helpers/helpers";

export const getTCDataAction = (baseUrl,values) => dispatch => {

    dispatch({type: 'GET_TC_LIST_REQUEST'})
    axios({
        method: 'GET',
        headers:authHeader(),
        url: baseUrl,
        data: values
    }).then((res) => {
        dispatch({type: 'GET_TC_LIST_SUCCESS', payload: {
            tc_data:res.data.staticContent
        }});
    }).catch((err) => {
        dispatch({type: 'GET_TC_LIST_FAILED', payload: err});
        handlingNonauthStaticContentErrors(err)
    });
}

export const getProHourInfoAction = (baseUrl,values) => dispatch => {

    dispatch({type: 'GET_PRO_INFO_REQUEST'})
    axios({
        method: 'GET',
        headers:authHeader(),
        url: baseUrl,
        data: values
    }).then((res) => {
        dispatch({type: 'GET_PRO_INFO_SUCCESS', payload: {
            pro_hour_info:res.data.staticContent
        }});
    }).catch((err) => {
        dispatch({type: 'GET_PRO_INFO_FAILED', payload: err});
        handlingNonauthStaticContentErrors(err)
    });
}

export const getCleaningMaterialInfoAction = (baseUrl,values) => dispatch => {

    dispatch({type: 'GET_CLEANING_INFO_REQUEST'})
    axios({
        method: 'GET',
        headers:authHeader(),
        url: baseUrl,
        data: values
    }).then((res) => {
        dispatch({type: 'GET_CLEANING_INFO_SUCCESS', payload: {
            cleaning_material_info:res.data.staticContent
        }});
    }).catch((err) => {
        dispatch({type: 'GET_CLEANING_INFO_FAILED', payload: err});
        handlingNonauthStaticContentErrors(err)
    });
}

export const getFavProInfoAction = (baseUrl,values) => dispatch => {

    dispatch({type: 'GET_FAV_PRO_REQUEST'})
    axios({
        method: 'GET',
        headers:authHeader(),
        url: baseUrl,
        data: values
    }).then((res) => {
        dispatch({type: 'GET_FAV_PRO_SUCCESS', payload: {
            fav_pro_info:res.data.staticContent
        }});
    }).catch((err) => {
        dispatch({type: 'GET_FAV_PRO_FAILED', payload: err});
        handlingNonauthStaticContentErrors(err)
    });
}

export const getVisitTimeInfoAction = (baseUrl,values) => dispatch => {

    dispatch({type: 'GET_VISIT_TIME_REQUEST'})
    axios({
        method: 'GET',
        headers:authHeader(),
        url: baseUrl,
        data: values
    }).then((res) => {
        dispatch({type: 'GET_VISIT_TIME_SUCCESS', payload: {
            start_time_info:res.data.staticContent
        }});
    }).catch((err) => {
        dispatch({type: 'GET_VISIT_TIME_FAILED', payload: err});
        handlingNonauthStaticContentErrors(err)
    });
}

export const getCovidGLDataAction = (baseUrl,values) => dispatch => {

    dispatch({type: 'GET_COVID_GL_REQUEST'})
    axios({
        method: 'GET',
        headers:authHeader(),
        url: baseUrl,
        data: values
    }).then((res) => {
        dispatch({type: 'GET_COVID_GL_SUCCESS', payload: {
            covid_guidlines:res.data.staticContent
        }});
    }).catch((err) => {
        dispatch({type: 'GET_COVID_GL_FAILED', payload: err});
        handlingNonauthStaticContentErrors(err)
    });
}