import axios from 'axios'
import {toast} from "react-toastify";
import {authHeader, handlingNonauthErrors} from "../helpers/helpers";

export const getProfessionalInfoAction = (baseUrl,values,handleProfessionalInfoModalShow) => dispatch => {
   
    dispatch({type: 'GET_PROFESSIONAL_INFO_REQUEST'})
    axios({
        method: 'GET',
        headers:authHeader(),
        url: baseUrl,
        data: values
    }).then((res) => {
        dispatch({type: 'GET_PROFESSIONAL_INFO_SUCCESS', payload: {
            professionalInfo:{
                user:res.data.user,reviewCount:res.data.reviewCount,cleanerHired:res.data.cleanerHired,
                cleaner5count:res.data.cleaner5count,cleaner4count:res.data.cleaner4count,cleaner3count:res.data.cleaner3count,
                cleaner2count:res.data.cleaner2count,cleaner1count:res.data.cleaner1count,cleanerReviews:res.data.cleanerReviews
            }
        }});
        handleProfessionalInfoModalShow()

    }).catch((err) => {
        dispatch({type: 'GET_PROFESSIONAL_INFO_FAILED', payload: err});
        handlingNonauthErrors(err);
    })
}

