import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import {ModifiedToastContainer} from "../../components/ModifiedToastContainer";
import { useTranslation } from "react-i18next";
import {postForm} from "../../helpers/helpers";
import { useState } from "react";

const ClaimAccount = () => {
  const [pageLoading, setPageLoading] = useState(false);
  const { t } = useTranslation();

  const currentUser = (localStorage.getItem('currentUser') && localStorage.getItem('currentUser') !== "null") ? JSON.parse(localStorage.getItem('currentUser')) : false;

  if(currentUser){
    setTimeout(() => {
      window.location.href = "/";
    }, 1000) 
  }

    const handleClaimAccountSubmit = async (e) => {
      setPageLoading(true);
      e.preventDefault();
      let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
      let awaitUrl = baseUrl + "/customer/claim-account";
      let frm = document.getElementById('claimAccount');
      await postForm(awaitUrl, frm, 'POST').then((response) => {
        setPageLoading(false);
        if(response && response?.data){
          frm.reset();
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }).catch(function (error) {
        setPageLoading(false);
        if (error.response) {
          if (error.response.status === 419) {
            setTimeout(function () {
              window.location.reload();
            }, 2000);
          }
        }
      })
    };

    return (
      <>
        <Header />
        {pageLoading ? <Loader /> : ""}
        <ModifiedToastContainer />
        <div className="py-5">
          <div className="userContainer">
            <div className="editPersonalDetail">
              <div className="heading">
                <h2>{t('claim_your_account')}</h2>
                <p>{t('claim_your_online_account_to_get_access')}</p>
              </div>
              <div className="card">
              <form onSubmit={handleClaimAccountSubmit} id="claimAccount">
                <div className="personalForm">                 
                  <div className="inputdiv col-lg-12 ">
                    <label>{t('email_address')}</label>
                    <input
                      type="text"
                      className="form-control"  
                      name="email"               
                      placeholder={t('enter_email_address')}
                    />
                    <div className="help-block"></div>
                  </div>
                  <div className="inputdiv col-lg-12 mt-3 mb-0 ">
                    <button type="submit" className="btn btn-primary w-full ">{t('claim_your_account')}</button>
                  </div>
                </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
}

export default ClaimAccount
