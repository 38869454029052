import React from 'react'
import OwlCarousel from "react-owl-carousel";
const ServiceSliderPage = () => {
    const options = {
      stagePadding: 50,
        margin: 10,
        responsiveClass: true,
        nav: false,
        dots: false,
        autoplay: true,
        rtl: true,
        ltr: false,
        autoplayTimeout:3000,
     
      
        
        navText: [
          "<button type='button' class='slick-prev'><i class='fa fa-angle-left' aria-hidden='true'></i></button>",
          "<button type='button' class='slick-next'><i class='fa fa-angle-right' aria-hidden='true'></i></button>",
        ],
        nxet: "",
        smartSpeed: 1000,
        responsive: {
          0: {
            items: 1,
            margin:0,
          },
          
          1200: {
            items: 1,
          },
        }
      };
  return (
    <>

<div className='serviceSliderPage'>
<OwlCarousel
            className="owl-theme"
          
            loop={true}
            touchDrag={false}
            mouseDrag={false}
         
            nav
            {...options}
          >
            <div className='item'>
            <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/bnr2.jpg.webp" />  
            <div className='container'>  
            <div className='sliderContent'>
            <h2>Enjoy QAR300 in credits,</h2>
            <p>and we will plant a tree!</p>
            <button className='btn btn-primary'>Find out more</button>
             </div>
             </div>     
            </div>
            <div className='item'>
            <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/bnr2.jpg.webp"  />  
            <div className='container'>  
            <div className='sliderContent'>
            <h2>Enjoy QAR300 in credits,</h2>
            <p>and we will plant a tree!</p>
            <button className='btn btn-primary'>Find out more</button>
             </div>
             </div>     
            </div>
           
           
  </OwlCarousel>
</div>
    </>
  )
}

export default ServiceSliderPage