import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { getEditData } from "../../../helpers/helpers";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import LoginSignupModal from "../../../components/LoginSignupModal";
import Markdown from 'markdown-to-jsx';
import { useSelector } from "react-redux";
const FreeServices = () => {

  const { t } = useTranslation();
  const [customer, setCustomer] = useState({
    country_code: "",
    mobile: "",
    name: "",
    email: "",
    gender: "",
    dob: "",
    referral_code: "",
    otp:""
  });
  const country_list = useSelector((state) => state?.countryReducer?.country_list);
  const [currentFlag, setCurrentFlag] = useState(localStorage.getItem('userCountryCode'));
  const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem('currentLanguage'));
  const [inviteMessage, setInviteMessage] = useState("");
  const loginSignupRef = useRef();
  const [loggedUser, setLoggedUser] = useState(localStorage.getItem('currentUser'))

  useEffect(() => {
    if (country_list && currentFlag) {
      const filteredCountries = country_list.filter(country => country.country_flag === currentFlag);
      if (filteredCountries.length > 0) {
        const countryData = filteredCountries[0];
        setInviteMessage(currentLanguage === 'ar' ? countryData.invite_message_arabic : countryData.invite_message);
      }
    }
  }, [country_list, currentFlag, currentLanguage]);

  useEffect(()=>{
    if(!loggedUser){
      loginSignupRef.current.handleLoginSignupModalShow();
    }
  },[loggedUser]);

  const currentUser = (localStorage.getItem('currentUser') && localStorage.getItem('currentUser') !== "null") ? JSON.parse(localStorage.getItem('currentUser')) : false;
  const shareLink = `Have you given Right Hands Services a try yet? Here’s QR40 OFF your next home service. Use my code ${currentUser.referral_code} upon sign up or simply follow this link: ${process.env.REACT_APP_INVITE_URL}/deeplink?voucher=${currentUser.referral_code}`;
  //const shareLink = process.env.REACT_APP_BASE_URL+"/deeplink?voucher="+currentUser.referral_code;
  const [copied, setCopied] = useState(currentUser.referral_code);
  const copyLink = () => {
      setCopied("Copied!");
      setTimeout( () => {
        setCopied(currentUser.referral_code);
      }, 500)
     navigator.clipboard.writeText(shareLink);
  };  
  const btnText = copied!=currentUser.referral_code ? t('copied') : currentUser.referral_code;

  useEffect(() => {
    let getUrl = process.env.REACT_APP_LOCAL_API_URL+ "/customer/get/profile";
    getEditData(getUrl, setCustomer).catch((err) => console.log(err));
  }, []); 

  return (
    <>
      <Header/>
      {!loggedUser && (
              <LoginSignupModal ref={loginSignupRef} />
      )}
      <div className="py-5">
        <div className="userContainer">
          <div className="appointments">
            <div className="heading">
              <h2>{t('free_services')}</h2>
            </div>
            <div className="card">
              <div className="accountSettings freeService ">
                <div className="p-5 text-center">
                <Markdown>{inviteMessage}</Markdown>
                </div>
                <ul className="list">
                  <li>
                    <Link to="/my-account/how-it-works">
                      <div>
                        <img
                          src="https://dvdljkkxpxqo3.cloudfront.net/images/question.png.webp"
                          alt=""
                          className="me-3"
                        />
                        {t('how_it_works')}
                      </div>
                      <span>
                        <i className="fa fa-angle-right"></i>
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/my-account/track-invites">
                      <div>
                        <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/search.png.webp" className="me-3" />
                        {t('track_your_invites')}
                      </div>
                      <span>
                        <i className="fa fa-angle-right"></i>
                      </span>
                    </Link>
                  </li>
                </ul>
                <div className="p-4 mb-4 pt-0">
                  <h3 className="text-center">{t('copy_your_invite_code')}</h3>
                  <div className="copyCode">
                    <button className="copyCodeBtn" title={copied!="Share" ? t('copied') : t('share')} onClick={() => copyLink()}>{ btnText }</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FreeServices;
