import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Loader from "./Loader";
import { useNavigate, useParams } from "react-router-dom";

const BlankLoading = () => {
    const params = useParams();
    let navigate = useNavigate(); 
    setTimeout(async () => {
        localStorage.setItem('subscriptionId',params.packageId);
        navigate('/my-account/credit-packages/payment');
    }, 300);
  return (
    <>
      <Header />
      <Loader />
      <div className="blogPAGE">
        <div className="container">
          <div className="blogDetail">
            <div className="blogPublished">
              <div className="publishDate BlankLoadingBody"></div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlankLoading;
