/* eslint-disable */
import React, {useState, useEffect} from "react";
import {Link, useLocation} from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {createUpdateCustCreditCard} from "../../helpers/helpers";
import {ModifiedToastContainer} from "../../components/ModifiedToastContainer";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import Loader from "../../components/Loader";

import jQuery from "jquery";

const AddCreditCard = () => {
  const {t} = useTranslation();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let REACT_APP_CHECKOUT_PUBLIC_KEY = process.env.REACT_APP_CHECKOUT_PUBLIC_KEY;
  const location = useLocation();
  const {state} = location;
  const [pageLoading, setPageLoading] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [name, setName] = useState("");
  const [expiryMonth, setExpiryMonth] = useState("");
  const [expiryYear, setExpiryYear] = useState("");
  const [cvv, setCvv] = useState("");
  const [errors, setErrors] = useState({
    cardNumber: "",
    expiry: "",
    cvv: ""
  });

  useEffect(() => {
    if (!state?.redirect) {
      setTimeout(() => {
        window.location.href = "/";
      }, 100);
    }
  }, [state]);

  useEffect(() => {
    validateFields();
  }, [cardNumber, expiryMonth, expiryYear, cvv]);

  const formatNumber = (number) =>
    number.split("").reduce((seed, next, index) => {
      if (index !== 0 && !(index % 4)) seed += " ";
      return seed + next;
    }, "");
  const validateFields = () => {
    let errors = {};

    if (!cardNumber || cardNumber.length !== 16) {
      errors.cardNumber = t("invalid_card");
    }

    // Expiry Month

    if (expiryMonth < 1 || expiryMonth > 12) {
      errors.expiry = t("invalid_month");
    }
    // Expiry Year
    let currentYear = new Date().getFullYear();

    if (expiryYear < currentYear) {
      errors.expiry = t("card_expired");
    }

    // CVV validation

    if (!cvv || cvv.length !== 3) {
      errors.cvv = t("cvv_expires");
    }

    setErrors(errors);
  };
  const simplifyResponseHandler = (data) => {
    var paymentForm = jQuery("#simplify-payment-form");
    jQuery(".error").remove();

    if (data.error) {
      // console.log(data.error);
      toast.error("Something went wrong. please try again.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      setPageLoading(false);
    } else {
      createCustCreditCard(data?.id);
    }
  };

  // function simplifyResponseHandler(data) {
  //   var paymentForm = jQuery("#simplify-payment-form");
  //   // Remove all previous errors
  //   jQuery(".error").remove();
  //   // Check for errors
  //   console.log(data);

  //   if (data.error) {
  //     // Show any validation errors
  //     if (data.error.code == "validation") {
  //       var fieldErrors = data.error.fieldErrors,
  //         fieldErrorsLength = fieldErrors.length,
  //         errorList = "";
  //       for (var i = 0; i < fieldErrorsLength; i++) {
  //         errorList +=
  //           "<div class='error'>Field: '" +
  //           fieldErrors[i].field +
  //           "' is invalid - " +
  //           fieldErrors[i].message +
  //           "</div>";
  //       }
  //       // Display the errors
  //       paymentForm.after(errorList);
  //     }
  //     // Re-enable the submit button
  //     jQuery("#process-payment-btn").removeAttr("disabled");
  //   } else {
  //     // The token contains id, last4, and card type
  //     var token = data["id"];
  //     console.log(token);
  //     // Insert the token into the form so it gets submitted to the server
  //     paymentForm.append(
  //       "<input type='hidden' name='simplifyToken' value='" + token + "' />"
  //     );
  //     // Submit the form to the server
  //     // return false;
  //     //   paymentForm.get(0).submit();
  //   }
  // }
  const handelCardNumber = (e) => {
    const input = e.target;
    const value = input.value.replace(/\D/g, ""); // Remove non-numeric characters
    const formattedValue = value
      .split("")
      .reduce((seed, next, index) => {
        if (index !== 0 && !(index % 4)) seed += " ";
        return seed + next;
      }, "");
  
    // Set the formatted value directly to the input
    input.value = formattedValue;
    // Update the cardNumber state with the unformatted value
    setCardNumber(value);
  };
  const handelNewCardSubmit = (e) => {
    e.preventDefault();
    setPageLoading(true);
    SimplifyCommerce.generateToken(
      {
        key: "sbpb_ZmUzNjFjZjgtZWEzOS00MmZiLWE2MzQtNzU0M2MyOTZlNGMx",
        card: {
          number: cardNumber,
          cvc: cvv,
          expMonth: expiryMonth,
          expYear: expiryYear.substring(2)
        }
      },
      simplifyResponseHandler
    );
  };

  async function createCustCreditCard(cardToken) {
    // let awaitRequestUrl = baseUrl+"/payment/create/instrument";
    let awaitRequestUrl = baseUrl + "/creditCard/create/qnbCustomer";
    setPageLoading(true);
    await createUpdateCustCreditCard(awaitRequestUrl, cardToken)
      .then((response) => {
        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        setPageLoading(false);

        setTimeout(() => {
          window.location.href = state?.redirect;
        }, 2000);
      })
      .catch(function (error) {
        setPageLoading(false);
        toast.error("Something went wrong. please try again.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      });
  }

  return (
    <>
      <Header />
      <ModifiedToastContainer />
      {pageLoading ? <Loader /> : ""}
      <div className="py-5">
        <div className="userContainer">
          <div className="creditCard">
            <div className="heading">
              <Link to="/my-account/account-details">
                <h5>{t("account_details")}</h5>
              </Link>
              <h2>{t("add_credit_card")}</h2>
            </div>

            <div className="card">
              <div className="personalForm">
                <div className="form-group">
                  <label>{t("card_number")}</label>
                 
                    
                    <input
                      type="text"
                      // value={cardNumber}
                      placeholder="0000 0000 0000 0000"
                      onChange={(e) => {
                        handelCardNumber(e);
                      }}
                      maxLength="19"
                    />
                 

                  {errors?.cardNumber && (
                    <p style={{color: "red", fontSize: 12, marginTop: 5}}>
                      {errors?.cardNumber}
                    </p>
                  )}
                </div>

                <div className="form-group">
                  <label>{t("full_name")}</label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <label>{t("expiry_date")}</label>
                  <div style={{display: "flex"}}>
                    <input
                      style={{width: "50%", marginRight: 10}}
                      type="text"
                      placeholder="MM"
                      maxLength="2"
                      value={expiryMonth}
                      onChange={(e) => setExpiryMonth(e.target.value)}
                    />

                    <input
                      style={{width: "50%", marginRight: 5}}
                      type="text"
                      placeholder="YYYY"
                      maxLength="4"
                      value={expiryYear}
                      onChange={(e) => setExpiryYear(e.target.value)}
                    />
                  </div>
                  {errors?.expiry && (
                    <p style={{color: "red", fontSize: 12, marginTop: 5}}>
                      {errors?.expiry}
                    </p>
                  )}
                </div>

                <div className="form-group">
                  <label>{t("cvv")}</label>
                  <input
                    type="text"
                    maxLength="3"
                    value={cvv}
                    onChange={(e) => {
                      setCvv(e.target.value);
                    }}
                  />
                  {errors?.cvv && (
                    <p style={{color: "red", fontSize: 12, marginTop: 5}}>
                      {errors?.cvv}
                    </p>
                  )}
                </div>

                <button
                  className="btn btn-primary w-full mt-4"
                  onClick={(e) => handelNewCardSubmit(e)}
                >
                  {t("add_card")}
                </button>
              </div>
            </div>

            {/* <div className="card">
              <div className="personalForm">
                  <Frames
                    config={{
                      publicKey: REACT_APP_CHECKOUT_PUBLIC_KEY,
                      localization: {
                        cardNumberPlaceholder: t('card_number'),
                        expiryMonthPlaceholder: t('MM'),
                        expiryYearPlaceholder: t('YY'),
                        cvvPlaceholder: t('cvv'),
                      },
                      style: {
                        base: {
                          fontSize: '17px',
                        },
                      },
                    }}
                    frameBlur={(e) => {
                      let  digit =  jQuery('#checkout-frames-card-number').val();
                      console.log(digit, 9999)

                    }}
                    cardTokenized={(e) => {createCustCreditCard(e.token) }}
                    cardValidationChanged={(e) => {
                      // let digit = jQuery('#checkout-frames-card-number').val();
                    }} 
                  >
                      
                  <div className="creditCardDiv">
                    <CardNumber maxlength={'15'}
                  
                    />
                    <ExpiryDate/>
                    <Cvv/>  
                  </div> 
                            
                  <button className="btn btn-primary w-full mt-4"
                    onClick={() => {creditCardSubmit()}}
                  >{t('submit')}
                  </button>
                </Frames>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AddCreditCard;
