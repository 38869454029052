import React, { useState,useEffect,forwardRef,useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next'; 
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

const AppointmentCartItemModal = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [appointmentCartModal, setAppointmentCartModal] = useState(false);
  const handleAppointmentCartModalShow = () => setAppointmentCartModal(true);
  const handleAppointmentCartModalClose = () => setAppointmentCartModal(false); 
  useEffect(() => {
    //handleAppointmentCartModalShow()
  }, []);
  useImperativeHandle(ref, () => ({
    handleShowAppointmentCartItem() {
      handleAppointmentCartModalShow(true);
      /* let mybookingUrl = (cart?.is_home_cleaning) ? '/frequency/checkout/details' : '/service/checkout/details?step=1&service_id='+cart._id;
      setTimeout(() => {
        setBookingUrl(mybookingUrl);
      }, 500); */
    }
  }),[props]);

  return (
    <div>
      {/* appointment cart Modal start */}
      <Modal show={appointmentCartModal} onHide={handleAppointmentCartModalClose} className="appointmentCart" id="appointmentCart">
          <Modal.Header className="appointmentCartModalHead">
            <button type="button" className="btn-close"></button>
          </Modal.Header>
          <Modal.Body className="appointmentCartModalBody">
            <div className="appointmentCartModal">                 
              <div className="p-4">            
                <p>{ t('finish_your_one_off_boooking') }</p>              
              </div>
              <div className="w-full d-flex btnGroup mb-2">
                <Link to="/" className="btn btn-primary finish-my-booking">{ t('finish_my_booking') }</Link>
              </div>
            </div>
          </Modal.Body>
        </Modal>  
        {/* appointment cart Modal end */}
    </div>
  );
})

export default AppointmentCartItemModal;
