import React, { useEffect, useRef} from "react";
import Header from "../components/Header";
import i18next from "i18next";
import {useTranslation} from "react-i18next";
// import LoginModal from "./LoginModal";
import { useNavigate} from "react-router-dom";
import Footer from "../components/Footer";
import {useDispatch, useSelector} from "react-redux";
import {getHomeDataAction} from "../actions/homeAction";
import LoginSignupModal from "../components/LoginSignupModal";

const About = () => {
  const {t} = useTranslation();
  let currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  const loginSignupRef = useRef();
  let navigate = useNavigate();
  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  const getAllServicesState = useSelector((state) => state.homeReducer);
  const {services} = getAllServicesState;
  const dispatch = useDispatch();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let listUrl = baseUrl + "/home/services";
  // useEffect(() => {
  //   dispatch(getHomeDataAction(listUrl));
  // }, [listUrl]);

  const currentLanguageCode = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";

  async function changeLanguage() {
    var selectedLanguage = "";
    if (currentLanguage && currentLanguage === "ar") {
      selectedLanguage = "en";
    } else {
      selectedLanguage = "ar";
    }
    localStorage.setItem("currentLanguage", selectedLanguage);
    i18next.changeLanguage(selectedLanguage);
  }

  async function viewServiceDetails(id, ui_type) {
    if (ui_type === "Frequency") {
      localStorage.setItem("frequencyTypeServiceDetailId", id);
    } else {
      if (
        localStorage.getItem("serviceDetailId") &&
        localStorage.getItem("serviceDetailId") !== id
      ) {
        localStorage.removeItem("bookAppointmentCartData");
        localStorage.removeItem("bookAppointmentDetails");
      }
      localStorage.setItem("serviceDetailId", id);
    }
    setTimeout(() => {
      navigate(
        ui_type === "Frequency"
          ? {pathname: "/frequency/checkout/details", state: id}
          : "/service/checkout/details?step=1&service_id=" + id
      );
    }, 500);
  }

  return (
    <>
      <Header />
      <LoginSignupModal ref={loginSignupRef} />
      <div className="aboutPage">
        <div className="problemsArea py-7">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="heading">
                  <h2>{t("consider_todo")}</h2>
                  <h5>{t("ppa")}</h5>
                  <p>{t("in_everyday_lives")}</p>
                  <h4>{t("talk_about")}</h4>
                  <p>{t("get_more")}</p>
                </div>
                <button
                  className="btn btn-primary "
                  onClick={() => {
                    let serviceIds = [];
                    serviceIds?.push("63ac38318a89315fd0fc4f6d");
                    navigate(
                      `/frequency/checkout/details?step=${currentUser ? 1 : 2}`,
                      {
                        state: {
                          serviceId: "63ac38318a89315fd0fc4f6d",
                          isBookNow: true,
                          isLogin: currentUser ? true : false,
                          ui_type: "Frequency"
                        }
                      }
                    );
                  }}
                >
                  {t("lets_get_started")}
                </button>
              </div>
              <div className="col-lg-6 col-md-6 ">
                <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/cleaning.jpg.webp"  className="w-full" />
              </div>
            </div>
          </div>
        </div>

        <div className="py-7 whorighthands ">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/who.jpg.webp" className="w-full" />
              </div>
              <div className="col-lg-7 pl-5">
                <div className="ps-lg-4 mt-4 mt-lg-0">
                  <div className="heading mb-4">
                    <h2>{t("who_is_rh")}</h2>
                    <h5>{t("rh_all_in_one")}</h5>
                    <p>{t("easiest_way_to_book")}</p>
                  </div>
                  <ul>
                    <li>{t("top_rated_prof")}</li>
                    <li>{t("appt_available")}</li>
                    <li>{t("360_degree")}</li>
                    <li>{t("take_care_of_your_home")}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='nowCheckArea'>
    <div className='container'>
        <div className='nowcheckContent'>
            <div className='row align-items-center'>
            <div className='col-lg-9 col-md-9'>
                <h2>Now Check That off your Right Hands-list.</h2>
                <p>Easy On-The-Go Booking for Home Cleaning & Home Maintenance</p>
            </div>
            <div className='col-lg-3 col-md-3'>
            <button className="btn btn-secondary btnLarge ">Let's Get Started</button>
            </div>
            </div>
        </div>
    </div>
    </div> */}
        <div className=" aboutProblem py-7">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="heading text-start">
                  <h2>{t("why_choose")} </h2>
                  <h5>{t("we_dont_talk")}</h5>
                  <p>{t("promises_to_provide")}</p>
                </div>
              </div>
              {/* <div className="col-lg-6 col-md-6 ">
                <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/family.jpg.webp"  className='w-full'/>
              </div> */}
            </div>
          </div>
        </div>
        <div className="ourProcess py-7">
          <div className="container">
            <div className="heading mb-0">
              <h2>{t("what_do_we_do")}</h2>
              <h5>{t("what_services")} </h5>
            </div>
            <div className="row mt-3 mt-md-5">
              {services?.length > 0 ? (
                services.map((service, index) => {
                  return (
                    <div className="col-lg-4 col-sm-6">
                      <div className="serviceDiv">
                        <img
                          src={
                            service?.icon
                              ? service?.icon
                              : "https://dvdljkkxpxqo3.cloudfront.net/images/no_image.jpg.webp"
                          }
                          alt=""
                        />
                        <h3>
                          {currentLanguage === "en"
                            ? service.name
                            : service.name_arabic}
                        </h3>
                        <p>
                          {currentLanguage === "en"
                            ? service.description
                            : service.description_arabic}
                        </p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className="">{t("no_services_found")}</p>
              )}
            </div>
          </div>
        </div>

        <div className="easyBook py-7">
          <div className="container">
            <div className="heading mb-0">
              <h2>{t("our_mission")}</h2>
              <p>{t("through_innovation")}</p>
              <button
                className="btn btn-primary mt-3 "
                onClick={() => {
                  let serviceIds = [];
                  serviceIds?.push("63ac38318a89315fd0fc4f6d");
                  navigate(
                    `/frequency/checkout/details?step=${currentUser ? 1 : 2}`,
                    {
                      state: {
                        serviceId: "63ac38318a89315fd0fc4f6d",
                        isBookNow: true,
                        isLogin: currentUser ? true : false,
                        ui_type: "Frequency"
                      }
                    }
                  );
                }}
              >
                {t("get_started")}
              </button>
            </div>
          </div>
        </div>

        <div className="aboutForm py-7 d-none">
          <div className="container">
            <div className="heading ">
              <h2>HAVE A BIGGER PROBLEM?</h2>
              <p>
                We can do that too. Renovations, additions, you name it. TOD has
                you covered. Fill out the form to request a quote!
              </p>
            </div>
            <form className="formSec">
              <div className="row">
                <div className="col-lg-6">
                  <input
                    type="text"
                    placeholder="First name"
                    className="form-control mb-5"
                  />
                </div>
                <div className="col-lg-6">
                  <input
                    type="text"
                    placeholder="Last name"
                    className="form-control mb-5"
                  />
                </div>
                <div className="col-lg-6">
                  <input
                    type="text"
                    placeholder="Email"
                    className="form-control mb-5"
                  />
                </div>
                <div className="col-lg-6">
                  <input
                    type="number"
                    placeholder="Phone"
                    className="form-control mb-5"
                  />
                </div>
                <div className="col-lg-12">
                  <textarea
                    placeholder="Tell us about your problem"
                    className="form-control mb-2"
                  ></textarea>
                </div>
                <div className="col-lg-12 ">
                  <h3 className="py-3">Subscribe to Mailing List?</h3>
                  <label className="py-2">
                    <input type="checkbox" className="me-2 " />
                    Yes
                  </label>
                </div>
                <div className="col-lg-12 ">
                  <button className="btn btn-secondary mt-3 btnLarge px-5">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default About;
