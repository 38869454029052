import React, { useState } from "react";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
const ServiceTabs = () => {
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  const [num, setnum] = useState(1);
 

  const incNum = () => {
    setnum(num + 1);
  };

  const incMin = () => {
    if (num > 0) {
      setnum(num - 1);
      setActive(!isActive);
    }
  };
  const [isActive, setActive] = useState("false");

  const ToggleClass = () => {
    setActive(!isActive);
  };


  return (
    <>
   
      <div className="  w-full pt-2 ">
              <div className="serviceTabContent">
          <div
            className="tabContent tabContentActive"
          >           

            <div className="serviceItem">
              <div data-bs-toggle="modal" data-bs-target="#ServicePopup">
                <h3>{num}Haircut</h3>
                <p>
                  Get a neat and slick cut from the comfort of your home in 30
                  minutes.
                </p>
              </div>
              <div className="amount align-items-center justify-content-between d-flex buttonadd">
                <p>
                  QAR 50: <span>QAR 70</span>
                </p>
                <button className={isActive ? "btn btn-primary show" : "btn btn-primary hide"}  onClick={ToggleClass}>
                  ADD +
                </button>
               
                <div className={isActive ? "hide" : "show"} >
                <div className="button-with-counter">
                  <i className="fa fa-minus" onClick={incMin}></i>
                  <span>{num}</span>
                  <i className="fa fa-plus" onClick={incNum}></i>
                </div>
                </div>
              </div>
            </div>
            <div className="serviceItem">
              <h3>Beard Trim & Shape</h3>
              <p>
                Get the trending beard shape and style from the most experienced
                stylists in town.
              </p>
              <div className="amount align-items-center justify-content-between d-flex">
                <p>
                  QAR 50: <span>QAR 70</span>
                </p>
                <button className=" btn btn-primary">ADD +</button>
              </div>
            </div>
            <div className="serviceItem">
              <h3>Clean Shave</h3>
              <p>
                Time to stop shaving your beard yourself. We will come to you
                and get it done in no time.
              </p>
              <div className="amount align-items-center justify-content-between d-flex">
                <p>
                  QAR 50: <span>QAR 70</span>
                </p>
                <button className=" btn btn-primary">ADD +</button>
              </div>
            </div>
            <div className="serviceItem">
              <h3>Haircut & Beard</h3>
              <p>
                Get a fresh new haircut and a neat beard trimming from one of
                the best stylist in town
              </p>
              <div className="amount align-items-center justify-content-between d-flex">
                <p>
                  QAR 50: <span>QAR 70</span>
                </p>
                <button className=" btn btn-primary">ADD +</button>
              </div>
            </div>
          </div>
          <div
            className={
              toggleState === 2 ? "tabContent tabContentActive" : "tabContent"
            }
          >
            <h3>Packages</h3>
            <img alt="" src="http://localhost:3000https://dvdljkkxpxqo3.cloudfront.net/images/hair.jpg.webp"/>

            <div className="serviceItem">
              <h3>{num}Haircut</h3>
              <p>
                Get a neat and slick cut from the comfort of your home in 30
                minutes.
              </p>
              <div className="amount align-items-center justify-content-between d-flex buttonadd">
                <p>
                  QAR 50: <span>QAR 70</span>
                </p>
                <button className=" btn btn-primary addBtn" onClick={incNum}>
                  ADD +
                </button>
                <div className="button-with-counter">
                  <i className="fa fa-minus"></i>
                  <span>1</span>
                  <i className="fa fa-plus"></i>
                </div>
              </div>
            </div>
            <div className="serviceItem">
              <h3>Beard Trim & Shape</h3>
              <p>
                Get the trending beard shape and style from the most experienced
                stylists in town.
              </p>
              <div className="amount align-items-center justify-content-between d-flex">
                <p>
                  QAR 50: <span>QAR 70</span>
                </p>
                <button className=" btn btn-primary">ADD +</button>
              </div>
            </div>
            <div className="serviceItem">
              <h3>Clean Shave</h3>
              <p>
                Time to stop shaving your beard yourself. We will come to you
                and get it done in no time.
              </p>
              <div className="amount align-items-center justify-content-between d-flex">
                <p>
                  QAR 50: <span>QAR 70</span>
                </p>
                <button className=" btn btn-primary">ADD +</button>
              </div>
            </div>
            <div className="serviceItem">
              <h3>Haircut & Beard</h3>
              <p>
                Get a fresh new haircut and a neat beard trimming from one of
                the best stylist in town
              </p>
              <div className="amount align-items-center justify-content-between d-flex">
                <p>
                  QAR 50: <span>QAR 70</span>
                </p>
                <button className=" btn btn-primary">ADD +</button>
              </div>
            </div>
          </div>
          <div
            className={
              toggleState === 3 ? "tabContent tabContentActive" : "tabContent"
            }
          >
            <h3>Massages</h3>
            <img alt="" src="http://localhost:3000https://dvdljkkxpxqo3.cloudfront.net/images/hair.jpg.webp"/>

            <div className="serviceItem">
              <h3>{num}Haircut</h3>
              <p>
                Get a neat and slick cut from the comfort of your home in 30
                minutes.
              </p>
              <div className="amount align-items-center justify-content-between d-flex buttonadd">
                <p>
                  QAR 50: <span>QAR 70</span>
                </p>
                <button className=" btn btn-primary addBtn" onClick={incNum}>
                  ADD +
                </button>
                <div className="button-with-counter">
                  <i className="fa fa-minus"></i>
                  <span>1</span>
                  <i className="fa fa-plus"></i>
                </div>
              </div>
            </div>
            <div className="serviceItem">
              <h3>Beard Trim & Shape</h3>
              <p>
                Get the trending beard shape and style from the most experienced
                stylists in town.
              </p>
              <div className="amount align-items-center justify-content-between d-flex">
                <p>
                  QAR 50: <span>QAR 70</span>
                </p>
                <button className=" btn btn-primary">ADD +</button>
              </div>
            </div>
            <div className="serviceItem">
              <h3>Clean Shave</h3>
              <p>
                Time to stop shaving your beard yourself. We will come to you
                and get it done in no time.
              </p>
              <div className="amount align-items-center justify-content-between d-flex">
                <p>
                  QAR 50: <span>QAR 70</span>
                </p>
                <button className=" btn btn-primary">ADD +</button>
              </div>
            </div>
            <div className="serviceItem">
              <h3>Haircut & Beard</h3>
              <p>
                Get a fresh new haircut and a neat beard trimming from one of
                the best stylist in town
              </p>
              <div className="amount align-items-center justify-content-between d-flex">
                <p>
                  QAR 50: <span>QAR 70</span>
                </p>
                <button className=" btn btn-primary">ADD +</button>
              </div>
            </div>
          </div>
          <div
            className={
              toggleState === 4 ? "tabContent tabContentActive" : "tabContent"
            }
          >
            <h3>Facials</h3>
            <img alt="" src="http://localhost:3000https://dvdljkkxpxqo3.cloudfront.net/images/hair.jpg.webp"/>

            <div className="serviceItem">
              <h3>{num}Haircut</h3>
              <p>
                Get a neat and slick cut from the comfort of your home in 30
                minutes.
              </p>
              <div className="amount align-items-center justify-content-between d-flex buttonadd">
                <p>
                  QAR 50: <span>QAR 70</span>
                </p>
                <button className=" btn btn-primary addBtn" onClick={incNum}>
                  ADD +
                </button>
                <div className="button-with-counter">
                  <i className="fa fa-minus"></i>
                  <span>1</span>
                  <i className="fa fa-plus"></i>
                </div>
              </div>
            </div>
            <div className="serviceItem">
              <h3>Beard Trim & Shape</h3>
              <p>
                Get the trending beard shape and style from the most experienced
                stylists in town.
              </p>
              <div className="amount align-items-center justify-content-between d-flex">
                <p>
                  QAR 50: <span>QAR 70</span>
                </p>
                <button className=" btn btn-primary">ADD +</button>
              </div>
            </div>
            <div className="serviceItem">
              <h3>Clean Shave</h3>
              <p>
                Time to stop shaving your beard yourself. We will come to you
                and get it done in no time.
              </p>
              <div className="amount align-items-center justify-content-between d-flex">
                <p>
                  QAR 50: <span>QAR 70</span>
                </p>
                <button className=" btn btn-primary">ADD +</button>
              </div>
            </div>
            <div className="serviceItem">
              <h3>Haircut & Beard</h3>
              <p>
                Get a fresh new haircut and a neat beard trimming from one of
                the best stylist in town
              </p>
              <div className="amount align-items-center justify-content-between d-flex">
                <p>
                  QAR 50: <span>QAR 70</span>
                </p>
                <button className=" btn btn-primary">ADD +</button>
              </div>
            </div>
          </div>
          <div
            className={
              toggleState === 5 ? "tabContent tabContentActive" : "tabContent"
            }
          >
            <h3>Dyes & Coloring</h3>
            <img alt="" src="http://localhost:3000https://dvdljkkxpxqo3.cloudfront.net/images/hair.jpg.webp"/>

            <div className="serviceItem">
              <h3>{num}Haircut</h3>
              <p>
                Get a neat and slick cut from the comfort of your home in 30
                minutes.
              </p>
              <div className="amount align-items-center justify-content-between d-flex buttonadd">
                <p>
                  QAR 50: <span>QAR 70</span>
                </p>
                <button className=" btn btn-primary addBtn" onClick={incNum}>
                  ADD +
                </button>
                <div className="button-with-counter">
                  <i className="fa fa-minus"></i>
                  <span>1</span>
                  <i className="fa fa-plus"></i>
                </div>
              </div>
            </div>
            <div className="serviceItem">
              <h3>Beard Trim & Shape</h3>
              <p>
                Get the trending beard shape and style from the most experienced
                stylists in town.
              </p>
              <div className="amount align-items-center justify-content-between d-flex">
                <p>
                  QAR 50: <span>QAR 70</span>
                </p>
                <button className=" btn btn-primary">ADD +</button>
              </div>
            </div>
            <div className="serviceItem">
              <h3>Clean Shave</h3>
              <p>
                Time to stop shaving your beard yourself. We will come to you
                and get it done in no time.
              </p>
              <div className="amount align-items-center justify-content-between d-flex">
                <p>
                  QAR 50: <span>QAR 70</span>
                </p>
                <button className=" btn btn-primary">ADD +</button>
              </div>
            </div>
            <div className="serviceItem">
              <h3>Haircut & Beard</h3>
              <p>
                Get a fresh new haircut and a neat beard trimming from one of
                the best stylist in town
              </p>
              <div className="amount align-items-center justify-content-between d-flex">
                <p>
                  QAR 50: <span>QAR 70</span>
                </p>
                <button className=" btn btn-primary">ADD +</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      
    </>
  );
};

export default ServiceTabs;
