export const getSubscriptionReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_SUBS_PLAN_LIST_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_SUBS_PLAN_LIST_SUCCESS':
            return {
                subscription_list:action.payload.subscription_list,
                loading: false,
                success: true
            }
        case'GET_SUBS_PLAN_LIST_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        case'POST_BUY_PACKAGE_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'POST_BUY_PACKAGE_SUCCESS':
            return {
                loading: false,
                success: true
            }
        case'POST_BUY_PACKAGE_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        default:
            return state
    }
}