import React, { Component }  from 'react';
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
const Success = () => {
    return (
      <>
       <Header/>
       <div className='successPage'>
        <div className="userContainer">
             <div className="successCard ">
               <span className='fa fa-check checkicon'></span>
               <div className='mt-4 mb-4'>
               <h2>Your Booking Successful</h2>
               </div>
               <div className="card-content ">
                   <h3>Details</h3>
                   <ul>
                    <li>Reference Code <span>REFNO124588546249</span>
                    </li>
                    <li>Service <span>Home Cleaning</span>
                    </li>
                    <li>Details <span>Bi-weekly</span>
                    </li>
                   </ul>
                </div>

                <div className="card-content ">
                   <h3>Date & Time</h3>
                   <ul>
                    <li>Date <span>06/05/2022</span>
                    </li>
                    <li>Start Time <span>12:00Pm-12:30PM</span>
                    </li>
                   
                   </ul>
                </div>

                <div className="card-content ">
                   <h3>Address</h3>
                   <ul>
                    <li>Address<span>56,Noida</span>
                    </li>
                    </ul>
                </div>

                <div className="card-content ">
                   <h3>Price Details</h3>
                   <ul>
                    <li>Total <strong>QAR 266.00</strong></li>
                    <li>Paid with<span>Visa</span>
                    
                    </li>
                   
                   </ul>
                   
                </div>
                <div className="card-content justify-content-center ">
                <button className='btn btn-primary'>Go to Next Booking</button>
                </div>
                

                 </div>
            </div>
       </div>
        <div className='successPage'>
     
        <div className="userContainer">
             <div className="successCard ">
               <span className='fa fa-check checkicon'></span>
               <div className='mt-4 mb-4'>
               <h2>Thank you for purchasing the package.</h2>
               </div>
               <div className="card-content ">
                   <h3>Details</h3>
                   <ul>
                    <li>Package Name <span>SHINY</span>
                    </li>
                    <li>Amount Get  <span>QAR 300</span>
                    </li>
                    <li>Date & Time   <span>06/14/2022</span>
                    </li>
                    <li>Paid Amount   <span>QAR 259</span>
                    </li>
                    <li>Paid with<span>Visa</span></li>
                   </ul>
                </div>               
                <div className="card-content justify-content-center ">
                <Link to="/"><button className='btn btn-primary'>Home</button></Link>
                </div>
                

                 </div>
            </div>
       </div>
      
    
       <Footer/>
      </>
    );
}

export default Success
