import React from "react";
import OwlCarousel from "react-owl-carousel";
import { useTranslation } from 'react-i18next';

function Testimonilas() {
  const options = {
    margin: 25,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    navText: [
      "<button type='button' class='slick-prev'><i class='fa fa-angle-left' aria-hidden='true'></i></button>",
      "<button type='button' class='slick-next'><i class='fa fa-angle-right' aria-hidden='true'></i></button>",
    ],

    nxet: "",
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },

      700: {
        items: 1,
      },

      1000: {
        items: 2,
      },
      1200: {
        items: 2,
      },
    },
  };
  const { t } = useTranslation();
  return (
    <>
      <div className="testimonialSec w-full pt-7 ">
        <div className="container">
          <div className="heading">
            <h5>{ t('everybody_loves') }</h5>
            <h2>{ t('what_customer_says') }</h2>
            <p>
            { t('excellent') } <span className="fiveStar">
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star-half-o"></i>
            </span> { t('over_reviews') }
            </p>
          </div>
          {/* <OwlCarousel
            className="owl-theme"
            items={2}
            loop
            margin={25}
            nav
            {...options}
          >
            <div className="item">
              <div className="d-flex testimonialDiv">
                <div className="testimonilaImage">
                  <img alt="" src="images/cutomer-image.jpg.webp" />
                </div>
                <div className="testimonialContent">
                  <img alt="" src="images/quote.png.webp" />
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <h3>Harriet Mitchel</h3>
                  <span>Web Developer</span>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="d-flex testimonialDiv">
                <div className="testimonilaImage">
                  <img alt="" src="images/cutomer-image2.jpg.webp" />
                </div>
                <div className="testimonialContent">
                  <img alt="" src="images/quote.png.webp" />
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <h3>Yousuf</h3>
                  <span>Manager</span>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="d-flex testimonialDiv">
                <div className="testimonilaImage">
                  <img alt="" src="images/cutomer-image2.jpg.webp" />
                </div>
                <div className="testimonialContent">
                  <img alt="" src="images/quote.png.webp" />
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <h3>Yousuf</h3>
                  <span>Manager</span>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="d-flex testimonialDiv">
                <div className="testimonilaImage">
                  <img alt="" src="images/cutomer-image.jpg.webp" />
                </div>
                <div className="testimonialContent">
                  <img alt="" src="images/quote.png.webp" />
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <h3>Yousuf</h3>
                  <span>Manager</span>
                </div>
              </div>
            </div>
          </OwlCarousel> */}
          {/* <section className="regular slider p-0">
            <div>
              <div className="d-flex testimonialDiv">
                <div className="testimonilaImage">
                  <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/cutomer-image.jpg.webp" />
                </div>
                <div className="testimonialContent">
                  <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/quote.png.webp" />
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <h3>Harriet Mitchel</h3>
                  <span>Web Developer</span>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex testimonialDiv">
                <div className="testimonilaImage">
                  <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/cutomer-image.jpg.webp" />
                </div>
                <div className="testimonialContent">
                  <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/quote.png.webp" />
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <h3>Yousuf</h3>
                  <span>Manager</span>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex testimonialDiv">
                <div className="testimonilaImage">
                  <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/cutomer-image2.jpg.webp" />
                </div>
                <div className="testimonialContent">
                  <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/quote.png.webp" />
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <h3>Harriet Mitchel</h3>
                  <span>Web Developer</span>
                </div>
              </div>
            </div>
          </section> */}
        </div>
      </div>
    </>
  );
}

export default Testimonilas;
