import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useTranslation } from 'react-i18next';

const RescheduleAppointmentPolicy = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {state} = useLocation();
  const handelReschedule = () =>{
    navigate("/my-account/reschedule-appointment", {
      state: state
    });
  }
  return (
    <>
      <Header />
      <div className="py-5">
        <div className="userContainer">
          <div className="reschduleAppoiintments">
            <div className="heading">
              <Link to="/my-account/appointments">
                <h5>{t('appointments')}</h5> 
              </Link>
              <h2>{t('reschedule_appointment')}</h2> 
            </div>
            <div className="card">
              <div className="personalForm">
                <div className="datesec"> 
                  <h3>{ t('can_reschedule_booking_within_ten_minutes') }</h3>
                </div>
                <div className="timeButtons view_policy_link"> 
                  <p className="view_our_policy">
                  <Link to={"/cancellation/policy"} onClick={() => window.scrollTo(0, 0)}>{ t('view_our_policy') }</Link>
                  </p>

                </div>
                <div
                onClick={()=> handelReschedule()}
                //  to="/my-account/reschedule-appointment"
                >
                  <button className="btn btn-primary w-full">
                    {t('reschedule')} 
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RescheduleAppointmentPolicy;
