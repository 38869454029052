import React from "react";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import Loader from "../../../../components/Loader";

const BlankLoading = () => {
    setTimeout(() => {
        let page = '/';
        if(localStorage.getItem('discoverBookingPage')){
            page = localStorage.getItem('discoverBookingPage');
        }
        window.location.href = page;
    }, 300);
  return (
    <>
      <Header />
      <Loader />
      <div className="blogPAGE">
        <div className="container">
          <div className="blogDetail">
            <div className="blogPublished">
              <div className="publishDate BlankLoadingBody"></div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlankLoading;
