import axios from 'axios'
import {authHeader, handlingNonauthErrors} from "../helpers/helpers";

export const getHelpAction = (baseUrl,values) => dispatch => {

    dispatch({type: 'GET_HELP_LIST_REQUEST'})
    axios({
        method: 'GET',
        headers:authHeader(),
        url: baseUrl,
        data: values
    }).then((res) => {
        dispatch({type: 'GET_HELP_LIST_SUCCESS', payload: {
            help_data:res.data.list
        }});
    }).catch((err) => {
        dispatch({type: 'GET_HELP_LIST_FAILED', payload: err});
        handlingNonauthErrors(err)
        
    })
}