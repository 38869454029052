import React, { useEffect } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import AppDownload from "../../components/AppDownload";
import Loader from "../../components/Loader";
import {useDispatch, useSelector} from "react-redux";
import { getHelpAction } from "../../actions/HelpAction";
import { useTranslation } from 'react-i18next';
import jQuery from 'jquery';

const Help = () => {
    const getHelpState = useSelector(state => state.getHelpReducer)
    const { loading, help_data } = getHelpState;
    const dispatch = useDispatch();
    let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
    let listUrl = baseUrl + "/help/category/front/list";
    let navigate = useNavigate(); 
    const currentLanguage = localStorage.getItem('currentLanguage') ? localStorage.getItem('currentLanguage') : 'en';
    useEffect(() => {
        dispatch(getHelpAction(listUrl,{}));
    }, [listUrl,dispatch]);
  const { t } = useTranslation();

  function viewStaticContent(id){
    jQuery("html, body").animate({ scrollTop: 0 }, "slow");
    setTimeout(() => {
      navigate(id);
    }, 500)
  };
 
  return (
    <>
      <Header />
      {loading ? <Loader /> : ""}
      <div className="py-5">
        <div className="userContainer">
          <div className="helpPage helpPage2">
            <div className="heading">
              <h5>{ t('help') }</h5>
              <h2>{ t('help') }</h2> 
            </div>
            <div className="card">
              <div className="personalForm">
                <div className="helpItem">
                  <h3>{ t('how_can_we_help_you') }</h3>
                </div>
              </div>
              <div className="accountSettings">
                <ul className="list">
                  {help_data?.length > 0
                  ? help_data.map((help, index) => {
                      if(help?.questions?.length > 0){
                        return (
                          <li key={index}>
                            <NavLink to={"/my-account/sub/questions/"} state={{questions: help.questions , name: help.name, name_arabic: help.name_arabic}} className="dropdown-item">
                              {(currentLanguage === "en" ? help.name : help.name_arabic)}
                              <i className="fa fa-angle-right"></i>
                            </NavLink>
                          </li>
                          );
                      }
                    }): ""}
                  <li>
                    <div className="termsLink">
                      <Link to="#" onClick={() => viewStaticContent('../terms/conditions')}>{t("terms_conditions")}</Link>
                      <Link to="#" onClick={() => viewStaticContent('../privacy/policy')}>{t("privacy_policy")}</Link>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppDownload />
      <Footer />
    </>
  );
};

export default Help;
