
export const myFavouriteProsReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_FAVOURITE_LIST_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_FAVOURITE_LIST_SUCCESS':
            return {
                favourites:action.payload.favourites,
                loading: false,
                success: true
            }
        case'GET_FAVOURITE_LIST_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        case'POST_UNFAVOURITE_REQUEST':
            return {
                ...state,
                loading: true,
                page_loadings: true,
            }
        case'POST_UNFAVOURITE_SUCCESS':
            return {
                loading: false,
                page_loadings: false,
                success: true
            }
        case'POST_UNFAVOURITE_FAILED':
            return {
                ...state,
                loading: false,
                page_loadings: false,
                error: true
            }
        default:
            return state
    }
}
