import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {NavLink, Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getSubscriptionPlanAction} from "../../actions/subscriptionPlanAction";
import {getRHClubAction} from "../../actions/homeAction";
import {
  cancelMemberShipAction,
  customerDetailAction
} from "../../actions/homeAction";
import {buyMemberShipAction} from "../../actions/buyMemberShipAction";
import Loader from "../../components/Loader";
import jQuery from "jquery";
import {toast} from "react-toastify";
import { BuyOnline } from "../../helpers/bookingAPIsteps";
import { Button } from "react-bootstrap";
import { hasStripePayment } from "../../helpers/helpers";

const moment = require("moment");

const PackageSlider = () => {
  let navigate = useNavigate();
  const [pageLoading, setPageLoading] = useState(false);
  const [isBuyAgreed, setIsBuyAgreed] = React.useState("disabled-link");
  const getSubscriptionState = useSelector(
    (state) => state.getSubscriptionReducer
  );
  const {loading, subscription_list} = getSubscriptionState;
  const dispatch = useDispatch();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let listUrl = baseUrl + "/subscription/plan/list";
  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
    const getAllCountryState = useSelector((state) => state.countryReducer);
    const {country_list} = getAllCountryState;
    const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  useEffect(() => {
    dispatch(getSubscriptionPlanAction(listUrl, {}));
  }, [listUrl]);
  const {t} = useTranslation();
  const buyMemberShip = async (price) => { 
    let onlinePayUrl = `${baseUrl}/appointment/generate/payment/link/`
    const data ={
      "amount": price,
      "name": currentUser?.name,
      "phone":`${currentUser?.country_code}${currentUser?.mobile}`,
      "email": currentUser.email,
      "reference_no": currentUser?._id,
      "custom":"memberShip",
      isStripe: hasStripePayment(localStorage.getItem("userCountryId"), country_list)
      ? true
      : false
   }
    try{
      const stepOneData = await BuyOnline({
        baseUrl: onlinePayUrl,
        data
      });
    if(stepOneData?.data){
      window.location.href = stepOneData?.data?.payUrl;
    }

    
     
     
     
      //  setPayUrl(stepOneData?.data?.payUrl)
      // setModalVisible(true)
    }catch (error) {
      // Handle errors here
      console.error("stepOneData-error", error);
    }
    // let buyMemberShipUrl = baseUrl+"/customer/buy-rhclub-membership";
   
    // let rh_club_id = localStorage.getItem('userCountryId');
    // // console.log("rh_club_id", rh_club_id);
    // let values = {
    //   rh_club_id: rh_club_id,
    //   source_id: useCard
    // } 
    // dispatch(buyMemberShipAction(buyMemberShipUrl,values,setPageLoading, from, setIsBuyMemberShip));
  }

  const getRHClubState = useSelector((state) => state.rhClubReducer);
  const {rh_club} = getRHClubState;
  let rhClubUrl =
    baseUrl +
    "/country/edit/" +
    (localStorage.getItem("userCountryId")
      ? localStorage.getItem("userCountryId")
      : "");
  useEffect(() => {
    dispatch(getRHClubAction(rhClubUrl));
  }, [rhClubUrl, dispatch]);

  const getCustomerDetailState = useSelector((state) => state.customerReducer);
  const {customerMembership} = getCustomerDetailState;
  let customerMembershipUrl = baseUrl + "/customer/get/profile";
  useEffect(() => {
    dispatch(customerDetailAction(customerMembershipUrl));
  }, [customerMembershipUrl, dispatch]);

  const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    stagePadding: 80,
    dots: false,
    autoplay: false,
    navText: [
      "<button type='button' class='slick-prev'><i class='fa fa-angle-left' aria-hidden='true'></i></button>",
      "<button type='button' class='slick-next'><i class='fa fa-angle-right' aria-hidden='true'></i></button>"
    ],
    nxet: "",
    smartSpeed: 2000,

    responsive: {
      0: {
        items: 1,
        stagePadding: 0
      },
      400: {
        items: 1,
        stagePadding: 0
      },
      600: {
        items: 1
      },

      700: {
        items: 1
      },

      1000: {
        items: 1
      },
      1200: {
        items: 1
      }
    }
  };


  const checkBuyAgreed = (value) => {
    if (value) {
      setIsBuyAgreed("");
    } else {
      setIsBuyAgreed("disabled-link");
    }
  };

  const [isCancelAgreed, setIsCancelAgreed] = React.useState("disabled-link");
  const checkCancelAgreed = (value) => {
    if (value) {
      setIsCancelAgreed("");
    } else {
      setIsCancelAgreed("disabled-link");
    }
  };

  // const buyMemberShip = (e) => {
  //   let buyMemberShipUrl = baseUrl + "/customer/buy-rhclub-membership";
  //   let rh_club_id = localStorage.getItem("userCountryId");
  //   let values = {
  //     rh_club_id: rh_club_id
  //   };
  //   dispatch(buyMemberShipAction(buyMemberShipUrl, values, setPageLoading));
  // };

  const cancelMemberShip = (e) => {
    let cancelMemberShipUrl = baseUrl + "/customer/cancel-membership";
    let values = {
      membership_id: customerMembership._id
    };
    dispatch(cancelMemberShipAction(cancelMemberShipUrl, values, t));
  };

  const current_date = moment().format("YYYY-MM-DD 00:00:00");
  const date1 = new Date(customerMembership?.createdAt);
  const date2 = new Date(current_date);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return (
    <>
      {pageLoading ? <Loader /> : ""}
      <div className="serviceBanner w-full">
        <div className="container px-4">
          <div className="packageSlider superPackage">
            <img
              src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/rhclub.png.webp"
              style={{width: "auto", margin: "0 auto 16px"}}
            />
            <h3>{t("rh_club")}</h3>
            {(!customerMembership || customerMembership?.isCanceled) && (
              <>
                {rh_club?.rhClub ? (
                  <h5>
                    {t("pay")} {rh_club?.rhPrice} {rh_club?.currency}{" "}
                    {t("and_get")}{" "}
                    <b style={{color: "#432375"}}>
                      {" "}
                      {rh_club?.rewards} {rh_club?.currency}
                    </b>
                  </h5>
                ) : (
                  <h5>{t("no_membership")}</h5>
                )}
                <label className="form-check-label" htmlFor="oneTime"></label>
                <hr />
              </>
            )}

            {customerMembership && !customerMembership?.isCanceled && (
              <>
                <h5>
                  {t("member_id")} : {customerMembership?.memberId}
                </h5>
                <h5>
                  {t("renewal_date")} :{" "}
                  {moment(customerMembership?.expiry_at).format("DD-MMM-YYYY")}
                </h5>
                {t("agree_to_rh")}{" "}
                <Link
                  to={"/rh-club/policy"}
                  target="_blank"
                  onClick={() => window.scrollTo(0, 0)}
                  style={{color: "blue"}}
                >
                  {t("policy")}.
                </Link>
                <hr />
              </>
            )}

            {((rh_club?.rhClub && !customerMembership) ||
              customerMembership?.isCanceled) && (
              <>
                <div className="checkboxItem">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="buyNow"
                      name="buyNow"
                      defaultChecked={false}
                      onChange={(ev) => checkBuyAgreed(ev.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="oneTime">
                      {t("agree_to_rh")}{" "}
                      <Link
                        to={"/rh-club/policy"}
                        target="_blank"
                        onClick={() => window.scrollTo(0, 0)}
                        style={{color: "blue"}}
                      >
                        {t("policy")}.
                      </Link>
                    </label>
                  </div>
                </div>
                
                <Button
                  className={"btn btn-primary buy_now_sub " + isBuyAgreed}
                  onClick={()=> buyMemberShip(rh_club?.rhPrice)}
                 
                >
                  {t("buy_now")}
                </Button>
              </>
            )}

            {/* {(customerMembership && !customerMembership?.isCanceled && diffDays<=14) &&              
                  <>
                  <div className="checkboxItem" >
                   <div className="form-check">
                   <input
                     type="checkbox"
                     className="form-check-input"
                     id="cancelMembership"
                     name="cancelMembership"  
                     defaultChecked={false}
                     onChange={(ev) => checkCancelAgreed(ev.target.checked)}   
                   />
                    <label className="form-check-label" htmlFor="oneTime">
                    {t('agree_to_rh')} <Link to={"/rh-club/policy"}   target="_blank" onClick={() => window.scrollTo(0, 0)} style={{color:"blue"}}>{t('policy')}.</Link>
                   </label>
                  </div>            
                  </div>  
                  <NavLink className={"btn btn-danger buy_now_sub "+isCancelAgreed} to={""} onClick={cancelMemberShip}>
                     {t('cancel_membership')}
                  </NavLink>
                  </>
                } */}
          </div>
          {/* <OwlCarousel
            className="owl-theme"
            items={3}
            loop
            margin={25}
            nav
            {...options}
          >
          {subscription_list?.length > 0 ?
            subscription_list.map((subscription, index) => {
            return (
            <div className="item " key={index}>
              <div className="packageSlider superPackage">
                <h3>{(currentLanguage === "en" ? subscription.name : subscription.name_arabic)}</h3>
                <h6>QAR {(currentLanguage === "en" ? subscription.price : subscription.price_arabic)}</h6>
                <h2>QAR {(currentLanguage === "en" ? subscription.new_price : subscription.new_price_arabic)}</h2>
                <hr />
                <p>
                  {(currentLanguage === "en" ? subscription.description : subscription.description_arabic)}
                </p>
                <h5>{t('save')} {(currentLanguage === "en" ? subscription.discount : subscription.discount_arabic)}%</h5>
                <hr />
                <NavLink className="btn btn-primary buy_now_sub" to={"/my-account/package-checkout/"+subscription._id} onClick={() => window.scrollTo(0, 0)}>
                  {t('buy_now')}
                </NavLink>
              </div>
            </div>
            )}): ""}           
          </OwlCarousel> */}
        </div>
      </div>
    </>
  );
};

export default PackageSlider;
