import React, { useState } from "react";


const ServiceSearch = () => {
  const [show, setShow] = useState(true);
  return (
   <>
 <div className=' py-3 py-md-4 serviceSearchArea'>
 <div className='heading text-start mb-4 '>
 <h2>How can we help you?</h2>
 </div>
  <div className='serviceSearch'>
    <input type="text" placeholder="Search for services or tasks?" className="form-control"/>
    <span className='fa fa-search'/>
  </div>
  <div className= {`serviceReminder ${show ? "show" : ""}`}>
  <span className='fa fa-close' onClick={() => setShow(false)}></span>
   <div className='w-100 px-4'>
   <h3>End of Tenancy cleaning</h3>
    <p>Complete your booking!</p>
   </div>
   <span className='fa fa-angle-right'></span>
  </div>
 </div>
 
   </>
  )
}

export default ServiceSearch