/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-dupe-class-members */
/* eslint-disable no-unused-vars */
import React from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  // InfoWindow,
  Marker
} from "react-google-maps";
import {fromLatLng, setKey} from "react-geocode";
import Autocomplete from "react-google-autocomplete";
import jQuery from "jquery";
import Axios from "axios";
import {geocodeByPlaceId} from "react-places-autocomplete";

setKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
// Geocode.enableDebug();
class LocationSearchModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLanguage: localStorage.getItem("currentLanguage")
        ? localStorage.getItem("currentLanguage")
        : "en",
      address: "",
      building: "",
      street: "",
      city: "",
      area: "",
      state: "",
      countryShort: "",
      countryLong: "",
      zoom: 15,
      height: 601,
      mapPosition: {
        lat: localStorage.getItem("currentLatitude")
          ? parseFloat(localStorage.getItem("currentLatitude"))
          : 0.0,
        lng: localStorage.getItem("currentLongitude")
          ? parseFloat(localStorage.getItem("currentLongitude"))
          : 0.0
      },
      markerPosition: {
        lat: localStorage.getItem("currentLatitude")
          ? parseFloat(localStorage.getItem("currentLatitude"))
          : 0.0,
        lng: localStorage.getItem("currentLongitude")
          ? parseFloat(localStorage.getItem("currentLongitude"))
          : 0.0
      }
    };
  }
  showPosition(position) {
    navigator.geolocation.getCurrentPosition((position) => {
      this?.setState({
        mapPosition: {
          lat: localStorage.getItem("currentLatitude")
            ? parseFloat(localStorage.getItem("currentLatitude"))
            : position.coords.latitude,
          lng: localStorage.getItem("currentLongitude")
            ? parseFloat(localStorage.getItem("currentLongitude"))
            : position.coords.longitude
        },
        markerPosition: {
          lat: localStorage.getItem("currentLatitude")
            ? parseFloat(localStorage.getItem("currentLatitude"))
            : position.coords.latitude,
          lng: localStorage.getItem("currentLongitude")
            ? parseFloat(localStorage.getItem("currentLongitude"))
            : position.coords.longitude
        }
      });
    });
  }
  componentDidMount() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition);
      navigator.geolocation.getCurrentPosition((position) => {
        // console.log(position.coords.latitude, position.coords.longitude);
        this.setState(
          {
            mapPosition: {
              lat: position?.coords?.latitude
                ? position?.coords?.latitude
                : localStorage.getItem("currentLatitude")
                ? parseFloat(localStorage.getItem("currentLatitude"))
                : position.coords.latitude,
              lng: position?.coords?.longitude
                ? position?.coords?.longitude
                : localStorage.getItem("currentLongitude")
                ? parseFloat(localStorage.getItem("currentLongitude"))
                : position.coords.longitude
            },
            markerPosition: {
              lat: position?.coords?.latitude
                ? position?.coords?.latitude
                : localStorage.getItem("currentLatitude")
                ? parseFloat(localStorage.getItem("currentLatitude"))
                : position.coords.latitude,
              lng: position?.coords?.longitude
                ? position?.coords?.longitude
                : localStorage.getItem("currentLongitude")
                ? parseFloat(localStorage.getItem("currentLongitude"))
                : position.coords.longitude
            }
          },
          () => {
            localStorage.setItem("currentLatitude", position.coords.latitude);
            localStorage.setItem("currentLongitude", position.coords.longitude);
            jQuery("#latitude").val(position.coords.latitude);
            jQuery("#longitude").val(position.coords.longitude);
            fromLatLng(
              position.coords.latitude,
              position.coords.longitude
            ).then(
              (response) => {
                const address = response.results[0].formatted_address,
                  addressArray = response?.results[0]?.address_components,
                  street = this.getStreet(addressArray),
                  city = this.getCity(addressArray),
                  area = this.getArea(addressArray),
                  state = this.getState(addressArray),
                  building = this?.getBuildling(addressArray);
                const country = this.getCountry(addressArray);
                if (this.props.from === "NewAddress") {
                  this?.props?.setNewCustomerAddress({
                    address: address ? address : "",
                    building: building ? building : "",
                    area: area ? area : "",
                    street: street ? street : "",
                    city: city ? city : "",
                    state: state ? state : "",
                    countryShort: country ? country.shortName : "",
                    countryLong: country ? country.longName : "",
                    lat: response.results[0]?.geometry.location.lat,
                    lng: response.results[0]?.geometry?.location.lng
                  });
                }

                this.setState({
                  address: address ? address : "",
                  building: building ? building : "",
                  area: area ? area : "",
                  street: street ? street : "",
                  city: city ? city : "",
                  state: state ? state : "",
                  countryShort: country ? country.shortName : "",
                  countryLong: country ? country.longName : "",
                  lat: position.coords.latitude,
                  lng: position.coords.longitude
                });
              },
              (error) => {
                console.error(error);
              }
            );
          }
        );
      });
    } else {
      console.error("Geolocation is not supported by this browser!");
    }
  }

  getBuildling = (addressArray) => {
    let building = "";
    if (addressArray?.length) {
      for (let i = 0; i < addressArray?.length; i++) {
        if (addressArray[i].types[0]) {
          for (let j = 0; j < addressArray[i]?.types?.length; j++) {
            if (addressArray[i].types[j] === "premise") {
              building = addressArray[i]?.long_name;
              return building ? building : " ";
            }
          }
        }
      }
    }
  };

  getStreet = (addressArray) => {
    let street = "";
    for (let i = 0; i < addressArray?.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i]?.types?.length; j++) {
          if (addressArray[i].types[j] === "route") {
            street = addressArray[i].long_name;
            return street;
          }
        }
      }
    }
  };

  getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray?.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_2" === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  getArea = (addressArray) => {
    let area = "";
    for (let i = 0; i < addressArray?.length; i++) {
      for (let j = 0; j < addressArray[i]?.types?.length; j++) {
        if (
          "sublocality_level_1" === addressArray[i].types[j] ||
          "locality" === addressArray[i].types[j]
        ) {
          area = addressArray[i].long_name;
          return area;
        }
      }
    }
  };

  getState = (addressArray) => {
    let state = "";
    for (let i = 0; i < addressArray?.length; i++) {
      for (let i = 0; i < addressArray?.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };
  getCountry = (addressArray) => {
    let state = "";
    for (let i = 0; i < addressArray?.length; i++) {
      for (let i = 0; i < addressArray?.length; i++) {
        if (
          addressArray[i].types[0] &&
          "country" === addressArray[i].types[0]
        ) {
          const country = {
            longName: addressArray[i].long_name,
            shortName: addressArray[i].short_name
          };

          return country;
        }
      }
    }
  };

  onChange = (event) => {
    this.setState({[event.target.name]: event.target.value});
  };

  onInfoWindowClose = (event) => {};

  onMarkerDragEnd = (event) => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();
    jQuery("#latitude").val(newLat);
    jQuery("#longitude").val(newLng);
    jQuery(".mapToogleDiv").toggleClass("addressDetailsHide");
    jQuery(".mapToggle").toggleClass("hide");
    jQuery(".mapToggle").toggleClass("show");
    var address = "";
    var country_long_name = "";
    var country_short_name = "";
    fromLatLng(newLat, newLng).then(
      (response) => {
        const addressArray = response.results[0].address_components;
        const newAddress = response.results;
        for (let j = 0; j < newAddress[0].address_components?.length; j++) {
          if (
            newAddress[0].address_components[j] &&
            newAddress[0].address_components[j].types[0] !== "plus_code"
          ) {
            address += newAddress[0].address_components[j].long_name + ", ";
          }
          if (
            newAddress[0].address_components[j] &&
            newAddress[0].address_components[j].types[0] === "country"
          ) {
            country_long_name = newAddress[0].address_components[j].long_name;
            country_short_name = newAddress[0].address_components[j].short_name;
          }
        }
        const city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          building = this.getBuildling(addressArray),
          street = this.getStreet(addressArray),
          state = this.getState(addressArray);
        let addressDetails = {
          address: address,
          building: this.getBuildling(addressArray),
          street: this.getStreet(addressArray),
          city: this.getCity(addressArray),
          area: this.getArea(addressArray),
          state: this.getState(addressArray),
          latitude: newLat,
          longitude: newLng
        };

        this.props.getAutoFillAddress(addressDetails);
        if (this.props.from === "NewAddress") {
          this?.props?.setNewCustomerAddress({
            address: address ? address : "",
            building: building ? building : "",
            area: area ? area : "",
            street: street ? street : "",
            city: city ? city : "",
            state: state ? state : "",
            countryShort: country_short_name ? country_short_name : "",
            countryLong: country_long_name ? country_long_name : "",
            lat: newLat,
            lng: newLng
          });
        }
        this.setState({
          address: addressArray ? addressArray : "",
          area: area ? area : "",
          building: building ? building : "",
          street: street ? street : "",
          city: city ? city : "",
          state: state ? state : "",
          markerPosition: {
            lat: newLat,
            lng: newLng
          },
          mapPosition: {
            lat: newLat,
            lng: newLng
          }
        });
      },
      (error) => {
        console.error(error);
      }
    );
    setTimeout(function () {
      jQuery("#address").val(address);
      jQuery("#country").val(country_long_name);
      jQuery("#country_short_name").val(country_short_name);
    }, 500);
  };

  async handlerSearch(e) {
    e.preventDefault();
    let lat = this.state.mapPosition.lat;
    let lng = this.state.mapPosition.lng;
    let data = await Axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
    )
      .then(async (res) => {
        const address = res?.data?.results[0]?.formatted_address,
          addressArray = res?.data?.results[0]?.address_components,
          building = this.getBuildling(addressArray),
          street = this.getStreet(addressArray),
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          state = this.getState(addressArray),
          latValue = res?.data?.results[0]?.geometry.location.lat,
          lngValue = res?.data?.results[0]?.geometry.location.lng;
        const country = this.getCountry(addressArray);
        let save_continue = this.props.translatedValue("save_continue");

        let addressDetails = {
          address: address,
          building: this.getBuildling(addressArray),
          street: this.getStreet(addressArray),
          city: this.getCity(addressArray),
          area: this.getArea(addressArray),
          state: this.getState(addressArray),
          latitude: res?.data?.results[0]?.geometry.location.lat,
          longitude: res?.data?.results[0]?.geometry.location.lng
        };
        if (this.props.from === "NewAddress") {
          this?.props?.setNewCustomerAddress({
            address: address ? address : "",
            building: building ? building : "",
            area: area ? area : "",
            street: street ? street : "",
            city: city ? city : "",
            state: state ? state : "",
            countryShort: country ? country.shortName : "",
            countryLong: country ? country.longName : "",
            lat: latValue,
            lng: lngValue
          });
        }
        this.props.getAutoFillAddress(addressDetails);
        this.setState({
          address: address ? address : "",
          building: building ? building : "",
          street: street ? street : "",
          area: this.getArea(addressArray) ? this.getArea(addressArray) : "",
          city: city ? city : "",
          state: state ? state : "",
          markerPosition: {
            lat: latValue,
            lng: lngValue
          },
          mapPosition: {
            lat: latValue,
            lng: lngValue
          }
        });
        setTimeout(function () {
          jQuery("#address").val(address);
          jQuery("#latitude").val(latValue);
          jQuery("#longitude").val(lngValue);
          for (
            var i = 0;
            i < res?.data?.results[0]?.address_components?.length;
            i++
          ) {
            var addressType =
              res?.data?.results[0]?.address_components[i]?.types[0];

            if (addressType === "country") {
              jQuery("#country").val(
                res?.data?.results[0]?.address_components[i]?.long_name
              );
              jQuery("#country_short_name").val(
                res?.data?.results[0].address_components[i]?.short_name
              );
              if (this?.props?.saveContinue) {
                jQuery(".addressSubmit").html(save_continue);
              }
              jQuery(".mapToogleDiv").removeClass("addressDetailsHide");

              jQuery(".mapToggle").removeClass("hide");
              jQuery(".mapToggle").addClass("show");
              break;
            }
          }
          // jQuery('#address').val(address);
        }, 500);
      })
      .catch((e) => {
        console.log("Error", e);
      });
  }

  onPlaceSelected = async (place) => {
    // Place ID for Google HQ
    const placeId = place?.place_id;
    // try {
    //   const response = await Axios.get(
    //     `https://maps.googleapis.com/maps/api/place/details/json?placeid=${placeId}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
    //   );
    //      debugger
    //   if (response.data.status === "OK") {
    //     const lat = response.data.result.geometry.location.lat;
    //     const lng = response.data.result.geometry.location.lng;
    //      console.log('Latitude:', lat);
    //      console.log('Longitude:', lng);
    //   } else {
    //     console.log(
    //       "Place details request failed. Status:",
    //       response.data.status
    //     );
    //   }
    // } catch (error) {
    //   console.log("Error fetching place details:", error);
    // }

    const results = await geocodeByPlaceId(placeId);
    const lat = results[0].geometry.location.lat();
    const lng = results[0].geometry.location.lng();
    // let data = await Axios.get(
    //   `https://maps.googleapis.com/maps/api/place/details/json?placeid=${placeId}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
    // )
    //   .then((res) => res.json())
    //   .then((data) => {
    //     console.log("placeplaceplace",data?.result?.geometry?.location?.lat)
    //   }) .catch((e) => {
    //     console.log("placeplaceplaceError", e);
    //   });

    const address = place.formatted_address,
      addressArray = place.address_components,
      building = this.getBuildling(addressArray),
      street = this.getStreet(addressArray),
      city = this.getCity(addressArray),
      area = this.getArea(addressArray),
      state = this.getState(addressArray),
      latValue = place?.geometry?.location?.lat(),
      lngValue = place?.geometry?.location?.lng();
    let save_continue = this.props.translatedValue("save_continue");

    let addressDetails = {
      address: address,
      building: this.getBuildling(addressArray),
      street: this.getStreet(addressArray),
      city: this.getCity(addressArray),
      area: this.getArea(addressArray),
      state: this.getState(addressArray),
      latitude: place?.geometry?.location?.lat(),
      longitude: place?.geometry?.location?.lng()
    };

    this.props.getAutoFillAddress(addressDetails);
    setTimeout(function () {
      jQuery("#address").val(address);
      jQuery("#latitude").val(latValue);
      jQuery("#longitude").val(lngValue);
      for (var i = 0; i < place.address_components?.length; i++) {
        var addressType = place.address_components[i].types[0];
        if (addressType === "country") {
          jQuery("#country").val(place.address_components[i].long_name);
          jQuery("#country_short_name").val(
            place.address_components[i].short_name
          );
          if (this?.props?.saveContinue) {
            jQuery(".addressSubmit").html(save_continue);
          }
          jQuery(".mapToogleDiv").removeClass("addressDetailsHide");
          jQuery(".mapToggle").removeClass("hide");
          jQuery(".mapToggle").addClass("show");
          break;
        }
      }
      jQuery("#address").val(address);
    }, 500);

    // Set these values in the state.
    this.setState({
      address: address ? address : "",
      building: building ? building : "",
      street: street ? street : "",
      area: area ? area : "",
      city: city ? city : "",
      state: state ? state : "",
      markerPosition: {
        lat: latValue,
        lng: lngValue
      },
      mapPosition: {
        lat: latValue,
        lng: lngValue
      }
    });
  };

  render() {
    const AsyncMap = withScriptjs(
      withGoogleMap((props) => (
        <GoogleMap
          defaultZoom={this.state.zoom}
          defaultCenter={{
            lat: this.state.mapPosition.lat,
            lng: this.state.mapPosition.lng
          }}
        >
          <Marker
            google={this.props.google}
            name={"Dolores park"}
            draggable={true}
            onDragEnd={this.onMarkerDragEnd}
            position={{
              lat: this.state.mapPosition.lat,
              lng: this.state.mapPosition.lng
            }}
          />
          <Marker />

          {/* For Auto complete Search Box */}
          <div className="searchPlace">
            <Autocomplete
              style={{
                width: "100%",
                height: "40px",
                paddingLeft: "16px",
                marginTop: "2px",
                marginBottom: "2rem"
              }}
              // customProps={{  textAling: {this.state.currentLanguage === 'ar' ? 'right' : 'left'}}}
              fetchDetails={true}
              name="address"
              id="address"
              autoComplete="on"
              placeholder={this.props.translatedValue(
                "search_for_your_building_or_area"
              )}
              // placeholderStyle={ aling: {this.state.currentLanguage === 'ar' ? 'right' : 'left'}}
              onPlaceSelected={this.onPlaceSelected}
              options={{
                types: ["(regions)"],
                componentRestrictions: {country: []} /* "QA","IN" */,
                fields: [
                  "address_components",
                  "geometry",
                  "icon",
                  "name",
                  "place_id",
                  "formatted_address"
                ],
                types: ["establishment"]
              }}
            />
            {/* <i
              class="fa fa-map-marker"
              style={{
                position: "absolute",
                top: "10px",
                right: currentLanguage === "ar" ? 30 : "auto",
                left: currentLanguage === "ar" ? "auto" : 30,
                fontSize: 20
              }}
              aria-hidden="true"
            ></i> */}
            <i
              className="fa fa-search"
              style={{
                position: "absolute",
                right: this.state.currentLanguage === "ar" ? 20 : 0
              }}
            ></i>
            <button
              className="autosign"
              onClick={(e) => this.handlerSearch(e)}
              style={{
                position: "absolute",
                top: "5px",
                right: this.state.currentLanguage === "ar" ? "auto" : "15px",
                left: this.state.currentLanguage === "ar" ? 15 : "auto"
              }}
            >
              <img
                src="https://dvdljkkxpxqo3.cloudfront.net/images/gps.png.webp"
                height="30"
                width="30"
              />{" "}
            </button>
          </div>
        </GoogleMap>
      ))
    );

    return (
      <div style={{margin: "0 auto", maxWidth: 1000}}>
        <AsyncMap
          googleMapURL={
            "https://maps.googleapis.com/maps/api/js?key=" +
            process.env.REACT_APP_GOOGLE_MAPS_API_KEY +
            "&libraries=places&language=" +
            this.state.currentLanguage
          }
          loadingElement={<div style={{height: `100%`}} />}
          containerElement={<div style={{height: this.state.height}} />}
          mapElement={<div style={{height: `100%`}} />}
        />
      </div>
    );
  }
}

export default LocationSearchModal;
