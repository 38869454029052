/* eslint-disable */

import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {Frames, CardNumber, ExpiryDate, Cvv} from "frames-react";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import jQuery from "jquery";
import {createUpdateCustCreditCard} from "../../../../helpers/helpers";
const AddCardModal = ({handleAddNewCardClose}) => {
  const {t} = useTranslation();
  const [pageLoading, setPageLoading] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [name, setName] = useState("");
  const [expiryMonth, setExpiryMonth] = useState("");
  const [expiryYear, setExpiryYear] = useState("");
  const [cvv, setCvv] = useState("");
  const [errors, setErrors] = useState({
    cardNumber: "",
    expiry: "",
    cvv: ""
  });
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let REACT_APP_CHECKOUT_PUBLIC_KEY = process.env.REACT_APP_CHECKOUT_PUBLIC_KEY;
  useEffect(() => {
    validateFields();
  }, [cardNumber, expiryMonth, expiryYear, cvv]);

  // ValidationFields
  const validateFields = () => {
    let errors = {};

    if (!cardNumber || cardNumber.length !== 16) {
      errors.cardNumber = t("invalid_card");
    }

    // Expiry Month

    if (expiryMonth < 1 || expiryMonth > 12) {
      errors.expiry = t("invalid_month");
    }
    // Expiry Year
    let currentYear = new Date().getFullYear();

    if (expiryYear < currentYear) {
      errors.expiry = t("card_expired");
    }

    // CVV validation

    if (!cvv || cvv.length !== 3) {
      errors.cvv = t("cvv_expires");
    }

    setErrors(errors);
  };
  // Gnerate Card Token
  const simplifyResponseHandler = (data) => {
    var paymentForm = jQuery("#simplify-payment-form");
    jQuery(".error").remove();

    if (data.error) {
      // console.log(data.error);
      toast.error("Something went wrong. please try again.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      setPageLoading(false);
    } else {
      createCustCreditCard(data?.id);
    }
  };
  const handelCardNumber = (e) => {
    const input = e.target;
    const value = input.value.replace(/\D/g, ""); // Remove non-numeric characters
    const formattedValue = value.split("").reduce((seed, next, index) => {
      if (index !== 0 && !(index % 4)) seed += " ";
      return seed + next;
    }, "");

    // Set the formatted value directly to the input
    input.value = formattedValue;
    // Update the cardNumber state with the unformatted value
    setCardNumber(value);
  };

  // Card SUbmit
  const handelNewCardSubmit = (e) => {
    e.preventDefault();
    setPageLoading(true);
    SimplifyCommerce.generateToken(
      {
        key: "sbpb_ZmUzNjFjZjgtZWEzOS00MmZiLWE2MzQtNzU0M2MyOTZlNGMx",
        card: {
          number: cardNumber,
          cvc: cvv,
          expMonth: expiryMonth,
          expYear: expiryYear.substring(2)
        }
      },
      simplifyResponseHandler
    );
  };

  // Save Card QNB
  async function createCustCreditCard(cardToken) {
    let awaitRequestUrl = baseUrl + "/creditCard/create/qnbCustomer";
    setPageLoading(true);
    await createUpdateCustCreditCard(awaitRequestUrl, cardToken)
      .then((response) => {
        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        setPageLoading(false);
        handleAddNewCardClose();
      })
      .catch(function (error) {
        setPageLoading(false);
        toast.error("Something went wrong. please try again.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      });
  }
  return (
    <>
      <div className="py-5">
        <div className="userContainer">
          <div className="creditCard">
            <div className="heading">
              <Link to="/my-account/account-details">
                <h5>{t("account_details")}</h5>
              </Link>
              <h2>{t("add_credit_card")}</h2>
            </div>
            <div className="card">
              <div className="personalForm">
                <div className="form-group">
                  <label>Card Number</label>
                  <input
                    type="text"
                    placeholder="0000 0000 0000 0000"
                    // value={cardNumber}
                    onChange={(e) => {
                      handelCardNumber(e);
                    }}
                    maxLength="19"
                  />

                  {errors?.cardNumber && (
                    <p style={{color: "red", fontSize: 12, marginTop: 5}}>
                      {errors?.cardNumber}
                    </p>
                  )}
                </div>

                <div className="form-group">
                  <label>Name on Card</label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <label>Expiry</label>
                  <div style={{display: "flex"}}>
                    <input
                      style={{width: "50%", marginRight: 10}}
                      type="text"
                      placeholder="MM"
                      maxLength="2"
                      value={expiryMonth}
                      onChange={(e) => setExpiryMonth(e.target.value)}
                    />

                    <input
                      style={{width: "50%", marginRight: 5}}
                      type="text"
                      placeholder="YYYY"
                      maxLength="4"
                      value={expiryYear}
                      onChange={(e) => setExpiryYear(e.target.value)}
                    />
                  </div>
                  {errors?.expiry && (
                    <p style={{color: "red", fontSize: 12, marginTop: 5}}>
                      {errors?.expiry}
                    </p>
                  )}
                </div>

                <div className="form-group">
                  <label>CVV</label>
                  <input
                    type="text"
                    maxLength="3"
                    value={cvv}
                    onChange={(e) => {
                      setCvv(e.target.value);
                    }}
                  />
                  {errors?.cvv && (
                    <p style={{color: "red", fontSize: 12, marginTop: 5}}>
                      {errors?.cvv}
                    </p>
                  )}
                </div>

                <button
                  className="btn btn-primary w-full mt-4"
                  onClick={(e) => handelNewCardSubmit(e)}
                >
                  {t("add_card")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCardModal;
