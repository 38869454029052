export const updateLanguageReducer = (state = {}, action) => {
    switch (action.type) {
        case'POST_UPDATE_LANGUAGE_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'POST_UPDATE_LANGUAGE_SUCCESS':
            return {
                countryId : action.payload.countryId,
                current_language: action.payload.current_language,
                loading: false,
                success: true
            }
        case'POST_UPDATE_LANGUAGE_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        default:
            return state
    }
}