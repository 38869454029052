/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState,useEffect,forwardRef,useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next'; 
import ReactPhoneInput from "react-phone-input-2";
import Modal from "react-bootstrap/Modal";
import jQuery from 'jquery';
import {useDispatch} from "react-redux";
import { profileSetupAction, loginUserResendOTPAction } from "../../../actions/userActions";
import { toast } from 'react-toastify';
import es from 'react-phone-input-2/lang/es.json';
import ar from 'react-phone-input-2/lang/ar.json';

let displayContent = {
  displayNone:{
    display:'none'
  },
  displayBlock:{
    display:'block'
  }
}

const SetupProfile = forwardRef((props, ref) => {
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  const userCountryCode = localStorage.getItem('userCountryCode') ? localStorage.getItem('userCountryCode') : 'en';
  let apiUrl = baseUrl + "/login";
  const currentLanguageCode = localStorage.getItem('currentLanguage') ? localStorage.getItem('currentLanguage') : 'en';
  const currentUser = (localStorage.getItem('currentUser') && localStorage.getItem('currentUser') !== "null") ? JSON.parse(localStorage.getItem('currentUser')) : false;
  const { t } = useTranslation();
  const [setupProfileModal, setSetupProfileModal] = useState(false);
  const handleSetupProfileModalShow = () => setSetupProfileModal(true);
  const handleSetupProfileModalClose = () => setSetupProfileModal(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const handleOtpModalShow = () => setShowOtpModal(true);
  const handleOtpModalClose = () => {setShowOtpModal(false);};
  const [userProfileInfo, setUserProfileInfo] = React.useState({
    name:"",
    email:"",
    mobile:"",
    country_code:''
  });
  const [file, setFile] = useState();
  const [preview1, setPreview1] = useState( `/images/user-placeholder.webp`)
  const dispatch = useDispatch();
  const currentLanguage = localStorage.getItem('currentLanguage') ? localStorage.getItem('currentLanguage') : 'en';

  let object1Url;
  const handleUpload = async (event) => {
    setFile(event.target.files[0]);
    object1Url = URL.createObjectURL(event.target.files[0])
    setPreview1(object1Url);
  }

  useEffect(() => {
    setTimeout(() => {
      if(currentUser){
        setUserInfo(currentUser);
      }
    }, 1500);
  }, [currentUser]);

const setUserInfo = async(currentUser) => {
    setUserProfileInfo({
      name:currentUser?.name ? currentUser.name : '',
      email:currentUser?.email ? currentUser.email : '',
      mobile:currentUser?.mobile ? currentUser.mobile : '',
      country_code:currentUser?.country_code ? currentUser.country_code : '+971',
      profile_image: currentUser?.profile_image ? currentUser.profile_image : null
    });
  }

  const handleProfileSetupSubmit = async (e) => {
    e.preventDefault();
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    if (file && !validImageTypes.includes(file.type)) {
      toast.error(t("image_only"), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }else{
      const currentUserDetail = (localStorage.getItem('currentUser') && localStorage.getItem('currentUser') !== "null") ? JSON.parse(localStorage.getItem('currentUser')) : "";
      const formData = new FormData();
      formData.append('customer_id',currentUserDetail.id)
      formData.append('profile_image', file);
      formData.append('name', jQuery('#full_name').val());
      formData.append('email', jQuery('#email').val())
      formData.append('country_code', jQuery('#country_code').val())
      formData.append('mobile', jQuery('#mobile').val())
      formData.append('dob', jQuery('#dob').val())
      formData.append('gender', jQuery('#gender').val())
      formData.append('referral_code', jQuery('#referral_code').val())
      let apiProfileSetupUrl = baseUrl+"/profile/setup";
      // console.log(formData);
      dispatch(profileSetupAction(formData,apiProfileSetupUrl,setUserInfo,currentLanguage));
      if (object1Url)
        URL.revokeObjectURL(object1Url)             
    }
  };

    var timeOut;
    function startOTPTimeOut(){
      if(timeOut) {
        stopOTPTimeOut();
        clearInterval(timeOut);
      }
      var time1 = 59;
      var time2 = 0;
      jQuery('.resentOtpTime').removeClass("timeNotVisible");
      timeOut = setInterval(function(){ 
        time2 = time1--;
        jQuery('.startTimer').html("00:"+((time2 < 10) ? '0'+time2:time2));
        if (time2 === 0) {
          clearInterval(timeOut);
          resendEvent();
        }
      }, 1000);
    }

    function stopOTPTimeOut() {
      clearInterval(timeOut);
    }

    const resendOtpSend = (e) => {
      jQuery('.resend-otp').css("display", "none");
      jQuery('.resendOtpError').css("display", "none");
      jQuery('.resendOtpError').html("");
      jQuery('.otpSent').css("display", "block");
      jQuery('.otpSent').html(t('otp_sent_on_your_mobile'));
      jQuery('.resentOtpTime').removeClass("timeNotVisible");
      let frm = document.getElementById('loginForm');
      dispatch(loginUserResendOTPAction(apiUrl,{
        mobile:currentUser.mobile,
        country_code:currentUser.country_code
      },loginSuccess));
    }

    const resendEvent = (e) => {
      jQuery('.resendOtpError').css("display", "block");
      jQuery('.resend-otp').css("display", "block");
      jQuery('.otpSent').css("display", "none");
      jQuery('.resentOtpTime').addClass("timeNotVisible");
    }

    async function loginSuccess(otp) {
      setTimeout(() => {
        const currentUser = (localStorage.getItem('currentUser') && localStorage.getItem('currentUser') !== "null") ? true : false;
        const newCurrentUser = (localStorage.getItem('currentUser') && localStorage.getItem('currentUser') !== "null") ? JSON.parse(localStorage.getItem('currentUser')) : false;
        if(currentUser){
          handleOtpModalShow();
          jQuery('#otp').val(otp);
          jQuery('.otpContinueBtn').removeClass('disabled');
          jQuery('.otpContinueBtn').attr('disabled', false);
          jQuery('.phone-number').html(newCurrentUser.country_code+newCurrentUser.mobile);
          jQuery('.resend-otp').css("display", "none");
          jQuery('.resendOtpError').css("display", "none");
          jQuery('.resendOtpError').html("");
          jQuery('.resentOtpTime').addClass(" timeNotVisible");
          startOTPTimeOut();
        }
      }, 700);
    }

    const handleFormOtpSubmit = (e) => {
      e.preventDefault();
      let otp = e.target.elements.otp.value;
      if(otp && otp.length === 4){
        let values = {
          otp: otp,
          fcm_token: "website",
          device_type: "website",
          mobile: currentUser.mobile,
          country_code: currentUser.country_code
        }
        let apiOtpUrl = process.env.REACT_APP_LOCAL_API_URL + "/verify/otp";
        //dispatch(loginEmailUserAction(apiOtpUrl, values, onOTPVerificationSuccess));
      }else{
        let message = (!otp) ? t('enter_otp') : t('invalid_otp');
        jQuery('.resendOtpError').html(message);
      }
    };

  const typeOtp = (e) => {
    let otp = jQuery('#otp').val();
    if(otp.length === 4 ){
      jQuery('.otpContinueBtn').removeClass("disabled");
      jQuery('.spinner-border').css("display", "block");
    }else{
      jQuery('.otpContinueBtn').addClass("disabled");
      jQuery('.spinner-border').css("display", "none"); 
    }

    if(otp && otp.length === 4){
      jQuery('.resendOtpError').css("display", "none");
      let values = {
        mobile: currentUser.mobile,
        country_code: currentUser.country_code,
        device_type: "website",
        fcm_token: "website",
        otp: otp
      }
      let apiOtpUrl = process.env.REACT_APP_LOCAL_API_URL + "/verify/otp";
      //dispatch(loginEmailUserAction(apiOtpUrl, values, onOTPVerificationSuccess));
    }else{
      if(otp.length >= 4 || otp.length === 4){
        jQuery('.resendOtpError').css("display", "block");
        jQuery('.resendOtpError').html(t('invalid_otp'));
      }else{
        jQuery('.resendOtpError').css("display", "none");
        jQuery('.resendOtpError').html('');
      }
    }
  }

  useImperativeHandle(ref, () => ({
    handleSetProfileModalShow() {
      handleSetupProfileModalShow(true);
    }
  }));

  const handlerValidBirthDate = (e) =>{
    // console.log(e.target.value)
  }

  return (
    <div>
      {/* Setup-Profile */}
      <Modal show={setupProfileModal} onHide={handleSetupProfileModalClose} className="addAddressModal" id="ProfileSetup">
        <Modal.Header closeButton className='border-0'></Modal.Header>
        <Modal.Body> 
          <form onSubmit={handleProfileSetupSubmit} id="profileSetupForm">       
            <div className="userInfo mb-3 pt-0">       
              <div className="profileImage">
                <img alt="" src={currentUser?.profile_image ? currentUser.profile_image : preview1}/>
                <div className="file-upload">
                <input type="file" accept="image/*" name="profile_image" onChange={handleUpload} className="formControl"placeholder="Password*"/>
                <i className="fa fa-image"></i>
                </div>
              </div>
            </div>
            <div className='profileForm' id="profileForm">
              <div className="row">   
              <input type="hidden" id="profile_customer_id" name="customer_id"/>
                <div className="col-lg-12 inputdiv mb-2">    
                  <input type="text" className="form-control pl-0" name="name" id="full_name" defaultValue={userProfileInfo.name} placeholder={t('full_name')}/>
                  <div className="help-block"></div>
                </div>    
                <div className="col-lg-12 inputdiv mb-2">
                  <input type="email" className="form-control pl-0" defaultValue={userProfileInfo.email} name="email" id="email" placeholder={t('email')}/>
                  <div className="help-block"></div>
                </div> 
                <div className="row loginMobileInput mb-2">
                  <div className="inputgroup phonecodeselect col-lg-5">
                    <ReactPhoneInput
                      inputProps={{
                        className:"login_country_code form-control",
                        name: "country_code",
                        id: "country_code",
                        autoFocus: true,
                        placeholder: t("enter_mobile_number"),
                        readOnly: "readonly",
                      }}
                      country={userCountryCode.toLocaleLowerCase()}
                      localization={currentLanguageCode === 'en' ? es : ar}
                      countryCodeEditable={false}
                      placeholder=""
                      disableCountryCode={false}
                      searchClass="search-class"
                      searchStyle={{
                        margin: "0",
                        width: "97%",
                        height: "30px",
                      }}
                      enableSearch
                      disableSearchIcon
                    />
                  <div className="help-block text-danger"></div>
                  </div>
                  <div className="inputgroup phonecodeselect col-lg-7">
                    <input type="number" onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} name="mobile" id="mobile" defaultValue={userProfileInfo.mobile} className="form-control rounded-pill"placeholder={t('enter_mobile_number')}/>
                    <div className="help-block text-danger"></div>
                  </div>
                </div> 
                <div className="col-lg-12 inputdiv mb-2">
                  <label>{t('date_of_birth_optional')}</label> 
                  <input type="date" name="dob" id="dob" placeholder={t('enter_your_dob')} className="form-control pl-0"
                   min="1965-01-01" max="2020-12-31" onChange = {(e)=>handlerValidBirthDate(e)}
                  />
                  <div className="help-block"></div>
                </div>    
                <div className="col-lg-12 inputdiv mb-2">
                  <label>{t('gender')}</label>
                  <select className="form-select pl-0 genderSelect" defaultValue={"Mr"} name="gender" id="gender">  
                    <option value="Mr.">{t('gender_mr')}</option>
                    <option value="Ms.">{t('gender_ms')}</option>
                    <option value="Rather Not Say">{t('gender_rather_not_say')}</option>
                  </select>
                </div>   
                <div className="col-lg-12 inputdiv mb-2">
                  <label>{t('referral_code_optional')}</label>
                  <input type="text" name="referral_code" id="referral_code" placeholder={t('enter_referral_code')} className="form-control pl-0"/>
                </div>   
                <div className="inputdiv col-lg-12 mt-3 mb-0">
                  <button className="btn btn-primary w-full">{t('save_profile')}</button>
                </div>           
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/*  Setup-Profile End */}

      {/*  OTP Modal */}
      <Modal show={showOtpModal} onHide={handleOtpModalClose} id="otpVerify" keyboard={false}>
          <Modal.Header closeButton className="border-0">
            <Modal.Title>
              <i className="flag flag-us"></i>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body> 
            <form onSubmit={handleFormOtpSubmit}>
              <div className="">
              <h2 className="mb-3">{ t('otp_verification') }</h2>
                <label>{ t('otp_detail') }</label>
                <p className="phone-number mb-4 phoneNumberDir"></p>
                <input type="text" id="otp" className="form-control" placeholder="" maxLength="4" onKeyUp={typeOtp}/>

                <p className="mb-0 py-3 resentOtpTime ">
                  {t('resend_code_in')}:
                  <p className="startTimer">00:59</p>
                </p>
                <p className="text-danger resendOtpError" style={displayContent.displayNone}></p>

                <p className="otpSent text-success" style={displayContent.displayNone}></p>
               
                <div className="resend-otp" onClick={resendOtpSend} style={displayContent.displayNone}>
                  <p>Did not receive an OTP?</p>
                 <p className="btn btn-light rounded-pill" > {t('resend_code_btn')}</p>
                </div>

                <div className="loader">
                  <button type="submit" className="btn btn-primary w-full disabled otpContinueBtn" disabled="">{t('continue_btn')}</button>
                  <div className="spinner-border text-dark " role="status" style={displayContent.displayNone}>
                    {" "}
                    <span className="sr-only">Loading...</span>{" "}
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
    </div>
  );
})

export default SetupProfile;
