import axios from "axios";
import {toast} from "react-toastify";
import i18next from "i18next";
import jQuery from "jquery";
import {
  authHeader,
  commonErrorBag,
  handlingNonauthErrors,
  handlingAuthErrors,
  handlingTranslatedAuthErrors
} from "../helpers/helpers";

export const loginUserAction = (baseUrl, _form, loginSuccess) => (dispatch) => {
  let frm = jQuery(_form);
  dispatch({type: "USER_LOGIN_REQUEST"});
  axios
    .post(baseUrl, frm.serialize())
    .then((res) => {
      localStorage.setItem("currentUser", JSON.stringify(res.data.user));

      dispatch({type: "USER_LOGIN_SUCCESS", payload: res.data.user});
      loginSuccess(res.data.otp);
    })
    .catch((err) => {
      dispatch({type: "USER_LOGIN_FAILED", payload: err});
      handlingNonauthErrors(err);
    });
};

export const loginEmailUserAction =
  (baseUrl, values, emailLoginSuccess, setShowInfoModal, implementedFrom) => (dispatch) => {
    dispatch({type: "USER_LOGIN_REQUEST"});
    axios({
      method: "POST",
      headers: authHeader(),
      url: baseUrl,
      data: values
    })
      .then((res) => {
        localStorage.setItem("currentUser", JSON.stringify(res.data.user));
        dispatch({type: "USER_LOGIN_SUCCESS", payload: res.data.user});
        let current_language = res.data?.user?.current_language
          ? res.data.user?.current_language
          : "en";
        setTimeout(() => {
          i18next.changeLanguage(current_language);
          localStorage.setItem("currentLanguage", current_language);
        }, 500);
        if(res?.data?.token){
          localStorage.setItem("currentToken", JSON.stringify(res.data.token));
        }else{
          localStorage.setItem("currentToken", null);
        }
        
        setShowInfoModal(false);
        emailLoginSuccess(
          res.data.token,
          res.data.appointment_cart,
          res.data.message,
          implementedFrom
        );
      })
      .catch((err) => {
        // console.log("emailLoginError", err);
        dispatch({type: "USER_LOGIN_FAILED", payload: err});
        handlingNonauthErrors(err);
      });
  };

export const otpVerificationAction =
  (baseUrl, values, onOTPVerificationSuccess) => (dispatch) => {
    dispatch({type: "USER_OTP_VERIFICATION_REQUEST"});
    axios
      .post(baseUrl, values)
      .then((res) => {
        dispatch({
          type: "USER_OTP_VERIFICATION_SUCCESS",
          payload: res.data.user
        });
        if (res.status === 200) {
          localStorage.setItem("currentUser", JSON.stringify(res.data.user));
          let current_language = res.data?.user?.current_language
            ? res.data.user?.current_language
            : "en";
          setTimeout(() => {
            i18next.changeLanguage(current_language);
            localStorage.setItem("currentLanguage", current_language);
          }, 500);
          onOTPVerificationSuccess(res.data.token);
        }
      })
      .catch((err) => {
        dispatch({type: "USER_OTP_VERIFICATION_FAILED", payload: err});
        handlingNonauthErrors(err);
      });
  };

export const signUpEmailUserAction =
  (baseUrl, values, emailSignUpSuccess) => (dispatch) => {
    dispatch({type: "USER_LOGIN_REQUEST"});
    axios({
      method: "POST",
      headers: authHeader(),
      url: baseUrl,
      data: values
    })
      .then((res) => {
        localStorage.setItem("currentUser", JSON.stringify(res.data.customer));
        dispatch({type: "USER_LOGIN_SUCCESS", payload: res.data.customer});
        emailSignUpSuccess(res.data.token, res.data.message);
      })
      .catch((err) => {
        dispatch({type: "USER_LOGIN_FAILED", payload: err});
        handlingNonauthErrors(err);
      });
  };

export const sendForgotPasswordMailAction =
  (baseUrl, values, t) => (dispatch) => {
    dispatch({type: "FORGOT_PASS_MAIL_REQUEST"});
    axios({
      method: "POST",
      headers: authHeader(),
      url: baseUrl,
      data: values
    })
      .then((res) => {
        dispatch({type: "FORGOT_PASS_MAIL_SUCCESS"});
        toast.success(res.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      })
      .catch((err) => {
        dispatch({type: "FORGOT_PASS_MAIL_FAILED", payload: err});
        handlingNonauthErrors(err);
      });
  };

export const resetPasswordAction =
  (baseUrl, values, t, token, currentLanguage) => (dispatch) => {
    dispatch({type: "RESET_PASSWORD_REQUEST"});
    axios({
      method: "POST",
      headers: {
        "x-auth-token": token,
        "Accept-Language": currentLanguage
      },
      url: baseUrl,
      data: values
    })
      .then((res) => {
        dispatch({type: "RESET_PASSWORD_SUCCESS"});
        toast.success(res.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        setTimeout(function () {
          window.location.href = "/";
        }, 2000);
      })
      .catch((err) => {
        dispatch({type: "RESET_PASSWORD_FAILED", payload: err});
        handlingAuthErrors(err);
      });
  };

export const changePasswordAction = (baseUrl, values, t) => (dispatch) => {
  dispatch({type: "PASSWORD_CHANGE_REQUEST"});
  axios({
    method: "POST",
    headers: authHeader(),
    url: baseUrl,
    data: values
  })
    .then((res) => {
      toast.success(res.data.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      setTimeout(function () {
        window.location.href = "/my-account/account-details";
      }, 2000);
    })
    .catch((err) => {
      dispatch({type: "PASSWORD_CHANGE_FAILED", payload: err});
      handlingAuthErrors(err);
    });
};

export const changeProSettingAction = (baseUrl, values) => (dispatch) => {
  dispatch({type: "PROSETTING_CHANGE_REQUEST"});
  axios({
    method: "POST",
    headers: authHeader(),
    url: baseUrl,
    data: values
  })
    .then((res) => {
      localStorage.removeItem("currentUser");
      localStorage.setItem("currentUser", JSON.stringify(res.data.customer));
      toast.success(res.data.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      setTimeout(function () {
        window.location.href = "/my-account/prosettings";
      }, 2000);
    })
    .catch((err) => {
      dispatch({type: "PROSETTING_CHANGE_FAILED", payload: err});
      handlingAuthErrors(err);
    });
};

export const loginUserResendOTPAction =
  (baseUrl, values, loginSuccess) => (dispatch) => {
    dispatch({type: "USER_LOGIN_REQUEST"});
    axios
      .post(baseUrl, values)
      .then((res) => {
        localStorage.setItem("currentUser", JSON.stringify(res.data.user));
        dispatch({type: "USER_LOGIN_SUCCESS", payload: res.data.user});
        loginSuccess(res.data.otp);
      })
      .catch((err) => {
        dispatch({type: "USER_LOGIN_FAILED", payload: err});
        handlingNonauthErrors(err);
      });
  };

export const profileSetupAction =
  (formData, baseUrl, setUserInfo, currentLanguage, ) => (dispatch) => {
    // let frm = jQuery(_form);
    dispatch({type: "USER_PROFILE_SETUP_REQUEST"});
    axios({
      url: baseUrl,
      data: formData,
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data",
        "Accept-Language": currentLanguage
      }
    })
      .then((res) => {
        dispatch({type: "USER_PROFILE_SETUP_SUCCESS", payload: res.data.user});
        localStorage.setItem("currentUser", JSON.stringify(res.data.user));
        setUserInfo(res.data.user);
        setTimeout(() => {
          if (res.data.token && res.data.token !== null) {
            localStorage.setItem(
              "currentToken",
              JSON.stringify(res.data.token)
            );
            const currentURL = window.location.href;
            const { pathname, searchParams } = new URL(currentURL);
            const path = pathname;
            const stepQueryParam = searchParams.get('step');
            if (path === '/frequency/checkout/details' && stepQueryParam === '5') {
                window.location.pathname = '/'
            }else{
                window.location.reload();
            }
           
          }
        }, 2000);
        toast.success(res.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      })
      .catch((err) => {
        dispatch({type: "USER_PROFILE_SETUP_FAILED", payload: err});
        handlingNonauthErrors(err);
      });
  };

export const addressSetupAction =
  (_form, baseUrl, handleProfileModalShow, currentLanguage, from, signupFrom) =>
  (dispatch) => {
   
    let frm;
    if (from != "newComponent") {
      frm = jQuery(_form);
    } else {
      frm = _form;
    }

    dispatch({type: "USER_ADDRESS_SETUP_REQUEST"});
    axios({
      method: "POST",
      headers: {
        "Accept-Language": currentLanguage
      },
      url: baseUrl,
      data: from != "newComponent" ? frm.serialize() : frm
    })
      .then((res) => {
        dispatch({
          type: "USER_ADDRESS_SETUP_SUCCESS",
          payload: res.data.address
        });
        handleProfileModalShow(res.data.address, res.data.message, signupFrom);
      })
      .catch((err) => {
        dispatch({type: "USER_ADDRESS_SETUP_FAILED", payload: err});
        return commonErrorBag(frm, err, "newComponent");
      });
  };

export const profileUpdateAction =
  (baseUrl, values, profileUpdateSuccess, setPageLoading) => (dispatch) => {
    setPageLoading(true);
    dispatch({type: "POST_UPDATE_PROFILE_REQUEST"});
    axios({
      method: "POST",
      headers: authHeader(),
      url: baseUrl,
      data: values
    })
      .then((res) => {
        dispatch({
          type: "POST_UPDATE_PROFILE_SUCCESS",
          payload: res.data.appointment
        });
        profileUpdateSuccess(res);
      })
      .catch((err) => {
        dispatch({type: "POST_UPDATE_PROFILE_FAILED", payload: err});
        toast.error(err.response.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        setPageLoading(false);
        handlingAuthErrors(err);
      });
  };

export const countryListAction = (baseUrl) => (dispatch) => {
  dispatch({type: "GET_COUNTRY_LIST_REQUEST"});
  axios({
    method: "GET",
    url: baseUrl
  })
    .then((res) => {
      let countryList = res.data.list;
      let country_arr = [];
      for (var i = 0; i < countryList?.length; i++) {
        if (countryList[i]?.isActive) {
          country_arr.push(countryList[i]?.name);
        }
      }

      dispatch({
        type: "GET_COUNTRY_LIST_SUCCESS",
        payload: {
          countries: res.data.list,
          country_arr: country_arr
        }
      });
    })
    .catch((err) => {
      dispatch({type: "GET_COUNTRY_LIST_FAILED", payload: err});
      handlingNonauthErrors(err);
    });
};

export const logoutUserAction = () => (dispatch) => {
  dispatch({type: "USER_LOGOUT"});
  toast.success("Logout successfully.", {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  });
  setTimeout(() => {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("currentToken");
    window.location.href = "/";
  }, 1000);
};

export const countryNameAction =
  (baseUrl, {}, country_list) =>
  (dispatch) => {
    dispatch({type: "GET_COUNTRY_NAME_REQUEST"});
    axios({
      method: "GET",
      url: baseUrl
    })
      .then((res) => {
        let AddressList = res.data.results;
        let country_name = "";
        for (let i = 0; i < AddressList.length; i++) {
          if (AddressList[i].types[0]) {
            for (let j = 0; j < AddressList[i].types.length; j++) {
              if (AddressList[i].types[j] === "country") {
                country_name = AddressList[i].address_components[0].long_name;
              }
            }
          }
        }

        dispatch({
          type: "GET_COUNTRY_NAME_SUCCESS",
          payload: {
            addressList: res.data.list,
            country_name: country_name
          }
        });
      })
      .catch((err) => {
        dispatch({type: "GET_COUNTRY_NAME_FAILED", payload: err});
        handlingNonauthErrors(err);
      });
  };
