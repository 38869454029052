/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  getAppointmentInfoAction,
  uploadAppointmentAction
} from "../../actions/appointmentAction";
import {Link, NavLink, useNavigate} from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Loader from "../../components/Loader";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
// import AddImage from "../../components/AddImage";
import UploadImages from "../../components/UploadImages";
import {Button} from "antd";
import InvoiceView from "../../components/invoiceView";
import {Modal} from "react-bootstrap";
// import Popup from "reactjs-popup";

const moment = require("moment");
const AppointmentDetails = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [imageData, setImageData] = useState();
  const [showInvoice, setShowInvoice] = useState(false);
  const closeModal = () => setShowInvoice(false);
  const getAppointmentInfoState = useSelector(
    (state) => state.appointmentReducer
  );

  const {loading, appointment, uploadImages} = getAppointmentInfoState;
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  const infoUrl =
    baseUrl +
    `/appointment/edit/` +
    localStorage.getItem("appointmentDetailsId");
  const uploadUrl = baseUrl + `/appointment/upload/`;
  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  const [hideReschedule, setHideReschedule] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("appointmentDetailsId")) {
      window.location.href = "/my-account/appointments";
    } else {
      dispatch(getAppointmentInfoAction(infoUrl));
    }
  }, [infoUrl, dispatch]);

  const {t} = useTranslation();
  const location = useLocation();
  const currency = appointment?.countryDetails?.currency;

  const uploadAppointmentImages = () => {
    const formData = new FormData();
    const allImages = uploadImages.map((img, index) => {
      formData.append("cover", img.originFileObj);
    });
    formData.append("appointment_id", appointment?.id);
    dispatch(uploadAppointmentAction(uploadUrl, formData));
  };
  const handelExtend = (appointment) => {
    window.scrollTo(0, 0);
    if (appointment.service_id.length > 0) {
      navigate("/my-account/appointment/extend", {
        state: {
          appointmentService_Id: appointment?.service_id[0],
          appointment_no: appointment?.appointment_no,
          appointmentId: appointment?.id
        }
      });
    }
  };
  const handelRedirect = (appointment) => {
    window.scrollTo(0, 0);
    navigate("/my-account/reschedule-appointment/policy", {
      state: {
        appointmentService_Id: appointment?.service_id[0],
        appointment_no: appointment?.appointment_no,
        appointmentId: appointment?.id
      }
    });
  };

  const cleanersId = () => {
    if (appointment?.cleaners_id?.length > 0) {
      localStorage.setItem(
        "appointmentCleanersId",
        JSON.stringify(appointment?.cleaners_id)
      );
      localStorage.setItem("appointmentCleanerId", appointment?.cleaners_id[0]);
      navigate("/my-account/writereview");
      window.scrollTo(0, 0);
    }
  };
  useEffect(() => {
    // Parse the appointment date in the format "DD/MM/YYYY"
    // const appointmentDate = moment(appointment?.appointment_date_start)?.format(
    //   "DD/MM/YYYY"
    // );
    const appointmentDate = moment(appointment?.appointment_date_start);
    // const parsedAppointmentDate = moment(appointmentDate, "DD/MM/YYYY");
    // Get the current date
    const currentDateTime = moment();

    // Check if the appointment date is equal to the current date

    // if (parsedAppointmentDate?.isSame(currentDate, "day")) {
    // If the dates are the same, then compare the time range
    const timeDifferenceMinutes = appointmentDate.diff(
      currentDateTime,
      "minutes"
    );

    // Check if the appointment time is within 12 hours from now

    if (timeDifferenceMinutes < 720) {
      setHideReschedule(true);
    } else {
      setHideReschedule(false);
    }
    //  }
  }, [appointment]);

  return (
    <>
      <Header />
      {loading ? <Loader /> : ""}
      <div className="py-5">
        <div className="userContainer">
          <div className="appoiintments">
            <div className="heading">
              <h5 onClick={() => navigate("/my-account/appointments")}>
                {t("appointments")}
              </h5>
              <h2>
                {location?.state?.apptType === "past"
                  ? t("past_appointments")
                  : location?.state?.apptType === "ongoing"
                  ? t("ongoing_appts")
                  : t("upcoming_appts")}
              </h2>
            </div>
            <div className="card">
              <div className="personalForm">
                <div className="appointmentDetails">
                  <h3>{t("services")}</h3>
                  {/* service  summery */}

                  <div className="appointmentService">
                    <img
                      src="https://dvdljkkxpxqo3.cloudfront.net/images/bg3.jpg.webp"
                      alt=""
                    />
                    <h3 className="align-items-center justify-content-between d-flex">
                      {appointment?.is_home_cleaning
                        ? appointment?.FrequencyDetails
                          ? currentLanguage === "en"
                            ? appointment?.FrequencyDetails?.package_name
                            : appointment?.FrequencyDetails?.package_name_arabic
                          : ""
                        : appointment?.ServiceDetail?.length > 0 ??
                          appointment?.ServiceDetail[0]?.name}{" "}
                      <span>
                        {t("Booking_Reference")} : {appointment?.appointment_no}
                      </span>
                    </h3>
                    <p>
                      {currentLanguage === "en"
                        ? appointment?.ServiceDetail[0]?.name
                        : appointment?.ServiceDetail[0]?.name_arabic}
                    </p>
                    <p>
                      <i className="fa fa-clock-o"></i>{" "}
                      {appointment?.appointment_date_start
                        ? moment(appointment?.appointment_date_start).format(
                            "DD/MM/YYYY"
                          )
                        : ""}
                      {" " + appointment?.start_time_between}
                    </p>
                    <p className="verifiedText">{t("verified_professional")}</p>
                  </div>
                  <button
                    onClick={() => setShowInvoice(true)}
                    className="btn btn-primary mt-2"
                  >
                    {t("invoice")}
                  </button>
                  {/* Appointment summery */}
                  {/* {Invoice Modal} */}
                  <Modal
                    show={showInvoice}
                    onHide={() => setShowInvoice(false)}
                    id="showInvoice"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>
                        {" "}
                        {t("invoice")}
                        <i className="flag flag-us"></i>{" "}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <InvoiceView appointment={appointment} />
                    </Modal.Body>
                  </Modal>

                  <div className="appointmentSummery">
                    <h3>{t("appointment_details")}</h3>
                    <ul>
                      <li>
                        {t("Booking_Reference")}
                        <span>
                          {appointment?.appointment_no
                            ? appointment?.appointment_no
                            : ""}
                        </span>
                      </li>
                      <li>
                        {t("appointment_status")}
                        <span
                          style={{
                            color:
                              appointment?.appointment_status === 0
                                ? "#ECA315"
                                : appointment?.appointment_status === 1
                                ? "#ECA315"
                                : appointment?.appointment_status === 2
                                ? "#7BC595"
                                : appointment?.appointment_status === 3
                                ? "#911C1C"
                                : appointment?.appointment_status === 4
                                ? "#7BC595"
                                : appointment?.appointment_status === 5
                                ? "#0x7BC595"
                                : "#222",
                            fontWeight: "600"
                          }}
                        >
                          {console.log("appointment?.appointment_status ", appointment?.appointment_status)}
                          {appointment?.appointment_status
                            ? appointment?.appointment_status === 0
                              ? t("pending")
                              : appointment?.appointment_status === 1
                              ? t("waiting")
                              : appointment?.appointment_status === 2
                              ? t("complete")
                              : appointment?.appointment_status === 3
                              ? t("cancel")
                              : appointment?.appointment_status === 4
                              ? t("ongoing")
                              : appointment?.appointment_status === 5
                              ? t("refunded")
                              : ""
                            : ""}
                        </span>
                      </li>
                    </ul>
                  </div>
                  {/* cleaner summery */}
                  <div className="cleanerSummery">
                    <h3>{t("cleaner_details")}</h3>
                    <ul>
                      <li>
                        {t("full_name")}{" "}
                        <span>
                          {appointment?.CleanerDetails[0]?.fullName
                            ? appointment?.CleanerDetails[0]?.fullName
                            : ""}
                        </span>
                      </li>
                      <li>
                        {t("mobile_number")}
                        <span>{`(${
                          appointment?.CleanerDetails[0]?.country_code
                            ? appointment?.CleanerDetails[0]?.country_code
                            : ""
                        })
                           ${
                             appointment?.CleanerDetails[0]?.mobile
                               ? appointment?.CleanerDetails[0]?.mobile
                               : ""
                           }`}</span>
                      </li>
                    </ul>
                  </div>
                  {/* {console.log("appointment----------------:", appointment)} */}
                  {/* order summery */}
                  <div className="orderSummary ">
                    <h3>{t("order_summary")}</h3>
                    <ul>
                      <li>
                        {t("payment_mode")}{" "}
                        <span>
                          {(() => {
                            let cardBYCard = "";
                            let freeCredit = "";
                            let cash = "";

                            if (
                              appointment?.payment_mode === "Cash" ||
                              appointment?.payment_mode === "cod" ||
                              appointment?.payment_mode === "cash"
                            ) {
                              cash = t("cash");
                            } else if (
                              appointment?.payment_mode === "card" ||
                              appointment?.payment_mode === "Card" ||
                              appointment?.payment_mode === ""
                            ) {
                              cardBYCard = t("credit_card");
                            }

                            if (parseInt(appointment?.credit_amount) > 0) {
                              freeCredit = t("free_credit");
                            }
                            return (
                              cardBYCard +
                              (freeCredit !== "" && cardBYCard !== ""
                                ? "/"
                                : "") +
                              freeCredit +
                              cash
                            );
                          })()}
                        </span>
                      </li>
                      <li>
                        {t("price")}{" "}
                        <span>
                          {currency}{" "}
                          {appointment?.FrequencyDetails?.package_name ===
                          "2 times daily"
                            ? appointment?.per_order_service_total
                            : appointment?.FrequencyDetails?.package_name ===
                              "Weekly"
                            ? appointment?.per_order_service_total
                            : appointment?.total_order_service_total}
                        </span>
                      </li>
                      {(() => {
                        if (
                          appointment?.slot_price &&
                          parseInt(appointment?.slot_price) > 0
                        ) {
                          return (
                            <li>
                              {t("rush_slot")}{" "}
                              <span>
                                {" "}
                                {currency} {appointment?.slot_price}
                              </span>
                            </li>
                          );
                        }
                        if (
                          appointment?.credit_amount &&
                          parseInt(appointment?.credit_amount) > 0
                        ) {
                          return (
                            <li>
                              {t("free_credit")}{" "}
                              <span>
                                -{currency} {appointment?.credit_amount}
                              </span>
                            </li>
                          );
                        }
                        if (
                          appointment?.holiday_price &&
                          appointment?.holiday_price > 0
                        ) {
                          return (
                            <li>
                              {t("holiday_price")}{" "}
                              <span>
                                {" "}
                                {currency} {appointment?.holiday_price}
                              </span>
                            </li>
                          );
                        }
                        if (
                          appointment?.extendPayment &&
                          parseInt(
                            appointment?.extendPayment?.additional_price
                          ) > 0
                        ) {
                          return (
                            <li>
                              {t("additional_price")}{" "}
                              <span>
                                -{currency}{" "}
                                {appointment?.extendPayment?.additional_price}
                              </span>
                            </li>
                          );
                        }
                        if (appointment?.vat && appointment?.vat > 0) {
                          return (
                            <li>
                              {t("additional_price")}{" "}
                              <span>
                                -{currency} {appointment?.vat}
                              </span>
                            </li>
                          );
                        }
                        // if (
                        //   appointment?.total_discount &&
                        //   appointment?.total_discount > 0
                        // ) {
                        //   return (
                        //     <li>
                        //       {t("additional_price")}{" "}
                        //       <span>
                        //         -{currency}{" "}
                        //         {appointment?.mainPayment?.discount_price}
                        //       </span>
                        //     </li>
                        //   );
                        // }
                      })()}
                      {(() => {
                        if (appointment?.total_cod_charges > 0) {
                          return (
                            <li>
                              {t("codCharge")}{" "}
                              <span>
                                {appointment?.total_cod_charges}{" "}
                                {/* {Number(checkoutData?.holidayPrice).toFixed(2)} */}
                              </span>
                            </li>
                          );
                        }
                      })()}
                      <li>
                        {t("vipDiscount")}{" "}
                        <span>
                          -{currency}{" "}
                          {appointment?.FrequencyDetails?.package_name ===
                          "2 times daily"
                            ? appointment?.per_frequency_discount
                            : appointment?.FrequencyDetails?.package_name ===
                              "Weekly"
                            ? appointment?.per_frequency_discount
                            : appointment?.total_discount}
                        </span>
                      </li>
                      <li>
                        {t("total")}
                        <span className="total">
                          {currency}{" "}
                          {appointment?.mainPayment?.payment_mode === "cash" ||
                          appointment?.mainPayment?.payment_mode === "cod"
                            ? appointment?.FrequencyDetails?.package_name ===
                              "2 times daily"
                              ? appointment?.total_per_display_amount
                              : appointment?.FrequencyDetails?.package_name ===
                                "Weekly"
                              ? appointment?.total_per_display_amount
                              : appointment.total_amount +
                                (appointment?.total_cod_charges > 0
                                  ? appointment?.total_cod_charges
                                  : 0)
                            : appointment?.FrequencyDetails?.package_name ===
                              "2 times daily"
                            ? appointment?.total_per_display_amount
                            : appointment?.FrequencyDetails?.package_name ===
                              "Weekly"
                            ? appointment?.total_per_display_amount
                            : appointment?.total_amount +
                              (appointment?.total_cod_charges > 0
                                ? appointment?.total_cod_charges
                                : 0)}
                        </span>
                      </li>

                      {(() => {
                        if (
                          appointment?.reschedule_amount &&
                          parseInt(appointment?.reschedule_amount) > 0
                        ) {
                          return (
                            <li>
                              {t("reschedule_amount")}{" "}
                              <span>
                                {" "}
                                {currency} {appointment?.reschedule_amount}
                              </span>
                            </li>
                          );
                        }

                        if (
                          appointment?.refund_credit &&
                          parseInt(appointment?.refund_credit) > 0
                        ) {
                          return (
                            <li>
                              {t("refund_credit")}{" "}
                              <span>
                                {" "}
                                {currency} {appointment?.refund_credit}
                              </span>
                            </li>
                          );
                        }

                        if (
                          appointment?.refund_amount &&
                          parseInt(appointment?.refund_amount) > 0
                        ) {
                          return (
                            <li>
                              {t("refund_amount")}{" "}
                              <span>
                                {" "}
                                {currency} {appointment?.refund_amount}
                              </span>
                            </li>
                          );
                        }
                      })()}

                      {(() => {
                        if (
                          appointment?.refund_credit &&
                          parseInt(appointment?.refund_credit) > 0
                        ) {
                          return (
                            <li>
                              {t("refund_credit")}{" "}
                              <span>
                                {" "}
                                {currency} {appointment?.refund_credit}
                              </span>
                            </li>
                          );
                        }
                      })()}

                      {(() => {
                        if (
                          appointment?.refund_amount &&
                          parseInt(appointment?.refund_amount) > 0
                        ) {
                          return (
                            <li>
                              {t("refund_amount")}{" "}
                              <span>
                                {" "}
                                {currency} {appointment?.refund_amount}
                              </span>
                            </li>
                          );
                        }
                      })()}
                    </ul>
                    {(location.state?.apptType === "ongoing" ||
                      location.state?.apptType === "past") && (
                      <div className="my-4 addPhotosSec  justify-content-between d-flex">
                        <div className="col-lg-8">
                          <h3>{t("add_photos")}</h3>
                          <UploadImages />
                          {uploadImages?.length > 0 && (
                            <Button
                              type="primary"
                              className="btn btn-primary mt-2"
                              onClick={() => uploadAppointmentImages()}
                            >
                              Save
                            </Button>
                          )}
                        </div>
                        {location.state?.apptType === "ongoing" && (
                          <>
                            {appointment?.extendOption.length > 0 ? (
                              <div className="text-end">
                                <h3>{t("extend_appointments")}</h3>
                                <Button
                                  // to="/my-account/appointment/extend"
                                  onClick={() => handelExtend(appointment)}
                                >
                                  <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/extend.png.webp" />
                                </Button>
                              </div>
                            ) : (
                              <center></center>
                            )}
                          </>
                        )}

                        {(location?.state?.apptType === "ongoing" ||
                          location?.state?.apptType === "past") && (
                          <>
                            <div className="photos-videos-section mt-4">
                              {/* <div className="grid grid-cols-3 gap-4"></div> */}

                              {appointment?.added_photos?.map(
                                (image, index) => {
                                  return (
                                    <span
                                      style={{height: "60px", marginRight: 5}}
                                    >
                                      <img key={index} src={image} alt="" />
                                    </span>
                                  );
                                }
                              )}

                              {appointment?.added_videos?.map(
                                (image, index) => {
                                  return (
                                    <>
                                      <video
                                        width={140}
                                        height={140}
                                        loop
                                        autoplay=""
                                        muted
                                      >
                                        <source src={image} />
                                      </video>
                                      {/* <video width={140} height={140} loop={true} autoPlay={true} key={index}src={image} /> */}
                                    </>
                                  );
                                }
                              )}
                            </div>
                          </>
                        )}
                        <div className="col-lg-8">
                          {appointment?.appointmentStatusData[
                            appointment?.appointmentStatusData?.length - 1
                          ]?.status_short === "oc" && (
                            <Link
                              to={`/my-account/track-appointment/${appointment?.id}`}
                              onClick={() => window.scrollTo(0, 0)}
                            >
                              <Button
                                type="primary"
                                className="btn btn-primary mt-2"
                              >
                                Track Appointment
                              </Button>
                            </Link>
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="buttonSec">
                    {(() => {
                      if (appointment?.appointment_status === 3) {
                        return (
                          <div className="col-lg-12 col-sm-12">
                            <div className="text-center">
                              <h4 className="text-danger">{t("cancelled")}</h4>
                            </div>
                          </div>
                        );
                      } else {
                        if (
                          (appointment?.appointment_status === 0 ||
                            appointment?.appointment_status === 1) &&
                          !hideReschedule
                        ) {
                          return (
                            <div className="row">
                              <div className="col-lg-6 col-sm-6">
                                <div
                                  // to="/my-account/reschedule-appointment/policy"
                                  onClick={() => handelRedirect(appointment)}
                                >
                                  <button className="btn btn-primary w-full">
                                    {t("reschedule")}
                                  </button>
                                </div>
                              </div>
                              <div className="col-lg-6 col-sm-6">
                                <Link
                                  to="/my-account/cancel-my-appointment"
                                  onClick={() => window.scrollTo(0, 0)}
                                >
                                  <button className="btn btn-primary btn-light w-full">
                                    {t("cancel")}
                                  </button>
                                </Link>
                              </div>
                            </div>
                          );
                        }
                      }
                    })()}
                    {(() => {
                      if (
                        appointment?.appointment_status === 2 &&
                        appointment?.given_review === 0
                      ) {
                        return (
                          <div className="addRating mt-2">
                            <button
                              className="btn btn-primary btn_skip"
                              onClick={() => cleanersId()}
                            >
                              <span className="fa fa-star me-2"></span>
                              {t("add_rating")}{" "}
                            </button>
                          </div>
                        );
                      }
                    })()}
                    <h4>
                      <a
                        href="https://righthandssupport.zendesk.com/hc/en-us"
                        target="_blank"
                        className="dropdown-item"
                      >
                        {t("question_related_to_booking")}{" "}
                        <i className="fa fa-question"></i>
                      </a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AppointmentDetails;
