import axios from "axios";
import {toast} from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {authHeader, handlingAuthErrors} from "../helpers/helpers";

const MySwal = withReactContent(Swal);

export const customerAddressListsAction = (baseUrl) => (dispatch) => {
  // if(window.location.pathname !== '/my-account/address'){
  //     let userCountryCode = (localStorage.getItem('userCountryCode') && localStorage.getItem('userCountryCode') !== "null") ? localStorage.getItem('userCountryCode') : 'UAE';
  //     // baseUrl += "?country_short_name="
  //     baseUrl += "?country_short_name="+userCountryCode;
  //     console.log("baseUrl",baseUrl)
  // }

  dispatch({type: "GET_CUSTOMER_ADDRESSES_REQUEST"});
  axios
    .get(baseUrl, {
      headers: authHeader()
    })
    .then((res) => {
      dispatch({
        type: "GET_CUSTOMER_ADDRESSES_SUCCESS",
        payload: {
          customerAddresses: res.data.address
        }
      });
    })
    .catch((err) => {
      dispatch({type: "GET_CUSTOMER_ADDRESSES_FAILED", payload: err});
      handlingAuthErrors(err);
    });
};

export const makeDefaultCustomerAddressAction = (baseUrl) => (dispatch) => {
  dispatch({type: "POST_DEFAULT_CUSTOMER_ADDRESSES_REQUEST"});
  axios({
    method: "post",
    url: baseUrl,
    headers: authHeader()
  })
    .then((res) => {
      dispatch({
        type: "POST_DEFAULT_CUSTOMER_ADDRESSES_SUCCESS",
        payload: res.data.addressList
      });
      toast.success(res.data.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({type: "POST_DEFAULT_CUSTOMER_ADDRESSES_FAILED", payload: err});
      handlingAuthErrors(err);
    });
};

export const customerAddressDeleteAction =
  (baseUrl, values, listUrl) => (dispatch) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this address?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete"
    }).then((result) => {
      if (result.isConfirmed) {
        // dispatch({type: 'GET_CUSTOMER_ADDRESSES_SUCCESS'})
        axios({
          method: "delete",
          url: baseUrl,
          headers: authHeader(),
          data: values
        })
          .then((res) => {
            toast.success(res.data.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
            dispatch({
              type: "DELETE_CUSTOMER_ADDRESSES_SUCCESS",
              payload: {
                customerAddresses: res.data.addressList
              }
            });
          })
          .catch((err) => {
            dispatch({type: "DELETE_CUSTOMER_ADDRESSES_FAILED"});
            handlingAuthErrors(err);
          });
      }
    });
  };

export const customerCreditCardListsAction = () => (dispatch) => {
  dispatch({type: "GET_CUSTOMER_CREDIT_CARD_REQUEST"});
  axios({
    method: "GET",
    url: `${process.env.REACT_APP_LOCAL_API_URL}/appointment/card/details`,
    headers: authHeader()
  })
    .then((res) => {
      dispatch({
        type: "GET_CUSTOMER_CREDIT_CARD_SUCCESS",
        payload: res?.data ? res?.data : null
      });
    })
    .catch((err) => {
      dispatch({type: "GET_CUSTOMER_CREDIT_CARD_FAILED", payload: err});
      console.log("err", err);
      handlingAuthErrors(err);
    });
};

export const customerCreditCardDeleteAction = (baseUrl) => (dispatch) => {
  MySwal.fire({
    title: "Are you sure?",
    text: "Are you sure you want to delete this card?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Delete"
  }).then((result) => {
    if (result.isConfirmed) {
      // dispatch({type: 'GET_CUSTOMER_CREDIT_CARD_REQUEST'})
      axios
        .delete(baseUrl, {
          headers: authHeader()
        })
        .then((res) => {
          // dispatch({type: 'DELETE_CUSTOMER_CREDIT_CARD_SUCCESS'});
          // localStorage.setItem('currentUser', JSON.stringify(res.data.customer));
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          setTimeout(() => {
            window.location.href = "/my-account/creditcard";
          }, 2000);
        })
        .catch((err) => {
          dispatch({type: "DELETE_CLEANERS_FAILED"});
          handlingAuthErrors(err);
        });
    }
  });
};
