import React, {useState} from "react";
import {Link} from "react-router-dom";
import LoginModal2 from "../../components/LoginModal2";
const Login = () => {
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };
  const [loginModalShow, setLoginModalShow] = useState(false);
  const handleLoginModalShow = () => setLoginModalShow(true);
  const handleLoginModalHide = () => setLoginModalShow(false);

  return (
    <>
      <div className="loginForm">
        <ul>
          <li
            className={toggleState === 1 ? "signBtn signBtn-active" : "signBtn"}
            onClick={() => toggleTab(1)}
          >
            Login
          </li>

          <li
            className={toggleState === 2 ? "signBtn signBtn-active" : "signBtn"}
            onClick={() => toggleTab(2)}
          >
            Sign Up
          </li>
        </ul>
        <div className="loginTabArea">
          <div
            className={
              toggleState === 1
                ? "loginTabContent loginTabContentActive"
                : "loginTabContent"
            }
          >
            <div className="loginArea">
              <h2>Welcome back!</h2>
              <div className="loginButtonGroup">
                <button className="btn btn-apple">
                  <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/apple.png.webp" />
                  Sign in with Apple
                </button>
                <button className="btn btn-facebook">
                  <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/facebook.png.webp" />
                  Continue with Facebook
                </button>
                <button
                  className="btn btn-phone"
                  onClick={handleLoginModalShow}
                >
                  <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/phone.png.webp" />
                  Sign in with Mobile number
                </button>
                <button className="btn btn-google">
                  <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/google.png.webp" />
                  Sign in with Google
                </button>
              </div>
              <div className="loginWithEmail">
                <div className="text-center orLogin mb-5">
                  <h3>or login with email </h3>
                </div>
                <div className="form-group mb-4">
                  <input
                    type="text"
                    className="form-control form-input-border"
                    placeholder="Email Address"
                  />
                </div>
                <div className="form-group mb-5">
                  <input
                    type="text"
                    className="form-control form-input-border"
                    placeholder="Password"
                  />
                </div>
                <div className="mt-5">
                  <button type="submit" className="btn btn-primary w-full  ">
                    Sign up with email
                  </button>
                </div>
                <div className="text-center text-xl">
                  <h3 className="mb-3">
                    {" "}
                    <Link to={"/customer/forgot-password"}>
                      Forgot Password?
                    </Link>
                  </h3>
                  <p>
                    Don’t have an account? <Link to={"/"}>Sign Up</Link>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              toggleState === 2
                ? "loginTabContent loginTabContentActive"
                : "loginTabContent"
            }
          >
            <div className="signupArea">
              <h2>
                Sign up to book services,
                <br /> access deals and more!
              </h2>
              <div className="loginButtonGroup">
                <button className="btn btn-apple">
                  <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/apple.png.webp" />
                  Sign in with Apple
                </button>
                <button className="btn btn-facebook">
                  <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/facebook.png.webp" />
                  Continue with Facebook
                </button>
                <button className="btn btn-phone">
                  <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/phone.png.webp" />
                  Sign in with Mobile number
                </button>
                <button className="btn btn-google">
                  <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/google.png.webp" />
                  Sign in with Google
                </button>
              </div>
              <div className="loginWithEmail">
                <div className="text-center orLogin mb-5">
                  <h3>or </h3>
                </div>
                <div className="form-group mb-4">
                  <input
                    type="text"
                    className="form-control form-input-border"
                    placeholder="Email Address"
                  />
                </div>
                <div className="form-group mb-5">
                  <input
                    type="text"
                    className="form-control form-input-border"
                    placeholder="Password"
                  />
                </div>
                <div className="mt-5">
                  <button type="submit" className="btn btn-primary w-full  ">
                    Sign up with email
                  </button>
                </div>
                <div className="text-center text-xl">
                  <p>
                    Already have an account? <Link to={"/"}>Login</Link>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="loginfooter">
          <h3 className="mb-2">
            Booked over phone or chat? <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/info3.png.webp" />
          </h3>
          <p>
            Claim your online account to get access to bookings you made over
            the phone or via chat.
          </p>
          <Link to={"/customer/claim-account"}>Claim Account</Link>
        </div>
      </div>

      {/* <LoginModal2 showLoginModal={loginModalShow} hideLoginModal={handleLoginModalHide}/> */}
    </>
  );
};

export default Login;
