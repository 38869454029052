import React from 'react'
import ServiceHeader from './ServiceHeader'
import ServiceList from './ServiceList'
import ServiceSearch from './ServiceSearch'
import ServiceSliderPage from './ServiceSliderPage'
import ServicePageFooter from './ServicePageFooter'
const ServicePage = () => {
  return (
    <>
       
       <ServiceHeader/>
       <div className='container max1060'>
       <ServiceSearch/>
       <ServiceSliderPage/>
       <ServiceList/>
       </div>
   
       
    </>
  )
}

export default ServicePage