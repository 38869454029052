export const homeReducer = (state = {}, action) => {
   
    switch (action.type) {
        case'GET_HOME_MAIN_DATA_REQUEST':
        return {
            ...state,
            loading: true,
        }
        case'GET_HOME_MAIN_DATA_SUCCESS':
        return {
            frequencies:action.payload.frequencies,
            services:action.payload.services,
            banner:action.payload.banner,
            servicesResult:action.payload.servicesResult,
            loading: false,
            success: action.payload.status,
            footerServices:action.payload.footerServices,
            allServiceListData: action.payload.allData
            
        }
        case'GET_HOME_DATA_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_HOME_DATA_SUCCESS':
            return {
                services:action.payload.services,
                banner:action.payload.banner,
                loading: false,
                success: true
            }
        case'GET_HOME_DATA_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        case'GET_HOME_SERVICE_CATEGORY_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_HOME_SERVICE_CATEGORY_SUCCESS':
            return {
                servicesCategory:action.payload.servicesCategory,
                loading: false,
                success: true
            }
        case'GET_HOME_SERVICE_CATEGORY_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        case'GET_HOME_PROFESSIONAL_LIST_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_HOME_PROFESSIONAL_LIST_SUCCESS':
            return {
                professionals:action.payload.professionals,
                loading: false,
                success: true
            }
        case'GET_HOME_PROFESSIONAL_LIST_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        default:
            return state
    }
}