import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {
  customerAddressListsAction,
  makeDefaultCustomerAddressAction
} from "../../../../actions/customerDetails";
import {ModifiedToastContainer} from "../../../../components/ModifiedToastContainer";
import Loader from "../../../../components/Loader";
import AddressComponent from "../../../my_account/Address/AddressComponent";
// import AddressAddModal from "../../../my_account/Address/AddressAddModal";

export const Address = ({
  checkoutData,
  // updateCheckoutData,
  // appointment,
  setCheckoutData,
  isAddressAdded
  // isBookingwithoutLogin
}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  // let addresslistUrl = baseUrl + "/customer/address/list";
  const userCountryId = localStorage.getItem("userCountryId");
  let listUrl = baseUrl + "/customer/address/list";
  useEffect(() => {
    const isLoggedIn =
      localStorage.getItem("currentToken") &&
      localStorage.getItem("currentToken") !== "null" &&
      localStorage.getItem("currentToken") !== ""
        ? true
        : false;
    if (isLoggedIn) {
      dispatch(customerAddressListsAction(listUrl));
    }
  }, []);
  const getCustomerAllAddressesState = useSelector(
    (state) => state.customerDetailsReducer
  );
  const {loading, customerAddresses} = getCustomerAllAddressesState;
  const setAddAddressRef = useRef();
  const [isAddAddress, setIsAddress] = useState(false);
  // useEffect(() => {
  //   dispatch(customerAddressListsAction(listUrl));
  // }, []);

  const findDefaultAddress = (addresses) => {
    setTimeout(() => {
      if (addresses?.length > 0) {
        addresses.forEach((address) => {
          if (address.isDefault) {
            setAppointmentAddress(address);
          }
        });
      }
    }, 2000);
  };

  useEffect(() => {
    if (customerAddresses?.length > 0) {
      customerAddresses.forEach((address) => {
        if (address?.isDefault) {
          setCheckoutData((prevState) => ({
            ...prevState,
            address_id: address?.id ? address?.id : "",
            address: address?.address,
            country: address?.country,
            latitude: address?.latitude,
            longitude: address?.longitude
          }));
          setCheckoutData({
            ...checkoutData,
            ["address_id"]: address?.id,
            ["address"]: address?.address,
            ["country"]: address?.country,
            ["latitude"]: address?.latitude,
            ["longitude"]: address?.longitude
          });
        }
      });
    }
  }, []);

  async function setAppointmentAddress(address) {
    setCheckoutData({
      ...checkoutData,
      ["address_id"]: address?.id,
      ["address"]: address?.address,
      ["country"]: address?.country,
      ["latitude"]: address?.latitude,
      ["longitude"]: address?.longitude
    });
  }

  // const country_arr = [];
  // for (var i = 0; i < country_list?.length; i++) {
  //   if (country_list[i]?.isActive) {
  //     country_arr.push(country_list[i]?.name);
  //   }
  // }
  useEffect(() => {
    if (customerAddresses?.length > 0) {
      customerAddresses.map((address, index) => {
        if (
          address.country_id === localStorage.getItem("userCountryId") &&
          address
        ) {
          if (address?.isDefault) {
            setCheckoutData({
              ...checkoutData,
              ["address_id"]: address?.id,
              ["address"]: address?.address,
              ["country"]: address?.country,
              ["latitude"]: address?.latitude,
              ["longitude"]: address?.longitude
            });
            // makeDefaultAddress(address?.id);
          }
        }
      });
    }
  }, []);
  useEffect(() => {
    if (customerAddresses?.length > 0) {
      customerAddresses.map((address, index) => {
        if (
          address.country_id === localStorage.getItem("userCountryId") &&
          address
        ) {
          if (address.isDefault) {
            setCheckoutData({
              ...checkoutData,
              ["address_id"]: address?.id
            });
          }
        }
      });
    }
  }, [customerAddresses]);

  async function selectAppointmentAddress(address) {
    setCheckoutData({
      ...checkoutData,
      ["address_id"]: address?.id,
      ["address"]: address?.address,
      ["country"]: address?.country,
      ["latitude"]: address?.latitude,
      ["longitude"]: address?.longitude
    });
    makeDefaultAddress(address?.id);
    // handleUserAddresListModalHide();
  }

  const getAddresses = () => {
    dispatch(customerAddressListsAction(listUrl, findDefaultAddress));
  };

  async function makeDefaultAddress(id) {
    let actionUrl = baseUrl + "/customer/address/default/" + id;
    dispatch(makeDefaultCustomerAddressAction(actionUrl));
  }


  return (
    <>
      <ModifiedToastContainer />
      <div className="stepsContent AddressStep ">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="card">
              {loading ? <Loader /> : ""}
              <div className="stepsCard">
                <div className="checkboxSec">
                  <div className="heading">
                    <h3>{t("addresses")}</h3>
                  </div>
                  {customerAddresses && customerAddresses?.length > 0 ? (
                    customerAddresses.map((address, index) => {
                      if (address?.country_id === userCountryId) {
                        return (
                          <div
                            className="checkboxItem mb-4"
                            key={index}
                            onClick={() => selectAppointmentAddress(address)}
                          >
                            <div>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  id={"addresss_" + index}
                                  name="optradio2"
                                  defaultChecked={address?.isDefault}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={"addresss_" + index}
                                >
                                  {`${address.address_nickname} ${address.area}`}
                                </label>
                              </div>
                              <p className="newAddresDetails">
                                {address.addressDetails}
                              </p>
                              {address.address !== "" ? (
                                <p>{`${address.address}`}</p>
                              ) : (
                                ""
                              )}
                              <p>{`${address?.street} ${address?.address_type} ${address?.floor} 
                                          `}</p>
                              <p>{`${address.mobile}`}</p>
                              <p>{address.landline_no}</p>
                            </div>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })
                  ) : (
                    <p className="">{t("no_address_found")}</p>
                  )}
                  <div className="selctAddressItem p-0 border-0 mb-5">
                    <h3
                      onClick={() => {
                        setIsAddress(true);
                      }}
                    >
                      <i className="fa fa-plus"></i>
                      {t("add_new_address")}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddressComponent
        isAddAddress={isAddAddress}
        setIsAddress={setIsAddress}
      />
    </>
  );
};
export default Address;
