export const countryReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_COUNTRY_LIST_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_COUNTRY_LIST_SUCCESS':
            return {
                ...state,
                country_list:action.payload.countries,
                country_arr:action.payload.country_arr,
                loading: false,
                success: true
            }
        case'GET_COUNTRY_LIST_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        default:
            return state
    }
}