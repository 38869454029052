import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useTranslation } from 'react-i18next';

const CancelMyAppointment = () => {
  useEffect(() => {
    if (!localStorage.getItem("appointmentDetailsId")) {
      window.location.href = "/my-account/appointments";
    }
  }, []);
  const [showCancelAppointmentModal, setShowCancelAppointmentModal] =
    useState(false);
  const handleCancelAppointmentModalShow = () =>
    setShowCancelAppointmentModal(true);
  const handleCancelAppointmentModalClose = () =>
    setShowCancelAppointmentModal(false);
  
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <div className="py-5">
        <div className="userContainer">
          <div className="cancelAppoitment">
            <div className="heading">   
              <h2>{t('cancel_appointment')}</h2>
            </div>
            <div className="card">
              <div className="personalForm"> 
                <p> {t('cancel_appointment')}
                  {t('about_to_cancel_your_booking')}
                </p>
                <Link to="/my-account/reschedule-appointment">
                  <button className="btn btn-primary w-full">{t('reschedule')} </button>
                </Link>
                <p>  
                  {t('still_want_to_cancel_your_booking')}
                </p>
                <p
                  className="cancel"
                  onClick={() => handleCancelAppointmentModalShow()}
                >
                  {t('cancel_this_booking')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showCancelAppointmentModal}
        onHide={handleCancelAppointmentModalClose}
        id="cancelModal"
        className="addAddressModal"
      >
        <Modal.Header closeButton>
          <Modal.Title> {t('cancel_this_booking')} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row"> 
            <p>{t('sure_cancel_appointment')}</p>
            <div className="w-full">
              <Link to="/my-account/cancel-appointment">
                <button
                  className="btn btn-primary mt-3 "
                  data-bs-dismiss="modal"
                >
                  {t('cancel')} 
                </button>
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
};

export default CancelMyAppointment;
