import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "./Header";
import Footer from "./Footer";
import Loader from "./Loader";
import {useParams} from 'react-router-dom';
import {FacebookShareButton, FacebookIcon} from "react-share";
import {TwitterShareButton, TwitterIcon} from "react-share";
import {LinkedinShareButton, LinkedinIcon} from "react-share";
import { getBlogDetailAction } from "../actions/BlogsAction";

const BlogDetail = () => {
    const currentLanguage = localStorage.getItem('currentLanguage') ? localStorage.getItem('currentLanguage') : 'en'; 
    const params = useParams();
    const getBlogDetailState = useSelector((state) => state.blogsReducer);
    const {  loading, blog } = getBlogDetailState;
    const dispatch = useDispatch();
    let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
    let listUrl = baseUrl + "/blog/edit/"+params.blogId;
    useEffect(() => {
      dispatch(getBlogDetailAction(listUrl));
    }, [listUrl,dispatch]);
    const created_at = blog?.createdAt;
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const created_at_ymd = new Date(created_at).toLocaleDateString([],options);
    
  return (
    <>
      <Header />
      {loading ? <Loader /> : ""}
      <div className="blogPAGE">
        <div className="container">
          <div className="blogDetail">
            <div className="blogPublished">
              <div className="publishlogo">
                <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/logo.png.webp" />
              </div>
              <div className="publishDate">
                <h4>RighHands</h4>
                <p>{created_at_ymd}</p>
              </div>
            </div>
            <div className="blogDetailImage">
              <img alt="" src={ blog?.icon ? blog?.icon : 'https://dvdljkkxpxqo3.cloudfront.net/images/no_image.jpg.webp' } />
            </div>
            <div className="p-3">
              <h2>{(currentLanguage === "en" ? blog?.title : blog?.title_arabic)}</h2>
              <p dangerouslySetInnerHTML={ { __html: ((blog) ? (currentLanguage === "en" ? blog?.body : blog?.body_arabic) : "")}}></p>
              <div className="socailIcon">
                <TwitterShareButton
                  url={window.location.href}
                  title={(currentLanguage === "en" ? blog?.title : blog?.title_arabic)}
                >
                <TwitterIcon size={36}/>
                </TwitterShareButton>&nbsp;
                <FacebookShareButton 
                       url={window.location.href}
                       quote={(currentLanguage === "en" ? blog?.title : blog?.title_arabic)}
                       >
                       <FacebookIcon size={36}/>
                </FacebookShareButton>&nbsp;
                <LinkedinShareButton 
                       url={window.location.href}
                       title={(currentLanguage === "en" ? blog?.title : blog?.title_arabic)}
                       >
                       <LinkedinIcon size={36}/>
                </LinkedinShareButton>&nbsp;
                <span className="fa fa-link" onClick={() =>  navigator.clipboard.writeText(window.location.href)}></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogDetail;
