import React from "react";
import SelectDateTime from "./SelectDateTime";
import CheckoutSummary from "../components/CheckoutSummary";
import Steps from "../components/Steps";

export const DateTime = ({ 
  cart,
  updateCart,
  checkoutData,
  updateCheckoutData,
  appointment,
  setCheckoutData
}) => {
  return (
    <>
      <div className="">
        <Steps checkoutData={checkoutData}/>
      </div>
      <div className="stepsContent stepDate ">
        <div className="row">
          <div className="col-lg-7 col-md-12">
            <div className="card">
              <div className="stepsCard ">
                <SelectDateTime 
                  checkoutData={checkoutData}
                  updateCheckoutData={updateCheckoutData}
                  setCheckoutData={setCheckoutData}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-5">
            <CheckoutSummary 
              checkoutData={checkoutData}
              cart={cart}
              updateCheckoutData={updateCheckoutData}
              appointment={appointment}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default DateTime;
