import axios from 'axios'
import {authHeader, handlingNonauthErrors} from "../helpers/helpers";

export const getTrackInvitesAction = (baseUrl) => dispatch => {

    dispatch({type: 'GET_TRACK_INVITES_REQUEST'})
    axios({
        method: 'GET',
        headers:authHeader(),
        url: baseUrl,
        data: {}
    }).then((res) => {
        dispatch({type: 'GET_TRACK_INVITES_SUCCESS', payload: {
            invites:{
                friend_referrals: res.data.friend_referrals,
                successful_invites: res.data.successful_invites
            }
        }});
    }).catch((err) => {
        dispatch({type: 'GET_TRACK_INVITES_FAILED', payload: err});
        handlingNonauthErrors(err)
    })
}