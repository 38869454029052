export const invitationReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_TRACK_INVITES_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_TRACK_INVITES_SUCCESS':
            return {
                invites:action.payload.invites,
                loading: false,
                success: true
            }
        case'GET_TRACK_INVITES_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        default:
            return state
    }
}