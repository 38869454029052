import React, { useEffect} from "react";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {useDispatch, useSelector} from "react-redux";
import AppDownload from "../components/AppDownload";
import { getHomeMainService} from "../actions/homeAction";
import Loader from "../components/Loader";
import {getRHClubAction} from "../actions/homeAction";
import {batch} from "react-redux";

const Membership = () => {
  const {t} = useTranslation();
  // let navigate = useNavigate();
  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  const getAllServicesState = useSelector((state) => state.homeReducer);
  // const allStates = useSelector((state) => state);
  const {loading, servicesResult, success} = getAllServicesState;

  const dispatch = useDispatch();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let listUrl = baseUrl + "/home/services";
  // useEffect(() => {
  //   const countryID = localStorage.getItem("userCountryId");
  //   let listMainUrl =
  //     baseUrl +
  //     `/home/dashboard?country_id=${localStorage.getItem("userCountryId")}`;
  //   batch(() => {
  //     //  dispatch(getHomeDataAction(listUrl));
  //     dispatch(getHomeMainService(listMainUrl));
  //   });
  // }, [listUrl]);
  useEffect(() => {}, []);

  // async function viewServiceDetails(id, ui_type) {
  //   if (ui_type === "Frequency") {
  //     localStorage.setItem("frequencyTypeServiceDetailId", id);
  //   } else {
  //     if (
  //       localStorage.getItem("serviceDetailId") &&
  //       localStorage.getItem("serviceDetailId") !== id
  //     ) {
  //       localStorage.removeItem("bookAppointmentCartData");
  //       localStorage.removeItem("bookAppointmentDetails");
  //     }
  //     localStorage.setItem("serviceDetailId", id);
  //   }
  //   setTimeout(() => {
  //     navigate(
  //       ui_type === "Frequency"
  //         ? {pathname: "/frequency/checkout/details", state: id}
  //         : "/service/checkout/details?step=1&service_id=" + id
  //     );
  //     window.scrollTo(0, 0);
  //   }, 500);
  // }

  const getRHClubState = useSelector((state) => state.rhClubReducer);
  const {rh_club} = getRHClubState;
  // console.log(rh_club, 9999);
  let rhClubUrl =
    baseUrl +
    "/country/edit/" +
    (localStorage.getItem("userCountryId")
      ? localStorage.getItem("userCountryId")
      : "");
  useEffect(() => {
    dispatch(getRHClubAction(rhClubUrl));
  }, [rhClubUrl, dispatch]);

  return (
    <>
      {/* {loading ? <Loader /> : ""} */}
      <Header />
      <div className="memberShippage">
        <div className="memberShipBanner">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="memberBnrContent">
                  <h2>
                    {t("one_membership")}
                    <br></br> {t("all_the_benefits")}
                  </h2>
                  {rh_club?.rhClub ? (
                    <>
                      <p>
                        {t("enjoy_priority_booking")}, <br></br>
                        <b>
                          {t("pay")} {rh_club?.rhPrice} {rh_club?.currency}{" "}
                          {t("and_get")}{" "}
                          <b style={{color: "#432375"}}>
                            {" "}
                            {rh_club?.rewards} {rh_club?.currency}
                          </b>
                        </b>{" "}
                        <br></br> {t("in_credits")}
                        {/* {t('we_plant')} */}
                      </p>
                      <NavLink to="/my-account/righthandcredit">
                        {!currentUser?.isRhMember && (
                          <button className="btn btn-primary">
                            {t("become_member")}
                          </button>
                        )}
                      </NavLink>
                    </>
                  ) : (
                    <h6>{loading ? <Loader /> : t("no_membership")}</h6>
                  )}
                </div>
              </div>
            </div>
          </div>
          <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/banner-image.jpg.webp" />
        </div>
        <div className="benefitsArea py-7 ">
          <div className="container">
            <div className="heading">
              <h2>Your gateway to 365 days of exclusive club rewards</h2>
            </div>
            <div className="row">
              <div className="col-lg-3 col-sm-6 col-md-4">
                <div className="benefitContent">
                  <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/wallet.png.webp" />
                  <p>1500 in credits</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-md-4">
                <div className="benefitContent">
                  <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/house.png.webp" />
                  <p>Domestic cleaning from 19/h</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-md-4">
                <div className="benefitContent">
                  <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/label.png.webp" />
                  <p>Preferential member rates</p>
                </div>
              </div>
              {/* <div className="col-lg-3 col-sm-6 col-md-4">
                <div className="benefitContent">
                  <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/plant-a-tree.png.webp" />
                  <p>Plant a tree</p>
                </div>
              </div> */}
              <div className="col-lg-3 col-sm-6 col-md-4">
                <div className="benefitContent">
                  <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/credit-card.png.webp" />
                  <p>Exclusive seasonal deals</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-md-4">
                <div className="benefitContent">
                  <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/tracker.png.webp" />
                  <p>Real-time arrival tracker</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-md-4">
                <div className="benefitContent">
                  <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/booking.png.webp" />
                  <p>Priority booking & case handling</p>
                </div>
              </div>
              {/* <div className="col-lg-3 col-sm-6 col-md-4">
                <div className="benefitContent">
                  <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/deal.png.webp" />
                  <p>Deals by partnering brands</p>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className="ourProcess py-7 bg-white">
          <div className="container">
            <div className="heading">
              <h2>The benefits you enjoy for a whole year</h2>
              <p>
                With the new Right Hands you enjoy preferential member rates for
                some of our most popular and demanded services. You also
                immediately receive QAR300 in credits in your Right Hands
                Account. You can apply 30 credits to 10 different services to
                get QAR30 OFF the price total of each one.
              </p>
              <p>You can apply your credits to the following services:</p>
            </div>
            <div className="row mt-3 mt-md-5">
              {servicesResult?.length > 0 ? (
                servicesResult.map((service, index) => {
                  return (
                    <div className="col-lg-6 col-sm-6" key={index}>
                      <div className="serviceDiv">
                        <img
                          src={
                            service?.icon
                              ? service?.icon
                              : "https://dvdljkkxpxqo3.cloudfront.net/images/no_image.jpg.webp"
                          }
                          alt=""
                        />
                        <h3>
                          {currentLanguage === "en"
                            ? service.name
                            : service.name_arabic}
                        </h3>
                        {/* <p>{ currentLanguage === 'en' ? service.description : service.description_arabic  }</p> */}
                      </div>
                    </div>
                  );
                })
              ) : (
                <center className="">{t("no_services_found")}</center>
              )}
            </div>
          </div>
        </div>
        <AppDownload />
      </div>
      <Footer />
    </>
  );
};

export default Membership;
