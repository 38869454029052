import axios from 'axios'
import {toast} from "react-toastify";
import {authHeader, handlingNonauthErrors} from "../helpers/helpers";

export const getBlogsAction = (baseUrl,values) => dispatch => {

    dispatch({type: 'GET_BLOGS_LIST_REQUEST'})
    axios({
        method: 'GET',
        headers:authHeader(),
        url: baseUrl,
        data: values
    }).then((res) => {
        dispatch({type: 'GET_BLOGS_LIST_SUCCESS', payload: {
            blogs_list:res.data
        }});
    }).catch((err) => {
        dispatch({type: 'GET_BLOGS_LIST_FAILED', payload: err});
        handlingNonauthErrors(err)
    });
}

export const getBlogDetailAction = (baseUrl,values) => dispatch => {

    dispatch({type: 'GET_BLOGS_DETAIL_REQUEST'})
    axios({
        method: 'GET',
        headers:authHeader(),
        url: baseUrl,
        data: values
    }).then((res) => {
        dispatch({type: 'GET_BLOGS_DETAIL_SUCCESS', payload: {
            blog:res.data.blog
        }});
    }).catch((err) => {
        dispatch({type: 'GET_BLOGS_DETAIL_FAILED', payload: err});
        handlingNonauthErrors(err)
    });
}