import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef
} from "react";
import {useTranslation} from "react-i18next";
import ReactPhoneInput from "react-phone-input-2";
import {Link} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import LoginModal2 from "../components/LoginModal2";
import jQuery from "jquery";
import {useDispatch, useSelector} from "react-redux";
import {
  loginEmailUserAction,
  signUpEmailUserAction,
  countryListAction,
  profileSetupAction,
  addressSetupAction
} from "../actions/userActions";
import {removeCartAppointmentAction} from "../actions/appointmentAction";

import SignupModal from "../components/SignupModal";
import {toast} from "react-toastify";
// import LocationSearchModal from "./LocationSearchModal";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import AppleLogin from "react-apple-login";
import {gapi} from "gapi-script";
import es from "react-phone-input-2/lang/es.json";
import ar from "react-phone-input-2/lang/ar.json";
import EmailInput from "../helpers/EmailInput";
// import LocationSearch from "./LocationSearch";
import AddressSetupComponent from "./LoginSignup/SetupAccountModals/AddressSetupComponent";
import LoginWithMobileModal from "./LoginSignup/LoginWithMobileModal";

const LoginSignupModal = forwardRef((props, ref) => {
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  // let facebookAppId = "825719178678540";
  let facebookAppId = "889352129375161";
  const {isLoggedIn, from} = props;
  const loginWithMobileRef = useRef();
  const userCountryCode = localStorage.getItem("userCountryCode")
    ? localStorage.getItem("userCountryCode")
    : "en";
  let googleClientId =
    "395949074977-gsgdttv80ref43n64flph6bcsd5aq3ke.apps.googleusercontent.com";
  const currentLanguageCode = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  const {t} = useTranslation();
  const [loginWithGoogle, setLoginWithGoogle] = useState(false);
  const [loginWithFacebook, setLoginWithFacebook] = useState(false);
  const [loginWithApple, setLoginWithApple] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const handleInfoModalClose = () => setShowInfoModal(false);
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };
  const [loginModalShow, setLoginModalShow] = useState(false);
  const handleLoginModalHide = () => setLoginModalShow(false);
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });
  const [addressObject, setAddressObject] = useState();
  const handelSingUpInputChange = (e) => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };
  const [profileInfoModal, setProfileInfoModal] = useState(false);
  const handleProfileInfoModalShow = () => setProfileInfoModal(true);
  const handleProfileInfoModalClose = () => setProfileInfoModal(false);

  const [appointmentCartModal, setAppointmentCartModal] = useState(false);
  const handleAppointmentCartModalShow = () => setAppointmentCartModal(true);
  const handleAppointmentCartModalClose = () => setAppointmentCartModal(false);

  const [showAddressModal, setShowAddressModal] = useState(false);
  const handleAddressModalShow = () => setShowAddressModal(true);
  const handleAddressModalClose = () => setShowAddressModal(false);

  const [signupModalShow, setSignupModalShow] = useState(false);
  const handleSignupModalShow = () => {
    setSignupModalShow(true);
    // setShowInfoModal(false);
  };
  const [loginDataFields, setLoginDataFields] = useState({
    email_id: "",
    password: ""
  });
  const handleSignupModalHide = () => setSignupModalShow(false);
  const [bookingUrl, setBookingUrl] = useState("");
  const [file, setFile] = useState();
  const [preview1, setPreview1] = useState(
    `/images/user-placeholder.webp`
  );
  const getAllCountryState = useSelector((state) => state.countryReducer);
  const {loading, country_list, country_arr} = getAllCountryState;
  let object1Url;
  function handleUpload(event) {
    setFile(event.target.files[0]);
    object1Url = URL.createObjectURL(event.target.files[0]);
    setPreview1(object1Url);
  }
  function setAutoFillAddress(address) {
    setAddressObject({
      ...addressObject,
      ["address"]: address?.address ? address?.address : "",
      ["area"]: address?.area ? address?.area : "",
      ["building"]: address?.building ? address?.building : "",
      ["city"]: address?.city ? address?.city : "",
      ["state"]: address?.state ? address?.state : "",
      ["street"]: address?.street
        ? address?.street
        : document.getElementById("street")?.value,
      ["latitude"]: address?.latitude ? address?.latitude : "",
      ["longitude"]: address?.longitude ? address?.longitude : ""
    });
  }
  const dispatch = useDispatch();
  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  const handelInputChange = (e) => {
    const {name, value} = e.target;
    setLoginDataFields({...loginDataFields, [name]: value});
  };

  const handleLoginWithEmail = (e) => {
    e.preventDefault();

    let loginEmailUrl = baseUrl + "/customer/login";
    let email_id = jQuery("#email_id").val();
    let password = jQuery("#password").val();
    let values = {
      email: loginDataFields.email_id ? loginDataFields.email_id : email_id,
      password: loginDataFields.password ? loginDataFields.password : password,
      device_type: "website"
    };
    dispatch(
      loginEmailUserAction(
        loginEmailUrl,
        values,
        emailLoginSuccess,
        setShowInfoModal
      )
    );
  };

  const handleSignUpWithEmail = (e) => {
    e.preventDefault();
    let signUpEmailUrl = baseUrl + "/customer/signup";
    // let sign_up_email = jQuery("#sign_up_email").val();
    // let sign_up_password = jQuery("#sign_up_password").val();
    let values = {
      email: formData.email,
      password: formData.password,
      device_type: "website"
    };
    dispatch(signUpEmailUserAction(signUpEmailUrl, values, emailSignUpSuccess));
  };

  async function emailLoginSuccess(token, cart = null, msg) {
    const currentUserDetail =
      localStorage.getItem("currentUser") &&
      localStorage.getItem("currentUser") !== "null"
        ? JSON.parse(localStorage.getItem("currentUser"))
        : "";
    if (currentUserDetail?.profile_status === 1) {
      handleInfoModalClose();
      handleAddressModalShow();
    } else if (currentUserDetail?.profile_status === 2) {
      handleInfoModalClose();
      setUserSignUpEmail(currentUserDetail?.email);
      setUserSignUpName(currentUserDetail?.name);
      handleProfileInfoModalShow();
    } else if (currentUserDetail?.profile_status === 3) {
      localStorage.setItem("currentToken", JSON.stringify(token));

      if (cart) {
        localStorage.setItem("bookAppointmentId", cart._id);
        if (cart?.is_home_cleaning) {
          localStorage.setItem("frequencyTypeServiceDetailId", cart._id);
          //localStorage.setItem('frequencyCheckoutData', null);
        } else {
          if (
            localStorage.getItem("serviceDetailId") &&
            localStorage.getItem("serviceDetailId") !== cart._id
          ) {
            localStorage.removeItem("bookAppointmentCartData");
            localStorage.removeItem("bookAppointmentDetails");
          }
          localStorage.setItem("serviceDetailId", cart._id);
        }

        if (msg) {
          handleInfoModalClose();
        }
        // let mybookingUrl = cart?.is_home_cleaning
        //   ? "/frequency/checkout/details"
        //   : "/service/checkout/details?step=1&service_id=" + cart._id;
        // setBookingUrl(mybookingUrl);
        // handleInfoModalClose();
        // setTimeout(() => {
        //   handleAppointmentCartModalShow();
        // }, 500);
      } else {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        toast.success(msg, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        handleInfoModalClose();
      }
    }
  }

  async function emailSignUpSuccess(token, msg) {
    const currentUserDetail =
      localStorage.getItem("currentUser") &&
      localStorage.getItem("currentUser") !== "null"
        ? JSON.parse(localStorage.getItem("currentUser"))
        : "";
    handleInfoModalClose();
    if (currentUserDetail?.profile_status === 1) {
      handleAddressModalShow();
    } else if (currentUserDetail?.profile_status === 2) {
      handleProfileInfoModalShow();
    } else if (currentUserDetail?.profile_status === 3) {
      setTimeout(() => {
        localStorage.setItem("currentToken", JSON.stringify(token));
        window.location.reload();
      }, 2000);
      toast.success(msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
  }

  let listUrl = baseUrl + "/country/list";
  const [userSignUpEmail, setUserSignUpEmail] = React.useState("");
  const [userSignUpName, setUserSignUpName] = React.useState("");

  const handleAddressSubmit = async (e) => {
    e.preventDefault();
    let addr = jQuery("#address").val();
    let contry = jQuery("#country").val();
    let lat = jQuery("#latitude").val();
    let long = jQuery("#longitude").val();
    let country_id = localStorage.getItem("userCountryId");
    setTimeout(() => {
      jQuery("#address").val(addr);
      jQuery("#country").val(contry);
      jQuery("#latitude").val(lat);
      jQuery("#longitude").val(long);
      jQuery("#country_id").val(country_id);
    }, 1000);

    dispatch(countryListAction(listUrl, {}));

    const currentUserDetail =
      localStorage.getItem("currentUser") &&
      localStorage.getItem("currentUser") !== "null"
        ? JSON.parse(localStorage.getItem("currentUser"))
        : "";
    var message = "";
    if (country_id) {
      if (country_list?.some((country) => country.id === country_id)) {
        jQuery("#customer_id").val(currentUserDetail.id);
        let form = document.getElementById("addressSetupForm");

        // Create an empty object to store form values
        let forms = {};

        // Iterate through form elements
        for (let i = 0; i < form.elements.length; i++) {
          let element = form.elements[i];

          // Check if the element has a name and is not a button or other non-input elements
          if (element.name && element.tagName !== "BUTTON") {
            // Assign the element value to the corresponding property in the frm object
            forms[element.name] = element.value;
          }
        }

        // Now "frm" contains all the form values
       
        let frm = {
          customer_id: currentUserDetail.id ? currentUserDetail.id : "",
          address_nickname: forms.address_nickname
            ? forms.address_nickname
            : "",
         ["area"]: addressObject?.area ? addressObject?.area : "",
          ["address_type"]: forms?.address_type ? forms.address_type : "",
          street: forms?.street ? forms.street : "",
          building: forms?.building ? forms.building : "",
          country_id: localStorage.getItem("userCountryId"),
          floor: forms?.floor ? forms.floor : "",
          ["country_code"]: forms?.country_code ? forms.country_code : "",
          mobile: forms?.mobile ? forms.mobile : "",
          landline_no: forms?.landline_no ? forms.landline_no : "",
          additional_direction: forms?.additional_direction ? forms.additional_direction : "",
          address: addressObject?.address
            ? addressObject.address
            : forms?.address
            ? forms.address
            : "",
            house_no: forms?.house_no ? forms.house_no : "",
          latitude: addressObject?.latitude
            ? addressObject?.latitude?.toString()
            : "",
          longitude: addressObject?.longitude
            ? addressObject?.longitude?.toString()
            : ""
        };
        if(frm.address_type === "" || frm.address_type === undefined){

        }
        // console.log(frm);
        // let frm = document.getElementById("addressSetupForm");
        let apiAddressSetupUrl = baseUrl + "/customer/address/setup";
        dispatch(
          addressSetupAction(
            frm,
            apiAddressSetupUrl,
            handleProfileModalShow,
            currentLanguage,
            "newComponent"
          )
        );
        setUserSignUpEmail(currentUserDetail?.email);
        setUserSignUpName(currentUserDetail?.name);
      } else {
        message = t("currently_not_operate_in_this_area");
      }
    } else {
      message = t("search_area_first");
    }
    if (message !== "") {
      toast.error(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    }
  };

  async function handleProfileModalShow(address, msg) {
    setTimeout(() => {
      handleAddressModalClose();
      handleProfileInfoModalShow();
    }, 1000);
  }

  const handleProfileSetupSubmit = async (e) => {
    e.preventDefault();
    const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
    if (file && !validImageTypes.includes(file.type)) {
      toast.error(t("image_only"), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    } else {
      const currentUserDetail =
        localStorage.getItem("currentUser") &&
        localStorage.getItem("currentUser") !== "null"
          ? JSON.parse(localStorage.getItem("currentUser"))
          : "";
      const formData = new FormData();
      formData.append("customer_id", currentUserDetail.id);
      formData.append("profile_image", file);
      formData.append("name", jQuery("#full_name").val());
      formData.append("email", jQuery("#email").val());
      formData.append("country_code", jQuery("#country_code").val());
      formData.append("mobile", jQuery("#mobile").val());
      formData.append("dob", jQuery("#dob").val());
      formData.append("gender", jQuery("#gender").val());
      formData.append("referral_code", jQuery("#referral_code").val());
      let frm = document.getElementById("profileSetupForm");
      let apiProfileSetupUrl = baseUrl + "/profile/setup";
      dispatch(
        profileSetupAction(formData, apiProfileSetupUrl, currentLanguage)
      );
      if (object1Url) URL.revokeObjectURL(object1Url);
    }
  };

  const onChange = (event) => {
    const value = event.target.value;
    let address = jQuery("#address").val();
    let country = jQuery("#country").val();
    let latitude = jQuery("#latitude").val();
    let longitude = jQuery("#longitude").val();
    let country_id = localStorage.getItem("userCountryId");
    setTimeout(() => {
      jQuery("#address").val(address);
      jQuery("#country").val(country);
      jQuery("#latitude").val(latitude);
      jQuery("#longitude").val(longitude);
      jQuery("#country_id").val(country_id);
    }, 1000);
    let inputName = "house_no";
    let inputPlaceholder = t("home");

    if (value == "Apartment") {
      inputName = "apartment_no";
      inputPlaceholder = t("apartment");
    } else if (value == "Office") {
      inputName = "office_no";
      inputPlaceholder = t("office");
    }

    inputPlaceholder = inputPlaceholder + " " + t("number");

    jQuery("#home_number").attr({
      name: inputName,
      placeholder: inputPlaceholder
    });
  };

  async function responseFacebook(response) {
    // console.log("responseFacebook", response);
    let values = {
      name: response.name,
      email: response?.email ? response.email : response.id + "@facebook.com",
      // "mobile": response.id,
      provider_id: response.id,
      login_type: "Facebook",
      fcm_token: "",
      device_type: "website",
      profile_image: response.picture.data.url ? response.picture.data.url : ""
    };
    // let values = {
    //   name: response.name,
    //   email: response?.email ? response.email : response.id + "@facebook.com",
    //   // "mobile": response.id,
    //   provider_id: response.id,
    //   login_type: "Facebook",
    //   fcm_token: "",
    //   device_type: "website",
    // };
    let socialLoginUrl = baseUrl + "/customer/social-login";
    dispatch(
      loginEmailUserAction(
        socialLoginUrl,
        values,
        emailSignUpSuccess,
        setShowInfoModal
      )
    );
  }

  async function responseApple(response) {
    /* let values = {
      "name": response?.user?.name?.firstName+" "+response?.user?.name?.lastName,
      "email": response?.user?.email ? response.user.email : response.id+"@apple.com",
      // "mobile": response.id,
      "provider_id": response?.authorization?.id_token,
      "login_type": "Apple",
      "fcm_token": "",
      "device_type":"website"
    }
    let socialLoginUrl = baseUrl+"/customer/social-login";
    dispatch(loginEmailUserAction(socialLoginUrl,values,emailSignUpSuccess)); */
  }

  async function responseSuccessGoogle(response) {
    if (response?.profileObj) {
      let values = {
        name: response.profileObj.name,
        email: response.profileObj.email,
        provider_id: response.profileObj.googleId,
        login_type: "Google",
        fcm_token: "",
        device_type: "website",
        profile_image: response.profileObj.imageUrl
          ? response.profileObj.imageUrl
          : ""
      };
      let socialLoginUrl = baseUrl + "/customer/social-login";
      dispatch(
        loginEmailUserAction(
          socialLoginUrl,
          values,
          emailSignUpSuccess,
          setShowInfoModal
        )
      );
    }
  }

  async function responseFailureGoogle(response) {
    console.log(response);
  }

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: googleClientId,
        scope: "email"
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const [passwordShown2, setPasswordShown2] = useState(false);
  const togglePasswordVisiblity2 = () => {
    setPasswordShown2(passwordShown2 ? false : true);
  };

  async function removeCartAppointment() {
    let bookAppointmentId = localStorage.getItem("bookAppointmentId");
    if (bookAppointmentId) {
      let removeCartUrl =
        baseUrl + `/customer/remove-cart-appointment/${bookAppointmentId}`;
      dispatch(
        removeCartAppointmentAction(
          removeCartUrl,
          handleAppointmentCartModalClose
        )
      );
    }
  }

  useImperativeHandle(ref, () => ({
    handleLoginSignupModalShow() {
      isLoggedIn !== true ? setShowInfoModal(true) : setShowInfoModal(false);
      setLoginWithFacebook(false);
      setLoginWithGoogle(false);
      setLoginWithApple(false);
    },
    handleLoginSignupModalHide() {
      setShowInfoModal(false);
      setLoginWithFacebook(false);
      setLoginWithGoogle(false);
      setLoginWithApple(false);
    }
  }));

  return (
    <div>
      {/*  Setup-Profile */}
      <Modal
        show={profileInfoModal}
        onHide={handleProfileInfoModalClose}
        className="addAddressModal"
        id="ProfileSetup"
      >
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body>
          <form onSubmit={handleProfileSetupSubmit} id="profileSetupForm">
            <div className="userInfo mb-3 pt-0">
              <div className="profileImage">
                <img alt="" src={preview1} />
                <div className="file-upload">
                  <input
                    type="file"
                    accept="image/*"
                    name="profile_image"
                    onChange={handleUpload}
                    className="formControl"
                    placeholder="Password*"
                  />
                  <i className="fa fa-image"></i>
                </div>
              </div>
            </div>
            <div className="profileForm" id="profileForm">
              <div className="row">
                <input
                  type="hidden"
                  id="profile_customer_id"
                  name="customer_id"
                />
                <div className="col-lg-12 inputdiv mb-2">
                  <input
                    type="text"
                    className="form-control pl-0"
                    name="name"
                    id="full_name"
                    defaultValue={userSignUpName}
                    placeholder={t("full_name")}
                  />
                  <div className="help-block"></div>
                </div>
                <div className="col-lg-12 inputdiv mb-2">
                  <input
                    type="email"
                    className="form-control pl-0"
                    value={userSignUpEmail}
                    name="email"
                    id="email"
                    placeholder={t("email")}
                  />
                  <div className="help-block"></div>
                </div>
                <div className="row loginMobileInput mb-2">
                  <div className="inputgroup phonecodeselect col-lg-5">
                    <ReactPhoneInput
                      inputProps={{
                        className: "login_country_code form-control",
                        name: "country_code",
                        id: "country_code",
                        autoFocus: true,
                        placeholder: t("enter_mobile_number"),
                        readOnly: "readonly"
                      }}
                      country={userCountryCode.toLocaleLowerCase()}
                      localization={currentLanguageCode === "en" ? es : ar}
                      countryCodeEditable={false}
                      placeholder=""
                      disableCountryCode={false}
                      searchClass="search-class"
                      searchStyle={{
                        margin: "0",
                        width: "97%",
                        height: "30px"
                      }}
                      enableSearch
                      disableSearchIcon
                    />
                    <div className="help-block text-danger"></div>
                  </div>
                  <div className="inputgroup phonecodeselect col-lg-7">
                    <input
                      type="number"
                      name="mobile"
                      id="mobile"
                      className="form-control rounded-pill"
                      placeholder={t("enter_mobile_number")}
                    />
                    <div className="help-block text-danger"></div>
                  </div>
                </div>
                <div className="col-lg-12 inputdiv mb-2">
                  <label>{t("date_of_birth_optional")}</label>
                  <input
                    type="date"
                    name="dob"
                    id="dob"
                    placeholder={t("enter_your_dob")}
                    className="form-control pl-0"
                    min="1965-01-01"
                    max="2020-12-31"
                  />
                  <div className="help-block"></div>
                </div>
                <div className="col-lg-12 inputdiv mb-2">
                  <label>{t("gender")}</label>
                  <select
                    className="form-select pl-0 genderSelect"
                    defaultValue={"Mr"}
                    name="gender"
                    id="gender"
                  >
                    <option value="Mr.">{t("gender_mr")}</option>
                    <option value="Ms.">{t("gender_ms")}</option>
                    <option value="Rather Not Say">
                      {t("gender_rather_not_say")}
                    </option>
                  </select>
                </div>
                <div className="col-lg-12 inputdiv mb-2">
                  <label>{t("referral_code_optional")}</label>
                  <input
                    type="text"
                    name="referral_code"
                    id="referral_code"
                    placeholder={t("enter_referral_code")}
                    className="form-control pl-0"
                  />
                </div>
                <div className="inputdiv col-lg-12 mt-3 mb-0">
                  <button className="btn btn-primary w-full">
                    {t("save_profile")}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/*  Setup-Profile End */}

      {/*  Info Modal */}
      <Modal
        show={showInfoModal}
        onHide={handleInfoModalClose}
        className="addAddressModal"
        id="addAddress"
      >
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body>
          <div className="loginForm">
            <ul>
              <li
                style={{width: '50%'}}
                className={
                   toggleState === 1 ? "signBtn signBtn-active" : "signBtn"
                }
                onClick={() => toggleTab(1)}
              >
                {t("login")}
              </li>
             
              <li
                className={
                  toggleState === 2 ? "signBtn signBtn-active" : "signBtn"
                }
                onClick={() => toggleTab(2)}
              >
                {t("signup")}
              </li> 
            </ul>
            <div className="loginTabArea">
              <div
                className={
                  toggleState === 1
                    ? "loginTabContent loginTabContentActive"
                    : "loginTabContent"
                }
              >
                <div className="loginArea">
                  <h2>{t("welcome_back")}</h2>
                  <div className="loginButtonGroup">
                    <button
                      className="btn btn-apple"
                      onClick={() =>
                        setLoginWithApple(loginWithApple ? false : true)
                      }
                    >
                      <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/apple.png.webp" />
                      {t("log_in_with_apple")}
                    </button>
                    <button
                      className="btn btn-facebook"
                      onClick={() =>
                        setLoginWithFacebook(loginWithFacebook ? false : true)
                      }
                    >
                      <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/facebook.png.webp" />
                      {t("continue_with_facebook")}
                    </button>
                    <button
                      className="btn btn-phone"
                      onClick={() => {
                        handleInfoModalClose();
                        loginWithMobileRef.current.handleLoginWithMobileModalShow();
                      }}
                    >
                      <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/phone.png.webp" />
                      {t("sign_in_with_mobile_number")}
                    </button>
                    <button
                      className="btn btn-google"
                      onClick={() =>
                        setLoginWithGoogle(loginWithGoogle ? false : true)
                      }
                    >
                      <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/google.png.webp" />
                      {t("sign_in_with_Google")}
                    </button>
                    {loginWithApple ? (
                      <AppleLogin
                        clientId="com.customer.web"
                        autoLoad={true}
                        redirectURI="https://betaweb.myrighth.com"
                        usePopup={true}
                        callback={responseApple} // Catch the response
                        scope="email name"
                        responseMode="query"
                        render={(
                          renderProps //Custom Apple Sign in Button
                        ) => (
                          <button
                            onClick={renderProps.onClick}
                            className="d-none"
                          >
                            <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/apple.png.webp" />
                            Continue with Apple
                          </button>
                        )}
                      />
                    ) : null}

                    {loginWithGoogle ? (
                      <GoogleLogin
                        clientId={googleClientId}
                        autoLoad={true}
                        className="d-none"
                        onSuccess={responseSuccessGoogle}
                        onFailure={responseFailureGoogle}
                        cookiePolicy={"single_host_origin"}
                      />
                    ) : null}

                    {loginWithFacebook ? (
                      <FacebookLogin
                        appId={facebookAppId}
                        autoLoad={true}
                        cssClass={"d-none"}
                        fields="link,first_name,middle_name,name,birthday,last_name,email,gender,locale,verified,picture.height(2048),age_range"
                        isMobile={false}
                        callback={responseFacebook}
                      />
                    ) : null}
                  </div>
                  <div className="loginWithEmail">
                    <div className="text-center orLogin mb-5">
                      <h3>{t("or")}</h3>
                    </div>
                    <h3> {t("login_with_email")}</h3>
                    <div className="form-group mb-4">
                      <EmailInput
                        type="email"
                        id="email_id"
                        name="email_id"
                        onChange={(e) => handelInputChange(e)}
                        placeholder={t("email_address")}
                        maxLength="80"
                      />
                    </div>
                    <div className="form-group mb-5 changeEyeSignUp">
                      <EmailInput
                        type={passwordShown2 ? "text" : "password"}
                        className="form-control form-input-border"
                        id="password"
                        name="password"
                        onChange={(e) => handelInputChange(e)}
                        placeholder={t("password")}
                        maxLength="80"
                      />
                      <i
                        className={
                          passwordShown2 ? "fa fa-eye" : "fa fa-eye-slash"
                        }
                        onClick={togglePasswordVisiblity2}
                      ></i>
                    </div>
                    <div className="mt-5">
                      <button
                        type="submit"
                        className="btn btn-primary w-full"
                        onClick={(e) => handleLoginWithEmail(e)}
                      >
                        {t("login_with_email")}
                      </button>
                    </div>
                    <div className="text-center text-xl">
                      <h3 className="mb-3">
                        {" "}
                        <Link to={"/customer/forgot-password"}>
                          {t("forgot_password")}
                        </Link>
                      </h3>
                      <p>
                        {t("dont_have_an_account")}{" "}
                        <Link to="" onClick={() => toggleTab(2)}>
                          {t("signup")}
                        </Link>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  toggleState === 2
                    ? "loginTabContent loginTabContentActive"
                    : "loginTabContent"
                }
              >
                <div className="signupArea">
                  <h2>{t("sign_up_to_book_services")}</h2>
                  <div className="loginButtonGroup">
                    <button
                      className="btn btn-apple"
                      onClick={() => setLoginWithApple(true)}
                    >
                      <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/apple.png.webp" />
                      {t("sign_up_with_apple")}
                    </button>
                    <button
                      className="btn btn-facebook"
                      onClick={() => setLoginWithFacebook(true)}
                    >
                      <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/facebook.png.webp" />
                      {t("continue_with_facebook")}
                    </button>
                 
                    <button
                      className="btn btn-phone"
                      onClick={handleSignupModalShow}
                    >
                      <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/phone.png.webp" />
                      {t("sign_up_with_mobile_number")}
                    </button>
                    <button
                      className="btn btn-google"
                      onClick={() => setLoginWithGoogle(true)}
                    >
                      <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/google.png.webp" />
                      {t("sign_up_with_google")}
                    </button>
                  </div>
                  <div className="loginWithEmail">
                    <div className="text-center orLogin mb-5">
                      <h3>or</h3>
                    </div>
                    <div className="form-group mb-4">
                      <input
                        type="email"
                        className="form-control form-input-border"
                        id="sign_up_email"
                        name="email"
                        placeholder={t("email_address")}
                        onChange={(e) => handelSingUpInputChange(e)}
                        maxLength="80"
                      />
                    </div>
                    <div className="form-group mb-5 changeEyeSignUp">
                      <input
                        type={passwordShown ? "text" : "password"}
                        className="form-control form-input-border"
                        id="sign_up_password"
                        name="password"
                        onChange={(e) => handelSingUpInputChange(e)}
                        placeholder={t("password")}
                        maxLength="80"
                      />
                      <i
                        className={
                          passwordShown ? "fa fa-eye" : "fa fa-eye-slash"
                        }
                        onClick={togglePasswordVisiblity}
                      ></i>
                    </div>
                    <div className="mt-5">
                      <button
                        type="submit"
                        className="btn btn-primary w-full"
                        onClick={(e) => handleSignUpWithEmail(e)}
                      >
                        {t("sign_up_with_email")}
                      </button>
                    </div>
                    <div className="text-center text-xl">
                      <p>
                        {t("already_have_an_account")}{" "}
                        <Link to="" onClick={() => toggleTab(1)}>
                          {t("login")}
                        </Link>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="loginfooter">
              <h3 className="mb-2">{t("booked_over_phone_or_chat")} </h3>
              <p>{t("claim_your_online_account")}</p>
              <Link to={"/customer/claim-account"}>{t("claim_account")}</Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <LoginModal2
        showLoginModal={loginModalShow}
        hideLoginModal={handleLoginModalHide}
      />
       <LoginWithMobileModal ref={loginWithMobileRef} />
      <SignupModal
        showSignupModal={signupModalShow}
        hideSignupModal={handleSignupModalHide}
      />

      {/*  Address Modal */}
      <AddressSetupComponent
        // ref={setAddressRef}
        from={"bookAppointments"}
        showSetupAddressModal={showAddressModal}
        setShowSetupAddressModal={setShowAddressModal}
      />
      {/* <Modal
        show={showAddressModal}
        onHide={handleAddressModalClose}
        className="addAddressModal"
        id="addAddress"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("search_your_location")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleAddressSubmit} id="addressSetupForm">
            <div className="modal-body">
              <div className="mapContent">
                <div className="inputdiv col-lg-12 mb-0 relative ">
                  <input type="hidden" id="customer_id" name="customer_id" />
                  <input type="hidden" id="latitude" name="latitude" value="" />
                  <input
                    type="hidden"
                    id="longitude"
                    name="longitude"
                    value=""
                  />
                  <input type="hidden" id="country" name="country" value="" />{" "}
                  <input
                    type="hidden"
                    id="country_id"
                    name="country_id"
                    value={localStorage.getItem("userCountryId")}
                  />
                </div>
                <div className="allInputDiv col-lg-12 mb-0 mapToogleDiv addressDetailsHide">
                  <div className="mapToggle toast hide">
                    <button
                      type="button"
                      className="btnclose"
                      data-bs-dismiss="toast"
                    >
                      {" "}
                      <span className="fa fa-angle-down"></span>{" "}
                    </button>
                    <div className="row mb-1">
                      <div className="col-lg-12 inputdiv">
                        <input
                          type="text"
                          className="form-control pl-0"
                          name="address_nickname"
                          placeholder={t("address_nickname")}
                        />
                        <div className="help-block"></div>
                      </div>
                    </div>
                    <div className="row mb-1">
                      <div className="col-lg-12 inputdiv">
                        <input
                          type="text"
                          className="form-control pl-0"
                          name="area"
                          placeholder={t("area")}
                        />
                        <div className="help-block"></div>
                      </div>
                    </div>
                    <div className="row mb-1">
                      <div className="col-lg-12 inputdiv">
                        <label>{t("address_type")}</label>
                        <select
                          className="form-select"
                          name="address_type"
                          onChange={onChange}
                        >
                          <option>{t("address_type")} </option>
                          <option value="Home">{t("home")} </option>
                          <option value="Apartment">{t("apartment")} </option>
                          <option value="Office">{t("office")}</option>
                        </select>
                        <div className="help-block"></div>
                      </div>
                    </div>
                    <div className="row mb-1">
                      <div className="col-lg-6 inputdiv ">
                        <input
                          type="text"
                          className="form-control pl-0"
                          name="street"
                          placeholder={t("street")}
                        />
                        <div className="help-block"></div>
                      </div>
                      <div className="col-lg-6 inputdiv ">
                        <input
                          type="text"
                          className="form-control pl-0"
                          name="building"
                          placeholder={t("building")}
                        />
                        <div className="help-block"></div>
                      </div>
                    </div>
                    <div className="row mb-1">
                      <div className="col-lg-12 inputdiv ">
                        <input
                          type="text"
                          className="form-control pl-0"
                          name="floor"
                          placeholder={t("floor")}
                        />
                        <div className="help-block"></div>
                      </div>
                    </div>
                    <div className="row mb-1">
                      <div className="col-lg-12 inputdiv">
                        <input
                          type="text"
                          className="form-control pl-0"
                          id="home_number"
                          name="house_no"
                          placeholder={t("number")}
                        />
                        <div className="help-block"></div>
                      </div>
                    </div>
                    <div className="row mb-1 ">
                      <div className="col-lg-12 inputdiv">
                        <input
                          type="text"
                          className="form-control pl-0"
                          placeholder={t("additional_directions")}
                        />
                        <div className="help-block"></div>
                      </div>
                    </div>
                    <div className="row mb-1">
                      <div className="col-lg-12 inputdiv ">
                        <div className="row loginMobileInput mb-2">
                          <div className="inputgroup phonecodeselect col-lg-5">
                            <ReactPhoneInput
                              inputProps={{
                                className: "login_country_code form-control",
                                name: "country_code",
                                id: "country_code",
                                autoFocus: true,
                                placeholder: t("enter_mobile_number"),
                                readOnly: "readonly"
                              }}
                              country={userCountryCode.toLocaleLowerCase()}
                              localization={
                                currentLanguageCode === "en" ? es : ar
                              }
                              countryCodeEditable={false}
                              placeholder=""
                              disableCountryCode={false}
                              searchClass="search-class"
                              searchStyle={{
                                margin: "0",
                                width: "97%",
                                height: "30px"
                              }}
                              enableSearch
                              disableSearchIcon
                            />
                            <div className="help-block text-danger"></div>
                          </div>
                          <div className="inputgroup phonecodeselect col-lg-7">
                            <input
                              type="number"
                              name="mobile"
                              id="mobile"
                              className="form-control rounded-pill"
                              placeholder={t("mobile_number")}
                            />
                            <div className="help-block text-danger"></div>
                          </div>
                        </div>
                        <div className="help-block"></div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-lg-12 inputdiv ">
                        <input
                          type="text"
                          className="form-control pl-0"
                          name="landline_number"
                          placeholder={t("landline_number")}
                        />
                        <div className="help-block"></div>
                      </div>
                    </div>
                  </div>
                
                  <button className="btn btn-primary addressSubmit">
                    {t("continue_btn")}
                  </button>
                </div>
              </div>
              <div className="divMap">
                <LocationSearchModal
                  translatedValue={t}
                  getAutoFillAddress={setAutoFillAddress}
                  saveContinue={true}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal> */}
      {/*  Address Modal End */}

      {/*  appointment cart Modal start */}
      <Modal
        show={appointmentCartModal}
        onHide={handleAppointmentCartModalClose}
        className="appointmentCart"
        id="appointmentCart"
      >
        <Modal.Header className="appointmentCartModalHead">
          <button
            type="button"
            class="btn-close"
            onClick={removeCartAppointment}
          ></button>
        </Modal.Header>
        <Modal.Body className="appointmentCartModalBody">
          <div className="appointmentCartModal">
            <div className="p-4">
              <p>
                Hurry up! The available slots are quickly running out. To finish
                your One off boooking, click on the button below and pick up
                where you left off.
              </p>
            </div>
            <div className="w-full d-flex btnGroup mb-2">
              <Link
                to={bookingUrl}
                className="btn btn-primary finish-my-booking"
              >
                Finish My Booking
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/*  appointment cart Modal end */}
    </div>
  );
});

export default LoginSignupModal;
