import React, {useState, memo} from "react";
import Modal from "react-bootstrap/Modal";
import {useTranslation} from "react-i18next";
import AddressMap from "./AddressMap";
import SearchLocation from "./Search";
import {useEffect} from "react";
import { fromLatLng } from "react-geocode";

import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker
} from "react-google-maps";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
// import Search from "./Search";

const AddressComponent = ({isAddAddress, setIsAddress}) => {
  const {t} = useTranslation();

  const handleAddressAddModalClose = () => {
    setIsAddress(false);
  };

  const [marker, setMarker] = useState();
  const [center, setCenter] = useState();
  const [isToggle, setIsToggle] = useState(false);

  const [addressObject, setAddressObject] = useState();

  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;

  const [shortName, setShortName] = useState("");
  const currentLocationGetter = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const {latitude, longitude} = position.coords;
        // setMarker({lat: latitude, lng: longitude});
        localStorage.setItem("latitude", latitude);
        localStorage.setItem("longitude", longitude);
        setAddressObject({
          ...addressObject,
          latitude: latitude,
          longitude: longitude
        });
        // setCenter({lat: latitude, lng: longitude});
        const lat = latitude;
        const lng = longitude;
       fromLatLng(lat, lng).then(
          (response) => {
            setCenter({lat: latitude, lng: longitude});
            setMarker({lat: latitude, lng: longitude});
            const address = response.results[0].formatted_address;
            const addressArray = response.results[0].address_components;
            let street = getStreet(addressArray);
            let area = getArea(addressArray);

            const countryComponent = addressArray.find((component) =>
              component.types.includes("country")
            );
            let newShortName = countryComponent.short_name;
            setShortName(countryComponent.short_name);
            setAddressObject({
              ...addressObject,
              address: address,
              street: street,
              area: area
            });
            // setIsToggle(true);
          },
          (error) => {
            console.error(error);
          }
        );
      },
      (error) => {
        console.error("Error getting current location:", error);
      }
    );
  };
  const getArea = (addressArray) => {
    let area = "";
    for (let i = 0; i < addressArray?.length; i++) {
      for (let j = 0; j < addressArray[i]?.types?.length; j++) {
        if (
          "sublocality_level_1" === addressArray[i].types[j] ||
          "locality" === addressArray[i].types[j]
        ) {
          area = addressArray[i].long_name;
          return area;
        }
      }
    }
  };

  const getStreet = (addressArray) => {
    let street = "";
    for (let i = 0; i < addressArray?.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i]?.types?.length; j++) {
          if (addressArray[i].types[j] === "route") {
            street = addressArray[i].long_name;
            return street;
          }
        }
      }
    }
  };
  useEffect(() => {
    // Fetch the user's current location when the component mounts
    currentLocationGetter();
  }, []);

  const handleMarkerDragEnd = (event) => {
    const {latLng} = event;
    const lat = latLng.lat();
    const lng = latLng.lng();
     setCenter({lat, lng});
    setMarker({lat, lng});
    setAddressObject({
      ...addressObject,
      latitude: lat,
      longitude: lng
    });

   fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        const addressArray = response.results[0].address_components;
        let street = getStreet(addressArray);
        let area = getArea(addressArray);

        const countryComponent = addressArray.find((component) =>
          component.types.includes("country")
        );
        // let newShortName = countryComponent.short_name;
        setShortName(countryComponent.short_name);
        setAddressObject({
          ...addressObject,
          address: address,
          street: street,
          area: area
        });
        setIsToggle(true);
      },
      (error) => {
        console.error(error);
      }
    );
  };
  const AsyncMap = memo(withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        defaultZoom={15}
        center={{
          lat: center?.lat ? center?.lat : 0,
          lng: center?.lng ? center?.lng : 0
        }}
        onClick={(event) => handleMarkerDragEnd(event)}
      >
        {marker && (
          <Marker
            google={props?.google}
            position={{lat: marker?.lat, lng: marker?.lng}}
            draggable={true}
            onDragEnd={(event) => handleMarkerDragEnd(event)}
          />
        )}
      </GoogleMap>
    ))
  ));
  return (
    <>
      <Modal
        show={isAddAddress}
        onHide={handleAddressAddModalClose}
        id="addAddress"
        className="addAddressModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("your_location")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="searchPlace" style={{zIndex: 1}}>
            <SearchLocation
              center={center}
              setCenter={setCenter}
              addressObject={addressObject}
              setShortName={setShortName}
              setAddressObject={setAddressObject}
              setMarker={setMarker}
              setIsToggle={setIsToggle}
              currentLocationGetter={currentLocationGetter}
            />
          </div>
          <AddressMap
            addressObject={addressObject}
            setShortName={setShortName}
            setAddressObject={setAddressObject}
            setCenter={setCenter}
            setMarker={setMarker}
            handleMarkerDragEnd={handleMarkerDragEnd}
            AsyncMap={AsyncMap}
            isToggle={isToggle}
            setIsToggle={setIsToggle}
            handleAddressAddModalClose={handleAddressAddModalClose}
          /> 
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddressComponent;
