import React from "react";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
// import Modal from "../Modal";
import ServiceTabs from "./ServiceTabs";

export const Service = () => {
  return (
    <>

   
      <div className="">
        {/* <div className="step-wizard ">
          <div className="item-container is-flex is-active">
            <div className="item">
              <div className="marker tag ">
                <p className="helper-text-item">1</p>
              </div>
              <div className="details">
                <p className=" step-title ">Service Details</p>
              </div>
            </div>
            <div className="divider"></div>
          </div>
          <div className="item-container is-flex ">
            <div className="item">
              <div className="marker tag ">
                <p className="helper-text-item ">2</p>
              </div>
              <div className="details">
                <p className=" step-title">Date &amp; Time</p>
              </div>
            </div>
            <div className="divider"></div>
          </div>
          <div className="item-container is-flex">
            <div className="item">
              <div className="marker tag ">
                <p className="helper-text-item ">3</p>
              </div>
              <div className="details">
                <p className="step-title">Payment</p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="stepsContent stepService ">
        <div className="row">
          <div className=" col-md-12">
            <div className="card">
              <div className="hairService">
                <ServiceTabs />
              </div>
            </div>
          </div>
         
        </div>
      </div>
   
    </>
  );
};
export default Service;
