import React from 'react';
import { useSelector } from 'react-redux';

export const useCountryCurrency = () => {
    const getAllCountryState = useSelector(state => state.countryReducer);
    const { country_list } = getAllCountryState;
   
    const countryCurrency =  country_list?.filter((f)=>f._id === localStorage.getItem("userCountryId") )
    return countryCurrency;
} 
