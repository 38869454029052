import React from 'react';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {Link, useParams} from "react-router-dom";
import jQuery from 'jquery';
import {useDispatch, useSelector} from "react-redux";
import {resetPasswordAction} from "../../actions/userActions";
import { useTranslation } from "react-i18next";
import EmailInput from '../../helpers/EmailInput';

const ResetPassword = () => {
    
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const currentLanguage = localStorage.getItem('currentLanguage') ? localStorage.getItem('currentLanguage') : 'en';
    const {token, emailToken} = useParams();
    const handleResetPassword = (e) => { 
      let resetPasswordUrl = process.env.REACT_APP_LOCAL_API_URL+"/customer/reset/password";
      let password = jQuery('#password').val();
      let password_confirm = jQuery('#password_confirm').val();
      let values = {
          email: emailToken,
          password: password,
          password_confirm: password_confirm
      }
      dispatch(resetPasswordAction(resetPasswordUrl,values,t,token,currentLanguage));
    }

    return (
      <>
        <Header/>
        <div className="py-5">
          <div className="userContainer">
             <div className="editPersonalDetail">
              <div className="heading">   
              <Link to="/my-account/account-details"><h5>{t("account_details")}</h5></Link>
                <h2>{t("reset_password")}</h2>
              </div>
              <div className="card">
                <div className="personalForm">                 
                  <div className="inputdiv col-lg-12">
                    <label>{t("new_password")}</label>
                    <EmailInput
                      type="password"
                      id="password"
                      name="password"  
                      className="form-control"                     
                      placeholder={t('enter_your_new_password')}
                    /> 
                  </div> 
                  <div className="inputdiv col-lg-12">
                    <label>{t("confirm_new_password")}</label>
                    <EmailInput
                      type="password"
                      id="password_confirm"
                      name="password_confirm"
                      className="form-control"                      
                      placeholder={t('confirm_new_password')}
                    />
                  </div> 
                  <div className="inputdiv col-lg-12 mt-3 mb-0 ">
                    <button className="btn btn-primary w-full" onClick={handleResetPassword}>{t('reset_password')}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      
      </>
    );
}

export default ResetPassword
