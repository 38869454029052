
// eslint-disable-next-line no-unused-vars
/* eslint-disable no-debugger*/
// import {usePagination, DOTS} from './usePagination';
import './pagination.css';

const Pagination = ({page, pages, changePage, decrementPage}) => {
    const handelPageClick = (idx) => {
        changePage(idx + 1);
    };

    let middlePagination;
    if (Number(pages) <= 5 && Number(pages) > 1) {
        middlePagination = [ ...Array(pages) ].map((_, idx) => (
            <button key={idx + 1} 
                    onClick={() => { handelPageClick(idx); }}
                    disabled={Number(page) === (idx + 1)}
            >
                {idx + 1}
            </button>
        ));
    } else {

        const startValues = Math.floor((Number(page) - 1) / 5) * 5;
        middlePagination = (
            <>
                {[ ...Array(5)
                ].map((_, idx) => (
                    <button key={startValues + idx + 1}
                            onClick={() => changePage(startValues + idx + 1)}
                            disabled={Number(page) === (startValues + idx + 1)}
                    >
                        {startValues + idx + 1}
                    </button>
                ))
                }
                <button>...</button>
                <button onClick={() => changePage(Number(pages))}>{pages}</button>
            </>
        );

        if (Number(page) > 5) {
            if (Number(pages) - Number(page) >= 5) {
                middlePagination = (
                    <>
                        <button onClick={() => changePage(1)}>1</button>
                        <button>...</button>
                        <button onClick={() => changePage(startValues)}>{startValues}</button>
                        {[ ...Array(5)
                        ].map((_, idx) => (
                            <button key={startValues + idx + 1}
                                    onClick={() => changePage(startValues + idx + 1)}
                                    disabled={Number(page) === (startValues + idx + 1)}
                            >
                                {startValues + idx + 1}
                            </button>
                        ))
                        }
                        <button>...</button>
                        <button onClick={() => changePage(Number(pages))}>{pages}</button>
                    </>
                );
            } else {
                let amountLeft = Number(pages) - Number(page) + 5;
                middlePagination = (
                    <>
                        <button onClick={() => changePage(1)}>1</button>
                        <button>...</button>
                        <button onClick={() => changePage(startValues)}>{startValues}</button>
                        {[ ...Array(amountLeft)
                        ].map((_, idx) => (
                            <button key={startValues + idx + 1}
                                    style={(pages < startValues + idx + 1) ? {display: "none"} : null}
                                    onClick={() => changePage(startValues + idx + 1)}
                                    disabled={Number(page) === (startValues + idx + 1)}
                            >
                                {startValues + idx + 1}
                            </button>
                        ))
                        }
                    </>
                );
            }
        }
    }

    return pages > 1 && (
        <div className="pagination" >
            <button className="pagination_prev" 
                    onClick={() =>   decrementPage(Number(page) - 1)}
                    disabled={Number(page) === 1}
            >&#171;</button>
            {middlePagination}  
            <button className="pagination_next" 
                    onClick={() => changePage(Number(page) + 1)}
                    disabled={Number(page) === pages}
            >&#187;</button>
        </div>
    );
};

export default Pagination;