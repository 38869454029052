export const appointmentReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_HOME_PROFESSIONAL_LIST_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_HOME_PROFESSIONAL_LIST_SUCCESS':
            return {
                ...state,
                professionals:action.payload.professionals,
                loading: false,
                success: true
            }
        case'GET_HOME_PROFESSIONAL_LIST_FAILED':
            return {
                loading: false,
                error: true
            }
        case'GET_PROFESSIONAL_INFO_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_PROFESSIONAL_INFO_SUCCESS':
            return {
                ...state,
                professional:action.payload.professional,
                loading: false,
                success: true
            }
        case'GET_PROFESSIONAL_INFO_FAILED':
            return {
                ...state,
                loading: true,
                error: true
            }
        case'GET_BOOK_APPOINTMENT_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_BOOK_APPOINTMENT_SUCCESS':
            return {
                ...state,
                appointment:action.payload,
                status:action.status,
                loading: false,
                success: true
            }
        case'GET_BOOK_APPOINTMENT_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        case'GET_APPOINTMENT_LIST_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_APPOINTMENT_LIST_SUCCESS':
            return {
                appointments:action.payload.appointments,
                loading: false,
                success: true
            }
        case'GET_APPOINTMENT_LIST_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        case'GET_APPOINTMENT_CANCEL_RESCHEDULE_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_APPOINTMENT_CANCEL_RESCHEDULE_SUCCESS':
            return {
                loading: false,
                success: true
            }
        case'GET_APPOINTMENT_CANCEL_RESCHEDULE_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        case'GET_APPOINTMENT_INFO_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_APPOINTMENT_INFO_SUCCESS':
            return {
                appointment:action.payload.appointment,
                loading: false,
                success: true
            }
        case'GET_APPOINTMENT_INFO_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        case'GET_APPOINTMENT_PRICE_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_APPOINTMENT_PRICE_SUCCESS':
            return {
                totalPrice:action.payload.totalPrice,
                loading: false,
                success: true
            }
            case'GET_APPOINTMENT_PICTURES_SUCCESS':
            return {
                ...state,
                uploadImages:action.payload.uploadImages,
                loading: false,
                success: true
            }
        case'GET_APPOINTMENT_PRICE_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        case 'GET_APPOINTMENT_CANCEL_REQUEST' :
            return {
                ...state,
                loading: true,
            }
        case 'GET_APPOINTMENT_CANCEL_SUCCESS' :
                return {
                    ...state,
                    message: action.payload.message,
                    status: action.payload.status,
                    loading: false,
                }
                case 'GET_APPOINTMENT_CANCEL_FAILED' :
                    return {
                        ...state,
                        loading: false,
                        error: true
                    }
        default:
            return {...state}
    }
}